import React, { useCallback, useState, useEffect } from 'react';
import { useModel, useModelKit } from '@thrivelot/hooks';
import { Button } from '@thrivelot/stories';
import { constructUuid } from '@thrivelot/utils';
import { ModelKit } from '@thrivelot/model-kit';
import { LayoutModal, SelectProjectFeature } from '../..';
import { featurelessFeature } from '../../FormBundleFeature/FormBundleFeature';
import { RowItemCatalog } from '../../RowItemCatalog';

const ModalMoveBundleItems = ({
  onClose,
  projectId,
  bundleId,
  featureId,
  itemPath,
  itemIds,
}) => {
  const { loaded: modelKitLoaded } = useModelKit();
  const { actions: bundleActions, updateModel: updateBundle } = useModel({
    modelName: 'Bundle',
    id: bundleId,
  });

  const [formState, setFormState] = useState({ featureId, items: [] });
  const [loaded, setLoaded] = useState(false);

  // useEffect(() => {
  //   if (
  //     projectLoaded &&
  //     !formState.featureId &&
  //     (project?.features || []).length === 0
  //   )
  //     setFormState({ ...formState, featureId: featurelessFeature.id });
  // }, [formState, setFormState, project?.features, featureId, projectLoaded]);

  // useEffect(() => {
  //   if (itemIds && bundleLoaded) {
  //     setFormState((prevState) => {
  //       if (!prevState.items || prevState.items.length === 0)
  //         return {
  //           ...formState,
  //           items: bundle[itemPath]
  //             .filter((item) => itemIds.includes(item.id))
  //             .map((item) => ({ ...item, id: constructUuid() })),
  //         };
  //       return prevState;
  //     });
  //   }
  // }, [bundleLoaded]); // Limit this to run only once when bundle loads

  useEffect(() => {
    const fetchData = async () => {
      if (!modelKitLoaded) return;

      const projectKit = new ModelKit({
        modelName: 'Project',
        id: projectId,
      });
      const project = await projectKit.query();

      if (!formState.featureId && (project?.features || []).length === 0)
        setFormState((prevState) => ({
          ...prevState,
          featureId: featurelessFeature.id,
        }));

      const bundleKit = new ModelKit({ modelName: 'Bundle', id: bundleId });
      const bundle = await bundleKit.query();

      if (itemIds) {
        setFormState((prevState) => ({
          ...prevState,
          items: bundle[itemPath]
            .filter((item) => itemIds.includes(item.id))
            .map((item) => ({ ...item, id: constructUuid() })),
        }));
      }
      setLoaded(true);
    };

    fetchData().catch(console.error);
  }, [modelKitLoaded]);

  const handleSubmit = useCallback(() => {
    const items = formState.items.map((item) => ({
      ...item,
      featureId: formState.featureId,
    }));
    updateBundle(bundleActions.removeItems(itemIds).addItems(items).result);

    onClose();
  }, [formState, updateBundle, onClose, bundleActions, itemIds]);

  return (
    <LayoutModal
      onClose={onClose}
      loading={!loaded}
      header={
        <div className="text-lg text-brown-dark italic">
          Move Item{itemIds.length > 1 && 's'}
        </div>
      }
      footer={
        <div className="flex justify-end">
          <Button
            label="Submit"
            color="green-main"
            kind="outline"
            disabled={formState.items.length === 0 || !formState.featureId}
            onClick={handleSubmit}
          />
        </div>
      }
      dialogCloseDisabled
    >
      <div>
        <div>
          <div className="text-brown-dark text-sm font-bold mb-2">
            Move line item{itemIds.length > 1 && 's'} to which feature?
          </div>
          <SelectProjectFeature
            projectId={projectId}
            currentFeatureId={formState.featureId}
            onSelect={(_featureId) =>
              setFormState({ ...formState, featureId: _featureId })
            }
          />
        </div>

        <div className="divide-y divide-tan-light mt-4">
          {formState.items.map((item) => (
            <RowItemCatalog key={item.id} classAppend="p-2" item={item} />
          ))}
        </div>
      </div>
    </LayoutModal>
  );
};

export { ModalMoveBundleItems };
