import React, { useMemo } from 'react';
import { useCanCan, useModel, useMultiSelect } from '@thrivelot/hooks';
import {
  LeafLoader,
  CheckFull,
  CheckEmpty,
  CheckMinus,
} from '@thrivelot/stories';
import { RowItemProjectFile } from '../RowItemProjectFile';
import { DropdownMultiSelectedActions } from '../RowItemProjectFileFolder/DropdownMultiSelectedActions';

export const ListFiles = ({ projectId }) => {
  const { model: project, loaded } = useModel({
    modelName: 'Project',
    id: projectId,
  });
  const { canEdit } = useCanCan(project);

  const filesWithoutFolder = useMemo(() => {
    if (!project?.files) return [];

    return project.files.filter(
      (file) =>
        !(project.fileFolders || []).find((folder) =>
          folder.fileIds.includes(file.id)
        )
    );
  }, [project?.files, project?.fileFolders]);

  const {
    selectedOption,
    allOfOptionAreSelected,
    onMultiSelect,
    selectedItemIds,
    itemIsSelected,
    setItemSelection,
  } = useMultiSelect({
    items: filesWithoutFolder,
  });

  if (!loaded)
    return (
      <div>
        <LeafLoader />
      </div>
    );

  return (
    <div className="p-4">
      {canEdit && (
        <div className="flex items-center mb-2 gap-6 h-10">
          {selectedOption === 'all' && allOfOptionAreSelected && (
            <CheckFull
              size={20}
              onClick={() => onMultiSelect()}
              styleProps="hover:cursor-pointer"
            />
          )}
          {!selectedOption &&
            selectedItemIds.length === filesWithoutFolder.length && (
              <CheckMinus
                size={20}
                onClick={() => onMultiSelect()}
                styleProps="hover:cursor-pointer"
              />
            )}
          {!selectedOption &&
            selectedItemIds.length !== filesWithoutFolder.length && (
              <CheckEmpty
                size={20}
                onClick={() => onMultiSelect('all')}
                styleProps="hover:cursor-pointer"
              />
            )}
          <DropdownMultiSelectedActions
            projectId={projectId}
            selectedItemIds={selectedItemIds}
            clearSelected={() => onMultiSelect()}
          />
        </div>
      )}
      <div className="divide-y divide-tan-light">
        {filesWithoutFolder.map((file) => (
          <div key={file.id} className="flex items-center gap-3 w-full">
            {canEdit && (
              <>
                {itemIsSelected(file.id) ? (
                  <CheckFull
                    size={20}
                    onClick={() => setItemSelection(file.id, false)}
                    styleProps="hover:cursor-pointer"
                  />
                ) : (
                  <CheckEmpty
                    size={20}
                    onClick={() => setItemSelection(file.id, true)}
                    styleProps="hover:cursor-pointer"
                  />
                )}
              </>
            )}
            <RowItemProjectFile
              projectId={projectId}
              file={file}
              classAppend="!bg-white-light"
            />
          </div>
        ))}
      </div>
    </div>
  );
};
