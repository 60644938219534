import { findItemsByTags } from "@thrivelot/actions";

const addToCost = (cost) => (item) => {
  cost += item.cost * item.quantity;
};

const calculateFeatureCost = (model, featureId) => {
  const pathToItemContainingTags = `features[id:${featureId}]`;
  const lineItems = findItemsByTags(model, pathToItemContainingTags, "lineItems");
  const plantItems = findItemsByTags(model, pathToItemContainingTags, "plantItems");
  let featureCost = 0;
  lineItems.forEach(addToCost(featureCost));
  plantItems.forEach(addToCost(featureCost));
  return featureCost;
};

export { calculateFeatureCost };
