import React, { useMemo } from 'react';
import { useModel } from '@thrivelot/hooks';
import { transformStringToTitleCase } from '@thrivelot/utils';
import { constructAddressString } from '@thrivelot/common';
import { LayoutModal } from '../..';
import { InputAddress } from '../../InputAddress';

const ModalEditAddress = ({ onClose, modelName, modelId, addressPath }) => {
  const {
    model: project,
    updateModel,
    loaded,
    actions,
  } = useModel({ modelName, id: modelId });

  const address = loaded && project[addressPath];

  const defaultValue = useMemo(
    () => loaded && constructAddressString(actions.get(addressPath) || ''),
    [addressPath, loaded, address, actions] // eslint-disable-line
  );

  return (
    <LayoutModal
      onClose={onClose}
      loading={!loaded}
      header={
        <div className="text-lg text-brown-dark italic">
          Edit {transformStringToTitleCase(modelName)} Address
        </div>
      }
    >
      {defaultValue !== undefined && (
        <InputAddress
          defaultValue={defaultValue}
          onSelect={(value) => {
            updateModel(actions.set(addressPath, value).result);
            onClose();
          }}
        />
      )}
    </LayoutModal>
  );
};

export { ModalEditAddress };
