import { css } from "styled-components";

const CssDivDefault = css`
  -webkit-tap-highlight-color: transparent;
  -webkit-appearance: none;
  ::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
  ::selection {
    background-color: ${({ theme }) => theme.inputTintColor};
    color: ${({ theme }) => theme.inputSelectedText};
  }
  blockquote::selection {
    background-color: ${({ theme }) => theme.inputTintColor};
    color: ${({ theme }) => theme.inputSelectedText};
  }
  transition: opacity 0.5s ease-out, box-shadow 0.5s ease-out, color 1s ease-in-out, background-color 1s ease-in-out;
  ${({ disabled, hoverable }) =>
    !disabled &&
    hoverable &&
    css`
      cursor: pointer;
      &:hover {
        opacity: 0.7;
      }
    `}
  ${({ disabled }) =>
    disabled &&
    css`
      pointer-events: none;
    `}
  ${({ backgroundColor }) =>
    backgroundColor &&
    css`
      background-color: ${backgroundColor};
    `}
`;

export default CssDivDefault;
