import styled from "styled-components";
import CssDivDefault from "./CssDivDefault";

const colorConstructor = ({ color }) => color;

const DivTag = styled.div`
  ${CssDivDefault}
  position: relative;
  padding: 5px 31px 5px 12px;
  min-height: fit-content;
  max-height: fit-content;
  border-radius: 300px;
  border: 1px solid ${colorConstructor};
  color: ${colorConstructor};
  font-family: Rokkitt-Bold;
  @media (max-width: 767px) {
    font-size: 14px;
  }
  @media (min-width: 768px) {
    font-size: 17px;
  }
`;

export default DivTag;
