import styled from "styled-components";

const MiddleColumnContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  padding: 0px 12px;
`;

export { MiddleColumnContainer };
