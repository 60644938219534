import React from 'react';
import { usePlantCreate } from '@thrivelot/hooks';
import { Button, Input } from '@thrivelot/stories';
import { transformStringToTitleCase } from '@thrivelot/utils';
import { LayoutModal } from '../..';
import { DropdownWithLabel } from './DropdownWithLabel';
import { InputNumber } from './InputNumber';
import { MonthSelect } from './MonthSelect';
import { MultiSelectTextInput } from './MultiSelectTextInput';
import { PlantSection } from './PlantSection';
import { SelectBox } from './SelectBox';
import { TextareaWithLabel } from './TextareaWithLabel';

const phRange = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14];
const numRange = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

export const ModalAddPlant = ({ onClose, title }) => {
  const {
    model: plant,
    update,
    create,
    actions,
    constants,
    saving,
  } = usePlantCreate();

  const otherCommonNames = actions.getStringAsArray('otherCommonNames');
  const synonyms = actions.getStringAsArray('synonyms');
  const edibleParts = actions.getStringAsArray('edibleParts');
  const habits = actions.getStringAsArray('habit');
  const distributions = actions.getStringAsArray('distributions');
  const flowerColors = actions.getStringAsArray('flowerColors');
  const foliageColors = actions.getStringAsArray('foliageColors');
  const fruitOrSeedColors = actions.getStringAsArray('fruitOrSeedColors');
  const growingMonths = actions.getMonthsAsArray('growingMonths');
  const bloomMonths = actions.getMonthsAsArray('bloomMonths');
  const fruitingMonths = actions.getMonthsAsArray('fruitingMonths');

  const formOptions = Object.keys(constants.forms).map((key) => ({
    label: transformStringToTitleCase(constants.forms[key]),
    onClick: () => update({ form: constants.forms[key] }),
  }));

  const growthRateOptions = Object.keys(constants.growthRates).map((key) => ({
    label: transformStringToTitleCase(constants.growthRates[key]),
    onClick: () => update({ growthRate: constants.growthRates[key] }),
  }));

  const foliageTextureOptions = Object.keys(constants.foliageTextures).map(
    (key) => ({
      label: transformStringToTitleCase(constants.foliageTextures[key]),
      onClick: () => update({ foliageTexture: constants.foliageTextures[key] }),
    })
  );

  const anaerobicToleranceOptions = Object.keys(
    constants.anaerobicTolerances
  ).map((key) => ({
    label: transformStringToTitleCase(constants.anaerobicTolerances[key]),
    onClick: () =>
      update({ anaerobicTolerance: constants.anaerobicTolerances[key] }),
  }));

  const habitOptions = Object.keys(constants.habits).map((key) => ({
    icon: habits.includes(constants.habits[key]) ? 'Checkmark' : undefined,
    color: habits.includes(constants.habits[key]) ? 'green-main' : undefined,
    label: transformStringToTitleCase(constants.habits[key]),
    onClick: () => selectInMultiSelect('habit', constants.habits[key]),
  }));

  const ediblePartOptions = Object.keys(constants.edibleParts).map((key) => ({
    icon: edibleParts.includes(constants.edibleParts[key])
      ? 'Checkmark'
      : undefined,
    color: edibleParts.includes(constants.edibleParts[key])
      ? 'green-main'
      : undefined,
    label: transformStringToTitleCase(constants.edibleParts[key]),
    onClick: () =>
      selectInMultiSelect('edibleParts', constants.edibleParts[key]),
  }));

  const flowerColorOptions = Object.keys(constants.colors).map((key) => ({
    icon: flowerColors.includes(constants.colors[key])
      ? 'Checkmark'
      : undefined,
    color: flowerColors.includes(constants.colors[key])
      ? 'green-main'
      : undefined,
    label: transformStringToTitleCase(constants.colors[key]),
    onClick: () => selectInMultiSelect('flowerColors', constants.colors[key]),
  }));

  const foliageColorOptions = Object.keys(constants.colors).map((key) => ({
    icon: foliageColors.includes(constants.colors[key])
      ? 'Checkmark'
      : undefined,
    color: foliageColors.includes(constants.colors[key])
      ? 'green-main'
      : undefined,
    label: transformStringToTitleCase(constants.colors[key]),
    onClick: () => selectInMultiSelect('foliageColors', constants.colors[key]),
  }));

  const fruitOrSeedColorOptions = Object.keys(constants.colors).map((key) => ({
    icon: fruitOrSeedColors.includes(constants.colors[key])
      ? 'Checkmark'
      : undefined,
    color: fruitOrSeedColors.includes(constants.colors[key])
      ? 'green-main'
      : undefined,
    label: transformStringToTitleCase(constants.colors[key]),
    onClick: () =>
      selectInMultiSelect('fruitOrSeedColors', constants.colors[key]),
  }));

  const phOptions = (path) =>
    phRange.map((ph) => ({
      label: `${ph}`,
      onClick: () => update({ [path]: ph }),
    }));

  const numOptions = (path) =>
    numRange.map((num) => ({
      label: `${num}`,
      onClick: () => update({ [path]: num }),
    }));

  const addToMultiSelect = (path, value) => {
    const values = actions.getStringAsArray(path);
    const newValues = [...values, value].sort();
    update({ [path]: newValues.join(',') });
  };

  const removeFromMultiSelect = (path, value) => {
    const values = actions.getStringAsArray(path);
    const newValues = values.filter((v) => v !== value);
    update({ [path]: newValues.join(',') });
  };

  const selectInMultiSelect = (path, value) => {
    const values = actions.getStringAsArray(path);
    const newValues = values.includes(value)
      ? values.filter((v) => v !== value)
      : [...values, value].sort();
    update({ [path]: newValues.join(',') });
  };

  const selectMonthInMultiSelect = (path, value) => {
    const values = actions.getMonthsAsArray(path);
    const newValues = values.includes(value)
      ? values.filter((v) => v !== value)
      : [...values, value];
    const months = actions.convertMonthArrayToBinaryString(newValues);
    update({ [path]: months });
  };

  return (
    <LayoutModal
      onClose={onClose}
      header={
        <div className="text-lg text-brown-dark italic">
          {title || 'Add Plant'}
        </div>
      }
      footer={
        <div className="flex justify-end">
          <Button
            label="Create"
            color="green-main"
            kind="outline"
            disabled={saving || (plant.commonName || '').trim() === ''}
            onClick={create}
          />
        </div>
      }
      dialogCloseDisabled
    >
      <div>
        <PlantSection header="Names">
          <Input
            containerClassName="mb-4"
            name="Common Name"
            label="Common Name"
            placeholder="Enter common name..."
            value={plant.commonName || ''}
            onChange={(e) => update({ commonName: e.target.value })}
          />
          <Input
            containerClassName="mb-4"
            name="Family Common Name"
            label="Family Common Name"
            placeholder="Enter family common name..."
            value={plant.familyCommonName || ''}
            onChange={(e) => update({ familyCommonName: e.target.value })}
          />
          <Input
            containerClassName="mb-4"
            name="Family"
            label="Family"
            placeholder="Enter family..."
            value={plant.family || ''}
            onChange={(e) => update({ family: e.target.value })}
          />
          <Input
            containerClassName="mb-4"
            name="Genus"
            label="Genus"
            placeholder="Enter genus..."
            value={plant.genus || ''}
            onChange={(e) => update({ genus: e.target.value })}
          />
          <Input
            containerClassName="mb-4"
            name="Species"
            label="Species"
            placeholder="Enter species..."
            value={plant.species || ''}
            onChange={(e) => update({ species: e.target.value })}
          />
          <MultiSelectTextInput
            label="Other Common Names"
            name="Other Common Names"
            placeholder="Enter other common names..."
            items={otherCommonNames}
            onAdd={(item) => addToMultiSelect('otherCommonNames', item)}
            onRemove={(item) => removeFromMultiSelect('otherCommonNames', item)}
          />
          <MultiSelectTextInput
            label="Synonyms"
            name="Synonyms"
            placeholder="Enter synonyms..."
            items={synonyms}
            onAdd={(item) => addToMultiSelect('synonyms', item)}
            onRemove={(item) => removeFromMultiSelect('synonyms', item)}
          />
        </PlantSection>
        <PlantSection header="Characteristics">
          <DropdownWithLabel
            name="Form"
            label="Form"
            placeholder="Select form..."
            value={plant.form}
            options={formOptions}
          />
          <DropdownWithLabel
            name="Habits"
            label="Habits"
            placeholder="Select habits..."
            value={habits.join(', ')}
            options={habitOptions}
          />
          <DropdownWithLabel
            name="Edible Parts"
            label="Edible Parts"
            placeholder="Select edible parts..."
            value={edibleParts.join(', ')}
            options={ediblePartOptions}
          />
        </PlantSection>
        <PlantSection header="Growth">
          <TextareaWithLabel
            name="Growing Description"
            label="Growing Description"
            placeholder="Enter growing description..."
            value={plant.growingDescription || ''}
            onChange={(e) => update({ growingDescription: e.target.value })}
          />
          <DropdownWithLabel
            name="Growth Rate"
            label="Growth Rate"
            placeholder="Select growth rate..."
            value={plant.growthRate}
            options={growthRateOptions}
          />
          <InputNumber
            containerClassName="mb-4"
            name="Average Height (mm)"
            label="Average Height (mm)"
            placeholder="Enter average height (mm)..."
            value={plant.averageHeightMm || ''}
            onChange={(value) => update({ averageHeightMm: value })}
          />
          <InputNumber
            containerClassName="mb-4"
            name="Maximum Height (mm)"
            label="Maximum Height (mm)"
            placeholder="Enter maximum height (mm)..."
            value={plant.maximumHeightMm || ''}
            onChange={(value) => update({ maximumHeightMm: value })}
          />
          <InputNumber
            containerClassName="mb-4"
            name="Spread (mm)"
            label="Spread (mm)"
            placeholder="Enter spread (mm)..."
            value={plant.spreadMm || ''}
            onChange={(value) => update({ spreadMm: value })}
          />
          <MonthSelect
            label="Growing Months"
            months={growingMonths}
            onSelect={(month) =>
              selectMonthInMultiSelect('growingMonths', month)
            }
          />
          <MonthSelect
            label="Bloom Months"
            months={bloomMonths}
            onSelect={(month) => selectMonthInMultiSelect('bloomMonths', month)}
          />
          <MonthSelect
            label="Fruiting Months"
            months={fruitingMonths}
            onSelect={(month) =>
              selectMonthInMultiSelect('fruitingMonths', month)
            }
          />
        </PlantSection>
        <PlantSection header="Planting">
          <TextareaWithLabel
            name="Sowing Description"
            label="Sowing Description"
            placeholder="Enter sowing description..."
            value={plant.sowingDescription || ''}
            onChange={(e) => update({ sowingDescription: e.target.value })}
          />
          <InputNumber
            containerClassName="mb-4"
            name="Number of Days till Harvest"
            label="Number of Days till Harvest"
            placeholder="Enter number of days till harvest..."
            value={plant.daysToHarvest || ''}
            onChange={(value) => update({ daysToHarvest: value })}
          />
          <InputNumber
            containerClassName="mb-4"
            name="Row Spacing (mm)"
            label="Row Spacing (mm)"
            placeholder="Enter row spacing (mm)..."
            value={plant.rowSpacingMm || ''}
            onChange={(value) => update({ rowSpacingMm: value })}
          />
          <InputNumber
            containerClassName="mb-4"
            name="Minimum Root Depth (mm)"
            label="Minimum Root Depth (mm)"
            placeholder="Enter minimum root depth (mm)..."
            value={plant.minimumRootDepthMm || ''}
            onChange={(value) => update({ minimumRootDepthMm: value })}
          />
        </PlantSection>
        <PlantSection header="Growing Conditions">
          <DropdownWithLabel
            name="Anaerobic Tolerance"
            label="Anaerobic Tolerance"
            placeholder="Select anaerobic tolerance..."
            value={plant.anaerobicTolerance}
            options={anaerobicToleranceOptions}
          />
          <DropdownWithLabel
            name="pH Minimum"
            label="pH Minimum (top 30 cm of soil)"
            placeholder="Select pH minimum..."
            value={plant.phMinimum}
            options={phOptions('phMinimum')}
          />
          <DropdownWithLabel
            name="pH Maximum"
            label="pH Maximum (top 30 cm of soil)"
            placeholder="Select pH maximum..."
            value={plant.phMaximum}
            options={phOptions('phMaximum')}
          />
          <DropdownWithLabel
            name="Light Requirement"
            label="Light Requirement (0 (no light, <= 10 lux) to 10 (very intensive insolation, >= 100 000 lux))"
            placeholder="Select light requirement..."
            value={plant.light}
            options={numOptions('light')}
          />
          <DropdownWithLabel
            name="Soil Humidity Requirement"
            label="Soil Humidity Requirement (0 (oligotrophic) to 10 (hypereutrophic))"
            placeholder="Select soil humidity requirement..."
            value={plant.soilHumidity}
            options={numOptions('soilHumidity')}
          />
          <DropdownWithLabel
            name="Soil Salinity Requirement"
            label="Soil Salinity Requirement ((untolerant) to 10 (hyperhaline))"
            placeholder="Select soil salinity requirement..."
            value={plant.soilSalinity}
            options={numOptions('soilSalinity')}
          />
          <DropdownWithLabel
            name="Atmospheric Humidity Requirement"
            label="Atmospheric Humidity Requirement (0 (<=10%) to 10 (>= 90%))"
            placeholder="Select atmospheric humidity requirement..."
            value={plant.atmosphericHumidity}
            options={numOptions('atmosphericHumidity')}
          />
          <MultiSelectTextInput
            label="Distributions"
            name="Distributions"
            placeholder="Enter distributions..."
            items={distributions}
            onAdd={(item) => addToMultiSelect('distributions', item)}
            onRemove={(item) => removeFromMultiSelect('distributions', item)}
          />
        </PlantSection>
        <PlantSection header="Foliage">
          <DropdownWithLabel
            name="Foliage Texture"
            label="Foliage Texture"
            placeholder="Select foliage texture..."
            value={plant.foliageTexture}
            options={foliageTextureOptions}
          />
          <DropdownWithLabel
            name="Foliage Colors"
            label="Foliage Colors"
            placeholder="Select foliage colors..."
            value={foliageColors.join(', ')}
            options={foliageColorOptions}
          />
          <SelectBox
            label="Foliage Conspicuous?"
            selected={plant.foliageConspicuous}
            onClick={() =>
              update({ foliageConspicuous: !plant.foliageConspicuous })
            }
          />
        </PlantSection>
        <PlantSection header="Flower">
          <DropdownWithLabel
            name="Flower Colors"
            label="Flower Colors"
            placeholder="Select flower colors..."
            value={flowerColors.join(', ')}
            options={flowerColorOptions}
          />
          <SelectBox
            label="Flower Conspicuous?"
            selected={plant.flowerConspicuous}
            onClick={() =>
              update({ flowerConspicuous: !plant.flowerConspicuous })
            }
          />
        </PlantSection>
        <PlantSection header="Fruit or Seed">
          <DropdownWithLabel
            name="Fruit or Seed Colors"
            label="Fruit or Seed Colors"
            placeholder="Select fruit or seed colors..."
            value={fruitOrSeedColors.join(', ')}
            options={fruitOrSeedColorOptions}
          />
          <SelectBox
            label="Fruit or Seed Conspicuous?"
            selected={plant.fruitOrSeedConspicuous}
            onClick={() =>
              update({ fruitOrSeedConspicuous: !plant.fruitOrSeedConspicuous })
            }
          />
        </PlantSection>
        <PlantSection header="External Links">
          <Input
            containerClassName="mb-4"
            name="USDA"
            label="USDA"
            placeholder="Enter USDA link..."
            value={plant.links?.usda || ''}
            onChange={(e) =>
              update({
                links: { ...(plant.links || {}), usda: e.target.value },
              })
            }
          />
          <Input
            containerClassName="mb-4"
            name="Tropicos"
            label="Tropicos"
            placeholder="Enter Tropicos link..."
            value={plant.links?.tropicos || ''}
            onChange={(e) =>
              update({
                links: { ...(plant.links || {}), tropicos: e.target.value },
              })
            }
          />
          <Input
            containerClassName="mb-4"
            name="Tela Botonica"
            label="Tela Botonica"
            placeholder="Enter Tela Botonica link..."
            value={plant.links?.telaBotanica || ''}
            onChange={(e) =>
              update({
                links: { ...(plant.links || {}), telaBotanica: e.target.value },
              })
            }
          />
          <Input
            containerClassName="mb-4"
            name="POWO"
            label="POWO"
            placeholder="Enter POWO link..."
            value={plant.links?.powo || ''}
            onChange={(e) =>
              update({
                links: { ...(plant.links || {}), powo: e.target.value },
              })
            }
          />
          <Input
            containerClassName="mb-4"
            name="PlantNet"
            label="PlantNet"
            placeholder="Enter PlantNet link..."
            value={plant.links?.plantnet || ''}
            onChange={(e) =>
              update({
                links: { ...(plant.links || {}), plantnet: e.target.value },
              })
            }
          />
          <Input
            containerClassName="mb-4"
            name="GBIF"
            label="GBIF"
            placeholder="Enter GBIF link..."
            value={plant.links?.gbif || ''}
            onChange={(e) =>
              update({
                links: { ...(plant.links || {}), gbif: e.target.value },
              })
            }
          />
          <Input
            containerClassName="mb-4"
            name="OpenFarm"
            label="OpenFarm"
            placeholder="Enter OpenFarm link..."
            value={plant.links?.openfarm || ''}
            onChange={(e) =>
              update({
                links: { ...(plant.links || {}), openfarm: e.target.value },
              })
            }
          />
          <Input
            containerClassName="mb-4"
            name="Catminat"
            label="Catminat"
            placeholder="Enter Catminat link..."
            value={plant.links?.catminat || ''}
            onChange={(e) =>
              update({
                links: { ...(plant.links || {}), catminat: e.target.value },
              })
            }
          />
          <Input
            containerClassName="mb-4"
            name="Wikipedia"
            label="Wikipedia"
            placeholder="Enter Wikipedia link..."
            value={plant.links?.wikipediaEn || ''}
            onChange={(e) =>
              update({
                links: { ...(plant.links || {}), wikipediaEn: e.target.value },
              })
            }
          />
        </PlantSection>
      </div>
    </LayoutModal>
  );
};
