import React from 'react';

const Cal = ({ color = 'green-main', size = 30, styleProps = '', ...rest }) => (
  <svg
    width={size.toString()}
    height={size.toString()}
    viewBox="0 0 75 75"
    className={`fill-${color} ${styleProps}`}
    {...rest}
  >
    <path d="M61.7184 10.1867H57.9114V3.32278C57.9114 1.49051 56.4209 0 54.5886 0C52.7563 0 51.2658 1.49051 51.2658 3.32278V10.1867H23.7342V3.32278C23.7342 1.49051 22.2437 0 20.4114 0C18.5791 0 17.0886 1.49051 17.0886 3.32278V10.1867H13.2816C5.96203 10.1867 0 16.1487 0 23.4684V61.7184C0 69.038 5.96203 75 13.2816 75H61.7184C69.038 75 75 69.038 75 61.7184V23.4684C75 16.1487 69.038 10.1867 61.7184 10.1867ZM68.3544 61.7184C68.3544 65.3734 65.3734 68.3544 61.7184 68.3544H13.2816C9.62658 68.3544 6.64557 65.3734 6.64557 61.7184V23.4684C6.64557 19.8133 9.62658 16.8323 13.2816 16.8323H17.0886V20.288C17.0886 22.1203 18.5791 23.6108 20.4114 23.6108C22.2437 23.6108 23.7342 22.1203 23.7342 20.288V16.8323H51.2658V20.288C51.2658 22.1203 52.7563 23.6108 54.5886 23.6108C56.4209 23.6108 57.9114 22.1203 57.9114 20.288V16.8323H61.7184C65.3734 16.8323 68.3544 19.8133 68.3544 23.4684V61.7184Z" />
    <path d="M58.8608 36.076H16.1393C14.307 36.076 12.8165 34.5855 12.8165 32.7532C12.8165 30.9209 14.307 29.4304 16.1393 29.4304H58.8608C60.6931 29.4304 62.1836 30.9209 62.1836 32.7532C62.1836 34.5855 60.6931 36.076 58.8608 36.076Z" />
  </svg>
);

export { Cal };
