import React from 'react';

const Water = ({
  color = 'green-main',
  size = 30,
  styleProps = '',
  ...rest
}) => (
  <svg
    width={size.toString()}
    height={size.toString()}
    viewBox="0 0 75 75"
    className={`fill-${color} ${styleProps}`}
    {...rest}
  >
    <path d="M38.0032 75C30.6266 75 23.6677 72.2753 18.2373 67.2057C12.3703 61.7279 9 53.9905 9 45.9684C9 45.5411 9.00949 45.1139 9.02848 44.6962V44.6867C9.32278 37.8608 12.2184 33.0665 15.9778 27.5032C18.4747 23.8006 21.5506 19.8228 26.326 13.6994C32.9715 5.17405 37.3291 0 41.8101 0C46.3576 0 47.3639 3.39873 48.3418 6.68354C48.7785 8.16456 49.3291 9.99684 50.2405 12.3987C52.3671 18.0095 56.6677 23.5633 59.9051 27.2468C63.038 30.807 65.2405 34.9082 66.2563 39.1044C66.9873 42.1139 67.2247 45.2278 66.9778 48.3608C65.8386 62.5253 54.2943 73.9462 40.1203 74.9335C39.4082 74.9715 38.7057 75 38.0032 75ZM15.6646 44.9715C15.6551 45.2943 15.6456 45.6266 15.6456 45.9684C15.6456 52.2437 18.1804 58.0633 22.7658 62.3449C27.3513 66.6266 33.3513 68.7342 39.6456 68.2975C50.5728 67.538 59.4684 58.7278 60.3418 47.8196C60.5411 45.3892 60.3513 42.9873 59.7911 40.6614C59.0222 37.5095 57.3418 34.3861 54.9114 31.6329C51.3038 27.5411 46.4905 21.2943 44.0127 14.7437C43.0253 12.1234 42.4082 10.0728 41.962 8.58228C41.7816 7.98418 41.5918 7.32911 41.4209 6.83544C39.076 8.12658 34.0538 14.5633 31.5475 17.7816C26.8671 23.7816 23.8481 27.6835 21.4652 31.2057C17.7057 36.8165 15.8734 40.3006 15.6646 44.9715Z" />
  </svg>
);

export { Water };
