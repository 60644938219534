import React from 'react';
import { useModelList } from '@thrivelot/hooks';
import { PageContainer } from '@thrivelot/stories';
import { HelpPrompt } from '@thrivelot/components';
import { MyCalendar } from './MyCalendar';
import { Progress } from './Progress';

const PageHome = () => {
  const { models: projects, loaded } = useModelList({ modelName: 'Project' });

  return (
    <PageContainer
      icon="Home"
      header="Home"
      documentTitle="Home"
      help={
        <HelpPrompt header="About your Projects page">
          This is a list view of your open and active projects on the thrive lot
          platform. You can see the stage, how many service providers are
          assigned to a project, and when it was last updated.
        </HelpPrompt>
      }
    >
      <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
        <div className="md:col-span-2">
          <MyCalendar projects={projects} isLoaded={loaded} />
        </div>
        <div className="">
          <Progress projects={projects} isLoaded={loaded} />
        </div>
      </div>
    </PageContainer>
  );
};

export { PageHome };
