import { awsPlantsConfig, gql } from '@thrivelot/aws';
import { actions } from './actions';

class PlantClient {
  readonly endpoint = awsPlantsConfig.aws_appsync_graphqlEndpoint;

  readonly queryNameRegex = /\b([a-z]+[A-Z][a-zA-Z]*)\(/m; // Used for plucking the query/mutation name out of the gql string for parsing the response

  readonly queries = {
    create: gql.createPlant,
    get: gql.getPlant,
    update: gql.updatePlant,
    delete: gql.deletePlant,
    list: gql.listPlants,
    search: gql.searchPlants,
  };

  readonly subscriptions = {
    onCreate: gql.onCreatePlant,
    onUpdate: gql.onUpdatePlant,
    onDelete: gql.onDeletePlant,
  };

  readonly actionsFactory = actions;

  readonly apiKey = awsPlantsConfig.aws_appsync_apiKey;

  readonly bucket = awsPlantsConfig.aws_user_files_s3_bucket;

  readonly region = awsPlantsConfig.aws_user_files_s3_bucket_region;

  fetchCredentials?: () => Promise<any>;

  setFetchCredentials(fetchCredentials: () => Promise<any>) {
    this.fetchCredentials = fetchCredentials;
  }

  constructor(fetchCredentials?: () => Promise<any>) {
    if (fetchCredentials) this.fetchCredentials = fetchCredentials;
  }
}

export const plantClient = new PlantClient();
