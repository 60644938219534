import styled from "styled-components";
import CssDivDefault from "./CssDivDefault";

const DivContentRow = styled.div`
  ${CssDivDefault}
  display: flex;
  flex-direction: row;
  padding: 8px 0px;
  width: 100%;
  min-height: fit-content;
  max-height: fit-content;
`;

export default DivContentRow;
