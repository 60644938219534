import { get } from "@thrivelot/actions";

const getFilePrev = (model, fileId) => {
  const files = get(model, "files");
  const index = files.findIndex((file) => file.id === fileId);
  if (index === 0) {
    return files[files.length - 1];
  }
  return files[index - 1];
};

export { getFilePrev };
