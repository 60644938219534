import styled from "styled-components";
import CssDivDefault from "./CssDivDefault";

const DivListRow = styled.div`
  ${CssDivDefault}
  display: flex;
  flex-direction: row;
  padding: 20px 0px 0px;
  width: 100%;
  min-height: fit-content;
  max-height: fit-content;
`;

export default DivListRow;
