/* eslint-disable indent */
import React from "react";
import styled, { css } from "styled-components";
import CssRaisedShadow from "./CssRaisedShadow";
import CssInsetShadow from "./CssInsetShadow";
import TextareaAutosize from "react-textarea-autosize";

const CSSTextarea = css`
  ${({ isFlat }) => !isFlat && CssInsetShadow}
  ${({ isFlat }) =>
    !isFlat &&
    css`
      border-radius: 5px;
      padding: 5px;
    `}
  @media (max-width: 767px) {
    font-size: 14px;
  }
  @media (min-width: 768px) {
    font-size: 17px;
  }
  transition: opacity 0.5s ease-out, box-shadow 0.5s ease-out, color 1s ease-in-out, background-color 1s ease-in-out;
  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  -webkit-appearance: none;
  border: 0px none transparent;
  resize: none;
  font-family: Rokkitt-Bold;
  background-color: ${({ theme }) => theme.inputBackground};
  caret-color: ${({ theme }) => theme.inputTintColor};
  ::selection {
    background-color: ${({ theme }) => theme.inputTintColor};
    color: ${({ theme }) => theme.inputSelectedText};
  }
  blockquote::selection {
    background-color: ${({ theme }) => theme.inputTintColor};
    color: ${({ theme }) => theme.inputSelectedText};
  }
  ::placeholder {
    color: ${({ theme }) => theme.inputPlaceholderText};
    opacity: 1;
  }
  :-ms-input-placeholder {
    color: ${({ theme }) => theme.inputPlaceholderText};
  }
  ::-ms-input-placeholder {
    color: ${({ theme }) => theme.inputPlaceholderText};
  }
  :-webkit-autofill {
    background-color: ${({ theme }) => theme.inputTintColor};
    color: ${({ theme }) => theme.inputSelectedText};
  }
  color: ${({ theme }) => theme.inputText};
  :focus {
    ${({ isFlat }) => !isFlat && CssRaisedShadow}
    outline: none !important;
    background-color: ${({ theme }) => theme.inputBackgroundFocused};
  }
`;

const StyledTextarea = styled.textarea`
  ${CSSTextarea}
`;

const StyledTextareaAutosize = styled(TextareaAutosize)`
  ${CSSTextarea}
`;

const Textarea = ({ autosize, ...rest }) => {
  if (autosize) {
    return <StyledTextareaAutosize {...rest} />;
  }

  return <StyledTextarea {...rest} />;
};

export default Textarea;
