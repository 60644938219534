import React from "react";
import { useTheme } from "@thrivelot/hooks";
import { DivColumn, TextHeader } from "@thrivelot/styles";
import { useInnerSpacing, useSupplierNameHeight } from "../../../../hooks";
import "./Name.css";

const Name = ({ supplierActions }) => {
  // hooks
  const theme = useTheme();
  const innerSpacing = useInnerSpacing();
  const height = useSupplierNameHeight();
  // actions
  const firstname = supplierActions.get("firstname");
  const lastname = supplierActions.get("lastname");
  const businessName = supplierActions.get("businessName");
  // utils
  const isValidBusinessName = () => businessName && businessName.trim() !== "";
  // constants
  const name = isValidBusinessName() ? `${businessName}` : `${firstname} ${lastname}`;
  // return
  return (
    <DivColumn
      style={{
        paddingLeft: innerSpacing,
        paddingRight: innerSpacing,
        minHeight: height,
        maxHeight: height,
        justifyContent: "center",
      }}
    >
      <TextHeader className="text" style={{ color: theme.textTitle, marginTop: "-3px" }}>
        {name}
      </TextHeader>
    </DivColumn>
  );
};

export { Name };
