import React, { useCallback, useEffect, useState } from "react";
import styled, { css } from "styled-components";
import { useFetchFileUrl } from "@thrivelot/common";
import CssImageDefault from "./CssImageDefault";

const heightConstructor = ({ size, height }) => `${size || height}px`;
const widthConstructor = ({ size, width }) => `${size || width}px`;

const Image = styled.img`
  overflow: hidden;
  object-position: 50% 50%;
  object-fit: cover;
  ${({ noRounding }) =>
    !noRounding &&
    css`
      border-radius: 5px;
    `}
  min-height: ${heightConstructor};
  max-height: ${heightConstructor};
  min-width: ${widthConstructor};
  max-width: ${widthConstructor};
  ${({ disabled, hoverable }) =>
    !disabled &&
    hoverable &&
    css`
      cursor: pointer;
      transition: opacity 0.5s ease-out;
      &:hover {
        opacity: 0.7;
      }
    `}
  ${CssImageDefault}
`;

const ImgRectangle = ({ src: srcObj, size, imgType, onLoadError, ...rest }) => {
  const [src, setSrc] = useState(
    "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkqAcAAIUAgUW0RjgAAAAASUVORK5CYII="
  );

  const fetchUrl = useFetchFileUrl();

  useEffect(() => {
    let didCancel;

    fetchUrl(srcObj).then((data) => {
      if (!didCancel) setSrc(data);
    });

    return () => {
      didCancel = true;
    };
  }, [srcObj, fetchUrl]);

  const onLoad = useCallback((event) => {
    event.target.classList.add("loaded");
  }, []);

  const onError = useCallback(
    (event) => {
      event.target.classList.add("has-error");

      if (onLoadError) onLoadError();
    },
    [onLoadError]
  );

  return <Image src={src} alt="" onLoad={onLoad} onError={onError} size={size} {...rest} />;
};

export default ImgRectangle;
