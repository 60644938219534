import React from "react";
import { ColumnsContainer } from "./ColumnsContainer";
import { LeftColumn } from "./LeftColumn";
import { MiddleColumn } from "./MiddleColumn";
import { RightColumn } from "./RightColumn";

const Columns = ({ title, memo, Icon, cost, totalCost }) => (
  <ColumnsContainer>
    <LeftColumn title={title} memo={memo} Icon={Icon} />
    <MiddleColumn cost={cost} />
    <RightColumn cost={totalCost} />
  </ColumnsContainer>
);

export { Columns };
