import { useCallback } from "react";

const useMarker = () => {
  const handleMarker = useCallback(() => {
    const mode = "fullscreen";
    window.Marker.capture(mode);
  }, []);

  return { handleMarker };
};

export { useMarker };
