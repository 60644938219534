import React from 'react';

const Cursor = ({
  color = 'green-main',
  size = 30,
  styleProps = '',
  ...rest
}) => (
  <svg
    width={size.toString()}
    height={size.toString()}
    viewBox="0 0 75 75"
    className={`fill-${color} ${styleProps}`}
    {...rest}
  >
    <path d="M25.9618 75C23.6058 74.9941 21.3187 74.2037 19.4617 72.7538C17.6046 71.3039 16.2832 69.2769 15.706 66.9926L2.3109 13.0834C1.83559 11.1698 1.90644 9.16131 2.51545 7.28598C3.12446 5.41066 4.24718 3.74372 5.75609 2.47451C7.26501 1.2053 9.09968 0.384699 11.0516 0.105913C13.0035 -0.172872 14.9945 0.101341 16.7984 0.897398L67.5789 23.3074C69.5138 24.1638 71.1465 25.5821 72.2652 27.3781C73.3838 29.1741 73.9368 31.265 73.8522 33.3792C73.7675 35.4935 73.0493 37.5335 71.7907 39.2344C70.5321 40.9352 68.7912 42.2185 66.7941 42.9175L47.0355 49.8113C46.3124 50.0671 45.706 50.5756 45.328 51.2431L35.1464 69.538C34.2454 71.188 32.9175 72.5654 31.3018 73.5263C29.686 74.4872 27.8417 74.9962 25.9618 75V75ZM9.51226 11.3653L22.9074 65.2321C23.0568 65.8417 23.3866 66.392 23.8538 66.8111C24.321 67.2302 24.9038 67.4985 25.5259 67.581C26.1481 67.6635 26.7808 67.5563 27.341 67.2735C27.9013 66.9906 28.3632 66.5453 28.6662 65.9957L38.8478 47.7113C40.1071 45.4539 42.1475 43.7341 44.5855 42.8751L64.3547 35.9813C64.9425 35.7708 65.4539 35.3892 65.823 34.8857C66.1921 34.3822 66.4022 33.7798 66.4262 33.1559C66.4501 32.532 66.2868 31.9152 65.9574 31.3849C65.6279 30.8546 65.1473 30.4349 64.5774 30.18L13.8075 7.78054C13.2757 7.55628 12.6921 7.48401 12.1216 7.57181C11.5512 7.65961 11.0164 7.90402 10.5766 8.27782C10.1368 8.65162 9.80941 9.14017 9.63085 9.68902C9.4523 10.2379 9.42956 10.8255 9.56522 11.3865L9.51226 11.3653Z" />
  </svg>
);

export { Cursor };
