import React from 'react';

const Camera = ({
  color = 'green-main',
  size = 30,
  styleProps = '',
  ...rest
}) => (
  <svg
    width={size.toString()}
    height={size.toString()}
    viewBox="0 0 75 75"
    className={`fill-${color} ${styleProps}`}
    {...rest}
  >
    <path d="M60.5696 68.3291H14.4304C6.4557 68.3291 0 62.538 0 55.5127V30.7342C0 23.7089 6.4557 17.9177 14.4304 17.9177H17.1835C18.2278 11.7468 24.3038 7 31.519 7H43.481C50.6962 7 56.7722 11.7468 57.8165 17.9177H60.5696C68.5443 17.9177 75 23.7089 75 30.7342V55.5127C75 62.538 68.5443 68.3291 60.5696 68.3291ZM14.4304 24.5633C10.1582 24.5633 6.64557 27.3165 6.64557 30.7342V55.5127C6.64557 58.9304 10.1582 61.6835 14.4304 61.6835H60.5696C64.8418 61.6835 68.3544 58.9304 68.3544 55.5127V30.7342C68.3544 27.3165 64.8418 24.5633 60.5696 24.5633H54.5886C52.7848 24.5633 51.2658 23.1392 51.2658 21.2405V19.8165C51.2658 16.3987 47.7532 13.6456 43.481 13.6456H31.519C27.2468 13.6456 23.7342 16.3987 23.7342 19.8165V21.2405C23.7342 23.0443 22.2152 24.5633 20.4114 24.5633H14.4304Z" />
    <path d="M37.4997 57.1266C29.1453 57.1266 22.4048 50.3861 22.4048 42.0317C22.4048 33.6773 29.1453 26.9368 37.4997 26.9368C45.8542 26.9368 52.5947 33.6773 52.5947 42.0317C52.5947 50.3861 45.8542 57.1266 37.4997 57.1266ZM37.4997 29.7849C30.7592 29.7849 25.2529 35.2912 25.2529 42.0317C25.2529 48.7722 30.7592 54.2785 37.4997 54.2785C44.2402 54.2785 49.7466 48.7722 49.7466 42.0317C49.7466 35.2912 44.2402 29.7849 37.4997 29.7849Z" />
    <path d="M13.1011 35.766C15.4606 35.766 17.3733 33.8533 17.3733 31.4938C17.3733 29.1344 15.4606 27.2217 13.1011 27.2217C10.7417 27.2217 8.82898 29.1344 8.82898 31.4938C8.82898 33.8533 10.7417 35.766 13.1011 35.766Z" />
  </svg>
);

export { Camera };
