import React from 'react';
import PropTypes from 'prop-types';

export const Card = ({
  header,
  headerActions,
  subheader,
  children,
  footer,
  classAppend,
}) => (
  <div
    className={`break-inside-avoid flex flex-col rounded-main bg-white-light shadow ${
      classAppend && classAppend
    }`}
  >
    {header && (
      <div className="px-4 py-3 flex justify-between items-center border-b border-tan-light">
        <div>
          <h3 className="text-brown-dark text-xl font-bold">{header}</h3>
          {subheader && (
            <h4 className="text-brown-dark font-bold">{subheader}</h4>
          )}
        </div>
        {headerActions && (
          <div className="flex items-center">{headerActions}</div>
        )}
      </div>
    )}
    <div className="p-4 flex-1">{children}</div>
    {footer && <div className="p-4 border-t border-tan-light">{footer}</div>}
  </div>
);

Card.propTypes = {
  /**
   * Main body of card (padded)
   */
  children: PropTypes.node,

  /**
   * Header string
   */
  header: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),

  /**
   * Subheader string
   */
  subheader: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),

  /**
   * Actions for the right-most section of the header
   */
  headerActions: PropTypes.node,

  /**
   * Footer can be a full Component
   */
  footer: PropTypes.node,

  /**
   * Classes to append to outermost div
   */
  classAppend: PropTypes.string,
};
