import styled from "styled-components";
import CssRaisedShadow from "./CssRaisedShadow";

const mobileTransform = ({ isOpen }) => `translate3d(${isOpen ? "0px" : "-100vw"}, 0, 0)`;

const desktopTransform = ({ isOpen }) => `translate3d(${isOpen ? "0" : "-210"}px, 0, 0)`;

const DivMenu = styled.div`
  ${CssRaisedShadow}
  position: fixed;
  top: 50px;
  left: 0px;
  height: calc(100vh - 50px);
  transition: all 0.5s ease-out 0s;
  overflow: hidden;
  background-color: ${({ theme }) => theme.foreground};
  @media (max-width: 767px) {
    width: 100vw;
    transform: ${mobileTransform};
  }
  @media (min-width: 768px) {
    width: 210px;
    transform: ${desktopTransform};
  }
`;

export default DivMenu;
