import React from 'react';
import { useRouter } from '@thrivelot/common';
import { TeamCardContainer } from './TeamCardContainer';
import { ProfilePic } from './ProfilePic';
import { Name } from './Name';

const TeamCard = ({ supplierActions }) => {
  // hooks
  const { history, urlHashes, urlModalValues } = useRouter();
  // navigation
  const openSupplierModal = () => {
    const supplierId = supplierActions.get('id');
    history.push({
      hash: {
        [urlHashes.proposalModal]: urlModalValues.proposalSupplier,
        [urlHashes.supplierId]: supplierId,
      },
    });
  };
  return (
    <TeamCardContainer onClick={openSupplierModal}>
      <ProfilePic supplierActions={supplierActions} />
      <Name supplierActions={supplierActions} />
    </TeamCardContainer>
  );
};

export { TeamCard };
