import awsConfig from './aws-exports';

export const getAmplifyEnvFromBucketName = (bucketName) => {
  const envStart = bucketName.lastIndexOf('-');
  return bucketName.slice(envStart + 1);
};

const amplifyEnv = getAmplifyEnvFromBucketName(
  awsConfig.aws_user_files_s3_bucket
);

export { amplifyEnv };
