import styled from "styled-components";

const FileContainer = styled.div`
  position: relative;
  overflow: hidden;
  display: flex;
  cursor: pointer;
  transition: opacity 0.5s ease-out, box-shadow 0.5s ease-out, color 1s ease-in-out, background-color 1s ease-in-out;
  &:hover {
    opacity: 0.7;
  }
  @media (max-width: 150px) {
    width: 100%;
    padding-bottom: 100%;
  }
  @media (max-width: 300px) and (min-width: 151px) {
    width: 50%;
    padding-bottom: 50%;
  }
  @media (max-width: 600px) and (min-width: 301px) {
    width: 33.3%;
    padding-bottom: 33.3%;
  }
  @media (max-width: 900px) and (min-width: 601px) {
    width: 25%;
    padding-bottom: 25%;
  }
  @media (min-width: 901px) {
    width: 20%;
    padding-bottom: 20%;
  }
`;

export { FileContainer };
