import React from 'react';

const Lock = ({
  color = 'green-main',
  size = 30,
  styleProps = '',
  ...rest
}) => (
  <svg
    width={size.toString()}
    height={size.toString()}
    viewBox="0 0 75 75"
    className={`fill-${color} ${styleProps}`}
    {...rest}
  >
    <path d="M62.1835 25.6329C62.1835 25.3481 62.1835 24.9684 62.1835 24.6835C62.1835 11.1076 51.076 0 37.5 0C23.9241 0 12.8165 11.1076 12.8165 24.6835C12.8165 24.9684 12.8165 25.3481 12.8165 25.6329C5.6962 25.9177 0 31.7089 0 38.9241V61.7089C0 69.019 5.98101 75 13.2911 75H61.7089C69.019 75 75 69.019 75 61.7089V38.9241C75 31.7089 69.3038 25.9177 62.1835 25.6329ZM37.5 6.64557C47.4684 6.64557 55.538 14.7152 55.538 24.6835C55.538 24.9684 55.538 25.3481 55.538 25.6329H19.462C19.462 25.3481 19.462 24.9684 19.462 24.6835C19.462 14.7152 27.5316 6.64557 37.5 6.64557ZM68.3544 61.7089C68.3544 65.3165 65.4114 68.3544 61.7089 68.3544H13.2911C9.68354 68.3544 6.64557 65.4114 6.64557 61.7089V38.9241C6.64557 35.3165 9.58861 32.2785 13.2911 32.2785H61.7089C65.3165 32.2785 68.3544 35.2215 68.3544 38.9241V61.7089Z" />
    <path d="M37.4998 59.0506C32.658 59.0506 28.7656 55.1582 28.7656 50.3165C28.7656 45.4747 32.658 41.5823 37.4998 41.5823C42.3416 41.5823 46.234 45.4747 46.234 50.3165C46.234 55.1582 42.3416 59.0506 37.4998 59.0506ZM37.4998 46.3291C35.3163 46.3291 33.5125 48.1329 33.5125 50.3165C33.5125 52.5 35.3163 54.3038 37.4998 54.3038C39.6833 54.3038 41.4871 52.5 41.4871 50.3165C41.4871 48.1329 39.6833 46.3291 37.4998 46.3291Z" />
  </svg>
);

export { Lock };
