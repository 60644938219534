import styled from "styled-components";

const DivProjectContainer = styled.div`
  transition: opacity 0.5s ease-out, box-shadow 0.5s ease-out, color 1s ease-in-out, background-color 1s ease-in-out;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.foreground};
  flex: 1;
  position: absolute;
  top: 0px;
  right: 0px;
  @media (max-width: 767px) {
    bottom: 60px;
    left: 0px;
  }
  @media (min-width: 768px) {
    bottom: 0px;
    left: 60px;
  }
`;

export default DivProjectContainer;
