import { createContext } from 'react';

export interface IPlantsState {
  [id: string]: {
    model: { [key: string]: any };
    draft?: { [key: string]: any };
    loading?: boolean;
    saving?: boolean;
  };
}

export interface IPlantsContext {
  state: IPlantsState;
  init: (plant: { [key: string]: any }) => void;
  get: (id: string, force?: boolean) => Promise<void>;
  remove: (id: string) => Promise<void>;
  update: (id: string, updated: { [key: string]: any }) => void;
}

export const PlantsContext = createContext<IPlantsContext>({
  state: {},
  init: () => null,
  get: () => Promise.resolve(),
  remove: () => Promise.resolve(),
  update: () => null,
});
