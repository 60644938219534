import React from "react";
import { useModel } from "@thrivelot/hooks";
import { LeafLoader } from "@thrivelot/stories";

const HomeInfoBox = ({ projectId }) => {
  const { actions, loaded } = useModel({ modelName: "Project", id: projectId });

  if (!loaded) return <LeafLoader />;

  return <div className="font-normal">{actions.getAddressText()}</div>;
};

export { HomeInfoBox };
