import React, { forwardRef } from "react";
import { useIsMobile } from "@thrivelot/hooks";
import { DateEndIcon, DateStartIcon } from "@thrivelot/styles";

const DateIcon = forwardRef((props, ref) => {
  const theme = window.$theme;

  const isMobile = useIsMobile();

  const customProps = {
    hoverable: true,
    color: theme.blue,
    size: isMobile ? 15 : 20,
  };

  return props.isStart ? (
    <DateStartIcon ref={ref} {...customProps} {...props} style={{ maxWidth: "fit-content" }} />
  ) : (
    <DateEndIcon ref={ref} {...customProps} {...props} style={{ maxWidth: "fit-content" }} />
  );
});

export { DateIcon };
