import styled from "styled-components";
import CssRaisedShadow from "./CssRaisedShadow";

const DivNavBar = styled.div`
  transition: opacity 0.5s ease-out, box-shadow 0.5s ease-out, color 1s ease-in-out, background-color 1s ease-in-out;
  ${CssRaisedShadow}
  display: flex;
  position: fixed;
  overflow: hidden;
  top: 0px;
  right: 0px;
  left: 0px;
  max-width: 100%;
  padding: 0px 12px;
  height: 50px;
  align-items: center;
  justify-content: space-between;
  background-color: ${({ theme }) => theme.foreground};
`;

export default DivNavBar;
