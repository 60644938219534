import { colors } from './colors';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const chroma = require('chroma-js');

const theme = {
  // general
  isDarkMode: false,
  background: colors.white,
  foreground: colors.white,
  // colors
  blue: colors.blue,
  green: colors.green,
  orange: colors.orange,
  purple: colors.purple,
  // button
  buttonDefault: colors.blue,
  buttonDone: colors.darkGreen,
  buttonDanger: colors.red,
  buttonAdd: colors.purple,
  buttonCancel: colors.lightBrown,
  // div
  divLine: chroma(colors.lightBrown).alpha(0.5).css(),
  divLineDark: chroma(colors.darkGreen).alpha(0.3).css(),
  // input
  inputBackground: colors.white,
  inputBackgroundFocused: colors.tan,
  inputTintColor: colors.green,
  inputPlaceholderText: chroma(colors.brown).alpha(0.6).css(),
  inputSelectedText: colors.white,
  inputText: colors.brown,
  inputTextTitle: colors.black,
  inputUnderline: colors.brown,
  // text
  text: colors.brown,
  textLabel: colors.darkGreen,
  textLight: colors.white,
  textTitle: colors.darkBrown,
  // shadow
  shadowDiv1: colors.lightShadow1,
  shadowDiv2: colors.lightShadow2,
  shadowButton: colors.lightButtonShadow,
  // specific styling
  lineItem: {
    material: colors.brown,
    labor: colors.blue,
    equipment: colors.orange,
  },
  projectStage: {
    openProject: colors.lightRed,
    assigned: colors.orange,
    siteAssessment: colors.green,
    detailedPlan: colors.darkGreen,
    installation: colors.brown,
    stewardship: colors.purple,
  },
  zoneMap: {
    _0: colors.lightPink,
    _1: colors.lightPurple,
    _2: colors.lightGreen,
    _3: colors.lightBlue,
    _4: colors.lightOrange,
    _5: colors.lightYellow,
  },
  zoneText: {
    _0: colors.pink,
    _1: colors.purple,
    _2: colors.green,
    _3: colors.blue,
    _4: colors.orange,
    _5: colors.yellow,
  },
};

export { theme };
