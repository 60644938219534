import React from 'react';
import { PageContainer } from '@thrivelot/stories';
import { useAuth } from '@thrivelot/hooks';
import {
  CardSupplierAbout,
  CardSupplierPersonal,
  CardSupplierProjectTypes,
  CardSupplierQualifications,
  CardSupplierSocials,
  HelpPrompt,
} from '@thrivelot/components';

const PageProfile = () => {
  const { userId: supplierId } = useAuth();

  return (
    <PageContainer
      icon="User"
      header="Profile"
      documentTitle="Profile"
      help={
        <HelpPrompt header="About your Profile page">
          This is your Ecosystems Specialist profile page. Here, you can edit
          and update your profile that Customers will see alongside every
          Proposal you send.
        </HelpPrompt>
      }
    >
      <div className="columns-1 md:columns-2 lg:columns-3">
        <CardSupplierPersonal supplierId={supplierId} />
        <CardSupplierAbout supplierId={supplierId} />
        <CardSupplierSocials supplierId={supplierId} />
        <CardSupplierQualifications supplierId={supplierId} />
        <CardSupplierProjectTypes supplierId={supplierId} />
      </div>
    </PageContainer>
  );
};

export { PageProfile };
