import React, { useCallback, useMemo } from "react";
import { Marker } from "@react-google-maps/api";
import { useMap, useModel, useQueryParam } from "@thrivelot/hooks";
import { getHomeIcon } from "./getHomeIcon";

const MapProjectElementHome = ({ location, projectId }) => {
  const { actions, update } = useModel({ modelName: "Project", id: projectId });
  const { toggleInfoBox } = useMap();
  const { param } = useQueryParam("map");

  const { zoneEditingId, addZone } = useMemo(() => param || {}, [param]);

  const onDragEnd = useCallback(
    (event) => {
      const location = { lat: event.latLng.lat(), lng: event.latLng.lng() };
      update(actions.set("details.address.location", location).result);
    },
    [update, actions]
  );

  const onClick = useCallback(() => {
    if (!zoneEditingId && !addZone) {
      toggleInfoBox("address");
    }
  }, [toggleInfoBox, zoneEditingId, addZone]);

  const options = {
    icon: getHomeIcon(),
    optimized: false,
  };

  return (
    <Marker
      draggable={!zoneEditingId && !addZone}
      onDragEnd={onDragEnd}
      position={location}
      labelContent="Home"
      options={options}
      onClick={onClick}
    />
  );
};

export { MapProjectElementHome };
