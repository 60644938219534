import { useRef } from "react";
import { useAuth, useModel } from "@thrivelot/hooks";
import { isAdminApp } from "@thrivelot/utils";
import { getModelIdFromCurrentUrl } from "../helpers";

const getModelName = () => (window.appConfig.appName === "customer" ? "Customer" : "Supplier");

const useModelUser = (
  props = { modelName: undefined, path: undefined, deferLoad: false, readOnly: false, idFallbackToCurrentUrl: true }
) => {
  // state hooks

  const { userId } = useAuth();

  // ref hooks

  const modelNameRef = useRef(props.modelName || getModelName());
  const idRef = useRef(isAdminApp() ? getModelIdFromCurrentUrl(modelNameRef.current) : userId);
  const idFallbackToCurrentUrlRef = useRef(props.idFallbackToCurrentUrl);
  if (!idRef.current && idFallbackToCurrentUrlRef.current) {
    idRef.current = getModelIdFromCurrentUrl(modelNameRef.current);
  }

  return useModel({
    modelName: modelNameRef.current,
    id: idRef.current,
    path: props.path,
    deferLoad: props.deferLoad,
    readOnly: props.readOnly,
  });
};

export { useModelUser };
