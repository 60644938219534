import React, { useState } from "react";
import { LayoutModal } from "../..";
import { useAuth, useModal, useTimer } from "@thrivelot/hooks";
import { transformStringToTitleCase } from "@thrivelot/utils";
import { Button } from "@thrivelot/stories";
import { formatPhoneNumber } from "react-phone-number-input";

const ModalVerifyUserAttribute = ({ onClose, attribute }) => {
  const { openModal } = useModal();
  const [loadingSend, setLoadingSend] = useState(false);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [code, setCode] = useState("");
  const [errors, setErrors] = useState([]);
  const { user, sendUserAttributeVerificationCode, verifyUserAttribute } = useAuth();
  const { timeRemaining, start, stop } = useTimer();

  const handleSend = async () => {
    try {
      setLoadingSend(true);
      await sendUserAttributeVerificationCode(attribute);
      start();
      setLoadingSend(false);
    } catch (e) {
      if (e.name === "LimitExceededException")
        setErrors([
          "Too many verification codes sent in a short time. Please try again later. This limitation helps keep your account secure.",
        ]);
      setLoadingSend(false);
    }
  };

  const handleSubmit = async () => {
    setLoadingSubmit(true);
    await verifyUserAttribute(attribute, code);
    stop();
    setLoadingSubmit(false);
    onClose();
  };

  const resendLimit = 60;
  const secondsRemainaing = (timeRemaining || 0) + resendLimit;
  const prettyAttribute =
    attribute === "phone_number" ? formatPhoneNumber(user.attributes[attribute]) : user.attributes[attribute];

  return (
    <LayoutModal width="md" onClose={onClose} header={`Verify ${transformStringToTitleCase(attribute)}`}>
      <div className="flex flex-col gap-4">
        <div className="text-brown-dark text-sm">
          Your {transformStringToTitleCase(attribute)} is <span className="font-bold">{prettyAttribute}</span>. Click the
          button below to send a verification code to <span className="font-bold">{prettyAttribute}</span>.
        </div>

        <div
          className="text-blue-main font-bold text-sm hover:cursor-pointer hover:text-blue-light"
          onClick={() => openModal({ path: "ModalEditAccount" })}
        >
          {transformStringToTitleCase(attribute)} is not {prettyAttribute} ? Click here
        </div>

        <div className="mb-2 pb-6 border-b border-tan-light">
          <Button
            disabled={loadingSend || (secondsRemainaing > 0 && secondsRemainaing !== resendLimit)}
            loading={loadingSend}
            block
            kind="outline"
            color="blue-main"
            label={
              secondsRemainaing > 0 && secondsRemainaing !== resendLimit
                ? `Resend in ${secondsRemainaing}s`
                : "Send Code"
            }
            onClick={handleSend}
          />
        </div>

        <div className="mb-4">
          <div className="text-green-dark text-sm font-bold mb-1">Code</div>
          <input
            disabled={secondsRemainaing === resendLimit}
            className="input"
            placeholder="Enter code here..."
            value={code}
            onChange={(e) => setCode(e.target.value)}
          />
        </div>
      </div>
      {errors.length > 0 && (
        <div className="mb-4">
          {errors.map((error) => (
            <div key={error} className="text-red-main text-sm">
              {error}
            </div>
          ))}
        </div>
      )}
      <Button
        loading={loadingSubmit}
        disabled={loadingSubmit || secondsRemainaing === resendLimit}
        block
        kind="outline"
        color="green-main"
        label="Submit Code"
        onClick={handleSubmit}
      />
    </LayoutModal>
  );
};

export { ModalVerifyUserAttribute };
