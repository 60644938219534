import styled from "styled-components";
import CssRaisedShadow from "./CssRaisedShadow";
import CssDivDefault from "./CssDivDefault";

const sizeConstructor = ({ size }) => `${size}px`;

const DivSquareRaised = styled.div`
  ${CssDivDefault}
  ${CssRaisedShadow}
  border-radius: 5px;
  min-width: ${sizeConstructor};
  max-width: ${sizeConstructor};
  min-height: ${sizeConstructor};
  max-height: ${sizeConstructor};
`;

export default DivSquareRaised;
