import React from "react";
import { TLGoldBadgeIcon } from "@thrivelot/styles";
import { Button } from "@thrivelot/stories";
import { Link } from "react-router-dom";

const PageUnchartedLands = () => (
  <div className="flex flex-col justify-center items-center pt-20">
    <TLGoldBadgeIcon size={90} />
    <div className="text-brown-dark mt-5 text-center flex flex-col items-center">
      <div>Oops! These are uncharted lands!</div>
      <div>Navigate using the menu.</div>
      <div className="py-2 my-3">- or -</div>
      <Link to="/">
        <Button icon="Leaf" kind="outline" label="Head back home" color="green-main" />
      </Link>
    </div>
  </div>
);

export { PageUnchartedLands };
