import styled from "styled-components";
import CssDivDefault from "./CssDivDefault";

const DivWrap = styled.div`
  ${CssDivDefault}
  display: flex;
  flex-wrap: wrap;
`;

export default DivWrap;
