import React, { useEffect, useState } from 'react';
import { Plus, LeafLoader } from '@thrivelot/stories';
import { useModelList } from '@thrivelot/hooks';
import { ServiceProvider } from '../../CardProjectServiceProviders/ServiceProvider';

const SelectServiceProviders = ({ onSelect, selectedOwnerIds }) => {
  const [queryString, setQueryString] = useState('');
  const [filteredSuppliers, setFilteredSuppliers] = useState([]);
  const { models, loading } = useModelList({ modelName: 'Supplier' });

  useEffect(() => {
    if (queryString) {
      setFilteredSuppliers(() =>
        models
          .filter(
            ({ businessName, firstname, lastname, email, owners }) =>
              ((businessName || '')
                .toLowerCase()
                .includes(queryString.toLowerCase()) ||
                (firstname || '')
                  .toLowerCase()
                  .includes(queryString.toLowerCase()) ||
                (lastname || '')
                  .toLowerCase()
                  .includes(queryString.toLowerCase()) ||
                (email || '')
                  .toLowerCase()
                  .includes(queryString.toLowerCase())) &&
              !(selectedOwnerIds || []).includes(owners[0])
          )
          .slice(0, 5)
      );
    } else {
      setFilteredSuppliers([]);
    }
  }, [models, queryString, selectedOwnerIds]);

  if (loading)
    return (
      <div className="flex mt-4">
        <LeafLoader />
      </div>
    );

  return (
    <div className="mt-4">
      <input
        className="input flex-1 mr-2"
        placeholder="Search for a supplier to add..."
        onChange={(e) => setQueryString(e.target.value)}
      />
      {filteredSuppliers.length > 0 && (
        <div className="mt-4 flex flex-col gap-2">
          {filteredSuppliers.map((filteredSupplier) => (
            <ServiceProvider
              key={filteredSupplier.id}
              supplier={filteredSupplier}
              renderDetails={(supplier) => (
                <Plus
                  color="green-main"
                  size="20"
                  styleProps="hover:cursor-pointer"
                  onClick={() =>
                    onSelect(supplier.owners[0], filteredSupplier.id)
                  }
                />
              )}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export { SelectServiceProviders };
