import { getFileType } from "./getFileType";

const formatFile = (file) => {
  const { id, file: src, caption } = file;
  const { filename: name, type: fileType } = src;
  const type = getFileType(fileType);
  return { id, name, caption, src, type };
};

export { formatFile };
