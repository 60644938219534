import React from "react";
import { DivPageContainer } from "@thrivelot/styles";
import { useSectionHeaders, useContentStyle } from "../hooks";
import { Section } from "./Section";

const Content = () => {
  // hooks
  const sections = useSectionHeaders();
  const contentStyle = useContentStyle();
  // return
  return (
    <DivPageContainer style={contentStyle}>
      {sections.map((section) => (
        <Section key={section.replace(" ", "-")} section={section} />
      ))}
    </DivPageContainer>
  );
};

export { Content };
