import React from "react";
import { useCalendarProvider } from "./useCalendarProvider";
import { CalendarContext } from "./CalendarContext";

const CalendarProvider = ({ selectedDate = new Date(), events = [], notes = [], children }) => {
  const calendar = useCalendarProvider({ selectedDate, events, notes });
  return <CalendarContext.Provider value={calendar}>{children}</CalendarContext.Provider>;
};

export { CalendarProvider };
