import React, { useCallback } from 'react';
import { useModel } from '@thrivelot/hooks';
import { Checkmark, Minus } from '@thrivelot/stories';
import { updateCustomerPermissionsFromProjectPermissions } from '@thrivelot/common';
import { ModelKit } from '@thrivelot/model-kit';
import { LayoutModal } from '../..';
import { SelectServiceProviders } from './SelectServiceProviders';
import { ServiceProviderFromOwnerId } from '../../CardProjectServiceProviders/ServiceProviderFromOwnerId';

const ModalEditServiceProviders = ({ onClose, projectId }) => {
  const {
    model: project,
    loaded,
    actions,
    updateModel,
  } = useModel({ modelName: 'Project', id: projectId });

  const { owners } = project || {};

  // const handleUpdateInvoiceProposalBundlesPayeeId = useCallback(
  //   (ownerId, supplierId) =>
  //     Promise.all(
  //       (project.invoiceProposalIds || []).map(async (invoiceProposalId) => {
  //         const invoiceProposalKit = new ModelKit({
  //           modelName: 'InvoiceProposal',
  //           id: invoiceProposalId,
  //         });

  //         const invoiceProposal = await invoiceProposalKit.query();

  //         await Promise.all(
  //           invoiceProposal.bundleIds.map(async (bundleId) => {
  //             const bundleKit = new ModelKit({
  //               modelName: 'Bundle',
  //               id: bundleId,
  //             });

  //             await bundleKit.update({
  //               updated: { supplierPayeeId: supplierId },
  //             });
  //           })
  //         );
  //       })
  //     ),
  //   [project]
  // );

  const handleSelect = useCallback(
    async (ownerId, supplierId) => {
      // if (owners.length === 0)
      //   await handleUpdateInvoiceProposalBundlesPayeeId(ownerId, supplierId); // Only update the bundle payee ids if this is the first owner (primary supplier)

      const newModel = actions.add('owners', ownerId).result;
      updateModel(newModel);

      await updateCustomerPermissionsFromProjectPermissions(newModel);
    },
    [updateModel, actions, owners]
  );

  const handleDeselect = useCallback(
    (ownerId) => {
      const index = owners.findIndex(
        (selectedOwnerId) => selectedOwnerId === ownerId
      );
      const newModel = actions.remove(`owners[index:${index}]`).result;
      updateModel(newModel);
      updateCustomerPermissionsFromProjectPermissions(newModel);
    },
    [updateModel, actions, owners]
  );

  const handleMakePrimary = useCallback(
    async (ownerId, supplierId) => {
      // await handleUpdateInvoiceProposalBundlesPayeeId(ownerId, supplierId);

      const index = owners.findIndex(
        (selectedOwnerId) => selectedOwnerId === ownerId
      );
      updateModel(
        actions.remove(`owners[index:${index}]`).add('owners', ownerId, 0)
          .result
      );
    },
    [updateModel, actions, owners]
  );

  return (
    <LayoutModal
      onClose={onClose}
      loading={!loaded}
      header={
        <div className="text-lg text-brown-dark italic">Service Providers</div>
      }
    >
      {owners?.length > 0 ? (
        <div className="flex flex-col gap-2">
          {owners.map((ownerId) => (
            <ServiceProviderFromOwnerId
              key={ownerId}
              ownerId={ownerId}
              renderDetails={(supplier) => (
                <div className="flex items-center">
                  {owners[0] === ownerId ? (
                    <Checkmark size={20} color="green-main" styleProps="mr-2" />
                  ) : (
                    <Checkmark
                      size={20}
                      color="tan-main"
                      styleProps="mr-2 hover:cursor-pointer hover:fill-green-main"
                      onClick={() => handleMakePrimary(ownerId, supplier.id)}
                    />
                  )}
                  <Minus
                    color="red-light"
                    size={20}
                    styleProps="hover:cursor-pointer"
                    onClick={() => handleDeselect(ownerId)}
                  />
                </div>
              )}
            />
          ))}
        </div>
      ) : (
        <div className="italic text-brown-dark text-sm">
          No service providers assigned...
        </div>
      )}
      <div className="mt-4">
        <div className="text-brown-dark text-lg font-bold">
          Add a new service provider
        </div>
        <SelectServiceProviders
          onSelect={handleSelect}
          selectedOwnerIds={owners}
        />
      </div>
    </LayoutModal>
  );
};

export { ModalEditServiceProviders };
