import React from 'react';

const Visibility = ({
  color = 'green-main',
  size = 30,
  styleProps = '',
  ...rest
}) => (
  <svg
    width={size.toString()}
    height={size.toString()}
    viewBox="0 0 75 75"
    className={`fill-${color} ${styleProps}`}
    {...rest}
  >
    <path d="M37.4997 19.5432C27.5564 19.5432 19.4937 27.6059 19.4937 37.5493C19.4937 47.4927 27.5564 55.5554 37.4997 55.5554C47.4431 55.5554 55.5058 47.4927 55.5058 37.5493C55.5058 27.6059 47.4431 19.5432 37.4997 19.5432ZM29.347 33.618C27.3563 33.618 25.7458 32.0074 25.7458 30.0168C25.7458 28.0261 27.3563 26.4155 29.347 26.4155C31.3377 26.4155 32.9482 28.0261 32.9482 30.0168C32.9482 32.0074 31.3377 33.618 29.347 33.618Z" />
    <path d="M74.7224 36.1888C68.2702 20.8837 53.3352 10.3601 37.5799 10H37.4198C21.5945 10.3601 7.07957 20.4835 0.447331 35.7987C0.407318 35.8687 0.387311 35.9388 0.357301 36.0088C-0.0728443 36.8891 -0.132865 37.9394 0.277274 38.9098C6.73946 54.2149 21.6645 64.7385 37.4198 65.0986H37.5799C53.3352 64.7385 68.2602 54.2149 74.7224 38.9098C75.0925 38.0395 75.0925 37.0591 74.7224 36.1888ZM37.4998 58.0963C24.9456 57.7761 13.0116 49.5834 7.32965 37.5493C13.0016 25.5252 24.9756 17.3225 37.4998 17.0024C50.0541 17.3225 61.9881 25.5152 67.66 37.5493C61.9881 49.5934 50.0541 57.7761 37.4998 58.0963Z" />
  </svg>
);

export { Visibility };
