import React, { useCallback, useState, useEffect, useMemo } from 'react';
import {
  usePlantSearch,
  useModel,
  useCatalog,
  useRecycleItems,
} from '@thrivelot/hooks';
import {
  Button,
  Descriptor,
  Selector,
  LeafLoader,
  Cancel,
  Checkmark,
} from '@thrivelot/stories';
import { constructUuid } from '@thrivelot/utils';
import {
  LayoutModal,
  InputGroupLineItemFull,
  SelectProjectFeature,
} from '../..';
// import { RowListItemResults } from './RowListItemResults';
import { featurelessFeature } from '../../FormBundleFeature/FormBundleFeature';
import { RowListItemsToAdd } from './RowListItemsToAdd';
import { RowItemCatalog } from '../../RowItemCatalog';

const ModalAddBundleItems = ({
  onClose,
  projectId,
  bundleId,
  featureId,
  itemPath,
  itemIds,
  title,
  description,
}) => {
  const { model: project, loaded: projectLoaded } = useModel({
    modelName: 'Project',
    id: projectId,
  });
  const {
    model: bundle,
    loaded: bundleLoaded,
    actions: bundleActions,
    updateModel: updateBundle,
  } = useModel({ modelName: 'Bundle', id: bundleId });

  const {
    query: catalogQuery,
    setQuery: setCatalogQuery,
    results: catalogResults,
    load: loadCatalog,
    loaded: catalogLoaded,
  } = useCatalog();

  const {
    query: recycleQuery,
    setQuery: setRecycleQuery,
    results: recycleResults,
    loaded: recycleLoaded,
    load: loadRecycledItems,
  } = useRecycleItems();

  const {
    query: dbQuery,
    setQuery: setDbQuery,
    items: dbResults,
    loading: dbLoading,
  } = usePlantSearch();

  // const {
  //   query: dbQuery,
  //   setQuery: setDbQuery,
  //   results: dbResults,
  //   loading: dbLoading,
  // } = useProjectsPlantSearch();

  const [formState, setFormState] = useState({
    featureId,
    items: [],
  });
  const [newItemState, setNewItemState] = useState();
  const [source, setSource] = useState('catalog');

  useEffect(() => {
    if (
      projectLoaded &&
      !formState.featureId &&
      (project?.features || []).length === 0
    )
      setFormState({ ...formState, featureId: featurelessFeature.id });
  }, [formState, setFormState, project?.features, featureId, projectLoaded]);

  useEffect(() => {
    if (itemIds && bundleLoaded) {
      setFormState({
        ...formState,
        items: bundle[itemPath]
          .filter((item) => itemIds.includes(item.id))
          .map((item) => ({ ...item, id: constructUuid() })),
      });
    }
  }, [bundleLoaded, bundle?.payeeId]); // Limit this to run only once when bundle loads

  useEffect(() => {
    if (bundleLoaded && bundle?.supplierPayeeId) {
      loadRecycledItems({ supplierId: bundle?.supplierPayeeId });
      loadCatalog({ supplierId: bundle?.supplierPayeeId });
    }
  }, [bundleLoaded]);

  const handleAddItem = useCallback(
    (item) => {
      setFormState({
        ...formState,
        items: [
          ...formState.items,
          {
            ...item,
            id: constructUuid(),
            data: { ...item?.data, catalogSourceId: item.id },
          },
        ],
      });
      setNewItemState();
    },
    [setFormState, setNewItemState, formState]
  );

  const handleAddPlant = useCallback(
    (item) => {
      const scientificName = `${item.genus || ''} ${item.species || ''}`;
      const plant = {
        kind: 'plant',
        unit: 'plants',
        name: item.commonName || scientificName || '',
        quantity: 1,
        pricePerUnit: 0,
      };

      setFormState({
        ...formState,
        items: [
          ...formState.items,
          {
            ...plant,
            id: constructUuid(),
            data: {
              ...item?.data,
              plantDatabaseId: item.id,
              catalogSourceId: item.id,
            },
          },
        ],
      });
      setNewItemState();
    },
    [setFormState, setNewItemState, formState]
  );

  const handleAddItems = useCallback(() => {
    const items = formState.items.map((item) => ({
      ...item,
      featureId: formState.featureId,
    }));
    updateBundle(bundleActions.addItems(items).result);

    onClose();
  }, [formState, updateBundle, onClose, bundleActions]);

  const bundleItemsSourceMap = useMemo(() => {
    const bundleItems = bundle?.[itemPath]?.reduce((acc, item) => {
      if (item?.data?.catalogSourceId) {
        acc[`${item.featureId}:${item?.data?.catalogSourceId}`] = item;
      }
      return acc;
    }, {});

    const formItems = formState.items.reduce((acc, item) => {
      if (item?.data?.catalogSourceId) {
        acc[`${item.featureId}:${item?.data?.catalogSourceId}`] = item;
      }
      return acc;
    }, {});

    return { ...bundleItems, ...formItems };
  }, [bundle?.[itemPath], formState.items]);

  const shortenedDbResults = useMemo(
    () => (dbResults?.length > 10 ? dbResults.slice(0, 10) : dbResults),
    [dbResults]
  );

  return (
    <LayoutModal
      onClose={onClose}
      loading={!projectLoaded || !bundleLoaded}
      header={
        <div className="text-lg text-brown-dark italic">
          {title || 'Add Line Items'}
        </div>
      }
      footer={
        <div className="flex justify-end">
          <Button
            label="Submit"
            color="green-main"
            kind="outline"
            disabled={formState.items.length === 0 || !formState.featureId}
            onClick={handleAddItems}
          />
        </div>
      }
      dialogCloseDisabled
    >
      <div>
        {description && (
          <div className="mb-4">
            <Descriptor>{description}</Descriptor>
          </div>
        )}
        <SelectProjectFeature
          projectId={projectId}
          currentFeatureId={formState.featureId}
          onSelect={(id) => setFormState({ ...formState, featureId: id })}
        />

        {!newItemState && formState.featureId && (
          <div className="mt-6">
            <div className="text-brown-dark text-sm font-bold mb-2">
              Search to find line items to add
            </div>
            <div className="flex gap-4">
              <Selector
                classAppend="!py-1"
                icon="LineItems"
                selected={source === 'catalog'}
                label="Catalog"
                onClick={() => {
                  setDbQuery('');
                  setSource('catalog');
                }}
              />
              <Selector
                classAppend="!py-1"
                icon="Clock"
                selected={source === 'recycle'}
                label="History"
                onClick={() => {
                  setRecycleQuery('');
                  setSource('recycle');
                }}
              />
              <Selector
                classAppend="!py-1"
                icon="Leaf"
                selected={source === 'plants'}
                label="Plant DB"
                onClick={() => {
                  setRecycleQuery('');
                  setSource('plants');
                }}
              />
            </div>
          </div>
        )}

        {!newItemState && formState.featureId && source === 'recycle' && (
          <div className="flex flex-row mt-6">
            <div className="relative flex-1">
              <input
                className="input !pr-7"
                disabled={!recycleLoaded}
                placeholder={
                  recycleLoaded
                    ? 'Search previously used items...'
                    : 'Loading catalog...'
                }
                value={recycleQuery}
                onChange={(e) => setRecycleQuery(e.target.value)}
              />
              {recycleQuery.length > 0 && (
                <div className="absolute inset-y-0 right-0 pr-3 flex items-center">
                  <Cancel
                    color="yellow-main"
                    size={16}
                    styleProps="hover:cursor-pointer"
                    onClick={() => setRecycleQuery('')}
                  />
                </div>
              )}
            </div>
            <Button
              label="Create"
              size="small"
              color="blue-main"
              kind="outline"
              classAppend="ml-3"
              onClick={() => {
                if (source === 'plants')
                  return setNewItemState({
                    name: recycleQuery,
                    kind: 'plant',
                    unit: 'plants',
                  });
                return setNewItemState({
                  name: recycleQuery,
                  kind: 'labor',
                  unit: 'units',
                });
              }}
            />
          </div>
        )}

        {!newItemState && formState.featureId && source === 'plants' && (
          <div className="flex flex-row mt-6">
            <div className="relative flex-1">
              <input
                className="input !pr-7"
                placeholder="Search for a plant..."
                value={dbQuery}
                onChange={(e) => setDbQuery(e.target.value)}
              />
              {dbQuery.length > 0 && (
                <div className="absolute inset-y-0 right-0 pr-3 flex items-center">
                  <Cancel
                    color="yellow-main"
                    size={16}
                    styleProps="hover:cursor-pointer"
                    onClick={() => setDbQuery('')}
                  />
                </div>
              )}
            </div>
          </div>
        )}

        {!newItemState && formState.featureId && source === 'catalog' && (
          <div className="flex flex-row mt-6">
            <div className="relative flex-1">
              <input
                className="input !pr-7"
                disabled={!catalogLoaded}
                placeholder={
                  catalogLoaded
                    ? 'Search for catalog item...'
                    : 'Loading catalog...'
                }
                value={catalogQuery}
                onChange={(e) => setCatalogQuery(e.target.value)}
              />
              {catalogQuery.length > 0 && (
                <div className="absolute inset-y-0 right-0 pr-3 flex items-center">
                  <Cancel
                    color="yellow-main"
                    size={16}
                    styleProps="hover:cursor-pointer"
                    onClick={() => setCatalogQuery('')}
                  />
                </div>
              )}
            </div>
            <Button
              label="Create"
              size="small"
              color="blue-main"
              kind="outline"
              classAppend="ml-3"
              onClick={() => {
                if (source === 'plants')
                  return setNewItemState({
                    name: catalogQuery,
                    kind: 'plant',
                    unit: 'plants',
                  });
                return setNewItemState({
                  name: catalogQuery,
                  kind: 'labor',
                  unit: 'units',
                });
              }}
            />
          </div>
        )}

        {!newItemState &&
          formState.featureId &&
          (recycleQuery || catalogQuery).length > 0 && (
            <div className="text-brown-dark italic text-sm mt-4">
              Click the <span className="font-bold">Create</span> button to make
              a new line item named{' '}
              <span className="font-bold">
                "{recycleQuery || catalogQuery}"
              </span>
            </div>
          )}

        {!newItemState &&
          formState.featureId &&
          source === 'recycle' &&
          recycleQuery.length > 0 &&
          recycleResults.length > 0 && (
            <div className="mt-4 flex flex-col border-2 border-tan-main rounded-main overflow-hidden max-h-64 overflow-y-scroll divide-y divide-tan-light">
              {recycleResults.map((item) => {
                const disabled =
                  bundleItemsSourceMap[`${formState.featureId}:${item.id}`];

                return (
                  <RowItemCatalog
                    key={item.id}
                    item={item}
                    disabled={disabled}
                    actions={
                      <Checkmark
                        color={disabled ? 'tan-main' : 'green-main'}
                        size="20"
                      />
                    }
                    onClick={() => !disabled && handleAddItem(item)}
                  />
                );
              })}
            </div>
          )}

        {!newItemState &&
          formState.featureId &&
          source === 'catalog' &&
          catalogQuery.length > 0 &&
          catalogResults.length > 0 && (
            <div className="mt-4 flex flex-col border-2 border-tan-main rounded-main overflow-hidden max-h-64 overflow-y-scroll divide-y divide-tan-light">
              {catalogResults.map((item) => {
                const disabled =
                  bundleItemsSourceMap[`${formState.featureId}:${item.id}`];

                return (
                  <RowItemCatalog
                    key={item.id}
                    item={item}
                    disabled={disabled}
                    actions={
                      <Checkmark
                        color={disabled ? 'tan-main' : 'green-main'}
                        size="20"
                      />
                    }
                    onClick={() => !disabled && handleAddItem(item)}
                  />
                );
              })}
            </div>
          )}

        {!newItemState && formState.featureId && dbLoading && (
          <LeafLoader classAppend="mt-4" />
        )}
        {!newItemState &&
          formState.featureId &&
          !dbLoading &&
          shortenedDbResults.map((item) => {
            const disabled =
              bundleItemsSourceMap[`${formState.featureId}:${item.id}`];

            const scientificName = `${item.genus || ''} ${item.species || ''}`;
            const plant = {
              id: item.id,
              kind: 'plant',
              unit: 'plants',
              name: item.commonName || scientificName || '',
              quantity: 1,
              pricePerUnit: 0,
              data: {
                ...item?.data,
                plantDatabaseId: item.id,
                catalogSourceId: item.id,
              },
            };

            return (
              <RowItemCatalog
                key={item.id}
                item={plant}
                disabled={disabled}
                actions={
                  <Checkmark
                    color={disabled ? 'tan-main' : 'green-main'}
                    size="20"
                  />
                }
                onClick={() => !disabled && handleAddPlant(item)}
              />
            );
          })}

        {newItemState && formState.featureId && (
          <div className="mt-6">
            <div className="text-brown-dark text-sm font-bold mb-2">
              Add the details of your new line item
            </div>
            <InputGroupLineItemFull
              formState={newItemState}
              setFormState={setNewItemState}
            />
            <div className="flex justify-between mt-2">
              <Button
                icon="Cancel"
                size="small"
                color="yellow-main"
                kind="outline"
                label="Cancel"
                onClick={() => setNewItemState()}
              />
              <Button
                icon="Checkmark"
                size="small"
                color="blue-main"
                kind="outline"
                label="Add"
                onClick={() => {
                  handleAddItem(newItemState);
                  recycleQuery('');
                }}
              />
            </div>
          </div>
        )}

        {formState.items.length > 0 && (
          <RowListItemsToAdd
            formState={formState}
            setFormState={setFormState}
          />
        )}
      </div>
    </LayoutModal>
  );
};

export { ModalAddBundleItems };
