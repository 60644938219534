import React, { useMemo } from 'react';
import { LeafLoader, Button, EmptyState } from '@thrivelot/stories';
// import { DragDropContext, Droppable } from "react-beautiful-dnd";
import { useCanCan, useModal, useModel } from '@thrivelot/hooks';
import { RowItemProjectFileFolder } from '../RowItemProjectFileFolder';
import { NoFolderRow } from './NoFolderRow';
// import { RowList } from "@thrivelot/stories";

const RowListProjectFiles = ({ projectId }) => {
  const { openModal } = useModal();
  const { model: project, loaded } = useModel({
    modelName: 'Project',
    id: projectId,
  });
  const { canEdit } = useCanCan(project);

  const projectFiles = project?.files || [];

  // const handleDragEnd = useCallback((result) => {
  // }, []);

  const sortedFolders = useMemo(() => {
    if (!project?.fileFolders) return [];

    const folders = project.fileFolders.sort((a, b) => {
      if (a.name < b.name) return -1;
      if (a.name > b.name) return 1;
      return 0;
    });

    if (!canEdit) return folders.filter((folder) => folder.fileIds.length > 0);
    return folders;
  }, [project?.fileFolders, canEdit]);

  const filesWithoutFolder = useMemo(() => {
    if (!project?.files) return [];

    return project.files.filter(
      (file) =>
        !(project.fileFolders || []).find((folder) =>
          folder.fileIds.includes(file.id)
        )
    );
  }, [project?.files, project?.fileFolders]);

  if (!loaded) return <LeafLoader />;

  if (projectFiles.length === 0 && sortedFolders.length === 0)
    return (
      <EmptyState
        icon="FilesFolder"
        header="No files here yet..."
        children={<div>Get started by uploading a file to this project</div>}
        footer={
          <div>
            <Button
              color="blue-main"
              kind="outline"
              icon="Plus"
              label="Add File"
              onClick={() =>
                openModal({
                  path: 'ModalAddFiles',
                  modelName: 'Project',
                  modelId: projectId,
                  filesPath: 'files',
                  allowMultiple: true,
                })
              }
            />
          </div>
        }
      />
    );

  return (
    // <DragDropContext onDragEnd={handleDragEnd}>
    //   <Droppable droppableId={`Project-${projectId}-Files`} direction="vertical">
    //     {(droppableProvided) => (
    <div
    // ref={droppableProvided.innerRef}
    // {...droppableProvided.droppableProps}
    >
      <div className="space-y-2">
        {filesWithoutFolder?.length ? (
          <NoFolderRow projectId={projectId} />
        ) : null}
        {sortedFolders.map((fileFolder) => (
          <RowItemProjectFileFolder
            key={fileFolder.id}
            projectId={projectId}
            fileFolder={fileFolder}
            files={projectFiles}
          />
        ))}
      </div>
      {/* {droppableProvided.placeholder} */}
    </div>
    //     )}
    //   </Droppable>
    // </DragDropContext>
  );
};

export { RowListProjectFiles };
