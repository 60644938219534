//  enum PlantHabit {
//    graminoid
//    herb
//    lichenous
//    nonvascular
//    shrub
//    subshrub
//    tree
//    vine
//  }
//  enum PlantEdiblePart {
//    flowers
//    fruits
//    leaves
//    roots
//    seeds
//    stem
//    tubers
//  }
//  enum PlantColor {
//    black
//    blue
//    brown
//    green
//    grey
//    orange
//    purple
//    red
//    white
//    yellow
//  }
// enum PlantForm {
//   bunch
//   colonizing
//   erect
//   multiple_stem
//   rhizomatous
//   single_crown
//   single_stem
//   stoloniferous
//   thicket_forming
// }
// enum PlantGrowthRate {
//   moderate
//   rapid
//   slow
// }
// enum PlantFoliageTexture {
//   coarse
//   fine
//   medium
// }
// enum PlantAnaerobicTolerance {
//   high
//   low
//   medium
//   none
// }

const habits = {
  graminoid: 'graminoid',
  herb: 'herb',
  lichenous: 'lichenous',
  nonvascular: 'nonvascular',
  shrub: 'shrub',
  subshrub: 'subshrub',
  tree: 'tree',
  vine: 'vine',
};

const edibleParts = {
  flowers: 'flowers',
  fruits: 'fruits',
  leaves: 'leaves',
  roots: 'roots',
  seeds: 'seeds',
  stem: 'stem',
  tubers: 'tubers',
};

const colors = {
  black: 'black',
  blue: 'blue',
  brown: 'brown',
  green: 'green',
  grey: 'grey',
  orange: 'orange',
  purple: 'purple',
  red: 'red',
  white: 'white',
  yellow: 'yellow',
};

const forms = {
  bunch: 'bunch',
  colonizing: 'colonizing',
  erect: 'erect',
  multiple_stem: 'multiple_stem',
  rhizomatous: 'rhizomatous',
  single_crown: 'single_crown',
  single_stem: 'single_stem',
  stoloniferous: 'stoloniferous',
  thicket_forming: 'thicket_forming',
};

const growthRates = {
  moderate: 'moderate',
  rapid: 'rapid',
  slow: 'slow',
};

const foliageTextures = {
  coarse: 'coarse',
  fine: 'fine',
  medium: 'medium',
};

const anaerobicTolerances = {
  high: 'high',
  low: 'low',
  medium: 'medium',
  none: 'none',
};

const months = {
  January: 'January',
  February: 'February',
  March: 'March',
  April: 'April',
  May: 'May',
  June: 'June',
  July: 'July',
  August: 'August',
  September: 'September',
  October: 'October',
  November: 'November',
  December: 'December',
};

export const constants = {
  anaerobicTolerances,
  colors,
  edibleParts,
  foliageTextures,
  forms,
  growthRates,
  habits,
  months,
};
