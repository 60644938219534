import { constructUuid } from "@thrivelot/utils";

const constructInvoice = ({ id = constructUuid(), type = "", memo = "", payments = [] }) => ({
  id,
  type,
  memo,
  payments,
});

export { constructInvoice };
