import { ProjectLineItemType } from "@thrivelot/aws";

const calculateTotalMaterialCost = (lineItems, plantItems) => {
  let total = 0;

  lineItems.forEach((item) => {
    if (item.type !== ProjectLineItemType.LABOR) total += item.cost * item.quantity;
  });

  plantItems.forEach((item) => {
    total += item.cost * item.quantity;
  });

  return total;
};

export { calculateTotalMaterialCost };
