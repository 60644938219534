import {
  transformCurrencyToFloat,
  transformCurrencyToInt,
} from '@thrivelot/utils';
import React, { useState } from 'react';
import CurrencyInput from 'react-currency-input-field';

const InputCurrency = ({ value, onChange, ...rest }) => {
  const [val, setVal] = useState(value && transformCurrencyToFloat(value));

  const handleChange = (v) => {
    onChange(transformCurrencyToInt(v));
    setVal(v);
  };

  return <CurrencyInput value={val} onValueChange={handleChange} {...rest} />;
};

export { InputCurrency };
