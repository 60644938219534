import styled from "styled-components";

const DivNotificationCenter = styled.div`
  transition: opacity 0.5s ease-out, box-shadow 0.5s ease-out, color 1s ease-in-out, background-color 1s ease-in-out;
  box-sizing: border-box;
  position: absolute;
  z-index: 1;
  top: 12px;
  right: 12px;
`;

export default DivNotificationCenter;
