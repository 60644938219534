import { constructUuid, isNumber } from "@thrivelot/utils";

const constructPlantItemId = (plantId) => {
  if (isNumber(plantId)) {
    return `${plantId}__//__${constructUuid()}`;
  }
  if (!plantId.includes("__//__")) {
    return constructUuid();
  }
  const [dbId] = plantId.split("__//__");
  return `${dbId}__//__${constructUuid()}`;
};

const constructChanges = ({ changes, commonName, scientificName, imageUrl }) =>
  changes
    .concat([
      { key: "commonName", change: commonName },
      { key: "scientificName", change: scientificName },
      { key: "imageUrl", change: imageUrl },
    ])
    .filter(({ key }, index, array) => array.findIndex((plantChange) => plantChange.key === key) === index);

const constructPlantItem = ({
  id = constructUuid(),
  commonName = "",
  scientificName = "",
  imageUrl = "",
  quantity = 0,
  cost = 0,
  changes = [],
}) => ({
  id: constructPlantItemId(id),
  quantity,
  cost,
  changes: constructChanges({ changes, commonName, scientificName, imageUrl }),
});

export { constructPlantItem };
