import React from "react";
// import styled, { css } from "styled-components";
// import CssDivDefault from "./CssDivDefault";

// const topConstructor = ({ top }) => top || "50px";
// const bottomConstructor = ({ bottom }) => bottom || "0px";
// const heightConstructor = ({ bottom = "0px", top = "50px", style = {} }) => {
//   const trueTop = style.top || top;
//   const trueBottom = style.bottom || bottom;
//   return `calc(100% - ${trueBottom} - ${trueTop})`;
// };
// const noFitSizeDecider = ({ noFitSize }) => {
//   if (noFitSize) return "";
//   return css`
//     min-height: ${heightConstructor};
//   `;
// };

// const DivPageContainer = styled.div`
//   ${CssDivDefault}
//   display: block;
//   overflow-y: scroll;
//   padding-left: 12px;
//   padding-right: 12px;
//   position: fixed;
//   right: 0px;
//   left: 0px;
//   top: ${topConstructor};
//   bottom: ${bottomConstructor};
//   max-height: ${heightConstructor};
//   ${noFitSizeDecider}
// `;

const DivPageContainer = ({ children, className, ...rest }) => {
  return (
    <div className={`container mx-auto ${className}`} {...rest}>
      {children}
    </div>
  );
};

export default DivPageContainer;
