import React from 'react';
import { useProposalViewProvider } from './useProposalViewProvider';
import { ProposalViewContext } from './ProposalViewContext';

const ProposalViewProvider = ({ children }) => {
  const proposalViewProvider = useProposalViewProvider();

  return (
    <ProposalViewContext.Provider value={proposalViewProvider}>
      {children}
    </ProposalViewContext.Provider>
  );
};

export { ProposalViewProvider };
