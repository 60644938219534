import React from 'react';

const Duplicate = ({
  color = 'green-main',
  size = 30,
  styleProps = '',
  ...rest
}) => (
  <svg
    width={size.toString()}
    height={size.toString()}
    viewBox="0 0 75 75"
    className={`fill-${color} ${styleProps}`}
    {...rest}
  >
    <path d="M61.7089 13.6709H61.3291V11.2975C61.3291 5.03165 56.2975 0 50.0316 0H11.2975C5.03165 0 0 5.03165 0 11.2975V50.0316C0 56.2975 5.03165 61.3291 11.2975 61.3291H13.6709V61.7089C13.6709 69.019 19.6519 75 26.962 75H61.7089C69.019 75 75 69.019 75 61.7089V26.962C75 19.6519 69.019 13.6709 61.7089 13.6709ZM11.2975 54.6835C8.73418 54.6835 6.64557 52.5949 6.64557 50.0316V11.2975C6.64557 8.73418 8.73418 6.64557 11.2975 6.64557H50.0316C52.5949 6.64557 54.6835 8.73418 54.6835 11.2975V13.6709H26.962C19.6519 13.6709 13.6709 19.6519 13.6709 26.962V54.6835H11.2975ZM68.3544 61.7089C68.3544 65.3165 65.4114 68.3544 61.7089 68.3544H26.962C23.2595 68.3544 20.3165 65.4114 20.3165 61.7089V26.962C20.3165 23.3544 23.2595 20.3165 26.962 20.3165H61.7089C65.3165 20.3165 68.3544 23.2595 68.3544 26.962V61.7089Z" />
    <path d="M54.019 44.3354C54.019 45.9494 52.6899 47.1835 51.1709 47.1835H46.6139V51.7405C46.6139 53.3544 45.2848 54.5886 43.7658 54.5886C42.1519 54.5886 40.9177 53.2595 40.9177 51.7405V47.1835H36.3608C34.7469 47.1835 33.5127 45.8544 33.5127 44.3354C33.5127 42.7215 34.8418 41.4873 36.3608 41.4873H40.9177V36.9304C40.9177 35.3165 42.2469 34.0823 43.7658 34.0823C45.3798 34.0823 46.6139 35.3165 46.6139 36.9304V41.4873H51.1709C52.6899 41.4873 54.019 42.7215 54.019 44.3354Z" />
  </svg>
);

export { Duplicate };
