import React from 'react';
import { useModal } from '@thrivelot/hooks';

export const Image = ({ classAppend, item }) => {
  const { openModal } = useModal();

  const { name, data } = item;

  return (
    <div
      className={classAppend}
      onClick={() =>
        openModal({
          path: 'ModalViewImage',
          fileUrl: data.imageUrl,
          fileName: name,
        })
      }
    >
      <img src={data.imageUrl} alt={`${name} plant`} className="h-full" />
    </div>
  );
};
