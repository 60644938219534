import React from "react";
import SvgContainer from "../SvgWrapper";

const DesignIcon = ({ color, size, ...rest }) => (
  <SvgContainer
    width={`${size}`}
    height={`${size}`}
    viewBox="0 0 75 75"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path d="M26.4015 18.5315L27.1515 19.6897V14.1074C25.7939 15.1422 25.4426 17.0694 26.4015 18.5315Z" fill={color} />
    <path
      d="M27.1519 14.1076V3.32278C27.1519 1.49051 25.6709 0 23.8291 0H3.32279C1.49051 0 0 1.49051 0 3.32278V71.6772C0 73.5095 1.49051 75 3.32279 75H23.8291C25.6709 75 27.1519 73.5095 27.1519 71.6772V19.6899L26.4019 18.5316C25.443 17.0696 25.7943 15.1424 27.1519 14.1076ZM20.5063 68.3544H6.64557V56.5823H13.576C14.3639 56.5823 15 55.9462 15 55.1582C15 54.3797 14.3639 53.7342 13.576 53.7342H6.64557V49.7089H10.4051C11.193 49.7089 11.8291 49.0633 11.8291 48.2848C11.8291 47.4968 11.193 46.8608 10.4051 46.8608H6.64557V42.826H13.576C14.3639 42.826 15 42.1899 15 41.4019C15 40.6139 14.3639 39.9778 13.576 39.9778H6.64557V35.943H10.4051C11.193 35.943 11.8291 35.307 11.8291 34.519C11.8291 33.731 11.193 33.0949 10.4051 33.0949H6.64557V29.0601H13.576C14.3639 29.0601 15 28.4241 15 27.6361C15 26.8576 14.3639 26.212 13.576 26.212H6.64557V22.1867H10.4051C11.193 22.1867 11.8291 21.5506 11.8291 20.7627C11.8291 19.9747 11.193 19.3386 10.4051 19.3386H6.64557V15.3038H13.576C14.3639 15.3038 15 14.6677 15 13.8797C15 13.0918 14.3639 12.4557 13.576 12.4557H6.64557V6.64557H20.5063V68.3544Z"
      fill={color}
    />
    <path
      d="M16.0532 65.8575C16.9917 65.8575 17.7525 65.0966 17.7525 64.1581C17.7525 63.2196 16.9917 62.4587 16.0532 62.4587C15.1146 62.4587 14.3538 63.2196 14.3538 64.1581C14.3538 65.0966 15.1146 65.8575 16.0532 65.8575Z"
      fill={color}
    />
    <path d="M26.4015 18.5315L27.1515 19.6897V14.1074C25.7939 15.1422 25.4426 17.0694 26.4015 18.5315Z" fill={color} />
    <path
      d="M74.0219 70.6614L71.7529 57.6836C71.6769 57.2468 71.506 56.8291 71.2687 56.4494L40.6326 9.28482C39.6358 7.74685 37.5757 7.31014 36.0377 8.30697L27.3795 13.9367C27.3035 13.9842 27.2181 14.0412 27.1516 14.1076V19.6899L57.0282 65.6962C57.275 66.0665 57.5883 66.3893 57.9586 66.6456L68.8953 73.9937C69.4554 74.3639 70.101 74.5538 70.7465 74.5538C71.3731 74.5538 71.9997 74.3734 72.5598 74.0222C73.6706 73.2912 74.2497 71.9715 74.0219 70.6614ZM36.8731 15.6931L40.3573 21.057L37.2624 23.0601L33.7782 17.6962L36.8731 15.6931ZM62.2307 61.5095L38.8098 25.4525L41.9048 23.4399L65.3257 59.4968L66.1326 64.1298L62.2307 61.5095Z"
      fill={color}
    />
  </SvgContainer>
);

export default DesignIcon;
