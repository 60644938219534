import { css } from "styled-components";

const CssInputDefault = css`
  transition: opacity 0.5s ease-out, box-shadow 0.5s ease-out, color 1s ease-in-out, background-color 1s ease-in-out,
    border 1s ease-in-out;
  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  -webkit-appearance: none;
  border: 0px none transparent;
  caret-color: ${({ theme }) => theme.inputTintColor};
  ::selection {
    background-color: ${({ theme }) => theme.inputTintColor};
    color: ${({ theme }) => theme.inputSelectedText};
  }
  blockquote::selection {
    background-color: ${({ theme }) => theme.inputTintColor};
    color: ${({ theme }) => theme.inputSelectedText};
  }
  ::placeholder {
    color: ${({ theme }) => theme.inputPlaceholderText};
    opacity: 1;
  }
  :-ms-input-placeholder {
    color: ${({ theme }) => theme.inputPlaceholderText};
  }
  ::-ms-input-placeholder {
    color: ${({ theme }) => theme.inputPlaceholderText};
  }
  :-webkit-autofill {
    background-color: ${({ theme }) => theme.inputTintColor};
    color: ${({ theme }) => theme.inputSelectedText};
  }
  color: ${({ theme }) => theme.inputText};
`;

export default CssInputDefault;
