import React, { useCallback, useEffect, useState } from 'react';
import { useModel } from '@thrivelot/hooks';
import { constructUuid } from '@thrivelot/utils';
import { Button } from '@thrivelot/stories';
import { LayoutModal } from '../../LayoutModal';
import { InputGroupLineItemFull } from '../../InputGroupLineItemFull';

const itemWithDefaults = (item) => ({
  ...item,
  quantity: 1,
  unit: item.unit || 'units',
  kind: item.kind || 'material',
  pricePerUnit: item.pricePerUnit,
  costPerUnit: item.costPerUnit,
});

export const ModalUpsertCatalogItem = ({
  onClose,
  catalogId,
  itemId,
  ...item
}) => {
  const {
    model: catalog,
    loaded: loadedCatalog,
    updateModel: updateCatalog,
    actions: catalogActions,
  } = useModel({
    modelName: 'Catalog',
    id: catalogId,
  });

  const initialState = itemId // Enforcing required defaults in case the item is missing those particular props
    ? null
    : itemWithDefaults(item);

  const [newItemState, setNewItemState] = useState(initialState);

  const handleSubmit = useCallback(
    (newItem) => {
      if (!catalog.items) {
        updateCatalog(catalogActions.set('items', []).result);
      }

      if (itemId) {
        updateCatalog(
          catalogActions.set(`items[id:${itemId}]`, newItem).result
        );
      } else {
        updateCatalog(
          catalogActions.add('items', { ...newItem, id: constructUuid() }, 0)
            .result
        );
      }
    },
    [newItemState]
  );

  useEffect(() => {
    if (loadedCatalog && itemId && !newItemState) {
      const existingItem = catalog.items.find((i) => i.id === itemId);
      setNewItemState(itemWithDefaults(existingItem));
    }
  }, [itemId, loadedCatalog, catalog, newItemState]);

  const submitDisabled = newItemState?.name?.length < 3;

  return (
    <LayoutModal
      onClose={onClose}
      loading={!loadedCatalog}
      header={
        <div className="text-lg text-brown-dark font-bold text-center">
          {itemId ? 'Edit Catalog Item' : 'Add Catalog Item'}
        </div>
      }
      footer={
        <div className="flex justify-between gap-4">
          <Button
            label="Submit"
            color="green-main"
            kind="outline"
            disabled={submitDisabled}
            onClick={() => {
              handleSubmit({ ...newItemState });
              onClose();
            }}
          />
          <Button
            label="Submit & Add"
            color="green-main"
            kind="outline"
            disabled={submitDisabled}
            onClick={() => {
              handleSubmit({ ...newItemState });
              setNewItemState({
                quantity: 1,
                unit: 'units',
                kind: 'material',
                pricePerUnit: 0,
                costPerUnit: 0,
              });
            }}
          />
        </div>
      }
    >
      {newItemState && (
        <InputGroupLineItemFull
          formState={newItemState}
          setFormState={setNewItemState}
          disabledMap={{ quantity: true }}
        />
      )}
    </LayoutModal>
  );
};
