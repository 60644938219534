import React from "react";
import SvgContainer from "../SvgWrapper";

const SupplierIcon = ({ color, size, ...rest }) => (
  <SvgContainer
    width={`${size}`}
    height={`${size}`}
    viewBox="0 0 300 300"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      d="M201.075 195.9C215.887 179.85 225 158.512 225 135C225 126.037 223.275 117.3 220.65 108.75C232.05 104.4 240 98.4 240 90C240 81.4875 231.45 75.525 209.888 69.2625C207.975 23.2875 182.812 0 135 0C87.1875 0 62.025 23.2875 60.1125 69.2625C38.55 75.525 30 81.4875 30 90C30 98.4 37.95 104.4 49.35 108.75C46.725 117.3 45 126 45 135C45 158.512 54.1125 179.85 68.925 195.9C26.1375 200.213 0 224.738 0 262.5V292.5C0 296.663 3.3375 300 7.5 300H262.5C266.663 300 270 296.663 270 292.5V262.5C270 224.738 243.862 200.213 201.075 195.9ZM90 212.812C103.238 220.5 118.613 225 135 225C151.387 225 166.762 220.5 180 212.812V255H90V212.812ZM82.5 270H187.5C191.663 270 195 266.663 195 262.5V210.262C199.35 210.562 204.525 211.162 210 212.325V285H60V212.325C65.475 211.162 70.65 210.562 75 210.262V262.5C75 266.663 78.3375 270 82.5 270ZM135 15C173.587 15 192.525 32.2125 194.587 68.4375C154.988 74.3625 115.013 74.3625 75.4125 68.4375C77.475 32.2125 96.4125 15 135 15ZM68.1 82.6125C90.3 86.2125 112.65 88.0875 135 88.0875C157.35 88.0875 179.7 86.2125 201.9 82.6125C215.212 86.25 221.325 89.1375 223.837 90.675C216.637 96.45 184.913 105 135 105C101.85 105 76.9125 101.25 61.6875 97.2C61.575 97.1625 61.5375 97.05 61.425 97.0125C61.0875 96.8625 60.7125 96.9 60.3375 96.7875C52.9125 94.725 47.8125 92.625 45.825 90.9C48.15 89.4 54.15 86.4375 68.1 82.6125ZM63.675 113.062C91.875 119.662 128.55 120 135 120C141.45 120 178.125 119.662 206.325 113.062C208.538 120.188 210 127.5 210 135C210 176.363 176.363 210 135 210C93.6375 210 60 176.363 60 135C60 127.5 61.4625 120.188 63.675 113.062ZM15 262.5C15 236.962 29.2125 223.837 45 217.087V285H15V262.5ZM255 285H225V217.087C240.788 223.875 255 237 255 262.5V285Z"
      fill={color}
    />
  </SvgContainer>
);

export default SupplierIcon;
