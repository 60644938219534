import React from 'react';
import TextareaAutosize from 'react-textarea-autosize';
import { InputGroupLineItemCells, DropdownSelectLineItemKind } from '../..';

const InputGroupLineItemFull = ({
  formState,
  setFormState,
  actions,
  disabledMap,
}) => (
  <div>
    <div className="flex flex-col gap-2">
      <div className="flex items-center gap-2">
        <DropdownSelectLineItemKind
          kind={formState.kind}
          onSelect={(value) => {
            if (value === 'plant')
              return setFormState({
                ...formState,
                kind: 'plant',
                unit: 'plants',
              });
            if (value !== 'plant' && formState.unit === 'plants')
              return setFormState({ ...formState, kind: value, unit: 'units' });
            setFormState({ ...formState, kind: value });
          }}
        />
        <input
          className="flex-1 input"
          placeholder="Name"
          value={formState.name || ''}
          onChange={(evt) =>
            setFormState({ ...formState, name: evt.target.value })
          }
        />
        {actions && <div className="pl-2">{actions}</div>}
      </div>
      <div className="flex items-center gap-2">
        {formState.data?.imageUrl && (
          <div className="h-[20px] w-[20px] flex items-center justify-center overflow-hidden rounded-full">
            <img
              src={formState.data.imageUrl}
              alt={`${formState.name} plant`}
              className="h-full"
            />
          </div>
        )}
        <input
          className="flex-1 input"
          placeholder="Image URL for thumbnail"
          value={formState.data?.imageUrl || ''}
          onChange={(evt) =>
            setFormState({
              ...formState,
              data: { ...formState.data, imageUrl: evt.target.value },
            })
          }
        />
      </div>
      <div>
        <TextareaAutosize
          className="input"
          placeholder="Add a description here..."
          minRows={2}
          value={formState.description || ''}
          onChange={(evt) =>
            setFormState({ ...formState, description: evt.target.value })
          }
        />
      </div>
      <div>
        <InputGroupLineItemCells
          formState={formState}
          onChange={(prop, value) =>
            setFormState({ ...formState, [prop]: value })
          }
          lockUnit={formState.kind === 'plant'}
          disabledMap={disabledMap}
        />
      </div>
    </div>
  </div>
);

export { InputGroupLineItemFull };
