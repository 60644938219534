import styled from "styled-components";
import CssButtonDefault from "./CssButtonDefault";
import { getButtonColor } from "./helpers";

const heightConstructor = ({ isSmall }) => {
  if (isSmall) return "20px";
  return "30px";
};

const paddingConstructor = ({ isSmall }) => {
  if (isSmall) return "8px";
  return "15px";
};

const fontSizeConstructor = ({ isSmall }) => {
  if (isSmall) return "10px";
  return "14px";
};

const ButtonJump = styled.div`
  ${CssButtonDefault}
  box-shadow: inset 0px 1px 4px ${({ theme }) => theme.shadowButton};
  color: ${(props) => getButtonColor(props)};
  background-color: ${({ backgroundColor, theme }) => backgroundColor || theme.foreground};
  border-radius: 30px;
  min-height: ${heightConstructor};
  max-height: ${heightConstructor};
  min-width: fit-content;
  max-width: fit-content;
  padding-left: ${paddingConstructor};
  padding-right: ${paddingConstructor};
  margin-right: 12px;
  align-items: center;
  justify-content: center;
  font-family: Poppins-Bold;
  font-size: ${fontSizeConstructor};
`;

export default ButtonJump;
