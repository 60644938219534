import { get } from "@thrivelot/actions";

const calculatePaymentSubtotalAndMarkup = (model, invoiceId, paymentId) => {
  const payment = get(model, `costPaymentSchedule[id:${invoiceId}].payments[id:${paymentId}]`);
  let subtotal = 0;
  let markup = 0;
  if (payment.calculation) {
    let paymentTotal = 0;
    payment.calculation.forEach((item) => {
      paymentTotal += item.amount;
    });
    ({ markup } = payment);
    subtotal += paymentTotal;
  }
  return { subtotal, markup };
};

export { calculatePaymentSubtotalAndMarkup };
