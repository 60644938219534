import awsConfig from "./aws-exports";
import awsPlantsConfig from "./aws-plants-exports";
import * as plantMutations from "./graphqlPlants/mutations";
import * as plantQueries from "./graphqlPlants/queries";
import * as plantSubscriptions from "./graphqlPlants/subscriptions";

export const gql = {
	...plantMutations,
	...plantQueries,
	...plantSubscriptions,
};

export { awsConfig, awsPlantsConfig };
export { amplifyEnv } from "./amplifyEnv";
export { apiEndpoints } from "./apiEndpoints";

export const StripeObjectStatus = {
	VERIFIED: "verified",
	VERIFICATION_REQUIRED: "verification_required",
	PAYMENT_FAILED: "payment_failed",
};

// export * from './API';
export * from "./APIPlants";
export * from "./graphql/mutations";
export * from "./graphql/queries";
export * from "./graphql/subscriptions";
export * from "./models";
