import { Visibility, VisibilityOff } from "@thrivelot/stories";
import React, { useState } from "react";

const InputPassword = (props) => {
  const [show, setShow] = useState();

  return (
    <div className="w-full flex items-center">
      {show ? <input {...props} /> : <input type="password" {...props} />}
      {show ? (
        <VisibilityOff
          size={16}
          color="tan-main"
          styleProps="ml-2 hover:cursor-pointer"
          onClick={() => setShow(false)}
        />
      ) : (
        <Visibility size={16} color="tan-main" styleProps="ml-2 hover:cursor-pointer" onClick={() => setShow(true)} />
      )}
    </div>
  );
};

export { InputPassword };
