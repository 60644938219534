import { useMobileDifference } from "@thrivelot/hooks";
import { styleConstants } from "../helpers";
import { useSpacing } from "./useSpacing";

const useContentStyle = () => {
  const top = useMobileDifference(styleConstants.topBarHeight);
  const spacing = useSpacing();
  return {
    paddingTop: spacing,
    paddingLeft: spacing,
    paddingRight: spacing,
    top,
  };
};

export { useContentStyle };
