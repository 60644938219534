import { formatMediumDateTime } from "@thrivelot/date";
import { useModel } from "@thrivelot/hooks";
import { LeafLoader } from "@thrivelot/stories";
import React from "react";

const ListInvoiceProposalEvents = ({ invoiceProposalId, excludeKinds = [] }) => {
  const { model: invoiceProposal, loaded } = useModel({ modelName: "InvoiceProposal", id: invoiceProposalId });

  if (!loaded) return <LeafLoader />;

  const { timestamps } = invoiceProposal;

  return (
    <div>
      {(timestamps || []).length > 0 && (
        <div className="flex flex-col gap-4">
          {(timestamps || []).map(
            ({ id, kind, description, createdAt, createdByEmail }) =>
              !excludeKinds.includes(kind) && (
                <div key={id || createdAt} className="text-sm">
                  <div>
                    {description} - {createdByEmail} - {formatMediumDateTime(createdAt)}
                  </div>
                </div>
              )
          )}
        </div>
      )}
    </div>
  );
};

export { ListInvoiceProposalEvents };
