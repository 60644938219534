import React from 'react';

const Refresh = ({
  color = 'green-main',
  size = 30,
  styleProps = '',
  ...rest
}) => (
  <svg
    width={size.toString()}
    height={size.toString()}
    viewBox="0 0 75 75"
    className={`fill-${color} ${styleProps}`}
    {...rest}
  >
    <path d="M36.0749 68.5999C34.1361 68.5999 32.2853 68.336 30.4345 67.8082C18.1842 64.5534 9.19481 53.4697 9.19481 40.2749C9.19481 26.5522 18.9774 14.8527 32.109 12.2137L31.6684 15.3805C31.404 17.1398 33.1666 18.5473 34.8411 17.8436L47.2677 12.8295C48.9422 12.1258 49.2947 9.92663 47.8846 8.78307L37.3088 0.514283C35.8987 -0.629274 33.7835 0.250385 33.5191 2.0097L32.9903 5.79223C15.0996 8.34324 1.61549 24.441 3.11373 43.0898C4.52383 60.1551 18.5368 73.8778 35.6343 74.9334C55.8164 76.1649 72.6496 60.1551 72.6496 40.2749C72.6496 36.8442 72.1208 33.5015 71.2395 30.3347C70.8869 29.0152 69.6531 28.0476 68.3311 28.0476C66.1278 28.0476 64.7177 30.0708 65.3346 32.0061C66.1278 34.645 66.5685 37.4599 66.5685 40.2749C66.5685 50.4789 61.1925 59.4514 53.1725 64.4655C48.5015 67.3683 43.0373 68.7758 37.4851 68.5999H36.0749Z" />
  </svg>
);

export { Refresh };
