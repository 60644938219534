import React, { useMemo } from 'react';
import { Expand, Shrink } from '@thrivelot/stories';
import colors from '@thrivelot/tailwind/colors';

export const InfoBar = ({ plantIdsWithQuantities, expanded, setExpanded }) => {
  const allZones = useMemo(() => {
    const zones = [];
    plantIdsWithQuantities.forEach((plant) => {
      zones.push(...(plant.zones || []));
    });
    return zones.filter(
      (z, i, self) => self.findIndex((zo) => z.id === zo.id) === i
    );
  }, [plantIdsWithQuantities]);

  const numOfSpecies = useMemo(
    () => Object.keys(plantIdsWithQuantities || {}).length,
    [plantIdsWithQuantities]
  );

  const totalQuantity = useMemo(() => {
    let quantity = 0;
    Object.keys(plantIdsWithQuantities || {}).forEach((key) => {
      quantity += plantIdsWithQuantities[key].total;
    });
    return quantity;
  }, [plantIdsWithQuantities]);

  return (
    <div className="w-full bg-white-dark rounded-main">
      <div className="flex w-full items-center p-3 gap-4">
        <div className="flex flex-1 flex-wrap items-center justify-between gap-4">
          <div className="flex items-center">
            <div className="text-mulch-dark mr-2 text-sm">
              Total Number of Species:
            </div>
            <div className="font-bold text-mulch-dark">{numOfSpecies}</div>
          </div>
          <div className="flex items-center">
            <div className="text-mulch-dark mr-2 text-sm">
              Total Number of Plants:
            </div>
            <div className="font-bold text-mulch-dark">{totalQuantity}</div>
          </div>
          <div className="flex items-center">
            <div className="rounded-full bg-white-light h-8 w-8 flex items-center justify-center shadow-outer">
              <div className="text-sm text-mulch-dark">1</div>
            </div>
            <div className="text-mulch-dark ml-2 text-sm">= Quantity</div>
          </div>
        </div>
        <div
          className="cursor-pointer hover:opacity-70 transition flex gap-2 items-center"
          onClick={() => setExpanded(!expanded)}
        >
          {expanded ? (
            <Shrink color="orange-main" size={18} />
          ) : (
            <Expand color="brown-dark" size={18} />
          )}
          <div
            className={`text-${
              expanded ? 'orange-main' : 'brown-dark'
            } font-bold`}
          >
            {expanded ? 'Close' : 'Expand'}
          </div>
        </div>
      </div>
      {expanded && allZones.length > 0 && (
        <div>
          <div className="mx-3 bg-tan-light h-[1px]" />
          <div className="flex flex-col p-3 w-full gap-2">
            {allZones.map((zone) => {
              const color =
                zone.type === 'general'
                  ? 'brown-dark'
                  : colors.zonesIconString[zone.type];
              const name = zone.type === 'general' ? 'General' : zone.name;

              return (
                <div key={zone.name} className="flex items-center">
                  <div
                    className={`rounded-full bg-${color} h-6 w-6 flex items-center justify-center shadow-outer`}
                  />
                  <div className="text-mulch-dark ml-3 font-bold">{name}</div>
                </div>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
};
