import React from 'react';
import { Document, Page } from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import { useMeasure } from '@thrivelot/hooks';
import { Pdf, Info } from '@thrivelot/stories';

export const ViewPdf = ({
  loaded,
  signedUrl,
  setNumPages,
  pageNumber,
  setPageNumber,
}) => {
  const [ref, { width }] = useMeasure();

  const onDocumentLoadSuccess = ({ numPages: _numPages }) => {
    setPageNumber(1);
    if (_numPages) setNumPages(_numPages);
  };

  return (
    <div className="absolute inset-0 overflow-y-scroll overflow-x-hidden">
      {loaded && signedUrl && (
        <div
          ref={ref}
          className="flex flex-1 items-center justify-center max-w-screen"
        >
          <Document
            file={signedUrl}
            loading={
              <Pdf size={90} color="white-light" styleProps="animate-pulse" />
            }
            noData={<Info size={90} color="yellow-main" />}
            error={<Info size={90} color="red-main" />}
            onLoadSuccess={onDocumentLoadSuccess}
          >
            <Page width={width} pageNumber={pageNumber} />
          </Document>
        </div>
      )}
    </div>
  );
};
