import React from 'react';

const AngleLeft = ({
  color = 'green-main',
  size = 30,
  styleProps = '',
  ...rest
}) => (
  <svg
    width={size.toString()}
    height={size.toString()}
    viewBox="0 0 75 75"
    className={`fill-${color} ${styleProps}`}
    {...rest}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M57.341 1.1768C55.7719 -0.392268 53.228 -0.392268 51.6589 1.1768L18.1768 34.6589C16.6077 36.228 16.6077 38.772 18.1768 40.3411L51.6589 73.8232C53.228 75.3923 55.7719 75.3923 57.341 73.8232C58.9101 72.2541 58.9101 69.7102 57.341 68.1411L26.6999 37.5L57.341 6.85891C58.9101 5.28984 58.9101 2.74587 57.341 1.1768Z"
    />
  </svg>
);

export { AngleLeft };
