import React, { useMemo, useCallback } from 'react';
import { Dropdown } from '@thrivelot/stories';
import { useModel } from '@thrivelot/hooks';
import { TagIdType } from '@thrivelot/aws';
import { sortComplex } from '@thrivelot/utils';
import { MoveIcon } from '@thrivelot/styles';
import colors from '@thrivelot/tailwind/colors';

const DropdownMoveFeatureItem = ({
  projectId,
  itemId,
  itemBasePath,
  currentFeatureId,
}) => {
  const { showNotification } = window;
  const { model, updateModel, actions, loaded } = useModel({
    modelName: 'Project',
    id: projectId,
  });
  const { features } = model || {};

  const item = useMemo(
    () => loaded && actions.get(`${itemBasePath}[id:${itemId}]`),
    [loaded, itemId, itemBasePath, actions]
  );

  const move = useCallback(
    (newFeatureId) => {
      const feature = actions.get(`features[id:${newFeatureId}]`);

      if (currentFeatureId) {
        const tag = actions.get(
          `features[id:${currentFeatureId}].orderedChildTagIds[prop:tagId:${itemId}]`
        );
        updateModel(
          actions
            .remove(
              `features[id:${currentFeatureId}].orderedChildTagIds[prop:tagId:${itemId}]`
            )
            .add(`features[id:${newFeatureId}].orderedChildTagIds`, tag).result
        );
      } else {
        const tag = { type: TagIdType.LINE_ITEM, tagId: itemId };
        updateModel(
          actions.add(`features[id:${newFeatureId}].orderedChildTagIds`, tag)
            .result
        );
      }

      showNotification({
        type: 'info',
        title: 'Item moved successfully',
        body: `Moved ${item.changes ? 'plant' : 'line item'} to "${
          feature.name
        }"`,
      });
    },
    [updateModel, actions, currentFeatureId, itemId, item, showNotification]
  );

  const options = useMemo(() => {
    if (!loaded) return [];

    return sortComplex({ arr: features, by: 'name' }).map((feature) => {
      const { name, id } = feature;
      let label = name;
      if (!name || name.trim() === '') {
        label = 'No name...';
      }

      return {
        key: id,
        selected: id === currentFeatureId,
        label,
        onClick: () => move(id),
      };
    });
  }, [loaded, features, currentFeatureId, move]);

  return (
    <Dropdown
      label={<MoveIcon hoverable color={colors.yellow.dark} size={25} />}
      header={<div className="text-brown-dark italic">move to feature...</div>}
      options={options}
      optionClass="font-bold"
    />
  );
};

export { DropdownMoveFeatureItem };
