import styled from "styled-components";

const borderColor = ({ theme }) => theme.divLine;
const TeamCardContainer = styled.div`
  border: 1px solid ${borderColor};
  overflow: hidden;
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  cursor: pointer;
  transition: opacity 0.5s ease-out, box-shadow 0.5s ease-out, color 1s ease-in-out, background-color 1s ease-in-out;
  &:hover {
    opacity: 0.7;
  }
  @media (max-width: 767px) {
    margin-right: 8px;
    margin-bottom: 8px;
    min-width: 150px;
    max-width: 150px;
  }
  @media (min-width: 768px) {
    margin-right: 12px;
    margin-bottom: 12px;
    min-width: 200px;
    max-width: 200px;
  }
`;

export { TeamCardContainer };
