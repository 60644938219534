import React from 'react';
import { useProposalProvider } from './useProposalProvider';
import { ProposalContext } from './ProposalContext';

export const ProposalProvider = ({ children }) => {
  const proposalProvider = useProposalProvider();

  return (
    <ProposalContext.Provider value={proposalProvider}>
      {children}
    </ProposalContext.Provider>
  );
};
