import { useMemo } from "react";
import { featurelessFeature } from "../FormBundleFeature/FormBundleFeature";


const useFeaturesMap = ({ project }) => {
  const map = useMemo(() => {
    if (!project) return {};
    const result = {};
    result[featurelessFeature.id] = featurelessFeature;

    project.features.forEach((feature) => {
      result[feature.id] = feature;
    });

    return result;
  }, [project]);

  return { map };
};

export { useFeaturesMap }