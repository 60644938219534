import { gradientItemStyle } from "./gradientItemStyle";
import { hangingIndent } from "./hangingIndent";
import { headerRowStyle } from "./headerRowStyle";
import { iconColor } from "./iconColor";
import { iconSize } from "./iconSize";
import { innerSpacing } from "./innerSpacing";
import { spacing } from "./spacing";
import { supplierImageSize } from "./supplierImageSize";
import { supplierNameHeight } from "./supplierNameHeight";
import { topBarHeight } from "./topBarHeight";

const styleConstants = {
  gradientItemStyle,
  hangingIndent,
  headerRowStyle,
  iconColor,
  iconSize,
  innerSpacing,
  spacing,
  supplierImageSize,
  supplierNameHeight,
  topBarHeight,
};

export { styleConstants };
