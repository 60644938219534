import { isNumber } from "@thrivelot/utils";

const getHashFromCurrentUrl = () => {
  let hashString = window.location.hash;
  if (hashString.startsWith("#")) {
    hashString = hashString.substring(1);
  }
  const hashStringFragments = hashString.split(",");
  const hash = {};
  hashStringFragments.forEach((item) => {
    let key = item;
    let value = true;
    if (item.includes("=")) {
      const keyValuePair = item.split("=");
      [key, value] = keyValuePair;
      if (isNumber(value)) value = parseFloat(value);
    }
    hash[key] = value;
  });
  return hash;
};

export { getHashFromCurrentUrl };
