import { duplicateObject } from "@thrivelot/utils";
import parsePath from "./parsePath";

const middleware = (obj, path, action, isGet) => {
  const deepCopiedObj = duplicateObject(obj);
  if (isGet) return parsePath(deepCopiedObj, path, action);
  parsePath(deepCopiedObj, path, action);
  return deepCopiedObj;
};

export default middleware;
