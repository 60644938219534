import { Card, Edit, CardLoader } from '@thrivelot/stories';
import React from 'react';
import TextareaAutosize from 'react-textarea-autosize';
import { useModal, useModel } from '@thrivelot/hooks';

const CardAboutCustomer = ({ projectId }) => {
  const { openModal } = useModal();
  const {
    model: project,
    updateModel,
    actions,
    loaded,
  } = useModel({ modelName: 'Project', id: projectId });

  if (!loaded) return <CardLoader showText />;

  const {
    favoritePlants,
    petInformation,
    allergyInformation,
    additionalNotes,
    desire,
  } = project.details || {};

  return (
    <Card header="About Customer" classAppend="mb-4">
      <div className="flex items-center mb-4">
        <div className="flex-1 flex">
          <div className="text-brown-dark mr-1">Desired Goal:</div>
          <div className="text-green-dark font-bold">{desire}</div>
        </div>
        <Edit
          color="yellow-dark"
          styleProps="hover:cursor-pointer"
          size="20"
          onClick={() => openModal({ path: 'ModalEditDesire', projectId })}
        />
      </div>

      <div className="text-brown-dark mb-2">What are your favorite plants?</div>
      <TextareaAutosize
        className="input"
        placeholder="Add a note here..."
        minRows={2}
        value={favoritePlants || ''}
        onChange={(e) =>
          updateModel(
            actions.set('details.favoritePlants', e.target.value).result
          )
        }
      />
      <div className="text-brown-dark mb-2">Do you have pets?</div>
      <TextareaAutosize
        className="input"
        placeholder="Add a note here..."
        minRows={2}
        value={petInformation || ''}
        onChange={(e) =>
          updateModel(
            actions.set('details.petInformation', e.target.value).result
          )
        }
      />
      <div className="text-brown-dark mb-2">Do you have any allergies?</div>
      <TextareaAutosize
        className="input"
        placeholder="Add a note here..."
        minRows={2}
        value={allergyInformation || ''}
        onChange={(e) =>
          updateModel(
            actions.set('details.allergyInformation', e.target.value).result
          )
        }
      />
      <div className="text-brown-dark mb-2">
        Tell us anything and everything.
      </div>
      <TextareaAutosize
        className="input"
        placeholder="Add a note here..."
        minRows={2}
        value={additionalNotes || ''}
        onChange={(e) =>
          updateModel(
            actions.set('details.additionalNotes', e.target.value).result
          )
        }
      />
    </Card>
  );
};

export { CardAboutCustomer };
