import React from 'react';
import { EmptyState, PageContainer } from '@thrivelot/stories';
import { useAuth, useModelList } from '@thrivelot/hooks';
import { HelpPrompt, RowListProjectsByStage } from '@thrivelot/components';

const PageExploreProjects = () => {
  const {
    user: { username },
  } = useAuth();

  const { models: projects, loaded } = useModelList({
    modelName: 'Project',
    filter: { owners: { contains: username } },
  });

  return (
    <PageContainer
      header="Projects"
      icon="Plan"
      loading={!loaded}
      documentTitle="Projects"
      help={
        <HelpPrompt header="About your Projects page">
          This is a list view of your open and active projects on the thrive lot
          platform. You can see the stage, how many service providers are
          assigned to a project, and when it was last updated.
        </HelpPrompt>
      }
    >
      {loaded && projects.length === 0 && (
        <EmptyState
          icon="Plan"
          footer="When ThriveLot assigns a projects to you, it will appear here."
        >
          No projects yet...
        </EmptyState>
      )}
      {(!loaded || projects.length > 0) && (
        <RowListProjectsByStage projects={projects} loaded={loaded} />
      )}
    </PageContainer>
  );
};

export { PageExploreProjects };
