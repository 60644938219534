const projectFeatures = {
  planterBoxes: {
    name: "Planter boxes",
    description:
      "Boxes of edible plants & the ultimate companion plants to provide beneficial insects and soil health.",
    imageUrl: "https://images.typeform.com/images/8uapWvpwTFrJ/image/default",
  },
  kitchenVegetableVictoryGarden: {
    name: "Kitchen / vegetable / victory garden",
    description:
      "Lots of veggies, mostly annuals, interplanted without tilling for optimum production, pest reduction, soil health, & sunlight efficiency.",
    imageUrl: "https://images.typeform.com/images/s9FrKrPfarEP/image/default",
  },
  edibleLandscaping: {
    name: "Edible landscaping",
    description: "Just like regular landscaping, only much more valuable because it provides food & medicine!",
    imageUrl: "https://images.typeform.com/images/gJSfcVrzKfM5/image/default",
  },
  pollinatorFlowerGarden: {
    name: "Pollinator / flower garden",
    description:
      "Native flowering garden space with a variety of blooms throughout the year to feed the hummingbirds, bees & butterflies",
    imageUrl: "https://images.typeform.com/images/aGteHPAAND65/image/default",
  },
  rainGarden: {
    name: "Rain garden",
    description:
      "Soaks up rainwater runoff from hard surfaces (like roofs, driveways, & walkways)  in deep organic matter & removes pollutants, prevents erosion, & feeds wildlife & beneficial insects with little maintenance.",
    imageUrl: "https://images.typeform.com/images/4yaCXtJDjNrh/image/default",
  },
  herbGarden: {
    name: "Herb garden",
    description:
      "All the fresh herbs you can handle & their wonderful fragrances, usually grown right near the kitchen door for easy access.",
    imageUrl: "https://images.typeform.com/images/NZqfsWxL7Gcs/image/default",
  },
  terraceGarden: {
    name: "Terrace garden",
    description: "Turn slopes into beautiful levels of bounty.",
    imageUrl: "https://images.typeform.com/images/j6xtNmXSMibW/image/default",
  },
  wetlandGarden: {
    name: "Wetland garden",
    description: "Embrace those wet areas. Can be used to filter runoff & even grey water in rural areas.",
    imageUrl: "https://images.typeform.com/images/27VmqZ6AVALL/image/default",
  },
  foodForest: {
    name: "Food forest",
    description:
      "A multi-story system of fruit & nut trees, edible vining plants, shrub understory, herbaceous layer, edible tubers, and ground cover. Every home should have one.",
    imageUrl: "https://images.typeform.com/images/Dab7CAPgh7sW/image/default",
  },
  orchard: {
    name: "Orchard",
    description:
      "(food forest recommended) Fruit & nut trees of more varieties than you probably know (we mean a lot more than almost anyone but our growers knows). You'll see in the next step.",
    imageUrl: "https://images.typeform.com/images/haxVYV3LBuqJ/image/default",
  },
  vineTrellis: {
    name: "Vine trellis",
    description: "Enjoy grapes, muscadines, hardy kiwi, & more amazing vining fruits & veggies.",
    imageUrl: "https://images.typeform.com/images/mDUHysgaYLnr/image/default",
  },
  vinePatioCanopy: {
    name: "Vine patio canopy",
    description:
      "(shade summer & sun winter) The best cover for your patio that never wears out. Imagine broad green leafs for shade in summer, with bunches of grapes hanging down, but full sun exposure in the winter for warmth.",
    imageUrl: "https://images.typeform.com/images/vM3eRGekQyPd/image/default",
  },
  evergreenPrivacyScreenHedgeBarrier: {
    name: "Evergreen privacy screen, hedge barrier",
    description:
      "Block any unwanted views either out or in with evergreens, many of which also produce abundant fruit for people & wildlife. Goodbye prying neighbors. So long ugly building views!",
    imageUrl: "https://images.typeform.com/images/aX9D3fjF9WGK/image/default",
  },
  livingWildlifeBarrier: {
    name: "Living wildlife barrier",
    description: "Deer and other wildlife will stay outside the hedge and ea",
    imageUrl: "https://images.typeform.com/images/qKSSEBLdDUCL/image/default",
  },
  wildlifeFeedPlot: {
    name: "Wildlife feed plot",
    description: "A plot of grains & plants to feed a variety of native local wildlife & keep the ecosystem strong.",
    imageUrl: "https://images.typeform.com/images/JcdJpNN7zGnM/image/default",
  },
  birdHabitat: {
    name: "Bird habitat",
    description: "Trees & plants especially beneficial to our feathered friends.",
    imageUrl: "https://images.typeform.com/images/a24shtywWBxw/image/default",
  },
  noMowEvergreenLawnAlternative: {
    name: "No mow evergreen lawn alternative",
    description:
      "Hate the expense & environmental impact of mowing? There are evergreen, soft, & short alternatives you can replace your lawn with & never mow again.",
    imageUrl: "https://images.typeform.com/images/zUHR6vBwMrqb/image/default",
  },
  greenhouse: {
    name: "Greenhouse",
    description:
      "Most food systems can provide year round food with a little planning & preservation or freezing, but adding a greenhouse maximizes fresh food yields all year long.",
    imageUrl: "https://images.typeform.com/images/F5yP4GFx799N/image/default",
  },
  beeBoxes: {
    name: "Bee boxes",
    description:
      "The freshest honey in the world, plus little workers to take care of your plants & keep them pollinated. Local beekeepers to maintain them for you.",
    imageUrl: "https://images.typeform.com/images/ydzQn3Rtexj7/image/default",
  },
  chickenCoop: {
    name: "Chicken Coop",
    description:
      "Fresh hearty eggs & pest control, plus experience hours of unexpected relaxation and entertainment by watching your flock.",
    imageUrl: "https://images.typeform.com/images/wfd5GDyLB3iL/image/default",
  },
  rainWaterCatchment: {
    name: "Rain water catchment",
    description:
      "Catch the thousands of gallons spilling off your roof each year for passive integrated food system watering.",
    imageUrl: "https://images.typeform.com/images/PKxNtmVKJm38/image/default",
  },
  solarPanels: {
    name: "Solar panels",
    description: "Why not go all the way green and make yourself more resilient with solar energy?",
    imageUrl: "https://images.typeform.com/images/FqmL7WKmpKMe/image/default",
  },
  mushroomLogsOrMulch: {
    name: "Mushroom Logs or Mulch",
    description:
      "Healthy, hearty fungi are a critical layer to any forest garden system. You can get all the protein packed, fresh mushrooms your heart desires right out of your Thriving Lot!",
    imageUrl: "https://images.typeform.com/images/ha7ZdYmuyWqY/image/default",
  },
  cutFlowers: {
    name: "Cut Flowers",
    description: "Have an abundance of flowers in season. Enough for you and the house, or the whole neighborhood!",
    imageUrl: "https://images.typeform.com/images/DKzyxPRAADmJ/image/default",
  },
  lightingElements: {
    name: "Lighting Elements",
    description: "Light up your space at night and keep it interesting with wildlife and bug friendly light spectrums.",
    imageUrl: "https://images.typeform.com/images/fNJThznnzzWL/image/default",
  },
  hardscapes: {
    name: "Hardscapes",
    description:
      "Paths, flagstone patios, concrete work–frame your ecological design in beautiful structures and spaces with a holistic plan.",
    imageUrl: "https://images.typeform.com/images/GCjMRaxaZi3R/image/default",
  },
};

export { projectFeatures };
