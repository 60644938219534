import styled from "styled-components";

const ZoneScrollContainer = styled.div`
  transition: opacity 0.5s ease-out, box-shadow 0.5s ease-out, color 1s ease-in-out, background-color 1s ease-in-out;
  -webkit-appearance: none;
  ::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
  overflow-y: scroll;
  width: 100%;
  max-height: 100%;
  display: flex;
  flex-direction: column;
`;

export { ZoneScrollContainer };
