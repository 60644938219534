import React from 'react';
import { useModel } from '@thrivelot/hooks';
import { Button, PageContainer } from '@thrivelot/stories';
import { Link, useParams } from 'react-router-dom';
import { HelpPrompt, ViewProjectInvoiceProposal, LinkBack } from '../..';

const PagePreviewProjectInvoiceProposal = () => {
  const { projectId, invoiceProposalId } = useParams();

  const { model: project } = useModel({ modelName: 'Project', id: projectId });
  const { model: invoiceProposal, loaded } = useModel({
    modelName: 'InvoiceProposal',
    id: invoiceProposalId,
  });

  return (
    <PageContainer
      icon="LineItems"
      header="Preview Order"
      preheader={<LinkBack label="Back to Edit" to={`edit`} />}
      subheader={invoiceProposal?.name}
      documentTitle={loaded && project?.details?.title}
      // headerActions={
      //   <Link to="plant-palette">
      //     <Button
      //       icon="Leaf"
      //       kind="outline"
      //       color="green-main"
      //       label="View Plant Palette"
      //     />
      //   </Link>
      // }
      help={
        <HelpPrompt header="About the Project Invoices page">
          Here you can preview the Proposal in presentation format as the
          Customer sees it. You can go back and make edits. When you're ready,
          you can submit the Proposal.
        </HelpPrompt>
      }
    >
      <div className="m-auto max-w-6xl">
        <ViewProjectInvoiceProposal
          projectId={projectId}
          invoiceProposalId={invoiceProposalId}
        />
      </div>
    </PageContainer>
  );
};

export { PagePreviewProjectInvoiceProposal };
