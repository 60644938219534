import React from 'react';
import { ViewItemCombinedTotals } from './ViewItemCombinedTotals';
import { ViewItemFeatureTotals } from './ViewItemFeatureTotals';
import { ViewItemIndividualTotals } from './ViewItemIndividualTotals';

const ViewItemSummary = ({
  projectId,
  invoiceProposalId,
  payment,
  totalsAs,
  descriptionMap,
}) => {
  totalsAs = totalsAs || 'grand_totals';

  return (
    <div className="flex flex-col gap-8">
      {totalsAs === 'feature_totals' && (
        <>
          <ViewItemFeatureTotals
            invoiceProposalId={invoiceProposalId}
            projectId={projectId}
            payment={payment}
            descriptionMap={descriptionMap}
          />
          <div className="py-2 italic text-center font-large">- Totals -</div>
          <ViewItemCombinedTotals
            invoiceProposalId={invoiceProposalId}
            projectId={projectId}
            payment={payment}
          />
        </>
      )}
      {totalsAs === 'item_totals' && (
        <>
          <ViewItemIndividualTotals
            invoiceProposalId={invoiceProposalId}
            projectId={projectId}
            payment={payment}
            descriptionMap={descriptionMap}
          />
          <div className="py-2 italic text-center font-large">- Totals -</div>
          <ViewItemCombinedTotals
            invoiceProposalId={invoiceProposalId}
            projectId={projectId}
            payment={payment}
          />
        </>
      )}
      {totalsAs === 'grand_totals' && (
        <ViewItemCombinedTotals
          invoiceProposalId={invoiceProposalId}
          projectId={projectId}
          payment={payment}
          descriptionMap={descriptionMap}
        />
      )}
    </div>
  );
};

export { ViewItemSummary };
