import React from 'react';
import { PlantsContext } from './PlantsContext';
import { usePlantsProvider } from './usePlantsProvider';

export const PlantsProvider = ({ children }) => {
  const plantsProvider = usePlantsProvider();
  return (
    <PlantsContext.Provider value={plantsProvider}>
      {children}
    </PlantsContext.Provider>
  );
};
