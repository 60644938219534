import React from 'react';
import { LeafLoader, Button, EmptyState } from '@thrivelot/stories';
import { useModal, useModel } from '@thrivelot/hooks';
import { CardProjectFile } from '..';

const CardListProjectFiles = ({ projectId }) => {
  const { openModal } = useModal();
  const { model: project, loaded } = useModel({
    modelName: 'Project',
    id: projectId,
  });

  const projectFiles = project?.files || [];

  if (!loaded) return <LeafLoader />;

  if (projectFiles.length === 0)
    return (
      <EmptyState
        icon="FilesFolder"
        header="No files here yet..."
        children={<div>Get started by uploading a file to this project</div>}
        footer={
          <div>
            <Button
              color="blue-main"
              kind="outline"
              icon="Plus"
              label="Add File"
              onClick={() =>
                openModal({
                  path: 'ModalAddFiles',
                  modelName: 'Project',
                  modelId: projectId,
                  filesPath: 'files',
                  allowMultiple: true,
                })
              }
            />
          </div>
        }
      />
    );

  return (
    <div className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-4">
      {projectFiles.map((file) => (
        <CardProjectFile key={file.id} projectId={projectId} file={file} />
      ))}
    </div>
  );
};

export { CardListProjectFiles };
