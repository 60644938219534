// import exampleProjects from "../assets/examples/exampleProjects";
import { duplicateObject, sortComplex } from "@thrivelot/utils";
import { ProjectMilestoneStage } from "@thrivelot/aws";

const sortProjectsByGroup = ({ projects }) => {
  const unsortedProjects = {
    [ProjectMilestoneStage.OPEN_PROJECT]: [],
    [ProjectMilestoneStage.SITE_ASSESSMENT]: [],
    [ProjectMilestoneStage.DETAILED_PLAN]: [],
    [ProjectMilestoneStage.INSTALLATION]: [],
    [ProjectMilestoneStage.STEWARDSHIP]: [],
    [ProjectMilestoneStage.CHURN]: [],
  };

  const sortableProjects = duplicateObject(projects);
  sortableProjects.forEach((project) => {
    if (!project) return;
    if (Object.keys(unsortedProjects).includes(project.currentMilestone)) {
      unsortedProjects[project.currentMilestone].push(project);
    }
  });

  const sortedProjects = {};
  Object.keys(unsortedProjects).forEach((key) => {
    if (unsortedProjects[key].length > 0) {
      sortedProjects[key] = sortComplex({
        arr: unsortedProjects[key],
        nestedBy: ["details", "title"],
        backupNestedBy: ["customer", "firstname"],
      });
    }
  });

  return sortedProjects;
};

export { sortProjectsByGroup };
