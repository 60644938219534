import { add, get, remove, set, duplicate, findItemsByTags, removeTagsFor } from "@thrivelot/actions";
import { constructUuid } from "@thrivelot/utils";
import { calculate100Materials50Labor } from "./calculate100Materials50Labor";
import { calculateDesignCost } from "./calculateDesignCost";
import { calculateFeatureCost } from "./calculateFeatureCost";
import { calculateInvoiceCost } from "./calculateInvoiceCost";
import { calculateInvoiceSubtotalAndMarkup } from "./calculateInvoiceSubtotalAndMarkup";
import { calculatePaymentCost } from "./calculatePaymentCost";
import { calculatePaymentSubtotalAndMarkup } from "./calculatePaymentSubtotalAndMarkup";
import { calculatePhaseCost } from "./calculatePhaseCost";
import { calculateTotalLaborCost } from "./calculateTotalLaborCost";
import { calculateTotalMaterialsCost } from "./calculateTotalMaterialsCost";
import { constructAddress } from "./constructAddress";
import { constructCalculation } from "./constructCalculation";
import { constructCalendarNote } from "./constructCalendarNote";
import { constructDetails } from "./constructDetails";
import { constructFeature } from "./constructFeature";
import { constructInvoice } from "./constructInvoice";
import { constructLifecycleEvent } from "./constructLifecycleEvent";
import { constructLineItem } from "./constructLineItem";
import { constructNote } from "./constructNote";
import { constructPayment } from "./constructPayment";
import { constructPhase } from "./constructPhase";
import { constructPlantItem } from "./constructPlantItem";
import { constructPlantItemChange } from "./constructPlantItemChange";
import { constructPriority } from "./constructPriority";
import { constructProperty } from "./constructProperty";
import { constructSelf } from "./constructSelf";
import { constructStyle } from "./constructStyle";
import { constructTagId } from "./constructTagId";
import { constructZone } from "./constructZone";
import { getAddressText } from "./getAddressText";
import { getFileNext } from "./getFileNext";
import { getFilePrev } from "./getFilePrev";
import { getPlantName } from "./getPlantName";

function actions(model) {
  return {
    result: model,
    add(path, value, index = -1) {
      this.result = add(this.result, path, value, index);
      return this;
    },
    remove(path, value) {
      this.result = remove(this.result, path, value);
      return this;
    },
    set(path, value) {
      this.result = set(this.result, path, value);
      return this;
    },
    duplicate(path, newId) {
      this.result = duplicate(this.result, path, newId);
      return this;
    },
    removeTagsFor(id) {
      this.result = removeTagsFor(this.result, id);
      return this;
    },
    findItemsByTags: (pathToItemContainingTags, pathToSearchForItems) =>
      findItemsByTags(model, pathToItemContainingTags, pathToSearchForItems),
    get: (path) => get(model, path),
    calculate100Materials50Labor: () => calculate100Materials50Labor(model),
    calculateDesignCost: () => calculateDesignCost(model),
    calculateFeatureCost: (featureId) => calculateFeatureCost(model, featureId),
    calculateInvoiceCost: (invoiceId) => calculateInvoiceCost(model, invoiceId),
    calculateInvoiceSubtotalAndMarkup: (invoiceId) => calculateInvoiceSubtotalAndMarkup(model, invoiceId),
    calculatePaymentCost: (invoiceId, paymentId) => calculatePaymentCost(model, invoiceId, paymentId),
    calculatePaymentSubtotalAndMarkup: (invoiceId, paymentId) =>
      calculatePaymentSubtotalAndMarkup(model, invoiceId, paymentId),
    calculatePhaseCost: (phaseId) => calculatePhaseCost(model, phaseId),
    calculateTotalLaborCost: () => calculateTotalLaborCost(model),
    calculateTotalMaterialsCost: () => calculateTotalMaterialsCost(model),
    constructAddress: (props) => constructAddress(props),
    constructCalculation: (props) => constructCalculation(props),
    constructCalendarNote: (props) => constructCalendarNote(props),
    constructDetails: (props) => constructDetails(props),
    constructFeature: (props) => constructFeature(props),
    constructInvoice: (props) => constructInvoice(props),
    constructLifecycleEvent: (props) => constructLifecycleEvent(props),
    constructLineItem: (props) => constructLineItem(props),
    constructNote: (props) => constructNote(props),
    constructPayment: (props) => constructPayment(props),
    constructPhase: (props) => constructPhase(props),
    constructPlantItem: (props) => constructPlantItem(props),
    constructPlantItemChange: (props) => constructPlantItemChange(props),
    constructPriority: (props) => constructPriority(props),
    constructProperty: (props) => constructProperty(props),
    constructSelf: (props) => constructSelf(props),
    constructStyle: (props) => constructStyle(props),
    constructTagId: (props) => constructTagId(props),
    constructUuid: () => constructUuid(),
    constructZone: (props) => constructZone(props),
    getAddressText: () => getAddressText(model),
    getFileNext: (fileId) => getFileNext(model, fileId),
    getFilePrev: (fileId) => getFilePrev(model, fileId),
    getPlantName: (plantId) => getPlantName(model, plantId),
  };
}

export { actions };
