const gradientItemStyle = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  position: "absolute",
  top: "1px",
  right: "1px",
  bottom: "1px",
  left: "1px",
};

export { gradientItemStyle };
