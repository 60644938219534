import React from 'react';

const DateStart = ({
  color = 'green-main',
  size = 30,
  styleProps = '',
  ...rest
}) => (
  <svg
    width={size.toString()}
    height={size.toString()}
    viewBox="0 0 75 75"
    className={`fill-${color} ${styleProps}`}
    {...rest}
  >
    <path d="M61.7184 10.1867H57.9114V3.32278C57.9114 1.49051 56.4209 0 54.5886 0C52.7563 0 51.2658 1.49051 51.2658 3.32278V10.1867H23.7342V3.32278C23.7342 1.49051 22.2437 0 20.4114 0C18.5791 0 17.0886 1.49051 17.0886 3.32278V10.1867H13.2816C5.96203 10.1867 0 16.1487 0 23.4684V61.7184C0 69.038 5.96203 75 13.2816 75H61.7184C69.038 75 75 69.038 75 61.7184V23.4684C75 16.1487 69.038 10.1867 61.7184 10.1867ZM68.3544 61.7184C68.3544 65.3734 65.3734 68.3544 61.7184 68.3544H13.2816C9.62658 68.3544 6.64557 65.3734 6.64557 61.7184V23.4684C6.64557 19.8133 9.62658 16.8323 13.2816 16.8323H17.0886V20.288C17.0886 22.1203 18.5791 23.6108 20.4114 23.6108C22.2437 23.6108 23.7342 22.1203 23.7342 20.288V16.8323H51.2658V20.288C51.2658 22.1203 52.7563 23.6108 54.5886 23.6108C56.4209 23.6108 57.9114 22.1203 57.9114 20.288V16.8323H61.7184C65.3734 16.8323 68.3544 19.8133 68.3544 23.4684V61.7184Z" />
    <path d="M31.0823 59.3354C30.5316 59.3354 29.9715 59.212 29.4588 58.9652C28.2721 58.3956 27.5316 57.2658 27.5316 56.0222V34.1677C27.5316 32.8861 28.3006 31.7373 29.5348 31.1867C30.8829 30.5791 32.4493 30.807 33.5316 31.7658L46.3671 43.1677C47.0886 43.8038 47.4873 44.7057 47.4683 45.6361C47.4493 46.5665 47.0031 47.4589 46.2531 48.0665L33.4177 58.519C32.7531 59.0506 31.9272 59.3354 31.0823 59.3354ZM31.0917 33.7025C30.9493 33.7025 30.8164 33.731 30.693 33.788C30.5696 33.8449 30.3702 33.9589 30.3702 34.1677V56.0222C30.3702 56.212 30.5316 56.3354 30.674 56.4019C30.9114 56.5158 31.3006 56.5633 31.6044 56.3165L44.4588 45.8544C44.5633 45.769 44.6202 45.6741 44.6202 45.5696C44.6202 45.4462 44.5443 45.3513 44.4778 45.2943L31.6424 33.9019C31.481 33.7595 31.2816 33.7025 31.0917 33.7025Z" />
  </svg>
);

export { DateStart };
