const getButtonColor = ({ color, add, done, danger, cancel, theme }) => {
  if (color) return color;
  if (add) return theme.buttonAdd;
  if (done) return theme.buttonDone;
  if (danger) return theme.buttonDanger;
  if (cancel) return theme.buttonCancel;
  return theme.buttonDefault;
};

export default getButtonColor;
