import styled from "styled-components";
import CssRaisedShadow from "./CssRaisedShadow";
import CssInsetShadow from "./CssInsetShadow";
import CssDivDefault from "./CssDivDefault";

const backgroundColor = ({ isSelected, theme }) => {
  if (isSelected) return theme.green;
  return theme.foreground;
};

const shadow = ({ isSelected }) => {
  if (isSelected) return CssInsetShadow;
  return CssRaisedShadow;
};

const DivProjectMenuItem = styled.div`
  ${CssDivDefault}
  ${shadow}
  background-color: ${backgroundColor};
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 40px;
  max-height: 40px;
  min-width: 40px;
  max-width: 40px;
  border-radius: 5px;
  @media (max-width: 767px) {
    margin-right: 12px;
  }
  @media (min-width: 768px) {
    margin-bottom: 12px;
  }
`;

export default DivProjectMenuItem;
