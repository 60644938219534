import { Card, CardLoader } from '@thrivelot/stories';
import React from 'react';
import TextareaAutosize from 'react-textarea-autosize';
import { useModel } from '@thrivelot/hooks';

const CardProjectNotes = ({ projectId }) => {
  const {
    model: project,
    updateModel,
    actions,
    loaded,
  } = useModel({ modelName: 'Project', id: projectId });

  if (!loaded) return <CardLoader showText />;

  const {
    details: { projectNotes },
  } = project;

  return (
    <Card
      header="Project Notes"
      subheader={
        <div className="italic font-normal text-sm">
          NOT visible to customer
        </div>
      }
      classAppend="mb-4"
    >
      <TextareaAutosize
        className="input"
        placeholder="Add a note here..."
        minRows={2}
        value={projectNotes || ''}
        onChange={(e) =>
          updateModel(
            actions.set('details.projectNotes', e.target.value).result
          )
        }
      />
    </Card>
  );
};

export { CardProjectNotes };
