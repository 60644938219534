import React, { useCallback, useMemo } from "react";
import { Circle } from "@react-google-maps/api";
import { useMap, useModel, useQueryParam } from "@thrivelot/hooks";
import colors from "@thrivelot/tailwind/colors";

const MapProjectElementZone = ({ zone, projectId }) => {
  const { actions, update } = useModel({ modelName: "Project", id: projectId });
  const { infoBoxId, toggleInfoBox } = useMap();
  const { param, setParam } = useQueryParam("map");

  const { zoneEditingId, addZone } = useMemo(() => param || {}, [param]);

  const onDragEnd = useCallback(
    (event) => {
      const center = { lat: event.latLng.lat(), lng: event.latLng.lng() };
      update(actions.set(`zones[id:${zone.id}].center`, center).result);
      setParam(); // Removes all the map query params
    },
    [zone.id, actions, update, setParam]
  );

  const onClick = useCallback(() => {
    if (!zoneEditingId && !addZone) {
      toggleInfoBox(zone.id);
    }
  }, [zone.id, toggleInfoBox, zoneEditingId, addZone]);

  if (!zone?.center) return null;

  const isRelocating = zoneEditingId === zone.id || infoBoxId === zone.id;
  const color = colors.zonesMap[zone.type];
  const options = {
    clickable: true,
    strokeColor: color,
    fillColor: color,
    fillOpacity: isRelocating ? 1 : 0.5,
  };

  return (
    <Circle
      draggable={!zoneEditingId && !addZone}
      onDragEnd={onDragEnd}
      center={zone.center}
      radius={5}
      options={options}
      onClick={onClick}
    />
  );
};

export { MapProjectElementZone };
