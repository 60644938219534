import { useCallback, useEffect, useRef, useState } from "react";
import { useService } from "@thrivelot/hooks";
import { fetchBalanceFromLocalStorage } from "./fetchBalanceFromLocalStorage";
import { upsertBalanceInLocalStorage } from "./upsertBalanceInLocalStorage";

const useBillingBalance = (user) => {
  const [balance, setBalance] = useState(fetchBalanceFromLocalStorage());
  const [loginLink, setLoginLink] = useState();
  const { request: getStripeAccountBalance } = useService({
    method: "get",
    level: "protected",
    path: "get-stripe-account-balance",
    deferLoad: true,
  });
  const { loading, request: getStripeAccountUrl } = useService({
    method: "get",
    level: "protected",
    path: "get-stripe-account-url",
    deferLoad: true,
  });

  const userRef = useRef();

  userRef.current = user;

  const fetchBalance = useCallback(() => {
    if (!userRef.current?.id || !userRef.current?.stripeId) return Promise.resolve(null);

    return getStripeAccountBalance({ variables: { supplierId: userRef.current.id } }).then(({ data: balance }) => {
      setBalance(balance);
      upsertBalanceInLocalStorage(balance);
      return balance;
    });
  }, [getStripeAccountBalance]);

  const goToStripe = useCallback(() => {
    if (!userRef.current?.id) return Promise.resolve(null);

    getStripeAccountUrl({ variables: { supplierId: userRef.current.id, redirectUrl: window.location.href } }).then(
      ({ data: loginLink }) => {
        setLoginLink(loginLink);

        window.open(loginLink);
      }
    );
  }, [getStripeAccountUrl]);

  useEffect(() => {
    if (user?.stripeId) fetchBalance();
  }, [user?.stripeId, fetchBalance]);

  return { balance, loginLink, loading, fetchBalance, goToStripe };
};

export { useBillingBalance };
