const getUndefined = (type) => {
  switch (type) {
    case "string":
      return "";
    case "number":
      return 0;
    case "array":
      return [];
    case "boolean":
      return false;
    default:
      return undefined;
  }
};

const findPlantChange = (key, plantItem) => plantItem.changes.find((change) => change.key === key);

const constructPlantAttributeWithChanges = ({ key, plantItem, plant, type = "string" }) => {
  if (plantItem.changes) {
    const possibleChange = findPlantChange(key, plantItem);
    if (possibleChange) {
      if (type === "boolean" && possibleChange.change === "true") return true;
      if (type === "boolean" && possibleChange.change === "false") return false;
      if (type === "number") return parseFloat(possibleChange.change);
      if (type === "array") return JSON.parse(possibleChange.change);
      return possibleChange.change;
    }
  }
  if (key.includes("_")) {
    const split = key.split("_");
    const primeKey = split[0];
    const subKey = split[1];
    if (plant && plant[primeKey] && plant[primeKey][subKey]) return plant[primeKey][subKey];
    return getUndefined(type);
  }
  if (plant && plant[key]) return plant[key];
  return getUndefined(type);
};

export { constructPlantAttributeWithChanges };
