import { useModel } from "@thrivelot/hooks";
import React from "react";
import { Outlet, useParams } from "react-router-dom";
import { HeaderProject, PageUnchartedLands } from "..";

const LayoutProject = ({ navConfig }) => {
  const { projectNavOptions: options } = navConfig;
  const { projectId } = useParams();
  const { loaded, model: project } = useModel({ modelName: "Project", id: projectId });

  if (loaded && !project) return <PageUnchartedLands />;

  return (
    <>
      <HeaderProject projectId={projectId} navOptions={options} />
      <Outlet />
    </>
  );
};

export { LayoutProject };
