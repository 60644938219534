import React from 'react';
import PropTypes from 'prop-types';

export const RowList = ({ children, ...rest }) => (
  <div {...rest} className="divide-y divide-tan-light">
    {children}
  </div>
);

RowList.propTypes = {
  /**
   * Array of RowListItem element
   */
  children: PropTypes.node,
};
