import React from 'react';
import { useTheme } from '@thrivelot/hooks';
import { useProposalView } from '@thrivelot/common';
import { CancelIcon } from '@thrivelot/styles';
import { PhaseInfoContainer } from './PhaseInfoContainer';
import { PhaseScrollContainer } from './PhaseScrollContainer';
import { TitleBar } from './TitleBar';
import { Dates } from './Dates';
import { Description } from './Description';
import { Features } from './Features';

const PhaseInfo = () => {
  // hooks
  const { phase: phaseId, setValue } = useProposalView();
  const theme = useTheme();
  // navigation
  const closeInfo = () => {
    setValue('phase', null);
  };
  // return
  return (
    <PhaseInfoContainer isOpen={phaseId}>
      <TitleBar />
      <PhaseScrollContainer>
        <Dates />
        <Description />
        <Features />
      </PhaseScrollContainer>
      <CancelIcon
        hoverable
        color={theme.text}
        size={20}
        onClick={closeInfo}
        style={{ position: 'absolute', top: '12px', right: '12px' }}
      />
    </PhaseInfoContainer>
  );
};

export { PhaseInfo };
