import React, { useMemo, useCallback } from 'react';
import { Dropdown, Pin } from '@thrivelot/stories';
import { TagIdType } from '@thrivelot/aws';
import { useModel } from '@thrivelot/hooks';

const Label = ({ selectedZone }) => {
  if (selectedZone) {
    return (
      <div className="text-sm text-brown-dark bg-white-dark flex items-center px-3 py-2 border rounded-main border-tan-light">
        <Pin color="blue-main" size={14} styleProps="mr-2" />
        <div>{selectedZone.name}</div>
      </div>
    );
  }

  return (
    <div className="text-sm text-brown-dark bg-white-dark flex items-center px-3 py-2 border italic rounded-main border-tan-light">
      <Pin color="blue-main" size={14} styleProps="mr-2" />
      <div>Select area of work...</div>
    </div>
  );
};

const DropdownUpdateFeatureArea = ({
  projectId,
  featureId,
  selectedId,
  onSelect,
}) => {
  const { model, updateModel, actions } = useModel({
    modelName: 'Project',
    id: projectId,
  });

  const selectedZone = useMemo(() => {
    if (selectedId)
      return (model.zones || []).find((zone) => zone.id === selectedId);

    if (!featureId) return null;
    return (model.zones || []).find((zone) =>
      (zone.orderedChildTagIds || []).find((tag) => tag.tagId === featureId)
    );
  }, [model.zones, featureId, selectedId]);

  const selectArea = useCallback(
    (zoneId) => {
      if (onSelect) return onSelect(zoneId);

      if (selectedZone) {
        return updateModel(
          actions
            .remove(
              `zones[id:${selectedZone.id}].orderedChildTagIds[prop:tagId:${featureId}]`
            )
            .add(`zones[id:${zoneId}].orderedChildTagIds`, {
              type: TagIdType.FEATURE,
              tagId: featureId,
            }).result
        );
      }
      return updateModel(
        actions.add(`zones[id:${zoneId}].orderedChildTagIds`, {
          type: TagIdType.FEATURE,
          tagId: featureId,
        }).result
      );
    },
    [updateModel, actions, selectedZone, featureId]
  );

  const options = useMemo(
    () =>
      (model.zones || []).map((zone) => ({
        key: zone.id,
        label: zone.name,
        selected: selectedZone && zone.id === selectedZone.id,
        onClick: () => selectArea(zone.id),
      })),
    [selectedZone, model.zones, selectArea]
  );

  if ((model.zones || []).length === 0) return null;

  return (
    <Dropdown
      label={<Label selectedZone={selectedZone} />}
      header={
        <div className="text-brown-dark italic">
          {selectedZone ? 'Move to area...' : 'Add to an area of work...'}
        </div>
      }
      options={options}
      optionClass="font-bold"
      selectedColor="blue-main"
    />
  );
};

export { DropdownUpdateFeatureArea };
