import React from "react";
import { useModelProvider } from "./useModelProvider";
import { ModelContext } from "./ModelContext";

const ModelProvider = ({ children }) => {
  const modelProvider = useModelProvider();

  return <ModelContext.Provider value={modelProvider}>{children}</ModelContext.Provider>;
};

export { ModelProvider };
