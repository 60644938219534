import { get } from "@thrivelot/actions";
import { calculateInvoiceSubtotalAndMarkup } from "./calculateInvoiceSubtotalAndMarkup";

const getSiteVisitCost = (model) => {
  const payment = get(model, "costPaymentSchedule[type:SITE_VISIT].payments[0]");
  const amount = payment.calculation[0].amount;
  const markup = payment.markup + 1;
  return { svSupplierPayout: amount, svTotal: amount * markup };
};

const calculateDesignCost = (model) => {
  const invoice = get(model, "costPaymentSchedule[type:DESIGN]");
  const { subtotal, markup } = calculateInvoiceSubtotalAndMarkup(model, invoice.id);
  if (subtotal === 0) {
    return { designCost: 0, dollarMarkup: 0 };
  }
  const { svSupplierPayout, svTotal } = getSiteVisitCost(model);
  const multiplier = markup + 1;
  const supplierPayout = subtotal + svSupplierPayout;
  const designCost = Math.floor(supplierPayout * multiplier) - svTotal;
  return { designCost, dollarMarkup: designCost - subtotal };
};

export { calculateDesignCost };
