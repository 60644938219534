const constructLinks = ({
  facebook = "",
  instagram = "",
  pinterest = "",
  twitter = "",
  website = "",
  youtube = "",
}) => ({ facebook, instagram, pinterest, twitter, website, youtube });

export { constructLinks };
