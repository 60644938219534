import React from "react";
import colors from "@thrivelot/tailwind/colors";
import { PlantIcon } from "@thrivelot/styles";
import { styleConstants } from "../../../../helpers";

const DefaultPic = () => (
  <div
    style={{
      ...styleConstants.gradientItemStyle,
      backgroundColor: colors.lightGreen,
      left: "4px",
      right: "4px",
      bottom: "4px",
      top: "4px",
      borderRadius: "100%",
    }}
  >
    <PlantIcon color={colors.green} size={45} />
  </div>
);

export { DefaultPic };
