import React from 'react';
import { useModel } from '@thrivelot/hooks';
import { LayoutModal } from '../../LayoutModal';

export const ModalViewSupplierBio = ({ onClose, supplierId }) => {
  const { model: supplier, loaded } = useModel({
    modelName: 'Supplier',
    id: supplierId,
  });

  const { businessName, firstname, lastname, bio } = supplier || {};
  const supplierName =
    (firstname || lastname) && [firstname, lastname].join(' ');

  return (
    <LayoutModal
      onClose={onClose}
      header={`${supplierName} - ${businessName}`}
      loading={!loaded}
    >
      <div className="text-brown-dark">{bio}</div>
    </LayoutModal>
  );
};
