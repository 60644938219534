const handleMovingFeaturesInWorkPhases = (source, destination, droppableSource, droppableDestination) => {
  if (!source || !destination) return undefined;

  const sourceClone = Array.from(source);
  const destClone = Array.from(destination);
  const [removed] = sourceClone.splice(droppableSource.index, 1);

  if (destClone.findIndex((e) => e.tagId === removed.tagId) !== -1) return undefined;

  destClone.splice(droppableDestination.index, 0, removed);

  const result = [];
  result.push({
    id: droppableSource.droppableId,
    orderedChildTagIds: sourceClone,
  });
  result.push({
    id: droppableDestination.droppableId,
    orderedChildTagIds: destClone,
  });

  return result;
};

export { handleMovingFeaturesInWorkPhases };
