import React, { useState, useCallback } from 'react';
import { Button } from '@thrivelot/stories';
import { LayoutModal } from '../LayoutModal';

const Confirm = ({
  classAppend = '',
  onConfirm,
  width,
  loading,
  prompt,
  children,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleChildClick = useCallback(() => {
    setIsOpen(true);
  }, [setIsOpen]);

  if (isOpen)
    return (
      <>
        <div className={classAppend} onClick={handleChildClick}>
          {children}
        </div>
        <LayoutModal
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          width={width}
          loading={loading}
          header={'Are you sure?'}
          footer={
            <div className="flex justify-between">
              <Button
                color="blue-main"
                kind="outline"
                onClick={() => setIsOpen(false)}
              >
                Cancel
              </Button>
              <Button color="red-main" onClick={onConfirm}>
                Yes, Confirm
              </Button>
            </div>
          }
        >
          {prompt}
        </LayoutModal>
      </>
    );

  return <div onClick={handleChildClick}>{children}</div>;
};

export { Confirm };
