import { useModal, useModel } from '@thrivelot/hooks';
import { Dropdown, AngleDown } from '@thrivelot/stories';
import React, { useMemo, useCallback } from 'react';
import { isAdminApp } from '@thrivelot/utils';

const DropdownBundleItemActions = ({ bundleId, projectId, item, itemPath }) => {
  const { openModal } = useModal();
  const { updateModel, actions } = useModel({
    modelName: 'Bundle',
    id: bundleId,
  });

  const handleDeleteItem = useCallback(() => {
    updateModel(actions.remove(`${itemPath}[id:${item.id}]`).result);
  }, [itemPath, item.id, updateModel, actions]);

  const options = useMemo(() => {
    const result = [
      {
        icon: 'Edit',
        label: 'Edit item details',
        onClick: () =>
          openModal({
            path: 'ModalEditBundleItem',
            bundleId,
            projectId,
            itemPath,
            itemId: item.id,
          }),
      },
      {
        icon: 'Duplicate',
        label: 'Duplicate item',
        onClick: () =>
          openModal({
            path: 'ModalAddBundleItems',
            bundleId,
            projectId,
            featureId: item.featureId,
            itemPath,
            itemIds: [item.id],
            title: 'Duplicate Item',
            description: 'Please complete the form to duplicate your item.',
          }),
      },
      {
        icon: 'Feature',
        label: 'Move item to feature',
        onClick: () => {
          openModal({
            path: 'ModalMoveBundleItems',
            bundleId,
            projectId,
            itemPath,
            itemIds: [item.id],
            replace: true,
          });
        },
      },
      {
        icon: 'Trash',
        label: 'Delete item',
        color: 'red-main',
        onClick: () => handleDeleteItem(),
      },
    ];

    if (item.hidden) {
      result.splice(2, 0, {
        icon: 'VisibilityOff',
        label: 'Un-hide item',
        onClick: () =>
          updateModel(
            actions.set(`${itemPath}[id:${item.id}].hidden`, false).result
          ),
      });
    } else {
      result.splice(2, 0, {
        icon: 'Visibility',
        label: 'Hide item',
        onClick: () =>
          updateModel(
            actions.set(`${itemPath}[id:${item.id}].hidden`, true).result
          ),
      });
    }

    if (item?.data?.plantDatabaseId && isAdminApp())
      result.splice(1, 0, {
        icon: 'Leaf',
        label: 'View/Edit plant DB details',
        onClick: () =>
          openModal({
            path: 'ModalEditPlant',
            modelId: item.data.plantDatabaseId,
          }),
      });
    else if (item?.data?.plantDatabaseId)
      result.splice(1, 0, {
        icon: 'Leaf',
        label: 'View plant DB details',
        onClick: () =>
          openModal({
            path: 'ModalViewPlant',
            modelId: item.data.plantDatabaseId,
          }),
      });

    if (item?.kind === 'plant' && !item?.data?.plantDatabaseId)
      result.splice(2, 0, {
        icon: 'Link',
        label: 'Link plant to DB',
        onClick: () =>
          openModal({
            path: 'ModalLinkPlantToDB',
            description: `Link ${item.name} to a plant database entry`,
            itemPath,
            bundleId,
            itemId: item.id,
          }),
      });

    return result;
  }, [bundleId, projectId, item, itemPath, handleDeleteItem, openModal]);

  return (
    <Dropdown
      label={<AngleDown size={16} />}
      options={options}
      optionClass="font-bold"
      align="right"
    />
  );
};

export { DropdownBundleItemActions };
