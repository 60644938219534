const projectStyles = {
  forestGarden: {
    name: "Forest Garden",
    description: "Most ecological, most productive, and least longterm maintenance makes this our favorite",
    imageUrl: "https://images.typeform.com/images/w7Y6f9Xr2HHG/image/default",
  },
  organizedLinearGarden: {
    name: "Organized Linear Garden",
    description: "",
    imageUrl: "https://images.typeform.com/images/FyzNYJyV8v4A/image/default",
  },
  hardscapedGarden: {
    name: "Hardscaped Garden",
    description: "",
    imageUrl: "https://images.typeform.com/images/sB3fStqYc5zU/image/default",
  },
  mulchedPathsWithRaisedBeds: {
    name: "Mulched Paths with Raised Beds",
    description: "",
    imageUrl: "https://images.typeform.com/images/ps8Ehq3eLYDZ/image/default",
  },
  edibleLandscapingWithLawn: {
    name: "Edible Landscaping with Lawn",
    description: "",
    imageUrl: "https://images.typeform.com/images/68Qmactfw8p6/image/default",
  },
  denselyLivingBackyard: {
    name: "Densely Living Backyard",
    description: "",
    imageUrl: "https://images.typeform.com/images/uF44T6wHTRzd/image/default",
  },
  pebbledPathsAndHardscapeBeds: {
    name: "Pebbled Paths & Hardscape Beds",
    description: "",
    imageUrl: "https://images.typeform.com/images/yRPDZut5RsG2/image/default",
  },
  layeredFrontYardForestGarden: {
    name: "Layered Front Yard Forest Garden",
    description: "",
    imageUrl: "https://images.typeform.com/images/Myjkcz3XwDYm/image/default",
  },
};

export { projectStyles };
