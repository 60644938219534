import React from "react";
import { User, Clock } from "@thrivelot/stories";
import { milestoneColorMap } from "@thrivelot/config";
import { dateFns } from "@thrivelot/date";
import { Link, useLocation } from "react-router-dom";
import * as icons from "@thrivelot/stories";
import { transformStringToTitleCase } from "@thrivelot/utils";
import { constructAddressString, getProjectStage, getProjectStageAbbreviation } from "@thrivelot/common";
import { useModel } from "@thrivelot/hooks";
import { HeaderProjectLoader } from "./HeaderProjectLoader";
import { DropdownLinks } from "../DropdownLinks";

const HeaderProject = ({ projectId, navOptions }) => {
  const location = useLocation();
  const { model: project, loaded } = useModel({ modelName: "Project", id: projectId });

  if (!loaded) return <HeaderProjectLoader navOptions={navOptions} />;

  const stage = getProjectStage(project);
  const stageAbbreviation = getProjectStageAbbreviation(project);
  const address = constructAddressString(project?.details?.address);

  const projectName = project?.details?.title;
  const { owners, updatedAt } = project;

  const pathMap = ({ location, to }) => to === location.pathname.split("/")[3];

  return (
    <div className="header-font">
      <div className="container mx-auto">
        <div className="pb-6 pt-2">
          <div className="flex text-mulch-light">
            <div className="mr-3">
              <div
                className={`rounded-full h-10 w-10 bg-${milestoneColorMap[stage]}-light font-bold flex items-center justify-center`}
              >
                <div className={`font-bold text-${milestoneColorMap[stage]}-dark`}>{stageAbbreviation}</div>
              </div>
            </div>
            <div className="w-full flex-1 items-between">
              <div className="font-bold">{projectName || address}</div>
              <div className="text-sm flex gap-3">
                <div className={`hidden md:block font-bold text-${milestoneColorMap[stage]}-main`}>
                  {transformStringToTitleCase(stage)}
                </div>
                {owners && owners.length > 0 && (
                  <div className="italic text-sm flex items-center">
                    <User size={12} color="mulch-light" />
                    <div className="ml-1">{owners.length} x</div>
                  </div>
                )}
                {updatedAt && (
                  <div className="italic text-sm flex items-center">
                    <Clock size={12} color="mulch-light" />
                    <div className="ml-1">{dateFns(updatedAt).fromNow()}</div>
                  </div>
                )}
              </div>
            </div>
            <div className="flex items-center">
              <div className="block lg:hidden">
                <DropdownLinks
                  options={navOptions.filter(({ hide }) => !hide || (hide && !hide(project)))}
                  pathMatch={pathMap}
                />
              </div>
            </div>
          </div>

          <div className="hidden lg:flex justify-between pt-6">
            {navOptions.map(({ label, to, icon, color, hide }) => {
              const Icon = icons[icon];
              const selected = pathMap({ location, to });

              if (hide && hide(project)) return null;

              // Purposely not using react-router-dom NavLink here to keep the behavior consistent with the Dropdown
              return (
                <Link
                  key={to}
                  to={to}
                  className={`flex items-center text-tan-dark ${selected ? "font-bold !text-green-main" : ""}`}
                >
                  <Icon color={selected ? "green-main" : "tan-dark"} size={16} />
                  <div className="ml-1">{label}</div>
                </Link>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export { HeaderProject };
