import styled from "styled-components";
import CssInsetShadow from "./CssInsetShadow";
import CssRaisedShadow from "./CssRaisedShadow";
import CssButtonDefault from "./CssButtonDefault";
import { getButtonColor } from "./helpers";

const sizeConstructor = ({ size }) => `${size}px`;

const shadow = ({ shadowInset, shadowRaised }) => {
  if (shadowInset) return CssInsetShadow;
  if (shadowRaised) return CssRaisedShadow;
  return "";
};

const ButtonCircle = styled.div`
  ${CssButtonDefault}
  ${shadow}
  color: ${(props) => getButtonColor(props)};
  background-color: ${({ backgroundColor, theme }) => backgroundColor || theme.foreground};
  align-items: center;
  justify-content: center;
  min-height: ${sizeConstructor};
  max-height: ${sizeConstructor};
  min-width: ${sizeConstructor};
  max-width: ${sizeConstructor};
  border-radius: ${sizeConstructor};
`;

export default ButtonCircle;
