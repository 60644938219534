import React from "react";

const isValidImageUrl = (plantInfo) => {
  if (!plantInfo.imageUrl || plantInfo.imageUrl.trim() === "") return false;
  return true;
};

const Pic = ({ plantInfo }) => {
  if (!isValidImageUrl(plantInfo)) return null;
  return <img className="w-full" src={plantInfo.imageUrl} alt="" />;
};

export { Pic };
