import styled from "styled-components";

const DivLineHorizontal = styled.div`
  transition: opacity 0.5s ease-out, box-shadow 0.5s ease-out, color 1s ease-in-out, background-color 1s ease-in-out;
  width: 100%;
  min-height: 1px;
  max-height: 1px;
  background-color: ${({ isDark, theme }) => (isDark ? theme.divLineDark : theme.divLine)};
`;

export default DivLineHorizontal;
