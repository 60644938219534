import React, { useCallback, useEffect, useState } from "react";
import { LogoGold } from "@thrivelot/stories";
import { Button } from "@thrivelot/stories";
import { useAuth, useQueryParam } from "@thrivelot/hooks";
import { Link, useNavigate } from "react-router-dom";
import { InputPassword } from "../..";

const PageResetPassword = () => {
  const navigate = useNavigate();
  const { constructParamWithKeyAndValue } = useQueryParam("login");
  const { param: reset } = useQueryParam("reset");
  const [errors, setErrors] = useState(false);
  const [loading, setLoading] = useState(false);
  const [code, setCode] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const { resetPassword } = useAuth();

  useEffect(() => {
    document.title = "Thrive Lot | Reset Password";
  }, []);

  const { destination, username } = reset;

  const handleSubmit = useCallback(
    (evt) => {
      evt.preventDefault();

      setErrors([]);

      if (password !== confirmPassword) return setErrors((prev) => [...prev, "Passwords don't match"]);

      setLoading(true);

      resetPassword(username, code, password)
        .then(() => {
          setLoading(false);
          const login = constructParamWithKeyAndValue({ email: username });
          navigate(`/auth/login?${login}`);
        })
        .catch((error) => {
          if (error.message.includes("combination not found")) setErrors((prev) => [...prev, "Unknown email"]);
          else setErrors((prev) => [...prev, error.message]);

          setLoading(false);
        });
    },
    [
      code,
      password,
      confirmPassword,
      username,
      setErrors,
      setLoading,
      resetPassword,
      navigate,
      constructParamWithKeyAndValue,
    ]
  );

  return (
    <form onSubmit={handleSubmit} className="max-w-sm w-full bg-white-dark shadow rounded-main p-5">
      <div className="flex justify-center mb-8 mt-4">
        <LogoGold size={180} />
      </div>

      <div className="text-brown-dark text-sm text-center mb-4">
        Code sent to <span className="font-bold">{destination}</span>
      </div>

      <input placeholder="Code" className="input mb-4" value={code} onChange={(evt) => setCode(evt.target.value)} />

      <div className="border-b border-tan-light mb-4" />

      <div className="mb-4">
        <InputPassword
          placeholder="New Password"
          className="input"
          value={password}
          onChange={(evt) => setPassword(evt.target.value)}
        />
      </div>

      <div className="mb-4">
        <InputPassword
          placeholder="Confirm Password"
          className="input"
          value={confirmPassword}
          onChange={(evt) => setConfirmPassword(evt.target.value)}
        />
      </div>

      {errors.length > 0 && (
        <div className="mb-4">
          {errors.map((error) => (
            <div key={error} className="mb-2 text-sm text-red-main">
              {error}
            </div>
          ))}
        </div>
      )}

      <Button block type="submit" loading={loading} disabled={loading} label="Submit" color="green-main" />

      <div className="flex flex-col items-center mt-5 gap-4">
        <Link to="/auth/login" className="text-blue-main font-bold text-sm">
          Back to login form
        </Link>
      </div>
    </form>
  );
};

export { PageResetPassword };
