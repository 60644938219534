import React from "react";
import { useTheme } from "@thrivelot/hooks";
import { useProposal, useProposalView } from "@thrivelot/common";
import { DivColumn, DivCircleInset, DivLineHorizontal, TextSmallHeader } from "@thrivelot/styles";
import { useSpacing } from "../../../../hooks";
import { Feature } from "./Feature";

const Features = () => {
  // hooks
  const { projectActions } = useProposal();
  const { zone: zoneId } = useProposalView();
  const theme = useTheme();
  const spacing = useSpacing();
  if (!zoneId) return null;
  // actions
  const zone = projectActions.get(`zones[id:${zoneId}]`);
  let features = projectActions.findItemsByTags("features", zone.orderedChildTagIds || []);
  features = features.filter((feature) => feature.name && feature.name.trim() !== "");
  if (!features || features.length === 0) return null;
  return (
    <DivColumn>
      <DivLineHorizontal
        style={{
          margin: `0px ${spacing}`,
          width: `calc(100% - ${spacing} - ${spacing})`,
        }}
      />
      <div
        style={{
          display: "flex",
          margin: spacing,
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <TextSmallHeader style={{ color: theme.textLabel }}>Features</TextSmallHeader>
        <DivCircleInset size={30}>
          <TextSmallHeader style={{ color: theme.textLabel }}>{features.length}</TextSmallHeader>
        </DivCircleInset>
      </div>
      {features.map((feature) => (
        <Feature key={feature.id} feature={feature} />
      ))}
    </DivColumn>
  );
};

export { Features };
