import { useMemo } from 'react';
import { get } from '@thrivelot/actions';

const useSpecificModel = ({ models, modelName, id, path }) =>
  useMemo(() => {
    if (!id) return {};
    let pathModel;

    const fullModel = models?.[modelName]?.[id];

    if (!fullModel) return {};

    if (path) pathModel = get(fullModel, path);

    return { pathModel, fullModel };
  }, [models, modelName, path, id]);

export { useSpecificModel };
