import { ProjectStory } from "./ProjectStory";
import { ProjectTeam } from "./ProjectTeam";
import { SiteAnalysis } from "./SiteAnalysis";
import { SitePlanning } from "./SitePlanning";
import { PlantPalette } from "./PlantPalette";
import { Files } from "./Files";
import { WorkPhaseCalendar } from "./WorkPhaseCalendar";
import { ProjectInvestment } from "./ProjectInvestment";

const SectionHashmap = {
  "Project Story": ProjectStory,
  "Project Team": ProjectTeam,
  "Site Analysis": SiteAnalysis,
  "Site Planning": SitePlanning,
  "Plant Palette": PlantPalette,
  Files,
  Calendar: WorkPhaseCalendar,
  "Project Investment": ProjectInvestment,
};

export { SectionHashmap };
