import { formatLongDate } from '@thrivelot/date';
import { useModel } from '@thrivelot/hooks';
import { LeafLoader } from '@thrivelot/stories';
import React from 'react';
import { InvoiceServiceProviderInfo } from './InvoiceServiceProviderInfo';
import { ViewItemSummary } from './ViewItemSummary';

const ViewInvoice = ({
  invoiceProposalId,
  projectId,
  payment,
  totalsAs: forceTotalsAs,
}) => {
  const { model: invoiceProposal, loaded: invoiceProposalLoaded } = useModel({
    modelName: 'InvoiceProposal',
    id: invoiceProposalId,
  });

  if (!invoiceProposalLoaded) return <LeafLoader />;

  const { submittedAt, owners } = invoiceProposal;
  const totalsAs = forceTotalsAs || invoiceProposal?.totalsAs || 'grand_totals';

  return (
    <div>
      <div className="flex">
        <div className="flex-1">
          {owners?.[0] && <InvoiceServiceProviderInfo ownerId={owners[0]} />}
        </div>
        <div className="flex-1 text-brown-dark flex flex-col justify-end items-end">
          <div>Date: {formatLongDate(submittedAt)}</div>
        </div>
      </div>

      <div className="p-6" />

      <ViewItemSummary
        invoiceProposalId={invoiceProposalId}
        projectId={projectId}
        payment={payment}
        totalsAs={totalsAs}
      />
    </div>
  );
};

export { ViewInvoice };
