import React from "react";
import colors from "@thrivelot/tailwind/colors";
import { transformCurrencyToString } from "@thrivelot/utils";
import { useTheme, useMobileDifference } from "@thrivelot/hooks";
import { DivLineHorizontal, TextHeader } from "@thrivelot/styles";
import { useInnerSpacing } from "../../../../hooks";

const Header = ({ title, Icon, cost }) => {
  const theme = useTheme();
  const iconSize = useMobileDifference({ desktop: 25, mobile: 15 });
  const spacing = useInnerSpacing();
  const negativeMargin = `-${spacing}`;
  return (
    <>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <Icon color={colors.yellow.dark} size={iconSize} />
          <TextHeader style={{ color: theme.textTitle, marginLeft: spacing }}>{title}</TextHeader>
        </div>
        <TextHeader style={{ color: theme.green }}>{transformCurrencyToString(cost)}</TextHeader>
      </div>
      <DivLineHorizontal
        style={{
          margin: `${spacing} ${negativeMargin}`,
          width: `calc(100% + ${spacing} + ${spacing})`,
        }}
      />
    </>
  );
};

export { Header };
