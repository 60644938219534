import React from 'react';

export const CardLoader = ({ count = 1, showText = false }) => (
  <div className="animate-pulse break-inside-avoid p-4 mb-5 rounded-main bg-white-light shadow">
    <div className="flex justify-between items-center mb-2">
      <div className="text-brown-light">Loading...</div>
      <div className="h-2 w-12 bg-tan-light rounded-full"></div>
    </div>
    <div className="flex flex-col gap-3">
      <div className="h-2 w-28 bg-tan-light rounded-full"></div>
      <div className="h-2 w-36 bg-tan-light rounded-full"></div>
      <div className="h-2 w-24 bg-tan-light rounded-full"></div>
    </div>
  </div>
);
