import { Auth } from '@thrivelot/aws-amplify';
import { fetchUser } from './fetchUser';

const verifyUserAttribute = (attrName, verificationCode) =>
  fetchUser().then((user) =>
    Auth.verifyUserAttributeSubmit(user, attrName, verificationCode).then(
      () => user
    )
  );

export { verifyUserAttribute };
