import React, { useMemo } from 'react';
import { useModel, useMultiSelect } from '@thrivelot/hooks';
import {
  LeafLoader,
  CheckEmpty,
  CheckFull,
  CheckMinus,
} from '@thrivelot/stories';
import { RowItemForm } from '..';
import { DropdownLineItemMultiSelect } from './DropdownLineItemMultiSelect';

const ListItems = ({
  itemPath,
  disabled,
  featureId,
  projectId,
  bundleId,
  multiSelectDisabled,
  renderMultiSelectActions,
  renderItemActions,
}) => {
  const { model: bundle, loaded } = useModel({
    modelName: 'Bundle',
    id: bundleId,
  });
  const { loaded: projectLoaded } = useModel({
    modelName: 'Project',
    id: projectId,
  });

  const bundleItems = (bundle || {})[itemPath];

  const items = useMemo(() => {
    if (loaded)
      return (bundleItems || []).filter((item) => item.featureId === featureId);
    return [];
  }, [loaded, bundleItems, featureId]);

  const {
    selectedOption,
    allOfOptionAreSelected,
    onMultiSelect,
    selectedItemIds,
    itemIsSelected,
    setItemSelection,
  } = useMultiSelect({
    items,
    propName: 'kind',
    propOptions: ['plant', 'material', 'labor', 'equipment'],
  });

  if (!loaded || !projectLoaded)
    return (
      <div>
        <LeafLoader />
      </div>
    );

  return (
    <div>
      <div className="flex justify-between items-center mb-2">
        {!multiSelectDisabled && items.length > 0 && (
          <div className="flex items-center gap-6">
            <div className="flex items-center gap-2">
              {selectedOption === 'all' && allOfOptionAreSelected && (
                <CheckFull
                  size={20}
                  onClick={() => onMultiSelect()}
                  styleProps="hover:cursor-pointer"
                />
              )}
              {selectedOption &&
                selectedOption !== 'all' &&
                allOfOptionAreSelected && (
                  <CheckMinus
                    size={20}
                    onClick={() => onMultiSelect()}
                    styleProps="hover:cursor-pointer"
                  />
                )}
              {(!selectedOption || !allOfOptionAreSelected) && (
                <CheckEmpty
                  size={20}
                  onClick={() => onMultiSelect('all')}
                  styleProps="hover:cursor-pointer"
                />
              )}
              <DropdownLineItemMultiSelect
                selectedOption={allOfOptionAreSelected && selectedOption}
                onMultiSelect={onMultiSelect}
              />
            </div>
            {renderMultiSelectActions &&
              renderMultiSelectActions({ selectedItemIds, onMultiSelect })}
          </div>
        )}
      </div>
      <div className="md:divide-y md:divide-tan-light">
        {items.length === 0 && (
          <div className="text-sm italic">No items here yet...</div>
        )}
        {items.length > 0 &&
          items.map((item) => (
            <RowItemForm
              key={item.id}
              itemPath={itemPath}
              modelName="Bundle"
              modelId={bundleId}
              disabled={disabled}
              item={item}
              selectDisabled={multiSelectDisabled}
              selected={itemIsSelected(item.id)}
              setSelected={(value) => setItemSelection(item.id, value)}
              renderItemActions={renderItemActions}
            />
          ))}
      </div>
    </div>
  );
};

export { ListItems };
