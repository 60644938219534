import { calculateTotals } from './calculateTotals';
import { metaMap } from './metaMap';

const constructSummaryDesignBilling = ({
  bundles,
  items,
  cacheMap,
  creditItem,
  housePercent,
  paymentMethod,
}) => ({
  items,
  payments: [
    {
      label: 'design_payment_1',
      nextInvoiceProposalStatus: 'active',
      stripeId: cacheMap.design_payment_1?.stripeId,

      ...metaMap.design_payment_1,
      ...calculateTotals({
        items,
        cache: cacheMap.design_payment_1,
        housePercent,
        creditItem,
        applyCredit: true,
        paymentMethod,
      }),
      stripeObjectCache: cacheMap.design_payment_1?.stripeObjectCache,
      stripeObjectStatus: cacheMap.design_payment_1?.stripeObjectStatus,
      invoiceSentAt: cacheMap.design_payment_1?.invoiceSentAt,
    },
  ],
  payouts: [
    ...bundles.map(({ supplierPayeeId }) => {
      const { priceSubtotal } = calculateTotals({
        items, // Needs to be the payment items
      });

      return {
        label: 'design_payout_1',
        paymentLabel: 'design_payment_1',
        ...metaMap.design_payout_1,
        supplierPayeeId,
        priceSubtotal:
          cacheMap[`design_payout_1:${supplierPayeeId}`]?.priceSubtotal ||
          Math.round(priceSubtotal / 2),
        totalAmount:
          cacheMap[`design_payout_1:${supplierPayeeId}`]?.totalAmount ||
          Math.round(priceSubtotal / 2),
        paidAt: cacheMap[`design_payout_1:${supplierPayeeId}`]?.paidAt || null,
      };
    }),
    ...bundles.map(({ supplierPayeeId, lineItems, changeItems }) => {
      const { priceSubtotal } = calculateTotals({
        items, // Needs to be the payment items
      });

      return {
        label: 'design_payout_2',
        paymentLabel: 'design_payment_1',
        ...metaMap.design_payout_2,
        supplierPayeeId,
        priceSubtotal:
          cacheMap[`design_payout_2:${supplierPayeeId}`]?.priceSubtotal ||
          Math.round(priceSubtotal / 2),
        totalAmount:
          cacheMap[`design_payout_2:${supplierPayeeId}`]?.totalAmount ||
          Math.round(priceSubtotal / 2),
        paidAt: cacheMap[`design_payout_2:${supplierPayeeId}`]?.paidAt || null,
      };
    }),
  ],
});

export { constructSummaryDesignBilling };
