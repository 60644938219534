import { useCallback, useReducer, useRef } from "react";
import { useJsApiLoader } from "@react-google-maps/api";

const mapLoaderOptions = {
  id: "google-maps-script",
  googleMapsApiKey: "AIzaSyCPPfZp0wc8QLtyYcppRqQh88w4eeHYuxE",
  libraries: ["geometry", "drawing", "places"],
};

const actionTypes = {
  setValue: "set_value",
  setValues: "set_values",
};

const initialState = {
  map: null,
  center: null,
  zoom: 19,
  infoBoxId: null,
  drawingManager: null,
  drawingMode: null,
  options: {
    disableDefaultUI: false,
    gestureHandling: "auto",
    mapTypeControl: false,
    mapTypeId: "satellite",
    fullscreenControl: false,
    scaleControl: true,
  },
  drawingOptions: {
    drawingControl: false,
    polylineOptions: {
      strokeColor: "#761DB4",
    },
    rectangleOptions: {
      fillColor: "#EA7211",
      strokeColor: "#EA7211",
    },
    circleOptions: {
      fillColor: "#F2C94C",
      strokeColor: "#F2C94C",
    },
    polygonOptions: {
      fillColor: "#216BD8",
      strokeColor: "#216BD8",
    },
  },
};

const reducer = (state, action) => {
  switch (action.type) {
    case actionTypes.setValue:
      return { ...state, [action.name]: action.value };
    case actionTypes.setValues:
      return { ...state, ...action.values };
    default:
      throw new Error(`Invalid map action type "${action.type}"`);
  }
};

const useMapProvider = () => {
  const { isLoaded, loadError } = useJsApiLoader(mapLoaderOptions);
  const [mapState, dispatchToMapState] = useReducer(reducer, initialState);
  const mapStateRef = useRef();
  mapStateRef.current = mapState;

  const setValue = useCallback((name, value) => {
    dispatchToMapState({ type: actionTypes.setValue, name, value });
  }, []);

  const onLoad = useCallback(
    (map) => {
      setTimeout(() => setValue("map", map), 500);
    },
    [setValue]
  );

  const onUnmount = useCallback(() => {
    setValue("map", null);
  }, [setValue]);

  const onCenterChanged = useCallback(() => {
    if (!mapStateRef?.current?.map?.center?.lat() || !mapStateRef?.current?.map?.center?.lng()) return;

    const lat = mapStateRef.current.map.center.lat();
    const lng = mapStateRef.current.map.center.lng();

    if (mapStateRef.current.center.lat === lat && mapStateRef.current.center.lng === lng) return;

    setValue("center", { lat, lng });
  }, [setValue]);

  const onZoomChanged = useCallback(() => {
    if (!mapStateRef?.current?.map?.zoom || mapStateRef.current.map.zoom === mapStateRef.current.zoom) return;

    setValue("zoom", mapStateRef.current.map.zoom);
  }, [setValue]);

  const onLoadDrawingManager = useCallback(
    (drawingManager) => {
      setValue("drawingManager", drawingManager);
    },
    [setValue]
  );

  const onUnmountDrawingManager = useCallback(() => {
    setValue("drawingManager", null);
  }, [setValue]);

  const onMarkerComplete = useCallback((marker) => {
    console.log("onMarkerComplete: ", JSON.stringify(marker, null, 2));
  }, []);

  const onPolylineComplete = useCallback((polyline) => {
    console.log("onPolylineComplete: ", JSON.stringify(polyline, null, 2));
  }, []);

  const onRectangleComplete = useCallback((rectangle) => {
    console.log("onRectangleComplete: ", JSON.stringify(rectangle, null, 2));
  }, []);

  const onCircleComplete = useCallback((circle) => {
    console.log("onCircleComplete: ", JSON.stringify(circle, null, 2));
  }, []);

  const onPolygonComplete = useCallback((polygon) => {
    console.log("onPolygonComplete: ", JSON.stringify(polygon, null, 2));
  }, []);

  const toggleInfoBox = useCallback(
    (id) => {
      if (mapStateRef.current.infoBoxId === id) setValue("infoBoxId", null);
      else setValue("infoBoxId", id);
    },
    [setValue]
  );

  return {
    map: mapState.map,
    center: mapState.center,
    zoom: mapState.zoom,
    infoBoxId: mapState.infoBoxId,
    drawingManager: mapState.drawingManager,
    drawingMode: mapState.drawingMode,
    options: mapState.options,
    drawingOptions: mapState.drawingOptions,
    loading: !isLoaded,
    loadError,
    setValue,
    onLoad,
    onUnmount,
    onCenterChanged,
    onZoomChanged,
    onLoadDrawingManager,
    onUnmountDrawingManager,
    onMarkerComplete,
    onPolylineComplete,
    onRectangleComplete,
    onCircleComplete,
    onPolygonComplete,
    toggleInfoBox,
  };
};

export { useMapProvider };
