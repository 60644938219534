import styled from "styled-components";

const DivTopBar = styled.div`
  transition: opacity 0.5s ease-out, box-shadow 0.5s ease-out, color 1s ease-in-out, background-color 1s ease-in-out;
  position: fixed;
  min-height: fit-content;
  max-height: fit-content;
  top: 50px;
  right: 0px;
  left: 0px;
  background-color: ${({ theme }) => theme.foreground};
  border-bottom: 1px solid ${({ theme }) => theme.divLineDark};
`;

export default DivTopBar;
