import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { GoogleMap } from '@react-google-maps/api';
import { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import { useMap, useModel, useQueryParam } from '@thrivelot/hooks';
import { constructAddressObject } from '@thrivelot/common';
import { isEqual } from '@thrivelot/utils';
import { LeafLoader } from '@thrivelot/stories';
// import '@thrivelot/styles/index.css';
import { MapProjectElementHome } from '../MapProjectElementHome';
import { MapProjectElementZone } from '../MapProjectElementZone';
import { MapProjectInfoBox } from '../MapProjectInfoBox';

const constructReadableAddressString = (addressObject = {}) => {
  let address = '';

  if (!addressObject) return address;

  if (addressObject.line1 && addressObject.line1.trim() !== '') {
    address += `${addressObject.line1} `;
  }
  if (addressObject.line2 && addressObject.line2.trim() !== '') {
    address += `\n${addressObject.line2} `;
  }
  if (addressObject.city && !addressObject.city.trim() !== '') {
    address += `\n${addressObject.city}, `;
  }
  if (addressObject.state && addressObject.state.trim() !== '') {
    address += `${addressObject.state} `;
  }
  if (addressObject.zip && addressObject.zip.trim() !== '') {
    address += `${addressObject.zip}`;
  }

  return address;
};

const getSearchAddress = (formattedAddress) => {
  let searchAddress = '';

  for (let i = 0; i < formattedAddress.length; i++) {
    if (formattedAddress.charAt(i) === '\n') {
      searchAddress += ' ';
    } else {
      searchAddress += formattedAddress.charAt(i);
    }
  }

  return searchAddress;
};

const finishAddress = ({
  incompleteAddress,
  addressSearchResults,
  location,
}) => {
  let address = { ...incompleteAddress };

  if (!incompleteAddress.formattedAddress) {
    address = constructAddressObject(
      addressSearchResults[0].address_components
    );
    address.formattedAddress = addressSearchResults[0].formatted_address;
  }

  address.location = location;

  return address;
};

const getFullAddress = async (incompleteAddress) => {
  const formattedAddress = constructReadableAddressString(incompleteAddress);
  const searchAddress = getSearchAddress(formattedAddress);

  const addressSearchResults = await geocodeByAddress(searchAddress);
  const location = await getLatLng(addressSearchResults[0]);

  const address = finishAddress({
    incompleteAddress,
    addressSearchResults,
    location,
  });

  return address;
};

const Map = ({ projectId }) => {
  const {
    loading,
    loadError,
    map,
    center,
    options,
    zoom,
    onLoad,
    onUnmount,
    onCenterChanged,
    onZoomChanged,
    toggleInfoBox,
    setValue,
  } = useMap();
  const {
    model: project,
    actions,
    update,
    loaded,
  } = useModel({ modelName: 'Project', id: projectId });
  const { param, setParam } = useQueryParam('map');
  const centerRef = useRef();
  centerRef.current = center;

  const { addZone, zoneEditingId } = useMemo(() => param || {}, [param]);
  const { location } = useMemo(
    () => project?.details?.address || {},
    [project?.details?.address]
  );
  const zones = useMemo(() => project?.zones || [], [project?.zones]);

  const handleAddZone = useCallback(
    (location) => {
      const zone = {
        id: actions.constructUuid(),
        type: '_0',
        name: '',
        description: '',
        center: location,
        orderedChildTagIds: [],
      };
      update(actions.add('zones', zone).result);
      setParam(); // Removes all the map query params
      toggleInfoBox(zone.id);
    },
    [update, setParam, actions, toggleInfoBox]
  );

  const handleRelocateZone = useCallback(
    (location) => {
      update(actions.set(`zones[id:${zoneEditingId}].center`, location).result);
      setParam(); // Removes all the map query params
    },
    [update, setParam, actions, zoneEditingId]
  );

  const handleRelocateHomeAddress = useCallback(
    (location) => {
      update(actions.set('details.address.location', location).result);
      setParam(); // Removes all the map query params
    },
    [update, setParam, actions]
  );

  const onClick = useCallback(
    (event) => {
      const location = { lat: event.latLng.lat(), lng: event.latLng.lng() };

      if (addZone) handleAddZone(location);
      else if (zoneEditingId === 'address') handleRelocateHomeAddress(location);
      else if (zoneEditingId) handleRelocateZone(location);
    },
    [
      addZone,
      zoneEditingId,
      handleAddZone,
      handleRelocateHomeAddress,
      handleRelocateZone,
    ]
  );

  useEffect(() => {
    if (
      loading ||
      loadError ||
      !loaded ||
      !project?.details?.address?.line1 ||
      project?.details?.address?.location
    )
      return;

    let didCancel;

    (async () => {
      const address = await getFullAddress(project.details.address);
      if (!didCancel) update(actions.set('details.address', address).result);
    })();

    return () => {
      didCancel = true;
    };
  }, [project?.details?.address, loaded, loading, loadError, update, actions]);

  useEffect(() => {
    if (location && !centerRef.current && !isEqual(centerRef.current, location))
      setValue('center', location);
  }, [location, setValue]);

  if (!loaded || loading || loadError) return <LeafLoader />;

  return (
    <GoogleMap
      mapContainerClassName="absolute top-0 right-0 bottom-0 left-0"
      mapContainerStyle={{ position: 'absolute' }}
      options={options}
      zoom={zoom}
      center={center}
      onLoad={onLoad}
      onUnmount={onUnmount}
      onDragEnd={onCenterChanged}
      onZoomChanged={onZoomChanged}
      onClick={onClick}
    >
      {map && (
        <>
          {zones.map((zone) => (
            <MapProjectElementZone
              key={zone.id}
              zone={zone}
              projectId={projectId}
            />
          ))}
          <MapProjectElementHome location={location} projectId={projectId} />
          <MapProjectInfoBox projectId={projectId} />
        </>
      )}
    </GoogleMap>
  );
};

export { Map };
