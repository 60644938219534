import { API } from "@thrivelot/aws-amplify";
import { client } from "@thrivelot/model-kit";

const subscribe = ({ gql, variables, callback, queryName }) => {
  if (!client.queryNameRegex && !queryName) return { unsubscribe: () => {} };

  queryName = queryName || gql.toString().match(client.queryNameRegex)[1];

  return API.graphql({ query: gql, variables }).subscribe({
    next: (subResponse) => {
      callback({ data: subResponse.value.data[queryName] });
    },
    error: (err) => {
      console.warn(`Error in subscription ${queryName}: `, JSON.stringify(err, null, 2));

      callback({ error: err });
    },
  });
};

export { subscribe };
