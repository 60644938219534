import React from 'react';

const Tag = ({ color = 'green-main', size = 30, styleProps = '', ...rest }) => (
  <svg
    width={size.toString()}
    height={size.toString()}
    viewBox="0 0 75 75"
    className={`fill-${color} ${styleProps}`}
    {...rest}
  >
    <path d="M40.614 75C36.3418 75 32.0696 73.3349 28.8394 70.1089L7.26993 48.5672C4.76913 46.0696 3.20613 42.9476 2.58093 39.4094L0.184327 24.3198C-0.545073 19.8449 0.913728 15.162 4.14393 11.9359L11.8547 4.23502C15.0849 1.00897 19.7739 -0.552026 24.2546 0.176438L39.3636 2.56996C42.9064 3.19436 46.0324 4.85941 48.5332 7.357L70.1026 28.8987C73.2286 32.0207 75 36.1833 75 40.6582C75 45.133 73.2286 49.2957 70.1026 52.4176L52.3886 70.1089C49.1584 73.3349 44.8862 75 40.614 75ZM13.3135 13.3928L9.45814 17.2433C7.99934 18.8043 7.26993 20.9897 7.58253 23.1751L9.97914 38.2646C10.2917 40.1378 11.1253 41.907 12.5841 43.2598L34.1536 64.8015C35.925 66.5706 38.2174 67.5072 40.614 67.5072C43.0106 67.5072 45.303 66.5706 47.0744 64.8015L64.7884 47.1103C66.5598 45.3411 67.4976 43.0517 67.4976 40.6582C67.4976 38.2646 66.5598 35.9752 64.7884 34.2061L43.219 12.6644C41.8644 11.3115 40.093 10.3749 38.2174 10.0627L23.1084 7.6692C20.9201 7.357 18.7319 8.08547 17.1689 9.54239L13.3135 13.3928Z" />
    <path d="M24.2547 30.7719C22.4833 30.7719 20.9203 30.1475 19.6699 28.8987C18.4195 27.6499 17.7943 26.0889 17.7943 24.3198C17.7943 22.5507 18.4195 20.9897 19.6699 19.7409C20.9203 18.4921 22.4833 17.8677 24.2547 17.8677C26.0261 17.8677 27.5891 18.4921 28.8395 19.7409C30.0899 20.9897 30.7151 22.5507 30.7151 24.3198C30.7151 26.0889 30.0899 27.6499 28.8395 28.8987C27.5891 30.0434 25.9219 30.7719 24.2547 30.7719ZM24.2547 21.0937C23.4211 21.0937 22.5875 21.4059 21.9623 22.0303C21.3371 22.6547 21.0245 23.4872 21.0245 24.3198C21.0245 25.1523 21.3371 25.9848 21.9623 26.6092C23.2127 27.858 25.2967 27.858 26.5471 26.6092C27.1723 25.9848 27.4849 25.1523 27.4849 24.3198C27.4849 23.4872 27.1723 22.6547 26.5471 22.0303C25.9219 21.4059 25.0883 21.0937 24.2547 21.0937Z" />
  </svg>
);

export { Tag };
