import { lineItemColorMap } from "@thrivelot/config";
import React, { useMemo } from "react";
import { ItemKindIcon } from "./ItemKindIcon";

const ItemKindCounts = ({ items }) => {
  const itemKindCounts = useMemo(() => {
    const counts = {
      equipment: 0,
      labor: 0,
      material: 0,
      plant: 0,
    };

    items.forEach((item) => {
      counts[item.kind] = counts[item.kind] || 0;
      counts[item.kind] += item.quantity;
    });

    return counts;
  }, [items]);

  return (
    <>
      {Object.keys(itemKindCounts).map((kind) => (
        <div key={kind} className={`flex items-center gap-1 text-lg font-bold text-${lineItemColorMap[kind]}`}>
          <ItemKindIcon size={18} kind={kind} />
          {itemKindCounts[kind]}
        </div>
      ))}
    </>
  );
};

export { ItemKindCounts };
