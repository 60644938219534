import { useCallback, useEffect, useMemo, useRef } from 'react';
import { Plant } from '@thrivelot/aws';
import { plantClient, PlantKit } from '@thrivelot/plant-kit';
import { usePlantsContext } from './usePlantsContext';
import { constants } from './constants';

interface PlantOpts {
  id?: string;
  model?: { [key: string]: any };
  deferLoad?: boolean;
}

export const usePlant = (opts?: PlantOpts) => {
  const {
    state,
    init: initFn,
    get: getFn,
    remove: removeFn,
    update: updateFn,
  } = usePlantsContext();

  let { id } = opts || {};
  const { model: externalModel, deferLoad } = opts || {};

  id = id || externalModel?.id;
  if (!id) throw Error('usePlant requires an id or model.id');

  const { model: originalModel, draft, loading, saving } = state[id] || {};
  const model = { ...(originalModel || {}), ...(draft || {}) };

  const idRef = useRef(id);
  const externalModelRef = useRef(externalModel);
  const deferLoadRef = useRef(deferLoad);

  const actions = useMemo(() => plantClient.actionsFactory(model), [model]);

  const get = useCallback(() => getFn(idRef.current), [getFn]);

  const remove = useCallback(() => removeFn(idRef.current), [removeFn]);

  const update = useCallback(
    (updated: { [key: string]: any }) => updateFn(idRef.current, updated),
    [updateFn]
  );

  const uploadImage = useCallback(
    async (base64Image: string, metadata?: { [key: string]: any }) => {
      const plantKit = new PlantKit({ model: model as Plant });
      const uploadedImage = await plantKit.uploadImage(base64Image, metadata);
      return uploadedImage;
    },
    [model]
  );

  const uploadImageFromUrl = useCallback(
    async (url: string, metadata?: { [key: string]: any }) => {
      const plantKit = new PlantKit({ model: model as Plant });
      const uploadedImage = await plantKit.uploadImageFromUrl(url, metadata);
      return uploadedImage;
    },
    [model]
  );

  const uploadMultipleImages = useCallback(
    async (
      uploading: {
        base64Image?: string;
        url?: string;
        metadata?: { [key: string]: any };
      }[]
    ) => {
      const plantKit = new PlantKit({ model: model as Plant });
      const uploadedImages = await plantKit.uploadMultipleImages(uploading);
      return uploadedImages;
    },
    [model]
  );

  const getImage = useCallback(
    (key: string) => {
      const plantKit = new PlantKit({ model: model as Plant });
      return plantKit.getImage(key);
    },
    [model]
  );

  const listImages = useCallback(() => {
    const plantKit = new PlantKit({ model: model as Plant });
    return plantKit.listImages();
  }, [model]);

  useEffect(() => {
    if (externalModelRef.current) initFn(externalModelRef.current);
    else if (!deferLoadRef.current) getFn(idRef.current);
  }, [initFn, getFn]);

  return {
    model: model as Plant,
    actions,
    loading,
    saving,
    get,
    remove,
    update,
    uploadImage,
    uploadImageFromUrl,
    uploadMultipleImages,
    getImage,
    listImages,
    constants,
  };
};
