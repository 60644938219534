import React from "react";
import { ButtonRaised, DivColumn, DivListRow, DivLineHorizontal, TextHeader } from "@thrivelot/styles";
import { useSubmitProject } from "@thrivelot/common";

const Submit = ({ projectId }) => {
  // hooks
  const { title, buttonTitle, submitType, submit } = useSubmitProject({ projectId });
  // breakout case
  if (!submitType) return null;
  // return
  return (
    <DivColumn>
      <DivListRow
        style={{
          padding: "20px 12px 0px",
          width: "100%",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <TextHeader>{title}</TextHeader>
        <ButtonRaised done onClick={submit}>
          {buttonTitle}
        </ButtonRaised>
      </DivListRow>
      <DivLineHorizontal style={{ marginTop: "20px" }} />
    </DivColumn>
  );
};

export { Submit };
