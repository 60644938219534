import React, { useState } from "react";
import { DivColumn, DivContentRow, DivLineHorizontal, Select } from "@thrivelot/styles";

const NewFeature = ({ featureOptions, addFeature }) => {
  const [option, setOption] = useState();

  const add = ({ value: overrideValue = null }) => {
    let value = overrideValue;
    if (!value) ({ value } = option);

    addFeature(value);

    setOption(null);
  };

  return (
    <DivColumn style={{ marginBottom: "45px" }}>
      <DivContentRow style={{ display: "flex", alignItems: "center" }}>
        <Select
          placeholder="Select feature..."
          options={featureOptions}
          value={option}
          onChange={add}
          style={{ width: "100%" }}
        />
      </DivContentRow>
      <DivLineHorizontal />
    </DivColumn>
  );
};

export { NewFeature };
