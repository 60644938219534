import React from 'react';

const CheckMinus = ({
  color = 'green-main',
  size = 30,
  styleProps = '',
  ...rest
}) => (
  <svg
    width={size.toString()}
    height={size.toString()}
    viewBox="0 0 75 75"
    className={`fill-${color} ${styleProps}`}
    {...rest}
  >
    <path d="M60.1098 75H14.8902C6.67683 75 0 68.3232 0 60.1098V14.8902C0 6.67683 6.67683 0 14.8902 0H60.1098C68.3232 0 75 6.67683 75 14.8902V60.1098C75 68.3232 68.3232 75 60.1098 75ZM14.8902 9.14634C11.7256 9.14634 9.14634 11.7256 9.14634 14.8902V60.1098C9.14634 63.2744 11.7256 65.8537 14.8902 65.8537H60.1098C63.2744 65.8537 65.8537 63.2744 65.8537 60.1098V14.8902C65.8537 11.7256 63.2744 9.14634 60.1098 9.14634H14.8902Z" />
    <path d="M53.9634 43.9025H21.0366C17.5061 43.9025 14.6342 41.0306 14.6342 37.5001C14.6342 33.9696 17.5061 31.0977 21.0366 31.0977H53.9634C57.4939 31.0977 60.3659 33.9696 60.3659 37.5001C60.3659 41.0306 57.4939 43.9025 53.9634 43.9025Z" />
  </svg>
);

export { CheckMinus };
