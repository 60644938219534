import React, { useMemo } from "react";
import { Card } from "@thrivelot/stories";
import { projectStyles as availableStyles } from "@thrivelot/config";
import { useModal, useModel } from "@thrivelot/hooks";
import { CardLoader } from "@thrivelot/stories";
import { ImgRectangle } from "@thrivelot/styles";
import { Edit } from "@thrivelot/stories";

// ! Possibly deprecated since this is built for the old customer form

const CardProjectStyles = ({ projectId, editable }) => {
  const { openModal } = useModal();

  const { model: project, loaded } = useModel({ modelName: "Project", id: projectId });

  const includedStyles = useMemo(() => {
    if (project?.details?.styles) {
      return project.details.styles
        .map((style) => {
          const key = Object.keys(availableStyles).find((key) => availableStyles[key].name === style.name);
          if (availableStyles[key])
            return {
              ...style,
              key,
              imageUrl: availableStyles[key].imageUrl,
            };
          return null;
        })
        .filter((item) => !!item);
    }
    return [];
  }, [project?.details?.styles]);

  if (!loaded) return <CardLoader showText />;

  if (includedStyles.length === 0) {
    return (
      <Card classAppend="mb-4">
        <div className="flex">
          <div className="flex-1 italic flex text-sm items-center text-brown-dark">
            No <span className="font-bold mx-1">styles</span> to show
          </div>
          {editable && (
            <Edit
              color="yellow-dark"
              styleProps="hover:cursor-pointer"
              size="20"
              onClick={() => openModal({ path: "ModalEditStyles", projectId })}
            />
          )}
        </div>
      </Card>
    );
  }

  return (
    <Card
      header="Styles"
      headerActions={
        editable && (
          <Edit
            color="yellow-dark"
            styleProps="hover:cursor-pointer"
            size="20"
            onClick={() => openModal({ path: "ModalEditStyles", projectId })}
          />
        )
      }
      classAppend="mb-4"
    >
      <div className="divide-y divide-tan-light">
        {includedStyles.map(({ key, imageUrl, name }) => (
          <div key={key} className="p-1.5">
            <div className="flex items-center">
              <div className="mr-2">
                <ImgRectangle size={20} src={{ url: imageUrl }} />
              </div>
              <div>
                <div className="text-brown-dark">{name}</div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </Card>
  );
};

export { CardProjectStyles };
