import { client } from './client';

const constructActions = (modelName) => {
  if (modelName) return client.modelConfig.models[modelName].actions;

  console.warn('Unable to construct actions in model-kit');

  return null;
};

export { constructActions };
