import React from "react";
import { useProposal } from "@thrivelot/common";
import { SiteAnalysisContainer } from "./SiteAnalysisContainer";
import { Note } from "./Note";

const SiteAnalysis = () => {
  const { projectActions } = useProposal();
  const siteAnalysis = projectActions.get("siteAnalysis");
  if (!siteAnalysis) return null;
  return (
    <SiteAnalysisContainer>
      {siteAnalysis.map((note) => (
        <Note key={note.id} note={note} />
      ))}
    </SiteAnalysisContainer>
  );
};

export { SiteAnalysis };
