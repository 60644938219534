import React from 'react';

const LineItems = ({
  color = 'green-main',
  size = 30,
  styleProps = '',
  ...rest
}) => (
  <svg
    width={size.toString()}
    height={size.toString()}
    viewBox="0 0 75 75"
    className={`fill-${color} ${styleProps}`}
    {...rest}
  >
    <path d="M61.7089 0H13.2911C5.98101 0 0 5.98101 0 13.2911V61.7089C0 69.019 5.98101 75 13.2911 75H61.7089C69.019 75 75 69.019 75 61.7089V13.2911C75 5.98101 69.019 0 61.7089 0ZM31.3291 6.64557H61.7089C65.4114 6.64557 68.3544 9.58861 68.3544 13.2911V26.5823H31.3291V6.64557ZM31.3291 31.3291H68.3544V42.2468H31.3291V31.3291ZM31.3291 45.0949H68.3544V55.538H31.3291V45.0949ZM26.5823 68.3544H13.2911C9.58861 68.3544 6.64557 65.4114 6.64557 61.7089V58.3861H26.5823V68.3544ZM26.5823 55.538H6.64557V45.0949H26.5823V55.538ZM26.5823 42.2468H6.64557V31.3291H26.5823V42.2468ZM26.5823 26.5823H6.64557V13.2911C6.64557 9.58861 9.58861 6.64557 13.2911 6.64557H26.5823V26.5823ZM68.3544 61.7089C68.3544 65.3165 65.4114 68.3544 61.7089 68.3544H31.3291V58.3861H68.3544V61.7089Z" />
    <path d="M20.317 18.6077C20.317 20.1267 19.3676 21.076 17.6587 21.3608V22.595C17.6587 22.7849 17.4689 22.9748 17.279 22.9748H15.76C15.5701 22.9748 15.3803 22.7849 15.3803 22.595V21.3608C14.1461 21.171 13.1018 20.5064 12.3423 19.557C12.2473 19.4621 12.2473 19.1773 12.3423 19.0824L13.2917 18.133C13.3866 18.0381 13.4815 18.0381 13.5765 18.0381C13.6714 18.0381 13.7663 18.0381 13.8613 18.133C14.6208 18.9874 15.4752 19.3672 16.6144 19.3672C17.5638 19.3672 18.0385 19.0824 18.0385 18.7026C18.0385 18.3229 17.9436 18.0381 16.3296 17.7532C14.5258 17.4684 12.722 16.8988 12.722 14.7153C12.722 13.2912 13.7663 12.2469 15.3803 11.9621V10.8229C15.3803 10.633 15.5701 10.4431 15.76 10.4431H17.279C17.4689 10.4431 17.6587 10.633 17.6587 10.8229V11.9621C18.6081 12.152 19.4625 12.6267 20.1271 13.4811C20.222 13.576 20.222 13.8608 20.1271 13.9558L19.1777 14.9051C19.0828 15.0001 18.9879 15.0001 18.8929 15.0001C18.798 15.0001 18.703 15.0001 18.6081 14.9051C17.8486 14.1456 17.279 13.9558 16.4246 13.9558C16.0448 13.9558 15.0005 14.0507 15.0005 14.6203C15.0005 15.095 15.2853 15.1899 16.8043 15.4748C18.6081 15.7596 20.317 16.4241 20.317 18.6077Z" />
  </svg>
);

export { LineItems };
