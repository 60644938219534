import React from 'react';
import { LayoutModal } from '../..';
import { ImgCircle } from '@thrivelot/styles';
import { Leaf } from '@thrivelot/stories';
import TextareaAutosize from 'react-textarea-autosize';

const ModalEditFeaturePlantDetails = ({ onClose, projectId, plantId }) => {
  return null;
  // const { plant, loadedPlantFromDatabase, loadedProject, setChangeValue, setValue } = useModelPlant({
  //   plantId,
  //   projectId,
  // });

  // const loaded = loadedProject && loadedPlantFromDatabase;
  // const { imageUrl, commonName, scientificName, planting, growth, projectProps } = plant || {};
  // const { sowingDescription } = planting || {};
  // const { growingDescription } = growth || {};
  // const { notes } = projectProps || {};

  // return (
  //   <LayoutModal
  //     width="md"
  //     onClose={onClose}
  //     loading={!loaded}
  //     header={loaded && commonName && <div className="text-lg text-brown-dark font-bold text-center">{commonName}</div>}
  //     subheader={
  //       loaded && commonName && <div className="text-sm text-brown-dark italic text-center">{scientificName}</div>
  //     }
  //   >
  //     <div className="flex justify-center">
  //       {imageUrl && <ImgCircle src={{ url: imageUrl }} size={120} style={{ zIndex: 5 }} />}
  //       {!imageUrl && (
  //         <div className="border-4 border-green-main h-28 w-28 rounded-full flex items-center justify-center">
  //           <Leaf size={80} color="green-main" styleProps="pl-3" />
  //         </div>
  //       )}
  //     </div>

  //     <div className="flex flex-col gap-2 mt-5 pt-5 border-t border-tan-light">
  //       <div className="text-brown-dark">
  //         <div className="text-sm font-bold mb-1">Common Name</div>
  //         <input
  //           className="input"
  //           placeholder="Add a common name..."
  //           value={commonName || ""}
  //           onChange={(e) => setChangeValue("commonName", e.target.value)}
  //         />
  //       </div>
  //       <div className="text-brown-dark">
  //         <div className="text-sm font-bold mb-1">Scientific Name</div>
  //         <input
  //           className="input"
  //           placeholder="Add a scientific name..."
  //           value={scientificName || ""}
  //           onChange={(e) => setChangeValue("scientificName", e.target.value)}
  //         />
  //       </div>
  //       <div className="text-brown-dark">
  //         <div className="text-sm font-bold mb-1">Image Url</div>
  //         <input
  //           className="input"
  //           placeholder="Add an image url..."
  //           value={imageUrl || ""}
  //           onChange={(e) => setChangeValue("imageUrl", e.target.value)}
  //         />
  //       </div>
  //     </div>

  //     <div className="flex flex-col gap-2 mt-5 pt-5 border-t border-tan-light">
  //       <div className="text-brown-dark">
  //         <div className="text-sm font-bold mb-1">Growing Description</div>
  //         <TextareaAutosize
  //           className="input"
  //           placeholder="Add a growing description..."
  //           minRows={2}
  //           value={growingDescription || ""}
  //           onChange={(e) => setChangeValue("growth.growingDescription", e.target.value)}
  //         />
  //       </div>
  //       <div className="text-brown-dark">
  //         <div className="text-sm font-bold mb-1">Sowing Description</div>
  //         <TextareaAutosize
  //           className="input"
  //           placeholder="Add a sowing description..."
  //           minRows={2}
  //           value={sowingDescription || ""}
  //           onChange={(e) => setChangeValue("planting.sowingDescription", e.target.value)}
  //         />
  //       </div>
  //       <div className="text-brown-dark">
  //         <div className="text-sm font-bold mb-1">Notes</div>
  //         <TextareaAutosize
  //           className="input"
  //           placeholder="Add some notes..."
  //           minRows={2}
  //           value={notes || ""}
  //           onChange={(e) => setValue("notes", e.target.value)}
  //         />
  //       </div>
  //     </div>
  //   </LayoutModal>
  // );
};

export { ModalEditFeaturePlantDetails };
