const metaMap = {
  single_payment_1: {
    stage: 'Single',
    direction: 'Payment',
    part: '1/1',
    description: '100% single payment paid up front',
  },
  single_payout_1: {
    stage: 'Single',
    direction: 'Payout',
    part: '1/1',
    shortDescription: 'Single Payout 1/1',
  },
  design_payment_1: {
    stage: 'Design',
    direction: 'Payment',
    part: '1/1',
    name: 'Design Payment',
    description: '%100 design fee paid up front',
  },
  design_payout_1: {
    stage: 'Design',
    direction: 'Payout',
    part: '1/2',
    description: '1st Payout - 50% for Design',
    shortDescription: 'Design Payout 1/2',
  },
  design_payout_2: {
    stage: 'Design',
    direction: 'Payout',
    part: '2/2',
    description: '2nd Payout - 50% for Design',
    shortDescription: 'Design Payout 2/2',
  },
  time_materials_payment_1: {
    stage: 'Install',
    direction: 'Payment',
    part: '1/2',
    name: 'Time & Materials First Payment',
    description:
      '%100 of plants, material, and equipment plus 50% of labor for install',
  },
  time_materials_payment_2: {
    stage: 'Install',
    direction: 'Payment',
    part: '2/2',
    name: 'Time & Materials Second Payment',
    description: '50% of labor, plus or minus any approved changes for install',
  },
  time_materials_payout_1: {
    stage: 'Install',
    direction: 'Payout',
    part: '1/2',
    description:
      '1st Payout - %100 of plants, material, and equipment plus 50% of labor',
    shortDescription: 'Payout Install 1/2',
  },
  time_materials_payout_2: {
    stage: 'Install',
    direction: 'Payout',
    part: '2/2',
    description:
      '2nd Payout - 50% of labor, plus or minus any approved changes',
    shortDescription: 'Payout Install 2/2',
  },
};

export { metaMap };
