import React from 'react';
import { transformStringToTitleCase } from '@thrivelot/utils';
import { Cancel } from '@thrivelot/stories';

const colorMap = {
  certificates: 'blue-main',
  degrees: 'purple-main',
  skills: 'green-main',
};

const Group = ({ index, type, qualifications, deleteQualification }) => {
  if (type === '__typename') return null;
  return (
    <div className="my-4">
      <div className={`font-bold mr-3 mb-2 text-${colorMap[type]}`}>
        {transformStringToTitleCase(type)}
      </div>
      <div className="flex flex-wrap gap-1">
        {qualifications &&
          Array.isArray(qualifications) &&
          qualifications.map((qualification) => (
            <div
              key={qualification}
              className={`flex break-inside-avoid items-center gap-2 border-2 px-2 font-bold rounded-full border-${colorMap[type]} text-${colorMap[type]}`}
            >
              {qualification}
              <Cancel
                styleProps="hover:cursor-pointer"
                size={15}
                color="red-main"
                onClick={() => deleteQualification(type, qualification)}
              />
            </div>
          ))}
      </div>
    </div>
  );
};

export default Group;
