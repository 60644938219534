import React, { useCallback } from "react";
import { Circle } from "@react-google-maps/api";
import colors from "@thrivelot/tailwind/colors";
import { useProposalView } from "@thrivelot/common";

const MapProposalElementZone = ({ zone }) => {
  const { zone: zoneId, setValue } = useProposalView();

  const onClick = useCallback(() => {
    setValue("zone", zone.id);
  }, [zone?.id, setValue]);

  if (!zone?.center) return null;

  const isRelocating = zoneId === zone.id;
  const color = colors.zonesMap[zone.type];
  const options = {
    clickable: true,
    strokeColor: color,
    fillColor: color,
    fillOpacity: isRelocating ? 1 : 0.5,
  };

  return <Circle center={zone.center} radius={5} options={options} onClick={onClick} />;
};

export { MapProposalElementZone };
