import React from "react";
import { useTheme } from "@thrivelot/hooks";
import { useProposal, useProposalView } from "@thrivelot/common";
import { DivLineHorizontal, TextBody, TextSmallHeader } from "@thrivelot/styles";
import { useSpacing } from "../../../../hooks";

const Description = () => {
  const { projectActions } = useProposal();
  const { phase: phaseId } = useProposalView();
  const theme = useTheme();
  const spacing = useSpacing();
  if (!phaseId) return null;
  const description = projectActions.get(`phases[id:${phaseId}].description`);
  if (!description || description.length === 0) return null;
  return (
    <>
      <DivLineHorizontal
        style={{
          margin: `0px ${spacing}`,
          width: `calc(100% - ${spacing} - ${spacing})`,
        }}
      />
      <TextSmallHeader style={{ margin: `${spacing} ${spacing} 8px`, color: theme.textLabel }}>
        Description
      </TextSmallHeader>
      <TextBody
        style={{
          minHeight: "fit-content",
          maxHeight: "fit-content",
          margin: `0px ${spacing} ${spacing}`,
          color: theme.textTitle,
        }}
      >
        {description}
      </TextBody>
    </>
  );
};

export { Description };
