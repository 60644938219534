/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cloud_logic_custom": [
        {
            "name": "Services",
            "endpoint": "https://mk2803qpzl.execute-api.us-east-1.amazonaws.com/maple",
            "region": "us-east-1"
        },
        {
            "name": "Tasks",
            "endpoint": "https://nktsqlvi48.execute-api.us-east-1.amazonaws.com/maple",
            "region": "us-east-1"
        },
        {
            "name": "Webhooks",
            "endpoint": "https://ioa2lotw39.execute-api.us-east-1.amazonaws.com/maple",
            "region": "us-east-1"
        }
    ],
    "aws_appsync_graphqlEndpoint": "https://npc2go2h6jgrjd7houm3arlalq.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_appsync_apiKey": "da2-fp4d3uauebeanjaz4hckla3huq",
    "aws_cognito_identity_pool_id": "us-east-1:04c7eda3-bca7-4616-97b6-095f7c64cbb7",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_LZPGpreDw",
    "aws_user_pools_web_client_id": "102ckk24ndhld4sqa6j1d05ki6",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "PHONE_NUMBER"
    ],
    "aws_cognito_mfa_configuration": "OPTIONAL",
    "aws_cognito_mfa_types": [
        "SMS",
        "TOTP"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "thrivelot-storage154430-maple",
    "aws_user_files_s3_bucket_region": "us-east-1",
    "aws_cognito_login_mechanisms": [
        "EMAIL",
        "PHONE_NUMBER"
    ]
};


export default awsmobile;
