import React, { useMemo } from 'react';
import { useModel } from '@thrivelot/hooks';
import {
  LeafLoader,
  RowItemLoader,
  Button,
  EmptyState,
} from '@thrivelot/stories';
import { AreaFeaturesGroup } from './AreaFeaturesGroup';
import { ArealessFeatures } from './ArealessFeatures';

const ProjectFeaturesGroupedByArea = ({ projectId }) => {
  const {
    model: project,
    loaded,
    updateModel,
    actions,
  } = useModel({ modelName: 'Project', id: projectId });

  const sortedZones = useMemo(
    () =>
      project?.zones &&
      project.zones.sort((a, b) =>
        parseInt(a.type.substring(1), 10) > parseInt(b.type.substring(1), 10)
          ? 1
          : -1
      ),
    [project?.zones]
  );

  if (!loaded)
    return (
      <div>
        <div className="flex">
          <LeafLoader />
        </div>
        <RowItemLoader />
      </div>
    );

  if ((project?.features || []).filter((f) => !f.hidden).length === 0)
    return (
      <EmptyState
        icon="LineItems"
        header="No features here yet..."
        children={<div>Get started by creating a feature...</div>}
        footer={
          <div>
            <Button
              color="blue-main"
              kind="outline"
              icon="Plus"
              label="Add Feature"
              onClick={() =>
                updateModel(
                  actions.add('features', actions.constructFeature({}), 0)
                    .result
                )
              }
            />
          </div>
        }
      />
    );

  return (
    <div className="flex flex-col gap-6">
      <ArealessFeatures projectId={projectId} />
      {sortedZones.map(
        ({ id }) =>
          id && <AreaFeaturesGroup key={id} projectId={projectId} zoneId={id} />
      )}
    </div>
  );
};

export { ProjectFeaturesGroupedByArea };
