import React, { Fragment, useMemo } from "react";
import { useToggle } from "react-use";
import { Droppable } from "react-beautiful-dnd";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import colors from "@thrivelot/tailwind/colors";
import { dateFns } from "@thrivelot/date";
import { duplicateObject, transformCurrencyToString } from "@thrivelot/utils";
import { useModel, useIsMobile } from "@thrivelot/hooks";
import { getWorkPhaseItems, calculateTotalCost, transformArrToObjById } from "@thrivelot/common";
import { TagIdType } from "@thrivelot/aws";
import {
  DivColumn,
  DivInset,
  DivListRow,
  DivLineHorizontal,
  TextBodyBold,
  TextSmallHeader,
  InputUnderline,
} from "@thrivelot/styles";
import { DeleteItem, DropIconMap } from "../../shared";
import { DateIcon } from "./DateIcon";
import { DropdownContainer } from "./DropdownContainer";

const AmountRow = ({ isMobile, totalCost, startDate, endDate, updatePhaseDate }) => {
  const theme = window.$theme;

  const dateFormat = isMobile ? "M/D/YY" : "MMM D, YYYY";

  const phaseDateContainerStyle = {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    flexDirection: "row",
    minWidth: isMobile ? "86px" : "160px",
    maxWidth: isMobile ? "86px" : "160px",
    minHeight: "35px",
    maxHeight: "35px",
    padding: "0px 8px",
    marginRight: "12px",
  };

  const amountContainerStyle = {
    display: "flex",
    flexDirection: "row",
    padding: "0px 8px",
    minHeight: "35px",
    maxHeight: "35px",
    minWidth: "54px",
    maxWidth: "54px",
    alignItems: "center",
    justifyContent: "space-between",
    marginRight: !isMobile ? "12px" : "20px",
  };

  return (
    <Fragment>
      <div style={{ display: "flex", alignItems: "center" }}>
        <DivInset style={phaseDateContainerStyle}>
          <DatePicker
            popperPlacement="bottom-start"
            showPopperArrow={false}
            selected={dateFns(startDate).toDate()}
            onChange={(date) => updatePhaseDate({ startDate: date })}
            customInput={<DateIcon isStart />}
          />
          <TextBodyBold
            style={{
              maxHeight: "fit-content",
              minWidth: "fit-content",
              maxWidth: "fit-content",
              fontSize: isMobile ? "12px" : undefined,
            }}
          >
            {dateFns(startDate).format(dateFormat)}
          </TextBodyBold>
        </DivInset>
        <DivInset style={phaseDateContainerStyle}>
          <DatePicker
            popperPlacement="bottom-start"
            showPopperArrow={false}
            selected={dateFns(endDate).toDate()}
            onChange={(date) => updatePhaseDate({ endDate: date })}
            customInput={<DateIcon />}
          />
          <TextBodyBold
            style={{
              maxHeight: "fit-content",
              minWidth: "fit-content",
              maxWidth: "fit-content",
              fontSize: isMobile ? "12px" : undefined,
            }}
          >
            {dateFns(endDate).format(dateFormat)}
          </TextBodyBold>
        </DivInset>
      </div>
      <DivInset
        style={{
          ...amountContainerStyle,
          minWidth: "76px",
          maxWidth: "76px",
          justifyContent: "center",
          marginRight: isMobile ? "25px" : "12px",
        }}
      >
        <TextSmallHeader color={theme.green}>{transformCurrencyToString(totalCost)}</TextSmallHeader>
      </DivInset>
    </Fragment>
  );
};

const WorkPhase = ({ phaseId, projectId }) => {
  const { model, updateModel } = useModel({ modelName: "Project", id: projectId });

  const getPhases = () => {
    if (!model.phases) return [];
    return model.phases;
  };
  const phases = useMemo(getPhases, [model.phases]);

  const [isOpen, toggleIsOpen] = useToggle(false);

  const isMobile = useIsMobile();

  const updatePhase = (updated) => {
    const workPhases = duplicateObject(phases);
    const index = workPhases.findIndex((p) => p.id === phaseId);

    if (index === -1) return;

    Object.keys(updated).forEach((key) => {
      workPhases[index][key] = updated[key];
    });

    updateModel({ phases: workPhases });
  };

  const updatePhaseDate = ({ startDate, endDate }) => {
    const workPhases = duplicateObject(phases);
    const index = workPhases.findIndex((p) => p.id === phaseId);

    if (index === -1) return;

    const key = startDate ? "startDate" : "endDate";
    const date = startDate || endDate;
    workPhases[index][key] = date.toISOString();

    if (startDate) {
      if (Math.abs(dateFns(date).diff(dateFns(workPhases[index].endDate), "days")) > 35)
        workPhases[index].endDate = dateFns(date).add(35, "days").toISOString();
    }

    updateModel({ phases: workPhases });
  };

  const deletePhase = () => {
    const updated = {};

    const workPhases = duplicateObject(phases);
    const index = workPhases.findIndex((p) => p.id === phaseId);

    if (index === -1) return;

    workPhases.splice(index, 1);

    const costPaymentSchedule = duplicateObject(model.costPaymentSchedule);
    const cpsIndex = costPaymentSchedule.findIndex((p) => {
      if (p.tagIds && p.tagIds.find((tag) => tag.tagId === phaseId)) return true;
      return false;
    });

    if (cpsIndex !== -1) {
      costPaymentSchedule.splice(cpsIndex, 1);
      updated.costPaymentSchedule = costPaymentSchedule;
    }

    updated.phases = workPhases;

    updateModel(updated);
  };

  const addFeature = (featureId) => {
    const workPhases = duplicateObject(phases);
    const index = workPhases.findIndex((p) => p.id === phaseId);

    if (index === -1) return;

    const tagId = {
      type: TagIdType.FEATURE,
      tagId: featureId,
    };

    if (workPhases[index].orderedChildTagIds) workPhases[index].orderedChildTagIds.push(tagId);
    else workPhases[index].orderedChildTagIds = [tagId];

    updateModel({ phases: workPhases });
  };

  const deleteFeature = (featureId) => {
    const workPhases = duplicateObject(phases);
    const index = workPhases.findIndex((p) => p.id === phaseId);

    if (index === -1) return;

    const { orderedChildTagIds } = workPhases[index];
    const childIndex = orderedChildTagIds.findIndex((child) => child.tagId === featureId);

    if (childIndex === -1) return;

    orderedChildTagIds.splice(childIndex, 1);
    workPhases[index].orderedChildTagIds = orderedChildTagIds;

    updateModel({ phases: workPhases });
  };

  const handleDragOver = () => {
    if (!isOpen) toggleIsOpen(true);
  };

  const getPhase = () => {
    if (!phases) return undefined;

    const phase = phases.find((p) => p.id === phaseId);
    return phase;
  };

  const phase = getPhase();

  if (!phase) return null;

  const { name, startDate, endDate } = phase;

  const getFeatures = () => {
    const mappedFeatures = transformArrToObjById(model.features);

    const { orderedChildTagIds } = phase;
    if (!orderedChildTagIds) return [];
    if (!mappedFeatures) return [];

    const features = [];

    orderedChildTagIds.forEach((tag) => {
      features.push(mappedFeatures[tag.tagId]);
    });

    return features;
  };

  const getPhaseCostTotal = () => {
    const features = getFeatures();
    if (!features) return 0;

    let total = 0;

    features.forEach((feature) => {
      if (!feature) return;
      const { orderedChildTagIds: tagIds } = feature;
      const items = getWorkPhaseItems({ tagIds, project: model });
      const totalCost = calculateTotalCost(items);
      total += totalCost;
    });

    total = Math.ceil(total / 100) * 100;
    return total;
  };

  const totalCost = getPhaseCostTotal();

  const DropIcon = DropIconMap[isOpen ? "up" : "down"];

  return (
    <Droppable droppableId={phase.id} direction="vertical">
      {(droppableProvided) => (
        <DivColumn id={phase.id} ref={droppableProvided.innerRef} {...droppableProvided.droppableProps}>
          <DivListRow
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: isOpen || isMobile ? "5px" : "20px",
            }}
            onDragOver={handleDragOver}
          >
            <InputUnderline
              noUnderline
              isTitle
              placeholder="Name"
              value={name}
              onChange={(e) => updatePhase({ name: e.target.value })}
              style={{
                fontSize: isMobile ? "14px" : undefined,
                marginRight: isMobile ? "12px" : "20px",
                width: "100%",
              }}
            />
            {!isMobile && (
              <AmountRow
                isMobile={isMobile}
                totalCost={totalCost}
                startDate={startDate}
                endDate={endDate}
                updatePhaseDate={updatePhaseDate}
              />
            )}
            <DropIcon
              hoverable
              color={colors.yellow.dark}
              size={35}
              style={{ marginRight: isMobile ? "12px" : "20px" }}
              onClick={toggleIsOpen}
            />
            <DeleteItem onClick={deletePhase} />
          </DivListRow>
          {isMobile && (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                marginBottom: isOpen ? "12px" : "20px",
                marginTop: "7px",
                marginRight: "-12px",
              }}
            >
              <AmountRow
                isMobile={isMobile}
                totalCost={totalCost}
                startDate={startDate}
                endDate={endDate}
                updatePhaseDate={updatePhaseDate}
              />
            </div>
          )}
          {isOpen && (
            <DropdownContainer
              model={model}
              updatePhase={updatePhase}
              addFeature={addFeature}
              deleteFeature={deleteFeature}
              placeholder={droppableProvided.placeholder}
              {...phase}
            />
          )}
          <DivLineHorizontal isDark />
        </DivColumn>
      )}
    </Droppable>
  );
};

export { WorkPhase };
