import { useModal, useModel } from '@thrivelot/hooks';
import { Button, EmptyState, LeafLoader, Pill } from '@thrivelot/stories';
import { constructUuid } from '@thrivelot/utils';
import React from 'react';
import { Link } from 'react-router-dom';
import { FormBundleFeature } from '../FormBundleFeature';
import { BundleSupplier } from './BundleSupplier';

const FormBundle = ({ bundleId, projectId }) => {
  const { openModal } = useModal();
  const {
    model: bundle,
    loaded,
    updateModel,
    actions,
  } = useModel({ modelName: 'Bundle', id: bundleId });

  const { model: project, loaded: projectLoaded } = useModel({
    modelName: 'Project',
    id: projectId,
  });

  if (!loaded || !projectLoaded)
    return (
      <div>
        <LeafLoader />
      </div>
    );

  const {
    status,
    orderedFeatureData,
    orderedFeatureIds,
    lineItems,
    changeItems,
  } = bundle || {};

  return (
    <div>
      <div className="mb-6">
        <div className="flex items-start">
          <div className="flex-1 text-brown-dark font-bold text-2xl flex items-center gap-2">
            <div>Invoice Details</div>
            {status === 'unlocked' && (
              <Pill size="sm" color="green-main">
                Unlocked
              </Pill>
            )}
            {status === 'changes_only' && (
              <Pill size="sm" color="blue-main">
                Changes Only
              </Pill>
            )}
            {status === 'locked' && (
              <Pill size="sm" color="red-main">
                Locked
              </Pill>
            )}
          </div>
          <div>
            <div className="flex items-end gap-4">
              {status === 'unlocked' && (
                <Link to="features">
                  <Button
                    icon="Feature"
                    kind="outline"
                    size="small"
                    color="blue-main"
                    label="Manage Features"
                  />
                </Link>
              )}
              {!bundle.supplierPayeeId && (
                <Button
                  icon="Plus"
                  kind="outline"
                  size="small"
                  color="blue-main"
                  label="Add Supplier Payee"
                  onClick={() =>
                    openModal({
                      path: 'ModalEditBundlePayee',
                      bundleId,
                      projectId,
                    })
                  }
                />
              )}
              {status === 'unlocked' && (
                <Button
                  icon="Plus"
                  kind="outline"
                  size="small"
                  color="green-main"
                  label="Add Line Items"
                  onClick={() =>
                    openModal({
                      path: 'ModalAddBundleItems',
                      bundleId,
                      projectId,
                      itemPath: 'lineItems',
                    })
                  }
                />
              )}
              {status === 'changes_only' && (
                <Button
                  icon="Plus"
                  kind="outline"
                  size="small"
                  color="blue-main"
                  label="Add Change Items"
                  onClick={() =>
                    openModal({
                      path: 'ModalAddBundleItems',
                      bundleId,
                      projectId,
                      itemPath: 'changeItems',
                      title: 'Add Change Items',
                    })
                  }
                />
              )}
            </div>
          </div>
        </div>
        <div className="flex gap-2 mt-2">
          <div className="text-brown-dark font-bold">Pay to:</div>
          <div
            className="flex items-center gap-2 hover:cursor-pointer"
            onClick={() =>
              openModal({ path: 'ModalEditBundlePayee', bundleId, projectId })
            }
          >
            {bundle.supplierPayeeId ? (
              <BundleSupplier supplierId={bundle.supplierPayeeId} />
            ) : (
              'No Supplier'
            )}
          </div>
        </div>
      </div>

      {/* ! Remove after Dec 30, 2022 - also remove the orderedFeatureIds from Bundle in the schema.graphql */}
      {status === 'unlocked' && (orderedFeatureIds || []).length > 0 && (
        <div className="flex flex-col items-center justify-center text-brown-dark text-center pb-4 px-4 max-w-md mx-auto mb-10">
          <div className="mt-4 font-bold text-2xl">Missing something?</div>
          {
            <div className="mt-4 mb-2">
              There was a recent change to the orders. Some data for this
              invoice may be hidden, click here to fix it.
            </div>
          }
          <div className="mt-4">
            <Button
              color="red-main"
              kind="outline"
              label="Fix Missing Data"
              onClick={() => {
                updateModel(actions.set('orderedFeatureIds', []).result);

                const featureIdMap = {};
                (lineItems || []).forEach((item) => {
                  if (
                    project.features.find(
                      (feature) => feature.id === item.featureId
                    )
                  ) {
                    featureIdMap[item.featureId] = true;
                  } else {
                    updateModel(
                      actions.set(
                        `lineItems[id:${item.id}].featureId`,
                        '__project__'
                      ).result
                    );
                    // eslint-disable-next-line dot-notation
                    featureIdMap['__project__'] = true;
                  }
                });
                (changeItems || []).forEach((item) => {
                  if (
                    project.features.find(
                      (feature) => feature.id === item.featureId
                    )
                  ) {
                    featureIdMap[item.featureId] = true;
                  } else {
                    updateModel(
                      actions.set(
                        `changeItems[id:${item.id}].featureId`,
                        '__project__'
                      ).result
                    );
                    // eslint-disable-next-line dot-notation
                    featureIdMap['__project__'] = true;
                  }
                });

                Object.keys(featureIdMap).forEach((featureId) => {
                  if (
                    !(orderedFeatureData || []).find(
                      (data) => data.featureId === featureId
                    )
                  ) {
                    updateModel(
                      actions.add('orderedFeatureData', {
                        id: constructUuid(),
                        featureId,
                      }).result
                    );
                  }
                });
              }}
            />
          </div>
        </div>
      )}
      {status === 'unlocked' && (orderedFeatureData || []).length === 0 && (
        <EmptyState
          icon="LineItems"
          header="Get started by adding line items"
          footer={
            <Button
              icon="Plus"
              kind="outline"
              color="blue-main"
              label="Add Line Items"
              onClick={() =>
                openModal({
                  path: 'ModalAddBundleItems',
                  bundleId,
                  projectId,
                  itemPath: 'lineItems',
                })
              }
            />
          }
        >
          <div className="text-center">
            Here is where you plan out all the itemized plant, labor, equipment,
            and materials you will use for the project. All line items must be
            assigned to a feature or assigned to the "Featureless Line Items"
            group.
          </div>
        </EmptyState>
      )}
      {(orderedFeatureData || []).length > 0 && (
        <div className="flex flex-col gap-5">
          {(orderedFeatureData || []).map(({ featureId }) => (
            <FormBundleFeature
              key={featureId}
              projectId={projectId}
              featureId={featureId}
              bundleId={bundleId}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export { FormBundle };
