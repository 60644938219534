import React from 'react';
import colors from '@thrivelot/tailwind/colors';
import { Circle } from '@thrivelot/stories';

const ZoneTypeDropdownLabel = ({ zoneType }) => {
  if (zoneType) {
    return (
      <div className="text-sm text-brown-dark bg-white-dark flex items-center px-3 py-2 w-[58px] border rounded shadow-inset border-tan-light hover:ring-1 hover:ring-tan-light">
        <Circle
          color={colors.zonesIconString[zoneType]}
          size={14}
          styleProps="mr-2"
        />
        <div>{zoneType.replace('_', '')}</div>
      </div>
    );
  }
  return (
    <div className="text-sm text-brown-dark bg-white-dark flex items-center px-3 py-2 w-[58px] border italic rounded shadow-inset border-tan-light hover:ring-1 hover:ring-tan-light">
      <Circle color="blue-main" size={14} styleProps="mr-2" />
      <div>Select zone type...</div>
    </div>
  );
};

export { ZoneTypeDropdownLabel };
