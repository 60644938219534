import React, { forwardRef } from "react";
import SvgContainer from "../SvgWrapper";

const DateStartIcon = forwardRef(({ color, size, ...rest }, ref) => (
  <SvgContainer
    ref={ref}
    width={`${size}`}
    height={`${size}`}
    viewBox="0 0 300 300"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      d="M246.873 40.7468H231.646V13.2911C231.646 5.96203 225.684 0 218.354 0C211.025 0 205.063 5.96203 205.063 13.2911V40.7468H94.9367V13.2911C94.9367 5.96203 88.9747 0 81.6456 0C74.3165 0 68.3544 5.96203 68.3544 13.2911V40.7468H53.1266C23.8481 40.7468 0 64.5949 0 93.8734V246.873C0 276.152 23.8481 300 53.1266 300H246.873C276.152 300 300 276.152 300 246.873V93.8734C300 64.5949 276.152 40.7468 246.873 40.7468ZM273.418 246.873C273.418 261.494 261.494 273.418 246.873 273.418H53.1266C38.5063 273.418 26.5823 261.494 26.5823 246.873V93.8734C26.5823 79.2532 38.5063 67.3291 53.1266 67.3291H68.3544V81.1519C68.3544 88.481 74.3165 94.443 81.6456 94.443C88.9747 94.443 94.9367 88.481 94.9367 81.1519V67.3291H205.063V81.1519C205.063 88.481 211.025 94.443 218.354 94.443C225.684 94.443 231.646 88.481 231.646 81.1519V67.3291H246.873C261.494 67.3291 273.418 79.2532 273.418 93.8734V246.873Z"
      fill={color}
    />
    <path
      d="M124.329 237.342C122.127 237.342 119.886 236.848 117.835 235.861C113.089 233.582 110.127 229.063 110.127 224.089V136.671C110.127 131.544 113.203 126.949 118.139 124.747C123.532 122.316 129.797 123.228 134.127 127.063L185.468 172.671C188.354 175.215 189.949 178.823 189.873 182.544C189.797 186.266 188.013 189.835 185.013 192.266L133.671 234.076C131.013 236.203 127.709 237.342 124.329 237.342ZM124.367 134.81C123.797 134.81 123.266 134.924 122.772 135.152C122.278 135.38 121.481 135.835 121.481 136.671V224.089C121.481 224.848 122.127 225.342 122.696 225.608C123.646 226.063 125.203 226.253 126.418 225.266L177.835 183.418C178.253 183.076 178.481 182.696 178.481 182.279C178.481 181.785 178.177 181.405 177.911 181.177L126.57 135.608C125.924 135.038 125.127 134.81 124.367 134.81Z"
      fill={color}
    />
  </SvgContainer>
));

export default DateStartIcon;
