import React, { useMemo } from "react";
import { MapProvider, useModel, useQueryParam } from "@thrivelot/hooks";
import { EmptyState } from "@thrivelot/stories";
import { LeafLoader } from "@thrivelot/stories";
import { Map } from "./Map";

const MapProject = ({ projectId }) => {
  const { model: project, loaded } = useModel({ modelName: "Project", id: projectId });
  const { param } = useQueryParam("map");

  const { addZone, zoneEditingId } = useMemo(() => param || {}, [param]);

  const subheader = useMemo(() => {
    if (addZone) return "Click an area on the map to add a new zone";
    if (zoneEditingId) return "Click on an area of the map to relocate";
  }, [addZone, zoneEditingId]);

  if (!loaded) return <LeafLoader />;

  const { line1, zip } = project?.details?.address || {};

  if (!line1 || !zip) {
    return <EmptyState>Update address to see map</EmptyState>;
  }

  return (
    <MapProvider>
      <div className="h-full relative">
        {subheader && (
          <div className="absolute z-10 left-0 px-4 py-1 bg-white-dark text-blue-main opacity-80">{subheader}</div>
        )}
        <Map projectId={projectId} />
      </div>
    </MapProvider>
  );
};

export { MapProject };
