import React, { useMemo } from 'react';
import { useModel } from '@thrivelot/hooks';
import { LeafLoader, RowList, Pin } from '@thrivelot/stories';
import { RowItemFeature } from '..';

const colorMap = {
  _0: 'pink-dark',
  _1: 'purple-dark',
  _2: 'evergreen-dark',
  _3: 'blue-dark',
  _4: 'orange-dark',
  _5: 'yellow-dark',
  default: 'brown-dark',
};

const AreaFeaturesGroup = ({ projectId, zoneId }) => {
  const { actions, loaded } = useModel({ modelName: 'Project', id: projectId });

  const zone = useMemo(
    () => actions.get(`zones[id:${zoneId}]`),
    [actions, zoneId]
  );

  const features = useMemo(
    () =>
      (actions.findItemsByTags(`zones[id:${zoneId}]`, 'features') || []).filter(
        (f) => !f.hidden
      ),
    [actions, zoneId]
  );

  if (!loaded) return <LeafLoader />;

  const { name, type } = zone;

  if (features.length === 0) return null;

  return (
    <div>
      {name ? (
        <div
          className={`text-${
            colorMap[type || 'default']
          } flex items-center mb-3`}
        >
          <Pin color={colorMap[type || 'default']} size="16" />
          <div className="font-bold ml-2 text-xl text-ellipsis overflow-hidden whitespace-nowrap">
            {name}
          </div>
          {type && (
            <div className="text-sm font-normal ml-2 pt-1 whitespace-nowrap">
              Zone {type.substring(1)}
            </div>
          )}
        </div>
      ) : (
        <div className="italic">Edit this area to give it a name...</div>
      )}

      <RowList>
        {features.map(({ id }) => (
          <RowItemFeature key={id} featureId={id} projectId={projectId} />
        ))}
      </RowList>
    </div>
  );
};

export { AreaFeaturesGroup };
