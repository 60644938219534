import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import * as icons from './icons';
import { getIconNameStrings } from './utils';
import { LeafLoader } from './loaders';

export const PageContainer = ({
  back,
  icon,
  preheader,
  header,
  headerActions,
  subheader,
  children,
  documentTitle,
  help,
  dropdown,
  loading,
}) => {
  useEffect(() => {
    documentTitle ? (document.title = `Thrive Lot | ${documentTitle}`) : (document.title = "Thrive Lot"); // eslint-disable-line
  }, [documentTitle]);

  let IconComponent = <div />;
  if (icon) {
    if (!icons[icon])
      throw new Error(`Couldn't find a valid icon named: ${icon}`);
    IconComponent = icons[icon];
  }

  return (
    <div className="flex flex-col h-full">
      {preheader && <div>{preheader}</div>}
      {header && (
        <div className="md:flex pb-4 pt-2 items-center">
          <div className="flex-1 text-brown-dark">
            <div className="flex items-center gap-2">
              {icon && <IconComponent color="brown-dark" />}
              <div className="font-bold text-2xl">{header}</div>
              {help && <div>{help}</div>}
              {!loading && dropdown && <div>{dropdown}</div>}
              {loading && <LeafLoader size="sm" />}
            </div>
            {subheader && <div className="text-sm pt-4">{subheader}</div>}
          </div>
          {headerActions && (
            <div className="border-t border-tan-light pt-4 mt-4 w-full md:w-auto md:p-0 md:m-0 md:border-none">
              {headerActions}
            </div>
          )}
        </div>
      )}
      <div className="flex-grow pt-2">{children}</div>
    </div>
  );
};

PageContainer.propTypes = {
  /**
   * Main body of card (padded)
   */
  children: PropTypes.node,

  /**
   * Header string
   */
  header: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),

  /**
   * Icon for the page header
   */
  icon: PropTypes.oneOf(getIconNameStrings()),

  /**
   * Subheader string
   */
  subheader: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),

  /**
   * Actions for the right-most section of the header
   */
  headerActions: PropTypes.node,

  /**
   * Usually a dropdown component for sub page linking
   */
  dropdown: PropTypes.node,

  /**
   * Element to display when the user clicks the help icon
   */
  help: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
};
