import React from 'react';

const Bullseye = ({
  color = 'green-main',
  size = 30,
  styleProps = '',
  ...rest
}) => (
  <svg
    width={size.toString()}
    height={size.toString()}
    viewBox="0 0 75 75"
    className={`fill-${color} ${styleProps}`}
    {...rest}
  >
    <path d="M31.4836 68.9631C26.3286 68.9634 21.2578 67.6564 16.7453 65.1643C12.2328 62.6721 8.4261 59.0763 5.68109 54.713C2.93608 50.3497 1.34248 45.3615 1.04933 40.2149C0.756188 35.0683 1.77304 29.9314 4.00485 25.2847C6.23666 20.6379 9.61048 16.633 13.8108 13.6446C18.0111 10.6562 22.9008 8.78192 28.0224 8.19692C33.144 7.61193 38.3304 8.33538 43.0964 10.2996C47.8625 12.2639 52.0525 15.4048 55.2746 19.4286C55.5003 19.7049 55.6691 20.0231 55.7712 20.3649C55.8733 20.7068 55.9066 21.0654 55.8693 21.4202C55.8319 21.775 55.7247 22.1189 55.5538 22.432C55.3828 22.7451 55.1515 23.0212 54.8732 23.2444C54.595 23.4677 54.2752 23.6336 53.9325 23.7325C53.5898 23.8315 53.2308 23.8616 52.8764 23.8211C52.522 23.7806 52.1791 23.6702 51.8675 23.4964C51.556 23.3226 51.2819 23.0889 51.0612 22.8086C47.3604 18.1865 42.1436 15.0227 36.3342 13.8775C30.5248 12.7323 24.4976 13.6795 19.3195 16.5514C14.1414 19.4233 10.1464 24.0346 8.04169 29.5691C5.93699 35.1036 5.85837 41.2043 7.8197 46.7912C9.78104 52.3781 13.6558 57.0908 18.7582 60.0952C23.8605 63.0996 29.8613 64.2019 35.6983 63.2068C41.5353 62.2117 46.8319 59.1836 50.6506 54.6584C54.4694 50.1331 56.5638 44.4027 56.5633 38.4815C56.5639 36.9886 56.4322 35.4986 56.1698 34.0289C56.0967 33.6752 56.0954 33.3105 56.1657 32.9563C56.2361 32.6021 56.3769 32.2656 56.5796 31.9667C56.7823 31.6678 57.0428 31.4126 57.3459 31.2162C57.6489 31.0198 57.9883 30.8861 58.3439 30.8231C58.6995 30.7602 59.0642 30.7691 59.4162 30.8496C59.7683 30.93 60.1007 31.0802 60.3937 31.2913C60.6867 31.5024 60.9344 31.7702 61.1222 32.0787C61.3099 32.3872 61.4338 32.7302 61.4867 33.0875C62.2731 37.4766 62.0875 41.9848 60.9429 46.2945C59.7982 50.6041 57.7225 54.6103 54.8619 58.0308C52.0013 61.4513 48.4254 64.203 44.3862 66.0918C40.3469 67.9806 35.9426 68.9608 31.4836 68.9631Z" />
    <path d="M31.4842 58.5453C28.1794 58.5452 24.9259 57.7287 22.0126 56.1685C19.0994 54.6082 16.6166 52.3526 14.7849 49.6018C12.9533 46.8511 11.8294 43.6905 11.5133 40.4009C11.1971 37.1113 11.6984 33.7946 12.9727 30.7454C14.2469 27.6961 16.2546 25.0089 18.8174 22.9224C21.3802 20.8359 24.4187 19.4147 27.6629 18.7851C30.9072 18.1556 34.2567 18.3372 37.4138 19.3137C40.571 20.2902 43.4381 22.0315 45.7603 24.3828C46.2638 24.8945 46.5434 25.5852 46.5376 26.303C46.5318 27.0208 46.2411 27.7069 45.7295 28.2104C45.2178 28.7139 44.5271 28.9935 43.8093 28.9877C43.0915 28.9819 42.4054 28.6912 41.9019 28.1795C39.5158 25.7629 36.3647 24.2496 32.9869 23.8979C29.609 23.5463 26.2139 24.3782 23.3811 26.2515C20.5484 28.1248 18.4538 30.9233 17.4551 34.1692C16.4563 37.4152 16.6154 40.9071 17.905 44.0489C19.1947 47.1906 21.5349 49.7872 24.5261 51.3953C27.5174 53.0033 30.9741 53.5232 34.3061 52.866C37.638 52.2088 40.6384 50.4153 42.7951 47.7918C44.9517 45.1684 46.1307 41.8776 46.1308 38.4815C46.1308 38.1728 46.1308 37.8719 46.1308 37.5709C46.109 37.2162 46.1573 36.8607 46.2729 36.5247C46.3885 36.1887 46.5691 35.8787 46.8045 35.6125C47.0399 35.3463 47.3254 35.129 47.6448 34.9732C47.9641 34.8173 48.311 34.7259 48.6657 34.7041C49.0204 34.6823 49.3759 34.7306 49.712 34.8462C50.048 34.9618 50.3579 35.1425 50.6241 35.3779C50.8903 35.6133 51.1076 35.8988 51.2634 36.2181C51.4193 36.5375 51.5108 36.8844 51.5326 37.2391C51.5326 37.6481 51.5711 38.0648 51.5711 38.4815C51.5711 41.1183 51.0514 43.7292 50.0417 46.1649C49.0319 48.6007 47.5519 50.8135 45.6864 52.6769C43.8208 54.5403 41.6063 56.0177 39.1694 57.0247C36.7324 58.0316 34.1209 58.5484 31.4842 58.5453Z" />
    <path d="M75.0149 24.1591L70.3848 21.5585C70.1853 21.4466 70.0269 21.274 69.9325 21.0658C69.8381 20.8575 69.8127 20.6246 69.86 20.401L70.9481 15.1612C71.0205 14.8118 70.9947 14.4493 70.8734 14.1137C70.7521 13.7782 70.5401 13.4829 70.261 13.2606C69.982 13.0383 69.6467 12.8977 69.2926 12.8545C68.9384 12.8113 68.5792 12.8672 68.2549 13.0159L59.4268 17.0595C59.1832 17.1696 58.9653 17.3294 58.7872 17.5288C58.6091 17.7281 58.4747 17.9626 58.3928 18.2171L56.2398 24.869C56.2008 24.9929 56.1698 25.1192 56.1472 25.2471L36.8088 34.0366C35.7598 32.7841 34.3029 31.9414 32.6942 31.6568C31.0854 31.3721 29.4279 31.6638 28.0129 32.4803C26.5978 33.2969 25.516 34.5861 24.9574 36.1214C24.3989 37.6567 24.3995 39.3398 24.9591 40.8747C25.5187 42.4096 26.6015 43.698 28.0171 44.5136C29.4327 45.3292 31.0905 45.6196 32.699 45.3338C34.3076 45.048 35.7639 44.2044 36.8119 42.9511C37.86 41.6978 38.4326 40.1152 38.4293 38.4815C38.4282 38.177 38.4076 37.8728 38.3676 37.5709L57.7523 28.7274C57.8615 28.7917 57.9749 28.8484 58.0918 28.8972L64.5199 31.6367C64.7644 31.7386 65.0267 31.791 65.2916 31.791C65.5583 31.7859 65.8211 31.7254 66.0633 31.6135L74.8605 27.6007C75.1855 27.4528 75.463 27.2177 75.6623 26.9214C75.8615 26.6251 75.9746 26.2794 75.989 25.9227C76.0034 25.5659 75.9185 25.2121 75.7438 24.9008C75.5691 24.5894 75.3114 24.3327 74.9994 24.1591H75.0149ZM65.253 27.7474L60.0673 25.5481L61.8036 20.1463L66.3565 18.0705L66.0402 19.5675C65.9699 19.9026 65.9337 20.2438 65.9321 20.5862C65.9322 21.4633 66.1669 22.3244 66.612 23.0802C67.057 23.836 67.6963 24.459 68.4633 24.8844L69.806 25.6561L65.253 27.7474Z" />
  </svg>
);

export { Bullseye };
