import { useEffect, useState } from "react";
import { useProposal, useRouter } from "@thrivelot/common";
import { constructPlantInfo, fetchPlantFromDatabase } from "../helpers";

const usePlantInfo = () => {
  // state hooks
  const { projectActions } = useProposal();
  const { hash, urlHashes } = useRouter();
  const [plantFromDatabase, setPlantFromDatabase] = useState();
  // actions
  const plantId = hash[urlHashes.plantId];
  const plantFromProject = projectActions.get(`plantItems[id:${plantId}]`);
  // lifecycle hooks
  useEffect(() => {
    let didCancel = false;
    (async () => {
      const plant = await fetchPlantFromDatabase(plantId);
      if (!didCancel) setPlantFromDatabase(plant);
    })();
    return () => {
      didCancel = true;
    };
  }, [plantId]);
  // constants
  if (!plantFromProject) return null;
  const plantInfo = constructPlantInfo(plantFromDatabase, plantFromProject);
  // return
  return plantInfo;
};

export { usePlantInfo };
