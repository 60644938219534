import React from "react";

const CardInfo = ({ header, body, classAppend }) => {
  return (
    <div className={`w-full rounded-main overflow-hidden bg-tan-light ${classAppend || ""}`}>
      <div className="bg-green-main text-white-light px-8 py-3 text-xl lg:text-2xl font-bold">{header}</div>
      <div className="p-8 text-green-main">{body}</div>
    </div>
  );
};

export { CardInfo };
