import React from "react";
import { useAuth, useModel } from "@thrivelot/hooks";
import { ImageUserAvatar } from "..";
import { User } from "@thrivelot/stories";

const Avatar = () => {
  const getModelName = () => (window.appConfig.appName === "customer" ? "Customer" : "Supplier");

  const { userId } = useAuth();
  const { model: user, loaded } = useModel({ modelName: getModelName(), id: userId });

  if (window.appConfig.appName === "admin") return <User size={30} />;

  return (
    <div className="hover:cursor-pointer">
      <ImageUserAvatar user={user} loading={!loaded} />
    </div>
  );
};

export { Avatar };
