const milestoneColorMap = {
  OPEN_PROJECT: "red",
  ASSIGNED: "orange",
  SITE_ASSESSMENT: "green",
  DETAILED_PLAN: "evergreen",
  INSTALLATION: "brown",
  STEWARDSHIP: "purple",
  CHURN: "tan",
};

export { milestoneColorMap };
