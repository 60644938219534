import React from "react";
import styled from "styled-components";
import CssDivDefault from "./CssDivDefault";

const DivContentContainer = styled.div`
  ${CssDivDefault}
  display: flex;
  justify-content: center;
  height: 100%;
`;

const DivContentInside = styled.div`
  width: 100%;
  max-width: 1320px;
  height: 100%;
`;

export default ({ children, ...rest }) => (
  <DivContentContainer>
    <DivContentInside {...rest}>{children}</DivContentInside>
  </DivContentContainer>
);
