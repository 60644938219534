import { getModelObjById } from "./getModelObjById";

const getObjArrFromTags = ({ tags, arr }) => {
  if (!tags) return [];

  const objArr = [];
  tags.forEach(({ tagId: id }) => {
    const { obj } = getModelObjById({ id, arr });
    if (obj) objArr.push(obj);
  });

  return objArr;
};

export { getObjArrFromTags };
