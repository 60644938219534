// eslint-disable

import { constructUuid } from '@thrivelot/utils';
import { dateFns } from '@thrivelot/date';
import { projectPriorities } from '@thrivelot/config';

const getProrities = () => {
  const formattedPriorities = [];
  Object.keys(projectPriorities).forEach((key) => {
    const mutatedKey = projectPriorities[key].includes(' / ')
      ? projectPriorities[key].replace(' / ', '/')
      : projectPriorities[key];
    formattedPriorities.push({
      prompt: `Priority: ${mutatedKey}`,
      priority: 0,
    });
  });
  return formattedPriorities;
};

const constructProject = ({ address, customerId, email, viewers, title }) => ({
  siteAnalysis: [
    {
      id: `DEFAULT${constructUuid()}`,
      title: 'General Field Notes',
      observations: '',
      recommendations: '',
    },
    {
      id: `DEFAULT${constructUuid()}`,
      title: 'Hydrology Analysis',
      observations: '',
      recommendations: '',
    },
    {
      id: `DEFAULT${constructUuid()}`,
      title: 'Soil Analysis',
      observations: '',
      recommendations: '',
    },
    {
      id: `DEFAULT${constructUuid()}`,
      title: 'Sun Analysis',
      observations: '',
      recommendations: '',
    },
    {
      id: `DEFAULT${constructUuid()}`,
      title: 'Existing Flora and Fauna',
      observations: '',
      recommendations: '',
    },
  ],
  details: {
    title,
    projectNotes: '',
    address,
    property: {
      size: '',
      amountImpacted: '',
    },
    desire: '',
    styles: [],
    features: [],
    priorities: getProrities(),
    favoritePlants: '',
    petInformation: '',
    allergyInformation: '',
    additionalNotes: '',
    budgetCommitment: 1000000,
    useInvoiceProposals: true,
  },
  files: [],
  zones: [],
  features: [],
  plantItems: [],
  lineItems: [],
  phases: [],
  activities: [],
  calendarNotes: [],
  costPaymentSchedule: [],
  milestones: [
    {
      id: constructUuid(),
      type: 'OPEN_PROJECT_ACCEPTANCE',
      stage: 'OPEN_PROJECT',
      lifecycleEvents: [],
    },
  ],
  currentMilestone: 'OPEN_PROJECT',
  owners: [],
  customerId,
  viewers,
});

export { constructProject };
