import { constructUuid } from "@thrivelot/utils";

const constructSelf = ({
  id = constructUuid(),
  email = "",
  phoneNumber = "",
  firstname = "",
  lastname = "",
  owners = [],
  viewers = [],
}) => ({ id, email, phoneNumber, firstname, lastname, owners, viewers });

export { constructSelf };
