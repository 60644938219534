import React from 'react';
import chroma from 'chroma-js';

const getTextColor = (bgColor) =>
  chroma(bgColor).luminance() > 0.5 ? 'mulch-dark' : 'white-light';

export const ItemSection = ({ title, content }) => (
  <div>
    <div className="text-mulch-dark mb-2">{title}</div>
    <div className="flex flex-wrap items-center gap-2">
      {content.map((item) => {
        const { text, bgColor } = item;
        const textColor = !bgColor ? 'mulch-dark' : getTextColor(bgColor);

        return (
          <div
            key={text}
            className={`rounded flex items-center justify-center px-3 py-1${
              bgColor ? '' : ' bg-tan-light'
            } text-${textColor} font-bold text-sm shadow-outer`}
            style={{ backgroundColor: bgColor }}
          >
            {text}
          </div>
        );
      })}
    </div>
  </div>
);
