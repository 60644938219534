import styled from "styled-components";

const PlantContainer = styled.div`
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  transition: opacity 0.5s ease-out, box-shadow 0.5s ease-out, color 1s ease-in-out, background-color 1s ease-in-out;
  &:hover {
    opacity: 0.7;
  }
  @media (max-width: 150px) {
    width: 100%;
    padding-bottom: 100%;
  }
  @media (max-width: 300px) and (min-width: 151px) {
    width: 50%;
    padding-bottom: 50%;
  }
  @media (max-width: 450px) and (min-width: 301px) {
    width: 33.3%;
    padding-bottom: 33.3%;
  }
  @media (max-width: 600px) and (min-width: 451px) {
    width: 25%;
    padding-bottom: 25%;
  }
  @media (max-width: 750px) and (min-width: 601px) {
    width: 20%;
    padding-bottom: 20%;
  }
  @media (max-width: 900px) and (min-width: 751px) {
    width: 14.28%;
    padding-bottom: 14.28%;
  }
  @media (max-width: 1050px) and (min-width: 901px) {
    width: 12.5%;
    padding-bottom: 12.5%;
  }
  @media (min-width: 1051px) {
    width: 11.11%;
    padding-bottom: 11.11%;
  }
`;

export { PlantContainer };
