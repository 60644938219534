import styled from "styled-components";
import CssRaisedShadow from "./CssRaisedShadow";

const DivModal = styled.div`
  ${CssRaisedShadow}
  transition: opacity 0.5s ease-out, box-shadow 0.5s ease-out, color 1s ease-in-out, background-color 1s ease-in-out;
  display: flex;
  background-color: ${({ theme }) => theme.foreground};
  position: fixed;
  top: 75px;
  border-radius: 5px;
  max-height: calc(100% - 200px);
  flex-direction: column;
  @media (max-width: 500px) {
    left: 5px;
    right: 5px;
  }
  @media (min-width: 500px) {
    min-width: 500px;
    max-width: 70vw;
    left: 50%;
    transform: translate(-50%, 0%);
  }
`;

export default DivModal;
