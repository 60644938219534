import React, { useMemo, useCallback } from "react";
import { LayoutModal } from "../..";
import { useModel } from "@thrivelot/hooks";
import { ImgBox } from "@thrivelot/styles";
import { projectFeatures as availableFeatures } from "@thrivelot/config";
import { Checkmark } from "@thrivelot/stories";

// ! Possibly deprecated since this is built for the old customer form

const ModalEditFeatures = ({ onClose, projectId }) => {
  const { model: project, updateModel, loaded, actions } = useModel({ modelName: "Project", id: projectId });

  const projectFeaturesMap = useMemo(() => {
    if (loaded) {
      const map = {};

      (project?.details?.features || []).forEach((feature) => {
        const resultKey = Object.keys(availableFeatures).find(
          (featureKey) => availableFeatures[featureKey].name === feature.name
        );
        map[resultKey] = { ...availableFeatures[resultKey], key: resultKey };
      });

      return map;
    }
  }, [loaded, project?.details?.features]);

  const handleSelect = useCallback(
    ({ key, imageUrl, ...feature }) => {
      if (projectFeaturesMap[key])
        return updateModel(actions.remove(`details.features[name:${feature.name}]`, feature).result);

      return updateModel(actions.add("details.features", feature).result);
    },
    [projectFeaturesMap, actions, updateModel]
  );

  return (
    <LayoutModal
      onClose={onClose}
      loading={!loaded}
      header={
        <div className="text-lg text-brown-dark italic">Select all the features you&apos;re interested in...</div>
      }
    >
      <div className="grid grid-cols-2 gap-4">
        {projectFeaturesMap &&
          Object.keys(availableFeatures).map((featureKey) => {
            const isSelected = !!projectFeaturesMap[featureKey];
            const { imageUrl, description, name } = availableFeatures[featureKey];
            return (
              <div
                key={featureKey}
                onClick={() => handleSelect({ key: featureKey, ...availableFeatures[featureKey] })}
                className="rounded-main shadow flex flex-col hover:cursor-pointer"
              >
                <div className="relative">
                  <ImgBox src={{ url: imageUrl }} />
                  {isSelected && (
                    <div className="absolute right-1 top-2 bg-white-light rounded-full">
                      <Checkmark color="green-main" size={40} />
                    </div>
                  )}
                </div>
                <div className="text-brown-dark p-2 font-bold">{name}</div>
                {description && (
                  <div className="text-brown-dark px-2 text-sm pb-2 flex-1 flex justify-center items-center">
                    {description}
                  </div>
                )}
              </div>
            );
          })}
      </div>
    </LayoutModal>
  );
};

export { ModalEditFeatures };
