import { useMemo } from "react";
import { useLocation, useParams } from "react-router-dom";

const useNavOptions = ({ options, pathMatch }) => {
  const location = useLocation();
  const params = useParams();

  const [currentOption, navOptions] = useMemo(() => {
    let currentOption = options[0];
    const navOptions = options.map((option) => {
      const selected = pathMatch({ location, params, to: option.to });

      if (selected) currentOption = option;

      return {
        ...option,
        selected,
      };
    });

    return [currentOption, navOptions];
  }, [options, pathMatch, location, params]);

  return [currentOption, navOptions];
};

export { useNavOptions };
