import { useCallback, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import * as JSURL from './jsurl';

const useQueryParam = (key) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const paramValue = searchParams.get(key);

  const constructParamValue = useCallback(
    (value) => JSURL.stringify(value),
    []
  );
  const constructParamWithKeyAndValue = useCallback(
    (value) => `${key}=${constructParamValue(value)}`,
    [key, constructParamValue]
  );

  const param = useMemo(() => {
    try {
      return JSURL.parse(paramValue);
    } catch (e) {
      const newSearchParams = new URLSearchParams(searchParams);
      newSearchParams.delete(key);
      return '';
    }
  }, [searchParams, paramValue]);

  const paramAsString = useMemo(() => `${key}=${paramValue}`, paramValue);

  const setParam = useCallback(
    (newValue, options) => {
      const newSearchParams = new URLSearchParams(searchParams);

      if (newValue === null || newValue === undefined) {
        newSearchParams.delete(key);
      } else {
        newSearchParams.set(key, constructParamValue(newValue));
      }

      setSearchParams(newSearchParams, options);
    },
    [key, searchParams, setSearchParams, constructParamValue]
  );

  return {
    param,
    paramAsString,
    setParam,
    constructParamValue,
    constructParamWithKeyAndValue,
  };
};

export { useQueryParam };
