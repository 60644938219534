import { useContext } from "react";
import { ModelKitContext } from "./ModelKitContext";

const useModelKit = () => {
  const { loaded, authenticated, subscriber, modelConfig, storageConfig } = useContext(ModelKitContext);

  return { loaded, authenticated, subscriber, modelConfig, storageConfig };
};

export { useModelKit };
