import React from "react";
import SvgContainer from "../SvgWrapper";

const NotepadIcon = ({ color, size, ...rest }) => (
  <SvgContainer
    width={`${size}`}
    height={`${size}`}
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      d="M24.6873 30H5.31266C2.38481 30 0 27.7215 0 24.9228V9.9038C0 7.10506 2.38481 4.82658 5.31266 4.82658H8.6962C9.33418 2.06962 11.9203 0 15 0C18.0797 0 20.6658 2.06962 21.3038 4.82658H24.6873C27.6152 4.82658 30 7.10506 30 9.9038V24.9228C30 27.7215 27.6152 30 24.6873 30ZM5.31266 7.48481C3.85063 7.48481 2.65823 8.57089 2.65823 9.9038V24.9228C2.65823 26.2557 3.85063 27.3418 5.31266 27.3418H24.6873C26.1494 27.3418 27.3418 26.2557 27.3418 24.9228V9.9038C27.3418 8.57089 26.1494 7.48481 24.6873 7.48481H20.1266C19.3937 7.48481 18.7975 6.88861 18.7975 6.1557C18.7975 4.22658 17.0924 2.65823 15 2.65823C12.9076 2.65823 11.2025 4.22658 11.2025 6.1557C11.2025 6.88861 10.6063 7.48481 9.87342 7.48481H5.31266Z"
      fill={color}
    />
    <path
      d="M15 8.81782C13.6557 8.81782 12.5658 7.72415 12.5658 6.38364C12.5658 5.03934 13.6595 3.94946 15 3.94946C16.3443 3.94946 17.4341 5.04313 17.4341 6.38364C17.4341 7.72415 16.3443 8.81782 15 8.81782ZM15 5.0887C14.286 5.0887 13.705 5.66972 13.705 6.38364C13.705 7.09756 14.286 7.67858 15 7.67858C15.7139 7.67858 16.2949 7.09756 16.2949 6.38364C16.2949 5.66972 15.7139 5.0887 15 5.0887Z"
      fill={color}
    />
    <path
      d="M23.5443 12.7291H6.45573C6.14054 12.7291 5.88611 12.4747 5.88611 12.1595C5.88611 11.8443 6.14054 11.5898 6.45573 11.5898H23.5443C23.8595 11.5898 24.114 11.8443 24.114 12.1595C24.114 12.4747 23.8595 12.7291 23.5443 12.7291Z"
      fill={color}
    />
    <path
      d="M23.5443 16.4544H6.45573C6.14054 16.4544 5.88611 16.2 5.88611 15.8848C5.88611 15.5696 6.14054 15.3152 6.45573 15.3152H23.5443C23.8595 15.3152 24.114 15.5696 24.114 15.8848C24.114 16.2 23.8595 16.4544 23.5443 16.4544Z"
      fill={color}
    />
    <path
      d="M23.5443 20.0963H6.45573C6.14054 20.0963 5.88611 19.8418 5.88611 19.5267C5.88611 19.2115 6.14054 18.957 6.45573 18.957H23.5443C23.8595 18.957 24.114 19.2115 24.114 19.5267C24.114 19.8418 23.8595 20.0963 23.5443 20.0963Z"
      fill={color}
    />
    <path
      d="M19.2038 23.6353H6.45573C6.14054 23.6353 5.88611 23.3809 5.88611 23.0657C5.88611 22.7505 6.14054 22.4961 6.45573 22.4961H19.2038C19.519 22.4961 19.7734 22.7505 19.7734 23.0657C19.7734 23.3809 19.519 23.6353 19.2038 23.6353Z"
      fill={color}
    />
  </SvgContainer>
);

export default NotepadIcon;
