import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { getTailwindColorStrings, getIconNameStrings } from './utils';
import { Spinner } from './Spinner';
import * as icons from './icons';

import './index.css';

/**
 * Primary UI component for user interaction
 */
const ButtonDetail = ({ loading, icon, size, kind, color, isIconOnly }) => {
  const spinnerSize = useMemo(() => {
    let value;
    if (size === 'large') {
      value = 'large';
    } else if (size === 'extra-small') {
      value = 'extra-small';
    }
    return value;
  }, [size]);

  const iconSize = useMemo(() => {
    let value = '20'; // default

    if (size === 'small' || size === 'extra-small') {
      value = '15';
    } else if (size === 'large') {
      value = '26';
    }
    return value;
  }, [size]);

  const detailColor = useMemo(() => {
    let value = 'white-light';
    if (kind === 'outline' || kind === 'prompt') {
      value = color;
    }
    return value;
  }, [kind, color]);

  const detailMargin = useMemo(() => {
    if (isIconOnly) return null;
    return 'mr-1.5';
  }, [isIconOnly]);

  if (loading) {
    return (
      <div className={`flex items-center ${detailMargin}`}>
        <Spinner size={spinnerSize} color={detailColor} />
      </div>
    );
  }

  if (icon) {
    const IconComponent = icons[icon];
    return (
      <div className={`flex items-center ${detailMargin}`}>
        <IconComponent size={iconSize} color={detailColor} />
      </div>
    );
  }

  return null;
};

export const Button = ({
  color,
  size,
  kind,
  disabled,
  loading,
  icon,
  block,
  label,
  onClick = () => null,
  children,
  classAppend,
  ...props
}) => {
  const isIconOnly = useMemo(() => !label && !children, [label, children]);

  const className = useMemo(() => {
    let result = 'flex justify-center items-center font-bold ';

    // kind
    if (kind === 'outline') {
      result += `border-2 border-${color} text-${color} `;
    } else if (kind === 'prompt') {
      result += `text-${color} `;
    } else {
      result += `border-2 border-${color} bg-${color} text-white-light `;
    }

    // disabled
    if (disabled) {
      result += 'opacity-50 hover:cursor-default ';
    } else {
      result += 'hover:opacity-75 ';
    }

    // size
    if (size === 'extra-small' && kind !== 'prompt') {
      result += 'py-0.25 px-1 text-xs ';
    } else if (size === 'small' && kind !== 'prompt') {
      result += 'py-1 px-3 text-sm ';
    } else if (size === 'large' && kind !== 'prompt') {
      result += 'py-3 px-6 text-xl ';
    } else if (kind !== 'prompt') {
      result += 'py-2 px-4 text-md ';
    }

    if (size === 'extra-small') result += 'rounded ';
    else result += 'rounded-main ';

    // block
    if (block) {
      result += 'w-full ';
    }

    // loading
    if ((loading || icon) && !isIconOnly) {
      if (size === 'extra-small') {
        result += '';
      } else if (size === 'small') {
        result += '!pl-2 ';
      } else if (size === 'large') {
        result += '!pl-4 ';
      } else {
        result += '!pl-3 ';
      }
    }

    // icon only
    if (isIconOnly) {
      if (size === 'small') {
        result += '!p-2 ';
      } else if (size === 'large') {
        result += '!p-4 ';
      } else {
        result += '!p-3 ';
      }
    }

    return result;
  }, [block, color, kind, size, disabled, loading, icon, isIconOnly]);

  return (
    <button
      aria-label={label}
      data-testid={`button-for-${label}`}
      className={`${className} ${classAppend || ''}`}
      onClick={() => !disabled && onClick()}
      {...props}
    >
      <ButtonDetail
        loading={loading}
        icon={icon}
        size={size}
        color={color}
        kind={kind}
        isIconOnly={isIconOnly}
      />
      {children || label}
    </button>
  );
};

Button.propTypes = {
  /**
   * The color of the button
   */
  color: PropTypes.oneOf(getTailwindColorStrings()),

  /**
   * Class string to append to outermost element class
   */
  classAppend: PropTypes.string,

  /**
   * Size of the button
   */
  size: PropTypes.oneOf(['small', 'default', 'large', 'extra-small']),

  /**
   * Show an icon in the button
   */
  icon: PropTypes.oneOf(getIconNameStrings()),

  /**
   * Style of the button
   */
  kind: PropTypes.oneOf(['default', 'outline', 'prompt']),

  /**
   * Is the button disabled?
   */
  disabled: PropTypes.bool,

  /**
   * Show loading indicator
   */
  loading: PropTypes.bool,

  /**
   * will make the button full width
   */
  block: PropTypes.bool,

  /**
   * Label for accessibility
   */
  label: PropTypes.string,

  /**
   * Optional click handler
   */
  onClick: PropTypes.func,
};

Button.defaultProps = {
  color: 'green',
  size: 'default',
  kind: 'default',
  disabled: false,
  loading: false,
  block: false,
};
