import { constructUuid } from "@thrivelot/utils";

const constructFile = ({ filename, metadata, bucket, region, fetchCredentials }) => {
  const [, , , extension] = /([^.]+)(\.(\w+))?$/.exec(filename);

  return fetchCredentials().then((credentials) => {
    const key = `${credentials.identityId}/${constructUuid()}${extension && "."}${extension}`;

    return { bucket, region, key, type: metadata.fileType, filename };
  });
};

export { constructFile };
