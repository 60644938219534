import styled from "styled-components";

const topConstructor = ({ top }) => top || "50px";

const DivNoScrollPageContainer = styled.div`
  transition: opacity 0.5s ease-out, box-shadow 0.5s ease-out, color 1s ease-in-out, background-color 1s ease-in-out;
  position: fixed;
  top: ${topConstructor};
  right: 0px;
  bottom: 0px;
  left: 0px;
`;

export default DivNoScrollPageContainer;
