import React from 'react';

const Crop = ({
  color = 'green-main',
  size = 30,
  styleProps = '',
  ...rest
}) => (
  <svg
    width={size.toString()}
    height={size.toString()}
    viewBox="0 0 75 75"
    className={`fill-${color} ${styleProps}`}
    {...rest}
  >
    <path d="M75 64.0823C75 65.9146 73.5095 67.4051 71.6772 67.4051H67.4051V71.6772C67.4051 73.5095 65.9146 75 64.0823 75C62.25 75 60.7595 73.5095 60.7595 71.6772V19.7753C60.7595 19.5095 60.7405 19.2532 60.7025 18.9968L18.9399 60.7595H53.6203C55.4525 60.7595 56.943 62.25 56.943 64.0823C56.943 65.9146 55.4525 67.4051 53.6203 67.4051H14.5823C10.7278 67.4051 7.59494 64.2722 7.59494 60.4177V21.3797C7.59494 19.5475 9.08544 18.057 10.9177 18.057C12.75 18.057 14.2405 19.5475 14.2405 21.3797V56.0601L56.0032 14.2975C55.7468 14.2595 55.4905 14.2405 55.2247 14.2405H3.32278C1.49051 14.2405 0 12.75 0 10.9177C0 9.08544 1.49051 7.59494 3.32278 7.59494H7.59494V3.32278C7.59494 1.49051 9.08544 0 10.9177 0C12.75 0 14.2405 1.49051 14.2405 3.32278V7.59494H55.2247C57.3797 7.59494 59.4019 8.15506 61.1582 9.14241L68.0696 2.23101C69.3703 0.93038 71.4684 0.93038 72.769 2.23101C74.0696 3.52215 74.0696 5.62975 72.769 6.93038L65.8576 13.8418C66.8449 15.5981 67.4051 17.6203 67.4051 19.7753V60.7595H71.6772C73.5095 60.7595 75 62.25 75 64.0823Z" />
  </svg>
);

export { Crop };
