import React from "react";
import { useTheme } from "@thrivelot/hooks";
import { TextBody, TextSmallHeader } from "@thrivelot/styles";
import { NoteContainer } from "./NoteContainer";

const Note = ({ title, note }) => {
  // hooks
  const theme = useTheme();
  // breakout
  if (!note || note.trim() === "") return null;
  return (
    <NoteContainer>
      <TextSmallHeader style={{ color: theme.textLabel }}>{title}</TextSmallHeader>
      <TextBody style={{ color: theme.textTitle }}>{note}</TextBody>
    </NoteContainer>
  );
};

export { Note };
