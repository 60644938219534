import React from "react";
import { useMobileDifference, useTheme } from "@thrivelot/hooks";
import { useProposal, useProposalView } from "@thrivelot/common";
import { TextTitle, ZoneIcon } from "@thrivelot/styles";
import { useIconSize, useSpacing } from "../../../../hooks";
import { TitleBarContainer } from "./TitleBarContainer";

const TitleBar = () => {
  const { projectActions } = useProposal();
  const { zone: zoneId } = useProposalView();
  const theme = useTheme();
  const iconSize = useIconSize();
  const spacing = useSpacing();
  const iconMarginTop = useMobileDifference({ desktop: "1px", mobile: "3px" });
  if (!zoneId) return null;
  const name = projectActions.get(`zones[id:${zoneId}].name`);
  const type = projectActions.get(`zones[id:${zoneId}].type`);
  return (
    <TitleBarContainer>
      <ZoneIcon color={theme.zoneText[type]} size={iconSize} style={{ marginTop: iconMarginTop }} />
      <TextTitle style={{ marginLeft: spacing, marginRight: "12px" }}>{name}</TextTitle>
    </TitleBarContainer>
  );
};

export { TitleBar };
