import React from 'react';
import { useProposal } from '@thrivelot/common';
import { useInnerSpacing, useSpacing } from '../../../hooks';
import { ProjectTeamContainer } from './ProjectTeamContainer';
import { TeamCard } from './TeamCard';

const ProjectTeam = () => {
  // hooks
  const { suppliers, supplierActions } = useProposal();
  const innerSpacing = useInnerSpacing();
  const spacing = useSpacing();
  // constants
  const negativeWrapMargin = `-${innerSpacing}`;
  // return
  return (
    <ProjectTeamContainer
      style={{
        marginTop: spacing,
        marginRight: negativeWrapMargin,
        marginBottom: negativeWrapMargin,
      }}
    >
      {suppliers.map((supplier) =>
        supplier ? (
          <TeamCard
            key={supplier.id}
            supplierActions={supplierActions(supplier.id)}
          />
        ) : null
      )}
    </ProjectTeamContainer>
  );
};

export { ProjectTeam };
