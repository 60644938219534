import React, { useMemo, useCallback } from "react";
import { LayoutModal } from "../..";
import { useModel } from "@thrivelot/hooks";
import { projectPriorities as availablePriorities } from "@thrivelot/config";

// ! Possibly deprecated since this is built for the old customer form

const ModalEditPriorities = ({ onClose, projectId }) => {
  const { model: project, updateModel, loaded, actions } = useModel({ modelName: "Project", id: projectId });

  const projectPrioritiesMap = useMemo(() => {
    let map = {};

    if (loaded) {
      Object.keys(availablePriorities).forEach((key) => {
        const prompt = availablePriorities[key];
        const promptMatch = prompt.slice(0, 10);
        const index = (project?.details?.priorities || []).findIndex(
          ({ prompt }) => promptMatch === prompt.replace(/^Priority: /, "").slice(0, 10)
        );

        if (
          index > -1 &&
          promptMatch === project.details.priorities[index].prompt.replace(/^Priority: /, "").slice(0, 10)
        ) {
          map[key] = { priority: (project.details.priorities[index] || {}).priority || 0, prompt, key, promptMatch };
        } else {
          map[key] = { priority: 0, prompt, key, promptMatch };
        }
      });
    }

    return map;
  }, [project?.details?.priorities, loaded]);

  const handleSelect = useCallback(
    ({ key, value }) => {
      const { promptMatch, prompt } = projectPrioritiesMap[key];
      const index = (project?.details?.priorities || []).findIndex(
        ({ prompt }) => promptMatch === prompt.replace(/^Priority: /, "").slice(0, 10)
      );

      if (index > -1) {
        updateModel(
          actions
            .set(`details.priorities[index:${index}].priority`, value)
            .set(`details.priorities[index:${index}].prompt`, prompt).result
        );
      } else {
        updateModel(actions.add("details.priorities", { priority: 0, prompt }).result);
      }
    },
    [projectPrioritiesMap, actions, updateModel, project?.details?.priorities]
  );

  return (
    <LayoutModal
      onClose={onClose}
      loading={!loaded}
      header={<div className="text-lg text-brown-dark italic">What are your highest priorities with this project?</div>}
    >
      <div>
        {Object.keys(projectPrioritiesMap).map((key) => {
          const { priority, prompt } = projectPrioritiesMap[key];

          return (
            <div key={key} className="my-6">
              <div className="mb-2">{prompt}</div>
              <div className="flex justify-between">
                {[...Array(10).keys()].map((value) => (
                  <div
                    key={value}
                    className={`h-8 w-8 hover:cursor-pointer flex items-center justify-center border rounded-full ${
                      priority === value ? "bg-green-main text-white-light" : "border-tan-dark text-tan-dark"
                    }`}
                    onClick={() => handleSelect({ key, value })}
                  >
                    {value}
                  </div>
                ))}
              </div>
            </div>
          );
        })}
      </div>
    </LayoutModal>
  );

  // const projectFeaturesMap = useMemo(() => {
  //   if (loaded) {
  //     const map = {};

  //     (project?.details?.features || []).forEach((feature) => {
  //       const resultKey = Object.keys(availableFeatures).find(
  //         (featureKey) => availableFeatures[featureKey].name === feature.name
  //       );
  //       map[resultKey] = { ...availableFeatures[resultKey], key: resultKey };
  //     });

  //     return map;
  //   }
  // }, [loaded, project?.details?.features]);

  // const handleSelect = useCallback(
  //   ({ key, imageUrl, ...feature }) => {
  //     if (projectFeaturesMap[key])
  //       return updateModel(actions.remove(`details.features[name:${feature.name}]`, feature).result);

  //     return updateModel(actions.add("details.features", feature).result);
  //   },
  //   [projectFeaturesMap, actions, updateModel]
  // );

  // return (
  //   <LayoutModal
  //     onClose={onClose}
  //     loading={!loaded}
  //     header={
  //       <div className="text-lg text-brown-dark italic">Select all the features you&apos;re interested in...</div>
  //     }
  //   >
  //     <div className="grid grid-cols-2 gap-4">
  //       {projectFeaturesMap &&
  //         Object.keys(availableFeatures).map((featureKey) => {
  //           const isSelected = !!projectFeaturesMap[featureKey];
  //           const { imageUrl, description, name } = availableFeatures[featureKey];
  //           return (
  //             <div
  //               key={featureKey}
  //               onClick={() => handleSelect({ key: featureKey, ...availableFeatures[featureKey] })}
  //               className="rounded-main shadow flex flex-col hover:cursor-pointer"
  //             >
  //               <div className="relative">
  //                 <ImgBox src={{ url: imageUrl }} />
  //                 {isSelected && (
  //                   <div className="absolute right-1 top-2 bg-white-light rounded-full">
  //                     <Checkmark color="green-main" size={40} />
  //                   </div>
  //                 )}
  //               </div>
  //               <div className="text-brown-dark p-2 font-bold">{name}</div>
  //               {description && (
  //                 <div className="text-brown-dark px-2 text-sm pb-2 flex-1 flex justify-center items-center">
  //                   {description}
  //                 </div>
  //               )}
  //             </div>
  //           );
  //         })}
  //     </div>
  //   </LayoutModal>
  // );
};

export { ModalEditPriorities };
