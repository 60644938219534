import React from "react";
import SvgContainer from "../SvgWrapper";

const CostIcon = ({ color, size, ...rest }) => (
  <SvgContainer
    width={`${size}`}
    height={`${size}`}
    viewBox="0 0 300 300"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      d="M299.734 114.797C299.696 114.456 299.582 114.114 299.468 113.772C299.241 112.861 298.899 111.987 298.443 111.152C298.215 110.772 298.025 110.392 297.76 110.051C297.532 109.633 297.266 109.291 296.962 108.949C296.696 108.608 296.43 108.304 296.127 108L192.835 3.91139H192.797C191.278 2.3924 189.418 1.25316 187.329 0.607595C187.101 0.531646 186.835 0.455696 186.57 0.379747C185.544 0.151899 184.481 0 183.38 0H53.1266C23.8481 0 0 23.8481 0 53.1266V246.873C0 276.152 23.8481 300 53.1266 300H246.873C276.152 300 300 276.152 300 246.873V117.38C300 116.506 299.924 115.633 299.734 114.797ZM196.671 45.5316L254.81 104.089H202.405C199.253 104.089 196.671 101.506 196.671 98.3544V45.5316ZM273.418 246.873C273.418 261.494 261.494 273.418 246.873 273.418H53.1266C38.5063 273.418 26.5823 261.494 26.5823 246.873V53.1266C26.5823 38.5063 38.5063 26.5823 53.1266 26.5823H170.089V98.3544C170.089 116.165 184.595 130.671 202.405 130.671H273.418V246.873Z"
      fill={color}
    />
    <path
      d="M101.393 102.266C101.393 109.823 96.4565 114.873 87.7223 116.316V122.696C87.7223 123.759 86.8868 124.595 85.8235 124.595H78.0767C77.0514 124.595 76.178 123.759 76.178 122.696V116.203C69.7982 115.063 64.4438 111.911 60.6084 106.975C60.0387 106.253 60.1147 105.228 60.7603 104.544L65.4691 99.5316C65.8109 99.1519 66.3805 98.962 66.8362 98.924C67.3678 98.924 67.8615 99.1519 68.2413 99.5316C72.1527 103.785 76.6337 105.759 82.4058 105.759C87.1147 105.759 89.4691 104.544 89.4691 102.19C89.4691 100.101 88.8995 98.6962 80.8489 97.1392C71.697 95.4683 62.7349 92.924 62.7349 81.9114C62.7349 74.924 67.9754 69.5696 76.178 67.8987V62.2405C76.178 61.1772 77.0514 60.3418 78.0767 60.3418H85.8235C86.8868 60.3418 87.7223 61.1772 87.7223 62.2405V68.0127C92.7729 69.0759 96.9881 71.6202 100.482 75.8354C101.127 76.557 101.089 77.6962 100.406 78.3797L95.621 83.1645C95.2413 83.5443 94.7096 83.5823 94.2539 83.7342C93.7602 83.7342 93.2666 83.5063 92.9248 83.1645C88.9754 79.1392 86.0134 78.4177 81.6463 78.4177C79.5197 78.4177 74.583 78.7215 74.583 81.7595C74.583 84.038 75.9501 84.6835 83.621 86.2025C92.8489 87.9494 101.393 91.1392 101.393 102.266Z"
      fill={color}
    />
    <path
      d="M235.444 172.975H64.5575C61.4056 172.975 58.8613 170.43 58.8613 167.278C58.8613 164.127 61.4056 161.582 64.5575 161.582H235.444C238.596 161.582 241.14 164.127 241.14 167.278C241.14 170.43 238.596 172.975 235.444 172.975Z"
      fill={color}
    />
    <path
      d="M235.444 209.392H64.5575C61.4056 209.392 58.8613 206.848 58.8613 203.696C58.8613 200.544 61.4056 198 64.5575 198H235.444C238.596 198 241.14 200.544 241.14 203.696C241.14 206.848 238.596 209.392 235.444 209.392Z"
      fill={color}
    />
    <path
      d="M192.039 244.747H64.5575C61.4056 244.747 58.8613 242.203 58.8613 239.051C58.8613 235.899 61.4056 233.354 64.5575 233.354H192.039C195.19 233.354 197.735 235.899 197.735 239.051C197.735 242.203 195.19 244.747 192.039 244.747Z"
      fill={color}
    />
  </SvgContainer>
);

export default CostIcon;
