import React, { useMemo, useState } from 'react';
import { useModel } from '@thrivelot/hooks';
import { sortComplex } from '@thrivelot/utils';
import { AngleDown, Cancel } from '@thrivelot/stories';
import { featurelessFeature } from '../FormBundleFeature/FormBundleFeature';
import { NewFeatureForm } from './NewFeatureForm';

const SelectProjectFeature = ({
  projectId,
  currentFeatureId,
  onSelect,
  placeholder,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [creatingFeature, setCreatingFeature] = useState(false);
  const { model: project, loaded } = useModel({
    modelName: 'Project',
    id: projectId,
  });
  const { features } = project || {};

  const sortedFeatures = useMemo(() => {
    if (!loaded) return [];

    return [
      featurelessFeature,
      ...sortComplex({ arr: features || [], by: 'name' })
        .filter((feature) => feature.name)
        .filter((f) => !f.hidden),
    ];
  }, [loaded, features]);

  const selectedFeature = useMemo(
    () => sortedFeatures.find((feature) => feature.id === currentFeatureId),
    [sortedFeatures, currentFeatureId]
  );

  if (!isOpen && !selectedFeature)
    // When there is no feature selected
    return (
      <div>
        <div className="text-brown-dark text-sm font-bold mb-2">
          Add line items to which feature?
        </div>
        <div
          className="flex items-center rounded-main py-1 px-4 border-2 border-tan-dark text-tan-dark hover:cursor-pointer"
          onClick={() => setIsOpen(true)}
        >
          <div className="flex-1 italic">
            {placeholder || 'Select feature...'}
          </div>
          <AngleDown size="16" color="tan-dark" />
        </div>
      </div>
    );

  if (!isOpen)
    // When there is a feature selected and the user is not choosing a new one
    return (
      <div
        className="flex items-center rounded-main py-1 px-4 bg-green-main text-white-light hover:cursor-pointer"
        onClick={() => setIsOpen(true)}
      >
        <div className="flex-1">{selectedFeature.name || 'No name...'}</div>
        <AngleDown size="16" color="white-light" />
      </div>
    );

  if (creatingFeature)
    return (
      <NewFeatureForm
        projectId={projectId}
        onSubmit={(featureId) => {
          onSelect(featureId);
          setIsOpen(false);
        }}
        onCancel={() => setCreatingFeature(false)}
      />
    );

  return (
    <div className="border-2 border-tan-main rounded-main overflow-hidden max-h-36 overflow-y-scroll">
      <div className="py-2 px-4 flex items-center">
        <div className="flex-1 italic text-brown-dark text-sm">
          Select feature from below or{' '}
          <span
            className="underline text-green-main hover:cursor-pointer"
            onClick={() => setCreatingFeature(true)}
          >
            create a new feature
          </span>
        </div>
        <Cancel
          color="yellow-main"
          size={16}
          onClick={() => setIsOpen(false)}
          styleProps="hover:cursor-pointer"
        />
      </div>
      {sortedFeatures.map((feature) => (
        <div
          key={feature.id}
          className={`flex items-center py-1 px-4 hover:cursor-pointer ${
            feature.id === currentFeatureId
              ? 'bg-green-main text-white-light'
              : 'text-brown-dark hover:bg-tan-light'
          }`}
          onClick={() => {
            onSelect(feature.id);
            setIsOpen(false);
          }}
        >
          {feature.name}
        </div>
      ))}
    </div>
  );
};

export { SelectProjectFeature };
