import { print } from "graphql";

const constructHeaders = async ({ apiKey, fetchJwt }) => {
  const headers = { "Content-Type": "application/json" };

  if (apiKey) headers["x-api-key"] = apiKey;
  else {
    const jwt = await fetchJwt();

    headers.Authorization = jwt;
  }

  return headers;
};

const constructRequest = ({ gql, variables, endpoint, apiKey, fetchJwt }) =>
  constructHeaders({ apiKey, fetchJwt }).then((headers) => ({
    endpoint,
    variables: { query: print(gql), variables },
    headers,
  }));

export { constructRequest };
