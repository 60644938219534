// @ts-check
import { initSchema } from '@aws-amplify/datastore';
import { schema } from './schema';

const CostPaymentScheduleType = {
  "DESIGN": "DESIGN",
  "INVOICE": "INVOICE",
  "PHASE": "PHASE",
  "STEWARDSHIP": "STEWARDSHIP",
  "SITE_VISIT": "SITE_VISIT"
};

const PaymentCalculationType = {
  "AMOUNT": "AMOUNT",
  "LABOR_PERCENTAGE": "LABOR_PERCENTAGE",
  "MATERIAL_PERCENTAGE": "MATERIAL_PERCENTAGE"
};

const ProjectMilestoneType = {
  "OPEN_PROJECT_ACCEPTANCE": "OPEN_PROJECT_ACCEPTANCE",
  "SITE_ASSESSMENT": "SITE_ASSESSMENT",
  "PROJECT_PROPOSAL": "PROJECT_PROPOSAL",
  "DESIGN": "DESIGN",
  "PRE_INSTALLATION": "PRE_INSTALLATION",
  "INSTALLATION": "INSTALLATION",
  "STEWARDSHIP": "STEWARDSHIP",
  "CHURN": "CHURN"
};

const ProjectMilestoneLifecycleEventType = {
  "ADMIN_ACCEPTANCE": "ADMIN_ACCEPTANCE",
  "ADMIN_REJECTION": "ADMIN_REJECTION",
  "CUSTOMER_CHANGE_ORDER_SUBMISSION": "CUSTOMER_CHANGE_ORDER_SUBMISSION",
  "CUSTOMER_PAYMENT": "CUSTOMER_PAYMENT",
  "SUPPLIER_SUBMISSION": "SUPPLIER_SUBMISSION"
};

const Day = {
  "SUNDAY": "SUNDAY",
  "MONDAY": "MONDAY",
  "TUESDAY": "TUESDAY",
  "WEDNESDAY": "WEDNESDAY",
  "THURSDAY": "THURSDAY",
  "FRIDAY": "FRIDAY",
  "SATURDAY": "SATURDAY"
};

const EstimatedDeliveryUnit = {
  "DAYS": "DAYS",
  "WEEKS": "WEEKS",
  "MONTHS": "MONTHS"
};

const MeasurementUnit = {
  "IN": "in",
  "FT": "ft",
  "YD": "yd",
  "CM": "cm",
  "M": "m",
  "SQ_IN": "sq_in",
  "SQ_FT": "sq_ft",
  "SQ_YD": "sq_yd",
  "SQ_CM": "sq_cm",
  "SQ_M": "sq_m",
  "CU_IN": "cu_in",
  "CU_FT": "cu_ft",
  "CU_YD": "cu_yd",
  "CU_CM": "cu_cm",
  "CU_M": "cu_m",
  "OZ": "oz",
  "QT": "qt",
  "GAL": "gal",
  "M_L": "mL",
  "L": "L",
  "HOURS": "hours",
  "DAYS": "days",
  "UNITS": "units",
  "TONS": "tons",
  "BAGS": "bags",
  "FLATS": "flats",
  "PLANTS": "plants"
};

const ProjectAnnotationType = {
  "FILE": "FILE",
  "NOTES": "NOTES"
};

const ProjectComplexity = {
  "LOW": "LOW",
  "MED": "MED",
  "HIGH": "HIGH"
};

const ProjectRole = {
  "DESIGN": "DESIGN",
  "INSTALLATION": "INSTALLATION",
  "STEWARDSHIP": "STEWARDSHIP",
  "PROJECT_MANAGEMENT": "PROJECT_MANAGEMENT"
};

const ProjectLineItemType = {
  "MATERIAL": "MATERIAL",
  "LABOR": "LABOR",
  "EQUIPMENT": "EQUIPMENT"
};

const ProjectMilestoneStage = {
  "OPEN_PROJECT": "OPEN_PROJECT",
  "SITE_ASSESSMENT": "SITE_ASSESSMENT",
  "DETAILED_PLAN": "DETAILED_PLAN",
  "INSTALLATION": "INSTALLATION",
  "STEWARDSHIP": "STEWARDSHIP",
  "CHURN": "CHURN"
};

const TagIdType = {
  "ACTIVITY": "ACTIVITY",
  "ANALYSIS": "ANALYSIS",
  "ANNOTATION": "ANNOTATION",
  "FEATURE": "FEATURE",
  "FILE": "FILE",
  "LINE_ITEM": "LINE_ITEM",
  "PHASE": "PHASE",
  "PLANT": "PLANT",
  "PROJECT": "PROJECT",
  "ZONE": "ZONE"
};

const TimestampKind = {
  "INVOICE_PROPOSAL_TERMS_AGREED": "invoiceProposalTermsAgreed",
  "INVOICE_PROPOSAL_SUBMITTED": "invoiceProposalSubmitted",
  "INVOICE_PROPOSAL_UNSUBMITTED": "invoiceProposalUnsubmitted",
  "INVOICE_PROPOSAL_APPROVED": "invoiceProposalApproved",
  "INVOICE_PROPOSAL_PAYMENT_SENT": "invoiceProposalPaymentSent",
  "INVOICE_PROPOSAL_PAYOUT_SENT": "invoiceProposalPayoutSent",
  "INVOICE_PROPOSAL_SUBMITTED_FOR_COMPLETION": "invoiceProposalSubmittedForCompletion",
  "INVOICE_PROPOSAL_UNSUBMITTED_FOR_COMPLETION": "invoiceProposalUnsubmittedForCompletion",
  "INVOICE_PROPOSAL_APPROVED_FOR_COMPLETION": "invoiceProposalApprovedForCompletion",
  "INVOICE_PROPOSAL_COMPLETE": "invoiceProposalComplete"
};

const LineItemKind = {
  "PLANT": "plant",
  "LABOR": "labor",
  "MATERIAL": "material",
  "EQUIPMENT": "equipment",
  "DISCOUNT": "discount"
};

const CreditItemDirection = {
  "INFLOW": "inflow",
  "OUTFLOW": "outflow"
};

const BundleStatus = {
  "UNLOCKED": "unlocked",
  "CHANGES_ONLY": "changes_only",
  "LOCKED": "locked"
};

const InvoiceProposalKind = {
  "SINGLE_BILLING": "single_billing",
  "TIME_MATERIALS_BILLING": "time_materials_billing",
  "DESIGN_BILLING": "design_billing"
};

const InvoiceProposalStatus = {
  "DRAFT": "draft",
  "SUBMITTED": "submitted",
  "APPROVED": "approved",
  "ACTIVE": "active",
  "SUBMITTED_FOR_COMPLETION": "submitted_for_completion",
  "APPROVED_FOR_COMPLETION": "approved_for_completion",
  "COMPLETE": "complete"
};

const PaymentStatus = {
  "WAITING": "waiting",
  "PARTIAL": "partial",
  "FULL": "full"
};

const ProposalPhaseKind = {
  "DATE": "date",
  "RANGE": "range"
};

const StripeObjectType = {
  "TRANSFER": "transfer",
  "PAYMENT_INTENT": "payment_intent",
  "INVOICE": "invoice"
};

const InvoiceProposalTotals = {
  "GRAND_TOTALS": "grand_totals",
  "FEATURE_TOTALS": "feature_totals",
  "ITEM_TOTALS": "item_totals"
};

const { Customer, Project, Supplier, SubscriptionNotification, Bundle, InvoiceProposal, Catalog, UpdatedMeta, Address, LatLng, S3Object, TimeBlock, TagId, CalendarNotes, CustomerAvailability, CustomerTermsOfUse, ProjectDetailsProperty, ProjectDetailsStyle, ProjectDetailsPriority, ProjectDetails, ProjectSiteAnalysis, ProjectFile, ProjectFileFolder, ProjectAnnotation, ProjectZone, ProjectFeature, ProjectPlantChanges, ProjectPlantItem, ProjectLineItem, ProjectActivity, ProjectPhase, ProjectPaymentCalculation, ProjectPayment, ProjectCostPaymentSchedule, DeliverableEstimatedDelivery, ProjectMilestoneDeliverable, ProjectMilestoneLifecycleEvent, ProjectMilestone, SupplierQualifications, SupplierLinks, SupplierTermsOfUse, Timestamp, BundleFeatureData, LineItemData, LineItem, DiscountItem, CreditItem, ProposalPhase, Payment, Payout } = initSchema(schema);

export {
  Customer,
  Project,
  Supplier,
  SubscriptionNotification,
  Bundle,
  InvoiceProposal,
  Catalog,
  CostPaymentScheduleType,
  PaymentCalculationType,
  ProjectMilestoneType,
  ProjectMilestoneLifecycleEventType,
  Day,
  EstimatedDeliveryUnit,
  MeasurementUnit,
  ProjectAnnotationType,
  ProjectComplexity,
  ProjectRole,
  ProjectLineItemType,
  ProjectMilestoneStage,
  TagIdType,
  TimestampKind,
  LineItemKind,
  CreditItemDirection,
  BundleStatus,
  InvoiceProposalKind,
  InvoiceProposalStatus,
  PaymentStatus,
  ProposalPhaseKind,
  StripeObjectType,
  InvoiceProposalTotals,
  UpdatedMeta,
  Address,
  LatLng,
  S3Object,
  TimeBlock,
  TagId,
  CalendarNotes,
  CustomerAvailability,
  CustomerTermsOfUse,
  ProjectDetailsProperty,
  ProjectDetailsStyle,
  ProjectDetailsPriority,
  ProjectDetails,
  ProjectSiteAnalysis,
  ProjectFile,
  ProjectFileFolder,
  ProjectAnnotation,
  ProjectZone,
  ProjectFeature,
  ProjectPlantChanges,
  ProjectPlantItem,
  ProjectLineItem,
  ProjectActivity,
  ProjectPhase,
  ProjectPaymentCalculation,
  ProjectPayment,
  ProjectCostPaymentSchedule,
  DeliverableEstimatedDelivery,
  ProjectMilestoneDeliverable,
  ProjectMilestoneLifecycleEvent,
  ProjectMilestone,
  SupplierQualifications,
  SupplierLinks,
  SupplierTermsOfUse,
  Timestamp,
  BundleFeatureData,
  LineItemData,
  LineItem,
  DiscountItem,
  CreditItem,
  ProposalPhase,
  Payment,
  Payout
};