import styled from "styled-components";

const DivCalendarHeader = styled.div`
  transition: opacity 0.5s ease-out, box-shadow 0.5s ease-out, color 1s ease-in-out, background-color 1s ease-in-out;
  padding: 10px 15px 3px 15px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: ${({ theme }) => theme.foreground};
`;

export default DivCalendarHeader;
