import { calculateTotals } from './calculateTotals';
import { metaMap } from './metaMap';

const constructSummarySinglePayment = ({
  bundles,
  items,
  cacheMap,
  creditItem,
  housePercent,
  paymentMethod,
}) => ({
  items,
  payments: [
    {
      label: 'single_payment_1',
      nextInvoiceProposalStatus: 'complete',
      stripeId: cacheMap.single_payment_1?.stripeId,

      ...metaMap.single_payment_1,
      ...calculateTotals({
        items,
        cache: cacheMap.single_payment_1,
        housePercent,
        creditItem,
        applyCredit: true,
        paymentMethod,
      }),
      stripeObjectCache: cacheMap.single_payment_1?.stripeObjectCache,
      stripeObjectStatus: cacheMap.single_payment_1?.stripeObjectStatus,
      invoiceSentAt: cacheMap.single_payment_1?.invoiceSentAt,
    },
  ],
  payouts: [
    ...bundles.map(({ supplierPayeeId }) => {
      const { priceSubtotal } = calculateTotals({
        items, // Needs to be the payment items
      });

      return {
        label: 'single_payout_1',
        paymentLabel: 'single_payment_1',
        ...metaMap.single_payout_1,
        supplierPayeeId,
        priceSubtotal:
          cacheMap[`single_payout_1:${supplierPayeeId}`]?.priceSubtotal ||
          priceSubtotal,
        totalAmount:
          cacheMap[`single_payout_1:${supplierPayeeId}`]?.totalAmount ||
          priceSubtotal,
        paidAt: cacheMap[`single_payout_1:${supplierPayeeId}`]?.paidAt || null,
      };
    }),
  ],
});

export { constructSummarySinglePayment };
