import React, { useMemo } from 'react';
import { Leaf, User } from '@thrivelot/stories';
import { ImageFetched } from '..';

const ImageUserAvatar = ({ user, size = 30, loading }) => {
  let pic;
  if (user) {
    pic = user.pic;
  }

  const missingFile = useMemo(() => {
    if (!pic || !pic.key || pic.key === '') return true;
    return false;
  }, [pic]);

  if (loading)
    return <Leaf size={size} color="brown-light" styleProps="animate-pulse" />;

  if (missingFile) return <User size={size} />;

  if (pic.key)
    return (
      <div className="rounded-full overflow-hidden">
        <ImageFetched
          fileKey={pic.key}
          height={size}
          width={size}
          style={{ height: `${size}px`, width: `${size}px` }}
        />
      </div>
    );

  return null;
};

export { ImageUserAvatar };
