import React, { Fragment } from "react";
import { useIsMobile } from "@thrivelot/hooks";
import { transformCurrencyToInt, transformCurrencyToFloat, transformCurrencyToString } from "@thrivelot/utils";
import {
  DivContentRow,
  DivInset,
  DivLineHorizontal,
  InputNumber,
  TextBodyBold,
  TextSmallHeader,
} from "@thrivelot/styles";
import { styleForListItemCostContainer, styleForListItemDollarSign } from "../helpers";

const StandardCalculation = ({ isLast, name, calculation, updateCalculation }) => {
  const theme = window.$theme;

  const isMobile = useIsMobile();

  const { amount } = calculation;

  return (
    <Fragment>
      <DivContentRow style={{ alignItems: "center", justifyContent: "space-between" }}>
        <TextSmallHeader
          color={name !== "SA Deposit" ? theme.green : undefined}
          style={{
            marginLeft: isMobile ? "8px" : "262px",
            marginRight: "12px",
            fontSize: isMobile ? "12px" : undefined,
          }}
        >
          {name || "Amount"}
        </TextSmallHeader>
        {updateCalculation ? (
          <div
            style={{
              marginRight: isMobile ? "13px" : "68px",
              minWidth: "90px",
              maxWidth: "90px",
              position: "relative",
            }}
          >
            <InputNumber
              inputType="bubble"
              placeholder="Price"
              value={transformCurrencyToFloat(amount) ? `${transformCurrencyToFloat(amount)}` : ""}
              onChange={(e) => updateCalculation({ amount: transformCurrencyToInt(e) })}
              style={{ textAlign: "right", width: "100%" }}
            />
            <TextBodyBold style={styleForListItemDollarSign}>$</TextBodyBold>
          </div>
        ) : (
          <DivInset
            style={{
              ...styleForListItemCostContainer,
              marginRight: isMobile ? undefined : "55px",
            }}
          >
            <TextSmallHeader>
              {name === "SA Deposit"
                ? `- $${transformCurrencyToString(amount).slice(1)}`
                : transformCurrencyToString(amount)}
            </TextSmallHeader>
          </DivInset>
        )}
      </DivContentRow>
      <DivLineHorizontal style={{ marginBottom: isLast && "45px" }} />
    </Fragment>
  );
};

export { StandardCalculation };
