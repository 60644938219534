import React from 'react';
import { PageContainer } from '@thrivelot/stories';
import {
  CardAccount,
  CardPayouts,
  CardTermsOfUse,
  CardTLResources,
  HelpPrompt,
} from '@thrivelot/components';

const PageSettings = () => (
  <PageContainer
    header="Settings"
    documentTitle="Settings"
    headerActions={
      <div className="text-brown-dark font-bold text-sm">{`App Version: ${window.appConfig.version}`}</div>
    }
    help={
      <HelpPrompt header="About your Settings page">
        Within your Settings page you can edit and update your account
        information and view available resources shared from the Supplier
        Development Team and other Ecosystems Specialists.
      </HelpPrompt>
    }
  >
    <div className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-4">
      <CardAccount />
      <CardPayouts />
      <CardTermsOfUse />
      <CardTLResources />
    </div>
  </PageContainer>
);

export { PageSettings };
