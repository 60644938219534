import React from "react";
import { ImgRectangle } from "@thrivelot/styles";
import { DefaultSupplierImage } from "../../../../../../shared";
import { useSupplierImageSize } from "../../../../hooks";

const ProfilePic = ({ supplierActions }) => {
  // hooks
  const imageSize = useSupplierImageSize();
  // actions
  const pic = supplierActions.get("pic");
  // utils
  const isValidPic = () => pic && pic.key && pic.key !== "";
  // return
  if (isValidPic()) return <ImgRectangle noRounding width={imageSize.width} height={imageSize.height} src={pic} />;
  return <DefaultSupplierImage size={imageSize.height} />;
};

export { ProfilePic };
