import { constructUuid } from '@thrivelot/utils';

const constructFeature = ({
  id = constructUuid(),
  name = '',
  description = '',
  orderedChildTagIds = [],
}) => ({
  id,
  name,
  description,
  orderedChildTagIds,
});

export { constructFeature };
