import { ModalAddBundleItems } from './ModalAddBundleItems';
import { ModalAddFeatureLineItem } from './ModalAddFeatureLineItem';
import { ModalAddFeaturePlant } from './ModalAddFeaturePlant';
import { ModalAddFiles } from './ModalAddFiles';
import { ModalAddFileToFolder } from './ModalAddFileToFolder';
import { ModalAddFolder } from './ModalAddFolder';
import { ModalAddInvoiceProposal } from './ModalAddInvoiceProposal';
import { ModalAddPlant } from './ModalAddPlant';
import { ModalAddPlantImages } from './ModalAddPlantImages/ModalAddPlantImages';
import { ModalAddProject } from './ModalAddProject';
import { ModalAddSupplier } from './ModalAddSupplier';
import { ModalAddSupplierPic } from './ModalAddSupplierPic';
import { ModalAdminEditInvoiceProposal } from './ModalAdminEditInvoiceProposal';
import { ModalEditAccount } from './ModalEditAccount';
import { ModalEditAddress } from './ModalEditAddress';
import { ModalEditBundleItem } from './ModalEditBundleItem';
import { ModalEditBundlePayee } from './ModalEditBundlePayee';
import { ModalEditDesire } from './ModalEditDesire';
import { ModalEditFeaturePlantDetails } from './ModalEditFeaturePlantDetails';
import { ModalEditFeatures } from './ModalEditFeatures';
import { ModalEditPassword } from './ModalEditPassword';
import { ModalEditPlant } from './ModalEditPlant';
import { ModalEditPriorities } from './ModalEditPriorities';
import { ModalEditProjectInfo } from './ModalEditProjectInfo';
import { ModalEditServiceProviders } from './ModalEditServiceProviders';
import { ModalEditSiteAnalysisNote } from './ModalEditSiteAnalysisNote';
import { ModalEditStyles } from './ModalEditStyles';
import { ModalInvoiceProposalPayment } from './ModalInvoiceProposalPayment';
import { ModalInvoiceProposalPayoutSummary } from './ModalInvoiceProposalPayoutSummary';
import { ModalInvoiceProposalVerifyBankAccount } from './ModalInvoiceProposalVerifyBankAccount';
import { ModalLinkCatalogPlantToDB } from './ModalLinkCatalogPlantToDB';
import { ModalLinkPlantToDB } from './ModalLinkPlantToDB';
import { ModalMoveBundleItems } from './ModalMoveBundleItems';
import { ModalReviewTerms } from './ModalReviewTerms';
import { ModalUpsertCatalogItem } from './ModalUpsertCatalogItem';
import { ModalVerifyUserAttribute } from './ModalVerifyUserAttribute';
import { ModalViewFiles } from './ModalViewFiles';
import { ModalViewImage } from './ModalViewImage';
import { ModalViewPdf } from './ModalViewPdf';
import { ModalViewPlant } from './ModalViewPlant';
import { ModalViewPlantDetails } from './ModalViewPlantDetails';
import { ModalViewPlantImages } from './ModalViewPlantImages';
import { ModalViewSupplierBio } from './ModalViewSupplierBio';

// Used in the RoutingModals to match the path to the component to display
const modals = {
  ModalAddBundleItems,
  ModalAddFeatureLineItem,
  ModalAddFeaturePlant,
  ModalAddFiles,
  ModalAddFileToFolder,
  ModalAddFolder,
  ModalAddInvoiceProposal,
  ModalAddPlant,
  ModalAddPlantImages,
  ModalAddProject,
  ModalAddSupplier,
  ModalAddSupplierPic,
  ModalAdminEditInvoiceProposal,
  ModalEditAccount,
  ModalEditAddress,
  ModalEditBundleItem,
  ModalEditBundlePayee,
  ModalEditDesire,
  ModalEditFeaturePlantDetails,
  ModalEditFeatures,
  ModalEditPassword,
  ModalEditPlant,
  ModalEditPriorities,
  ModalEditProjectInfo,
  ModalEditServiceProviders,
  ModalEditSiteAnalysisNote,
  ModalEditStyles,
  ModalInvoiceProposalPayment,
  ModalInvoiceProposalPayoutSummary,
  ModalInvoiceProposalVerifyBankAccount,
  ModalLinkPlantToDB,
  ModalLinkCatalogPlantToDB,
  ModalMoveBundleItems,
  ModalReviewTerms,
  ModalUpsertCatalogItem,
  ModalVerifyUserAttribute,
  ModalViewFiles,
  ModalViewImage,
  ModalViewPdf,
  ModalViewPlant,
  ModalViewPlantDetails,
  ModalViewPlantImages,
  ModalViewSupplierBio,
};

export { modals };
