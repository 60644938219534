import React from 'react';

const Mail = ({
  color = 'green-main',
  size = 30,
  styleProps = '',
  ...rest
}) => (
  <svg
    width={size.toString()}
    height={size.toString()}
    viewBox="0 0 75 75"
    className={`fill-${color} ${styleProps}`}
    {...rest}
  >
    <path d="M61.7184 9H13.2816C5.96203 9 0 14.962 0 22.2816V53.6297C0 60.9494 5.96203 66.9114 13.2816 66.9114H61.7184C69.0475 66.9114 75 60.9494 75 53.6297V22.2816C75 14.962 69.0475 9 61.7184 9ZM13.2816 15.6456H61.7184C64.519 15.6456 66.9209 17.3924 67.8892 19.8513L37.5 42.6551L7.11076 19.8703C8.06962 17.4019 10.481 15.6456 13.2816 15.6456ZM68.3544 53.6297C68.3544 57.2848 65.3829 60.2658 61.7184 60.2658H13.2816C9.62658 60.2658 6.64557 57.2848 6.64557 53.6297V27.8259L35.5063 49.4715C36.1044 49.9177 36.7975 50.1361 37.5 50.1361C38.2025 50.1361 38.9051 49.9177 39.4937 49.4715L68.3544 27.807V53.6297Z" />
  </svg>
);

export { Mail };
