import { useCallback, useRef } from 'react';
import { StorageKit } from '@thrivelot/storage';
import { notifyErrors } from '../useModel/notifyErrors';
import { useModelKit } from '../useModel/useModelKit';
import { constructFile } from './constructFile';

const useFiles = () => {
  const { modelConfig, storageConfig } = useModelKit();

  const modelConfigRef = useRef();
  const storageConfigRef = useRef();

  modelConfigRef.current = modelConfig;
  storageConfigRef.current = storageConfig;

  const fetch = useCallback(
    (key) =>
      new StorageKit({ key, ...storageConfigRef.current })
        .fetch()
        .then((data) => ({ data }))
        .catch((err) => {
          notifyErrors(err, modelConfigRef.current.onFileQueryError);

          return { error: err };
        }),
    []
  );

  const fetchUrl = useCallback(
    (key) =>
      new StorageKit({ key, ...storageConfigRef.current })
        .fetchSignedUrl()
        .then((data) => ({ data }))
        .catch((err) => {
          notifyErrors(err, modelConfigRef.current.onFileQueryError);

          return { error: err };
        }),
    []
  );

  const upload = useCallback(
    ({ filename, data, metadata }) =>
      constructFile({ filename, metadata, ...storageConfigRef.current }).then(
        (file) =>
          new StorageKit({
            body: data,
            key: file.key,
            metadata,
            ...storageConfigRef.current,
          })
            .upload()
            .then((result) => ({ data: { ...result, file } }))
            .catch((err) => {
              notifyErrors(err, modelConfigRef.current.onFileSaveError);

              return { error: err };
            })
      ),
    []
  );

  return { fetch, fetchUrl, upload };
};

export { useFiles };
