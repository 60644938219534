import { fetchUserJwt, refreshUserSession } from '@thrivelot/auth';
import { parseJwt } from '@thrivelot/utils';

const fetchRefreshedJwt = async () => {
  const session = await refreshUserSession();
  return session.getIdToken().getJwtToken();
};

const fetchJwt = async () => {
  let jwt;

  try {
    jwt = await fetchUserJwt();
  } catch (err) {
    jwt = null;
    console.log(`Error fetching jwt: `, JSON.stringify(err, null, 2));
  }

  let exp;
  let offset;

  if (jwt) {
    ({ exp } = parseJwt(jwt));
    const now = parseInt(new Date().getTime() / 1000, 10);
    offset = now + 300; // Now plus 5 min
  }

  try {
    if (!jwt || offset > exp) {
      console.log('Refreshing jwt');
      jwt = await fetchRefreshedJwt();
    }
  } catch (err) {
    console.log(`Error refreshing jwt: `, JSON.stringify(err, null, 2));
    throw err;
  }

  return jwt;
};

export { fetchJwt };
