import React from "react";
import colors from "@thrivelot/tailwind/colors";
import { useMobileDifference } from "@thrivelot/hooks";
import { useProposal, useProposalView } from "@thrivelot/common";
import { PhaseIcon, TextTitle } from "@thrivelot/styles";
import { useIconSize, useSpacing } from "../../../../hooks";
import { TitleBarContainer } from "./TitleBarContainer";

const TitleBar = () => {
  const { projectActions } = useProposal();
  const { phase: phaseId } = useProposalView();
  const iconSize = useIconSize();
  const spacing = useSpacing();
  const iconMarginTop = useMobileDifference({ desktop: "1px", mobile: "3px" });
  if (!phaseId) return null;
  const name = projectActions.get(`phases[id:${phaseId}].name`);
  return (
    <TitleBarContainer>
      <PhaseIcon color={colors.yellow.dark} size={iconSize} style={{ marginTop: iconMarginTop }} />
      <TextTitle style={{ marginLeft: spacing, marginRight: "12px" }}>{name}</TextTitle>
    </TitleBarContainer>
  );
};

export { TitleBar };
