import React from 'react';
import TextareaAutosize from 'react-textarea-autosize';

export const TextareaWithLabel = ({
  name,
  label,
  placeholder,
  value,
  onChange,
}) => (
  <div className="mb-3">
    <label
      htmlFor={name}
      className="block font-accent text-mulch-dark text-sm ml-2 mb-0.5"
    >
      {label}
    </label>
    <TextareaAutosize
      className="input"
      aria-label={name}
      data-testid={`input-for-${name}`}
      minRows={2}
      placeholder={placeholder}
      value={value}
      onChange={onChange}
    />
  </div>
);
