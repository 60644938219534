import { duplicateObject } from "@thrivelot/utils";

const getModelObjById = ({ id, arr, customKey }) => {
  if (!arr) return {};

  const key = customKey || "id";

  let index = -1;
  const obj = arr.find(({ [key]: propertyId }, objIndex) => {
    if (propertyId === id) {
      index = objIndex;
      return true;
    }
    return false;
  });

  return { obj: duplicateObject(obj), index };
};

export { getModelObjById };
