import { get } from "@thrivelot/actions";

const calculateTotalMaterialsCost = (model) => {
  const lineItems = get(model, "lineItems");
  const plantItems = get();

  let materialsCost = 0;

  lineItems.forEach((lineItem) => {
    if (lineItem.type !== "LABOR") {
      materialsCost += lineItem.cost * lineItem.quantity;
    }
  });

  plantItems.forEach((plantItem) => {
    materialsCost += plantItem.cost * plantItem.quantity;
  });

  return materialsCost;
};

export { calculateTotalMaterialsCost };
