const getChangeValue = (plant, key) => {
  if (!plant.changes) return "";
  const { change } = plant.changes.find((plantChange) => plantChange.key === key);
  if (!change) return "";
  return change;
};

const nameComparison = (a, b) => {
  if (a.name > b.name) return 1;
  if (a.name < b.name) return -1;
  return 0;
};
const quantityComparison = (a, b) => {
  if (a.quantity > b.quantity) return -1;
  if (a.quantity < b.quantity) return 1;
  return 0;
};
const sort = (plants = []) => {
  const sortedByName = plants.sort(nameComparison);
  return sortedByName.sort(quantityComparison);
};

const formatPlants = (plants) => {
  const formattedPlants = [];
  plants.forEach((plant) => {
    const imageUrl = getChangeValue(plant, "imageUrl");
    let name = getChangeValue(plant, "commonName");
    if (name === "") name = getChangeValue(plant, "scientificName");
    if (name === "") name = "No name...";
    const index = formattedPlants.findIndex((formattedPlant) => formattedPlant.name === name);
    if (index === -1)
      formattedPlants.push({
        id: plant.id,
        name,
        imageUrl,
        quantity: plant.quantity || 0,
      });
    else formattedPlants[index].quantity += plant.quantity ? plant.quantity : 0;
  });
  return sort(formattedPlants).filter((plant) => plant.quantity > 0);
};

export { formatPlants };
