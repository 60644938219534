import React from 'react';

const ProgressSteward = ({ size = 30, classAppend = '', ...rest }) => (
  <svg
    width={`${size}px`}
    height={`${size}px`}
    viewBox="50 50 200 200"
    fill="none"
    className={classAppend}
    {...rest}
  >
    <circle cx="150.5" cy="150" r="94.5" stroke="#F5E8D3" strokeWidth="11" />
    <g opacity="0.3">
      <mask id="path-2-inside-1_167_1267" fill="white">
        <path d="M93.1424 231.915C75.8436 219.802 62.8235 202.524 55.9481 182.557C49.0728 162.589 48.6952 140.958 54.8695 120.763L65.3889 123.979C59.8938 141.953 60.2298 161.205 66.3488 178.976C72.4679 196.747 84.0558 212.124 99.4517 222.905L93.1424 231.915Z" />
      </mask>
      <path
        d="M93.1424 231.915C75.8436 219.802 62.8235 202.524 55.9481 182.557C49.0728 162.589 48.6952 140.958 54.8695 120.763L65.3889 123.979C59.8938 141.953 60.2298 161.205 66.3488 178.976C72.4679 196.747 84.0558 212.124 99.4517 222.905L93.1424 231.915Z"
        stroke="#00A272"
        strokeWidth="22"
        mask="url(#path-2-inside-1_167_1267)"
      />
      <mask id="path-3-inside-2_167_1267" fill="white">
        <path d="M209.279 230.902C192.194 243.314 171.618 250 150.5 250C129.382 250 108.806 243.314 91.7215 230.902L98.1871 222.003C113.393 233.05 131.705 239 150.5 239C169.295 239 187.607 233.05 202.813 222.003L209.279 230.902Z" />
      </mask>
      <path
        d="M209.279 230.902C192.194 243.314 171.618 250 150.5 250C129.382 250 108.806 243.314 91.7215 230.902L98.1871 222.003C113.393 233.05 131.705 239 150.5 239C169.295 239 187.607 233.05 202.813 222.003L209.279 230.902Z"
        stroke="#00A272"
        strokeWidth="22"
        mask="url(#path-3-inside-2_167_1267)"
      />
      <mask id="path-4-inside-3_167_1267" fill="white">
        <path d="M245.606 119.098C252.131 139.183 252.131 160.817 245.606 180.902C239.08 200.986 226.363 218.489 209.279 230.902L202.813 222.003C218.018 210.955 229.336 195.378 235.144 177.503C240.952 159.627 240.952 140.373 235.144 122.497L245.606 119.098Z" />
      </mask>
      <path
        d="M245.606 119.098C252.131 139.183 252.131 160.817 245.606 180.902C239.08 200.986 226.363 218.489 209.279 230.902L202.813 222.003C218.018 210.955 229.336 195.378 235.144 177.503C240.952 159.627 240.952 140.373 235.144 122.497L245.606 119.098Z"
        stroke="#00A272"
        strokeWidth="22"
        mask="url(#path-4-inside-3_167_1267)"
      />
      <mask id="path-5-inside-4_167_1267" fill="white">
        <path d="M150.5 50C171.618 50 192.194 56.6855 209.279 69.0983C226.363 81.5111 239.08 99.014 245.606 119.098L235.144 122.497C229.336 104.622 218.018 89.0449 202.813 77.9975C187.607 66.9501 169.295 61 150.5 61V50Z" />
      </mask>
      <path
        d="M150.5 50C171.618 50 192.194 56.6855 209.279 69.0983C226.363 81.5111 239.08 99.014 245.606 119.098L235.144 122.497C229.336 104.622 218.018 89.0449 202.813 77.9975C187.607 66.9501 169.295 61 150.5 61V50Z"
        stroke="#00A272"
        strokeWidth="22"
        mask="url(#path-5-inside-4_167_1267)"
      />
    </g>
    <mask id="path-6-inside-5_167_1267" fill="white">
      <path d="M54.8695 120.763C61.1285 100.291 73.7921 82.3673 90.9976 69.6292C108.203 56.8911 129.042 50.0107 150.45 50L150.455 61C131.403 61.0095 112.856 67.1331 97.5429 78.47C82.23 89.8069 70.9594 105.759 65.3889 123.979L54.8695 120.763Z" />
    </mask>
    <path
      d="M54.8695 120.763C61.1285 100.291 73.7921 82.3673 90.9976 69.6292C108.203 56.8911 129.042 50.0107 150.45 50L150.455 61C131.403 61.0095 112.856 67.1331 97.5429 78.47C82.23 89.8069 70.9594 105.759 65.3889 123.979L54.8695 120.763Z"
      stroke="#761DB4"
      strokeWidth="22"
      mask="url(#path-6-inside-5_167_1267)"
    />
    <path
      d="M171.233 143.013C168.575 140.456 164.335 140.532 161.778 143.177L142.841 162.772L139.259 159C138.031 157.709 136.36 156.975 134.575 156.924C132.79 156.886 131.094 157.532 129.803 158.759C128.512 160 127.778 161.658 127.727 163.443C127.689 165.228 128.335 166.924 129.562 168.215L137.955 177.038C139.208 178.354 140.955 179.101 142.778 179.114H142.803C144.6 179.114 146.347 178.367 147.6 177.076L171.385 152.468C172.638 151.19 173.297 149.494 173.271 147.709C173.233 145.924 172.512 144.253 171.233 143.013ZM168.664 149.823L144.879 174.43C144.335 175 143.575 175.316 142.803 175.316H142.79C142.005 175.316 141.246 174.987 140.702 174.418L132.322 165.595C131.221 164.443 131.271 162.607 132.423 161.519C132.967 161 133.664 160.721 134.41 160.721H134.486C135.259 160.747 135.967 161.063 136.499 161.62L142.816 168.266L164.512 145.81C165.613 144.671 167.448 144.645 168.588 145.747C169.145 146.278 169.461 147 169.474 147.772C169.486 148.544 169.195 149.278 168.664 149.823Z"
      fill="#761DB4"
    />
    <path
      d="M182.791 113.582H177.715V104.43C177.715 101.987 175.728 100 173.285 100C170.842 100 168.854 101.987 168.854 104.43V113.582H132.146V104.43C132.146 101.987 130.158 100 127.715 100C125.272 100 123.285 101.987 123.285 104.43V113.582H118.209C108.449 113.582 100.5 121.532 100.5 131.291V182.291C100.5 192.051 108.449 200 118.209 200H182.791C192.551 200 200.5 192.051 200.5 182.291V131.291C200.5 121.532 192.551 113.582 182.791 113.582ZM191.639 182.291C191.639 187.165 187.665 191.139 182.791 191.139H118.209C113.335 191.139 109.361 187.165 109.361 182.291V131.291C109.361 126.418 113.335 122.443 118.209 122.443H123.285V127.051C123.285 129.494 125.272 131.481 127.715 131.481C130.158 131.481 132.146 129.494 132.146 127.051V122.443H168.854V127.051C168.854 129.494 170.842 131.481 173.285 131.481C175.728 131.481 177.715 129.494 177.715 127.051V122.443H182.791C187.665 122.443 191.639 126.418 191.639 131.291V182.291Z"
      fill="#761DB4"
    />
  </svg>
);

export { ProgressSteward };
