import { TagIdType } from "@thrivelot/aws";

const getCostEstimateItems = ({ tagIds, project }) => {
  if (!tagIds) return { lineItems: [], plantItems: [] };

  const lineItems = [];
  const plantItems = [];

  tagIds.forEach((tagId) => {
    if (tagId.type === TagIdType.LINE_ITEM) {
      const lineItem = project.lineItems && project.lineItems.find((e) => e.id === tagId.tagId);

      if (lineItem) lineItems.push(lineItem);
    } else if (tagId.type === TagIdType.PLANT) {
      const plantItem = project.plantItems && project.plantItems.find((e) => e.id === tagId.tagId);

      if (plantItem) plantItems.push(plantItem);
    }
  });

  return { lineItems, plantItems };
};

export { getCostEstimateItems };
