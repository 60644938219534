import { ApiKit } from '@thrivelot/api';

export const downloadImageFromUrl = async (
  endpoint: string
): Promise<string> => {
  const api = new ApiKit({
    endpoint,
    config: { responseType: 'blob' },
  });
  const data = await api.get();
  const reader = new FileReader();
  return new Promise((resolve, reject) => {
    reader.onloadend = () => {
      if (reader.result && typeof reader.result === 'string')
        return resolve(reader.result.replace(/^data:.+,/, ''));
      return reject(new Error('Failed to read image data'));
    };
    reader.readAsDataURL(data);
  });
};
