/* global google */

// Imported from https://github.com/ErrorPro/react-google-autocomplete/blob/master/src/usePlacesAutocompleteService.js

import { useEffect, useCallback, useRef, useState } from 'react';
import { handleDebounce } from '@thrivelot/utils';

const usePlacesAutocomplete = ({
  isLoaded,
  apiKey,
  libraries = 'places',
  debounce = 300,
  options = {},
  sessionToken,
  language,
}) => {
  const [placePredictions, setPlacePredictions] = useState([]);
  const [isPlacePredsLoading, setIsPlacePredsLoading] = useState(false);
  const [placeInputValue, setPlaceInputValue] = useState(null);
  const [isQueryPredsLoading, setIsQueryPredsLoading] = useState(false);
  const [queryInputValue, setQueryInputValue] = useState(false);
  const [queryPredictions, setQueryPredictions] = useState([]);
  const placesAutocompleteService = useRef(null);
  const placesService = useRef(null);
  const autocompleteSession = useRef(null);

  const debouncedPlacePredictions = useCallback(
    handleDebounce((optionsArg) => {
      if (placesAutocompleteService.current && optionsArg.input)
        placesAutocompleteService.current.getPlacePredictions(
          {
            ...(sessionToken && autocompleteSession.current
              ? { sessionToken: autocompleteSession.current }
              : {}),
            ...options,
            ...optionsArg,
          },
          (r) => {
            setIsPlacePredsLoading(false);
            setPlacePredictions(r || []);
          }
        );
    }, debounce),
    [debounce, isLoaded]
  );

  const debouncedQueryPredictions = useCallback(
    handleDebounce((optionsArg) => {
      if (placesAutocompleteService.current && optionsArg.input)
        placesAutocompleteService.current.getQueryPredictions(
          {
            ...(sessionToken && autocompleteSession.current
              ? { sessionToken: autocompleteSession.current }
              : {}),
            ...options,
            ...optionsArg,
          },
          (r) => {
            setIsQueryPredsLoading(false);
            setQueryPredictions(r || []);
          }
        );
    }, debounce),
    [debounce, isLoaded]
  );

  useEffect(() => {
    if (!isLoaded) return;

    const buildService = () => {
      // eslint-disable-next-line no-undef
      if (!google)
        return console.error(
          'Google has not been found. Make sure your provide apiKey prop.'
        );

      // eslint-disable-next-line no-undef
      placesAutocompleteService.current =
        new google.maps.places.AutocompleteService();

      // eslint-disable-next-line no-undef
      placesService.current = new google.maps.places.PlacesService(
        document.createElement('div')
      );

      // eslint-disable-next-line no-undef
      if (sessionToken)
        autocompleteSession.current =
          new google.maps.places.AutocompleteSessionToken();
    };

    buildService();
  }, [isLoaded, sessionToken]);

  return {
    placesService: placesService.current,
    autocompleteSessionToken: autocompleteSession.current,
    placesAutocompleteService: placesAutocompleteService.current,
    placePredictions: placeInputValue ? placePredictions : [],
    isPlacePredictionsLoading: isPlacePredsLoading,
    getPlacePredictions: (opt) => {
      if (opt.input) {
        setPlaceInputValue(opt.input);
        setIsPlacePredsLoading(true);
        debouncedPlacePredictions(opt);
        return;
      }
      setPlacePredictions([]);
      setPlaceInputValue(null);
      debouncedPlacePredictions(opt);
      setIsPlacePredsLoading(false);
    },
    queryPredictions: queryInputValue ? queryPredictions : [],
    isQueryPredictionsLoading: isQueryPredsLoading,
    getQueryPredictions: (opt) => {
      if (opt.input) {
        setQueryInputValue(opt.input);
        setIsQueryPredsLoading(true);
        debouncedQueryPredictions(opt);
        return;
      }
      setQueryPredictions([]);
      setQueryInputValue(null);
      debouncedQueryPredictions(opt);
      setIsQueryPredsLoading(false);
    },
    refreshSessionToken: () => {
      autocompleteSession.current =
        new google.maps.places.AutocompleteSessionToken();
    },
  };
};

export { usePlacesAutocomplete };
