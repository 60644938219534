import styled from "styled-components";

const DivMapInfoBox = styled.div`
  transition: opacity 0.5s ease-out, box-shadow 0.5s ease-out, color 1s ease-in-out, background-color 1s ease-in-out;
  position: absolute;
  top: 10px;
  right: 10px;
  min-width: 200px;
  max-width: 200px;
  border-radius: 5px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.foreground};
`;

export default DivMapInfoBox;
