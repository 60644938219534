import { Card } from "@thrivelot/stories";
import { CardLoader } from "@thrivelot/stories";
import React from "react";
import TextareaAutosize from "react-textarea-autosize";
import { useCanCan, useModal, useModel } from "@thrivelot/hooks";
import { Edit, Trash } from "@thrivelot/stories";
import { Confirm } from "../Confirm";
import { isEmpty } from "@thrivelot/utils";

const CardSiteAnalysisNote = ({ projectId, siteAnalysisId }) => {
  const { openModal } = useModal();
  const { model: project, updateModel, actions, loaded } = useModel({ modelName: "Project", id: projectId });
  const { canEdit } = useCanCan(project);

  if (!loaded) return <CardLoader showText />;

  const path = `siteAnalysis[id:${siteAnalysisId}]`;
  const { title, observations, recommendations } = actions.get(path);

  return (
    <Card
      header={title || <div className="font-normal italic">Edit to add title...</div>}
      subheader={canEdit && <div className="italic font-normal text-sm">Visible to customer</div>}
      headerActions={
        canEdit && (
          <div className="flex gap-4">
            <Edit
              color="yellow-dark"
              styleProps="hover:cursor-pointer"
              size="20"
              onClick={() => openModal({ path: "ModalEditSiteAnalysisNote", projectId, siteAnalysisId })}
            />
            <Confirm
              prompt="Are you sure you want to delete this order?"
              onConfirm={() => updateModel(actions.remove(path).result)}
            >
              <Trash color="red-main" styleProps="hover:cursor-pointer" size="20" />
            </Confirm>
          </div>
        )
      }
      classAppend="mb-4"
    >
      {!isEmpty(observations) || canEdit ? <div className="text-brown-dark font-bold">Observations</div> : null}
      {canEdit ? (
        <TextareaAutosize
          className="input"
          placeholder="Add a note here..."
          minRows={2}
          value={observations || ""}
          onChange={(e) => updateModel(actions.set(`${path}.observations`, e.target.value).result)}
        />
      ) : !isEmpty(observations) ? (
        <div className={!isEmpty(recommendations) && "mb-2"}>{observations}</div>
      ) : null}
      {!isEmpty(recommendations) || canEdit ? <div className="text-brown-dark font-bold">Recommendations</div> : null}
      {canEdit ? (
        <TextareaAutosize
          className="input"
          placeholder="Add a note here..."
          minRows={2}
          value={recommendations || ""}
          onChange={(e) => updateModel(actions.set(`${path}.recommendations`, e.target.value).result)}
        />
      ) : !isEmpty(recommendations) ? (
        <div>{recommendations}</div>
      ) : null}
    </Card>
  );
};

export { CardSiteAnalysisNote };
