import React from "react";
import { PhasePayment } from "./PhasePayment";
import { StandardPayment } from "./StandardPayment";

const Payment = ({ payment, projectId }) => {
  const { id, type } = payment;

  if (type !== "PHASE") return <StandardPayment paymentId={id} projectId={projectId} />;

  return <PhasePayment paymentId={id} projectId={projectId} />;
};

export { Payment };
