import React from "react";

const ViewSection = ({ header, children, disabled, classAppend }) => {
  return (
    <div className={`p-6 lg:p-12 xl:p-24 ${disabled ? "bg-[#fff3e0]" : "bg-white-light"} ${classAppend}`}>
      {header && (
        <div className="mb-6 lg:mb-12 xl:mb-24 text-center text-3xl lg:text-5xl font-header font-bold">{header}</div>
      )}
      {children}
    </div>
  );
};

export { ViewSection };
