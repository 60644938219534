import React, { useMemo, useState } from 'react';
import { useModel } from '@thrivelot/hooks';
import { LeafLoader } from '@thrivelot/stories';
import { useInvoiceProposalBillingSummary } from '@thrivelot/common';
import { InfoBar } from './InfoBar';
import { PlantPalette } from './PlantPalette';

export const ViewPlantPalette = ({ projectId, invoiceProposalId }) => {
  const [expanded, setExpanded] = useState(true);
  const { model: project } = useModel({
    modelName: 'Project',
    id: projectId,
  });
  const { billingSummary: billing, loaded: billingLoaded } =
    useInvoiceProposalBillingSummary(invoiceProposalId);

  const plantIdsWithQuantities = useMemo(() => {
    if (!billingLoaded) return [];

    const value = {};

    (billing?.items || []).forEach((item) => {
      if (item.data?.plantDatabaseId) {
        const { quantity, type } = item;
        const { plantDatabaseId, imageUrl } = item.data;

        const featureIds = [...(value[plantDatabaseId]?.featureIds || [])];
        if (!featureIds.includes(item.featureId))
          featureIds.push(item.featureId);

        if (type === 'change')
          value[plantDatabaseId] = {
            ...(imageUrl && { imageUrl }),
            featureIds,
            ...(value[plantDatabaseId] || {}),
            changeQuantity:
              (value[plantDatabaseId]?.changeQuantity || 0) + quantity,
          };
        else
          value[plantDatabaseId] = {
            ...(imageUrl && { imageUrl }),
            featureIds,
            ...(value[plantDatabaseId] || {}),
            quantity: (value[plantDatabaseId]?.quantity || 0) + quantity,
          };
      }
    });

    return Object.keys(value)
      .map((plantDatabaseId) => {
        const { quantity, changeQuantity, featureIds, imageUrl } =
          value[plantDatabaseId];
        const total = (quantity || 0) + (changeQuantity || 0);

        let zones = [];
        featureIds.forEach((featureId) => {
          const newZones = (project?.zones || []).filter(
            (z) =>
              z.orderedChildTagIds.findIndex(
                (tag) => tag.tagId === featureId
              ) !== -1
          );
          if (featureId === '__project__') {
            newZones.push({
              id: '__project__',
              type: 'general',
            });
          }
          zones.push(...newZones);
        });
        zones = zones.filter(
          (z, i, self) => self.findIndex((zo) => z.id === zo.id) === i
        );

        return {
          plantId: plantDatabaseId,
          imageUrl,
          featureIds,
          zones,
          quantity,
          changeQuantity,
          total,
        };
      })
      .sort((a, b) => b.total - a.total);
  }, [billing?.items, project?.zones]);

  if (!billingLoaded) return <LeafLoader />;

  return (
    <div className="p-6 lg:p-12 xl:p-24 bg-white-light space-y-6">
      <InfoBar
        plantIdsWithQuantities={plantIdsWithQuantities}
        expanded={expanded}
        setExpanded={setExpanded}
      />
      {expanded && (
        <PlantPalette plantIdsWithQuantities={plantIdsWithQuantities} />
      )}
    </div>
  );
};
