import { constructUuid } from "@thrivelot/utils";

const constructLifecycleEvent = ({ id = constructUuid(), type = "", by = "", at = new Date().toISOString() }) => ({
  id,
  type,
  by,
  at,
});

export { constructLifecycleEvent };
