import React, { useMemo } from "react";
import { useModel } from "@thrivelot/hooks";
import { getObjArrFromTags, getUnattachedLineItemsAndPlants } from "@thrivelot/common";
import { TagIdType } from "@thrivelot/aws";
import { UnassignedPlantItem } from "./UnassignedPlantItem";
import { UnassignedLineItem } from "./UnassignedLineItem";

const RowListFeatureUnassignedItems = ({ projectId, featureId }) => {
  const { model: project } = useModel({ modelName: "Project", id: projectId });

  const lineItems = useMemo(() => project?.lineItems || [], [project?.lineItems]);
  const plantItems = useMemo(() => project?.plantItems || [], [project?.plantItems]);
  const features = useMemo(() => project?.features || [], [project]);

  const { unassignedLineItems, unassignedPlantItems } = useMemo(() => {
    const list = getUnattachedLineItemsAndPlants(features, lineItems, plantItems);
    if (list.length === 0) return { unassignedLineItems: [], unassignedPlantItems: [] };

    const lineItemTags = list.filter(({ type }) => type === TagIdType.LINE_ITEM);
    const plantItemTags = list.filter(({ type }) => type === TagIdType.PLANT);

    const unassignedLineItems = getObjArrFromTags({
      tags: lineItemTags,
      arr: lineItems,
    });

    const unassignedPlantItems = getObjArrFromTags({
      tags: plantItemTags,
      arr: plantItems,
    });

    return { unassignedLineItems, unassignedPlantItems };
  }, [features, lineItems, plantItems]);

  if (unassignedLineItems.length === 0 && unassignedPlantItems.length === 0) return null;

  return (
    <div>
      <div className="mb-2">
        <div className="text-brown-dark font-bold text-lg">Unassigned Items</div>
        <div className="text-brown-dark italic">Move these to a feature to make changes</div>
      </div>
      <div className="divide-y divide-tan-light pb-4">
        {unassignedLineItems.map(({ id }, index) => (
          <UnassignedLineItem key={id} projectId={projectId} lineItemId={id} featureId={featureId} />
        ))}
        {unassignedPlantItems.map(({ id }, index) => (
          <UnassignedPlantItem key={id} projectId={projectId} plantItemId={id} featureId={featureId} />
        ))}
      </div>
    </div>
  );
};

export { RowListFeatureUnassignedItems };
