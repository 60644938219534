import styled from "styled-components";

const DivHeader = styled.div`
  transition: opacity 0.5s ease-out, box-shadow 0.5s ease-out, color 1s ease-in-out, background-color 1s ease-in-out;
  padding-bottom: 8px;
  width: 100%;
  border-bottom: 1px solid ${({ theme }) => theme.divLine};
`;

export default DivHeader;
