const constructAddress = (addressComponents = []) => {
  let streetNum = "";
  let route = "";
  let city = "";
  let county = "";
  let state = "";
  let country = "";
  let zip = "";

  addressComponents.forEach((component) => {
    if (component.types.includes("street_number")) {
      streetNum = `${component.short_name} `;
    } else if (component.types.includes("route")) {
      route = component.short_name;
    } else if (component.types.includes("locality")) {
      city = component.short_name;
    } else if (component.types.includes("administrative_area_level_2")) {
      county = component.short_name;
    } else if (component.types.includes("administrative_area_level_1")) {
      state = component.short_name;
    } else if (component.types.includes("country")) {
      country = component.short_name;
    } else if (component.types.includes("postal_code")) {
      zip = component.short_name;
    }
  });

  const line1 = `${streetNum}${route}`;

  return {
    line1,
    line2: "",
    city,
    county,
    state,
    country,
    zip,
  };
};

export { constructAddress };
