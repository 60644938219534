import { useModel } from '@thrivelot/hooks';
import { LeafLoader } from '@thrivelot/stories';
import { transformCurrencyToString } from '@thrivelot/utils';
import React, { useMemo } from 'react';
import { RowItemKindTotal } from './RowItemKindTotal';
import { useFeatureItemsMap } from './useFeatureItemsMap';
import { useFeaturesMap } from './useFeaturesMap';
import { featurelessFeature } from '../FormBundleFeature/FormBundleFeature';

const ViewItemFeatureTotals = ({
  invoiceProposalId,
  projectId,
  payment = {},
  descriptionMap = {},
}) => {
  const { model: project, loaded: projectLoaded } = useModel({
    modelName: 'Project',
    id: projectId,
  });

  const { map: featureItemsMap } = useFeatureItemsMap({
    items: payment.items || [],
  });
  const { map: featuresMap } = useFeaturesMap({ project });

  const sortedFeatureIds = useMemo(() => {
    const features = Object.keys(featureItemsMap)
      .filter((featureId) => featureId !== featurelessFeature.id)
      .sort((a, b) => {
        const featureA = featuresMap[a];
        const featureB = featuresMap[b];

        if (featureA?.name === featureB?.name) return 0;
        return featureA?.name < featureB?.name ? -1 : 1;
      });

    if (featureItemsMap?.[featurelessFeature.id])
      features.unshift(featurelessFeature.id);

    return features;
  }, [featuresMap, featureItemsMap]);

  if (!projectLoaded) return <LeafLoader />;

  return (
    <div className="flex flex-col gap-10">
      {sortedFeatureIds.map(
        (featureId) =>
          featuresMap[featureId] &&
          Math.abs(featureItemsMap[featureId].itemsTotalAmount) > 0 && (
            <div key={featureId}>
              <div className="font-bold text-xl mb-2 text-center">
                {featuresMap[featureId].name}
              </div>
              {descriptionMap[featureId] && (
                <div className="pb-6 pt-4 whitespace-pre-line">
                  {descriptionMap[featureId]}
                </div>
              )}
              <div className="divide-y divide-tan-light">
                {Math.abs(
                  featureItemsMap[featureId]?.materialItemsTotalAmount || 0
                ) > 0 && (
                  <RowItemKindTotal
                    iconSize={20}
                    classAppend="!text-base"
                    kind="material"
                    total={
                      featureItemsMap[featureId]?.materialItemsTotalAmount || 0
                    }
                  />
                )}

                {Math.abs(
                  featureItemsMap[featureId]?.equipmentItemsTotalAmount || 0
                ) >
                  0 >
                  0 && (
                  <RowItemKindTotal
                    iconSize={20}
                    classAppend="!text-base"
                    kind="equipment"
                    total={
                      featureItemsMap[featureId]?.equipmentItemsTotalAmount || 0
                    }
                  />
                )}

                {Math.abs(
                  featureItemsMap[featureId]?.laborItemsTotalAmount || 0
                ) > 0 && (
                  <RowItemKindTotal
                    iconSize={20}
                    classAppend="!text-base"
                    kind="labor"
                    amountDescription={
                      featureItemsMap[featureId].laborIsHalf && '50%'
                    }
                    total={
                      featureItemsMap[featureId]?.laborItemsTotalAmount || 0
                    }
                  />
                )}

                {Math.abs(
                  featureItemsMap[featureId]?.plantItemsTotalAmount || 0
                ) > 0 && (
                  <RowItemKindTotal
                    iconSize={20}
                    classAppend="!text-base"
                    kind="plant"
                    total={
                      featureItemsMap[featureId]?.plantItemsTotalAmount || 0
                    }
                  />
                )}

                <div className="flex py-3 text-green-dark items-center text-lg">
                  <div className="flex-1 font-bold ml-2">
                    {featuresMap[featureId].name} Subtotal
                  </div>
                  <div className="font-bold">
                    {transformCurrencyToString(
                      featureItemsMap[featureId].itemsTotalAmount || 0
                    )}
                  </div>
                </div>
              </div>
            </div>
          )
      )}
    </div>
  );
};

export { ViewItemFeatureTotals };
