import styled from "styled-components";

const TitleBarContainer = styled.div`
  display: flex;
  border-bottom: 1px solid ${({ theme }) => theme.divLine};
  @media (max-width: 767px) {
    padding: 12px;
  }
  @media (min-width: 768px) {
    padding: 20px;
  }
`;

export { TitleBarContainer };
