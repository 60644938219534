import { useCallback } from "react";
import { useQueryParam } from "..";

const useModal = (props) => {
  if (props?.modelName || props?.id) throw new Error("Looks like you're using the wrong hook. Try 'useModel'.");

  const { setParam } = useQueryParam("modal");
  const openModal = useCallback(
    ({ path, ...rest }) => {
      if (!path) throw new Error("The 'path' param required when opening modal");
      setParam({ path, ...rest });
    },
    [setParam]
  );

  return { openModal };
};

export { useModal };
