import React from 'react';
import { useModel } from '@thrivelot/hooks';
import { CheckEmpty, CheckFull } from '@thrivelot/stories';
import { DropdownSelectLineItemKind, InputGroupLineItemCells } from '..';
import { LineItemImage } from '../LineItemImage';

const RowItemForm = ({
  modelName,
  modelId,
  itemPath,
  item,
  selectDisabled,
  selected,
  setSelected,
  disabled,
  renderItemActions,
  disabledMap,
}) => {
  const { actions, updateModel } = useModel({
    modelName,
    id: modelId,
  });

  const { name, description, kind, unit, data, hidden } = item || {};

  return (
    <div className="md:flex py-2 md:py-0">
      <div className="flex items-center mb-2 md:mb-0 md:w-1/2">
        {!selectDisabled && (
          <div className="mr-3">
            {selected ? (
              <CheckFull
                size={20}
                onClick={() => setSelected(false)}
                styleProps="hover:cursor-pointer"
              />
            ) : (
              <CheckEmpty
                size={20}
                onClick={() => setSelected(true)}
                styleProps="hover:cursor-pointer"
              />
            )}
          </div>
        )}
        <div className="mr-2 flex items-center gap-1">
          <DropdownSelectLineItemKind
            disabled={disabled}
            kind={kind}
            onSelect={(value) => {
              if (value === 'plant')
                return updateModel(
                  actions
                    .set(`${itemPath}[id:${item.id}].unit`, 'plants')
                    .set(`${itemPath}[id:${item.id}].kind`, value).result
                );

              if (value !== 'plant' && unit === 'plants')
                return updateModel(
                  actions
                    .set(`${itemPath}[id:${item.id}].unit`, 'units')
                    .set(`${itemPath}[id:${item.id}].kind`, value).result
                );

              return updateModel(
                actions.set(`${itemPath}[id:${item.id}].kind`, value).result
              );
            }}
          />
          <LineItemImage
            item={item}
            classAppend="h-[20px] w-[20px] flex items-center justify-center overflow-hidden rounded-full ml-1 cursor-pointer"
          />
          {/* {data?.imageUrl && (
            <div
              className="h-[20px] w-[20px] flex items-center justify-center overflow-hidden rounded-full ml-1 cursor-pointer"
              onClick={() =>
                openModal({
                  path: 'ModalViewImage',
                  fileUrl: data.imageUrl,
                  fileName: name,
                })
              }
            >
              <img
                src={data.imageUrl}
                alt={`${name} plant`}
                className="h-full"
              />
            </div>
          )} */}
        </div>
        <div
          className={`flex-1 text-ellipsis overflow-hidden whitespace-nowrap ${
            hidden ? 'line-through' : ''
          }`}
        >
          {name}
          {description && (
            <span className="text-sm italic"> - {description}</span>
          )}
        </div>
        {renderItemActions && (
          <div className="mx-3">{renderItemActions(item)}</div>
        )}
      </div>
      <div className="md:w-1/2">
        <InputGroupLineItemCells
          disabled={disabled}
          formState={item}
          disabledMap={disabledMap}
          onChange={(prop, value) =>
            updateModel(
              actions.set(`${itemPath}[id:${item.id}].${prop}`, value).result
            )
          }
        />
      </div>
    </div>
  );
};

export { RowItemForm };
