import React, { useMemo } from 'react';
import {
  transformCurrencyToFloat,
  transformCurrencyToString,
} from '@thrivelot/utils';
import { ImgCircle } from '@thrivelot/styles';
import { Confirm, DropdownMoveFeatureItem } from '..';
import { useModal, useModel } from '@thrivelot/hooks';
import { Button } from '@thrivelot/stories';

const UnassignedPlantItem = ({ projectId, plantItemId, featureId }) => {
  return null;
  // const { openModal } = useModal();
  // const { updateModel, actions } = useModel({ modelName: "Project", id: projectId });
  // const { plant, loadedPlantFromDatabase, loadedProject } = useModelPlant({ plantId: plantItemId, projectId });
  // const plantItem = useMemo(() => actions.get(`plantItems[id:${plantItemId}]`), [plantItemId, actions]);
  // const deletePlantItem = () => updateModel(actions.remove(`plantItems[id:${plantItemId}]`).result);

  // if (!loadedPlantFromDatabase || !loadedProject) return null;
  // if (!plantItem) return null;

  // const { quantity, cost } = plantItem;
  // const { imageUrl, commonName, scientificName } = plant || {};
  // const numberCost = Math.ceil((quantity * cost) / 100) * 100;
  // const totalCost = transformCurrencyToString(numberCost);

  // return (
  //   <div className="p-3 flex" data-plant-item-id={plantItemId}>
  //     <div className="flex items-center flex-1 min-w-0">
  //       <div className="mr-1">
  //         <DropdownMoveFeatureItem
  //           projectId={projectId}
  //           currentFeatureId={featureId}
  //           itemId={plantItemId}
  //           itemBasePath="plantItems"
  //         />
  //       </div>
  //       <div className="ml-2 mr-3">
  //         <ImgCircle
  //           src={{ url: imageUrl || "" }}
  //           className="bg-green-main hover:cursor-pointer"
  //           size={25}
  //           onClick={() => openModal({ path: "ModalEditFeaturePlantDetails", projectId, plantId: plantItemId })}
  //         />
  //       </div>
  //       <div className={`${!commonName && "italic"} break-words text-bold text-brown-dark flex-1 min-w-0 mr-2`}>
  //         <div className="text-ellipsis overflow-hidden whitespace-nowrap">{commonName || scientificName}</div>
  //       </div>
  //     </div>

  //     <div className="flex items-center">
  //       <div className="ml-4 relative text-brown-dark font-bold">$ {transformCurrencyToFloat(cost).toString()}</div>

  //       <div className="ml-4 text-brown-dark font-bold"> x {quantity}</div>

  //       <div className="ml-4 text-right text-green-main font-bold text-lg ">{totalCost}</div>

  //       <div className="ml-5 mr-2">
  //         <Confirm prompt="Are you sure you want to delete this plant item?" onConfirm={deletePlantItem}>
  //           <Button icon="Trash" kind="prompt" size="small" color="red-main" label="" />
  //         </Confirm>
  //       </div>
  //     </div>
  //   </div>
  // );
};

export { UnassignedPlantItem };
