import React from 'react';

const FileUpload = ({
  color = 'green-main',
  size = 30,
  styleProps = '',
  ...rest
}) => (
  <svg
    width={size.toString()}
    height={size.toString()}
    viewBox="0 0 75 75"
    className={`fill-${color} ${styleProps}`}
    {...rest}
  >
    <path d="M64.3462 23.0112C62.7013 23.0112 61.3633 24.3408 61.3633 25.9942V35.1987C58.9173 34.3379 56.4031 33.8776 53.8463 33.8265C43.2355 33.622 35.9316 40.4742 33.1191 43.6617C30.5453 42.1361 24.8095 39.4259 14.2584 40.1077C11.0113 40.3123 8.18177 40.8662 5.96587 41.7015V19.5936C5.96587 16.3124 8.64199 13.6363 11.9232 13.6363H49.0054C50.6588 13.6363 51.9883 12.2982 51.9883 10.6533C51.9883 8.99995 50.6588 7.67041 49.0054 7.67041H11.9232C5.35224 7.67041 0 13.0227 0 19.5936V63.0763C0 69.6473 5.35224 74.9995 11.9232 74.9995H55.4059C61.9854 74.9995 67.3291 69.6473 67.3291 63.0763V25.9942C67.3291 24.3408 65.9996 23.0112 64.3462 23.0112ZM11.9232 69.0337C8.64199 69.0337 5.96587 66.3576 5.96587 63.0763V44.4628C7.77268 43.6361 10.5596 42.9032 14.4204 42.656C25.4743 41.9316 30.7413 45.1531 32.5737 46.3207C32.6333 46.3719 32.693 46.4145 32.7612 46.4486C42.3151 52.6275 44.9997 63.4598 45.7582 69.0337H11.9232ZM61.3633 63.0763C61.3633 66.3576 58.6957 69.0337 55.4059 69.0337H48.3321C47.6077 63.3661 44.9571 52.0309 35.2924 45.0764C37.7981 42.3407 44.3094 36.3833 53.386 36.3833H53.7951C56.369 36.4344 58.9087 36.9543 61.3633 37.9259V63.0763V63.0763Z" />
    <path d="M19.4572 32.6504C15.9629 32.6504 13.1249 29.8124 13.1249 26.3181C13.1249 22.8238 15.9629 19.9857 19.4572 19.9857C22.9515 19.9857 25.7896 22.8238 25.7896 26.3181C25.7896 29.8124 22.9515 32.6504 19.4572 32.6504ZM19.4572 24.2471C18.3152 24.2471 17.3862 25.176 17.3862 26.3181C17.3862 27.4601 18.3152 28.3891 19.4572 28.3891C20.5993 28.3891 21.5282 27.4601 21.5282 26.3181C21.5282 25.176 20.5993 24.2471 19.4572 24.2471Z" />
    <path d="M75 10.6533C75 12.2982 73.662 13.6363 72.0171 13.6363H67.3296V18.3238C67.3296 19.9686 65.9916 21.3067 64.3467 21.3067C62.7018 21.3067 61.3638 19.9686 61.3638 18.3238V13.6363H56.6763C55.0314 13.6363 53.6934 12.2982 53.6934 10.6533C53.6934 8.99995 55.0314 7.67041 56.6763 7.67041H61.3638V2.98294C61.3638 1.32954 62.7018 0 64.3467 0C65.9916 0 67.3296 1.32954 67.3296 2.98294V7.67041H72.0171C73.662 7.67041 75 8.99995 75 10.6533Z" />
  </svg>
);

export { FileUpload };
