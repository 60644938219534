import styled from "styled-components";
import CssDivDefault from "./CssDivDefault";

const DivVerticalScrollContainer = styled.div`
  ${CssDivDefault}
  display: block;
  position: relative;
  overflow-x: hidden;
  overflow-y: scroll;
  padding-right: 12px;
  padding-left: 12px;
  padding-top: 12px;
  max-height: 100%;
  width: 100%;
`;

export default DivVerticalScrollContainer;
