import React from 'react';

const ProgressInstall = ({ size = 30, classAppend = '', ...rest }) => (
  <svg
    width={`${size}px`}
    height={`${size}px`}
    viewBox="50 50 200 200"
    fill="none"
    className={classAppend}
    {...rest}
  >
    <circle cx="150.5" cy="150" r="94.5" stroke="#F5E8D3" strokeWidth="11" />
    <g opacity="0.3">
      <mask id="path-2-inside-1_167_1257" fill="white">
        <path d="M209.279 230.902C192.194 243.314 171.618 250 150.5 250C129.382 250 108.806 243.314 91.7215 230.902L98.1871 222.003C113.393 233.05 131.705 239 150.5 239C169.295 239 187.607 233.05 202.813 222.003L209.279 230.902Z" />
      </mask>
      <path
        d="M209.279 230.902C192.194 243.314 171.618 250 150.5 250C129.382 250 108.806 243.314 91.7215 230.902L98.1871 222.003C113.393 233.05 131.705 239 150.5 239C169.295 239 187.607 233.05 202.813 222.003L209.279 230.902Z"
        stroke="#00A272"
        strokeWidth="22"
        mask="url(#path-2-inside-1_167_1257)"
      />
      <mask id="path-3-inside-2_167_1257" fill="white">
        <path d="M245.606 119.098C252.131 139.183 252.131 160.817 245.606 180.902C239.08 200.986 226.363 218.489 209.279 230.902L202.813 222.003C218.018 210.955 229.336 195.378 235.144 177.503C240.952 159.627 240.952 140.373 235.144 122.497L245.606 119.098Z" />
      </mask>
      <path
        d="M245.606 119.098C252.131 139.183 252.131 160.817 245.606 180.902C239.08 200.986 226.363 218.489 209.279 230.902L202.813 222.003C218.018 210.955 229.336 195.378 235.144 177.503C240.952 159.627 240.952 140.373 235.144 122.497L245.606 119.098Z"
        stroke="#00A272"
        strokeWidth="22"
        mask="url(#path-3-inside-2_167_1257)"
      />
      <mask id="path-4-inside-3_167_1257" fill="white">
        <path d="M150.5 50C171.618 50 192.194 56.6855 209.279 69.0983C226.363 81.5111 239.08 99.014 245.606 119.098L235.144 122.497C229.336 104.622 218.018 89.0449 202.813 77.9975C187.607 66.9501 169.295 61 150.5 61V50Z" />
      </mask>
      <path
        d="M150.5 50C171.618 50 192.194 56.6855 209.279 69.0983C226.363 81.5111 239.08 99.014 245.606 119.098L235.144 122.497C229.336 104.622 218.018 89.0449 202.813 77.9975C187.607 66.9501 169.295 61 150.5 61V50Z"
        stroke="#00A272"
        strokeWidth="22"
        mask="url(#path-4-inside-3_167_1257)"
      />
    </g>
    <mask id="path-5-inside-4_167_1257" fill="white">
      <path d="M93.1424 231.915C75.8436 219.802 62.8235 202.524 55.9481 182.557C49.0728 162.589 48.6952 140.958 54.8695 120.763L65.3889 123.979C59.8938 141.953 60.2298 161.205 66.3488 178.976C72.4679 196.747 84.0558 212.124 99.4517 222.905L93.1424 231.915Z" />
    </mask>
    <path
      d="M93.1424 231.915C75.8436 219.802 62.8235 202.524 55.9481 182.557C49.0728 162.589 48.6952 140.958 54.8695 120.763L65.3889 123.979C59.8938 141.953 60.2298 161.205 66.3488 178.976C72.4679 196.747 84.0558 212.124 99.4517 222.905L93.1424 231.915Z"
      stroke="#7C543D"
      strokeWidth="22"
      mask="url(#path-5-inside-4_167_1257)"
    />
    <path
      d="M198.504 112.79L187.701 101.986C185.744 100.03 182.87 99.4761 180.014 100.515C176.724 101.709 174.422 104.653 174.127 108.046L173.539 114.625C173.192 114.867 172.863 115.144 172.552 115.456L143.137 144.87L135.883 139.607C132.541 137.183 128.161 136.508 123.885 137.789C118.985 139.261 114.951 143.017 113.134 147.83L101.707 178.024C99.3004 184.412 100.547 191.112 104.962 195.527C109.377 199.959 116.094 201.205 122.465 198.782L152.676 187.372C157.489 185.555 161.246 181.521 162.718 176.621C163.982 172.328 163.341 167.965 160.9 164.624L155.62 157.37L185.034 127.938C185.346 127.644 185.623 127.315 185.866 126.969L192.479 126.397C195.838 126.086 198.781 123.766 199.993 120.494C201.014 117.62 200.478 114.763 198.504 112.79ZM129.944 170.545C128.663 169.263 133.442 162.356 140.869 154.513C141.267 155.656 141.942 156.712 142.86 157.629C143.778 158.547 144.851 159.222 145.994 159.638C138.151 167.065 131.243 171.843 129.944 170.545ZM181.364 124.268L152.521 153.111L151.897 153.734L151.672 153.959C151.551 154.098 151.395 154.219 151.257 154.323C149.837 155.361 147.829 155.24 146.53 153.959C145.249 152.678 145.128 150.67 146.184 149.25C146.288 149.094 146.409 148.956 146.53 148.817L147.379 147.969L173.21 122.156L173.937 121.411L176.222 119.126C177.642 117.706 179.944 117.706 181.364 119.126C182.784 120.546 182.784 122.848 181.364 124.268Z"
      fill="#7C543D"
    />
  </svg>
);

export { ProgressInstall };
