import styled from "styled-components";

const InfoContainer = styled.div`
  transition: opacity 0.5s ease-out, box-shadow 0.5s ease-out, color 1s ease-in-out, background-color 1s ease-in-out;
  position: absolute;
  right: 1px;
  bottom: 1px;
  left: 1px;
  height: fit-content;
  background-image: linear-gradient(rgba(200, 200, 200, 0.01), rgba(0, 0, 0, 0.8));
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  @media (max-width: 767px) {
    padding: 36px 12px 12px;
  }
  @media (min-width: 768px) {
    padding: 60px 20px 20px;
  }
`;

export { InfoContainer };
