import React from "react";
import { Circles } from "react-loader-spinner";
import ButtonFlat from "./ButtonFlat";
import ButtonInset from "./ButtonInset";
import ButtonRaised from "./ButtonRaised";

const ButtonMap = {
  flat: ButtonFlat,
  inset: ButtonInset,
  raised: ButtonRaised,
};

const ButtonWithSpinner = ({ isProcessing, buttonType, children, style = {}, ...rest }) => {
  const theme = window.$theme;

  const Button = ButtonMap[buttonType] || ButtonRaised;

  return (
    <Button
      {...rest}
      style={{
        position: "relative",
        ...style,
        paddingLeft: isProcessing ? "25px" : undefined,
      }}
    >
      {children}
      {isProcessing && (
        <div
          style={{
            position: "absolute",
            left: "8px",
            pointerEvents: "none",
            top: "50%",
            transform: "translate(0%,-50%)",
          }}
        >
          <Circles
            loading
            color={buttonType !== "flat" && buttonType !== "inset" ? theme.textLight : theme.buttonDefault}
            width={15}
            height={15}
          />
        </div>
      )}
    </Button>
  );
};

export default ButtonWithSpinner;
