import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useService } from "@thrivelot/hooks";

const useProposalProvider = () => {
  const [customer, setCustomer] = useState({});
  const [project, setProject] = useState({});
  const [suppliers, setSuppliers] = useState([]);
  const [type, setType] = useState("SITE_ASSESSMENT");
  const [loading, setLoading] = useState(true);
  const { request } = useService({ method: "get", level: "public", path: "get-customer-proposal", deferLoad: true });

  const location = useLocation();

  useEffect(() => {
    if (!location?.pathname) return;

    let didCancel;

    const query = () => {
      const [, , projectId, urlType] = location.pathname.split("/");

      if (!didCancel) setType(urlType === "site-assessment" ? "SITE_ASSESSMENT" : "DETAILED_PLAN");

      request({ variables: { projectId } })
        .then(({ data }) => {
          const { customer, project, suppliers } = data;
          if (!didCancel) setCustomer(customer);
          if (!didCancel) setProject(project);
          if (!didCancel) setSuppliers(suppliers);
          if (!didCancel) setLoading(false);
        })
        .catch((err) => {
          console.error("Error loading proposal: ", JSON.stringify(err, null, 2));
        });
    };

    query();

    return () => {
      didCancel = true;
    };
  }, [location?.pathname, request]);

  return { customer, project, suppliers, type, loading };
};

export { useProposalProvider };
