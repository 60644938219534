import React from 'react';

const Chat = ({
  color = 'green-main',
  size = 30,
  styleProps = '',
  ...rest
}) => (
  <svg
    width={size.toString()}
    height={size.toString()}
    viewBox="0 0 75 75"
    className={`fill-${color} ${styleProps}`}
    {...rest}
  >
    <path d="M58.8608 21.3608H16.1393C15.3798 21.3608 14.7152 20.6962 14.7152 19.9367C14.7152 19.1773 15.3798 18.5127 16.1393 18.5127H58.8608C59.6203 18.5127 60.2848 19.1773 60.2848 19.9367C60.2848 20.6962 59.6203 21.3608 58.8608 21.3608Z" />
    <path d="M58.8608 30.4748H16.1393C15.3798 30.4748 14.7152 29.8103 14.7152 29.0508C14.7152 28.2913 15.3798 27.6267 16.1393 27.6267H58.8608C59.6203 27.6267 60.2848 28.2913 60.2848 29.0508C60.2848 29.8103 59.6203 30.4748 58.8608 30.4748Z" />
    <path d="M48.038 39.3989H16.1393C15.3798 39.3989 14.7152 38.7343 14.7152 37.9748C14.7152 37.2153 15.3798 36.5508 16.1393 36.5508H48.038C48.7975 36.5508 49.462 37.2153 49.462 37.9748C49.462 38.7343 48.7975 39.3989 48.038 39.3989Z" />
    <path d="M37.5 75C35.4114 75 33.5127 74.0506 32.2785 72.4367C32.2785 72.4367 24.3038 61.7089 21.4557 57.9114H13.2911C5.98101 57.9114 0 51.9304 0 44.6203V13.2911C0 5.98101 5.98101 0 13.2911 0H61.7089C69.019 0 75 5.98101 75 13.2911V44.6203C75 51.9304 69.019 57.9114 61.7089 57.9114H53.5443L42.7215 72.4367C41.4873 74.0506 39.5886 75 37.5 75ZM23.1646 51.2658C24.7785 51.2658 25.6329 52.5 26.2975 53.2595L37.5 68.2595L49.1772 52.5949C49.8418 51.7405 50.7911 51.2658 51.8354 51.2658H61.7089C65.4114 51.2658 68.3544 48.3228 68.3544 44.6203V13.2911C68.3544 9.58861 65.4114 6.64557 61.7089 6.64557H13.2911C9.58861 6.64557 6.64557 9.58861 6.64557 13.2911V44.6203C6.64557 48.3228 9.58861 51.2658 13.2911 51.2658H22.7848C22.9747 51.2658 23.0696 51.2658 23.1646 51.2658Z" />
  </svg>
);

export { Chat };
