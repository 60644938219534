import React from "react";
import dayjs from "dayjs";
import colors from "@thrivelot/tailwind/colors";
import { useTheme, useMobileDifference } from "@thrivelot/hooks";
import {
  DivLineHorizontal,
  TextBody,
  TextHeader,
  PlantIcon,
  MaterialsIcon,
  ShovelIcon,
  InstallationIcon,
  TextBodyBold,
} from "@thrivelot/styles";
import { transformCurrencyToString } from "@thrivelot/utils";
import { ColumnsContainer } from "./ColumnsContainer";
import { PhaseLeftColumn } from "./PhaseLeftColumn";
import { PhaseMiddleColumn } from "./PhaseMiddleColumn";
import { LeftColumnContainer } from "./LeftColumnContainer";
import { MiddleColumnContainer } from "./MiddleColumnContainer";
import { RightColumn } from "./RightColumn";

const PhasePaymentColumns = ({ groupedPayments, Icon }) => {
  const theme = useTheme();
  const iconSize = useMobileDifference({ desktop: 20, mobile: 15 });
  const fontSize = useMobileDifference({ desktop: "21px", mobile: "14px" });
  return groupedPayments.map((payment, index) => (
    <>
      {payment.payments.map((pmnt, pmntIndex) => (
        <>
          <div
            style={{
              marginTop: "30px",
              display: "flex",
              alignItems: "center",
              marginBottom: "12px",
            }}
          >
            <Icon color={colors.yellow.dark} size={iconSize} />
            <TextHeader style={{ color: theme.textTitle, fontSize, marginLeft: "12px" }}>{payment.title}</TextHeader>
          </div>
          <div style={{ display: "flex", flexDirection: "column", marginBottom: "12px" }}>
            <TextBody
              style={{
                color: colors.yellow.dark,
                paddingLeft: `${iconSize + 12}px`,
              }}
            >
              {dayjs(pmnt.date).format("MMM D, YYYY")}
            </TextBody>
            <TextBody
              style={{
                color: colors.yellow.dark,
                paddingLeft: `${iconSize + 12}px`,
              }}
            >
              {pmntIndex !== 0 ? "(50% labor)" : "(100% materials, 50% labor)"}
            </TextBody>
          </div>
          <TextHeader style={{ color: theme.green, paddingLeft: `${iconSize + 12}px` }}>
            {transformCurrencyToString(pmnt.totalCost)}
          </TextHeader>
          <ColumnsContainer
            style={{ marginTop: index !== 0 || pmntIndex !== 0 ? "15px" : "0px", flexDirection: "column" }}
          >
            {pmntIndex === 0 && (
              <>
                <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <PlantIcon color={colors.green.main} size={15} style={{ marginLeft: `${iconSize + 12}px` }} />
                    <TextBody style={{ marginLeft: "12px" }}>Plants</TextBody>
                  </div>
                  <TextBodyBold>{transformCurrencyToString(pmnt.plantsCost)}</TextBodyBold>
                </div>
                <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <MaterialsIcon
                      color={theme.lineItem.material}
                      size={15}
                      style={{ marginLeft: `${iconSize + 12}px` }}
                    />
                    <TextBody style={{ marginLeft: "12px" }}>Materials</TextBody>
                  </div>
                  <TextBodyBold>{transformCurrencyToString(pmnt.materialsCost)}</TextBodyBold>
                </div>
                <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <InstallationIcon
                      color={theme.lineItem.equipment}
                      size={15}
                      style={{ marginLeft: `${iconSize + 12}px` }}
                    />
                    <TextBody style={{ marginLeft: "12px" }}>Equipment</TextBody>
                  </div>
                  <TextBodyBold>{transformCurrencyToString(pmnt.equipmentCost)}</TextBodyBold>
                </div>
              </>
            )}
            <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
              <div style={{ display: "flex", alignItems: "center" }}>
                <ShovelIcon color={theme.lineItem.labor} size={15} style={{ marginLeft: `${iconSize + 12}px` }} />
                <TextBody style={{ marginLeft: "12px" }}>Labor</TextBody>
              </div>
              <TextBodyBold>{transformCurrencyToString(pmnt.laborCost)}</TextBodyBold>
            </div>
          </ColumnsContainer>
        </>
      ))}
      {index !== groupedPayments.length - 1 && (
        <DivLineHorizontal style={{ marginTop: "18px", marginBottom: "18px" }} />
      )}
    </>
  ));
};

export { PhasePaymentColumns };
