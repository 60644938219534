import React from 'react';
import { Button } from '@thrivelot/stories';

const monthMap = {
  January: 'Jan',
  February: 'Feb',
  March: 'Mar',
  April: 'Apr',
  May: 'May',
  June: 'Jun',
  July: 'Jul',
  August: 'Aug',
  September: 'Sep',
  October: 'Oct',
  November: 'Nov',
  December: 'Dec',
};

export const MonthSelect = ({ label, months, onSelect }) => (
  <div className="mb-2">
    <label className="block font-accent text-mulch-dark text-sm ml-2 mb-0.5">
      {label}
    </label>
    <div className="flex flex-wrap">
      {Object.keys(monthMap).map((month) => (
        <div key={month} className="mb-2 mr-2">
          <Button
            label={monthMap[month]}
            size="small"
            color={months.includes(month) ? 'green-main' : 'yellow-dark'}
            onClick={() => onSelect(month)}
          />
        </div>
      ))}
    </div>
  </div>
);
