import { constructSummaryDesignBilling } from './constructSummaryDesignBilling';
import { constructSummarySinglePayment } from './constructSummarySinglePayment';
import { constructSummaryTimeMaterialsBilling } from './constructSummaryTimeMaterialsBilling';
import { constructItems } from './constructItems';

const getBillingSummary = (model, bundles, paymentMethod) => {
  const { housePercent, creditItem } = model || {};

  const lineItems = bundles
    .map((bundle) =>
      constructItems(bundle.lineItems, housePercent, bundle, creditItem, 'line')
    )
    .flat();

  const changeItems = bundles
    .map((bundle) =>
      constructItems(
        bundle.changeItems,
        housePercent,
        bundle,
        creditItem,
        'change'
      )
    )
    .flat();

  const cacheMap = {};

  (model?.payments || []).forEach((payment) => {
    cacheMap[payment.label] = { ...payment };
    if (payment.items && typeof payment.items === 'string')
      cacheMap[payment.label].items = JSON.parse(payment.items);
  });

  (model?.payouts || []).forEach((payout) => {
    cacheMap[`${payout.label}:${payout.supplierPayeeId}`] = payout;
  });

  const calculationState = {
    paymentMethod,
    bundles,
    items: [...lineItems, ...changeItems],
    cacheMap,
    creditItem,
    housePercent: housePercent || 0,
  };

  if (model?.kind === 'time_materials_billing')
    return constructSummaryTimeMaterialsBilling(calculationState);
  if (model?.kind === 'design_billing')
    return constructSummaryDesignBilling(calculationState);
  return constructSummarySinglePayment(calculationState);
};

export { getBillingSummary };
