import React, { useState } from "react";
import colors from "@thrivelot/tailwind/colors";
import { AddIcon, InputBubble } from "@thrivelot/styles";

const Input = ({ addProjectType }) => {
  const [type, setType] = useState("");

  const isDisabled = type.trim() === "";

  const handleAddProjectType = () => {
    addProjectType(type);
    setType("");
  };
  const handleOnKeyPress = ({ key }) => {
    if (!isDisabled && key === "Enter") handleAddProjectType();
  };

  return (
    <div
      style={{
        position: "relative",
        marginBottom: "8px",
        marginTop: "8px",
        width: "100%",
      }}
    >
      <InputBubble
        placeholder="Add a new project type"
        value={type}
        onChange={(e) => setType(e.target.value)}
        onKeyPress={handleOnKeyPress}
        style={{ paddingRight: "31px", width: "calc(100% - 36px)" }}
      />
      <AddIcon
        hoverable
        disabled={isDisabled}
        color={colors.green.main}
        size={21}
        style={{
          position: "absolute",
          top: "7px",
          right: "7px",
        }}
        onClick={handleAddProjectType}
      />
    </div>
  );
};

export default Input;
