/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
import { ProjectLineItemType } from "@thrivelot/aws";
import { sortComplex } from "@thrivelot/utils";

const calculateInstallationCost = (projectActions, type) => {
  const cps = projectActions.get("costPaymentSchedule");
  const phasePayments = cps
    .map((p) => {
      if (p.type === type) return p;
    })
    .filter((p) => p);
  if (!phasePayments) return { totalCost: 0, groupedPayments: [] };
  let totalCost = 0;
  const groupedPayments = [];
  phasePayments.forEach((payment) => {
    const phase = projectActions.get(`phases[id:${payment.tagIds[0].tagId}]`);
    const features = projectActions.findItemsByTags("features", phase.orderedChildTagIds || []);

    let plantsCost = 0;
    let materialsCost = 0;
    let laborCost = 0;
    let equipmentCost = 0;

    features.forEach((feature) => {
      const plants = projectActions.findItemsByTags("plantItems", feature.orderedChildTagIds || []);
      plants.forEach((plant) => {
        plantsCost += plant.quantity * plant.cost;
      });
      const lineItems = projectActions.findItemsByTags("lineItems", feature.orderedChildTagIds || []);
      lineItems.forEach((lineItem) => {
        if (lineItem.type === ProjectLineItemType.MATERIAL) materialsCost += lineItem.cost * lineItem.quantity;
        if (lineItem.type === ProjectLineItemType.LABOR) laborCost += lineItem.cost * lineItem.quantity;
        if (lineItem.type === ProjectLineItemType.EQUIPMENT) equipmentCost += lineItem.cost * lineItem.quantity;
      });
    });

    let firstPaymentCost = plantsCost + materialsCost + equipmentCost;
    firstPaymentCost += laborCost / 2;
    const firstPaymentMultiplier = payment.payments[0].markup + 1;
    const firstPaymentTotalCost = firstPaymentCost * firstPaymentMultiplier;
    const firstPaymentMarkup = firstPaymentTotalCost - firstPaymentCost;

    const secondPaymentCost = laborCost / 2;
    const secondPaymentMultiplier = payment.payments[1].markup + 1;
    const secondPaymentTotalCost = secondPaymentCost * secondPaymentMultiplier;
    const secondPaymentMarkup = secondPaymentTotalCost - secondPaymentCost;

    totalCost += firstPaymentTotalCost + secondPaymentTotalCost;

    groupedPayments.push({
      title: phase.name,
      cost: firstPaymentCost + secondPaymentCost,
      totalCost: firstPaymentTotalCost + secondPaymentTotalCost,
      payments: [
        {
          date: payment.payments[0].date,
          plantsCost: plantsCost * firstPaymentMultiplier,
          materialsCost: materialsCost * firstPaymentMultiplier,
          laborCost: (laborCost / 2) * firstPaymentMultiplier,
          equipmentCost: equipmentCost * firstPaymentMultiplier,
          markup: firstPaymentMarkup,
          cost: firstPaymentCost,
          totalCost: firstPaymentTotalCost,
        },
        {
          date: payment.payments[1].date,
          plantsCost: 0,
          materialsCost: 0,
          laborCost: (laborCost / 2) * secondPaymentMultiplier,
          equipmentCost: 0,
          markup: secondPaymentMarkup,
          cost: secondPaymentCost,
          totalCost: secondPaymentTotalCost,
        },
      ],
    });
  });

  return {
    totalCost,
    groupedPayments: sortComplex({
      arr: groupedPayments,
      nestedBy: ["payments", "date"],
      nestedByIndex: 0,
    }),
  };
};

export { calculateInstallationCost };
