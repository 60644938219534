/* eslint-disable no-nested-ternary */
import React, { useCallback, useEffect, useState } from 'react';
import { useConstructTimestamp, useModal, useService } from '@thrivelot/hooks';
import { Button, LeafLoader } from '@thrivelot/stories';
import { LayoutModal, PaymentElement } from '../..';

const InvoicePayment = ({ invoiceProposalId, paymentLabel, onClose }) => {
  const { request: handleWireTransfer, loading } = useService({
    method: 'post',
    level: 'protected',
    path: 'handle-wire-transfer',
    deferLoad: true,
  });

  const onSubmit = useCallback(async () => {
    const { data } = await handleWireTransfer({
      variables: { invoiceProposalId, paymentLabel },
    });
    onClose();
  });

  return (
    <div>
      <div className="mb-8">
        Click below to generate an invoice which will allow you to pay by bank
        transfer. You will receive an email but you will also be able to access
        the invoice here on the order page.
      </div>
      <Button
        disabled={loading}
        loading={loading}
        label="Generate Invoice"
        block
        color="green-main"
        onClick={onSubmit}
      />
    </div>
  );
};

const SplitPayment = ({ invoiceProposalId, paymentLabel, onClose }) => {
  const { request: handleWireTransfer, loading } = useService({
    method: 'post',
    level: 'protected',
    path: 'handle-wire-transfer',
    deferLoad: true,
  });

  const onSubmit = useCallback(async () => {
    const { data } = await handleWireTransfer({
      variables: { invoiceProposalId, paymentLabel, requestSplit: true },
    });
    onClose();
  });

  return (
    <div>
      <div className="mb-8">
        Split payments are possible but require a manual process. If you would
        like a split payment, click below and we will contact you to arrange
        payment.
      </div>
      <Button
        disabled={loading}
        loading={loading}
        label="Request split payment"
        block
        color="green-main"
        onClick={onSubmit}
      />
    </div>
  );
};

const ElementPayment = ({
  invoiceProposalId,
  paymentLabel,
  paymentMethod,
  onClose,
}) => {
  const { data: paymentIntent, loaded } = useService({
    method: 'post',
    level: 'public',
    path: 'create-payment-intent',
    variables: { invoiceProposalId, paymentLabel, paymentMethod },
  });
  const { request: completePayment } = useService({
    method: 'post',
    level: 'public',
    path: 'complete-payment',
    deferLoad: true,
  });
  const { construct } = useConstructTimestamp();
  const [processing, setProcessing] = useState(false);
  const { openModal } = useModal();
  const [isOkayToPay, setIsOkayToPay] = useState(false);

  const onConfirmPayment = useCallback(async () => {
    const timestamp = construct({ kind: 'invoiceProposalPaymentSent' });

    const { data: _paymentIntent } = await completePayment({
      variables: { invoiceProposalId, paymentLabel, timestamp, paymentMethod },
    });

    setProcessing(false);

    if (_paymentIntent?.next_action?.type === 'verify_with_microdeposits')
      openModal({ path: 'ModalInvoiceProposalVerifyBankAccount' });
    else onClose();
  }, [completePayment, invoiceProposalId, paymentLabel, onClose, openModal]);

  useEffect(() => {
    if (paymentIntent?.next_action?.type === 'verify_with_microdeposits')
      openModal({
        path: 'ModalInvoiceProposalVerifyBankAccount',
        paymentIntent,
      });
    else if (paymentIntent?.client_secret) setIsOkayToPay(true);
  }, [paymentIntent, openModal]);

  if (!loaded || !isOkayToPay) return <LeafLoader />;

  return (
    <PaymentElement
      paymentIntent={paymentIntent}
      onConfirmPayment={onConfirmPayment}
      processing={processing}
      setProcessing={setProcessing}
    />
  );
};

export const ModalInvoiceProposalPayment = ({
  onClose,
  projectId,
  invoiceProposalId,
  paymentLabel,
  paymentMethod = 'invoice',
}) => (
  <LayoutModal
    onClose={onClose}
    header={
      <div className="text-lg text-brown-dark italic">
        {paymentMethod === 'card' && 'Pay with a Credit/Debit Card'}
        {paymentMethod === 'invoice' && 'Pay by Bank Transfer'}
      </div>
    }
    dialogCloseDisabled
  >
    {paymentMethod === 'invoice' ? (
      <InvoicePayment
        invoiceProposalId={invoiceProposalId}
        paymentLabel={paymentLabel}
        onClose={onClose}
      />
    ) : paymentMethod === 'split' ? (
      <SplitPayment
        invoiceProposalId={invoiceProposalId}
        paymentLabel={paymentLabel}
        onClose={onClose}
      />
    ) : (
      <ElementPayment
        invoiceProposalId={invoiceProposalId}
        paymentLabel={paymentLabel}
        paymentMethod={paymentMethod}
        onClose={onClose}
      />
    )}
  </LayoutModal>
);
