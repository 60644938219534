import React from "react";
// import styled from "styled-components";

const DivAppContainer = ({ children, className, ...rest }) => {
  return (
    <div className={`container mx-auto ${className}`} {...rest}>
      {children}
    </div>
  );
};

// const DivAppContainer = styled.div`
//   transition: opacity 0.5s ease-out, box-shadow 0.5s ease-out, color 1s ease-in-out, background-color 1s ease-in-out;
//   overflow: hidden;
//   position: absolute;
//   top: 0px;
//   right: 0px;
//   bottom: 0px;
//   left: 0px;
// `;

export default DivAppContainer;
