import { Equipment, Labor, Soil } from '@thrivelot/stories';
import { ProjectLineItemType } from '@thrivelot/aws';

const iconMap = {
  [ProjectLineItemType.EQUIPMENT]: Equipment,
  [ProjectLineItemType.LABOR]: Labor,
  [ProjectLineItemType.MATERIAL]: Soil,
};

const colorMap = {
  [ProjectLineItemType.MATERIAL]: 'brown-dark',
  [ProjectLineItemType.LABOR]: 'blue-main',
  [ProjectLineItemType.EQUIPMENT]: 'orange-main',
};

export { iconMap, colorMap };
