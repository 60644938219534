import styled from "styled-components";

const PlantPaletteContainer = styled.div`
  transition: opacity 0.5s ease-out, box-shadow 0.5s ease-out, color 1s ease-in-out, background-color 1s ease-in-out;
  display: flex;
  flex-wrap: wrap;
  padding: 8px 0px 0px;
`;

export { PlantPaletteContainer };
