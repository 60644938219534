import React from "react";
import { dateFns } from "@thrivelot/date";
import { useModal, useAuth, useModel } from "@thrivelot/hooks";
import { isCustomerApp } from "@thrivelot/utils";
import { Button, Card } from "@thrivelot/stories";
import { Checkmark, Info } from "@thrivelot/stories";
import { CardLoader } from "@thrivelot/stories";

const termsUrl = () =>
  isCustomerApp()
    ? "https://thrivelot.notion.site/Terms-Conditions-for-Customer-d29485acd283404b900c513d533784f2"
    : "https://thrivelot.notion.site/Terms-Conditions-Service-Provider-569ec9c1306e41f9a9cf2134be100019";

const getModelName = () => (isCustomerApp() ? "Customer" : "Supplier");

const CardTermsOfUse = () => {
  const { userId } = useAuth();
  const { model: user, loaded } = useModel({ modelName: getModelName(), id: userId });
  const { openModal } = useModal();

  if (!loaded) return <CardLoader />;

  return (
    <Card
      header="Terms of Use"
      footer={
        <Button
          color="blue-main"
          block
          kind="outline"
          onClick={() => openModal({ path: "ModalReviewTerms", termsUrl: termsUrl() })}
        >
          Review
        </Button>
      }
    >
      {user.termsOfUse && user.termsOfUse.agreedAt ? (
        <div className="flex justify-center items-center w-full h-full px-2 py-5">
          <Checkmark size={20} color="green-light" />
          <div className="text-brown-dark font-bold text-sm ml-3">
            Agreed on {dateFns(user.termsOfUse.agreedAt).format("M/D/YYYY")}
          </div>
        </div>
      ) : (
        <div className="flex justify-center items-center w-full h-full px-2 py-5">
          <Info size={20} color="red-main" />
          <div className="text-brown-dark font-bold text-sm ml-3">Needs Review</div>
        </div>
      )}
    </Card>
  );
};

export { CardTermsOfUse };
