import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Document, Page, pdfjs } from "react-pdf";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import { useFetchFileUrl } from "@thrivelot/common";
import { CssDivDefault } from "@thrivelot/styles";
import "./Pdf.css";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const pdfClassNames = {
  gradientDocument: "gradient-document",
  mobileHubDocument: "mobile-hub-document",
  hubDocument: "hub-document",
  largeDocument: "large-document",
  smallDocument: "small-document",
  deleteDocument: "delete-document",
  dpDocument: "dp-document",
  proposalDocument: "proposal-document",
  portfolioDocument: "portfolio-document",
  fileDocument: "file-document",
};

const getSizeProps = (className) => {
  const sizeProps = {};

  if (className === pdfClassNames.hubDocument) {
    sizeProps.height = 320;
    sizeProps.width = 320;
  } else if (className === pdfClassNames.mobileHubDocument) {
    sizeProps.height = window.innerWidth - 60;
    sizeProps.width = window.innerWidth - 20;
  } else if (className === pdfClassNames.largeDocument) {
    if (window.innerWidth > window.innerHeight) {
      sizeProps.height = window.innerHeight;
    } else {
      sizeProps.width = window.innerWidth;
    }
  } else if (className === pdfClassNames.smallDocument) {
    sizeProps.height = 120;
  } else if (className === pdfClassNames.portfolioDocument) {
    sizeProps.height = 180;
    sizeProps.width = 180;
  } else if (className === pdfClassNames.proposalDocument) {
    if (window.innerWidth < 390) {
      sizeProps.width = window.innerWidth - 10;
    } else {
      sizeProps.height = 400;
    }
  } else if (className === pdfClassNames.fileDocument) {
    sizeProps.height = 60;
    sizeProps.width = 60;
  }

  return sizeProps;
};

const PdfContainerDiv = styled.div`
  ${CssDivDefault}
`;

const Pdf = ({
  className = "large-document",
  src,
  pageNumber = 1,
  setNumPages,
  setPageNumberOptions,
  style = {},
  docStyle = {},
  ...rest
}) => {
  const [fileSrc, setFileSrc] = useState(typeof src === "string" ? src : "");
  const [isLoaded, setIsLoaded] = useState(false);

  const fetchUrl = useFetchFileUrl();

  useEffect(() => {
    let didCancel;

    fetchUrl(src).then((data) => {
      if (!didCancel) setFileSrc(data);
    });

    return () => {
      didCancel = true;
    };
  }, [src, fetchUrl]);

  const handleOnLoadSuccess = ({ numPages }) => {
    setIsLoaded(true);
    if (setNumPages) setNumPages(numPages);
    const options = [];
    for (let i = 1; i <= numPages; i++) {
      options.push({ value: i, label: `${i}` });
    }
    if (setPageNumberOptions) setPageNumberOptions(options);
  };

  const safePageNumber = pageNumber || 1;
  const sizeProps = getSizeProps(className);

  return (
    <PdfContainerDiv
      style={{
        ...style,
        backgroundColor: "rgba(0,0,0,0.5)",
        position: "relative",
      }}
      {...rest}
    >
      <Document className={className} file={fileSrc} onLoadSuccess={handleOnLoadSuccess} loading={<div />}>
        <Page
          className="no-events"
          pageNumber={safePageNumber}
          style={{ ...docStyle, opacity: isLoaded ? 1 : 0 }}
          {...sizeProps}
        />
      </Document>
    </PdfContainerDiv>
  );
};

export { Pdf };
