import React from "react";
import * as icons from "@thrivelot/stories";
import { lineItemIconMap, lineItemColorMap } from "@thrivelot/config";
import { transformCurrencyToString } from "@thrivelot/utils";

const kindLabelMap = {
  labor: "Labor",
  material: "Materials",
  plant: "Plants",
  equipment: "Equipment",
};

const RowItemKindTotal = ({ kind, total, iconSize, amountDescription, classAppend }) => {
  const Icon = icons[lineItemIconMap[kind]];
  const label = kindLabelMap[kind];

  return (
    <div className={`flex py-3 text-${lineItemColorMap[kind]} items-center text-lg ${classAppend}`}>
      <Icon size={iconSize || 26} color={lineItemColorMap[kind]} />
      <div className="flex-1 font-bold ml-2">{label}</div>
      <div>
        {amountDescription && <span className="mr-2 text-sm">({amountDescription})</span>}
        {transformCurrencyToString(total)}
      </div>
    </div>
  );
};

export { RowItemKindTotal };
