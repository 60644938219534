import React from 'react';

const File = ({
  color = 'green-main',
  size = 30,
  styleProps = '',
  ...rest
}) => (
  <svg
    width={size.toString()}
    height={size.toString()}
    viewBox="0 0 75 75"
    className={`fill-${color} ${styleProps}`}
    {...rest}
  >
    <path d="M37.5 75C16.8269 75 0 58.1731 0 37.5C0 16.8269 16.8269 0 37.5 0C58.1731 0 75 16.8269 75 37.5C75 58.1731 58.1731 75 37.5 75ZM37.5 5.76923C20 5.76923 5.76923 20 5.76923 37.5C5.76923 55 20 69.2308 37.5 69.2308C55 69.2308 69.2308 55 69.2308 37.5C69.2308 20 55 5.76923 37.5 5.76923Z" />
    <path d="M33.2019 59.4808C27.0865 59.4808 22.1154 54.5097 22.1154 48.3943V30.4712C22.1154 22.2308 29.0192 15.5193 37.5 15.5193C45.9807 15.5193 52.8846 22.2308 52.8846 30.4712V49.1154C52.8846 50.4424 51.8077 51.5193 50.4807 51.5193C49.1538 51.5193 48.0769 50.4424 48.0769 49.1154V30.4712C48.0769 24.8751 43.3365 20.327 37.5 20.327C31.6634 20.327 26.923 24.8751 26.923 30.4712V48.3943C26.923 51.8558 29.7404 54.6731 33.2019 54.6731C36.6634 54.6731 39.4807 51.8558 39.4807 48.3943V35.2693C39.4807 35.1251 39.4807 34.9808 39.4711 34.8366C39.3654 33.2789 38.1154 33.1443 37.5673 33.1731C37.2404 33.1828 35.6154 33.327 35.6154 35.1154V48.4904C35.6154 49.8174 34.5384 50.8943 33.2115 50.8943C31.8846 50.8943 30.8077 49.8174 30.8077 48.4904V35.1058C30.8077 30.827 34.125 28.4712 37.4038 28.3558C40.625 28.2501 43.9807 30.327 44.2692 34.5001C44.2884 34.7501 44.298 35.0001 44.298 35.2597V48.3943C44.2788 54.5097 39.3077 59.4808 33.2019 59.4808Z" />
  </svg>
);

export { File };
