module.exports = {
  transparent: "transparent",
  current: "currentColor",
  green: {
    light: "#74D1B6",
    main: "#00A172",
    dark: "#006A4B",
  },
  evergreen: {
    light: "#80DE8F",
    main: "#13B053",
    dark: "#057030",
  },
  olive: {
    light: "#87E555",
    main: "#60BC2E",
    dark: "#2E8300",
  },
  red: {
    light: "#F1998C",
    main: "#DF472F",
    dark: "#B51E07",
  },
  yellow: {
    light: "#FBE890",
    main: "#F2C94C",
    dark: "#D2A419",
  },
  blue: {
    light: "#90B3E7",
    main: "#216BD8",
    dark: "#26508E",
  },
  orange: {
    light: "#FFB579",
    main: "#EA7211",
    dark: "#C35903",
  },
  purple: {
    light: "#C8A0E2",
    main: "#761DB4",
    dark: "#511C76",
  },
  pink: {
    light: "#FDA3F0",
    main: "#E93BD0",
    dark: "#AE0E97",
  },
  tan: {
    light: "#F5E8D3",
    main: "#BFAFA3",
    dark: "#B98956",
  },
  mulch: {
    light: "#3C362F",
    dark: "#322D27",
  },
  brown: {
    light: "#C0A592",
    dark: "#7C543D",
  },
  white: {
    light: "#FEFAF2",
    dark: "#FAF5EF",
  },
  zonesMap: {
    _0: "#FDA3F0",
    _1: "#C8A0E2",
    _2: "#74D1B6",
    _3: "#90B3E7",
    _4: "#FFB579",
    _5: "#FBE890",
  },
  zonesIcon: {
    _0: "#E93BD0",
    _1: "#761DB4",
    _2: "#00A172",
    _3: "#216BD8",
    _4: "#EA7211",
    _5: "#D2A419",
  },
  zonesIconString: {
    _0: "pink-main",
    _1: "purple-main",
    _2: "green-main",
    _3: "blue-main",
    _4: "orange-main",
    _5: "yellow-dark",
  },
};
