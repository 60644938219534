import { calculatePhaseInnerTotal } from "./calculatePhaseInnerTotal";

const calculatePhaseInnerMarkup = (payment, calculation, project) => {
  const total = Math.ceil(calculatePhaseInnerTotal(payment, calculation, project) / 100) * 100;
  const subtotal = Math.ceil(calculatePhaseInnerTotal(payment, calculation, project, true) / 100) * 100;

  return total - subtotal;
};

export { calculatePhaseInnerMarkup };
