import { MapProvider, useMap, usePlacesAutocomplete } from '@thrivelot/hooks';
import { LeafLoader } from '@thrivelot/stories';
import React, { useCallback, useState } from 'react';
import { constructAddressObject } from '@thrivelot/common';
import { ResultItem } from './ResultItem';

const InputAddressChild = ({ onSelect, defaultValue, ...props }) => {
  const { loading, loadError } = useMap();
  const [query, setQuery] = useState(defaultValue || '');
  const { placePredictions, getPlacePredictions, isPlacePredictionsLoading } =
    usePlacesAutocomplete({
      isLoaded: !loading && !loadError,
    });

  const handleAddressSearch = useCallback(
    (address) => {
      setQuery(address);
      getPlacePredictions({ input: address });
    },
    [getPlacePredictions]
  );

  const handleSelectAddress = useCallback(
    (placeId) => {
      if (loading || loadError) return;
      const geocoder = new window.google.maps.Geocoder();
      geocoder.geocode({ placeId }, (results, status) => {
        if (status === 'OK') {
          const place = results[0];
          const incompleteAddress = constructAddressObject(
            place.address_components
          );
          const address = {
            ...incompleteAddress,
            formattedAddress: place.formatted_address,
            location: {
              lat: place.geometry.location.lat(),
              lng: place.geometry.location.lng(),
            },
          };
          onSelect(address);
          setQuery(address.formattedAddress);
          getPlacePredictions({ input: undefined });
        } else {
          // TODO: Handle the case where it cant find an address
        }
      });
    },
    [loading, loadError, getPlacePredictions, onSelect]
  );

  return (
    <div>
      <input
        disabled={loading || loadError}
        className="input"
        onChange={(evt) => handleAddressSearch(evt.target.value)}
        value={query}
        {...props}
      />
      {placePredictions.length > 0 && <div className="pt-3" />}
      <div className="divide-y divide-tan-light">
        {placePredictions.map(
          ({
            description,
            matched_substrings: matchedSubstrings,
            place_id: placeId,
          }) => (
            <ResultItem
              key={placeId}
              description={description}
              matchedSubstrings={matchedSubstrings}
              placeId={placeId}
              onSelect={handleSelectAddress}
            />
          )
        )}
      </div>
      {isPlacePredictionsLoading && (
        <div className="flex pt-3">
          <LeafLoader size="sm" />
        </div>
      )}
    </div>
  );
};

const InputAddress = ({ onSelect, defaultValue, ...props }) => (
  <MapProvider>
    <InputAddressChild
      onSelect={onSelect}
      defaultValue={defaultValue}
      {...props}
    />
  </MapProvider>
);

export { InputAddress };
