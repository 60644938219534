import React from "react";
import { Card } from "@thrivelot/stories";
import { CardLoader } from "@thrivelot/stories";
import { transformStringToTitleCase } from "@thrivelot/utils";
import { useModel } from "@thrivelot/hooks";

const socials = ["facebook", "instagram", "pinterest", "twitter", "website", "youtube"];

const CardSupplierSocials = ({ supplierId }) => {
  const { updateModel: updateSupplier, actions, loaded } = useModel({ modelName: "Supplier", id: supplierId });

  if (!loaded) return <CardLoader />;

  return (
    <Card header="Social" classAppend="mb-4">
      <div className="flex flex-col gap-4">
        {socials.map((social) => (
          <div key={social}>
            <div className="text-green-dark text-sm font-bold">{transformStringToTitleCase(social)}</div>
            <input
              className="input"
              placeholder={transformStringToTitleCase(social)}
              value={actions.get(`links.${social}`) || ""}
              onChange={(e) => updateSupplier(actions.set(`links.${social}`, e.target.value).result)}
            />
          </div>
        ))}
      </div>
    </Card>
  );
};

export { CardSupplierSocials };
