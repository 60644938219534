import React from "react";
import { useNavOptions } from "@thrivelot/hooks";
import { Dropdown } from "@thrivelot/stories";
import * as icons from "@thrivelot/stories";

const DropdownLinks = ({ options, pathMatch, color = "green-main", classAppend = "", ...rest }) => {
  pathMatch = pathMatch || (() => {});
  const [currentOption, navOptions] = useNavOptions({
    options,
    pathMatch,
  });

  const CurrentOptionIcon = currentOption.icon ? icons[currentOption.icon] : null;
  const AngleDown = icons["AngleDown"]; // No need to import this again;

  return (
    <Dropdown
      align="right"
      label={
        <div
          className={`text-${currentOption.color || color} flex items-center border border-${
            currentOption.color || color
          } px-3 py-1 rounded-main ${classAppend}`}
        >
          <CurrentOptionIcon color={currentOption.color || color} size={16} />
          <div className={`ml-1 mr-1 font-bold text-${currentOption.color || color}`}>{currentOption.label}</div>
          <AngleDown color={currentOption.color || color} size={16} styleProps="pt-1" />
        </div>
      }
      options={navOptions}
      {...rest}
    />
  );
};

export { DropdownLinks };
