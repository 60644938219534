import React from 'react';

const Leaf = ({
  color = 'green-main',
  size = 30,
  styleProps = '',
  ...rest
}) => (
  <svg
    width={size.toString()}
    height={size.toString()}
    viewBox="0 0 75 75"
    className={`fill-${color} ${styleProps}`}
    {...rest}
  >
    <path d="M54.4815 22.4613C54.453 22.4138 54.4245 22.3758 54.396 22.3283C47.0235 11.7921 19.2245 -3.60851 7.78575 0.761795C4.2705 2.11089 2.33236 5.1226 2.33236 9.13188C2.33236 9.65442 2.37037 10.196 2.43687 10.7565C3.49144 19.3736 2.30386 25.9671 1.25879 31.7815C-0.166313 39.705 -1.51541 47.1821 4.00448 55.8942C4.03298 55.9417 4.06148 55.9797 4.08999 56.0272C12.4316 67.9505 26.9486 75 42.0261 75C45.0094 75 48.0401 74.867 50.9948 74.1544C51.9733 73.9169 52.6479 73.4704 53.1419 72.7198C63.2696 57.3953 63.8207 37.1873 54.4815 22.4613ZM50.5387 63.8082C50.3012 64.3118 49.5602 64.2927 49.3512 63.7702C46.0449 55.6851 40.4775 44.6834 36.4018 38.0044C32.8865 32.228 28.7252 26.8031 24.0414 21.8818C23.4998 21.3117 22.5973 21.2927 22.0272 21.8343C21.4572 22.3758 21.4382 23.2784 21.9797 23.8484C26.5211 28.6272 30.5588 33.8906 33.9696 39.496C38.6154 47.125 44.2018 58.4688 47.1945 66.0504C47.5745 67.0099 46.9095 68.0645 45.8834 68.1595C31.6229 69.5086 17.4004 63.3997 9.58137 52.2744C5.49608 45.776 6.40815 40.6741 7.80475 32.9596C8.87832 26.9931 10.2179 19.5636 9.03983 9.94894C8.73581 7.51677 9.59087 7.19375 10.1609 6.98473C17.4384 4.20104 41.9976 16.3144 48.9046 26.0811C55.8021 37.0163 56.2962 51.5429 50.5387 63.8082Z" />
  </svg>
);

export { Leaf };
