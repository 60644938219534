const handleReorderingFeaturesInWorkPhases = (list, startIndex, endIndex) => {
  if (!list) return undefined;

  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

export { handleReorderingFeaturesInWorkPhases };
