import { useModal, useModel } from '@thrivelot/hooks';
import { Dropdown, AngleDown } from '@thrivelot/stories';
import React, { useMemo, useCallback, useState } from 'react';

export const DropdownFolderActions = ({ projectId, folderId, setEditing }) => {
  const { openModal } = useModal();
  const {
    model: project,
    update,
    actions,
  } = useModel({
    modelName: 'Project',
    id: projectId,
  });
  const [deleting, setDeleting] = useState(false);

  const handleDeleteItem = useCallback(() => {
    if (!project?.fileFolders) return;

    const newFolders = project.fileFolders.filter(
      (folder) => folder.id !== folderId
    );

    update({ fileFolders: newFolders });
  }, [folderId, project?.fileFolders, update, actions]);

  const handleDeleteItemAndFiles = useCallback(() => {
    if (!project?.fileFolders) return;

    const folder = project.fileFolders.find(
      (_folder) => _folder.id === folderId
    );
    const newFolders = project.fileFolders.filter(
      (_folder) => _folder.id !== folderId
    );
    const newFiles = (project?.files || []).filter(
      (file) => !folder.fileIds.includes(file.id)
    );

    update({ files: newFiles, fileFolders: newFolders });
  }, [folderId, project?.files, project?.fileFolders, update, actions]);

  const options = useMemo(() => {
    let result = [];
    if (deleting)
      result = [
        {
          icon: 'Minus',
          label: 'No, keep folder',
          color: 'blue-main',
          closeOnClick: true,
          onClick: () => setDeleting(false),
        },
        {
          icon: 'Trash',
          label: 'Yes, delete folder but keep files',
          color: 'orange-main',
          closeOnClick: true,
          onClick: () => handleDeleteItem(),
        },
        {
          icon: 'Trash',
          label: 'Yes, delete folder and files',
          color: 'red-main',
          closeOnClick: true,
          onClick: () => handleDeleteItemAndFiles(),
        },
      ];
    else
      result = [
        {
          icon: 'Edit',
          label: 'Edit folder details',
          onClick: () => setEditing(true),
          closeOnClick: true,
        },
        {
          icon: 'Plus',
          label: 'Add files to folder',
          onClick: () =>
            openModal({
              path: 'ModalAddFiles',
              modelName: 'Project',
              modelId: projectId,
              filesPath: 'files',
              allowMultiple: true,
              fileFolderId: folderId,
            }),
          closeOnClick: true,
        },
        {
          icon: 'Trash',
          label: 'Delete folder',
          color: 'red-main',
          onClick: () => setDeleting(true),
        },
      ];

    return result;
  }, [handleDeleteItem, deleting]);

  return (
    <Dropdown
      label={<AngleDown size={16} />}
      options={options}
      optionClass="font-bold"
      align="right"
      closeOnSelect={false}
      header={deleting ? <div className="italic">Are you sure?</div> : null}
      onClose={() => setDeleting(false)}
    />
  );
};
