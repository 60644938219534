const client = {
  bucket: null,
  region: null,
  fetchCredentials: () => {
    return Promise.resolve(undefined);
  },
  keyPrefix: "public",
  setBucket(bucket) {
    if (bucket) this.bucket = bucket;
  },
  setRegion(region) {
    if (region) this.region = region;
  },
  setFetchCredentials(fetchCredentials) {
    if (fetchCredentials) this.fetchCredentials = fetchCredentials;
  },
  setKeyPrefix(keyPrefix) {
    if (keyPrefix) this.keyPrefix = keyPrefix;
  },
  init({ bucket, region, fetchCredentials, keyPrefix = "public" }) {
    this.setBucket(bucket);
    this.setRegion(region);
    this.setFetchCredentials(fetchCredentials);
    this.setKeyPrefix(keyPrefix);
  },
};

export { client };
