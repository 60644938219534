import React from 'react';

const Save = ({
  color = 'green-main',
  size = 30,
  styleProps = '',
  ...rest
}) => (
  <svg
    width={size.toString()}
    height={size.toString()}
    viewBox="0 0 75 75"
    className={`fill-${color} ${styleProps}`}
    {...rest}
  >
    <path d="M69.1139 14.7152L56.6772 3.41772C54.9684 1.23418 52.3101 0 49.6519 0H13.2911C5.98101 0 0 5.98101 0 13.2911V61.7089C0 69.019 5.98101 75 13.2911 75H61.7089C69.019 75 75 69.019 75 61.7089V28.0063C75 22.9747 72.8165 18.1329 69.1139 14.7152ZM17.5633 6.64557H39.9684V18.038H17.5633V6.64557ZM55.538 68.3544H19.462V45.3797H55.538V68.3544ZM68.3544 61.7089C68.3544 65.2215 65.6013 68.0696 62.1835 68.3544V42.057C62.1835 40.2532 60.6646 38.7342 58.8608 38.7342H16.1392C14.3354 38.7342 12.8165 40.2532 12.8165 42.057V68.3544C9.39873 68.0696 6.64557 65.2215 6.64557 61.7089V13.2911C6.64557 9.58861 9.58861 6.64557 13.2911 6.64557H14.7152V19.462C14.7152 20.2215 15.3797 20.8861 16.1392 20.8861H41.3924C42.1519 20.8861 42.8165 20.2215 42.8165 19.462V6.64557H49.6519C50.4114 6.64557 51.1709 7.02532 51.6456 7.59494C51.7405 7.78481 51.9304 7.97468 52.1203 8.06962L64.7468 19.557C67.1203 21.7405 68.4494 24.7785 68.4494 27.9114V61.7089H68.3544Z" />
    <path d="M35.3166 16.4241C33.9875 16.4241 32.9432 15.3798 32.9432 14.0507V10.6329C32.9432 9.30383 33.9875 8.25952 35.3166 8.25952C36.6457 8.25952 37.69 9.30383 37.69 10.6329V14.0507C37.69 15.3798 36.5508 16.4241 35.3166 16.4241Z" />
    <path d="M49.1769 54.6836H25.7276C24.9681 54.6836 24.3035 54.019 24.3035 53.2595C24.3035 52.5 24.9681 51.8354 25.7276 51.8354H49.1769C49.9364 51.8354 50.601 52.5 50.601 53.2595C50.601 54.019 50.0314 54.6836 49.1769 54.6836Z" />
    <path d="M49.1769 62.2785H25.7276C24.9681 62.2785 24.3035 61.614 24.3035 60.8545C24.3035 60.095 24.9681 59.4304 25.7276 59.4304H49.1769C49.9364 59.4304 50.601 60.095 50.601 60.8545C50.601 61.614 50.0314 62.2785 49.1769 62.2785Z" />
  </svg>
);

export { Save };
