import { ModelKit } from '@thrivelot/model-kit';

const updateCustomerPermissionsFromProjectPermissions = async ({
  customerId,
  owners,
}) => {
  const customerKit = new ModelKit({ modelName: 'Customer', id: customerId });

  const customer = await customerKit.query();

  // add new owners to the customer's viewers and filter for uniqueness
  const newViewers = [...customer.viewers, ...owners].filter(
    (v, i, a) => a.indexOf(v) === i
  );

  return customerKit.update({ updated: { viewers: newViewers } });
};

export { updateCustomerPermissionsFromProjectPermissions };
