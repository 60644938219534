import { useCallback, useEffect, useRef, useState } from "react";
import { dateFns } from "@thrivelot/date";
import { constructUuid, duplicateObject, isEqual } from "@thrivelot/utils";

const DEFAULT_ERROR_MESSAGE = "Oops! Something went wrong.";

const getBodyText = (body) => {
  if (typeof body === "string") return body;
  if (typeof error !== "object") return null;

  if (body.message) return body.message;
  if (body.stack?.message) return body.stack.message;
  if (!body.errors) return DEFAULT_ERROR_MESSAGE;

  let message = "";

  body.errors.forEach((stack, index) => {
    if (index !== 0) message += "\n";
    if (typeof stack === "object" && stack.message) message += stack.message;
    else if (typeof stack === "string") message += stack;
  });

  return message.trim() === "" ? DEFAULT_ERROR_MESSAGE : message;
};

const useNotificationsProvider = () => {
  const [notifications, setNotifications] = useState([]);

  const notificationsRef = useRef();

  notificationsRef.current = notifications;

  const showNotification = ({ type, title, body, exp = 10 }) => {
    const notification = {
      id: constructUuid(),
      type,
      body: getBodyText(body),
      title,
      expiry: dateFns(new Date()).add(exp, "s"),
    };

    const index = notifications.findIndex((notif) => notif.id === notification.id);

    if (index === -1) setNotifications((prevState) => [notification, ...prevState]);
  };

  window.showNotification = showNotification;

  const removeNotification = useCallback((id) => {
    setNotifications((prevState) => {
      const index = prevState.findIndex((notification) => notification.id === id);

      if (index === -1) return prevState;

      const newNotifications = duplicateObject(prevState);
      newNotifications.splice(index, 1);

      return newNotifications;
    });
  }, []);

  useEffect(() => {
    let didCancel;
    const removeNotifications = () => {
      const newNotifications = [];

      notifications.forEach((notification) => {
        if (dateFns(new Date()).isBefore(notification.expiry)) newNotifications.push(notification);
      });

      if (!isEqual(newNotifications, notifications) && !didCancel) setNotifications(newNotifications);
    };

    const interval = setInterval(removeNotifications, 1000);

    return () => {
      didCancel = true;
      clearInterval(interval);
    };
  }, [notifications]);

  return { notifications, showNotification, removeNotification };
};

export { useNotificationsProvider };
