/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createPlant = /* GraphQL */ `
  mutation CreatePlant(
    $input: CreatePlantInput!
    $condition: ModelPlantConditionInput
  ) {
    createPlant(input: $input, condition: $condition) {
      id
      commonName
      familyCommonName
      scientificName
      family
      genus
      species
      otherCommonNames
      synonyms
      edibleParts
      form
      habit
      growingMonths
      bloomMonths
      fruitingMonths
      growthRate
      growingDescription
      averageHeightMm
      maximumHeightMm
      flowerColors
      flowerConspicuous
      foliageColors
      foliageTexture
      foliageConspicuous
      fruitOrSeedColors
      fruitOrSeedConspicuous
      daysToHarvest
      sowingDescription
      rowSpacingMm
      spreadMm
      minimumRootDepthMm
      anaerobicTolerance
      light
      soilHumidity
      soilNutriments
      soilSalinity
      atmosphericHumidity
      phMinimum
      phMaximum
      distributions
      links {
        usda
        tropicos
        telaBotanica
        powo
        plantnet
        gbif
        openfarm
        catminat
        wikipediaEn
        __typename
      }
      images {
        id
        bucket
        region
        key
        url
        __typename
      }
      createdAt
      updatedAt
      updatedMeta {
        id
        userCognitoId
        userId
        userName
        userEmail
        userGroups
        device
        __typename
      }
      __typename
    }
  }
`;
export const updatePlant = /* GraphQL */ `
  mutation UpdatePlant(
    $input: UpdatePlantInput!
    $condition: ModelPlantConditionInput
  ) {
    updatePlant(input: $input, condition: $condition) {
      id
      commonName
      familyCommonName
      scientificName
      family
      genus
      species
      otherCommonNames
      synonyms
      edibleParts
      form
      habit
      growingMonths
      bloomMonths
      fruitingMonths
      growthRate
      growingDescription
      averageHeightMm
      maximumHeightMm
      flowerColors
      flowerConspicuous
      foliageColors
      foliageTexture
      foliageConspicuous
      fruitOrSeedColors
      fruitOrSeedConspicuous
      daysToHarvest
      sowingDescription
      rowSpacingMm
      spreadMm
      minimumRootDepthMm
      anaerobicTolerance
      light
      soilHumidity
      soilNutriments
      soilSalinity
      atmosphericHumidity
      phMinimum
      phMaximum
      distributions
      links {
        usda
        tropicos
        telaBotanica
        powo
        plantnet
        gbif
        openfarm
        catminat
        wikipediaEn
        __typename
      }
      images {
        id
        bucket
        region
        key
        url
        __typename
      }
      createdAt
      updatedAt
      updatedMeta {
        id
        userCognitoId
        userId
        userName
        userEmail
        userGroups
        device
        __typename
      }
      __typename
    }
  }
`;
export const deletePlant = /* GraphQL */ `
  mutation DeletePlant(
    $input: DeletePlantInput!
    $condition: ModelPlantConditionInput
  ) {
    deletePlant(input: $input, condition: $condition) {
      id
      commonName
      familyCommonName
      scientificName
      family
      genus
      species
      otherCommonNames
      synonyms
      edibleParts
      form
      habit
      growingMonths
      bloomMonths
      fruitingMonths
      growthRate
      growingDescription
      averageHeightMm
      maximumHeightMm
      flowerColors
      flowerConspicuous
      foliageColors
      foliageTexture
      foliageConspicuous
      fruitOrSeedColors
      fruitOrSeedConspicuous
      daysToHarvest
      sowingDescription
      rowSpacingMm
      spreadMm
      minimumRootDepthMm
      anaerobicTolerance
      light
      soilHumidity
      soilNutriments
      soilSalinity
      atmosphericHumidity
      phMinimum
      phMaximum
      distributions
      links {
        usda
        tropicos
        telaBotanica
        powo
        plantnet
        gbif
        openfarm
        catminat
        wikipediaEn
        __typename
      }
      images {
        id
        bucket
        region
        key
        url
        __typename
      }
      createdAt
      updatedAt
      updatedMeta {
        id
        userCognitoId
        userId
        userName
        userEmail
        userGroups
        device
        __typename
      }
      __typename
    }
  }
`;
