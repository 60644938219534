import React from "react";
import { useTheme } from "@thrivelot/hooks";
import { TextTitle } from "@thrivelot/styles";
import { NameContainer } from "./NameContainer";

const Name = ({ supplierActions }) => {
  // hooks
  const theme = useTheme();
  // actions
  const firstname = supplierActions.get("firstname");
  const lastname = supplierActions.get("lastname");
  const businessName = supplierActions.get("businessName");
  // utils
  const isValidBusinessName = () => businessName && businessName.trim() !== "";
  // constants
  const name = isValidBusinessName() ? businessName : `${firstname} ${lastname}`;
  const textStyle = { color: theme.textLight };
  // return
  return (
    <NameContainer>
      <TextTitle style={textStyle}>{name}</TextTitle>
    </NameContainer>
  );
};

export { Name };
