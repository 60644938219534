import {
  useCallback,
  useEffect,
  useMemo,
  useReducer,
  useRef,
  useState,
} from 'react';
import { plantClient, PlantKit } from '@thrivelot/plant-kit';
import { handleDebounce, isEmpty } from '@thrivelot/utils';
import { useModelKit } from '../useModel/useModelKit';
import { useWindowFocus } from '../useWindowFocus';
import { subscribe } from './subscribe';
import { IPlantsState, IPlantsContext } from './PlantsContext';

type ActionType =
  | 'SET_MODEL'
  | 'SET_DRAFT'
  | 'SET_LOADING'
  | 'SET_SAVING'
  | 'FINALIZE_DELETE'
  | 'FINALIZE_UPDATE';

interface IAction {
  type: ActionType;
  payload: any;
}

const initialState: IPlantsState = {};

const reducer = (state: IPlantsState, action: IAction) => {
  const { type, payload } = action;
  const loadingState: { [key: string]: any } = {};
  const savingState: { [key: string]: any } = {};
  const updatedState: { [key: string]: any } = {};

  switch (type) {
    case 'SET_MODEL':
      return {
        ...state,
        [payload.id]: {
          ...state[payload.id],
          model: payload,
          loading: false,
          saving: false,
        },
      };
    case 'SET_DRAFT':
      return {
        ...state,
        [payload.id]: { ...state[payload.id], draft: payload },
      };
    case 'SET_LOADING':
      payload.forEach(({ id, loading }) => {
        loadingState[id] = { ...state[id], loading };
      });
      return { ...state, ...loadingState };
    case 'SET_SAVING':
      payload.forEach(({ id, saving }) => {
        savingState[id] = { ...state[id], saving };
      });
      return { ...state, ...savingState };
    case 'FINALIZE_DELETE':
      return {
        ...state,
        [payload.id]: {
          ...state[payload.id],
          model: payload,
          draft: undefined,
          saving: false,
        },
      };
    case 'FINALIZE_UPDATE':
      payload.forEach((model: { [key: string]: any }) => {
        updatedState[model.id] = {
          ...state[model.id],
          model,
          draft: undefined,
          saving: false,
        };
      });
      return { ...state, ...updatedState };
    default:
      return state;
  }
};

export const usePlantsProvider = (): IPlantsContext => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [resetSubs, setResetSubs] = useState(0);

  const { authenticated } = useModelKit();
  const isWindowFocused = useWindowFocus();

  const stateRef = useRef(state);
  stateRef.current = state;

  const init = useCallback(
    (plant: { [key: string]: any }) =>
      dispatch({ type: 'SET_MODEL', payload: plant }),
    []
  );

  const get = useCallback(async (id: string, force?: boolean) => {
    if (stateRef.current[id]?.loading || stateRef.current[id]?.saving) return;
    if (!force && stateRef.current[id]?.model) return;

    if (!force)
      dispatch({ type: 'SET_LOADING', payload: [{ id, loading: true }] });

    try {
      const plantKit = new PlantKit({ id });
      const data = await plantKit.get();
      dispatch({ type: 'SET_MODEL', payload: data });
    } catch (error) {
      console.log('Error in get:', error);
      if (!force)
        dispatch({ type: 'SET_LOADING', payload: [{ id, loading: false }] });
    }
  }, []);

  const remove = useCallback(async (id: string) => {
    if (stateRef.current[id]?.saving) return;

    dispatch({ type: 'SET_SAVING', payload: [{ id, saving: true }] });

    try {
      const plantKit = new PlantKit({ id });
      const data = await plantKit.delete();
      dispatch({ type: 'FINALIZE_DELETE', payload: data });
    } catch (error) {
      console.log('Error in remove:', error);
      dispatch({ type: 'SET_SAVING', payload: [{ id, saving: false }] });
    }
  }, []);

  const update = useCallback((id: string, updated: { [key: string]: any }) => {
    const plant = stateRef.current[id]?.model;
    if (!plant) return;

    const currentDraft = stateRef.current[id]?.draft || {};
    const draft = { ...plant, ...currentDraft, ...updated };

    dispatch({ type: 'SET_DRAFT', payload: draft });
  }, []);

  const save = useCallback(async () => {
    const unsavedModels = Object.values(stateRef.current).filter(
      ({ draft, saving }) => !isEmpty(draft) && !saving
    );

    dispatch({
      type: 'SET_SAVING',
      payload: unsavedModels.map(({ model }) => ({
        id: model.id,
        saving: true,
      })),
    });

    const promises = unsavedModels.map(async ({ model, draft }) => {
      const plantKit = new PlantKit({ id: model.id, updated: draft });
      return plantKit.update();
    });

    let results: any[] = [];
    try {
      results = await Promise.all(promises);
    } catch (error) {
      console.log('Error in save:', error);
    } finally {
      dispatch({ type: 'FINALIZE_UPDATE', payload: results });
    }
  }, []);

  const saveDebounced = useMemo(() => handleDebounce(save, 2000), [save]);

  useEffect(() => {
    const prevSaveDebounced = saveDebounced;
    return () => prevSaveDebounced.cancel();
  }, [saveDebounced]);

  useEffect(() => {
    const unsavedModels = Object.values(state).filter(
      ({ draft, saving }) => !isEmpty(draft) && !saving
    );
    if (!isEmpty(unsavedModels)) saveDebounced();
  }, [saveDebounced, state]);

  useEffect(() => {
    if (isWindowFocused) return;

    let didCancel: boolean;

    const refresh = async () => {
      const ids = Object.keys(state);

      if (!didCancel)
        dispatch({
          type: 'SET_LOADING',
          payload: ids.map((id) => ({ id, loading: true })),
        });

      await Promise.all(ids.map((id) => get(id, true)));

      if (!didCancel)
        dispatch({
          type: 'SET_LOADING',
          payload: ids.map((id) => ({ id, loading: false })),
        });
    };

    refresh();

    // eslint-disable-next-line consistent-return
    return () => {
      didCancel = true;
    };
  }, [isWindowFocused, get]);

  useEffect(() => {
    if (!authenticated) return;

    const callback = ({
      data,
      error,
    }: {
      data?: { [key: string]: any };
      error?: { [key: string]: any };
    }) => {
      if (error) {
        if (error?.error?.errors?.[0]?.message === 'Connection closed') {
          console.log('Resetting subscriptions');
          setResetSubs(Date.now());
          return;
        }

        console.log('Error in subscription:', error);
        return;
      }

      dispatch({ type: 'SET_MODEL', payload: data });
    };

    const subscriptions = Object.values(plantClient.subscriptions).map((gql) =>
      subscribe(gql, callback)
    );

    // eslint-disable-next-line consistent-return
    return () => subscriptions.forEach(({ unsubscribe }) => unsubscribe());
  }, [authenticated, resetSubs]);

  return {
    state,
    init,
    get,
    remove,
    update,
  };
};
