import React from 'react';
import { useModel } from '@thrivelot/hooks';
import { LayoutModal } from '../..';

const ModalEditSiteAnalysisNote = ({ onClose, projectId, siteAnalysisId }) => {
  const { updateModel, loaded, actions } = useModel({
    modelName: 'Project',
    id: projectId,
  });

  const path = `siteAnalysis[id:${siteAnalysisId}]`;

  return (
    <LayoutModal
      onClose={onClose}
      loading={!loaded}
      header={<div className="text-lg text-brown-dark italic">Edit Note</div>}
    >
      {loaded && (
        <div>
          <div className="text-brown-dark mb-1 text-sm">Note Title</div>
          <input
            className="input"
            placeholder="Add a note title..."
            value={actions.get(`${path}.title`) || ''}
            onChange={(e) =>
              updateModel(actions.set(`${path}.title`, e.target.value).result)
            }
          />
        </div>
      )}
    </LayoutModal>
  );
};

export { ModalEditSiteAnalysisNote };
