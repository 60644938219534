const projectPriorities = {
  growingFood: "Growing an abundance of food / replacing groceries",
  superfoods: "Having rare & healthy superfoods that are pesticide free",
  makeYardBeautiful: "Make my yard / property more beautiful",
  feedWildlife: "Feed and create habitat for wildlife and pollinators",
  reduceCarbon: "Reduce my personal supply chain carbon footprint & sequester carbon in my yard",
  share: "Share my abundance with neighbors, friends & family",
  education: "Provide educational activity for children",
  increaseValue: "Increase my property value",
  reduceMowing: "Reduce or remove mowing",
};

export { projectPriorities };
