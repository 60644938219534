export const kindMap = {
  single_billing: {
    icon: 'SingleBilling',
    value: 'single_billing',
    label: 'Single Payment',
    description:
      'Collect a one-time payment from the Customer by composing a one-off invoice for an assembly of line items, billed upfront as 100% of the cost.',
  },
  design_billing: {
    icon: 'Design',
    value: 'design_billing',
    label: 'Design',
    description:
      'Collect a payment from the Customer for the Design Fee that is paid out 50% upfront and 50% upon completion.',
  },
  time_materials_billing: {
    icon: 'TimeMaterialsBilling',
    value: 'time_materials_billing',
    label: 'Time & Materials',
    description:
      'Collect two payments from the Customer for the Installation Fee, split into two distinct payments with flexible billing logic. By default, the first payment includes 100% of material costs and 50% of labor costs, with the second payment including the remaining 50% of labor costs (plus or minus any cost changes in the form of Change Orders).',
  },
};
