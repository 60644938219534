/* eslint-disable no-underscore-dangle */
import { S3 } from 'aws-sdk';
import { Plant, PlantImage } from '@thrivelot/aws';
import { constructBuffer, constructUuid, isEmpty } from '@thrivelot/utils';
import { plantClient } from './PlantClient';
import { request } from './request';
import { constructUnsavedUpdates } from './constructUnsavedUpdates';
import { downloadImageFromUrl } from './downloadImageFromUrl';

type FilterType =
  | {
      [key: string]: { [key: string]: string | number | boolean };
    }
  | {
      [key: string]: {
        [key: string]: { [key: string]: string | number | boolean };
      }[];
    };

type PropType =
  | string
  | number
  | boolean
  | null
  | undefined
  | { [key: string]: any }
  | { [key: string]: any }[];

type UpdatedType = { [key: string]: PropType };

type ActionType = ReturnType<typeof plantClient.actionsFactory>;

interface PlantKitProps {
  id?: string;
  filter?: FilterType;
  updated?: UpdatedType;
  model?: Plant;
}

const constructStorage = async () => {
  if (!plantClient.fetchCredentials)
    return new S3({ region: plantClient.region });
  const credentials = await plantClient.fetchCredentials();
  return new S3({ region: plantClient.region, credentials });
};

export class PlantKit {
  private id?: string;

  private filter?: FilterType;

  private updated?: UpdatedType;

  private _model?: Plant;

  actions?: ActionType;

  set model(model: Plant | undefined) {
    if (model) this._model = model;
    else return;

    if (model.id) this.id = model.id;

    this.setActions();
  }

  get model(): Plant | undefined {
    return this._model;
  }

  constructor(props?: PlantKitProps) {
    this.init(props);
  }

  private init(props?: PlantKitProps) {
    const { id, filter, updated, model } = props || {};

    if (id) this.id = id;
    if (filter) this.filter = filter;
    if (updated) this.updated = updated;
    if (model) this.model = model;
  }

  private setActions(): void {
    if (!this.model) {
      this.actions = undefined;
      return;
    }

    this.actions = plantClient.actionsFactory(this.model);
  }

  async get(props?: PlantKitProps) {
    this.init(props);

    if (!this.id) throw new Error('id is required');

    try {
      const data = await request('get', { id: this.id });
      this.model = data;
      return data;
    } catch (error) {
      console.error('Error in PlantKit get:', JSON.stringify(error, null, 2));
      throw error;
    }
  }

  async list(props?: PlantKitProps) {
    this.init(props);

    try {
      const data = await request('list', { filter: this.filter });
      return data;
    } catch (error) {
      console.error('Error in PlantKit list:', JSON.stringify(error, null, 2));
      throw error;
    }
  }

  async search(props?: PlantKitProps) {
    this.init(props);

    try {
      const data = await request('search', { filter: this.filter });
      return data;
    } catch (error) {
      console.error(
        'Error in PlantKit search:',
        JSON.stringify(error, null, 2)
      );
      throw error;
    }
  }

  async create(props?: PlantKitProps) {
    this.init(props);

    if (!this.model?.id)
      this.model = {
        ...this.model,
        __typename: 'Plant',
        id: this.id || constructUuid(),
      } as Plant;

    try {
      const data = await request('create', { input: this.model });
      this.model = data;
      return data;
    } catch (error) {
      console.error(
        'Error in PlantKit create:',
        JSON.stringify(error, null, 2)
      );
      throw error;
    }
  }

  async update(props?: PlantKitProps) {
    this.init(props);

    if (!this.id) throw new Error('id is required');
    if (!this.updated) throw new Error('updated is required');

    try {
      const model = await this.get();
      const updated = constructUnsavedUpdates(this.updated, model);

      if (isEmpty(updated)) return model;

      const data = await request('update', {
        input: { ...updated, id: this.id },
      });
      this.model = data;
      return data;
    } catch (error) {
      console.error(
        'Error in PlantKit update:',
        JSON.stringify(error, null, 2)
      );
      throw error;
    }
  }

  async delete(props?: PlantKitProps) {
    this.init(props);

    if (!this.id) throw new Error('id is required');

    try {
      const data = await request('delete', { id: this.id, deleted: true });
      this.model = data;
      return data;
    } catch (error) {
      console.error(
        'Error in PlantKit delete:',
        JSON.stringify(error, null, 2)
      );
      throw error;
    }
  }

  private async uploadToStorage(
    base64Image: string,
    metadata?: { [key: string]: any }
  ) {
    if (!this.id) throw new Error('id is required');

    try {
      const Buffer = constructBuffer();
      const body = Buffer.from(base64Image, 'base64');
      const id = constructUuid();
      const key = `public/${this.id}/${id}`;

      const storage = await constructStorage();
      await storage
        .upload({
          Bucket: plantClient.bucket,
          Key: key,
          Body: body,
          ...(metadata && { Metadata: metadata }),
        })
        .promise();

      const image = {
        id,
        bucket: plantClient.bucket,
        region: plantClient.region,
        key,
      } as PlantImage;

      return image;
    } catch (error) {
      console.error(
        'Error in PlantKit uploadToStorage:',
        JSON.stringify(error, null, 2)
      );
      throw error;
    }
  }

  async uploadImage(
    base64Image: string,
    metadata?: { [key: string]: any }
  ): Promise<PlantImage> {
    if (!this.id) throw new Error('id is required');

    try {
      const image = await this.uploadToStorage(base64Image, metadata);

      let { images } = this.model || {};
      if (!images) images = [];
      images.push(image);

      await this.update({ updated: { images } });

      return image;
    } catch (error) {
      console.error(
        'Error in PlantKit uploadImage:',
        JSON.stringify(error, null, 2)
      );
      throw error;
    }
  }

  async uploadMultipleImages(
    uploading: {
      base64Image?: string;
      url?: string;
      metadata?: { [key: string]: any };
    }[]
  ) {
    if (!this.id) throw new Error('id is required');

    try {
      const urlImages = uploading.filter((image) => image.url);
      const base64Images = uploading.filter((image) => image.base64Image);
      const urlPromises = urlImages.map(async (image) => {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const data = await downloadImageFromUrl(image.url!);
        return this.uploadToStorage(data, image.metadata);
      });
      const base64Promises = base64Images.map((image) =>
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        this.uploadToStorage(image.base64Image!, image.metadata)
      );
      const promises = [...urlPromises, ...base64Promises];
      const uploadedImages = await Promise.all(promises);

      let { images } = this.model || {};
      if (!images) images = [];
      images.push(...uploadedImages);

      await this.update({ updated: { images } });

      return uploadedImages;
    } catch (error) {
      console.error(
        'Error in PlantKit uploadMultipleImages:',
        JSON.stringify(error, null, 2)
      );
      throw error;
    }
  }

  async uploadImageFromUrl(url: string, metadata?: { [key: string]: any }) {
    if (!this.id) throw new Error('id is required');

    try {
      const data = await downloadImageFromUrl(url);
      const image = await this.uploadToStorage(data, metadata);

      let { images } = this.model || {};
      if (!images) images = [];
      images.push(image);

      await this.update({ updated: { images } });

      return image;
    } catch (error) {
      console.error(
        'Error in PlantKit uploadImageFromUrl:',
        JSON.stringify(error, null, 2)
      );
      throw error;
    }
  }

  // eslint-disable-next-line class-methods-use-this
  async getImage(key: string) {
    if (!key.startsWith('public/')) key = `public/${key}`;

    try {
      const storage = await constructStorage();
      const data = await storage.getSignedUrlPromise('getObject', {
        Bucket: plantClient.bucket,
        Key: key,
      });
      return { key, url: data };
    } catch (error) {
      console.error(
        'Error in PlantKit getImage:',
        JSON.stringify(error, null, 2)
      );
      throw error;
    }
  }

  async listImages() {
    try {
      const images = this.model?.images || [];
      const promises = images
        .map((image) => (image?.key ? this.getImage(image.key) : null))
        .filter((image) => image);
      const data = await Promise.all(promises);

      const sortedData: { key: string; url: string }[] = [];
      images.forEach((img) => {
        const image = data.find((item) => item?.key === img?.key);
        if (image) sortedData.push(image);
      });

      return sortedData;
    } catch (error) {
      console.error(
        'Error in PlantKit listImages:',
        JSON.stringify(error, null, 2)
      );
      throw error;
    }
  }
}
