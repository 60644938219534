import styled, { css } from "styled-components";

const styleConstructor = ({ inHub }) => {
  if (!inHub)
    return css`
      position: relative;
    `;

  return css`
    flex: 1;
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
  `;
};

const DivMap = styled.div`
  transition: opacity 0.5s ease-out, box-shadow 0.5s ease-out, color 1s ease-in-out, background-color 1s ease-in-out;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.foreground};
  ${styleConstructor}
`;

export default DivMap;
