import { useState, useMemo, useCallback } from "react";

// propName is the name of the property for automatic autoSelect by a specific prop
// propOptions are the options for propName to allow multi selection
const useMultiSelect = ({ items, propName, propOptions = [] }) => {
  const [selectedItemsMap, setSelectedItemsMap] = useState({});
  const [selectedOption, setSelectedOption] = useState();

  const allOfOptionAreSelected = useMemo(() => {
    if (selectedOption === "all") return !items.find((item) => !selectedItemsMap[item.id]);
    if (propOptions.includes(selectedOption)) {
      return !items.find((item) =>
        item[propName] === selectedOption ? !selectedItemsMap[item.id] : !!selectedItemsMap[item.id]
      );
    }
  }, [selectedOption, selectedItemsMap, items, propName, propOptions]);

  const selectedItemIds = useMemo(
    () => Object.keys(selectedItemsMap).filter((itemId) => selectedItemsMap[itemId]),
    [selectedItemsMap]
  );

  const setSelectedItemsOfKind = useCallback(
    (option) => {
      setSelectedItemsMap((prevState) => {
        const newState = {};
        items.forEach((item) => {
          if (item[propName] === option) {
            newState[item.id] = true;
          }
        });
        return newState;
      });
    },
    [setSelectedItemsMap, items, propName]
  );

  const handleMultiSelect = useCallback(
    (option) => {
      if (option === "all") {
        items.forEach((item) => {
          setSelectedItemsMap((prevState) => ({ ...prevState, [item.id]: true }));
        });
      } else if (propOptions.includes(option)) {
        setSelectedItemsOfKind(option);
      } else if (!option) {
        setSelectedItemsMap({});
      }

      setSelectedOption(option);
    },
    [items, setSelectedItemsMap, setSelectedOption, setSelectedItemsOfKind, propOptions]
  );

  const setItemSelection = useCallback(
    (itemId, value) => setSelectedItemsMap((prevState) => ({ ...prevState, [itemId]: value })),
    [setSelectedItemsMap]
  );

  const itemIsSelected = useCallback((itemId) => !!selectedItemsMap[itemId], [selectedItemsMap]);

  return {
    selectedOption,
    selectedItemIds,
    selectedItemsMap,
    allOfOptionAreSelected,
    onMultiSelect: handleMultiSelect,
    itemIsSelected,
    setItemSelection,
  };
};

export { useMultiSelect };
