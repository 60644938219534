import { useMemo, useRef } from "react";
import { constructModelConfig } from "./constructModelConfig";
import { useModelKit } from "./useModelKit";

const useActions = (modelName, model) => {
  const { modelConfig } = useModelKit();

  const modelNameRef = useRef();
  const modelConfigRef = useRef();

  modelNameRef.current = modelName;
  modelConfigRef.current = modelConfig;

  return useMemo(() => constructModelConfig(modelConfigRef.current, modelNameRef.current)?.actions?.(model), [model]);
};

export { useActions };
