const urlModalValues = {
  account: "account",
  addPlant: "add-plant",
  adminFunctions: "admin-functions",
  agenda: "agenda",
  changePassword: "change-password",
  createSupplier: "create-supplier",
  createProject: "create-project",
  deleteSupplier: "delete-supplier",
  deleteProject: "delete-project",
  desire: "desire",
  features: "features",
  fileUpload: "file-upload",
  fileViewer: "file-viewer",
  milestones: "milestones",
  payment: "payment",
  plantInformation: "plant-information",
  priorities: "priorities",
  profilePicUpload: "profile-pic-upload",
  proposalFile: "proposal-file",
  proposalPayment: "proposal-payment",
  proposalPlant: "proposal-plant",
  proposalSupplier: "proposal-supplier",
  styles: "styles",
  termsOfUse: "terms-of-use",
  viewPlantInformation: "view-plant-information",
  verifyUserCredential: "verify-user-credential",
};

export { urlModalValues };
