import styled from "styled-components";
import CssRaisedShadow from "./CssRaisedShadow";
import CssInsetShadow from "./CssInsetShadow";
import CssDivDefault from "./CssDivDefault";

const backgroundColor = ({ isSelected, theme }) => {
  if (isSelected) return theme.green;
  return theme.foreground;
};

const shadow = ({ isSelected }) => {
  if (isSelected) return CssInsetShadow;
  return CssRaisedShadow;
};

const DivSelectOptionBox = styled.div`
  ${CssDivDefault}
  ${shadow}
  @media (min-width: 768px) {
    min-width: 303px;
    max-width: 303px;
  }
  overflow: hidden;
  position: relative;
  display: flex;
  flex: 1;
  flex-direction: column;
  border-radius: 5px;
  min-height: fit-content;
  background-color: ${backgroundColor};
`;

export default DivSelectOptionBox;
