import { useCanCan, useModal, useModel, useFiles } from '@thrivelot/hooks';
import { Button, Pdf, Info, RowItemLoader } from '@thrivelot/stories';
import React, { useEffect, useState } from 'react';
// import { Draggable } from "react-beautiful-dnd";
import TextareaAutosize from 'react-textarea-autosize';
import Image from 'react-graceful-image';
import { Document, Page } from 'react-pdf';
import { DropdownFileActions } from './DropdownFileActions';

const RowItemProjectFile = ({ projectId, file, folderId, classAppend }) => {
  const { openModal } = useModal();
  const [editing, setEditing] = useState();
  const [signedUrl, setSignedUrl] = useState();
  const {
    model: project,
    updateModel,
    actions,
  } = useModel({ modelName: 'Project', id: projectId });
  const { fetchUrl } = useFiles();
  const { canEdit } = useCanCan(project);

  useEffect(() => {
    const fetchSignedUrl = async () => {
      const { data } = await fetchUrl(file.file.key);
      setSignedUrl(data);
    };

    fetchSignedUrl();
  }, [fetchUrl, file?.file?.key]);

  // const handleDocumentClick = useCallback(() => {
  //   (async () => {
  //     const { data } = await fetchUrl(file.file.key);

  //     const newWindow = window.open();
  //     newWindow.document.title = 'Thrive Lot | View PDF';
  //     newWindow.document.write(
  //       `<iframe src="${data}" frameborder="0" style="border:0; top:0px; left:0px; bottom:0px; right:0px; width:100%; height:100%;" allowfullscreen></iframe>`
  //     );
  //   })();
  // }, [fetchUrl, file?.file?.key]);

  const handleOpenFile = () => {
    if (/image/.test(file.file.type)) {
      openModal({
        path: 'ModalViewFiles',
        projectId,
        fileId: file.id,
      });
      // openModal({
      //   path: 'ModalViewImage',
      //   modelName: 'Project',
      //   modelId: projectId,
      //   filePath: 'files',
      //   fileId: file.id,
      // });
    } else {
      openModal({
        path: 'ModalViewFiles',
        projectId,
        fileId: file.id,
      });
      // openModal({
      //   path: 'ModalViewPdf',
      //   modelName: 'Project',
      //   modelId: projectId,
      //   filePath: 'files',
      //   fileId: file.id,
      // });
      // handleDocumentClick();
    }
  };

  if (!signedUrl) return <RowItemLoader />;

  return (
    // <Draggable draggableId={file.id} index={index}>
    //   {(draggableProvided, snapshot) => (
    <div
      className={`flex px-4 py-2 items-center text-mulch-light bg-white-dark hover:bg-white-light min-w-0 w-full ${
        classAppend && classAppend
      }`}
      // ref={draggableProvided.innerRef}
      // {...draggableProvided.draggableProps}
    >
      <div className="mr-3">
        <div
          style={{
            minWidth: 40,
            minHeight: 40,
            maxHeight: 40,
            overflow: 'hidden',
          }}
          className="flex items-center rounded"
        >
          {/* <div {...draggableProvided.dragHandleProps}>
                <Menu color="brown-dark" styleProps="hover:cursor-grab mr-4" />
              </div> */}
          <div className="hover:cursor-pointer">
            {/image/.test(file.file.type) ? (
              <div onClick={handleOpenFile}>
                <Image width={40} src={signedUrl} alt={file.file.filename} />
              </div>
            ) : (
              <Document
                onClick={handleOpenFile}
                file={signedUrl}
                loading={
                  <Pdf
                    size={30}
                    color="brown-light"
                    styleProps="animate-pulse"
                  />
                }
                noData={<Info size={30} color="yellow-main" />}
                error={<Info size={30} color="red-main" />}
              >
                <Page width={40} pageNumber={1} />
              </Document>
            )}
          </div>
        </div>
      </div>
      <div className="flex-1 min-w-0">
        {canEdit && editing ? (
          <div className="pr-5">
            <input
              className="input"
              placeholder="Enter name"
              value={file.file.filename || ''}
              onChange={(e) =>
                updateModel(
                  actions.set(
                    `files[id:${file.id}].file.filename`,
                    e.target.value
                  ).result
                )
              }
            />
          </div>
        ) : (
          <div
            className="font-bold hover:cursor-pointer truncate overflow-hidden whitespace-nowrap mr-1"
            onClick={handleOpenFile}
          >
            {file.file.filename}
            {!editing && file?.caption && (
              <span className="font-normal italic text-sm ml-1">
                - {file.caption}
              </span>
            )}
          </div>
        )}
        {canEdit && editing ? (
          <div className="pr-5 mt-2">
            <TextareaAutosize
              className="input"
              placeholder="Add a note here..."
              minRows={1}
              value={file.caption || ''}
              onChange={(e) =>
                updateModel(
                  actions.set(`files[id:${file.id}].caption`, e.target.value)
                    .result
                )
              }
            />
          </div>
        ) : null}
      </div>
      {canEdit && (
        <div className="flex flex-col items-end">
          <div className="flex gap-4 items-center">
            {editing ? (
              <Button
                icon="Minus"
                kind="outline"
                size="small"
                color="blue-main"
                label="Save"
                onClick={() => setEditing(false)}
              />
            ) : (
              <DropdownFileActions
                projectId={projectId}
                fileId={file.id}
                folderId={folderId}
                setEditing={setEditing}
              />
              // <Button
              //   icon="Edit"
              //   kind="outline"
              //   size="small"
              //   color="yellow-dark"
              //   label="Edit"
              //   onClick={() => setEditing(true)}
              // />
            )}
            {/* <Confirm
              prompt="Are you sure you want to delete this file?"
              onConfirm={deleteFile}
            >
              <Button
                icon="Trash"
                kind="outline"
                size="small"
                color="red-main"
              />
            </Confirm> */}
          </div>
        </div>
      )}
    </div>
    //     )}
    //   </Draggable>
  );
};

export { RowItemProjectFile };
