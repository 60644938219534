import React, { useState } from "react";
import { useElements, useStripe, CardNumberElement } from "@stripe/react-stripe-js";
import {
  ButtonWithSpinner,
  InputBubble,
  DivContentRowCenter,
  DivModalBanner,
  DivModalFooter,
  DivModalContent,
  TextTitle,
} from "@thrivelot/styles";
import { transformCurrencyToString } from "@thrivelot/utils";
import { StripeForm } from "./StripeForm";
import { StripeLogo } from "./StripeLogo";

const PaymentForm = ({ amount, onSubmit }) => {
  const showNotification = window.showNotification;

  const [processing, setProcessing] = useState(false);
  const [name, setName] = useState("");
  const [zip, setZip] = useState("");
  const elements = useElements();
  const stripe = useStripe();

  const handleSubmit = async () => {
    setProcessing(true);

    const cardElement = elements.getElement(CardNumberElement);
    const billingDetails = {
      name,
      address: {
        postal_code: zip,
      },
    };

    const payload = await stripe.createPaymentMethod({
      type: "card",
      card: cardElement,
      billing_details: billingDetails,
    });

    if (payload.error) {
      setProcessing(false);
      showNotification({ type: "error", body: payload.error.message });
      return;
    }

    const tokenResult = await stripe.createToken(cardElement);
    if (tokenResult.error) {
      setProcessing(false);
      showNotification({ type: "error", body: tokenResult.error.message });
      return;
    }

    await onSubmit({ token: tokenResult.token }).then(() => {
      setProcessing(false);
    });
  };

  return (
    <>
      <DivModalBanner>
        <TextTitle>Pay Now</TextTitle>
      </DivModalBanner>
      <DivModalContent>
        <DivContentRowCenter>
          <InputBubble
            disabled={false}
            placeholder="Name on Card"
            value={name}
            onChange={(e) => setName(e.target.value)}
            style={{
              width: "100%",
            }}
          />
        </DivContentRowCenter>
        <StripeForm />
        <DivContentRowCenter>
          <InputBubble
            disabled={false}
            placeholder="Zip Code"
            value={zip}
            onChange={(e) => setZip(e.target.value)}
            style={{
              width: "100%",
            }}
          />
        </DivContentRowCenter>
      </DivModalContent>
      <DivModalFooter>
        <div style={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
          <StripeLogo />
          <ButtonWithSpinner isProcessing={processing} onClick={handleSubmit}>
            Pay {transformCurrencyToString(amount)}
          </ButtonWithSpinner>
        </div>
      </DivModalFooter>
    </>
  );
};

export { PaymentForm };
