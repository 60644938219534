import { isId } from "@thrivelot/utils"

const parseFindByVarForPropertyNameAndValue = (findByVar) => {
  let name = isId(findByVar) ? "id" : undefined;
  let value;
  const propertyArray = findByVar.split(":");
  if (propertyArray.length === 1) [value] = propertyArray;
  else [name, value] = propertyArray;
  return { name, value };
};

export default parseFindByVarForPropertyNameAndValue;
