import React, { useState } from 'react';
import {
  useElements,
  useStripe,
  Elements,
  PaymentElement as StripePaymentElement,
} from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { stripeProductionKey } from '@thrivelot/config';
import { Button } from '@thrivelot/stories';
import colors from '@thrivelot/tailwind/colors';

const stripePromise = loadStripe(stripeProductionKey);

const PaymentElementForm = ({
  onConfirmPayment,
  processing,
  setProcessing,
}) => {
  const stripe = useStripe();
  const elements = useElements();
  const [error, setError] = useState(null);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setError(null);

    if (!stripe || !elements) return;

    setProcessing(true);

    const result = await stripe.confirmPayment({
      // `Elements` instance that was used to create the Payment Element
      elements,
      redirect: 'if_required',
      confirmParams: {
        return_url: window.location.href,
      },
    });

    if (result.error) {
      setProcessing(false);
      setError(result.error.message);

      console.error(result.error);
      return;
    }

    onConfirmPayment(result);
  };

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <StripePaymentElement />
        {error && <div className="text-red-main">{error}</div>}
        <div className="flex items-center mt-8">
          <Button block loading={processing} type="submit" color="green-main">
            Submit
          </Button>
        </div>
      </form>
    </div>
  );
};

const PaymentElement = ({
  paymentIntent,
  onConfirmPayment,
  processing,
  setProcessing,
}) => {
  const options = {
    clientSecret: paymentIntent.client_secret,
    fonts: [
      {
        cssSrc:
          'url(https://fonts.googleapis.com/css2?family=Poppins:wght@700&display=swap)',
      },
    ],
    appearance: {
      theme: 'stripe',
      variables: {
        fontFamily: '"Poppins-Bold", sans-serif',
        fontLineHeight: '1.5',
        borderRadius: '10px',
        colorPrimaryText: colors.white.light,
        colorPrimary: colors.green.main,
        colorText: colors.brown.dark,
        colorTextPlaceholder: colors.tan.main,
        colorDanger: colors.red.main,
        colorSuccess: colors.green.main,
      },
    },
  };

  return (
    <Elements stripe={stripePromise} options={options}>
      <PaymentElementForm
        onConfirmPayment={onConfirmPayment}
        processing={processing}
        setProcessing={setProcessing}
      />
    </Elements>
  );
};

export { PaymentElement };
