import React from "react";
import { Draggable } from "react-beautiful-dnd";
import { transformCurrencyToString } from "@thrivelot/utils";
import colors from "@thrivelot/tailwind/colors";
import { DivColumn, DivContentRow, DivInset, DivLineHorizontal, TextSmallHeader, ReorderIcon } from "@thrivelot/styles";
import { DeleteItem } from "../../shared";

const amountContainerStyle = {
  display: "flex",
  flexDirection: "row",
  padding: "0px 8px",
  minHeight: "35px",
  maxHeight: "35px",
  minWidth: "54px",
  maxWidth: "54px",
  alignItems: "center",
  justifyContent: "space-between",
  marginRight: "12px",
};

const Feature = ({ index, feature, totalCost, deleteFeature }) => {
  const theme = window.$theme;
  return (
    <Draggable draggableId={feature.id} index={index}>
      {(draggableProvided, snapshot) => (
        <DivColumn
          ref={draggableProvided.innerRef}
          shadowRaised={snapshot.isDragging}
          backgroundColor={theme.foreground}
          {...draggableProvided.draggableProps}
        >
          <DivContentRow style={{ display: "flex", alignItems: "center" }}>
            <div
              style={{
                marginRight: "12px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              {...draggableProvided.dragHandleProps}
            >
              <ReorderIcon color={colors.yellow.dark} size={20} />
            </div>
            <TextSmallHeader style={{ width: "100%", marginRight: "20px" }}>{feature.name}</TextSmallHeader>
            <DivInset
              style={{
                ...amountContainerStyle,
                minWidth: "76px",
                maxWidth: "76px",
                justifyContent: "center",
                marginRight: "20px",
              }}
            >
              <TextSmallHeader color={theme.green}>{transformCurrencyToString(totalCost)}</TextSmallHeader>
            </DivInset>
            <DeleteItem
              disabled={snapshot.isDragging}
              style={{
                opacity: snapshot.isDragging ? 0 : 1,
              }}
              onClick={() => deleteFeature(feature.id)}
            />
          </DivContentRow>
          <DivLineHorizontal />
        </DivColumn>
      )}
    </Draggable>
  );
};

export { Feature };
