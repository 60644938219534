import styled from "styled-components";
import CssButtonDefault from "./CssButtonDefault";
import { getButtonColor } from "./helpers";

const ButtonPrompt = styled.div`
  ${CssButtonDefault}
  color: ${(props) => getButtonColor(props)};
  background-color: ${({ backgroundColor, theme }) => backgroundColor || theme.foreground};
  border-radius: 5px;
  min-height: 35px;
  max-height: 35px;
  min-width: fit-content;
  max-width: fit-content;
  padding-left: 15px;
  padding-right: 15px;
  align-items: center;
  justify-content: center;
  font-family: Poppins-Bold;
  font-size: 14px;
`;

export default ButtonPrompt;
