import React, { useMemo } from "react";
import { transformStringToTitleCase, transformStringToCamelCase } from "@thrivelot/utils";
import { sortProjectsByGroup } from "@thrivelot/common";
import { milestoneColorMap } from "@thrivelot/config";
import { RowItemLoader } from "@thrivelot/stories";
import { RowList } from "@thrivelot/stories";
import { RowItemProject } from "../";

const RowListProjectsByStage = ({ projects, loaded }) => {
  const sortedProjects = useMemo(() => sortProjectsByGroup({ projects }), [projects]);

  if (!loaded && projects.length === 0) return <RowItemLoader showText />;

  return (
    <div>
      {Object.keys(sortedProjects).map((milestone) => (
        <div key={milestone}>
          <div
            key={milestone}
            id={transformStringToCamelCase(milestone)}
            className={`text-${milestoneColorMap[milestone]}-dark font-bold text-lg my-2`}
          >
            {transformStringToTitleCase(milestone, true)}
          </div>
          <RowList>
            {sortedProjects[milestone].map((project, index) => (
              <RowItemProject key={project.id} project={project} />
            ))}
          </RowList>
        </div>
      ))}
    </div>
  );
};

export { RowListProjectsByStage };
