import styled from "styled-components";

const LeftColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
  @media (max-width: 767px) {
    padding: 15px 0px 0px;
    flex: 4;
  }
  @media (min-width: 768px) {
    padding: 20px 37px 0px;
    flex: 2;
  }
`;

export { LeftColumnContainer };
