import React from 'react';
import { ImageUserAvatar } from '../ImageUserAvatar';

const ServiceProvider = ({ supplier, renderDetails }) => {
  if (!supplier) return null;

  const { businessName, firstname, lastname } = supplier;
  const supplierName = `${firstname.trim()} ${lastname.trim()}`;

  return (
    <div className="flex items-center text-mulch-light">
      <div className="mr-3">
        <ImageUserAvatar user={supplier} />
      </div>
      <div className="w-full flex-1">
        <div className="font-bold">{businessName || supplierName}</div>
        <div className="text-sm">{businessName ? supplierName : null}</div>
      </div>
      {renderDetails && (
        <div className="flex flex-col items-end justify-center">
          {renderDetails(supplier)}
        </div>
      )}
    </div>
  );
};

export { ServiceProvider };
