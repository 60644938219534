import React from "react";
import { Card } from "@thrivelot/stories";
import { CardLoader } from "@thrivelot/stories";
import { useModal, useModel } from "@thrivelot/hooks";
import { constructAddressString, getProjectStage } from "@thrivelot/common";
import { transformCurrencyToFloat, transformStringToTitleCase } from "@thrivelot/utils";
import { Edit } from "@thrivelot/stories";

const InfoItem = ({ label, value, empty }) => (
  <div className="">
    <div className="text-brown-dark text-sm">{label}</div>
    {empty ? (
      <div className="text-brown-dark italic">None...</div>
    ) : (
      <div className="text-green-dark font-bold">{value}</div>
    )}
  </div>
);

const CardProjectInfo = ({ projectId, editable }) => {
  const { openModal } = useModal();
  const { model: project, loaded } = useModel({ modelName: "Project", id: projectId });

  const stage = project && getProjectStage(project);

  if (!loaded) return <CardLoader showText />;

  const {
    title,
    address,
    budgetCommitment,
    property: { amountImpacted, size },
  } = project.details || { property: {} };

  return (
    <Card
      header="Project Info"
      classAppend="mb-4"
      headerActions={
        editable && (
          <Edit
            color="yellow-dark"
            styleProps="hover:cursor-pointer"
            size="20"
            onClick={() => openModal({ path: "ModalEditProjectInfo", projectId })}
          />
        )
      }
    >
      <div className="mb-4">
        <InfoItem label="Stage" value={transformStringToTitleCase(stage)} empty={!stage} />
      </div>
      <div className="mb-4">
        <InfoItem label="Title" value={title} empty={!title} />
      </div>
      <div className="mb-4">
        <InfoItem label="Address" value={constructAddressString(address)} empty={!address} />
      </div>
      <div className="mb-4">
        <InfoItem label="Property Size" value={size} empty={!size} />
      </div>
      <div className="mb-4">
        <InfoItem label="Impact on property" value={amountImpacted} empty={!amountImpacted} />
      </div>
      <div className="mb-4">
        <InfoItem label="Budget ($)" value={transformCurrencyToFloat(budgetCommitment)} empty={!budgetCommitment} />
      </div>
    </Card>
  );
};

export { CardProjectInfo };
