import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { RowItemLoader, Pill, Button } from '@thrivelot/stories';
import { useAuth, useModel, useModelList } from '@thrivelot/hooks';
import { isSupplierApp, transformStringToTitleCase } from '@thrivelot/utils';

const RowItemProjectInvoiceProposal = ({ invoiceProposalId, to }) => {
  const { userId } = useAuth();
  const {
    model: invoiceProposal,
    loaded,
    actions,
  } = useModel({ modelName: 'InvoiceProposal', id: invoiceProposalId });

  const { models: bundles, loaded: bundlesLoaded } = useModelList({
    modelName: 'Bundle',
    filter: { invoiceProposalId: { eq: invoiceProposalId } },
  });

  const needsVerification = useMemo(
    () =>
      loaded &&
      (invoiceProposal?.payments || []).filter(
        (payment) => payment.stripeObjectStatus === 'verification_required'
      ).length > 0,
    [loaded, invoiceProposal?.payments]
  );
  const completedPayments = useMemo(
    () =>
      loaded &&
      (invoiceProposal?.payments || []).filter((payment) => payment.paidAt),
    [invoiceProposal?.payments, loaded]
  );
  const paymentStatus = useMemo(() => {
    if (!loaded) return;
    if (needsVerification) return 'needsVerification';
    if (
      completedPayments.length > 0 &&
      completedPayments.length === actions.getPaymentCount()
    )
      return 'paid';
    if (
      completedPayments.length > 0 &&
      completedPayments.length !== actions.getPaymentCount()
    )
      return 'partial';
    return 'unpaid';
  }, [actions, completedPayments.length, loaded, needsVerification]);

  if (!loaded || !bundlesLoaded) return <RowItemLoader />;

  if (isSupplierApp() && bundles?.[0].supplierPayeeId !== userId) return null;

  const { name, description, status, phases } = invoiceProposal;

  if (name === 'Site Visit') return null;

  return (
    <Link
      to={to}
      className="block w-full p-6 hover:bg-white-light hover:cursor-pointer"
    >
      <div className="flex gap-6">
        <div className="flex-1 min-w-0">
          <div className="text-lg font-bold text-ellipsis overflow-hidden whitespace-nowrap">
            {name || (
              <span className="italic">Edit to add name to this order...</span>
            )}
            {description && (
              <span className="text-sm font-normal"> - {description}</span>
            )}
          </div>
        </div>
        <div>
          <Button
            color="blue-main"
            label="View"
            icon="Visibility"
            kind="outline"
            size="small"
          />
        </div>
      </div>
      <div className="flex justify-between gap-6 mt-6">
        <div className="flex gap-2">
          <Pill
            color="brown-dark"
            icon="LineItems"
            size="sm"
            label={transformStringToTitleCase(status)}
          />
          {(phases || []).length > 0 && (
            <Pill
              color="purple-main"
              icon="Phases"
              size="sm"
              label={`${(phases || []).length} Phases`}
            />
          )}
        </div>
        <div className="flex gap-2">
          {paymentStatus === 'needsVerification' && (
            <Pill color="blue-light" size="sm" label="$ Needs Verification" />
          )}
          {paymentStatus === 'paid' && (
            <Pill color="green-dark" size="sm" label="$ Paid in Full" />
          )}
          {paymentStatus === 'partial' && (
            <Pill
              color="orange-main"
              size="sm"
              label={`$ ${
                completedPayments.length
              }/${actions.getPaymentCount()} Paid`}
            />
          )}
          {paymentStatus === 'unpaid' && (
            <Pill color="red-main" size="sm" label="$ Unpaid" />
          )}
        </div>
      </div>
    </Link>
  );
};

export { RowItemProjectInvoiceProposal };
