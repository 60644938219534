import styled, { keyframes } from "styled-components";

const slideIn = keyframes`
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
`;

const DivNotification = styled.div`
  transition: opacity 0.5s ease-out, box-shadow 0.5s ease-out, color 1s ease-in-out, background-color 1s ease-in-out;
  overflow: hidden;
  padding: 12px;
  margin-bottom: 12px;
  height: fit-content;
  max-height: 100px;
  display: flex;
  align-items: center;
  width: 375px;
  max-width: calc(100vw - 64px);
  border-radius: 5px;
  -webkit-filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.25));
  -moz-filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.25));
  -o-filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.25));
  -ms-filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.25));
  filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.25));
  opacity: 0.9;
  top: 12px;
  right: 12px;
  animation: ${slideIn} 0.5s ease-out;
  background-repeat: no-repeat;
  &:hover {
    -webkit-filter: drop-shadow(0px 0px 10px rgba(229, 229, 229, 0.25));
    -moz-filter: drop-shadow(0px 0px 10px rgba(229, 229, 229, 0.25));
    -o-filter: drop-shadow(0px 0px 10px rgba(229, 229, 229, 0.25));
    -ms-filter: drop-shadow(0px 0px 10px rgba(229, 229, 229, 0.25));
    filter: drop-shadow(0px 0px 10px rgba(229, 229, 229, 0.25));
    opacity: 1;
  }
`;

export default DivNotification;
