import React from 'react';

const Search = ({
  color = 'green-main',
  size = 30,
  styleProps = '',
  ...rest
}) => (
  <svg
    width={size.toString()}
    height={size.toString()}
    viewBox="0 0 75 75"
    className={`fill-${color} ${styleProps}`}
    {...rest}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M73.8585 68.4488C75.3754 69.938 75.3805 72.3663 73.8739 73.862C73.1035 74.6575 72.1024 75 71.1582 75C70.1736 75 69.1925 74.6116 68.4713 73.8906L48.2869 53.7235C43.2163 57.6306 36.865 59.9684 29.9822 59.9684C13.4471 59.9684 0 46.4986 0 29.9842C0 13.4491 13.448 0 29.9822 0C46.4967 0 59.9447 13.4491 59.9447 29.9644C59.9447 36.8477 57.6071 43.1994 53.7002 48.2704L73.8585 68.4488ZM29.9822 54.193C36.6471 54.193 42.6989 51.4834 47.0894 47.0926C51.4799 42.7017 54.1894 36.6495 54.1894 29.9842C54.1894 16.6337 43.3318 5.77532 29.9822 5.77532C16.6327 5.77532 5.77505 16.6337 5.77505 29.9842C5.77505 43.3149 16.6129 54.1733 29.9822 54.193Z"
    />
  </svg>
);

export { Search };
