import React from 'react';
import { Lock, Button } from '@thrivelot/stories';
import { useModal } from '@thrivelot/hooks';
import { DropdownMultiSelectedActions } from './DropdownMultiSelectedActions';
import { ListItems } from './ListItems';
import { DropdownBundleItemActions } from './DropdownBundleItemActions';
import { InputFeatureDescription } from './InputFeatureDescription';

const ListItemsChangeItems = ({
  projectId,
  bundleId,
  bundleStatus,
  feature,
  items,
}) => {
  const { openModal } = useModal();
  const status = bundleStatus;

  const disabled = status === 'locked' || status === 'unlocked';

  return (
    <div
      className={`p-4 border-t border-tan-light ${
        disabled ? '!bg-tan-light' : ''
      }`}
    >
      <div
        className={`flex justify-between items-center ${
          !disabled ? 'mb-2' : 'mb-1'
        }`}
      >
        <div className="flex items-center gap-2 text-blue-main font-bold">
          {disabled && <Lock size={16} color="red-main" />}
          Change Items
        </div>
        <div>
          {status === 'changes_only' && (
            <Button
              icon="Plus"
              kind="prompt"
              size="small"
              color="blue-main"
              label="Change Items"
              onClick={() =>
                openModal({
                  path: 'ModalAddBundleItems',
                  bundleId,
                  projectId,
                  featureId: feature.id,
                  itemPath: 'changeItems',
                  title: 'Add change items',
                })
              }
            />
          )}
        </div>
      </div>

      <InputFeatureDescription
        bundleId={bundleId}
        path={`orderedFeatureData[prop:featureId:${feature.id}].changeItemsDescription`}
        disabled={disabled}
        placeholder="Describe the changes to the original scope of work for this feature..."
      />

      <ListItems
        itemPath="changeItems"
        disabled={disabled}
        featureId={feature.id}
        projectId={projectId}
        bundleId={bundleId}
        multiSelectDisabled={disabled}
        renderMultiSelectActions={({ selectedItemIds, onMultiSelect }) => (
          <DropdownMultiSelectedActions
            itemPath="changeItems"
            selectedItemIds={selectedItemIds}
            clearSelected={() => onMultiSelect()}
            bundleId={bundleId}
            featureId={feature.id}
            projectId={projectId}
          />
        )}
        renderItemActions={
          !disabled &&
          ((item) => (
            <DropdownBundleItemActions
              itemPath="changeItems"
              bundleId={bundleId}
              projectId={projectId}
              item={item}
            />
          ))
        }
      />
    </div>
  );
};

export { ListItemsChangeItems };
