import { useCallback, useEffect, useRef, useState } from 'react';
import { ModelKit } from '@thrivelot/model-kit';
import { subscribe } from './subscribe';
import { useModelKit } from './useModelKit';
import { notifyErrors } from './notifyErrors';

const useModelList = ({
  modelName,
  models: passedModels = null,
  filter = null,
  deferLoad = false,
  customQuery = null,
}) => {
  const {
    loaded: modelKitLoaded,
    authenticated,
    subscriber,
    modelConfig,
  } = useModelKit();

  const [models, setModels] = useState(passedModels || []);
  const [loaded, setLoaded] = useState(false);
  const [loading, setLoading] = useState(false);
  const [resetSubscriptions, setResetSubscriptions] = useState();

  const modelNameRef = useRef(modelName);
  const filterRef = useRef(filter);
  const deferLoadRef = useRef(deferLoad);
  const subscriberRef = useRef();
  const modelConfigRef = useRef();
  const modelsRef = useRef();

  subscriberRef.current = subscriber;
  modelConfigRef.current = modelConfig;
  modelsRef.current = models;

  if (passedModels && !deferLoadRef.current) deferLoadRef.current = true;

  const resolveItems = useCallback((items) => {
    setModels((prevState) => {
      const newModels = [...prevState];
      prevState.forEach((model, index) => {
        const item = items.find((value) => value.id === model.id);
        if (item) newModels[index] = item;
      });
      return [...newModels, ...items].filter(
        (item, index, self) =>
          self.findIndex((selfItem) => selfItem.id === item.id) === index
      );
    });
    setLoaded(true);
  }, []);

  const queryAll = useCallback(
    async (queryFilter, override) => {
      if (!modelKitLoaded) return;

      if (!override) setLoading(true);

      if (customQuery) {
        const freshModels = await customQuery();
        setModels(freshModels);
        setLoaded(true);
        setLoading(false);
        return;
      }

      if (queryFilter) filterRef.current = queryFilter;

      try {
        const modelKit = new ModelKit({
          modelName: modelNameRef.current,
          filter: filterRef.current,
          resolveItems,
        });
        await modelKit.scan();
      } catch (err) {
        console.log(err);
        notifyErrors(err, modelConfigRef.current.onQueryError);
      } finally {
        if (!override) {
          setLoading(false);
          setLoaded(true);
        }
      }
    },
    [modelKitLoaded, resolveItems, customQuery]
  );

  // const queryOne = useCallback(
  //   async (modelName, id) => {
  //     try {
  //       const modelKit = new ModelKit({ modelName, id });
  //       const data = await modelKit.query();

  //       resolveItems([data]);
  //     } catch (err) {
  //       notifyErrors(err, modelConfigRef.current.onQueryError);
  //     }
  //   },
  //   [resolveItems]
  // );

  useEffect(() => {
    if (!modelKitLoaded || deferLoadRef.current) return;

    queryAll();
  }, [modelKitLoaded, queryAll]);

  useEffect(() => {
    if (!modelKitLoaded || !authenticated) return;

    const callback = ({ data, error }) => {
      if (error) {
        if (error?.error?.errors?.[0]?.message === 'Connection closed') {
          console.log('Resetting subscriptions');
          return setResetSubscriptions(Date.now());
        }

        return notifyErrors(error, modelConfigRef.current.onSubscriptionError);
      }

      const { modelName: callbackModelName } = data;

      if (callbackModelName !== modelNameRef.current) return null;

      // TODO: Filter whether or not model gets added based on (scan) filter used for current list

      console.log('Subscription response: ', data);

      return queryAll(filterRef.current, true);
    };

    const subscriptions = [];

    const subscriptionNames = Object.keys(
      modelConfigRef?.current?.subscriptions
    );

    if (subscriptionNames)
      subscriptionNames.forEach((subscriptionName) => {
        const { gql } =
          modelConfigRef.current.subscriptions[subscriptionName].subscription;

        subscriptions.push(
          subscribe({
            gql,
            variables: { subscriber: subscriberRef.current },
            callback,
          })
        );
      });

    // eslint-disable-next-line consistent-return
    return () => {
      subscriptions.forEach((subscription) => {
        subscription.unsubscribe();
      });
    };
  }, [modelKitLoaded, authenticated, queryAll, resetSubscriptions]);

  return {
    models,
    query: queryAll,
    queryModels: queryAll,
    loading,
    loaded,
  };
};

export { useModelList };
