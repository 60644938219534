import React, { useCallback, useMemo } from 'react';
import { useAuth, useModal } from '@thrivelot/hooks';
import { Dropdown, Alert, User } from '@thrivelot/stories';
import { Avatar } from './Avatar';

const DropdownUser = () => {
  const { openModal } = useModal();
  const { user, signOut, groups } = useAuth();

  const shouldTryAvatar = window.appConfig.appName !== 'admin';

  const handleLogout = useCallback(() => {
    signOut();
  }, [signOut]);

  const [options, alert] = useMemo(() => {
    let alertVal = false;
    const optionsVal = [
      {
        label: 'Settings',
        to: '/settings',
      },
      {
        styleProps: '!text-red-main',
        label: 'Log Out',
        onClick: () => handleLogout(),
      },
    ];

    if (!user?.attributes?.phone_number_verified) {
      alertVal = true;
      optionsVal.unshift({
        icon: 'Alert',
        color: 'red-main',
        label: 'Verify your phone number',
        styleProps: '!text-red-main',
        onClick: () =>
          openModal({
            path: 'ModalVerifyUserAttribute',
            attribute: 'phone_number',
          }),
      });
    }

    if (!user?.attributes?.email_verified) {
      alertVal = true;
      optionsVal.unshift({
        icon: 'Alert',
        color: 'red-main',
        label: 'Verify your email',
        styleProps: '!text-red-main',
        onClick: () =>
          openModal({ path: 'ModalVerifyUserAttribute', attribute: 'email' }),
      });
    }

    if ((groups || []).includes('admin')) {
      optionsVal.unshift({
        label: 'Admin Tools',
        to: '/admin-tools',
      });
    }

    return [optionsVal, alertVal];
  }, [
    openModal,
    handleLogout,
    user?.attributes?.email_verified,
    user?.attributes?.phone_number_verified,
    groups,
  ]);

  return (
    <Dropdown
      align="right"
      label={
        <div className="relative">
          {shouldTryAvatar ? <Avatar /> : <User size={30} />}
          {alert && (
            <div className="absolute top-0 right-0 bg-white-light rounded-full">
              <Alert color="red-main" size="14" />
            </div>
          )}
        </div>
      }
      options={options}
    />
  );
};

export { DropdownUser };
