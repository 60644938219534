import React, { useMemo, useState } from 'react';
import { useModel } from '@thrivelot/hooks';
import { Button, Input } from '@thrivelot/stories';
import { constructUuid } from '@thrivelot/utils';
import { LayoutModal } from '../../LayoutModal';

export const ModalAddFolder = ({ onClose, modelId }) => {
  const [name, setName] = useState('');
  const {
    model: project,
    update,
    loaded,
  } = useModel({
    modelName: 'Project',
    id: modelId,
  });

  const disabled = useMemo(() => {
    if (!name) return true;

    return project?.fileFolders?.some(
      (folder) => folder.name.trim() === name.trim()
    );
  }, [name, project?.fileFolders]);

  const createFolder = () => {
    update({
      fileFolders: [
        ...(project.fileFolders || []),
        { id: constructUuid(), name, fileIds: [] },
      ],
    });
    onClose();
  };

  return (
    <LayoutModal
      onClose={onClose}
      loading={!loaded}
      header={
        <div className="text-lg text-brown-dark italic">
          Add a new folder...
        </div>
      }
      footer={
        <div className="flex items-center justify-end">
          <Button
            label="Submit"
            disabled={disabled}
            color="green-main"
            kind="outline"
            onClick={createFolder}
          />
        </div>
      }
    >
      <Input
        placeholder="Enter folder name"
        value={name}
        onChange={(e) => setName(e.target.value)}
      />
    </LayoutModal>
  );
};
