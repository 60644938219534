import styled from "styled-components";
import { getButtonColor } from "./helpers";

const AColored = styled.a`
  color: ${(props) => getButtonColor(props)};
  font-family: Poppins-Bold;
  &:hover {
    opacity: 0.7;
  }
`;

export default AColored;
