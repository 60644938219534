import styled from "styled-components";
import CssRaisedShadow from "./CssRaisedShadow";
import CssDivDefault from "./CssDivDefault";

const DivRaised = styled.div`
  ${CssDivDefault}
  ${CssRaisedShadow}
  display: flex;
  border-radius: 5px;
  padding: 12px;
  flex-direction: column;
  background-color: ${({ theme }) => theme.foreground};
`;

export default DivRaised;
