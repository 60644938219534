import { useCallback, useEffect, useMemo, useState } from 'react';
import { Plant } from '@thrivelot/aws';
import { PlantKit } from '@thrivelot/plant-kit';
import { handleDebounce } from '@thrivelot/utils';
import { constants } from './constants';

export const usePlantSearch = () => {
  const [query, setQuery] = useState('');
  const [loading, setLoading] = useState(false);
  const [items, setItems] = useState([]);

  const searchPlants = useCallback(async () => {
    const formattedQuery = query.trim().toLowerCase();

    if (query.length < 3) return;

    setLoading(true);

    try {
      const plantKit = new PlantKit({
        filter: {
          or: [
            { commonName: { matchPhrase: formattedQuery } },
            { familyCommonName: { matchPhrase: formattedQuery } },
            { scientificName: { matchPhrase: formattedQuery } },
            { family: { matchPhrase: formattedQuery } },
            { genus: { matchPhrase: formattedQuery } },
            { species: { matchPhrase: formattedQuery } },
            { otherCommonNames: { matchPhrase: formattedQuery } },
            { synonyms: { matchPhrase: formattedQuery } },
          ],
        },
      });

      const data = await plantKit.search();
      setItems(data || []);
    } catch (error) {
      console.log('Error searching plants', error);
    } finally {
      setLoading(false);
    }
  }, [query]);

  const searchPlantsDebounced = useMemo(
    () => handleDebounce(searchPlants, 500),
    [searchPlants]
  );

  useEffect(() => {
    const preSearchPlantsDebounced = searchPlantsDebounced;
    return () => preSearchPlantsDebounced.cancel();
  });

  useEffect(() => {
    if (query.trim().length === 0) setItems([]);
    else searchPlantsDebounced();
  }, [query, searchPlantsDebounced]);

  return { query, setQuery, loading, items: items as Plant[], constants };
};
