import { LineItemKind } from "@thrivelot/aws";

const lineItemIconMap = {
  [LineItemKind.EQUIPMENT]: "Equipment",
  [LineItemKind.LABOR]: "Labor",
  [LineItemKind.MATERIAL]: "Soil",
  [LineItemKind.PLANT]: "Leaf",
};

const lineItemColorMap = {
  [LineItemKind.MATERIAL]: "brown-dark",
  [LineItemKind.LABOR]: "blue-main",
  [LineItemKind.EQUIPMENT]: "orange-main",
  [LineItemKind.PLANT]: "green-main",
};

export { lineItemIconMap, lineItemColorMap };
