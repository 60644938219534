import React from "react";
import PhoneInput from "react-phone-number-input/input";
import { useTheme } from "@thrivelot/hooks";
import "./InputPhoneNumber.css";

const InputPhoneNumber = (props) => {
  const theme = useTheme();

  return (
    <PhoneInput
      className={`phone-input-rounded phone-${theme.isDarkMode ? "dark" : "light"}`}
      country="US"
      {...props}
    />
  );
};

export default InputPhoneNumber;
