import { useContext } from "react";
import { CalendarContext } from "./CalendarContext";

const useCalendar = () => {
  const { state, setValue } = useContext(CalendarContext);

  return {
    state,
    setValue: (name, value) => setValue(name, value),
  };
};

export { useCalendar };
