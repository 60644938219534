import React from 'react';
import { ProposalProvider, ProposalViewProvider } from '@thrivelot/common';
import { CustomerProposal as CustomerProposalHub } from '@thrivelot/components';

const CustomerProposal = () => (
  <ProposalProvider>
    <ProposalViewProvider>
      <CustomerProposalHub />
    </ProposalViewProvider>
  </ProposalProvider>
);

export { CustomerProposal };
