import React from "react";
import { useRouter } from "@thrivelot/common";
import { PlantContainer } from "./PlantContainer";
import { Pic } from "./Pic";
import { Quantity } from "./Quantity";

const Plant = ({ plant }) => {
  // hooks
  const { history, urlHashes, urlModalValues } = useRouter();
  // navigation
  const openPlantModal = () => {
    history.push({
      hash: {
        [urlHashes.proposalModal]: urlModalValues.proposalPlant,
        [urlHashes.plantId]: plant.id,
      },
    });
  };
  return (
    <PlantContainer onClick={openPlantModal}>
      <Pic plant={plant} />
      <Quantity plant={plant} />
    </PlantContainer>
  );
};

export { Plant };
