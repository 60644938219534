import { useModal, useModel } from '@thrivelot/hooks';
import { Dropdown, AngleDown } from '@thrivelot/stories';
import React, { useMemo, useCallback, useState } from 'react';

export const DropdownMultiSelectedActions = ({
  selectedItemIds,
  clearSelected,
  folderId,
  projectId,
}) => {
  const { openModal } = useModal();
  const {
    model: project,
    loaded,
    update,
    actions,
  } = useModel({
    modelName: 'Project',
    id: projectId,
  });
  const [deleting, setDeleting] = useState(false);

  const handleDeleteSelected = useCallback(() => {
    if (!project?.files) return;

    const newFiles = project.files.filter(
      (file) => !selectedItemIds.includes(file.id)
    );
    const newFolders = (project.fileFolders || []).map((folder) => {
      const newFileIds = (folder.fileIds || []).filter(
        (id) => !selectedItemIds.includes(id)
      );
      return { ...folder, fileIds: newFileIds };
    });

    update({ files: newFiles, fileFolders: newFolders });
    clearSelected();
  }, [selectedItemIds, project?.files, project?.fileFolders, update, actions]);

  const options = useMemo(() => {
    let result = [];
    if (deleting)
      result = [
        {
          icon: 'Minus',
          label: 'No, keep files',
          color: 'blue-main',
          closeOnClick: true,
          onClick: () => setDeleting(false),
        },
        {
          icon: 'Trash',
          label: 'Yes, delete files',
          color: 'red-main',
          closeOnClick: true,
          onClick: () => handleDeleteSelected(),
        },
      ];
    else
      result = [
        {
          icon: 'FilesFolder',
          label: 'Move files to a folder',
          onClick: () => {
            openModal({
              path: 'ModalAddFileToFolder',
              modelId: projectId,
              fileIds: selectedItemIds,
              folderId,
            });
            clearSelected();
          },
          closeOnClick: true,
        },
        {
          icon: 'Trash',
          label: 'Delete files',
          color: 'red-main',
          onClick: () => setDeleting(true),
        },
      ];

    return result;
  }, [handleDeleteSelected, deleting, clearSelected, selectedItemIds]);

  if (selectedItemIds.length === 0 || !loaded) return null;

  return (
    <Dropdown
      label={
        <div className="flex items-center gap-2 py-0.5 px-3 border-2 border-blue-main rounded-main">
          <div className="text-sm text-blue-main">
            {selectedItemIds.length} Items Selected
          </div>
          <AngleDown size={12} color="blue-main" />
        </div>
      }
      options={options}
      optionClass="font-bold"
      align="left"
      closeOnSelect={false}
      header={deleting ? <div className="italic">Are you sure?</div> : null}
      onClose={() => setDeleting(false)}
    />
  );
};
