const removeItems = (model, itemIds) => {
  let path;
  if (model.status === "unlocked") {
    path = "lineItems";
  } else if (model.status === "changes_only") {
    path = "changeItems";
  } else {
    return model;
  }

  // Everything related to this featureIdsWithLineItems variable is to remove
  // features from the list when there are no line items in them after removing.
  // Here we build a running hash map of all features with at least one line item
  // or change item after removing the items from itemIds.
  const featuresWithItems = {};

  const items = [];
  const orderedFeatureData = [];

  // We need to also preserve any features with existing lineItems regardless of path
  (model.lineItems || []).forEach((item) => {
    if (path === "lineItems" && itemIds.includes(item.id)) return;
    if (path === "lineItems") items.push(item);
    featuresWithItems[item.featureId] = true;
  });

  // We need to also preserve any features with existing changeItems regardless of path
  (model.changeItems || []).forEach((item) => {
    if (path === "changeItems" && itemIds.includes(item.id)) return;
    if (path === "changeItems") items.push(item);
    featuresWithItems[item.featureId] = true;
  });

  // Here we create a new list of featureData from the featuresWithItems hashmap
  // while preserving the order
  (model.orderedFeatureData || []).forEach((featureData) => {
    if (!featuresWithItems[featureData.featureId]) return;
    if (orderedFeatureData.find((data) => data.featureId === featureData.featureId)) return; // remove duplicates
    orderedFeatureData.push(featureData);
  });

  return { ...model, [path]: items, orderedFeatureData };
};

export { removeItems };
