import React, { Fragment } from "react";
import { duplicateObject, sortComplex, transformCurrencyToString } from "@thrivelot/utils";
import { transformArrToObjById, getWorkPhaseItems, calculateTotalCost } from "@thrivelot/common";
import { Feature } from "./Feature";
import { NewFeature } from "./NewFeature";

const Features = ({ model, addFeature, deleteFeature, orderedChildTagIds, placeholder }) => {
  const mappedFeatures = transformArrToObjById(model.features);

  const getFeatures = () => {
    if (!orderedChildTagIds) return [];
    if (!mappedFeatures) return [];

    const features = [];
    const featureOptions = duplicateObject(mappedFeatures);

    orderedChildTagIds.forEach((tag) => {
      if (mappedFeatures[tag.tagId]) features.push(mappedFeatures[tag.tagId]);
      delete featureOptions[tag.tagId];
    });

    const selectReadyFeatureOptions = Object.keys(featureOptions).map((featureId) => {
      const feature = featureOptions[featureId];
      const { orderedChildTagIds: tagIds } = feature;
      const items = getWorkPhaseItems({ tagIds, project: model });
      const totalCost = calculateTotalCost(items);

      const option = {
        label: `${feature.name} (${transformCurrencyToString(Math.ceil(totalCost / 100) * 100)})`,
        value: feature.id,
      };
      return option;
    });

    const sortedFeatureOptions = sortComplex({
      arr: selectReadyFeatureOptions,
      by: "label",
    });

    return { features, featureOptions: sortedFeatureOptions };
  };

  const { features, featureOptions } = getFeatures();

  const getFeatureTotalCost = (feature) => {
    if (!feature) return 0;
    const { orderedChildTagIds: tagIds } = feature;
    const items = getWorkPhaseItems({ tagIds, project: model });
    const totalCost = calculateTotalCost(items);

    return Math.ceil(totalCost / 100) * 100;
  };

  return (
    <Fragment>
      {features.map((feature, index) => {
        const totalCost = getFeatureTotalCost(feature);
        return (
          <Feature
            key={feature.id}
            index={index}
            feature={feature}
            totalCost={totalCost}
            deleteFeature={deleteFeature}
          />
        );
      })}
      {placeholder}
      <NewFeature featureOptions={featureOptions} addFeature={addFeature} />
    </Fragment>
  );
};

export { Features };
