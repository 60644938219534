import React, { useState, useEffect, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import * as icons from './icons';

import './index.css';
import { getTailwindColorStrings } from './utils';

const Label = ({ label, isOpen }) => {
  if (typeof label === 'function') {
    return label({ isOpen });
  }

  return label;
};

export const Dropdown = ({
  label,
  options,
  header,
  align,
  verticalAlign,
  optionClass,
  dropdownClassAppend,
  optionsClassAppend,
  labelClassAppend,
  labelWrapperClassAppend,
  selectedColor,
  closeOnSelect = true,
  onClose,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const ref = useRef();
  const labelRef = useRef();

  const toggleOpen = useCallback(
    (bool) => {
      setIsOpen(bool);
      if (!bool && onClose) onClose();
    },
    [onClose]
  );

  const handleEscKeypress = useCallback(
    (event) => {
      if (event.key === 'Escape') {
        toggleOpen(false);
      }
    },
    [toggleOpen]
  );

  const handleOffClick = useCallback(
    (event) => {
      if (
        !labelRef.current.contains(event.target) &&
        !ref.current.contains(event.target)
      ) {
        toggleOpen(false);
      }
    },
    [ref, labelRef, toggleOpen]
  );

  useEffect(() => {
    const addListeners = () => {
      document.addEventListener('keydown', handleEscKeypress, true);
      document.addEventListener('click', handleOffClick, true);
    };

    const removeListeners = () => {
      document.removeEventListener('keydown', handleEscKeypress, true);
      document.removeEventListener('click', handleOffClick, true);
    };

    if (isOpen) {
      addListeners();
    } else {
      removeListeners();
    }

    return () => {
      removeListeners();
    };
  }, [isOpen, handleEscKeypress, handleOffClick]);

  return (
    <div className={`relative inline-block ${labelWrapperClassAppend}`}>
      <div
        className={`relative hover:cursor-pointer ${labelClassAppend}`}
        ref={labelRef}
        onClick={() => {
          toggleOpen(!isOpen);
        }}
      >
        <Label label={label} isOpen={isOpen} />
      </div>

      {isOpen && (
        <div
          ref={ref}
          className={`absolute ${
            align === 'right' ? 'right-0' : 'left-0'
          } z-10 w-64 ${
            verticalAlign === 'top' ? '-top-1/2 -translate-y-full' : 'mt-1'
          } overflow-hidden bg-white-light rounded-main shadow-xl ${
            dropdownClassAppend || ''
          }`}
        >
          {header && (
            <div className="flex items-center p-3 pt-2 text-sm text-brown-dark transition-colors duration-200 border-b border-[#e6d6be]">
              {header}
            </div>
          )}

          <div
            className={`max-h-72 overflow-y-auto ${optionsClassAppend || ''}`}
          >
            {options.map((option) => {
              const {
                key,
                label: optionLabel,
                icon,
                to,
                href,
                color,
                selected,
                onClick = () => null,
                closeOnClick,
                styleProps,
              } = option;
              const className = `block flex items-center px-4 py-3 text-sm font-bold hover:cursor-pointer ${
                selected
                  ? `text-white-light bg-${selectedColor || 'green-main'}`
                  : `text-${color || 'brown-dark'} hover:bg-tan-light`
              }  transition-colors duration-200 `;

              let Icon;
              if (icon && typeof icon === 'string') {
                Icon = icon ? icons[icon] : null;
              } else {
                Icon = icon;
              }
              if (icon && !Icon) throw Error(`Could not find icon: ${icon}`);

              if (typeof option === 'function') {
                return option({ setIsOpen: toggleOpen, className });
              }

              if (href) {
                return (
                  <a
                    key={key || optionLabel}
                    target="_blank"
                    href={href}
                    className={`${className} ${styleProps || optionClass}`}
                    rel="noreferrer"
                  >
                    {optionLabel}
                  </a>
                );
              }

              if (to) {
                return (
                  <Link
                    key={key || optionLabel}
                    onClick={() => {
                      toggleOpen(false);
                    }}
                    to={to}
                    className={`${className} ${styleProps || optionClass}`}
                  >
                    {icon && (
                      <Icon
                        size={12}
                        color={selected ? 'white-light' : color || 'brown-dark'}
                        styleProps="mr-1"
                      />
                    )}
                    {optionLabel}
                  </Link>
                );
              }

              if (optionLabel || optionLabel === '')
                return (
                  <div
                    key={key || optionLabel}
                    onClick={(e) => {
                      if (!selected) onClick(e);
                      if (closeOnClick || closeOnSelect) toggleOpen(false);
                    }}
                    className={`${className} ${styleProps || optionClass}`}
                  >
                    {icon && (
                      <Icon
                        size={12}
                        color={selected ? 'white-light' : color || 'brown-dark'}
                        styleProps="mr-1"
                      />
                    )}
                    {optionLabel || <span className="italic">Empty...</span>}
                  </div>
                );

              return option;
            })}
          </div>
        </div>
      )}
    </div>
  );
};

Dropdown.propTypes = {
  /**
   * Header section of dropdown
   */
  label: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),

  /**
   * Size of the button
   */
  options: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.oneOfType([
        PropTypes.shape({
          label: PropTypes.string.isRequired,
          color: PropTypes.oneOf(getTailwindColorStrings()),
          selected: PropTypes.bool,
          onClick: PropTypes.func,
          render: PropTypes.func,
          to: PropTypes.string,
          href: PropTypes.string,
          icon: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
        }),
        PropTypes.element,
        PropTypes.func,
      ])
    ),
  ]).isRequired,

  /**
   * Header section of dropdown
   */
  header: PropTypes.element,

  /**
   * Alignment of dropdown
   */
  align: PropTypes.string,

  /**
   * Vertical alignment of dropdown
   */
  verticalAlign: PropTypes.string,

  /**
   * Class names to be passed through to each option
   */
  optionClass: PropTypes.string,

  /**
   * Class to be added to the label wrapper divs
   */
  labelWrapperClassAppend: PropTypes.string,

  /**
   * Color for the selected item
   */
  selectedColor: PropTypes.oneOf(getTailwindColorStrings()),
};

Dropdown.defaultProps = {
  align: 'left',
  verticalAlign: 'bottom',
};
