import { useEffect } from "react";
import { useAuth, useModal, useModel } from "@thrivelot/hooks";
import { isCustomerApp } from "@thrivelot/utils";

const getModelName = () => (isCustomerApp() ? "Customer" : "Supplier");

const termsUrl = () =>
  isCustomerApp()
    ? "https://thrivelot.notion.site/Terms-Conditions-for-Customer-d29485acd283404b900c513d533784f2"
    : "https://thrivelot.notion.site/Terms-Conditions-Service-Provider-569ec9c1306e41f9a9cf2134be100019";

const EnsureTerms = () => {
  const { userId } = useAuth();
  const { openModal } = useModal();
  const { model: user, loaded } = useModel({ modelName: getModelName(), id: userId });

  useEffect(() => {
    if (loaded && user?.termsOfUse?.revisedAt !== "2022-01-19T04:00:00.000Z") {
      openModal({ path: "ModalReviewTerms", termsUrl: termsUrl() });
    }
  }, [loaded, user?.termsOfUse?.revisedAt, openModal]);

  return null;
};

export { EnsureTerms };
