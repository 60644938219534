import styled from "styled-components";
import CssDivDefault from "./CssDivDefault";

const DivModalContent = styled.div`
  ${CssDivDefault}
  position: relative;
  overflow-y: scroll;
  overflow-x: hidden;
  min-height: 200px;
  padding: 12px 12px 0px;
`;

export default DivModalContent;
