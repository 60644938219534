import React from 'react';

const Link = ({
  color = 'green-main',
  size = 30,
  styleProps = '',
  ...rest
}) => (
  <svg
    width={size.toString()}
    height={size.toString()}
    viewBox="0 0 75 75"
    className={styleProps}
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M18.0574 47.2523C16.9713 47.2523 15.8852 46.817 15.0162 45.9465L6.43529 37.4592C-2.1451 28.8624 -2.1451 14.9347 6.43529 6.44755C15.0162 -2.14918 28.9195 -2.14918 37.392 6.44755L45.9724 15.0437C47.7104 16.7848 47.7104 19.5052 45.9724 21.2463C44.2344 22.9874 41.5192 22.9874 39.7812 21.2463L31.2002 12.6496C28.7022 10.1467 25.335 8.73213 21.8591 8.73213C18.2749 8.73213 15.0161 10.1467 12.518 12.6496C7.3041 17.8729 7.3041 26.2516 12.518 31.3664L21.0989 39.9631C22.837 41.7042 22.837 44.4242 21.0989 46.1653C20.3383 46.8182 19.1438 47.2535 18.0572 47.2535L18.0574 47.2523ZM53.032 75C47.3839 75 41.8446 72.8236 37.5001 68.5796L28.9191 59.9834C27.1811 58.2424 27.1811 55.522 28.9191 53.7809C30.6572 52.0398 33.3729 52.0398 35.1103 53.7809L43.6913 62.3776C48.9052 67.6009 57.2691 67.6009 62.3732 62.3776C67.5871 57.1543 67.5871 48.7756 62.3732 43.6608L53.7928 35.0641C52.0548 33.323 52.0548 30.6026 53.7928 28.8619C55.5309 27.1208 58.246 27.1208 59.984 28.8619L68.565 37.4581C77.1459 46.0548 77.1459 59.9825 68.565 68.471C64.2201 72.8232 58.6802 74.9996 53.0317 74.9996L53.032 75ZM41.4099 47.4693C42.2789 48.3398 43.365 48.7751 44.4516 48.7751C45.646 48.7751 46.7326 48.3398 47.6011 47.5781C49.3391 45.837 49.3391 43.1166 47.6011 41.3755L33.5893 27.3382C31.8513 25.5971 29.1361 25.5971 27.3981 27.3382C25.6601 29.0793 25.6601 31.7997 27.3981 33.5408L41.4099 47.4693Z"
      className={`fill-${color}`}
    />
  </svg>
);

export { Link };
