import React from "react";
import { CardNumberElement, CardCvcElement, CardExpiryElement } from "@stripe/react-stripe-js";
import { useTheme } from "@thrivelot/hooks";
import { StripeElementWrapperDiv } from "./StripeElementWrapperDiv";
import { buildCardOptions } from "./buildCardOptions";

const StripeForm = () => {
  const theme = useTheme();
  const cardOptions = buildCardOptions(theme);

  return (
    <>
      <div style={{ display: "flex", margin: "10px 0" }}>
        <StripeElementWrapperDiv>
          <CardNumberElement options={cardOptions} />
        </StripeElementWrapperDiv>
      </div>
      <div style={{ display: "flex", margin: "10px 0", minHeight: "fit-content" }}>
        <StripeElementWrapperDiv style={{ marginRight: "10px" }}>
          <CardExpiryElement options={cardOptions} />
        </StripeElementWrapperDiv>
        <StripeElementWrapperDiv>
          <CardCvcElement options={cardOptions} />
        </StripeElementWrapperDiv>
      </div>
    </>
  );
};

export { StripeForm };
