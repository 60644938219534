import React, { useEffect } from "react";
import { Outlet } from "react-router-dom";

const LayoutAuthentication = ({ navConfig }) => {
  // The bg needs to be set on the document.body otherwise
  // you can see the wrong color when scrolling up and down
  useEffect(() => {
    document.body.classList.add("!bg-tan-light");

    return () => {
      document.body.classList.remove("!bg-tan-light");
    };
  }, []);

  return (
    <div className="flex flex-col h-screen">
      <div className="container mx-auto p-4 flex flex-grow justify-center items-center">
        <Outlet />
      </div>
    </div>
  );
};

export { LayoutAuthentication };
