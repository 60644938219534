import { constructUuid } from '@thrivelot/utils';

const addItems = (model, items) => {
  let path: string;
  if (model.status === 'unlocked') {
    path = 'lineItems';
  } else if (model.status === 'changes_only') {
    path = 'changeItems';
  } else {
    return model;
  }

  model[path] = model[path] || [];
  model.orderedFeatureData = model.orderedFeatureData || [];

  const itemsAlreadyAddedMap = {};
  model[path].forEach((item) => {
    itemsAlreadyAddedMap[item.id] = true;
  });

  items.forEach((item) => {
    if (
      !model.orderedFeatureData.find(
        (data) => data.featureId === item.featureId
      )
    )
      model.orderedFeatureData.push({
        id: constructUuid(),
        featureId: item.featureId,
      });
    if (!itemsAlreadyAddedMap[item.id]) model[path].push(item);
  });

  return model;
};

export { addItems };
