import { useEffect, useRef, useState } from 'react';
import { awsConfig } from '@thrivelot/aws';
import { client } from '@thrivelot/model-kit';
import { plantClient } from '@thrivelot/plant-kit';
import { client as storageClient } from '@thrivelot/storage';
import { isEqual } from '@thrivelot/utils';

const getSubscriber = (owner, groups) => {
  if (groups?.[0] === 'admin') return 'admin';

  return owner;
};

const useModelKitProvider = (
  props = { authConfig: null, modelConfig: null, auth: null }
) => {
  const [authConfig, setAuthConfig] = useState(props.authConfig);
  const [modelConfig, setModelConfig] = useState(props.modelConfig);
  const [storageConfig, setStorageConfig] = useState({});
  const [auth, setAuth] = useState(props.auth);
  const [subscriber, setSubscriber] = useState(
    getSubscriber(auth?.user?.username, auth?.groups)
  );
  const [loaded, setLoaded] = useState(false);

  const authConfigRef = useRef();
  const modelConfigRef = useRef();
  const authRef = useRef();
  const subscriberRef = useRef();

  authConfigRef.current = authConfig;
  modelConfigRef.current = modelConfig;
  authRef.current = auth;
  subscriberRef.current = subscriber;

  useEffect(() => {
    let didCancel;

    if (!isEqual(props.authConfig, authConfigRef.current) && !didCancel)
      setAuthConfig(props.authConfig);

    return () => {
      didCancel = true;
    };
  }, [props.authConfig]);

  useEffect(() => {
    let didCancel;

    if (!isEqual(props.modelConfig, modelConfigRef.current) && !didCancel)
      setModelConfig(props.modelConfig);

    return () => {
      didCancel = true;
    };
  }, [props.modelConfig]);

  useEffect(() => {
    let didCancel;

    if (!isEqual(props.auth, authRef.current) && !didCancel)
      setAuth(props.auth);

    return () => {
      didCancel = true;
    };
  }, [props.auth]);

  useEffect(() => {
    let didCancel;

    const newSubscriber = getSubscriber(auth?.user?.username, auth?.groups);

    if (!isEqual(newSubscriber, subscriberRef.current) && !didCancel)
      setSubscriber(newSubscriber);

    return () => {
      didCancel = true;
    };
  }, [auth?.user?.username, auth?.groups]);

  useEffect(() => {
    if (!auth?.authenticated) return;

    let didCancel;

    const storage = {
      bucket: awsConfig.aws_user_files_s3_bucket,
      region: awsConfig.aws_user_files_s3_bucket_region,
      fetchCredentials: authConfigRef.current.fetchCredentials,
    };

    client.init({
      modelConfig: modelConfigRef.current,
      endpoint: awsConfig.aws_appsync_graphqlEndpoint,
      fetchJwt: authConfigRef.current.fetchJwt,
      queryNameRegex: modelConfigRef.current.queryNameRegex,
    });
    plantClient.setFetchCredentials(storage.fetchCredentials);
    storageClient.init(storage);

    if (!didCancel) setStorageConfig(storage);
    if (!didCancel) setLoaded(true);

    return () => {
      didCancel = true;
    };
  }, [auth?.authenticated]);

  return {
    loaded,
    authenticated: auth?.authenticated,
    subscriber,
    modelConfig,
    storageConfig,
  };
};

export { useModelKitProvider };
