import { isId } from '@thrivelot/utils';
import { getHashFromCurrentUrl } from './getHashFromCurrentUrl';
import { getModelNameFromCurrentUrl } from './getModelNameFromCurrentUrl';
import { modelNameByUrlKey } from './modelNameByUrlKey';

const getModelIdFromCurrentUrl = (modelName, hash) => {
  if (!hash) {
    hash = getHashFromCurrentUrl();
  }
  if (!modelName) {
    modelName = getModelNameFromCurrentUrl({ hash });
  }
  if (modelName) {
    return hash[modelNameByUrlKey[modelName]];
  }
  const urlFragments = window.location.pathname.split('/');
  for (let i = 0; i < urlFragments.length; i += 1) {
    if (isId(urlFragments[i])) {
      return urlFragments[i];
    }
  }
  return null;
};

export { getModelIdFromCurrentUrl };
