import {
  constructItemWithTotals,
  calculatePercentTotal,
} from './constructItemWithTotals';

export const constructItems = (
  items,
  housePercent,
  bundle,
  creditItem,
  type
) => {
  let allPriceSubtotal = 0;

  const constructedItems = (items || [])
    .filter((i) => !i.hidden)
    .map((item) => {
      const itemWithTotal = constructItemWithTotals(
        item,
        housePercent,
        bundle,
        type
      );
      allPriceSubtotal += itemWithTotal.priceSubtotal;
      return itemWithTotal;
    });

  if (!creditItem) return constructedItems;

  const constructedItemsWithRedistributeSubtotal = constructedItems.map(
    (item) => {
      if ((creditItem.redistributeAmount || 0) === 0)
        return {
          ...item,
          redistributeSubtotal: 0,
          halfRedistributeSubtotal: 0,
        };

      const halfRedistributeSubtotal = Math.round(
        (item.priceSubtotal * creditItem.redistributeAmount) /
          allPriceSubtotal /
          2
      );
      const redistributeSubtotal = halfRedistributeSubtotal * 2;

      const newItem = {
        ...item,
        redistributeSubtotal,
        totalAmount:
          item.priceSubtotal + item.houseSubtotal + redistributeSubtotal,
      };

      // For time_materials_billing
      if (newItem.kind === 'labor') {
        newItem.halfRedistributeSubtotal = halfRedistributeSubtotal;
        newItem.halfTotalAmount =
          newItem.halfPriceSubtotal +
          newItem.halfHouseSubtotal +
          newItem.halfRedistributeSubtotal;
      }

      return newItem;
    }
  );

  return constructedItemsWithRedistributeSubtotal;
};
