import { useProposal, useRouter } from "@thrivelot/common";

const useSupplierActionSet = () => {
  // state hooks
  const { supplierActions } = useProposal();
  const { hash, urlHashes } = useRouter();
  // constants
  const supplierId = hash[urlHashes.supplierId];
  // return
  return supplierActions(supplierId);
};

export { useSupplierActionSet };
