import React, { useState, useEffect } from "react";
import { useModel, useModal, useFiles } from "@thrivelot/hooks";
import { Edit, FileUpload } from "@thrivelot/stories";

const ProfilePic = ({ supplierId }) => {
  const { model: supplier, loaded } = useModel({ modelName: "Supplier", id: supplierId });

  const { openModal } = useModal();
  const [signedUrl, setSignedUrl] = useState();
  const { fetchUrl } = useFiles();

  useEffect(() => {
    const fetchSignedUrl = async () => {
      const { data } = await fetchUrl(supplier.pic.key);
      setSignedUrl(data);
    };
    if (supplier?.pic?.key) fetchSignedUrl();
  }, [fetchUrl, supplier?.pic?.key]);

  if (!loaded && !supplier?.pic?.key && !signedUrl)
    return <div className="bg-tan-light h-48 w-48 rounded-full flex items-center justify-center animate-pulse" />;

  return (
    <div className="relative">
      {signedUrl ? (
        <img
          src={signedUrl}
          alt="profile pic"
          className="bg-tan-light h-48 w-48 rounded-full flex items-center justify-center"
        />
      ) : (
        <div className="bg-tan-light h-48 w-48 rounded-full flex items-center justify-center">
          <FileUpload color="blue-main" size={75} />
        </div>
      )}

      <div
        className="absolute bottom-4 right-3 bg-white-light rounded-full hover:cursor-pointer"
        onClick={() => openModal({ path: "ModalAddSupplierPic", supplierId })}
      >
        <Edit color="orange-main" size={30} />
      </div>
    </div>
  );
};

export { ProfilePic };
