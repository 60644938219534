import React from "react";
import { useTheme } from "@thrivelot/hooks";
import { useProposal } from "@thrivelot/common";
import { TextBody } from "@thrivelot/styles";
import { useHangingIndent, useSpacing } from "../../../hooks";

const Notes = () => {
  // hooks
  const theme = useTheme();
  const { projectActions } = useProposal();
  const marginLeft = useHangingIndent();
  const spacing = useSpacing();
  // actions
  const projectStory = projectActions.get("details.adminNotes");
  // return
  return <TextBody style={{ marginLeft, marginTop: spacing, color: theme.textTitle }}>{projectStory}</TextBody>;
};

export { Notes };
