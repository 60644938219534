import React, { useCallback } from "react";
import { useModel } from "@thrivelot/hooks";
import { LeafLoader } from "@thrivelot/stories";
import { Selector } from "@thrivelot/stories";

export const totalsAsMap = {
  grand_totals: {
    // icon: "SingleBilling",
    value: "grand_totals",
    label: "Grand Totals",
    description:
      "Show the customer a only a single total for each type of line item (materials, labor, plants, etc...).",
  },
  feature_totals: {
    // icon: "Design",
    value: "feature_totals",
    label: "Feature Totals",
    description:
      "Show the customer individual totals for each type of line item (materials, labor, plants, etc...) for each feature.",
  },
  item_totals: {
    // icon: "TimeMaterialsBilling",
    value: "item_totals",
    label: "Item Totals",
    description: "Show the customer the price of each individual line item grouped by feature",
  },
};

const SelectInvoiceProposalTotalsAs = ({ invoiceProposalId }) => {
  const {
    model: invoiceProposal,
    loaded,
    updateModel,
    actions,
  } = useModel({ modelName: "InvoiceProposal", id: invoiceProposalId });

  const updateValue = useCallback(
    (value) => {
      updateModel(actions.set("totalsAs", value).result);
    },
    [updateModel, actions]
  );

  if (!loaded) return <LeafLoader />;

  const { totalsAs, status } = invoiceProposal;

  return (
    <div className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-2">
      {Object.values(totalsAsMap).map(({ icon, value, label }) => (
        <Selector
          color="green-main"
          disabledColor="green-dark"
          key={value}
          icon={icon}
          selected={totalsAs === value}
          label={label}
          disabled={status !== "draft"}
          onClick={() => updateValue(value)}
        />
      ))}
    </div>
  );
};

export { SelectInvoiceProposalTotalsAs };
