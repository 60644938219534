import { PaymentCalculationType } from "@thrivelot/aws";
import { calculateTotalLaborCost } from "./calculateTotalLaborCost";
import { calculateTotalMaterialCost } from "./calculateTotalMaterialCost";

const calculatePhaseInnerTotal = (payment, calculation, project, noMultiplier = false) => {
  let subtotal = 0;

  const lineItems = project.lineItems || [];
  const plantItems = project.plantItems || [];

  const totalLaborCost = calculateTotalLaborCost(lineItems);
  const totalMaterialCost = calculateTotalMaterialCost(lineItems, plantItems);

  if (calculation) {
    let paymentTotal = 0;

    calculation.forEach((item) => {
      if (item.type === PaymentCalculationType.AMOUNT) paymentTotal += item.amount;
      else if (item.type === PaymentCalculationType.LABOR_PERCENTAGE)
        paymentTotal += (item.amount * totalLaborCost) / 100;
      else paymentTotal += (item.amount * totalMaterialCost) / 100;
    });

    if (noMultiplier) subtotal += paymentTotal;
    else {
      const multiplier = payment.markup + 1;
      subtotal += paymentTotal * multiplier;
    }
  }

  return subtotal;
};

export { calculatePhaseInnerTotal };
