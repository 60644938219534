import { useQueryParam } from '@thrivelot/hooks';
import React, { useMemo, useCallback } from 'react';
import { modals } from '../modals';

const RoutingModals = () => {
  const { param: modal, setParam } = useQueryParam('modal');

  const handleClose = useCallback(() => {
    setParam();
  }, [setParam]);

  const modalComponent = useMemo(() => {
    let component = null;

    if (modal) {
      const { path, ...rest } = modal;
      const ModalComponent = modals[path];

      if (!ModalComponent)
        throw new Error(
          'Could not find Modal Component. You probably forgot to add the component modals/index.js'
        );

      component = (
        <ModalComponent key={path} onClose={handleClose} {...(rest || {})} />
      );
    }

    return [component];
  }, [modal, handleClose]);

  return modalComponent;
};

export { RoutingModals };
