import { useModel } from '@thrivelot/hooks';
import { Minus, LeafLoader } from '@thrivelot/stories';
import { transformCurrencyToString } from '@thrivelot/utils';
import React, { useMemo } from 'react';
import { RowItemKindTotal } from './RowItemKindTotal';
import { useFeaturesMap } from './useFeaturesMap';

const ViewItemCombinedTotals = ({
  projectId,
  payment = {},
  descriptionMap = {},
}) => {
  const { model: project, loaded } = useModel({
    modelName: 'Project',
    id: projectId,
  });
  const { map: featuresMap } = useFeaturesMap({ project });

  const itemTotals = useMemo(() => {
    const result = {
      itemsEstimatedTotalAmount: null, // "Estimated" because its only used if totalAmount is not in payment prop so its not coming from the source of truth payment object
      equipmentItemsTotalAmount: 0,
      laborItemsTotalAmount: 0,
      materialItemsTotalAmount: 0,
      plantItemsTotalAmount: 0,
      discountItems: [],
    };

    payment?.items?.forEach((item) => {
      if (!payment?.totalAmount) {
        result.itemsEstimatedTotalAmount =
          (result.itemsEstimatedTotalAmount || 0) + item.totalAmount;
      }

      if (item.type === 'discount') {
        return result.discountItems.push(item);
      }

      if (item.isHalf) {
        result[`${item.kind}IsHalf`] = true;
      }

      result[`${item.kind}ItemsTotalAmount`] =
        (result[`${item.kind}ItemsTotalAmount`] || 0) + item.totalAmount;
    });

    return result;
  }, [payment?.items, payment?.totalAmount]);

  const {
    itemsEstimatedTotalAmount,
    equipmentItemsTotalAmount,
    laborItemsTotalAmount,
    materialItemsTotalAmount,
    plantItemsTotalAmount,
  } = itemTotals;
  const {
    totalAmount,
    creditItem,
    applyCredit,
    preCreditSubtotal,
    processingFeeAmount,
  } = payment;

  if (!loaded) return <LeafLoader />;

  const getActualTotal = () => {
    if (processingFeeAmount) return totalAmount - processingFeeAmount;
    return totalAmount || itemsEstimatedTotalAmount;
  };

  return (
    <div>
      <div className="flex flex-col gap-10 mb-10">
        {Object.keys(descriptionMap).map((featureId) => (
          <div key={featureId}>
            <div className="font-bold text-xl mb-2 text-center">
              {featuresMap[featureId]?.name}
            </div>
            {descriptionMap[featureId] && (
              <div className="pb-6 pt-4 whitespace-pre-line">
                {descriptionMap[featureId]}
              </div>
            )}
          </div>
        ))}
      </div>

      <div className="divide-y divide-tan-light">
        {Math.abs(materialItemsTotalAmount) > 0 && (
          <RowItemKindTotal kind="material" total={materialItemsTotalAmount} />
        )}
        {Math.abs(equipmentItemsTotalAmount) > 0 && (
          <RowItemKindTotal
            kind="equipment"
            total={equipmentItemsTotalAmount}
          />
        )}
        {Math.abs(laborItemsTotalAmount) > 0 && (
          <RowItemKindTotal
            kind="labor"
            amountDescription={itemTotals.laborIsHalf && '50%'}
            total={laborItemsTotalAmount}
          />
        )}
        {Math.abs(plantItemsTotalAmount) > 0 && (
          <RowItemKindTotal kind="plant" total={plantItemsTotalAmount} />
        )}

        {applyCredit && (preCreditSubtotal || 0) > 0 && (
          <div className="flex py-3 items-center text-lg">
            <div className="flex-1 font-bold ml-2">Subtotal</div>
            <div className="font-bold">
              {transformCurrencyToString(preCreditSubtotal)}
            </div>
          </div>
        )}

        {applyCredit && (creditItem?.creditAmount || 0) > 0 && (
          <>
            <div className="flex py-3 items-center">
              <Minus size={16} color="red-main" />
              <div className="flex-1 font-bold ml-2">
                {creditItem?.name || 'Thrive Lot Credit'}
              </div>
              <div className="text-red-main">
                -{transformCurrencyToString(creditItem?.creditAmount)}
              </div>
            </div>
          </>
        )}

        <div className="flex py-3 text-green-dark items-center text-lg">
          <div className="flex-1 font-bold ml-2">Total</div>
          <div className="font-bold">
            {transformCurrencyToString(getActualTotal())}
          </div>
        </div>
      </div>
    </div>
  );
};

export { ViewItemCombinedTotals };
