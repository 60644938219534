import React from 'react';

const Cancel = ({
  color = 'green-main',
  size = 30,
  styleProps = '',
  ...rest
}) => (
  <svg
    width={size.toString()}
    height={size.toString()}
    viewBox="0 0 75 75"
    className={`fill-${color} ${styleProps}`}
    {...rest}
  >
    <path d="M37.5 75C16.8269 75 0 58.1731 0 37.5C0 16.8269 16.8269 0 37.5 0C58.1731 0 75 16.8269 75 37.5C75 58.1731 58.1731 75 37.5 75ZM37.5 5.76923C20 5.76923 5.76923 20 5.76923 37.5C5.76923 55 20 69.2308 37.5 69.2308C55 69.2308 69.2308 55 69.2308 37.5C69.2308 20 55 5.76923 37.5 5.76923Z" />
    <path d="M24.5187 53.8462C23.6534 53.8462 22.7976 53.5193 22.1437 52.8559C20.8264 51.5386 20.8264 49.4136 22.1437 48.0962L48.1053 22.1347C49.4226 20.8174 51.5476 20.8174 52.8649 22.1347C54.1822 23.452 54.1822 25.577 52.8649 26.8943L26.9034 52.8559C26.2399 53.5193 25.3841 53.8462 24.5187 53.8462Z" />
    <path d="M50.4803 53.8462C49.6149 53.8462 48.7591 53.5193 48.1053 52.8559L22.1437 26.8943C20.8264 25.577 20.8264 23.452 22.1437 22.1347C23.4611 20.8174 25.586 20.8174 26.9034 22.1347L52.8649 48.0962C54.1822 49.4136 54.1822 51.5386 52.8649 52.8559C52.2014 53.5193 51.3457 53.8462 50.4803 53.8462Z" />
  </svg>
);

export { Cancel };
