import React from "react";
import { useMobileDifference } from "@thrivelot/hooks";
import { styleConstants } from "../../../../helpers";
import { IconHashmap } from "./IconHashmap";

// functional component
const Icon = ({ section }) => {
  // hooks
  const iconSize = useMobileDifference(styleConstants.iconSize);
  // components
  const SvgIcon = IconHashmap[section];
  return <SvgIcon color={styleConstants.iconColor} size={iconSize} />;
};

export { Icon };
