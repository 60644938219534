import React from "react";
import { useParams } from "react-router-dom";
import { useModel } from "@thrivelot/hooks";
import { Button, PageContainer } from "@thrivelot/stories";
import { CostPaymentSchedule } from "../../../pages";
import { HelpPrompt } from "../..";
import { isAdminApp } from "@thrivelot/utils";
import { constructProject } from "@thrivelot/common";

const PageProjectPaymentSchedule = () => {
  const { projectId } = useParams();
  const { model: project, loaded, updateModel, actions } = useModel({ modelName: "Project", id: projectId });

  return (
    <PageContainer
      icon="Cost"
      header="Payment Schedule"
      documentTitle={loaded && project.details.title}
      help={
        <HelpPrompt header="About the Project Phases page">
          The Payment Schedule serves as both a record and final step prior to invoicing Customers. You can also see the
          history of paid invoices here, too.
        </HelpPrompt>
      }
      headerActions={
        loaded &&
        isAdminApp() && (
          <div>
            {!(project?.costPaymentSchedule || []).find((p) => p.type === "DESIGN") &&
              !(project?.costPaymentSchedule || []).find((p) => p.type === "SITE_VISIT") && (
                <Button
                  icon="Plus"
                  color="blue-main"
                  kind="outline"
                  size="small"
                  label="Seed Default Payments"
                  onClick={() => {
                    const payments = constructProject({}).costPaymentSchedule;
                    const existingPayments = project?.costPaymentSchedule || [];

                    return updateModel(actions.set("costPaymentSchedule", [...payments, ...existingPayments]).result);
                  }}
                />
              )}
          </div>
        )
      }
    >
      <CostPaymentSchedule projectId={projectId} />
    </PageContainer>
  );
};

export { PageProjectPaymentSchedule };
