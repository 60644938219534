import { useCanCan, useModal, useModel, useFiles } from '@thrivelot/hooks';
import { Button, Card, Pdf, Info, File, Input } from '@thrivelot/stories';
import { useMeasure } from 'react-use';
import React, { useEffect, useState, useCallback } from 'react';
import TextareaAutosize from 'react-textarea-autosize';
import Image from 'react-graceful-image';
import { Document, Page } from 'react-pdf';
import { Confirm } from '../Confirm';

const CardProjectFile = ({ projectId, file }) => {
  const { openModal } = useModal();
  const [loaded, setLoaded] = useState();
  const [signedUrl, setSignedUrl] = useState();
  const {
    model: project,
    updateModel,
    actions,
  } = useModel({ modelName: 'Project', id: projectId });
  const { fetchUrl } = useFiles();
  const { canEdit } = useCanCan(project);

  const [ref, { width }] = useMeasure();

  useEffect(() => {
    const fetchSignedUrl = async () => {
      const { data } = await fetchUrl(file.file.key);
      setSignedUrl(data);
    };
    fetchSignedUrl();
  }, [fetchUrl, file?.file?.key]);

  const deleteFile = useCallback(() => {
    updateModel(actions.remove(`files[id:${file.id}]`).result);
  }, [updateModel, file.id, actions]);

  if (!signedUrl)
    return (
      <Card classAppend="mb-4 flex items-center justify-center">
        <File size={30} color="brown-light" styleProps="animate-pulse" />
      </Card>
    );

  return (
    <Card classAppend="mb-4">
      <div className="flex flex-col items-center justify-center relative">
        {canEdit && loaded && (
          <div className="absolute top-2 right-2 z-10">
            <Confirm
              prompt="Are you sure you want to delete this file?"
              onConfirm={deleteFile}
            >
              <Button icon="Trash" size="small" color="red-main" />
            </Confirm>
          </div>
        )}
        <div
          ref={ref}
          style={{ height: width }}
          className="rounded overflow-hidden w-full"
        >
          {/image/.test(file.file.type) ? (
            <div
              onClick={() => {
                openModal({
                  path: 'ModalViewFiles',
                  projectId,
                  fileId: file.id,
                });
                // openModal({
                //   path: 'ModalViewImage',
                //   modelName: 'Project',
                //   modelId: projectId,
                //   filePath: 'files',
                //   fileId: file.id,
                // });
              }}
            >
              <Image
                className="w-full min-h-28 hover:cursor-pointer"
                src={signedUrl}
                alt={file.file.filename}
                onLoad={() => setLoaded(true)}
              />
            </div>
          ) : (
            <div
              className="w-full cursor-pointer"
              onClick={() => {
                openModal({
                  path: 'ModalViewFiles',
                  projectId,
                  fileId: file.id,
                });
                // openModal({
                //   path: 'ModalViewPdf',
                //   modelName: 'Project',
                //   modelId: projectId,
                //   filePath: 'files',
                //   fileId: file.id,
                // });
                // const newWindow = window.open();
                // newWindow.document.title = 'Thrive Lot | View PDF';
                // newWindow.document.write(
                //   `<iframe src="${signedUrl}" frameborder="0" style="border:0; top:0px; left:0px; bottom:0px; right:0px; width:100%; height:100%;" allowfullscreen></iframe>`
                // );
              }}
            >
              <Document
                file={signedUrl}
                loading={
                  <Pdf
                    size={30}
                    color="brown-light"
                    styleProps="animate-pulse"
                  />
                }
                noData={<Info size={30} color="yellow-main" />}
                error={<Info size={30} color="red-main" />}
                onLoadSuccess={() => setLoaded(true)}
                onLoadError={() => setLoaded(true)}
              >
                <Page width={width} pageNumber={1} />
              </Document>
            </div>
          )}
        </div>
        {canEdit ? (
          <div className="w-full mt-2">
            <Input
              classAppend="w-full"
              placeholder="Enter name"
              value={file.file?.filename || ''}
              onChange={(e) =>
                updateModel(
                  actions.set(
                    `files[id:${file.id}].file.filename`,
                    e.target.value
                  ).result
                )
              }
            />
            <TextareaAutosize
              className="input mt-2"
              placeholder="Add a note here..."
              minRows={1}
              value={file.caption || ''}
              onChange={(e) =>
                updateModel(
                  actions.set(`files[id:${file.id}].caption`, e.target.value)
                    .result
                )
              }
            />
          </div>
        ) : (
          <div className="mt-3 w-full">
            <div className="font-bold w-full">{file.file?.filename}</div>
            <div className="w-full">{file.caption}</div>
          </div>
        )}
      </div>
    </Card>
  );
};

export { CardProjectFile };
