import React from "react";
import { useTheme } from "@thrivelot/hooks";
import { ArrowLeftIcon, ButtonRaised } from "@thrivelot/styles";
import { useSpacing } from "../../../hooks";

const CloseButton = ({ onClickClose }) => {
  // hooks
  const theme = useTheme();
  const spacing = useSpacing();
  // return
  return (
    <ButtonRaised
      onClick={onClickClose}
      style={{
        position: "absolute",
        top: spacing,
        left: spacing,
        paddingLeft: "8px",
      }}
    >
      <ArrowLeftIcon color={theme.textLight} size={15} style={{ marginRight: "8px" }} />
      Close
    </ButtonRaised>
  );
};

export { CloseButton };
