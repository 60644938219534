import React from 'react';

const Console = ({
  color = 'green-main',
  size = 30,
  styleProps = '',
  ...rest
}) => (
  <svg
    width={size.toString()}
    height={size.toString()}
    viewBox="0 0 75 75"
    className={`fill-${color} ${styleProps}`}
    {...rest}
  >
    <path d="M61.7184 75H13.2816C5.96203 75 0 69.038 0 61.7184V13.2816C0 5.96203 5.96203 0 13.2816 0H61.7184C69.038 0 75 5.96203 75 13.2816V61.7184C75 69.038 69.038 75 61.7184 75ZM13.2816 6.64557C9.62658 6.64557 6.64557 9.62658 6.64557 13.2816V61.7184C6.64557 65.3734 9.62658 68.3544 13.2816 68.3544H61.7184C65.3734 68.3544 68.3544 65.3734 68.3544 61.7184V13.2816C68.3544 9.62658 65.3734 6.64557 61.7184 6.64557H13.2816Z" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.8996 14.8996C16.0991 13.7001 18.0438 13.7001 19.2433 14.8996L28.1718 23.8282C29.3713 25.0276 29.3713 26.9724 28.1718 28.1718L19.2433 37.1004C18.0438 38.2999 16.0991 38.2999 14.8996 37.1004C13.7001 35.9009 13.7001 33.9562 14.8996 32.7567L21.6563 26L14.8996 19.2433C13.7001 18.0438 13.7001 16.0991 14.8996 14.8996Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M33 35C33 33.3431 34.3431 32 36 32L58 32C59.6569 32 61 33.3431 61 35C61 36.6569 59.6569 38 58 38L36 38C34.3431 38 33 36.6569 33 35Z"
    />
  </svg>
);

export { Console };
