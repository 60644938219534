import React from 'react';
import { lineItemColorMap, lineItemIconMap } from '@thrivelot/config';
import * as icons from '@thrivelot/stories';

const ItemKindIcon = ({ kind, ...rest }) => {
  const Icon = icons[lineItemIconMap[kind]];
  if (!Icon) return null;
  return <Icon color={lineItemColorMap[kind]} {...rest} />;
};

export { ItemKindIcon };
