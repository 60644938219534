import React, { useCallback, useMemo } from 'react';
import { useMap, useModel } from '@thrivelot/hooks';
import { LeafLoader, Button, Dropdown } from '@thrivelot/stories';
import TextareaAutosize from 'react-textarea-autosize';
import { Confirm } from '../Confirm';
import { ZoneTypeDropdownLabel } from './ZoneTypeDropdownLabel';

const ZoneInfoBox = ({ projectId }) => {
  const { infoBoxId, toggleInfoBox } = useMap();
  const { actions, update, loaded } = useModel({
    modelName: 'Project',
    id: projectId,
  });

  const path = useMemo(() => `zones[id:${infoBoxId}]`, [infoBoxId]);

  const zone = useMemo(() => actions.get(path), [actions, path]);

  const selectZoneType = useCallback(
    (zoneType) => () => {
      update(actions.set(`${path}.type`, zoneType).result);
    },
    [update, path, actions]
  );

  const updateValue = useCallback(
    (name) => (e) => {
      update(actions.set(`${path}.${name}`, e.target.value).result);
    },
    [update, actions, path]
  );

  const options = useMemo(
    () =>
      ['1', '2', '3', '4', '5'].map((zoneType) => ({
        label: zoneType,
        selected: zone?.type === zoneType,
        onClick: selectZoneType(`_${zoneType}`),
      })),
    [zone?.type, selectZoneType]
  );

  if (!loaded) return <LeafLoader />;

  return (
    <div>
      <div className="flex flex-row items-center mb-2">
        <input
          className="input mr-2"
          placeholder={'Name'}
          value={zone?.name || ''}
          onChange={updateValue('name')}
        />
        <Dropdown
          label={<ZoneTypeDropdownLabel zoneType={zone?.type} />}
          options={options}
          align="right"
          optionClass="font-bold"
          selectedColor="blue-main"
          dropdownClassAppend="w-[58px]"
        />
      </div>
      <TextareaAutosize
        className="input mb-6"
        placeholder="Description..."
        value={zone?.description || ''}
        minRows={2}
        onChange={updateValue('description')}
      />
    </div>
  );
};

export { ZoneInfoBox };
