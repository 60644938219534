import React from "react";
import SvgContainer from "../SvgWrapper";

const TLGoldBadgeIcon = ({ size, ...rest }) => (
  <SvgContainer
    id="Layer_1"
    data-name="Layer 1"
    width={`${size}`}
    height={`${size}`}
    viewBox="0 0 432.72 432.72"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <defs>
      <linearGradient
        id="linear-gradient"
        x1="456.22"
        y1="225.96"
        x2="331.41"
        y2="343.55"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="#3db491" />
        <stop offset="1" stopColor="#358c64" />
      </linearGradient>
      <linearGradient
        id="linear-gradient-2"
        x1="147.37"
        y1="301.22"
        x2="369.08"
        y2="12.51"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="#74c1e7" />
        <stop offset="0.42" stopColor="#b4def2" />
        <stop offset="0.81" stopColor="#eaf6fb" />
        <stop offset="1" stopColor="#fff" />
      </linearGradient>
      <linearGradient
        id="linear-gradient-3"
        x1="219.15"
        y1="275.7"
        x2="214.19"
        y2="181.39"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="#5a5351" />
        <stop offset="0.47" stopColor="#70645f" />
        <stop offset="1" stopColor="#85736d" />
      </linearGradient>
      <linearGradient
        id="linear-gradient-4"
        x1="481.57"
        y1="261.46"
        x2="210.93"
        y2="318.61"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="#3db491" />
        <stop offset="0.28" stopColor="#3cb08c" />
        <stop offset="0.61" stopColor="#3aa37e" />
        <stop offset="0.97" stopColor="#358e67" />
        <stop offset="1" stopColor="#358c64" />
      </linearGradient>
      <linearGradient
        id="linear-gradient-5"
        x1="461.89"
        y1="312.48"
        x2="302.92"
        y2="318.39"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="#3db491" />
        <stop offset="0.28" stopColor="#3cb08c" />
        <stop offset="0.62" stopColor="#3aa37e" />
        <stop offset="0.98" stopColor="#358d66" />
        <stop offset="1" stopColor="#358c64" />
      </linearGradient>
      <linearGradient
        id="linear-gradient-6"
        x1="217.34"
        y1="188.66"
        x2="215.37"
        y2="403.46"
        xlinkHref="#linear-gradient"
      />
      <linearGradient
        id="linear-gradient-7"
        x1="111.7"
        y1="304.37"
        x2="183.54"
        y2="304.37"
        xlinkHref="#linear-gradient"
      />
      <linearGradient
        id="linear-gradient-8"
        x1="57.93"
        y1="317.97"
        x2="158.03"
        y2="317.97"
        xlinkHref="#linear-gradient"
      />
      <linearGradient
        id="linear-gradient-9"
        x1="44.23"
        y1="327.15"
        x2="102.25"
        y2="327.15"
        xlinkHref="#linear-gradient"
      />
    </defs>
    <circle cx="216" cy="216" r="215.1" style={{ fill: "#e9c487" }} />
    <path
      d="M366.27,288.39c8,1.49,15.31,3.13,21.48,4.69A188.83,188.83,0,0,1,329.68,366h0C348.22,342.09,359.51,312.84,366.27,288.39Z"
      style={{ fill: "url(#linear-gradient)" }}
    />
    <path
      d="M341.85,244.44V187.5a12.25,12.25,0,0,0-5.34-10.14L222.78,99.6a12,12,0,0,0-13.54,0L95.41,177.43a12,12,0,0,0-5.26,9.95v57.07c-7.62-1.58-14.38-2.71-19.84-3.5a10.82,10.82,0,0,0-12.24,12.42,327.42,327.42,0,0,0,7.63,35c-8,1.48-15.3,3.13-21.47,4.69A187.38,187.38,0,0,1,27.78,216c0-103.94,84.28-188.2,188.22-188.2S404.22,112.05,404.22,216a187.43,187.43,0,0,1-16.47,77.09c-6.17-1.56-13.44-3.2-21.48-4.69a325.58,325.58,0,0,0,7.65-35.15,10.74,10.74,0,0,0-12.14-12.31C356.3,241.72,349.5,242.86,341.85,244.44Z"
      style={{ fill: "url(#linear-gradient-2)" }}
    />
    <path
      d="M341.85,244.44c-7,1.46-14.7,3.3-22.81,5.62a323.52,323.52,0,0,0-4.76-33.13,10.62,10.62,0,0,0-15.46-7.29,317.21,317.21,0,0,0-50.34,33.71,324.89,324.89,0,0,0-23.78-37.44,10.78,10.78,0,0,0-17.39,0,326.4,326.4,0,0,0-23.77,37.44,315.93,315.93,0,0,0-50.32-33.69,10.65,10.65,0,0,0-15.5,7.32,324,324,0,0,0-4.76,33.1c-8.11-2.34-15.84-4.16-22.81-5.63V187.38a12,12,0,0,1,5.26-9.95L209.24,99.6a12,12,0,0,1,13.54,0l113.73,77.76a12.25,12.25,0,0,1,5.34,10.14Z"
      style={{ fill: "url(#linear-gradient-3)" }}
    />
    <path
      d="M319,250.06c4,43.37.75,106.25-42.2,142.7-.61.53-1.25,1.06-1.91,1.58l.13.41a182.31,182.31,0,0,1-21,5.61,105.18,105.18,0,0,0,11.65-19.66c21.37-47.05,3-100-17.19-137.35a317.21,317.21,0,0,1,50.34-33.71,10.62,10.62,0,0,1,15.46,7.29A323.52,323.52,0,0,1,319,250.06Z"
      style={{ fill: "url(#linear-gradient-4)" }}
    />
    <path
      d="M329.68,366a187,187,0,0,1-54.62,28.74l-.13-.41c.66-.52,1.3-1,1.91-1.58,43-36.45,46.18-99.33,42.2-142.7,8.11-2.32,15.82-4.16,22.81-5.62,7.65-1.58,14.45-2.72,19.93-3.51a10.74,10.74,0,0,1,12.14,12.31,325.58,325.58,0,0,1-7.65,35.15c-6.76,24.45-18,53.7-36.61,77.61Z"
      style={{ fill: "url(#linear-gradient-5)" }}
    />
    <path
      d="M248.48,243.35c20.21,37.39,38.56,90.3,17.19,137.35A105.18,105.18,0,0,1,254,400.36a189.65,189.65,0,0,1-76,0,106.84,106.84,0,0,1-11.65-19.66c-21.37-47.05-3-100,17.21-137.35a326.4,326.4,0,0,1,23.77-37.44,10.78,10.78,0,0,1,17.39,0A324.89,324.89,0,0,1,248.48,243.35Z"
      style={{ fill: "url(#linear-gradient-6)" }}
    />
    <path
      d="M166.33,380.7A106.84,106.84,0,0,0,178,400.36,179.29,179.29,0,0,1,158,395.1c-1-.77-1.95-1.56-2.87-2.34C112.22,356.31,109,293.45,113,250.08a324,324,0,0,1,4.76-33.1,10.65,10.65,0,0,1,15.5-7.32,315.93,315.93,0,0,1,50.32,33.69C163.31,280.74,145,333.65,166.33,380.7Z"
      style={{ fill: "url(#linear-gradient-7)" }}
    />
    <path
      d="M155.16,392.76c.92.78,1.88,1.57,2.87,2.34A187.6,187.6,0,0,1,102.25,366c-18.54-23.89-29.81-53.16-36.55-77.59a327.42,327.42,0,0,1-7.63-35A10.82,10.82,0,0,1,70.31,241c5.46.79,12.22,1.92,19.84,3.5,7,1.47,14.7,3.29,22.81,5.63C109,293.45,112.22,356.31,155.16,392.76Z"
      style={{ fill: "url(#linear-gradient-8)" }}
    />
    <path
      d="M102.25,366a188.64,188.64,0,0,1-58-72.9c6.17-1.56,13.43-3.21,21.47-4.69C72.44,312.79,83.71,342.06,102.25,366Z"
      style={{ fill: "url(#linear-gradient-9)" }}
    />
  </SvgContainer>
);

export default TLGoldBadgeIcon;
