// import exampleProjects from "../assets/examples/exampleProjects";
import { duplicateObject, sortComplex } from "@thrivelot/utils";
import { ProjectMilestoneStage } from "@thrivelot/aws";

const sortProjects = ({
  projects,
  // showExamples,
  showAssigned = false,
  supplierFilter = null,
}) => {
  const unsortedProjects = {
    EXAMPLE: [],
    [ProjectMilestoneStage.OPEN_PROJECT]: [],
    ASSIGNED: [],
    [ProjectMilestoneStage.SITE_ASSESSMENT]: [],
    [ProjectMilestoneStage.DETAILED_PLAN]: [],
    [ProjectMilestoneStage.INSTALLATION]: [],
    [ProjectMilestoneStage.STEWARDSHIP]: [],
    [ProjectMilestoneStage.CHURN]: [],
  };

  const sortableProjects = duplicateObject(projects);
  sortableProjects.forEach((project) => {
    if (
      showAssigned &&
      project.currentMilestone === ProjectMilestoneStage.OPEN_PROJECT &&
      project.owners &&
      project.owners.length > 0
    ) {
      if (!supplierFilter || project?.owners?.includes(supplierFilter)) {
        unsortedProjects.ASSIGNED.push(project);
      }
    } else if (Object.keys(unsortedProjects).includes(project.currentMilestone)) {
      if (!supplierFilter) {
        unsortedProjects[project.currentMilestone].push(project);
      } else if (project.owners && project?.owners?.includes(supplierFilter)) {
        unsortedProjects[project.currentMilestone].push(project);
      }
    }
  });

  const sortedProjects = {};
  Object.keys(unsortedProjects).forEach((key) => {
    if (unsortedProjects[key].length > 0) {
      sortedProjects[key] = sortComplex({
        arr: unsortedProjects[key],
        nestedBy: ["details", "title"],
        backupNestedBy: ["customer", "firstname"],
      });
    }
  });

  // if (showExamples) {
  //   sortedProjects.EXAMPLE = [
  //     exampleProjects.OPEN_PROJECT,
  //     exampleProjects.SITE_ASSESSMENT,
  //     exampleProjects.DETAILED_PLAN,
  //   ];
  // }

  return sortedProjects;
};

export { sortProjects };
