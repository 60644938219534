import React from 'react';

const Home = ({
  color = 'green-main',
  size = 30,
  styleProps = '',
  ...rest
}) => (
  <svg
    width={size.toString()}
    height={size.toString()}
    viewBox="0 0 75 75"
    className={`fill-${color} ${styleProps}`}
    {...rest}
  >
    <path d="M65.4696 73.2751H9.52095C4.27161 73.2751 0 69.0035 0 63.7542V31.6601C0 28.5086 1.54727 25.5755 4.14821 23.8004L32.1225 4.67305C35.3879 2.44232 39.6121 2.44232 42.8775 4.67305L70.8518 23.8004C73.4527 25.5755 75 28.5181 75 31.6601V63.7542C74.9905 69.0035 70.7189 73.2751 65.4696 73.2751ZM37.4953 9.65659C36.9257 9.65659 36.3657 9.82746 35.872 10.1597L7.89773 29.287C7.10986 29.8186 6.64473 30.7109 6.64473 31.6601V63.7542C6.64473 65.3394 7.9357 66.6304 9.52095 66.6304H65.4601C67.0453 66.6304 68.3363 65.3394 68.3363 63.7542V31.6601C68.3363 30.7109 67.8711 29.8186 67.0833 29.287L39.1185 10.1597C38.6249 9.82746 38.0648 9.65659 37.4953 9.65659Z" />
  </svg>
);

export { Home };
