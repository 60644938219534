import { constructUuid } from "@thrivelot/utils";
import { constructAddress } from "./constructAddress";
import { constructLinks } from "./constructLinks";
import { constructProfilePic } from "./constructProfilePic";
import { constructQualifications } from "./constructQualifications";

const constructSelf = ({
  id = constructUuid(),
  email = "",
  phoneNumber = "",
  firstname = "",
  lastname = "",
  businessName = "",
  bio = "",
  stripeId = "",
  address = constructAddress(),
  qualifications = constructQualifications(),
  links = constructLinks(),
  pic = constructProfilePic(),
  owners = [],
  viewers = [],
}) => ({
  id,
  email,
  phoneNumber,
  firstname,
  lastname,
  businessName,
  bio,
  stripeId,
  address,
  qualifications,
  links,
  pic,
  owners,
  viewers,
});

export { constructSelf };
