import { useMemo } from 'react';
import { useAuth } from '../useAuth';

const useCanCan = (model) => {
  const { groups, user } = useAuth();

  const canEdit = useMemo(
    () => groups?.includes('admin') || model?.owners?.includes(user.username),
    [model?.owners, groups, user?.username]
  );
  const canView = useMemo(
    () => groups?.includes('admin') || model?.viewers?.includes(user.username),
    [model?.viewers, groups, user?.username]
  );
  const isAdmin = useMemo(() => groups?.includes('admin'), [groups]);

  return { canEdit, canView, isAdmin };
};

export { useCanCan };
