import { useRef } from "react";
import { theme as themeConfig } from "./theme";

const useTheme = () => {
  const theme = useRef(themeConfig);

  return theme.current;
};

export { useTheme };
