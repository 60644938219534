import styled from "styled-components";
import CssRaisedShadow from "./CssRaisedShadow";
import CssInsetShadow from "./CssInsetShadow";
import CssInputDefault from "./CssInputDefault";

const InputBubble = styled.input`
  ${CssInputDefault}
  ${CssInsetShadow}
  @media (max-width: 767px) {
    font-size: 14px;
  }
  @media (min-width: 768px) {
    font-size: 17px;
  }
  background-color: ${({ theme }) => theme.inputBackground};
  font-family: Rokkitt-Bold;
  min-height: 33px;
  max-height: 33px;
  border-radius: 5px;
  padding-left: 8px;
  padding-right: 5px;
  display: flex;
  align-items: center;
  :focus {
    ${CssRaisedShadow}
    outline: none !important;
    background-color: ${({ theme }) => theme.inputBackgroundFocused};
  }
`;

export default InputBubble;
