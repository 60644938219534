// ! THIS IS DEPRECATED NOW BECAUSE ALL LINE ITEM CATALOG RESULTS HAVE BEEN COMBINED INTO ONE LIST WITH useModelCatalog
// ! Remove this file when features have been refactored to hide line items

import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useModelList } from '@thrivelot/hooks';
import {
  filterCatalogItemsByQuery,
  filterUniqueSearchResults,
  getAllCatalogItemsByType,
  normalizeSearchResults,
  sortSearchResults,
} from '../helpers';

const useModelLineItemCatalog = (props = { deferLoadOfProjects: false }) => {
  const {
    models: projects,
    queryModels: queryProjects,
    loading: loadingProjects,
    loaded: loadedProjects,
  } = useModelList({
    modelName: 'Project',
    deferLoad: props.deferLoadOfProjects,
  });

  const [query, setQuery] = useState('');
  const [lineItems, setLineItems] = useState([]);

  const allUniqueLineItemsFromProjects = useMemo(
    () =>
      sortSearchResults(
        filterUniqueSearchResults(
          normalizeSearchResults(
            getAllCatalogItemsByType(projects, 'lineItems'),
            'lineItem'
          )
        )
      ),
    [projects]
  );

  const queryRef = useRef();
  const lineItemsRef = useRef();
  const allUniqueLineItemsFromProjectsRef = useRef();

  queryRef.current = query;
  lineItemsRef.current = lineItems;
  allUniqueLineItemsFromProjectsRef.current = allUniqueLineItemsFromProjects;

  const queryLineItems = useCallback(() => {
    const staticQuery = queryRef.current || '';

    if (staticQuery.length < 3) setLineItems([]);
    else
      setLineItems(
        filterCatalogItemsByQuery(
          allUniqueLineItemsFromProjectsRef.current,
          staticQuery
        )
      );
  }, []);

  useEffect(() => {
    queryLineItems();
  }, [queryLineItems, query, loadedProjects]);

  return {
    query,
    setQuery,
    lineItems,
    queryProjects,
    loadingProjects,
    loadedProjects,
  };
};

export { useModelLineItemCatalog };
