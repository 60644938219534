import { calculateTotals } from './calculateTotals';
import { metaMap } from './metaMap';

const constructItemsWithHalfLabor = (items) =>
  items.map((item) => {
    const { type, kind } = item;

    if (type === 'line' && kind === 'labor') {
      return {
        ...item,
        isHalf: true,
        originalTotals: {
          priceSubtotal: item.priceSubtotal,
          houseSubtotal: item.houseSubtotal,
          redistributeSubtotal: item.redistributeSubtotal,
          totalAmount: item.totalAmount,
        },
        priceSubtotal: item.halfPriceSubtotal,
        houseSubtotal: item.halfHouseSubtotal,
        redistributeSubtotal: item.halfRedistributeSubtotal,
        totalAmount: item.halfTotalAmount,
      };
    }

    return item;
  });

const constructSummaryTimeMaterialsBilling = ({
  bundles,
  items,
  cacheMap,
  creditItem,
  housePercent,
  paymentMethod,
}) => {
  const laborHalfItems = constructItemsWithHalfLabor(items);
  const firstItems = laborHalfItems.filter(
    ({ type, kind }) => type === 'line' || type === 'discount'
  );
  const secondItems = laborHalfItems.filter(({ kind, type }) => {
    if (type === 'change') return true;
    if (type === 'line' && kind === 'labor') return true;
    return false;
  });

  return {
    items,
    payments: [
      {
        label: 'time_materials_payment_1',
        nextInvoiceProposalStatus: 'active',
        stripeId: cacheMap.time_materials_payment_1?.stripeId,
        ...metaMap.time_materials_payment_1,
        ...calculateTotals({
          items: firstItems,
          cache: cacheMap.time_materials_payment_1,
          housePercent,
          creditItem,
          applyCredit: true,
          paymentMethod,
        }),
        stripeObjectCache: cacheMap.time_materials_payment_1?.stripeObjectCache,
        stripeObjectStatus: cacheMap.time_materials_payment_1?.stripeObjectStatus,
        invoiceSentAt: cacheMap.time_materials_payment_1?.invoiceSentAt,
      },
      {
        label: 'time_materials_payment_2',
        nextInvoiceProposalStatus: 'complete',
        stripeId: cacheMap.time_materials_payment_2?.stripeId,
        ...metaMap.time_materials_payment_2,
        ...calculateTotals({
          items: secondItems,
          cache: cacheMap.time_materials_payment_2,
          housePercent,
          creditItem,
          paymentMethod,
        }),
        stripeObjectCache: cacheMap.time_materials_payment_2?.stripeObjectCache,
        stripeObjectStatus: cacheMap.time_materials_payment_2?.stripeObjectStatus,
        invoiceSentAt: cacheMap.time_materials_payment_2?.invoiceSentAt,
      },
    ],
    payouts: [
      ...bundles.map(({ supplierPayeeId, lineItems, changeItems }) => {
        const { priceSubtotal } = calculateTotals({
          items: cacheMap.time_materials_payment_1?.items || firstItems, // Needs to be the payment items
        });

        return {
          label: 'time_materials_payout_1',
          paymentLabel: 'time_materials_payment_1',
          ...metaMap.time_materials_payout_1,

          supplierPayeeId,
          priceSubtotal:
            cacheMap[`time_materials_payout_1:${supplierPayeeId}`]
              ?.priceSubtotal || priceSubtotal,
          totalAmount:
            cacheMap[`time_materials_payout_1:${supplierPayeeId}`]
              ?.totalAmount || priceSubtotal,
          paidAt:
            cacheMap[`time_materials_payout_1:${supplierPayeeId}`]?.paidAt ||
            null,
        };
      }),
      ...bundles.map(({ supplierPayeeId, lineItems, changeItems }) => {
        const { priceSubtotal } = calculateTotals({
          items: cacheMap.time_materials_payment_2?.items || secondItems, // Needs to be the payment items
        });

        return {
          label: 'time_materials_payout_2',
          paymentLabel: 'time_materials_payment_2',
          ...metaMap.time_materials_payout_2,

          supplierPayeeId,
          priceSubtotal:
            cacheMap[`time_materials_payout_2:${supplierPayeeId}`]
              ?.priceSubtotal || priceSubtotal,
          totalAmount:
            cacheMap[`time_materials_payout_2:${supplierPayeeId}`]
              ?.totalAmount || priceSubtotal,
          paidAt:
            cacheMap[`time_materials_payout_2:${supplierPayeeId}`]?.paidAt ||
            null,
        };
      }),
    ],
  };
};

export { constructSummaryTimeMaterialsBilling };
