import React from 'react';
import PropTypes from 'prop-types';
import { transformStringToTitleCase } from '@thrivelot/utils';
import * as icons from './icons';
import { getIconNameStrings } from './utils';

export const Descriptor = ({ icon, header, body, classAppend, children }) => {
  const Icon = icon ? icons[icon] : <div />;
  return (
    <div
      className={`border bg-tan-light border-tan-dark p-4 rounded-main ${
        classAppend || ''
      }`}
    >
      {(header || icon) && (
        <div className="flex items-center gap-2 mb-1">
          {icon && <Icon color="brown-dark" size={16} />}
          {header && (
            <div className="text-brown-dark font-bold">
              {transformStringToTitleCase(header)}
            </div>
          )}
        </div>
      )}
      {(body || children) && (
        <div className="text-brown-dark text-sm whitespace-pre-line">
          {body || children}
        </div>
      )}
    </div>
  );
};

Descriptor.propTypes = {
  /**
   * Show an icon in the selector
   */
  icon: PropTypes.oneOf([...getIconNameStrings(), undefined]),

  /**
   * Header text
   */
  header: PropTypes.string,

  /**
   * Text body text
   */
  body: PropTypes.string,

  /**
   * Class string to append to outermost element class
   */
  classAppend: PropTypes.string,
};

Descriptor.defaultProps = {};
