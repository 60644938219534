import { loadStripe } from "@stripe/stripe-js";
import { amplifyEnv } from "@thrivelot/aws";

const constructStripePromise = () => {
  const key =
    amplifyEnv === "maple"
      ? "pk_live_bLAt5zoGQqIFtD8Lf1ravnRf00IfWkod2S"
      : "pk_test_iXVcRBZZIbkkwWwNZ8HoQpm300AWDR4HwC";
  return loadStripe(key);
};

export { constructStripePromise };
