import { isEmpty } from "@thrivelot/utils";
import { get } from "@thrivelot/actions";

const getName = (plant, key) => {
  if (!isEmpty(plant?.[key])) {
    return plant[key];
  }
  return plant?.changes?.find((plantChange) => plantChange.key === key).change;
};

const getPlantName = (model, plantId) => {
  const plant = get(model, `plantItems[id:${plantId}]`);
  const commonName = getName(plant, "commonName");
  if (!isEmpty(commonName)) {
    return commonName;
  }
  const scientificName = getName(plant, "scientificName");
  if (!isEmpty(scientificName)) {
    return scientificName;
  }
  return "No plant name";
};

export { getPlantName };
