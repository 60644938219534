import React from 'react';
import { DragDropContext } from 'react-beautiful-dnd';
import { duplicateObject, sortComplex } from '@thrivelot/utils';
import { useModel } from '@thrivelot/hooks';
import { RowItemLoader } from '@thrivelot/stories';
import {
  handleReorderingFeaturesInWorkPhases,
  getFeatureListForWorkPhases,
  handleMovingFeaturesInWorkPhases,
} from '@thrivelot/common';
import { TextBodyBold } from '@thrivelot/styles';
import { WorkPhase } from './WorkPhase';

const WorkPhases = ({ projectId }) => {
  const { model, updateModel, loaded } = useModel({
    modelName: 'Project',
    id: projectId,
  });

  if (!loaded) return <RowItemLoader />;

  const projectPhases = model?.phases ? [...model.phases] : [];
  if (!projectPhases) return null;

  const lists = getFeatureListForWorkPhases(projectPhases);

  const handleDragEnd = (dragResult) => {
    const { source, destination } = dragResult;
    if (!destination) return;
    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    )
      return;

    const workPhases = duplicateObject(projectPhases);

    if (destination.droppableId === source.droppableId) {
      const result = handleReorderingFeaturesInWorkPhases(
        lists[source.droppableId].list,
        source.index,
        destination.index
      );
      if (!result) return;

      workPhases[lists[source.droppableId].index].orderedChildTagIds = result;
    } else {
      const result = handleMovingFeaturesInWorkPhases(
        lists[source.droppableId].list,
        lists[destination.droppableId].list,
        source,
        destination
      );
      if (!result) return;

      result.forEach((item) => {
        workPhases[lists[item.id].index].orderedChildTagIds =
          item.orderedChildTagIds;
      });
    }

    updateModel({ phases: workPhases });
  };

  const sortedPhases = sortComplex({ arr: projectPhases, by: 'startDate' });

  return (
    <div>
      <DragDropContext onDragEnd={handleDragEnd}>
        {sortedPhases.map((phase) => (
          <WorkPhase key={phase.id} phaseId={phase.id} projectId={projectId} />
        ))}
        {sortedPhases.length === 0 && (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '100%',
              minHeight: '60px',
              marginTop: '12px',
            }}
          >
            <TextBodyBold style={{ textAlign: 'center' }}>
              Add work phases to schedule out the project
            </TextBodyBold>
          </div>
        )}
      </DragDropContext>
    </div>
  );
};

export { WorkPhases };
