import React from "react";
import { MapProposal } from "../../../../../common";
import { useSpacing } from "../../../hooks";
import { ZoneInfo } from "./ZoneInfo";

const SitePlanning = () => {
  const spacing = useSpacing();
  const negativeMargin = `-${spacing}`;
  const wrapperStyle = {
    position: "relative",
    height: "calc(100vh - 190px)",
    marginLeft: negativeMargin,
    marginBottom: negativeMargin,
    marginRight: negativeMargin,
  };
  return (
    <div style={wrapperStyle}>
      <MapProposal />
      <ZoneInfo />
    </div>
  );
};

export { SitePlanning };
