import React from "react";
import { useTheme } from "@thrivelot/hooks";
import { transformCurrencyToString } from "@thrivelot/utils";
import { TextBodyBold, TextHeader, DivColumn } from "@thrivelot/styles";
import { RightColumnContainer } from "./RightColumnContainer";

const RightColumn = ({ cost }) => {
  const theme = useTheme();
  return (
    <RightColumnContainer>
      <DivColumn style={{ alignItems: "center" }}>
        <TextBodyBold>Price</TextBodyBold>
        <TextHeader style={{ color: theme.green }}>{transformCurrencyToString(cost)}</TextHeader>
      </DivColumn>
    </RightColumnContainer>
  );
};

export { RightColumn };
