import styled from "styled-components";
import CssDivDefault from "./CssDivDefault";

const DivTooltip = styled.div`
  ${CssDivDefault}
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-left: -12px;
  margin-right: -12px;
  @media (max-width: 767px) {
    max-width: 80vw;
  }
  @media (min-width: 768px) {
    max-width: 350px;
  }
`;

export default DivTooltip;
