import { formatMediumDateTime } from '@thrivelot/date';
import { Checkmark, SingleBilling } from '@thrivelot/stories';
import { transformCurrencyToString } from '@thrivelot/utils';
import React, { useMemo } from 'react';
import { BundleSupplier } from '../FormBundle/BundleSupplier';

const CardPaymentPayout = ({ payment, payouts }) => {
  const {
    name,
    description,
    priceSubtotal,
    housePercent,
    houseSubtotal,
    redistributeSubtotal,
    paidAt,
    receiptUrl,
    totalAmount,
    amountCharged,
    creditItem,
    applyCredit,
  } = payment;

  const paymentPayouts = useMemo(
    () => payouts.filter((payout) => payout.paymentLabel === payment.label),
    [payment, payouts]
  );

  return (
    <div className="md:flex-1 text-brown-dark">
      <div className="h-full flex flex-col justify-between">
        <div className="mb-4">
          <div className="font-bold">{name}</div>
          <div className="text-sm">{description}</div>
        </div>

        <div className="divide-y divide-tan-light">
          <div className="flex justify-between py-1">
            <div>Price Subtotal:</div>
            <div>{transformCurrencyToString(priceSubtotal)}</div>
          </div>
          <div className="flex justify-between py-1">
            <div>ThriveLot Markup</div>
            <div>
              ({housePercent}%)
              {Math.abs(redistributeSubtotal || 0) > 0 &&
                ` (+${transformCurrencyToString(redistributeSubtotal || 0)}) `}
              {transformCurrencyToString(
                houseSubtotal + (redistributeSubtotal || 0)
              )}
            </div>
          </div>

          {applyCredit && creditItem && (
            <div className="flex justify-between py-1">
              <div>Site Visit Credit</div>
              <div>
                -{transformCurrencyToString(creditItem.creditAmount || 0)}
              </div>
            </div>
          )}

          <div className="flex justify-between text-green-main py-1">
            <div>Payment Total:</div>
            <div className="font-bold">
              {amountCharged && amountCharged !== totalAmount ? (
                <>
                  <span className="line-through mr-2 text-brown-dark">
                    {transformCurrencyToString(totalAmount)}
                  </span>
                  {transformCurrencyToString(amountCharged)}
                </>
              ) : (
                <span>{transformCurrencyToString(totalAmount)}</span>
              )}
            </div>
          </div>

          {paidAt && (
            <div className="flex justify-between text-green-dark text-sm py-1">
              <div className="flex gap-1 items-center">
                <Checkmark color="green-dark" size={14} /> <div>Paid</div>{' '}
                {receiptUrl && (
                  <div>
                    {' - '}
                    <a
                      href={receiptUrl}
                      target="_blank"
                      className="text-blue-main hover:text-blue-dark hover:underline hover:cursor-pointer"
                      rel="noreferrer"
                    >
                      View receipt
                    </a>
                  </div>
                )}
              </div>
              <div className="font-bold">{formatMediumDateTime(paidAt)}</div>
            </div>
          )}
        </div>
        <div>
          <div className="text-brown-dark mb-4 flex items-center gap-2 pt-8">
            <SingleBilling color="brown-dark" size={16} />{' '}
            <span className="font-bold">Payouts</span> will be in
            <span className="font-bold">
              {payouts.length} installment
              {payouts.length > 1 ? 's' : ''}
            </span>{' '}
          </div>
          <div className="flex flex-col gap-6">
            {paymentPayouts.map((payout) => (
              <div key={payout.id || payout.label}>
                <div>
                  <div key={payout.label} className="flex justify-between py-1">
                    <BundleSupplier supplierId={payout.supplierPayeeId} />

                    {payout.paidAt && (
                      <div className="flex gap-1 items-center text-green-dark text-sm italic">
                        <Checkmark color="green-dark" size={14} />
                        <div>Paid</div>
                        <div>{formatMediumDateTime(payout.paidAt)}</div>
                      </div>
                    )}
                  </div>
                </div>
                <div className="divide-y divide-tan-light">
                  <div className="flex justify-between text-green-main py-1">
                    <div>Payout Total:</div>
                    <div className="font-bold">
                      {transformCurrencyToString(payout.totalAmount)}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export { CardPaymentPayout };
