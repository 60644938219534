import React from "react";
import { ImgRectangle } from "@thrivelot/styles";

const ImageFile = ({ src }) => (
  <ImgRectangle
    height="100%"
    width="100%"
    src={src}
    style={{
      display: "flex",
      height: "100%",
      width: "100%",
      objectFit: "scale-down",
      alignItems: "center",
      justifyContent: "center",
    }}
  />
);

export { ImageFile };
