import React from 'react';

const Alert = ({
  color = 'green-main',
  size = 30,
  styleProps = '',
  ...rest
}) => (
  <svg
    width={size.toString()}
    height={size.toString()}
    viewBox="0 0 75 75"
    className={`fill-${color} ${styleProps}`}
    {...rest}
  >
    <path d="M38 51C36.3431 51 35 52.3431 35 54C35 55.6569 36.3431 57 38 57C39.6569 57 41 55.6569 41 54C41 52.3431 39.6569 51 38 51Z" />
    <path d="M37.5 75C16.8228 75 0 58.1772 0 37.5C0 16.8228 16.8228 0 37.5 0C58.1772 0 75 16.8228 75 37.5C75 58.1772 58.1772 75 37.5 75ZM37.5 6.64557C20.4873 6.64557 6.64557 20.4873 6.64557 37.5C6.64557 54.5127 20.4873 68.3544 37.5 68.3544C54.5127 68.3544 68.3544 54.5127 68.3544 37.5C68.3544 20.4873 54.5127 6.64557 37.5 6.64557Z" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M38 19C39.6569 19 41 20.3431 41 22V46C41 47.6569 39.6569 49 38 49C36.3431 49 35 47.6569 35 46V22C35 20.3431 36.3431 19 38 19Z"
    />
  </svg>
);

export { Alert };
