import React, { useState } from 'react';
import { useModel } from '@thrivelot/hooks';
import { Button, Checkmark, FilesFolder } from '@thrivelot/stories';
import { LayoutModal } from '../../LayoutModal';

export const ModalAddFileToFolder = ({
  onClose,
  modelId,
  fileIds,
  folderId: existingFolderId,
}) => {
  const [folderId, setFolderId] = useState(existingFolderId);
  const {
    model: project,
    update,
    loaded,
  } = useModel({
    modelName: 'Project',
    id: modelId,
  });

  // eslint-disable-next-line consistent-return
  const addFileToFolder = () => {
    if (folderId === existingFolderId) return onClose();

    const newFolders = (project.fileFolders || []).map((_folder) => {
      const filteredFileIds = (_folder.fileIds || []).filter(
        (id) => !fileIds.includes(id)
      );

      if (_folder.id !== folderId)
        return { ..._folder, fileIds: filteredFileIds };

      return { ..._folder, fileIds: [...filteredFileIds, ...fileIds] };
    });
    update({ fileFolders: newFolders });
    onClose();
  };

  // eslint-disable-next-line consistent-return
  const removeFileFromFolder = () => {
    if (!existingFolderId) return onClose();

    const newFolders = (project.fileFolders || []).map((_folder) => {
      const filteredFileIds = (_folder.fileIds || []).filter(
        (id) => !fileIds.includes(id)
      );

      return { ..._folder, fileIds: filteredFileIds };
    });
    update({ fileFolders: newFolders });
    onClose();
  };

  return (
    <LayoutModal
      onClose={onClose}
      loading={!loaded}
      header={
        <div className="text-lg text-brown-dark italic">
          Move file to folder...
        </div>
      }
      subheader={
        <div className="text-sm text-brown-dark">Select a folder:</div>
      }
      footer={
        <div className="flex items-center justify-end">
          <Button
            label="Submit"
            color="green-main"
            kind="outline"
            onClick={folderId ? addFileToFolder : removeFileFromFolder}
          />
        </div>
      }
    >
      <div className="divide-y divide-tan-light">
        <div
          className="flex px-4 py-2 items-center text-mulch-light bg-white-dark hover:bg-white-light min-w-0 w-full cursor-pointer"
          onClick={() => setFolderId(null)}
        >
          <FilesFolder size={26} color="tan-dark" />
          <div className="ml-3 flex w-full items-center justify-between">
            <div className="text-mulch-dark font-bold">General</div>
            {!folderId ? (
              <div>
                <Checkmark color="green-main" size={24} />
              </div>
            ) : null}
          </div>
        </div>
        {project?.fileFolders?.map((_folder) => (
          <div
            key={_folder.id}
            className="flex px-4 py-2 items-center text-mulch-light bg-white-dark hover:bg-white-light min-w-0 w-full cursor-pointer"
            onClick={() => setFolderId(_folder.id)}
          >
            <FilesFolder size={26} color="tan-dark" />
            <div className="ml-3 flex w-full items-center justify-between">
              <div className="text-mulch-dark font-bold">{_folder.name}</div>
              {folderId === _folder.id ? (
                <div>
                  <Checkmark color="green-main" size={24} />
                </div>
              ) : null}
            </div>
          </div>
        ))}
      </div>
    </LayoutModal>
  );
};
