import React from 'react';
import { useModel, useQueryParam } from '@thrivelot/hooks';
import { Button, PageContainer } from '@thrivelot/stories';
import { Link, useParams } from 'react-router-dom';
import { HelpPrompt, MapProject } from '../..';

const PageProjectMap = () => {
  const { projectId } = useParams();
  const { setParam } = useQueryParam('map');
  const { model: project, loaded } = useModel({
    modelName: 'Project',
    id: projectId,
  });

  return (
    <PageContainer
      icon="Map"
      header="Map"
      documentTitle={loaded && project.details.title}
      headerActions={
        loaded && (
          <div className="flex items-center gap-2">
            <Link to="features" className="mr-2">
              <Button
                icon="Feature"
                kind="outline"
                size="small"
                color="blue-main"
                label="Manage Features"
              />
            </Link>
            <Button
              icon="Home"
              size="small"
              color="blue-main"
              label="Relocate Home"
              kind="outline"
              onClick={() => setParam({ zoneEditingId: 'address' })}
            />
            <Button
              icon="Plus"
              size="small"
              color="blue-main"
              label="Add Zone"
              kind="outline"
              onClick={() => setParam({ addZone: true })}
            />
          </div>
        )
      }
      help={
        <HelpPrompt header="About the Project Map page">
          This section is the interactive mapping tool for locating Areas of
          Work by zone of use for achieving alignment with the Customer.
        </HelpPrompt>
      }
    >
      <MapProject projectId={projectId} />
    </PageContainer>
  );
};

export { PageProjectMap };
