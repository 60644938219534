import styled from "styled-components";

const borderColor = ({ theme }) => theme.divLine;
const SectionContainer = styled.div`
  transition: opacity 0.5s ease-out, box-shadow 0.5s ease-out, color 1s ease-in-out, background-color 1s ease-in-out;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  border: 1px solid ${borderColor};
  @media (max-width: 767px) {
    padding: 12px;
  }
  @media (min-width: 768px) {
    padding: 20px;
  }
`;

export { SectionContainer };
