import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useService } from '../useService';
import { ModelContext } from './ModelContext';
import { useActions } from './useActions';
import { useLoading } from './useLoading';
import { useModelKit } from './useModelKit';
import { useSpecificModel } from './useSpecificModel';

const useModel = ({
  modelName,
  id: initialId,
  path = null,
  model: passedModel = null,
  deferLoad = false,
}) => {
  initialId = passedModel?.id || initialId;

  const { loaded: modelKitLoaded } = useModelKit();
  const { request: getChangelogService } = useService({
    method: 'get',
    level: 'protected',
    path: 'get-changelog',
    deferLoad: true,
  });
  const { request: listChangelogsService } = useService({
    method: 'get',
    level: 'protected',
    path: 'list-changelogs',
    deferLoad: true,
  });

  const {
    models,
    initModel,
    query: queryFn,
    update: updateFn,
    remove: removeFn,
    loading: loadingAll,
    saving,
  } = useContext(ModelContext);

  const [id, setId] = useState(initialId);

  const { fullModel: model, pathModel } = useSpecificModel({
    models,
    modelName,
    id,
    path,
    model: passedModel,
  });

  const actions = useActions(modelName, model);
  const loading = useLoading(loadingAll, id);

  const modelNameRef = useRef(modelName);
  const idRef = useRef(id);
  const passedModelRef = useRef(passedModel);
  const deferLoadRef = useRef(deferLoad);
  const initModelRef = useRef();
  const modelRef = useRef();

  initModelRef.current = initModel;
  modelRef.current = model;

  if (passedModel && !deferLoadRef.current) deferLoadRef.current = true;

  const query = useCallback(
    (_modelName, _id) => {
      if (!modelKitLoaded) return null;

      if (_modelName) modelNameRef.current = _modelName;
      if (_id) {
        setId(_id);
        idRef.current = _id;
      }

      return queryFn(modelNameRef.current, idRef.current);
    },
    [modelKitLoaded, queryFn]
  );

  const remove = useCallback(() => {
    if (!modelKitLoaded) return null;

    return removeFn(modelNameRef.current, idRef.current);
  }, [modelKitLoaded, removeFn]);

  const update = useCallback(
    (updates) => updateFn(modelNameRef.current, idRef.current, updates),
    [updateFn]
  );

  const fetchChangelog = useCallback(
    async (key) => await getChangelogService({ variables: { key } }),
    [getChangelogService]
  );

  const listChangelogs = useCallback(
    async () =>
      listChangelogsService({
        variables: { modelName: modelNameRef.current, modelId: idRef.current },
      }),
    [listChangelogsService]
  );

  useEffect(() => {
    if (passedModelRef.current)
      initModelRef.current(modelNameRef.current, passedModelRef.current);
  }, []);

  useEffect(() => {
    if (!modelKitLoaded || deferLoadRef.current) return;

    queryFn(modelNameRef.current, idRef.current);
  }, [modelKitLoaded, queryFn]);

  return {
    model,
    pathModel,
    initModel,
    actions,
    query,
    queryModel: query,
    remove,
    deleteModel: remove,
    update,
    updateModel: update,
    fetchChangelog,
    listChangelogs,
    saving,
    loading,
    loaded: !!model,
  };
};

export { useModel };
