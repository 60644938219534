import React, { useMemo, useCallback, useState } from 'react';
import { Button, Clock, SingleBilling } from '@thrivelot/stories';
import { formatShortDateTime, formatLongDateTime } from '@thrivelot/date';
import {
  useModal,
  useService,
  useConstructTimestamp,
  useModel,
} from '@thrivelot/hooks';
import {
  transformCurrencyToString,
  transformStringToTitleCase,
} from '@thrivelot/utils';

const paymentMap = {
  invoice: 'Bank Transfer',
  card: 'Card',
};

const CancelInvoicePayment = ({ invoiceProposalId, paymentLabel }) => {
  const { request: handleWireTransfer, loading } = useService({
    method: 'post',
    level: 'protected',
    path: 'handle-wire-transfer',
    deferLoad: true,
  });

  const onSubmit = useCallback(async () => {
    const { data } = await handleWireTransfer({
      variables: { invoiceProposalId, paymentLabel, cancel: true },
    });
  }, [invoiceProposalId, paymentLabel]);

  return (
    <Button
      loading={loading}
      disabled={loading}
      kind="prompt"
      color="orange-main"
      label="Choose another payment method"
      onClick={onSubmit}
    />
  );
};

const ViewPayment = ({
  projectId,
  invoiceProposalId,
  title,
  status,
  payment,
  dueOn,
  disabled,
  approvalTimestamp,
}) => {
  const [method, setMethod] = useState('invoice');

  const description = useMemo(() => {
    if (payment.paidAt) return `Paid ${formatShortDateTime(payment.paidAt)}`;

    if (dueOn === 'approval') {
      if (status === 'approved') return 'Due Now';
      return 'Due on Approval';
    }

    if (dueOn === 'completion') {
      if (status === 'submitted_for_completion')
        return 'Due on Approval of Changes';
      if (status === 'approved_for_completion') return 'Due Now';
      return 'Due on Completion';
    }

    return null;
  }, [dueOn, payment.paidAt, status]);

  const { openModal } = useModal();
  const [approving, setApproving] = useState(false);
  const { construct } = useConstructTimestamp();

  const { model: invoiceProposal, query: queryInvoiceProposal } = useModel({
    modelName: 'InvoiceProposal',
    id: invoiceProposalId,
  });

  const approvedTimestamp = useMemo(
    () =>
      invoiceProposal?.timestamps?.find(
        (timestamp) => timestamp.kind === approvalTimestamp.kind
      ),
    [invoiceProposal?.timestamps]
  );

  const { request: changeInvoiceProposalStatus, loading: changeStatusLoading } =
    useService({
      method: 'post',
      level: 'protected',
      path: 'change-customer-invoice-proposal-status',
      deferLoad: true,
    });

  const handleApproving = useCallback(
    ({ kind, description: desc, status: stat }) =>
      Promise.resolve(setApproving(true))
        .then(() =>
          changeInvoiceProposalStatus({
            variables: {
              invoiceProposalId,
              timestamp: construct({
                kind,
                description: desc,
              }),
              status: stat,
            },
          })
        )
        .then(() => queryInvoiceProposal())
        .then(() => setApproving(false)),
    [
      changeInvoiceProposalStatus,
      construct,
      invoiceProposalId,
      queryInvoiceProposal,
    ]
  );

  const feeAmount = useMemo(() => {
    if (method === 'card' && payment?.ccFeeAmount) return payment.ccFeeAmount;
    return 0;
  }, [method, payment.ccFeeAmount]);

  const feePercent = useMemo(() => {
    if (method === 'card' && payment?.ccFeePercent) return payment.ccFeePercent;
    return 0;
  }, [method, payment.ccFeePercent]);

  const actualTotal = useMemo(() => {
    if (feeAmount > 0) {
      return payment.totalAmount + feeAmount;
    }
    return payment.totalAmount;
  }, [payment.totalAmount, feeAmount]);

  const {
    paidAt,
    receiptUrl,
    stripeObjectCache,
    stripeObjectType,
    stripeObjectStatus,
    amountCharged,
    totalAmount,
    ccFeePercent,
    processingFeePercent,
    processingFeeAmount,
    invoiceSentAt,
    label: paymentLabel,
  } = payment;

  const stripeObject = stripeObjectCache ? JSON.parse(stripeObjectCache) : null;
  const needsVerification =
    stripeObject?.next_action?.type === 'verify_with_microdeposits' || false;
  const verificationUrl =
    stripeObject?.next_action?.verify_with_microdeposits
      ?.hosted_verification_url || '';
  const paymentPending = stripeObject?.status === 'pending' || false;
  const paymentProcessing = stripeObject?.status === 'processing' || false;

  if (invoiceSentAt && stripeObject?.hosted_invoice_url && !paidAt) {
    return (
      <>
        {approvedTimestamp && (
          <div className="text-center">
            <div className="italic">
              Approved on {formatLongDateTime(approvedTimestamp.createdAt)}
            </div>
          </div>
        )}
        <div className="text-center flex flex-col justify-center gap-3">
          <div className="italic">
            Awaiting bank transfer. Please check your email for instructions, or
            click below to view and pay the invoice. You can also use this link
            to check the status of the transfer.
          </div>
          <div className="flex flex-col items-center gap-4">
            <a
              href={stripeObject.hosted_invoice_url}
              target="_blank"
              className="flex justify-center"
            >
              <Button kind="prompt" color="blue-main" label="View Invoice" />
            </a>
            <a
              href="https://thrivelot.notion.site/Thrive-Lot-Support-83c32215c63e43cb87941b5f6b58d56f?pvs=4"
              target="_blank"
              className="text-blue-main  hover:text-blue-dark hover:underline hover:cursor-pointer"
            >
              Learn more about bank transfers
            </a>
            <CancelInvoicePayment
              invoiceProposalId={invoiceProposalId}
              paymentLabel={paymentLabel}
            />
          </div>
        </div>
      </>
    );
  }

  if (stripeObjectStatus === 'split_requested') {
    return (
      <>
        {approvedTimestamp && (
          <div className="text-center">
            <div className="italic">
              Approved on {formatLongDateTime(approvedTimestamp.createdAt)}
            </div>
          </div>
        )}
        <div className="text-center flex flex-col justify-center gap-3">
          <div className="italic">
            You've requested a split payment. You will be contacted with
            instructions on how to complete this payment outside the thrivelot
            website. After it is paid the order will continue normally.
          </div>
          <div className="flex flex-col items-center gap-4">
            <CancelInvoicePayment
              invoiceProposalId={invoiceProposalId}
              paymentLabel={paymentLabel}
            />
          </div>
        </div>
      </>
    );
  }

  if (needsVerification)
    return (
      <>
        {approvedTimestamp && (
          <div className="text-center">
            <div className="italic">
              Approved on {formatLongDateTime(approvedTimestamp.createdAt)}
            </div>
          </div>
        )}
        <div className="border rounded-main p-6 shadow bg-white-dark border-tan-light text-green-dark">
          <div className="flex justify-between italic mb-4">
            <div className="flex gap-1 items-center">
              <Clock size={16} color="green-dark" />
              <div>{description.replace('Paid', 'Initialized')}</div>
            </div>
            <div className="flex gap-1 items-center">
              <SingleBilling size={16} color="green-dark" />
              <div>{title}</div>
            </div>
          </div>
          <div className="text-center">
            <div>
              Amount{' '}
              {amountCharged && amountCharged !== totalAmount ? (
                <>
                  <span className="line-through mr-1">
                    {transformCurrencyToString(totalAmount)}
                  </span>
                  {transformCurrencyToString(amountCharged)}
                </>
              ) : (
                <span>{transformCurrencyToString(totalAmount)}</span>
              )}
            </div>
          </div>
          <div className="text-center mt-6 mb-3 text-brown-dark">
            <div>
              To verify your bank account and initialize your payment, please
              click the button below.
            </div>
          </div>
          <Button
            color={disabled ? 'brown-dark' : 'green-main'}
            block
            disabled={disabled}
            kind={disabled ? 'outline' : undefined}
            onClick={() => window.open(verificationUrl)}
          >
            Verify Bank Account
          </Button>
        </div>
      </>
    );

  if (paymentProcessing || paymentPending)
    return (
      <>
        {approvedTimestamp && (
          <div className="text-center">
            <div className="italic">
              Approved on {formatLongDateTime(approvedTimestamp.createdAt)}
            </div>
          </div>
        )}
        <div className="border rounded-main p-6 shadow bg-white-dark border-tan-light text-green-dark">
          <div className="flex justify-between italic mb-4">
            <div className="flex gap-1 items-center">
              <Clock size={16} color="green-dark" />
              <div>{description}</div>
            </div>
            <div className="flex gap-1 items-center">
              <SingleBilling size={16} color="green-dark" />
              <div>{title}</div>
            </div>
          </div>
          <div className="text-center">
            {(processingFeePercent || 0) > 0 && (
              <div>
                {transformCurrencyToString(totalAmount - processingFeeAmount)} +{' '}
                {processingFeePercent}% Processing Fee
              </div>
            )}
            <div>
              Amount{' '}
              {amountCharged && amountCharged !== totalAmount ? (
                <>
                  <span className="line-through mr-1">
                    {transformCurrencyToString(totalAmount)}
                  </span>
                  {transformCurrencyToString(amountCharged)}
                </>
              ) : (
                <span>{transformCurrencyToString(totalAmount)}</span>
              )}
            </div>
            <div>{transformStringToTitleCase(stripeObject.status)}</div>
          </div>
        </div>
      </>
    );

  if (paidAt)
    return (
      <>
        {approvedTimestamp && (
          <div className="text-center">
            <div className="italic">
              Approved on {formatLongDateTime(approvedTimestamp.createdAt)}
            </div>
          </div>
        )}
        <div className="border rounded-main p-6 shadow bg-white-dark border-tan-light text-green-dark">
          <div className="flex justify-between italic mb-4">
            <div className="flex gap-1 items-center">
              <Clock size={16} color="green-dark" />
              <div>{description}</div>
            </div>
            <div className="flex gap-1 items-center">
              <SingleBilling size={16} color="green-dark" />
              <div>{title}</div>
            </div>
          </div>
          <div className="text-center">
            {(processingFeePercent || 0) > 0 && (
              <div>
                {transformCurrencyToString(totalAmount - processingFeeAmount)} +{' '}
                {processingFeePercent}% Processing Fee
              </div>
            )}
            <div>
              Amount Charged{' '}
              {amountCharged && amountCharged !== totalAmount ? (
                <>
                  <span className="line-through mr-1">
                    {transformCurrencyToString(totalAmount)}
                  </span>
                  {transformCurrencyToString(amountCharged)}
                </>
              ) : (
                <span>{transformCurrencyToString(totalAmount)}</span>
              )}
              {stripeObject?.hosted_invoice_url && (
                <span className="text-sm">
                  {' '}
                  -{' '}
                  <a
                    href={stripeObject.hosted_invoice_url}
                    target="_blank"
                    className="text-blue-main hover:text-blue-dark hover:underline hover:cursor-pointer"
                    rel="noreferrer"
                  >
                    View invoice
                  </a>
                </span>
              )}
              {receiptUrl && (
                <span className="text-sm">
                  {' '}
                  -{' '}
                  <a
                    href={receiptUrl}
                    target="_blank"
                    className="text-blue-main hover:text-blue-dark hover:underline hover:cursor-pointer"
                    rel="noreferrer"
                  >
                    View receipt
                  </a>
                </span>
              )}
            </div>
          </div>
        </div>
      </>
    );

  return (
    <>
      {approvedTimestamp && (
        <div className="text-center">
          <div className="italic">
            Approved on {formatLongDateTime(approvedTimestamp.createdAt)}
          </div>
        </div>
      )}
      <div>
        <div className="flex">
          <div
            className={`flex-1 text-center px-2 py-2 rounded-t-main p-6 ${
              method === 'invoice'
                ? 'text-green-dark bg-white-dark border-x border-t shadow-x shadow-t'
                : '!bg-white-light shadow-b border-b'
            } ${
              disabled
                ? '!bg-[#fff3e0] border-brown-light !text-brown-dark'
                : 'bg-white-dark border-tan-light hover:cursor-pointer'
            }`}
            onClick={() => !disabled && setMethod('invoice')}
          >
            <div className="text-xs">Pay with</div>
            <div className="font-bold">Bank Transfer</div>
            <div className="text-xs">0% Fee</div>
          </div>
          {/* <div
            className={`flex-1 text-center px-2 py-2 rounded-t-main p-6 ${
              method === 'us_bank_account'
                ? 'text-green-dark bg-white-dark border-x border-t shadow-x shadow-t'
                : '!bg-white-light shadow-b border-b'
            } ${
              disabled
                ? '!bg-[#fff3e0] border-brown-light !text-brown-dark'
                : 'bg-white-dark border-tan-light hover:cursor-pointer'
            }`}
            onClick={() => !disabled && setMethod('us_bank_account')}
          >
            <div className="text-xs">Pay with</div>
            <div className="font-bold">ACH</div>
            <div className="text-xs">0% Fee</div>
          </div> */}
          <div
            className={`flex-1 text-center px-2 py-2 rounded-t-main p-6 ${
              method === 'card'
                ? 'text-green-dark bg-white-dark border-x border-t shadow-x shadow-t'
                : '!bg-white-light shadow-b border-b'
            } ${
              disabled
                ? '!bg-[#fff3e0] border-brown-light !text-brown-dark'
                : 'bg-white-dark border-tan-light hover:cursor-pointer'
            }`}
            onClick={() => !disabled && setMethod('card')}
          >
            <div className="text-xs">Pay with</div>
            <div className="font-bold">Card</div>
            <div className="text-xs">{ccFeePercent}% Fee</div>
          </div>
        </div>
        <div
          className={`border-x border-b rounded-b-main shadow p-6 ${
            disabled
              ? 'bg-[#fff3e0] border-brown-light'
              : 'bg-white-dark border-tan-light'
          }`}
        >
          <div className="flex justify-between italic mb-4">
            <div className="flex gap-1 items-center">
              <Clock size={16} color={disabled ? 'brown-dark' : 'green-main'} />
              <div>{description}</div>
            </div>
            <div className="flex gap-1 items-center">
              <SingleBilling
                size={16}
                color={disabled ? 'brown-dark' : 'green-main'}
              />
              <div>{title}</div>
            </div>
          </div>
          <div className="flex flex-col items-center gap-6">
            <div
              className={`${
                disabled ? 'text-brown-dark' : 'text-green-dark'
              } text-sm text-center py-6`}
            >
              <div className="font-bold">Payment Amount:</div>
              {feeAmount > 0 ? (
                <div className="mt-2 text-md">
                  {transformCurrencyToString(totalAmount)} + {feePercent}%
                  Processing Fee
                </div>
              ) : null}
              <div className="mt-2 text-4xl font-bold text-header">
                {amountCharged && amountCharged !== actualTotal ? (
                  <>
                    <span className="line-through mr-1 text-lg">
                      {transformCurrencyToString(actualTotal)}
                    </span>
                    {transformCurrencyToString(amountCharged)}
                  </>
                ) : (
                  <span>{transformCurrencyToString(actualTotal)}</span>
                )}
              </div>
            </div>
            {approvedTimestamp ? (
              <Button
                color={disabled ? 'brown-dark' : 'green-main'}
                block
                disabled={disabled}
                kind={disabled ? 'outline' : undefined}
                onClick={() =>
                  openModal({
                    path: 'ModalInvoiceProposalPayment',
                    projectId,
                    invoiceProposalId,
                    paymentLabel: payment.label,
                    paymentMethod: method,
                  })
                }
              >
                Pay Now with {paymentMap[method]}
              </Button>
            ) : (
              <Button
                color={disabled ? 'brown-dark' : 'green-main'}
                loading={approving || changeStatusLoading}
                block
                disabled={disabled}
                kind={disabled ? 'outline' : undefined}
                onClick={() =>
                  handleApproving(approvalTimestamp).then(() =>
                    openModal({
                      path: 'ModalInvoiceProposalPayment',
                      projectId,
                      invoiceProposalId,
                      paymentLabel: payment.label,
                      paymentMethod: method,
                    })
                  )
                }
              >
                Approve & Pay with {paymentMap[method]}
              </Button>
            )}
          </div>
        </div>
        {!disabled && (
          <div className="flex justify-center mt-4">
            <Button
              kind="prompt"
              color="blue-main"
              label="Request split payment"
              onClick={() =>
                handleApproving(approvalTimestamp).then(() =>
                  openModal({
                    path: 'ModalInvoiceProposalPayment',
                    projectId,
                    invoiceProposalId,
                    paymentLabel: payment.label,
                    paymentMethod: 'split',
                  })
                )
              }
            />
          </div>
        )}
      </div>
    </>
  );
};

export { ViewPayment };
