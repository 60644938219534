import convertPathToArrayByPeriods from "./convertPathToArrayByPeriods";
import findPropertyByPath from "./findPropertyByPath";

const parsePath = (deepCopiedObj, path, action) => {
  path = convertPathToArrayByPeriods(path);
  if (path.length === 1) return action(deepCopiedObj, path[0]);
  if (!path[0].includes("[") && !deepCopiedObj[path[0]]) deepCopiedObj[path[0]] = {};
  const deepCopiedObjProperty = findPropertyByPath(deepCopiedObj, path[0]);
  return parsePath(deepCopiedObjProperty, path.slice(1), action);
};

export default parsePath;
