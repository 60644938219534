import React, { useState } from 'react';
import { Plus, Trash, Input } from '@thrivelot/stories';

const Item = ({ item, onRemove }) => (
  <div className="flex py-1 px-2 mr-2 mb-2 rounded items-center justify-center border-tan-light border">
    {item}
    <div
      className="ml-2 hover:opacity-80 transition-all duration-500 cursor-pointer"
      onClick={() => onRemove(item)}
    >
      <Trash size={16} color="red-main" />
    </div>
  </div>
);

export const MultiSelectTextInput = ({
  label,
  name,
  placeholder,
  items,
  onRemove,
  onAdd,
}) => {
  const [value, setValue] = useState('');

  const disabled = value.trim() === '' || items.includes(value.trim());

  return (
    <div className="mb-2">
      {label && (
        <label
          htmlFor={name}
          className="block font-accent text-mulch-dark text-sm ml-2 mb-0.5"
        >
          {label}
        </label>
      )}
      <div className="relative mb-2">
        <Input
          classAppend="pr-7! pl-2!"
          name={name}
          placeholder={placeholder}
          value={value}
          onChange={(e) => setValue(e.target.value)}
        />
        <div
          className={`absolute right-2 top-1/2 -translate-y-1/2 hover:opacity-80 transition-all duration-500 cursor-pointer ${
            disabled ? 'opacity-50 pointer-events-none' : ''
          }`}
          onClick={() => onAdd(value.trim())}
        >
          <Plus size={24} color="green-main" />
        </div>
      </div>
      <div className="flex flex-wrap">
        {items.map((item) => (
          <Item key={item} item={item} onRemove={onRemove} />
        ))}
      </div>
    </div>
  );
};
