import React from 'react';
import { createRoot } from 'react-dom/client';
import LogRocket from 'logrocket';
import { initAmplify } from '@thrivelot/aws-amplify';
import { initDate } from '@thrivelot/date';
import { AppContexts } from '@thrivelot/components';
import '@thrivelot/styles/index.css';
import '@thrivelot/assets/Poppins-Bold.ttf';
import '@thrivelot/assets/Poppins-ExtraBold.ttf';
import '@thrivelot/assets/Rokkitt-Bold.ttf';
import '@thrivelot/assets/Rokkitt-ExtraBold.ttf';
import App from './App';
import reportWebVitals from './reportWebVitals';
import versionJson from './package-version.json';

window.appConfig = { appName: 'supplier', ...versionJson };

console.log('Supplier Version', window.appConfig.version);

// Initialize LogRocket in production only
if (/thrivelot.com/.test(window.location.hostname)) {
  try {
    LogRocket.init('0ycx7a/thrivelot');
    console.log('LogRocket initialized');
  } catch (e) {
    console.log('LogRocket initialization failed');
    console.error(e);
  }
}

initAmplify();
initDate();

const container = document.getElementById('root');
const root = createRoot(container);
root.render(
  <AppContexts>
    <App />
    <div id="app-modal" />
    <div id="proposal-modal" />
  </AppContexts>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.log);
