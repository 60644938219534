import React, { useEffect, useState } from 'react';
import { useModal, usePlant } from '@thrivelot/hooks';

export const PlantImage = ({ plantId, classAppend, item }) => {
  const { model: plant, loading, getImage } = usePlant({ id: plantId });
  const { openModal } = useModal();
  const [src, setSrc] = useState();

  useEffect(() => {
    if (loading || !plant?.images?.length) return;

    let didCancel;

    const fetchImage = async () => {
      const [image] = plant.images;
      if (!image) return;

      const { url } = await getImage(image.key);
      if (!didCancel && url) setSrc(url);
    };

    fetchImage();

    // eslint-disable-next-line consistent-return
    return () => {
      didCancel = true;
    };
  }, [plant?.images, loading, getImage]);

  if (!src) return null;

  return (
    <div
      className={classAppend}
      onClick={() =>
        src &&
        openModal({
          path: 'ModalViewImage',
          fileUrl: src,
          fileName: item.name,
        })
      }
    >
      <img
        src={src}
        alt={`${item.name} plant`}
        className="h-full object-cover"
      />
    </div>
  );
};
