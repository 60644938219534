import React from 'react';
import { LayoutModal } from '../..';
import { ImgCircle } from '@thrivelot/styles';
import { Leaf } from '@thrivelot/stories';

const ModalViewPlantDetails = ({ onClose, projectId, plantId }) => {
  return null;
  // const { plant, loadedPlantFromDatabase, loadedProject } = useModelPlant({ plantId, projectId });

  // const loaded = loadedProject && loadedPlantFromDatabase;
  // const { imageUrl, commonName, scientificName, planting, growth, projectProps } = plant || {};
  // const { sowingDescription } = planting || {};
  // const { growingDescription } = growth || {};
  // const { notes } = projectProps || {};

  // return (
  //   <LayoutModal
  //     width="md"
  //     onClose={onClose}
  //     loading={!loaded}
  //     header={loaded && commonName && <div className="text-lg text-brown-dark font-bold text-center">{commonName}</div>}
  //     subheader={
  //       loaded && commonName && <div className="text-sm text-brown-dark italic text-center">{scientificName}</div>
  //     }
  //   >
  //     <div className="flex justify-center">
  //       {imageUrl && <ImgCircle src={{ url: imageUrl }} size={120} style={{ zIndex: 5 }} />}
  //       {!imageUrl && (
  //         <div className="border-4 border-green-main h-28 w-28 rounded-full flex items-center justify-center">
  //           <Leaf size={80} color="green-main" styleProps="pl-3" />
  //         </div>
  //       )}
  //     </div>
  //     {(growingDescription || sowingDescription || notes) && (
  //       <div className="flex flex-col gap-4 mt-5 pt-5 border-t border-tan-light">
  //         {sowingDescription && (
  //           <div className="text-brown-dark">
  //             <div className="font-bold">Sowing Description</div>
  //             <div className="text-sm">{sowingDescription}</div>
  //           </div>
  //         )}
  //         {growingDescription && (
  //           <div className="text-brown-dark">
  //             <div className="font-bold">Growing Description</div>
  //             <div className="text-sm">{growingDescription}</div>
  //           </div>
  //         )}
  //         {notes && (
  //           <div className="text-brown-dark">
  //             <div className="font-bold">Notes</div>
  //             <div className="text-sm">{notes}</div>
  //           </div>
  //         )}
  //       </div>
  //     )}
  //   </LayoutModal>
  // );
};

export { ModalViewPlantDetails };
