import { useModal, useModel } from '@thrivelot/hooks';
import { Dropdown, AngleDown } from '@thrivelot/stories';
import React, { useMemo, useCallback } from 'react';

const DropdownMultiSelectedActions = ({
  itemPath,
  selectedItemIds,
  clearSelected,
  bundleId,
  projectId,
  featureId,
}) => {
  const { openModal } = useModal();
  const { loaded, updateModel, actions } = useModel({
    modelName: 'Bundle',
    id: bundleId,
  });

  const handleDeleteSelected = useCallback(() => {
    updateModel(actions.removeItems(selectedItemIds).result);
    clearSelected();
  }, [selectedItemIds, clearSelected, updateModel, actions]);

  const options = useMemo(
    () => [
      {
        icon: 'Duplicate',
        label: 'Duplicate selected items',
        onClick: () => {
          openModal({
            path: 'ModalAddBundleItems',
            bundleId,
            projectId,
            featureId,
            itemPath,
            itemIds: selectedItemIds,
            title: 'Duplicate Items',
            description:
              'Please complete the form to duplicate your selected items.',
          });
          clearSelected();
        },
      },
      {
        icon: 'Feature',
        label: 'Move items to another feature',
        onClick: () => {
          openModal({
            path: 'ModalMoveBundleItems',
            bundleId,
            projectId,
            itemPath,
            itemIds: selectedItemIds,
          });
          clearSelected();
        },
      },
      {
        icon: 'VisibilityOff',
        label: 'Hide selected items',
        onClick: () => {
          const items = actions.get(itemPath);
          items.forEach((item) => {
            if (selectedItemIds.includes(item.id)) {
              item.hidden = true;
            }
          });
          updateModel(actions.set(itemPath, items).result);
          clearSelected();
        },
      },
      {
        icon: 'Visibility',
        label: 'Un-hide selected items',
        onClick: () => {
          const items = actions.get(itemPath);
          items.forEach((item) => {
            if (selectedItemIds.includes(item.id)) {
              item.hidden = false;
            }
          });
          updateModel(actions.set(itemPath, items).result);
          clearSelected();
        },
      },
      {
        icon: 'Trash',
        color: 'red-main',
        label: 'Delete selected items',
        onClick: () => handleDeleteSelected(),
      },
    ],
    [
      bundleId,
      projectId,
      featureId,
      itemPath,
      selectedItemIds,
      handleDeleteSelected,
      openModal,
      clearSelected,
    ]
  );

  if (selectedItemIds.length === 0 || !loaded) return null;

  return (
    <Dropdown
      label={
        <div className="flex items-center gap-2 py-0.5 px-3 border-2 border-blue-main rounded-main">
          <div className="text-sm text-blue-main">
            {selectedItemIds.length} Items Selected
          </div>
          <AngleDown size={12} color="blue-main" />
        </div>
      }
      options={options}
      optionClass="font-bold"
      align="left"
    />
  );
};

export { DropdownMultiSelectedActions };
