import { useContext } from "react";
import { AuthContext } from "./AuthContext";

const useAuth = () => {
  const {
    user,
    groups,
    userId,
    authenticated,
    loading,
    fetchUserCredentials,
    fetchUserJwt,
    fetchUserSession,
    signIn,
    signOut,
    completeNewPassword,
    sendPasswordResetCode,
    resetPassword,
    changePassword,
    updateUserAttributes,
    sendUserAttributeVerificationCode,
    verifyUserAttribute,
  } = useContext(AuthContext);

  return {
    user,
    groups,
    userId,
    authenticated,
    loading,
    fetchUserCredentials,
    fetchUserJwt,
    fetchUserSession,
    signIn,
    signOut,
    completeNewPassword,
    sendPasswordResetCode,
    resetPassword,
    changePassword,
    updateUserAttributes,
    sendUserAttributeVerificationCode,
    verifyUserAttribute,
  };
};

export { useAuth };
