/* eslint-disable no-nested-ternary */
import React, { useMemo, useState, useCallback } from 'react';
import { isEmpty } from '@thrivelot/utils';
import { Edit, File, Pin, Button } from '@thrivelot/stories';
import { useModal, useModel } from '@thrivelot/hooks';
import ReactTextareaAutosize from 'react-textarea-autosize';
import { TagIdType } from '@thrivelot/aws';
import { FeatureSummary } from './FeatureSummary';
import { ImageFetched, DropdownUpdateFeatureArea, Confirm } from '..';

const RowItemFeature = ({ projectId, featureId }) => {
  const { openModal } = useModal();
  const { model, updateModel, actions } = useModel({
    modelName: 'Project',
    id: projectId,
  });
  const [isOpen, setIsOpen] = useState(false);

  const deleteFeature = useCallback(
    () =>
      updateModel(actions.set(`features[id:${featureId}].hidden`, true).result),
    [featureId, actions, updateModel]
  );

  const useInvoiceProposals = useMemo(
    () => !!model?.details?.useInvoiceProposals,
    [model]
  );

  const feature = useMemo(
    () => (featureId ? actions.get(`features[id:${featureId}]`) : null),
    [actions, featureId]
  );

  const zone = useMemo(() => {
    if (!featureId) return null;

    return (model.zones || []).find((_zone) =>
      (_zone?.orderedChildTagIds || []).find((tag) => tag.tagId === featureId)
    );
  }, [model?.zones, featureId]);

  const file = useMemo(() => {
    if (isEmpty(model?.files)) return null;

    return model.files.find((_file) =>
      _file?.tagIds?.find((tag) => tag.tagId === featureId)
    );
  }, [featureId, model?.files]);

  const { name, description } = feature || {};

  if (isOpen) {
    return (
      <div className="my-1 bg-white-light shadow rounded-main border border-tan-light p-4 flex flex-col gap-4">
        <div className="flex w-full justify-end items-center ml-5 px-4">
          <div className="flex gap-3">
            <Confirm
              prompt="Are you sure you want to delete this feature?"
              onConfirm={deleteFeature}
            >
              <Button
                icon="Trash"
                kind="outline"
                size="small"
                color="red-main"
                label="Delete Feature"
              />
            </Confirm>

            <Button
              icon="Save"
              kind="outline"
              size="small"
              color="blue-main"
              label="Save"
              onClick={() => setIsOpen(!isOpen)}
            />
          </div>
        </div>
        <div className="flex gap-4">
          <div
            className={`flex w-[54px] h-[54px] rounded-main items-center justify-center bg-tan-light relative ${
              file ? 'hover:cursor-pointer' : ''
            }`}
            onClick={
              file
                ? () =>
                    openModal({
                      path: 'ModalViewImage',
                      modelName: 'Project',
                      modelId: projectId,
                      filePath: 'files',
                      fileId: file.id,
                    })
                : null
            }
          >
            {file ? (
              <ImageFetched
                fileKey={file.file.key}
                height={54}
                width={54}
                style={{
                  height: '54px',
                  width: '54px',
                  borderRadius: '0.5rem',
                  objectFit: 'cover',
                }}
              />
            ) : (
              <File color="green-main" size={24} />
            )}
            <div
              className="absolute bottom-[-4px] right-[-4px] bg-white-light rounded-full hover:cursor-pointer"
              onClick={() =>
                openModal({
                  path: 'ModalAddFiles',
                  modelName: 'Project',
                  modelId: projectId,
                  filesPath: 'files',
                  tagIds: [{ tagId: featureId, type: TagIdType.FEATURE }],
                  allowMultiple: false,
                  header: 'Add File',
                  subheader: 'Please add one file',
                })
              }
            >
              <Edit color="orange-main" size={20} />
            </div>
          </div>
          <div className="flex-1 flex flex-col gap-4">
            <div className="flex-1">
              <input
                className="input"
                placeholder="Name"
                value={name}
                onChange={(e) =>
                  updateModel(
                    actions.set(
                      `features[id:${featureId}].name`,
                      e.target.value
                    ).result
                  )
                }
              />
            </div>

            <DropdownUpdateFeatureArea
              projectId={projectId}
              featureId={featureId}
            />
          </div>
        </div>
        <div>
          <div className="flex flex-col items-end">
            <div className="w-full">
              <ReactTextareaAutosize
                className="w-full input"
                placeholder="Add a description..."
                value={description}
                onChange={(e) =>
                  updateModel(
                    actions.set(
                      `features[id:${featureId}].description`,
                      e.target.value
                    ).result
                  )
                }
              />
            </div>
            {/* {!useInvoiceProposals && (
              <div className="ml-5 mr-5 mt-4">
                <FeatureSummary projectId={projectId} featureId={featureId} />
              </div>
            )} */}
          </div>

          {/* {!useInvoiceProposals && (
            <div className="py-5 px-4">
              <RowListFeatureLineItems
                projectId={projectId}
                featureId={featureId}
              />
              <div className="py-3" />
              <RowListFeaturePlants
                projectId={projectId}
                featureId={featureId}
              />
            </div>
          )} */}
        </div>
      </div>
    );
  }

  return (
    <div
      className="my-1 bg-white-light shadow rounded-main border border-tan-light flex gap-4 p-4 hover:cursor-pointer"
      onClick={() => setIsOpen(!isOpen)}
    >
      {file && (
        <ImageFetched
          fileKey={file.file.key}
          height={54}
          width={54}
          style={{
            height: '54px',
            width: '54px',
            borderRadius: '0.5rem',
            objectFit: 'cover',
          }}
        />
      )}
      <div className="w-full h-full">
        <div
          className={`${
            featureId ? 'text-brown-dark' : 'text-orange-dark'
          } font-bold text-lg flex min-w-0`}
        >
          <div className="text-ellipsis overflow-hidden whitespace-nowrap flex-1">
            {name || (
              <span className="italic font-normal">
                Edit to rename this feature...
              </span>
            )}
            {!isOpen && description && (
              <span className="text-sm italic font-normal">
                {' '}
                - {description}
              </span>
            )}
          </div>
          <Button
            classAppend="shrink-0"
            icon="Edit"
            kind="outline"
            size="small"
            color="orange-main"
            label="Edit"
          />
        </div>

        {zone && (
          <div className="flex items-start mt-1">
            <Pin size={14} color="blue-main" styleProps="mr-1 mt-0.5" />
            <div className="text-sm text-brown-dark text-ellipsis overflow-hidden whitespace-nowrap">
              {zone.name}
            </div>
          </div>
        )}
      </div>
      {/* {!useInvoiceProposals && (
          <div
            className="flex justify-end items-center ml-5 hover:cursor-pointer"
            onClick={() => setIsOpen(!isOpen)}
          >
            <div className="">
              <FeatureSummary
                mobileTotalOnly
                projectId={projectId}
                featureId={featureId}
              />
            </div>
          </div>
        )} */}
    </div>
  );
};

export { RowItemFeature };
