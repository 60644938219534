import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useFiles, useModel, useZoom } from '@thrivelot/hooks';
import {
  AngleLeft,
  AngleRight,
  AngleDown,
  Button,
  Download,
  Dropdown,
  Minus,
  Plus,
} from '@thrivelot/stories';
import { Layout } from './Layout';
import { ViewPdf } from './ViewPdf';

export const ModalViewFiles = ({
  onClose,
  projectId,
  fileId: startingFileId,
}) => {
  const [fileId, setFileId] = useState(startingFileId);
  const [signedUrl, setSignedUrl] = useState(null);
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  const { model: project, loaded } = useModel({
    modelName: 'Project',
    id: projectId,
  });
  const { fetchUrl } = useFiles();
  const { isSizeToFit, setIsSizeToFit, zoomIn, zoomOut, ZoomView } =
    useZoom(signedUrl);

  const file = useMemo(() => {
    if (!project?.files) return null;
    return project.files.find((_file) => _file.id === fileId);
  }, [fileId, project?.files]);

  const fileIds = useMemo(() => {
    if (!project?.files?.length) return [];

    const fileIdsWithFolder = (project?.fileFolders || [])
      .map((_folder) => _folder.fileIds)
      .flat();
    const fileIdsWithoutFolder = (project?.files || [])
      .filter((_file) => !fileIdsWithFolder.includes(_file.id))
      .map((_file) => _file.id);

    return [...fileIdsWithoutFolder, ...fileIdsWithFolder];
  }, [project?.files, project?.fileFolders]);

  const handleNext = useCallback(() => {
    if (!fileIds.length) return;

    const currentIndex = fileIds.indexOf(fileId);
    if (currentIndex === fileIds.length - 1) return;

    setFileId(fileIds[currentIndex + 1]);
  }, [fileId, fileIds]);

  const handlePrevious = useCallback(() => {
    if (!fileIds.length) return;

    const currentIndex = fileIds.indexOf(fileId);
    if (currentIndex === 0) return;

    setFileId(fileIds[currentIndex - 1]);
  }, [fileId, fileIds]);

  const currentIndex = useMemo(
    () => fileIds.indexOf(fileId),
    [fileIds, fileId]
  );

  const prevDisabled = useMemo(() => currentIndex === 0, [currentIndex]);

  const nextDisabled = useMemo(
    () => currentIndex === fileIds.length - 1,
    [currentIndex, fileIds]
  );

  const fileType = useMemo(
    () => (/image/.test(file?.file?.type) ? 'image' : 'pdf'),
    [file?.file?.type]
  );

  const pageOptions = useMemo(() => {
    const arr = numPages ? Array.from(Array(numPages).keys()) : [];
    return arr.map((num) => ({
      label: num + 1,
      onClick: () => setPageNumber(num + 1),
    }));
  }, [numPages]);

  useEffect(() => {
    const fetchSignedUrl = async () => {
      const { data } = await fetchUrl(file?.file?.key);
      setSignedUrl(data);
    };

    if (file?.file?.key) fetchSignedUrl();
  }, [fetchUrl, file?.file?.key]);

  const download = () => {
    const newWindow = window.open();
    newWindow.document.title = 'Thrive Lot | View File';
    newWindow.document.write(
      `<iframe src="${signedUrl}" frameborder="0" style="border:0; top:0px; left:0px; bottom:0px; right:0px; width:100%; height:100%;" allowfullscreen></iframe>`
    );
  };

  const filename = file?.file?.filename;
  const caption = file?.caption;

  return (
    <Layout
      onClose={onClose}
      header={filename}
      subheader={caption}
      footer={
        <div className="flex flex-1 items-center justify-between">
          <div className="flex items-center gap-2 mr-5">
            <AngleLeft
              size={20}
              color="white-light"
              styleProps={`${
                prevDisabled
                  ? 'pointer-events-none opacity-30'
                  : 'cursor-pointer'
              }`}
              onClick={handlePrevious}
            />
            <div className="text-white-light w-18 select-none">
              {currentIndex + 1} of {fileIds.length}
            </div>
            <AngleRight
              size={20}
              color="white-light"
              styleProps={`${
                nextDisabled
                  ? 'pointer-events-none opacity-30'
                  : 'cursor-pointer'
              }`}
              onClick={handleNext}
            />
          </div>
          {fileType === 'pdf' && (
            <Dropdown
              label={
                <div className="flex items-center justify-center py-1 px-2 border-2 rounded-main border-white-light">
                  <div className="text-white-light mr-2">Pg</div>
                  <div className="text-white-light font-bold mr-2">
                    {pageNumber}
                  </div>
                  <div className="text-white-light mr-2">of</div>
                  <div className="text-white-light font-bold mr-2">
                    {numPages}
                  </div>
                  <AngleDown size={16} color="white-light" />
                </div>
              }
              optionClass="font-bold"
              align="left"
              verticalAlign="top"
              options={pageOptions}
            />
          )}
          {fileType === 'image' && (
            <div className="flex items-center gap-3 select-none">
              {/* <div className="text-white-light font-bold">Zoom</div> */}
              <Minus
                size={24}
                color="white-light"
                styleProps="hover:cursor-pointer"
                onClick={zoomOut}
              />
              <Plus
                size={24}
                color="white-light"
                styleProps="hover:cursor-pointer"
                onClick={zoomIn}
              />
              <Button
                label={isSizeToFit ? 'Fill Screen' : 'Size to Fit'}
                size="small"
                kind="outline"
                color="white-light"
                onClick={() => setIsSizeToFit(!isSizeToFit)}
              />
            </div>
          )}
          <Download
            size={24}
            color="white-light"
            styleProps="hover:cursor-pointer"
            onClick={download}
          />
        </div>
      }
    >
      {loaded &&
        signedUrl &&
        (fileType === 'image' ? (
          ZoomView
        ) : (
          <ViewPdf
            loaded={loaded}
            signedUrl={signedUrl}
            setNumPages={setNumPages}
            pageNumber={pageNumber}
            setPageNumber={setPageNumber}
          />
        ))}
    </Layout>
  );

  // return (
  //   <LayoutModal
  //     onClose={onClose}
  //     width="full"
  //     loading={!loaded && !signedUrl}
  //     header={
  //       loaded && (
  //         <div className="flex items-center justify-between gap-4">
  //           <div className="text-lg text-brown-dark font-bold truncate">
  //             {filename}
  //           </div>
  //           <div className="flex items-center gap-2 mr-5">
  //             <AngleLeft
  //               size={16}
  //               color="green-main"
  //               styleProps={`${
  //                 prevDisabled
  //                   ? 'pointer-events-none opacity-30'
  //                   : 'cursor-pointer'
  //               }`}
  //               onClick={handlePrevious}
  //             />
  //             <div className="text-brown-dark w-18">
  //               {currentIndex + 1} of {fileIds.length}
  //             </div>
  //             <AngleRight
  //               size={16}
  //               color="green-main"
  //               styleProps={`${
  //                 nextDisabled
  //                   ? 'pointer-events-none opacity-30'
  //                   : 'cursor-pointer'
  //               }`}
  //               onClick={handleNext}
  //             />
  //           </div>
  //         </div>
  //       )
  //     }
  //   >
  //     <ViewFile loaded={loaded} signedUrl={signedUrl} />
  //     {caption && <div className="mt-4">{caption}</div>}
  //   </LayoutModal>
  // );
};
