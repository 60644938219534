import React, { useRef } from "react";
import SvgContainer from "../SvgWrapper";

const PhaseIcon = ({ color, size, ...rest }) => {
  const clipRef = useRef(`${Math.floor(Math.random() * 1000)}`);
  return (
    <SvgContainer
      width={`${size}`}
      height={`${size}`}
      viewBox="0 0 300 300"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <g clipPath={`url(#${clipRef.current})`}>
        <path
          d="M59.5846 155.833H239.585C259.168 155.833 275.835 142.083 281.668 122.917C282.085 122.917 282.085 122.5 282.501 122.5L298.751 105C300.835 102.917 300.835 99.1667 298.751 96.6667C296.668 94.1667 293.335 94.1667 291.251 96.6667L283.751 104.167C282.085 79.1667 262.918 59.5833 239.585 59.5833H204.585V36.6667C204.585 16.6667 189.585 0 170.835 0H42.5013C23.7513 0 8.7513 16.25 8.7513 36.6667V95C8.7513 115 23.7513 131.667 42.5013 131.667H171.251C189.585 131.667 205.001 115.417 205.001 95V71.6667H240.001C257.085 71.6667 271.251 85.8333 273.335 104.167L266.668 96.6667C264.585 94.1667 261.251 94.1667 259.168 96.6667C257.085 99.1667 257.085 102.5 259.168 105L272.085 119.167C267.918 133.75 255.001 144.583 240.418 144.583H59.5846C37.5013 144.583 19.168 162.5 15.8346 185.417L9.16797 177.917C7.08463 175.833 3.7513 175.833 1.66797 177.917C-0.415365 180.417 -0.415365 183.75 1.66797 186.25L16.668 202.083C20.8346 223.75 38.7513 240 60.0013 240H95.0013V264.167C95.0013 283.75 109.585 300 127.918 300H258.335C276.251 300 291.251 284.167 291.251 264.167V204.167C291.251 184.583 276.668 168.333 258.335 168.333H127.501C109.585 168.333 94.5846 184.167 94.5846 204.167V228.333H59.5846C44.168 228.333 30.8346 216.667 27.0846 200.833L41.2513 185.833C43.3346 183.75 43.3346 180 41.2513 177.5C39.168 175.417 35.8346 175.417 33.7513 177.5L27.0846 185C30.0013 168.333 43.3346 155.833 59.5846 155.833ZM180.418 95C180.418 100.417 176.251 105 171.251 105H42.5013C37.5013 105 33.3346 100.417 33.3346 95V36.6667C33.3346 31.25 37.5013 26.6667 42.5013 26.6667H171.251C176.251 26.6667 180.418 31.25 180.418 36.6667V95ZM119.168 204.167C119.168 199.167 122.918 195 127.501 195H257.918C262.501 195 266.251 199.167 266.251 204.167V264.167C266.251 269.167 262.501 273.333 257.918 273.333H127.501C122.918 273.333 119.168 269.167 119.168 264.167V204.167Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id={clipRef.current}>
          <rect width="300" height="300" fill="none" />
        </clipPath>
      </defs>
    </SvgContainer>
  );
};

export default PhaseIcon;
