import React from 'react';
import PropTypes from 'prop-types';
import * as icons from './icons';
import { getIconNameStrings, getTailwindColorStrings } from './utils';

export const Pill = ({ icon, color, size, classAppend, children, label }) => {
  const Icon = icon ? icons[icon] : <div />;

  let iconSize = 16;
  let sizeClass = 'text-base py-0.5 px-4';
  if (size === 'lg') {
    iconSize = 20;
    sizeClass = 'text-base py-1 px-6';
  } else if (size === 'sm') {
    iconSize = 12;
    sizeClass = 'text-sm py-0 px-4';
  }

  return (
    <span className="flex">
      <div
        className={`text-white-light rounded-full bg-${color} ${sizeClass} flex items-center gap-1`}
      >
        {icon && <Icon size={iconSize} color="white-light" />}
        <div>{children || label}</div>
      </div>
    </span>
  );
};

Pill.propTypes = {
  /**
   * Show an icon in the selector
   */
  icon: PropTypes.oneOf(getIconNameStrings({ includeUndefined: true })),

  /**
   * Size of the button
   */
  size: PropTypes.oneOf(['sm', 'md', 'lg']),

  /**
   * The color of the selector
   */
  color: PropTypes.oneOf(getTailwindColorStrings()),

  /**
   * Class string to append to outermost element class
   */
  classAppend: PropTypes.string,

  /**
   * Label or text for the pill, props.children can also be used
   */
  label: PropTypes.string,
};

Pill.defaultProps = {
  color: 'green-main',
  classAppend: '',
  selected: false,
};
