const transformStringToCamelCase = (str) => {
  const lowerCasedStr = str.toLowerCase();
  const splitStr = lowerCasedStr.split("_");

  let camelCasedStr = "";
  splitStr.forEach((item, index) => {
    if (index !== 0) {
      camelCasedStr += item.charAt(0).toUpperCase() + item.slice(1);
    } else {
      camelCasedStr += item;
    }
  });

  return camelCasedStr;
};

export { transformStringToCamelCase };
