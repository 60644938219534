import { isObject } from '@thrivelot/utils';
import { get } from './get';

const getAllTags = (obj: any, pathToItemContainingTags: string) => {
  const itemContainingTags = get(obj, pathToItemContainingTags);
  const tags: any[] = [];

  if (Array.isArray(itemContainingTags) || !isObject(itemContainingTags))
    return tags;

  if (itemContainingTags.orderedChildTagIds)
    tags.push(...itemContainingTags.orderedChildTagIds);

  if (itemContainingTags.tagIds) {
    const filteredTagIds = itemContainingTags.tagIds.filter(
      ({ tagId }) => !tags.find((tag) => tagId !== tag.tagId)
    );
    tags.push(...filteredTagIds);
  }

  return tags;
};

export const findItemsByTags = (
  obj: any,
  pathToItemContainingTags: string,
  pathToSearchForItems: string
) =>
  getAllTags(obj, pathToItemContainingTags)
    .map((tag) => get(obj, `${pathToSearchForItems}[id:${tag.tagId}]`))
    .filter((tag) => tag);
