import { useMemo, useRef } from "react";

const useLoading = (loadingAll, id) => {
  const idRef = useRef();

  idRef.current = id;

  return useMemo(() => loadingAll[idRef.current], [loadingAll]);
};

export { useLoading };
