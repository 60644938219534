import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { ModelKit } from "@thrivelot/model-kit";
import { handleDebounce } from "@thrivelot/utils";
import { normalizeSearchResults, sortSearchResults } from "../helpers";

const useModelPlantSearch = () => {
  const [query, setQuery] = useState("");
  const [plants, setPlants] = useState([]);
  const [loading, setLoading] = useState(false);

  const queryRef = useRef();
  const plantsRef = useRef();

  queryRef.current = query;
  plantsRef.current = plants;

  const queryPlants = useCallback(async () => {
    const staticQuery = queryRef.current.trim().toLowerCase();

    if (staticQuery.length < 3) return;

    setLoading(true);

    try {
      const plantKit = new ModelKit({
        modelName: "Plant",
        filter: {
          or: [
            { commonName: { matchPhrase: staticQuery } },
            { scientificName: { matchPhrase: staticQuery } },
            { commonNames: { matchPhrase: staticQuery } },
            { family: { matchPhrase: staticQuery } },
            { familyCommonName: { matchPhrase: staticQuery } },
            { synonyms: { matchPhrase: staticQuery } },
          ],
        },
      });

      const data = await plantKit.search();

      if (data) setPlants(sortSearchResults(normalizeSearchResults(data, "plant", true)));
    } catch (err) {
      console.error("Error searching plants: ", JSON.stringify(err, null, 2));

      window.showNotification({
        type: "error",
        title: "Search Error",
        body: "There was a problem searching plants",
      });
    } finally {
      setLoading(false);
    }
  }, []);

  const queryPlantsDebounced = useMemo(() => handleDebounce(queryPlants, 500), [queryPlants]);

  useEffect(() => {
    const preQueriedPlantsDebounced = queryPlantsDebounced;

    return () => {
      preQueriedPlantsDebounced.cancel();
    };
  }, [queryPlantsDebounced]);

  useEffect(() => {
    if (query.length === 0) setPlants([]);
    else queryPlantsDebounced();
  }, [queryPlantsDebounced, query]);

  return {
    query,
    setQuery,
    plants,
    loading,
  };
};

export { useModelPlantSearch };
