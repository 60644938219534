import { isAdminApp } from '@thrivelot/utils';
import { ProjectMilestoneLifecycleEventType } from '@thrivelot/aws';

const getProjectSubmitType = (stage, milestones = []) => {
  const milestone = milestones[milestones.length - 1];
  if (!milestone || milestone.stage !== stage) return null;
  if (
    !milestone.lifecycleEvents ||
    !milestone.lifecycleEvents.find(
      (event) =>
        event.type === ProjectMilestoneLifecycleEventType.SUPPLIER_SUBMISSION
    )
  )
    return ProjectMilestoneLifecycleEventType.SUPPLIER_SUBMISSION;
  if (
    isAdminApp() &&
    !milestone.lifecycleEvents.find(
      (event) =>
        event.type === ProjectMilestoneLifecycleEventType.ADMIN_ACCEPTANCE
    )
  )
    return ProjectMilestoneLifecycleEventType.ADMIN_ACCEPTANCE;
  return null;
};

export { getProjectSubmitType };
