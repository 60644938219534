import styled from "styled-components";
import CssInputDefault from "./CssInputDefault";

const borderColor = ({ theme, underlineColor, noUnderline }) => {
  if (noUnderline) return "transparent";
  if (underlineColor) return underlineColor;
  return theme.inputUnderline;
};

const textColor = ({ isTitle, theme }) => {
  if (isTitle) return theme.inputTextTitle;
  return theme.inputText;
};

const InputUnderline = styled.input`
  ${CssInputDefault}
  @media (max-width: 767px) {
    font-size: 14px;
  }
  @media (min-width: 768px) {
    font-size: 17px;
  }
  color: ${textColor};
  background-color: transparent;
  font-family: Poppins-Bold;
  border-bottom: 1px solid ${borderColor};
  padding: 0px;
  margin: 0px;
  min-height: fit-content;
  max-height: fit-content;
  border-radius: 0px;
  :focus {
    outline: none !important;
    border-bottom: 1px solid ${({ theme }) => theme.inputTintColor};
  }
`;

export default InputUnderline;
