import { useMemo, useCallback } from 'react';
import { useModel, useModelList } from '@thrivelot/hooks';

const useInvoiceProposalBillingSummary = (invoiceProposalId) => {
  const {
    model: invoiceProposal,
    loaded: invoiceProposalLoaded,
    loading: invoiceProposalLoading,
    actions,
  } = useModel({ modelName: 'InvoiceProposal', id: invoiceProposalId });

  const {
    models: bundles,
    loading: bundleLoading,
    loaded: bundleLoaded,
  } = useModelList({
    modelName: 'Bundle',
    filter: { invoiceProposalId: { eq: invoiceProposalId } },
  });

  const billingSummary = useMemo(() => {
    if (!bundleLoaded || !invoiceProposalLoaded) return;
    return actions.getBillingSummary(bundles);
  }, [bundleLoaded, invoiceProposalLoaded, bundles]);

  const getCombinedItems = useCallback(() => {
    if (!bundleLoaded || !invoiceProposalLoaded) return;
    return actions.getCombinedBundleItems(bundles);
  }, [bundleLoaded, invoiceProposalLoaded, bundles]);

  const constructItemsTotals = useCallback(
    (items) => {
      if (!invoiceProposalLoaded) return;
      return actions.constructItemsTotals(items);
    },
    [invoiceProposalLoaded, actions]
  );

  return {
    bundles,
    invoiceProposal,
    billingSummary,
    loading: bundleLoading || invoiceProposalLoading,
    loaded: bundleLoaded && invoiceProposalLoaded,
    constructItemsTotals,
    getCombinedItems,
  };
};

export { useInvoiceProposalBillingSummary };
