import React from 'react';
import { useRouter } from '@thrivelot/common';
import { FileContainer } from './FileContainer';
import { FileDisplay } from './FileDisplay';
import { Name } from './Name';

const File = ({ file }) => {
  // hooks
  const { history, urlHashes, urlModalValues } = useRouter();
  // navigation
  const openFileModal = () => {
    history.push({
      hash: {
        [urlHashes.proposalModal]: urlModalValues.proposalFile,
        [urlHashes.fileId]: file.id,
      },
    });
  };
  // return
  return (
    <FileContainer onClick={openFileModal}>
      <FileDisplay file={file} />
      <Name file={file} />
    </FileContainer>
  );
};

export { File };
