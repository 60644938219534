import React from 'react';
import { useCatalog, useAuth } from '@thrivelot/hooks';
import { PageContainer } from '@thrivelot/stories';
import { HelpPrompt, CatalogImport, LinkBack } from '@thrivelot/components';

const PageCatalogImport = () => {
  const {
    user: { username },
  } = useAuth();

  const { loaded, catalog, ownerId } = useCatalog({
    ownerId: username,
  });

  if (!loaded) return null;
  return (
    <PageContainer
      icon="Catalog"
      header="Catalog Import Line Item"
      documentTitle="Catalog Import"
      preheader={<LinkBack label="Back to Catalog" to=".." />}
      loading={!loaded}
      help={
        <HelpPrompt header="About the Catalog Import Page">
          Choose from your previously added items to quickly and easily import
          them into your official catalog.
        </HelpPrompt>
      }
    >
      {loaded && catalog && (
        <CatalogImport catalogId={catalog.id} ownerId={ownerId} />
      )}
    </PageContainer>
  );
};

export { PageCatalogImport };
