import styled from "styled-components";
import CssDivDefault from "./CssDivDefault";

const DivJumpBar = styled.div`
  ${CssDivDefault}
  display: flex;
  flex-direction: row;
  overflow-x: scroll;
  margin-right: -12px;
  margin-left: -12px;
  padding-left: 12px;
  padding-top: 8px;
`;

export default DivJumpBar;
