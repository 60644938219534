import React, { useEffect } from 'react';
import { useProposal } from '@thrivelot/common';
import { PageContainer } from '@thrivelot/stories';
import { DivSuspenseContainer } from '@thrivelot/styles';
import { login } from './login';
import { Content, Modal } from './Content';
import { TopBar } from './TopBar';

export const CustomerProposal = () => {
  const { loading } = useProposal();

  useEffect(() => {
    login();
  }, []);

  if (loading) return <DivSuspenseContainer />;

  return (
    <>
      <TopBar />
      <PageContainer>
        <Content />
        <Modal />
      </PageContainer>
    </>
  );
};
