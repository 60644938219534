import styled from "styled-components";
import CssDivDefault from "./CssDivDefault";

const DivAddressSearchTitle = styled.div`
  ${CssDivDefault}
  min-height: fit-content;
  max-height: fit-content;
  margin-top: 12px;
  margin-bottom: 8px;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
`;

export default DivAddressSearchTitle;
