import colors from "@thrivelot/tailwind/colors";

const buildCardOptions = (theme) => ({
  iconStyle: "solid",
  style: {
    base: {
      fontSize: "15px",
      fontFamily: "Rokkitt-Bold",
      fontWeight: "bold",
      "::selection": {
        backgroundColor: theme.inputTintColor,
        color: theme.inputSelectedText,
      },
      "blockquote::selection": {
        backgroundColor: theme.inputTintColor,
        color: theme.inputSelectedText,
      },
      "::placeholder": {
        color: theme.inputPlaceholderText,
      },
      ":-webkit-autofill": {
        backgroundColor: theme.inputTintColor,
        color: theme.inputSelectedText,
      },
      color: theme.inputText,
      backgroundColor: theme.inputBackground,
    },
    invalid: {
      iconColor: colors.tan.main,
      color: colors.red.main,
    },
  },
});

export { buildCardOptions };
