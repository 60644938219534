import { Card } from "@thrivelot/stories";
import { CardLoader } from "@thrivelot/stories";
import React from "react";
import TextareaAutosize from "react-textarea-autosize";
import { useCanCan, useModel } from "@thrivelot/hooks";
import { isEmpty } from "@thrivelot/utils";

const CardAdminNotes = ({ projectId }) => {
  const { model: project, updateModel, actions, loaded } = useModel({ modelName: "Project", id: projectId });
  const { canEdit } = useCanCan(project);

  if (!loaded) return <CardLoader showText />;

  const {
    details: { adminNotes },
  } = project;

  return (
    <Card
      header="Project Story"
      subheader={canEdit && <div className="italic font-normal text-sm">Visible to customer</div>}
      classAppend="mb-4"
    >
      {canEdit ? (
        <TextareaAutosize
          className="input"
          placeholder="Add a note here..."
          minRows={2}
          value={adminNotes || ""}
          onChange={(e) => updateModel(actions.set("details.adminNotes", e.target.value).result)}
        />
      ) : !isEmpty(adminNotes) ? (
        adminNotes
      ) : (
        <div className="italic">No story added</div>
      )}
    </Card>
  );
};

export { CardAdminNotes };
