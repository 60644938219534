import { TagIdType } from "@thrivelot/aws";

const getUnattachedLineItemsAndPlants = (features, lineItems, plantItems) => {
  const usedIds = [];
  const list = [];

  if (features)
    features.forEach((feature) => {
      if (feature.orderedChildTagIds) usedIds.push(...feature.orderedChildTagIds.map((tagId) => tagId.tagId));
    });

  if (lineItems)
    lineItems.forEach(({ id }) => {
      if (!usedIds.includes(id)) list.push({ type: TagIdType.LINE_ITEM, tagId: id });
    });

  if (plantItems)
    plantItems.forEach(({ id }) => {
      if (!usedIds.includes(id)) list.push({ type: TagIdType.PLANT, tagId: id });
    });

  return list;
};

export { getUnattachedLineItemsAndPlants };
