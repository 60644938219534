import React from "react";
import { useHover } from "react-use";
import { IoMdArrowDropdown } from "react-icons/io";
import getReactIconStyle from "./helpers";

const ArrowDropdownIcon = ({ color, size, style = {}, hoverable, ...rest }) => {
  const Icon = (hovering) => {
    const iconStyle = {
      ...getReactIconStyle({
        width: size,
        height: size,
        hoverable,
        hovering,
        ...rest,
      }),
      ...style,
    };

    return <IoMdArrowDropdown color={color} size={size} style={iconStyle} {...rest} />;
  };

  const [HoverableIcon] = useHover(Icon);
  return HoverableIcon;
};

export default ArrowDropdownIcon;
