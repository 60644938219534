import { transformCurrencyToString } from '@thrivelot/utils';
import React, { useMemo } from 'react';

const BundleFeatureTotals = ({
  featureName,
  lineItems,
  changeItems,
  classAppend,
}) => {
  const lineItemsCost = useMemo(() => {
    let sum = 0;
    lineItems.forEach((item) => {
      sum += (item.costPerUnit || 0) * (item.quantity || 0);
    });
    return sum;
  }, [lineItems]);

  const lineItemsPrice = useMemo(() => {
    let sum = 0;
    lineItems.forEach((item) => {
      sum += (item.pricePerUnit || 0) * (item.quantity || 0);
    });
    return sum;
  }, [lineItems]);

  const lineItemsMargin = useMemo(
    () => (lineItemsCost > 0 ? lineItemsPrice - lineItemsCost : 0),
    [lineItemsCost, lineItemsPrice]
  );

  const changeItemsCost = useMemo(() => {
    let sum = 0;
    changeItems.forEach((item) => {
      sum += (item.costPerUnit || 0) * (item.quantity || 0);
    });
    return sum;
  }, [changeItems]);

  const changeItemsPrice = useMemo(() => {
    let sum = 0;
    changeItems.forEach((item) => {
      sum += (item.pricePerUnit || 0) * (item.quantity || 0);
    });
    return sum;
  }, [changeItems]);

  const changeItemsMargin = useMemo(
    () => (changeItemsCost > 0 ? changeItemsPrice - changeItemsCost : 0),
    [changeItemsCost, changeItemsPrice]
  );

  const totalCost = lineItemsCost + changeItemsCost;
  const totalPrice = lineItemsPrice + changeItemsPrice;
  const totalMargin = lineItemsMargin + changeItemsMargin;

  return (
    <div
      className={`w-full divide-y divide-tan-light flex flex-col justify-center ${classAppend}`}
    >
      {totalCost > 0 && (
        <div className="flex justify-between gap-6 py-1">
          <div className="text-sm col-span-4 flex items-center justify-end">
            {featureName} Cost:
          </div>
          <div className="text-right mr-2">
            {Math.abs(changeItemsCost) > 0 && (
              <span className="text-sm mx-2">
                ({transformCurrencyToString(lineItemsCost)}{' '}
                <span className="text-blue-main">
                  {changeItemsCost > 0 ? '+' : '-'}{' '}
                  {transformCurrencyToString(Math.abs(changeItemsCost))}
                </span>
                )
              </span>
            )}
            {transformCurrencyToString(totalCost)}
          </div>
        </div>
      )}
      {totalMargin > 0 && (
        <div className="flex justify-between gap-6 py-1">
          <div className="text-sm col-span-4 flex items-center justify-end">
            {featureName} Margin:
          </div>
          <div className="text-right mr-2">
            +
            {Math.abs(changeItemsMargin) > 0 && (
              <span className="text-sm mx-2">
                ({transformCurrencyToString(lineItemsMargin)}{' '}
                <span className="text-blue-main">
                  {changeItemsMargin > 0 ? '+' : '-'}{' '}
                  {transformCurrencyToString(Math.abs(changeItemsMargin))}
                </span>
                )
              </span>
            )}
            {transformCurrencyToString(totalMargin)}
          </div>
        </div>
      )}
      <div className="flex justify-between gap-6 py-1">
        <div className="text-sm col-span-4 flex items-center justify-end">
          {featureName} Total:
        </div>
        <div className="font-bold text-right mr-2">
          {Math.abs(changeItemsPrice) > 0 && (
            <span className="text-sm mx-2">
              ({transformCurrencyToString(lineItemsPrice)}{' '}
              <span className="text-blue-main">
                {changeItemsPrice > 0 ? '+' : '-'}{' '}
                {transformCurrencyToString(Math.abs(changeItemsPrice))}
              </span>
              )
            </span>
          )}
          {transformCurrencyToString(totalPrice)}
        </div>
      </div>
    </div>
  );
};

export { BundleFeatureTotals };
