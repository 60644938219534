import React, { Fragment } from "react";
import { useIsMobile } from "@thrivelot/hooks";
import { transformCurrencyToString, isAdminApp } from "@thrivelot/utils";
import {
  DivContentRow,
  DivInset,
  DivLineHorizontal,
  InputNumber,
  TextBody,
  TextBodyBold,
  TextSmallHeader,
} from "@thrivelot/styles";
import { styleForListItemCostContainer, styleForListItemDollarSign } from "../helpers";

const YieldToThrive = ({ amount, isPhase, isLast, percentageMarkup, dollarMarkup, updatePayment, siteVisitCost }) => {
  const isMobile = useIsMobile();

  const percentage = percentageMarkup * 100;

  const getMarginLeft = () => {
    if (isMobile && isPhase) return "15px";
    if (isMobile) return "8px";
    if (isPhase) return "274px";
    return "262px";
  };
  const marginLeft = getMarginLeft();

  return (
    <Fragment>
      <DivContentRow
        style={{
          alignItems: isMobile && updatePayment ? undefined : "center",
          justifyContent: "space-between",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: isMobile ? "column" : "row",
            marginLeft,
            marginRight: "12px",
          }}
        >
          <TextSmallHeader
            style={{
              fontSize: isMobile ? "12px" : undefined,
              marginBottom: "-4px",
            }}
          >
            Yield to Thrive Lot
          </TextSmallHeader>
          <TextBody
            style={{
              fontSize: isMobile ? "12px" : undefined,
              marginLeft: isMobile ? undefined : "8px",
            }}
          >
            ({percentage}%)
          </TextBody>
        </div>
        {amount ? (
          <TextBody style={{ fontStyle: "italic" }}>
            {`((${transformCurrencyToString(amount)} + $250) x ${1 + percentageMarkup}) - ${transformCurrencyToString(
              siteVisitCost
            )}`}
          </TextBody>
        ) : null}
        <div
          style={{
            display: "flex",
            flexDirection: isMobile ? "column" : "row",
          }}
        >
          {updatePayment && isAdminApp() && (
            <div
              style={{
                marginBottom: isMobile ? "5px" : undefined,
                marginRight: isMobile ? undefined : "24px",
                minWidth: "90px",
                maxWidth: "90px",
                position: "relative",
              }}
            >
              <InputNumber
                inputType="bubble"
                placeholder="Markup"
                value={percentage || ""}
                onChange={(e) => updatePayment({ markup: e / 100 })}
                style={{ textAlign: "right", width: "100%" }}
              />
              <TextBodyBold style={styleForListItemDollarSign}>%</TextBodyBold>
            </div>
          )}
          <DivInset
            style={{
              ...styleForListItemCostContainer,
              marginRight: isMobile ? undefined : "55px",
            }}
          >
            <TextSmallHeader>{dollarMarkup}</TextSmallHeader>
          </DivInset>
        </div>
      </DivContentRow>
      <DivLineHorizontal style={{ marginBottom: isLast && "45px" }} />
    </Fragment>
  );
};

export { YieldToThrive };
