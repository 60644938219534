import styled from "styled-components";

const NotesContainer = styled.div`
  transition: opacity 0.5s ease-out, box-shadow 0.5s ease-out, color 1s ease-in-out, background-color 1s ease-in-out;
  padding: 12px 12px 0px;
  display: flex;
  flex-direction: column;
`;

export { NotesContainer };
