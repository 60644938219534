const calculateTotalCost = (items) => {
  let total = 0;

  items.forEach((item) => {
    if (item.quantity && item.cost) total += item.quantity * item.cost;
  });

  return total;
};

export { calculateTotalCost };
