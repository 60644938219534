import React from 'react';
import { LeafLoader, Button, EmptyState } from '@thrivelot/stories';
import { useModal, useModel } from '@thrivelot/hooks';
import { RowItemProjectInvoiceProposal } from '..';

const RowListProjectInvoiceProposals = ({ projectId }) => {
  const { openModal } = useModal();
  const { model: project, loaded } = useModel({
    modelName: 'Project',
    id: projectId,
  });

  if (!loaded) return <LeafLoader />;

  if ((project.invoiceProposalIds || []).length === 0)
    return (
      <EmptyState
        icon="LineItems"
        header="No orders here yet..."
        children={<div>Get started by adding an order to this project</div>}
        footer={
          <Button
            icon="Plus"
            size="small"
            color="blue-main"
            kind="outline"
            label="Add Order"
            onClick={() =>
              openModal({
                path: 'ModalAddInvoiceProposal',
                projectId,
              })
            }
          />
        }
      />
    );

  return (
    <div className="divide-y divide-tan-light">
      {project.invoiceProposalIds.map((id) => (
        <RowItemProjectInvoiceProposal
          key={id}
          projectId={projectId}
          invoiceProposalId={id}
          to={`${id}/edit`}
        />
      ))}
    </div>
  );
};

export { RowListProjectInvoiceProposals };
