import React from 'react';
import colors from '@thrivelot/tailwind/colors';
import { DivCircle, SupplierIcon } from '@thrivelot/styles';

const DefaultSupplierImage = ({ size = 30 }) => (
  <DivCircle size={size} style={{ backgroundColor: colors.green.light }}>
    <SupplierIcon
      size={size / 2}
      color={colors.green.dark}
      style={{ marginLeft: '2px', marginBottom: '2px' }}
    />
  </DivCircle>
);

export { DefaultSupplierImage };
