import React from 'react';

export const RowItemLoader = ({
  count = 1,
  showText = false,
  classAppend = '',
}) =>
  [...Array(count)].map((num, index) => (
    <div key={index} className={`w-full px-4 py-2 ${classAppend}`}>
      <div className="animate-pulse flex space-x-4">
        <div className="rounded-full bg-tan-light h-10 w-10"></div>
        <div className="flex-1 py-1 flex flex-col justify-around text-brown-light">
          {showText ? (
            <>
              <div className="text-brown-light text-sm">Loading...</div>
              <div className="h-2 w-28 bg-tan-light rounded-full"></div>
            </>
          ) : (
            <>
              <div className="h-2 w-36 bg-tan-light rounded-full"></div>
              <div className="h-2 w-28 bg-tan-light rounded-full"></div>
            </>
          )}
        </div>
        <div className="py-1 flex flex-col justify-around">
          <div className="h-2 w-24 bg-tan-light rounded-full"></div>
        </div>
      </div>
    </div>
  ));
