import React from 'react';

const Edit = ({
  color = 'green-main',
  size = 30,
  styleProps = '',
  ...rest
}) => (
  <svg
    width={size.toString()}
    height={size.toString()}
    viewBox="0 0 75 75"
    className={`fill-${color} ${styleProps}`}
    {...rest}
  >
    <path d="M37.5 75C16.8269 75 0 58.1731 0 37.5C0 16.8269 16.8269 0 37.5 0C58.1731 0 75 16.8269 75 37.5C75 58.1731 58.1731 75 37.5 75ZM37.5 5.76923C20 5.76923 5.76923 20 5.76923 37.5C5.76923 55 20 69.2308 37.5 69.2308C55 69.2308 69.2308 55 69.2308 37.5C69.2308 20 55 5.76923 37.5 5.76923Z" />
    <path d="M60.1729 20.798L54.2017 14.8269C53.2402 13.8653 51.7498 13.8076 50.8748 14.6826L19.2402 46.3172C18.9998 46.5672 18.8171 46.8749 18.7209 47.1922L16.0863 55.8076C15.8267 56.6442 16.0767 57.5769 16.7498 58.2499C17.2498 58.7499 17.894 59.0192 18.5286 59.0192C18.7594 59.0192 18.9806 58.9807 19.1921 58.9134L27.7979 56.2788C28.1344 56.1826 28.4421 55.9999 28.6825 55.7596L60.3171 24.1249C61.1921 23.2499 61.1248 21.7596 60.1729 20.798ZM26.7786 53.5769L19.0575 55.9422L21.4229 48.2211L43.1344 26.5192L48.4902 31.8749L26.7786 53.5769ZM53.2498 27.1153L47.894 21.7596L52.4709 17.173L57.8267 22.5288L53.2498 27.1153Z" />
  </svg>
);

export { Edit };
