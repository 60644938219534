import styled from "styled-components";
import CssInsetShadow from "./CssInsetShadow";
import CssDivDefault from "./CssDivDefault";

const sizeConstructor = ({ size }) => `${size}px`;

const DivSquareInset = styled.div`
  ${CssDivDefault}
  ${CssInsetShadow}
  border-radius: 5px;
  min-width: ${sizeConstructor};
  max-width: ${sizeConstructor};
  min-height: ${sizeConstructor};
  max-height: ${sizeConstructor};
`;

export default DivSquareInset;
