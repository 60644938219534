/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createCustomer = /* GraphQL */ `
  mutation CreateCustomer(
    $input: CreateCustomerInput!
    $condition: ModelCustomerConditionInput
  ) {
    createCustomer(input: $input, condition: $condition) {
      id
      owners
      viewers
      email
      phoneNumber
      stripeId
      hubspotId
      firstname
      lastname
      contactTimes {
        day
        timeBlocks {
          start
          end
        }
      }
      termsOfUse {
        name
        agreedAt
        revisedAt
      }
      projectIds
      projects {
        items {
          id
          owners
          viewers
          hubspotId
          details {
            title
            projectNotes
            address {
              line1
              line2
              city
              county
              state
              country
              zip
              formattedAddress
            }
            property {
              size
              amountImpacted
            }
            desire
            styles {
              name
              description
            }
            features {
              name
              description
            }
            priorities {
              prompt
              priority
            }
            favoritePlants
            petInformation
            allergyInformation
            additionalNotes
            adminNotes
            siteAssessmentAvailability {
              day
            }
            budgetRange
            budgetCommitment
            useInvoiceProposals
          }
          siteAnalysis {
            id
            title
            observations
            recommendations
            color
            tagIds {
              type
              tagId
            }
          }
          files {
            id
            file {
              bucket
              region
              key
              type
              filename
            }
            caption
            selected
            tagIds {
              type
              tagId
            }
          }
          fileFolders {
            id
            name
            fileIds
          }
          annotations {
            id
            type
            location {
              lat
              lng
            }
            notes
            tagIds {
              type
              tagId
            }
          }
          zones {
            id
            type
            name
            color
            description
            center {
              lat
              lng
            }
            bounds {
              lat
              lng
            }
            tagIds {
              type
              tagId
            }
            orderedChildTagIds {
              type
              tagId
            }
          }
          features {
            id
            name
            description
            color
            center {
              lat
              lng
            }
            bounds {
              lat
              lng
            }
            tagIds {
              type
              tagId
            }
            orderedChildTagIds {
              type
              tagId
            }
            hidden
          }
          plantItems {
            id
            cost
            quantity
            notes
            source
            locations {
              lat
              lng
            }
            changes {
              key
              change
            }
            tagIds {
              type
              tagId
            }
            created
          }
          lineItems {
            id
            type
            name
            cost
            quantity
            unit
            notes
            source
            locations {
              lat
              lng
            }
            tagIds {
              type
              tagId
            }
          }
          activities {
            id
            name
            description
            dates
            tagIds {
              type
              tagId
            }
            orderedChildTagIds {
              type
              tagId
            }
          }
          phases {
            id
            name
            description
            startDate
            endDate
            tagIds {
              type
              tagId
            }
            orderedChildTagIds {
              type
              tagId
            }
          }
          costPaymentSchedule {
            id
            type
            payments {
              id
              date
              paidAt
              paidBy
              markup
            }
            memo
            tagIds {
              type
              tagId
            }
          }
          calendarNotes {
            date
            notes
            private
            tagIds {
              type
              tagId
            }
          }
          milestones {
            id
            type
            stage
            deliverables {
              id
            }
            lifecycleEvents {
              id
              type
              notes
              by
              at
            }
            paymentIds
          }
          currentMilestone
          showProposal
          customer {
            id
            owners
            viewers
            email
            phoneNumber
            stripeId
            hubspotId
            firstname
            lastname
            contactTimes {
              day
            }
            termsOfUse {
              name
              agreedAt
              revisedAt
            }
            projectIds
            projects {
              nextToken
            }
            createdAt
            updatedAt
            updatedMeta {
              id
              userCognitoId
              userId
              userName
              userEmail
              userGroups
              device
            }
            deleted
          }
          customerId
          invoiceProposalIds
          createdAt
          updatedAt
          updatedMeta {
            id
            userCognitoId
            userId
            userName
            userEmail
            userGroups
            device
          }
          deleted
        }
        nextToken
      }
      createdAt
      updatedAt
      updatedMeta {
        id
        userCognitoId
        userId
        userName
        userEmail
        userGroups
        device
      }
      deleted
    }
  }
`;
export const updateCustomer = /* GraphQL */ `
  mutation UpdateCustomer(
    $input: UpdateCustomerInput!
    $condition: ModelCustomerConditionInput
  ) {
    updateCustomer(input: $input, condition: $condition) {
      id
      owners
      viewers
      email
      phoneNumber
      stripeId
      hubspotId
      firstname
      lastname
      contactTimes {
        day
        timeBlocks {
          start
          end
        }
      }
      termsOfUse {
        name
        agreedAt
        revisedAt
      }
      projectIds
      projects {
        items {
          id
          owners
          viewers
          hubspotId
          details {
            title
            projectNotes
            address {
              line1
              line2
              city
              county
              state
              country
              zip
              formattedAddress
            }
            property {
              size
              amountImpacted
            }
            desire
            styles {
              name
              description
            }
            features {
              name
              description
            }
            priorities {
              prompt
              priority
            }
            favoritePlants
            petInformation
            allergyInformation
            additionalNotes
            adminNotes
            siteAssessmentAvailability {
              day
            }
            budgetRange
            budgetCommitment
            useInvoiceProposals
          }
          siteAnalysis {
            id
            title
            observations
            recommendations
            color
            tagIds {
              type
              tagId
            }
          }
          files {
            id
            file {
              bucket
              region
              key
              type
              filename
            }
            caption
            selected
            tagIds {
              type
              tagId
            }
          }
          fileFolders {
            id
            name
            fileIds
          }
          annotations {
            id
            type
            location {
              lat
              lng
            }
            notes
            tagIds {
              type
              tagId
            }
          }
          zones {
            id
            type
            name
            color
            description
            center {
              lat
              lng
            }
            bounds {
              lat
              lng
            }
            tagIds {
              type
              tagId
            }
            orderedChildTagIds {
              type
              tagId
            }
          }
          features {
            id
            name
            description
            color
            center {
              lat
              lng
            }
            bounds {
              lat
              lng
            }
            tagIds {
              type
              tagId
            }
            orderedChildTagIds {
              type
              tagId
            }
            hidden
          }
          plantItems {
            id
            cost
            quantity
            notes
            source
            locations {
              lat
              lng
            }
            changes {
              key
              change
            }
            tagIds {
              type
              tagId
            }
            created
          }
          lineItems {
            id
            type
            name
            cost
            quantity
            unit
            notes
            source
            locations {
              lat
              lng
            }
            tagIds {
              type
              tagId
            }
          }
          activities {
            id
            name
            description
            dates
            tagIds {
              type
              tagId
            }
            orderedChildTagIds {
              type
              tagId
            }
          }
          phases {
            id
            name
            description
            startDate
            endDate
            tagIds {
              type
              tagId
            }
            orderedChildTagIds {
              type
              tagId
            }
          }
          costPaymentSchedule {
            id
            type
            payments {
              id
              date
              paidAt
              paidBy
              markup
            }
            memo
            tagIds {
              type
              tagId
            }
          }
          calendarNotes {
            date
            notes
            private
            tagIds {
              type
              tagId
            }
          }
          milestones {
            id
            type
            stage
            deliverables {
              id
            }
            lifecycleEvents {
              id
              type
              notes
              by
              at
            }
            paymentIds
          }
          currentMilestone
          showProposal
          customer {
            id
            owners
            viewers
            email
            phoneNumber
            stripeId
            hubspotId
            firstname
            lastname
            contactTimes {
              day
            }
            termsOfUse {
              name
              agreedAt
              revisedAt
            }
            projectIds
            projects {
              nextToken
            }
            createdAt
            updatedAt
            updatedMeta {
              id
              userCognitoId
              userId
              userName
              userEmail
              userGroups
              device
            }
            deleted
          }
          customerId
          invoiceProposalIds
          createdAt
          updatedAt
          updatedMeta {
            id
            userCognitoId
            userId
            userName
            userEmail
            userGroups
            device
          }
          deleted
        }
        nextToken
      }
      createdAt
      updatedAt
      updatedMeta {
        id
        userCognitoId
        userId
        userName
        userEmail
        userGroups
        device
      }
      deleted
    }
  }
`;
export const deleteCustomer = /* GraphQL */ `
  mutation DeleteCustomer(
    $input: DeleteCustomerInput!
    $condition: ModelCustomerConditionInput
  ) {
    deleteCustomer(input: $input, condition: $condition) {
      id
      owners
      viewers
      email
      phoneNumber
      stripeId
      hubspotId
      firstname
      lastname
      contactTimes {
        day
        timeBlocks {
          start
          end
        }
      }
      termsOfUse {
        name
        agreedAt
        revisedAt
      }
      projectIds
      projects {
        items {
          id
          owners
          viewers
          hubspotId
          details {
            title
            projectNotes
            address {
              line1
              line2
              city
              county
              state
              country
              zip
              formattedAddress
            }
            property {
              size
              amountImpacted
            }
            desire
            styles {
              name
              description
            }
            features {
              name
              description
            }
            priorities {
              prompt
              priority
            }
            favoritePlants
            petInformation
            allergyInformation
            additionalNotes
            adminNotes
            siteAssessmentAvailability {
              day
            }
            budgetRange
            budgetCommitment
            useInvoiceProposals
          }
          siteAnalysis {
            id
            title
            observations
            recommendations
            color
            tagIds {
              type
              tagId
            }
          }
          files {
            id
            file {
              bucket
              region
              key
              type
              filename
            }
            caption
            selected
            tagIds {
              type
              tagId
            }
          }
          fileFolders {
            id
            name
            fileIds
          }
          annotations {
            id
            type
            location {
              lat
              lng
            }
            notes
            tagIds {
              type
              tagId
            }
          }
          zones {
            id
            type
            name
            color
            description
            center {
              lat
              lng
            }
            bounds {
              lat
              lng
            }
            tagIds {
              type
              tagId
            }
            orderedChildTagIds {
              type
              tagId
            }
          }
          features {
            id
            name
            description
            color
            center {
              lat
              lng
            }
            bounds {
              lat
              lng
            }
            tagIds {
              type
              tagId
            }
            orderedChildTagIds {
              type
              tagId
            }
            hidden
          }
          plantItems {
            id
            cost
            quantity
            notes
            source
            locations {
              lat
              lng
            }
            changes {
              key
              change
            }
            tagIds {
              type
              tagId
            }
            created
          }
          lineItems {
            id
            type
            name
            cost
            quantity
            unit
            notes
            source
            locations {
              lat
              lng
            }
            tagIds {
              type
              tagId
            }
          }
          activities {
            id
            name
            description
            dates
            tagIds {
              type
              tagId
            }
            orderedChildTagIds {
              type
              tagId
            }
          }
          phases {
            id
            name
            description
            startDate
            endDate
            tagIds {
              type
              tagId
            }
            orderedChildTagIds {
              type
              tagId
            }
          }
          costPaymentSchedule {
            id
            type
            payments {
              id
              date
              paidAt
              paidBy
              markup
            }
            memo
            tagIds {
              type
              tagId
            }
          }
          calendarNotes {
            date
            notes
            private
            tagIds {
              type
              tagId
            }
          }
          milestones {
            id
            type
            stage
            deliverables {
              id
            }
            lifecycleEvents {
              id
              type
              notes
              by
              at
            }
            paymentIds
          }
          currentMilestone
          showProposal
          customer {
            id
            owners
            viewers
            email
            phoneNumber
            stripeId
            hubspotId
            firstname
            lastname
            contactTimes {
              day
            }
            termsOfUse {
              name
              agreedAt
              revisedAt
            }
            projectIds
            projects {
              nextToken
            }
            createdAt
            updatedAt
            updatedMeta {
              id
              userCognitoId
              userId
              userName
              userEmail
              userGroups
              device
            }
            deleted
          }
          customerId
          invoiceProposalIds
          createdAt
          updatedAt
          updatedMeta {
            id
            userCognitoId
            userId
            userName
            userEmail
            userGroups
            device
          }
          deleted
        }
        nextToken
      }
      createdAt
      updatedAt
      updatedMeta {
        id
        userCognitoId
        userId
        userName
        userEmail
        userGroups
        device
      }
      deleted
    }
  }
`;
export const createProject = /* GraphQL */ `
  mutation CreateProject(
    $input: CreateProjectInput!
    $condition: ModelProjectConditionInput
  ) {
    createProject(input: $input, condition: $condition) {
      id
      owners
      viewers
      hubspotId
      details {
        title
        projectNotes
        address {
          line1
          line2
          city
          county
          state
          country
          zip
          formattedAddress
          location {
            lat
            lng
          }
        }
        property {
          size
          amountImpacted
        }
        desire
        styles {
          name
          description
        }
        features {
          name
          description
        }
        priorities {
          prompt
          priority
        }
        favoritePlants
        petInformation
        allergyInformation
        additionalNotes
        adminNotes
        siteAssessmentAvailability {
          day
          timeBlocks {
            start
            end
          }
        }
        budgetRange
        budgetCommitment
        useInvoiceProposals
      }
      siteAnalysis {
        id
        title
        observations
        recommendations
        color
        tagIds {
          type
          tagId
        }
      }
      files {
        id
        file {
          bucket
          region
          key
          type
          filename
        }
        caption
        selected
        tagIds {
          type
          tagId
        }
      }
      fileFolders {
        id
        name
        fileIds
      }
      annotations {
        id
        type
        location {
          lat
          lng
        }
        notes
        tagIds {
          type
          tagId
        }
      }
      zones {
        id
        type
        name
        color
        description
        center {
          lat
          lng
        }
        bounds {
          lat
          lng
        }
        tagIds {
          type
          tagId
        }
        orderedChildTagIds {
          type
          tagId
        }
      }
      features {
        id
        name
        description
        color
        center {
          lat
          lng
        }
        bounds {
          lat
          lng
        }
        tagIds {
          type
          tagId
        }
        orderedChildTagIds {
          type
          tagId
        }
        hidden
      }
      plantItems {
        id
        cost
        quantity
        notes
        source
        locations {
          lat
          lng
        }
        changes {
          key
          change
        }
        tagIds {
          type
          tagId
        }
        created
      }
      lineItems {
        id
        type
        name
        cost
        quantity
        unit
        notes
        source
        locations {
          lat
          lng
        }
        tagIds {
          type
          tagId
        }
      }
      activities {
        id
        name
        description
        dates
        tagIds {
          type
          tagId
        }
        orderedChildTagIds {
          type
          tagId
        }
      }
      phases {
        id
        name
        description
        startDate
        endDate
        tagIds {
          type
          tagId
        }
        orderedChildTagIds {
          type
          tagId
        }
      }
      costPaymentSchedule {
        id
        type
        payments {
          id
          date
          calculation {
            type
            amount
            fullPayment
            tagId {
              type
              tagId
            }
          }
          paidAt
          paidBy
          markup
        }
        memo
        tagIds {
          type
          tagId
        }
      }
      calendarNotes {
        date
        notes
        private
        tagIds {
          type
          tagId
        }
      }
      milestones {
        id
        type
        stage
        deliverables {
          id
          estimatedDelivery {
            quantity
            unit
          }
          tagIds {
            type
            tagId
          }
        }
        lifecycleEvents {
          id
          type
          notes
          by
          at
          tagIds {
            type
            tagId
          }
        }
        paymentIds
      }
      currentMilestone
      showProposal
      customer {
        id
        owners
        viewers
        email
        phoneNumber
        stripeId
        hubspotId
        firstname
        lastname
        contactTimes {
          day
          timeBlocks {
            start
            end
          }
        }
        termsOfUse {
          name
          agreedAt
          revisedAt
        }
        projectIds
        projects {
          items {
            id
            owners
            viewers
            hubspotId
            details {
              title
              projectNotes
              desire
              favoritePlants
              petInformation
              allergyInformation
              additionalNotes
              adminNotes
              budgetRange
              budgetCommitment
              useInvoiceProposals
            }
            siteAnalysis {
              id
              title
              observations
              recommendations
              color
            }
            files {
              id
              caption
              selected
            }
            fileFolders {
              id
              name
              fileIds
            }
            annotations {
              id
              type
              notes
            }
            zones {
              id
              type
              name
              color
              description
            }
            features {
              id
              name
              description
              color
              hidden
            }
            plantItems {
              id
              cost
              quantity
              notes
              source
              created
            }
            lineItems {
              id
              type
              name
              cost
              quantity
              unit
              notes
              source
            }
            activities {
              id
              name
              description
              dates
            }
            phases {
              id
              name
              description
              startDate
              endDate
            }
            costPaymentSchedule {
              id
              type
              memo
            }
            calendarNotes {
              date
              notes
              private
            }
            milestones {
              id
              type
              stage
              paymentIds
            }
            currentMilestone
            showProposal
            customer {
              id
              owners
              viewers
              email
              phoneNumber
              stripeId
              hubspotId
              firstname
              lastname
              projectIds
              createdAt
              updatedAt
              deleted
            }
            customerId
            invoiceProposalIds
            createdAt
            updatedAt
            updatedMeta {
              id
              userCognitoId
              userId
              userName
              userEmail
              userGroups
              device
            }
            deleted
          }
          nextToken
        }
        createdAt
        updatedAt
        updatedMeta {
          id
          userCognitoId
          userId
          userName
          userEmail
          userGroups
          device
        }
        deleted
      }
      customerId
      invoiceProposalIds
      createdAt
      updatedAt
      updatedMeta {
        id
        userCognitoId
        userId
        userName
        userEmail
        userGroups
        device
      }
      deleted
    }
  }
`;
export const updateProject = /* GraphQL */ `
  mutation UpdateProject(
    $input: UpdateProjectInput!
    $condition: ModelProjectConditionInput
  ) {
    updateProject(input: $input, condition: $condition) {
      id
      owners
      viewers
      hubspotId
      details {
        title
        projectNotes
        address {
          line1
          line2
          city
          county
          state
          country
          zip
          formattedAddress
          location {
            lat
            lng
          }
        }
        property {
          size
          amountImpacted
        }
        desire
        styles {
          name
          description
        }
        features {
          name
          description
        }
        priorities {
          prompt
          priority
        }
        favoritePlants
        petInformation
        allergyInformation
        additionalNotes
        adminNotes
        siteAssessmentAvailability {
          day
          timeBlocks {
            start
            end
          }
        }
        budgetRange
        budgetCommitment
        useInvoiceProposals
      }
      siteAnalysis {
        id
        title
        observations
        recommendations
        color
        tagIds {
          type
          tagId
        }
      }
      files {
        id
        file {
          bucket
          region
          key
          type
          filename
        }
        caption
        selected
        tagIds {
          type
          tagId
        }
      }
      fileFolders {
        id
        name
        fileIds
      }
      annotations {
        id
        type
        location {
          lat
          lng
        }
        notes
        tagIds {
          type
          tagId
        }
      }
      zones {
        id
        type
        name
        color
        description
        center {
          lat
          lng
        }
        bounds {
          lat
          lng
        }
        tagIds {
          type
          tagId
        }
        orderedChildTagIds {
          type
          tagId
        }
      }
      features {
        id
        name
        description
        color
        center {
          lat
          lng
        }
        bounds {
          lat
          lng
        }
        tagIds {
          type
          tagId
        }
        orderedChildTagIds {
          type
          tagId
        }
        hidden
      }
      plantItems {
        id
        cost
        quantity
        notes
        source
        locations {
          lat
          lng
        }
        changes {
          key
          change
        }
        tagIds {
          type
          tagId
        }
        created
      }
      lineItems {
        id
        type
        name
        cost
        quantity
        unit
        notes
        source
        locations {
          lat
          lng
        }
        tagIds {
          type
          tagId
        }
      }
      activities {
        id
        name
        description
        dates
        tagIds {
          type
          tagId
        }
        orderedChildTagIds {
          type
          tagId
        }
      }
      phases {
        id
        name
        description
        startDate
        endDate
        tagIds {
          type
          tagId
        }
        orderedChildTagIds {
          type
          tagId
        }
      }
      costPaymentSchedule {
        id
        type
        payments {
          id
          date
          calculation {
            type
            amount
            fullPayment
            tagId {
              type
              tagId
            }
          }
          paidAt
          paidBy
          markup
        }
        memo
        tagIds {
          type
          tagId
        }
      }
      calendarNotes {
        date
        notes
        private
        tagIds {
          type
          tagId
        }
      }
      milestones {
        id
        type
        stage
        deliverables {
          id
          estimatedDelivery {
            quantity
            unit
          }
          tagIds {
            type
            tagId
          }
        }
        lifecycleEvents {
          id
          type
          notes
          by
          at
          tagIds {
            type
            tagId
          }
        }
        paymentIds
      }
      currentMilestone
      showProposal
      customer {
        id
        owners
        viewers
        email
        phoneNumber
        stripeId
        hubspotId
        firstname
        lastname
        contactTimes {
          day
          timeBlocks {
            start
            end
          }
        }
        termsOfUse {
          name
          agreedAt
          revisedAt
        }
        projectIds
        projects {
          items {
            id
            owners
            viewers
            hubspotId
            details {
              title
              projectNotes
              desire
              favoritePlants
              petInformation
              allergyInformation
              additionalNotes
              adminNotes
              budgetRange
              budgetCommitment
              useInvoiceProposals
            }
            siteAnalysis {
              id
              title
              observations
              recommendations
              color
            }
            files {
              id
              caption
              selected
            }
            fileFolders {
              id
              name
              fileIds
            }
            annotations {
              id
              type
              notes
            }
            zones {
              id
              type
              name
              color
              description
            }
            features {
              id
              name
              description
              color
              hidden
            }
            plantItems {
              id
              cost
              quantity
              notes
              source
              created
            }
            lineItems {
              id
              type
              name
              cost
              quantity
              unit
              notes
              source
            }
            activities {
              id
              name
              description
              dates
            }
            phases {
              id
              name
              description
              startDate
              endDate
            }
            costPaymentSchedule {
              id
              type
              memo
            }
            calendarNotes {
              date
              notes
              private
            }
            milestones {
              id
              type
              stage
              paymentIds
            }
            currentMilestone
            showProposal
            customer {
              id
              owners
              viewers
              email
              phoneNumber
              stripeId
              hubspotId
              firstname
              lastname
              projectIds
              createdAt
              updatedAt
              deleted
            }
            customerId
            invoiceProposalIds
            createdAt
            updatedAt
            updatedMeta {
              id
              userCognitoId
              userId
              userName
              userEmail
              userGroups
              device
            }
            deleted
          }
          nextToken
        }
        createdAt
        updatedAt
        updatedMeta {
          id
          userCognitoId
          userId
          userName
          userEmail
          userGroups
          device
        }
        deleted
      }
      customerId
      invoiceProposalIds
      createdAt
      updatedAt
      updatedMeta {
        id
        userCognitoId
        userId
        userName
        userEmail
        userGroups
        device
      }
      deleted
    }
  }
`;
export const deleteProject = /* GraphQL */ `
  mutation DeleteProject(
    $input: DeleteProjectInput!
    $condition: ModelProjectConditionInput
  ) {
    deleteProject(input: $input, condition: $condition) {
      id
      owners
      viewers
      hubspotId
      details {
        title
        projectNotes
        address {
          line1
          line2
          city
          county
          state
          country
          zip
          formattedAddress
          location {
            lat
            lng
          }
        }
        property {
          size
          amountImpacted
        }
        desire
        styles {
          name
          description
        }
        features {
          name
          description
        }
        priorities {
          prompt
          priority
        }
        favoritePlants
        petInformation
        allergyInformation
        additionalNotes
        adminNotes
        siteAssessmentAvailability {
          day
          timeBlocks {
            start
            end
          }
        }
        budgetRange
        budgetCommitment
        useInvoiceProposals
      }
      siteAnalysis {
        id
        title
        observations
        recommendations
        color
        tagIds {
          type
          tagId
        }
      }
      files {
        id
        file {
          bucket
          region
          key
          type
          filename
        }
        caption
        selected
        tagIds {
          type
          tagId
        }
      }
      fileFolders {
        id
        name
        fileIds
      }
      annotations {
        id
        type
        location {
          lat
          lng
        }
        notes
        tagIds {
          type
          tagId
        }
      }
      zones {
        id
        type
        name
        color
        description
        center {
          lat
          lng
        }
        bounds {
          lat
          lng
        }
        tagIds {
          type
          tagId
        }
        orderedChildTagIds {
          type
          tagId
        }
      }
      features {
        id
        name
        description
        color
        center {
          lat
          lng
        }
        bounds {
          lat
          lng
        }
        tagIds {
          type
          tagId
        }
        orderedChildTagIds {
          type
          tagId
        }
        hidden
      }
      plantItems {
        id
        cost
        quantity
        notes
        source
        locations {
          lat
          lng
        }
        changes {
          key
          change
        }
        tagIds {
          type
          tagId
        }
        created
      }
      lineItems {
        id
        type
        name
        cost
        quantity
        unit
        notes
        source
        locations {
          lat
          lng
        }
        tagIds {
          type
          tagId
        }
      }
      activities {
        id
        name
        description
        dates
        tagIds {
          type
          tagId
        }
        orderedChildTagIds {
          type
          tagId
        }
      }
      phases {
        id
        name
        description
        startDate
        endDate
        tagIds {
          type
          tagId
        }
        orderedChildTagIds {
          type
          tagId
        }
      }
      costPaymentSchedule {
        id
        type
        payments {
          id
          date
          calculation {
            type
            amount
            fullPayment
            tagId {
              type
              tagId
            }
          }
          paidAt
          paidBy
          markup
        }
        memo
        tagIds {
          type
          tagId
        }
      }
      calendarNotes {
        date
        notes
        private
        tagIds {
          type
          tagId
        }
      }
      milestones {
        id
        type
        stage
        deliverables {
          id
          estimatedDelivery {
            quantity
            unit
          }
          tagIds {
            type
            tagId
          }
        }
        lifecycleEvents {
          id
          type
          notes
          by
          at
          tagIds {
            type
            tagId
          }
        }
        paymentIds
      }
      currentMilestone
      showProposal
      customer {
        id
        owners
        viewers
        email
        phoneNumber
        stripeId
        hubspotId
        firstname
        lastname
        contactTimes {
          day
          timeBlocks {
            start
            end
          }
        }
        termsOfUse {
          name
          agreedAt
          revisedAt
        }
        projectIds
        projects {
          items {
            id
            owners
            viewers
            hubspotId
            details {
              title
              projectNotes
              desire
              favoritePlants
              petInformation
              allergyInformation
              additionalNotes
              adminNotes
              budgetRange
              budgetCommitment
              useInvoiceProposals
            }
            siteAnalysis {
              id
              title
              observations
              recommendations
              color
            }
            files {
              id
              caption
              selected
            }
            fileFolders {
              id
              name
              fileIds
            }
            annotations {
              id
              type
              notes
            }
            zones {
              id
              type
              name
              color
              description
            }
            features {
              id
              name
              description
              color
              hidden
            }
            plantItems {
              id
              cost
              quantity
              notes
              source
              created
            }
            lineItems {
              id
              type
              name
              cost
              quantity
              unit
              notes
              source
            }
            activities {
              id
              name
              description
              dates
            }
            phases {
              id
              name
              description
              startDate
              endDate
            }
            costPaymentSchedule {
              id
              type
              memo
            }
            calendarNotes {
              date
              notes
              private
            }
            milestones {
              id
              type
              stage
              paymentIds
            }
            currentMilestone
            showProposal
            customer {
              id
              owners
              viewers
              email
              phoneNumber
              stripeId
              hubspotId
              firstname
              lastname
              projectIds
              createdAt
              updatedAt
              deleted
            }
            customerId
            invoiceProposalIds
            createdAt
            updatedAt
            updatedMeta {
              id
              userCognitoId
              userId
              userName
              userEmail
              userGroups
              device
            }
            deleted
          }
          nextToken
        }
        createdAt
        updatedAt
        updatedMeta {
          id
          userCognitoId
          userId
          userName
          userEmail
          userGroups
          device
        }
        deleted
      }
      customerId
      invoiceProposalIds
      createdAt
      updatedAt
      updatedMeta {
        id
        userCognitoId
        userId
        userName
        userEmail
        userGroups
        device
      }
      deleted
    }
  }
`;
export const createSupplier = /* GraphQL */ `
  mutation CreateSupplier(
    $input: CreateSupplierInput!
    $condition: ModelSupplierConditionInput
  ) {
    createSupplier(input: $input, condition: $condition) {
      id
      owners
      viewers
      email
      phoneNumber
      firstname
      lastname
      hubspotId
      stripeId
      businessName
      bio
      address {
        line1
        line2
        city
        county
        state
        country
        zip
        formattedAddress
        location {
          lat
          lng
        }
      }
      projectTypes
      qualifications {
        certificates
        degrees
        skills
      }
      links {
        facebook
        instagram
        pinterest
        twitter
        website
        youtube
      }
      pic {
        bucket
        region
        key
        type
        filename
      }
      calendarNotes {
        date
        notes
        private
        tagIds {
          type
          tagId
        }
      }
      termsOfUse {
        name
        businessType
        agreedAt
        revisedAt
      }
      createdAt
      updatedAt
      updatedMeta {
        id
        userCognitoId
        userId
        userName
        userEmail
        userGroups
        device
      }
      deleted
    }
  }
`;
export const updateSupplier = /* GraphQL */ `
  mutation UpdateSupplier(
    $input: UpdateSupplierInput!
    $condition: ModelSupplierConditionInput
  ) {
    updateSupplier(input: $input, condition: $condition) {
      id
      owners
      viewers
      email
      phoneNumber
      firstname
      lastname
      hubspotId
      stripeId
      businessName
      bio
      address {
        line1
        line2
        city
        county
        state
        country
        zip
        formattedAddress
        location {
          lat
          lng
        }
      }
      projectTypes
      qualifications {
        certificates
        degrees
        skills
      }
      links {
        facebook
        instagram
        pinterest
        twitter
        website
        youtube
      }
      pic {
        bucket
        region
        key
        type
        filename
      }
      calendarNotes {
        date
        notes
        private
        tagIds {
          type
          tagId
        }
      }
      termsOfUse {
        name
        businessType
        agreedAt
        revisedAt
      }
      createdAt
      updatedAt
      updatedMeta {
        id
        userCognitoId
        userId
        userName
        userEmail
        userGroups
        device
      }
      deleted
    }
  }
`;
export const deleteSupplier = /* GraphQL */ `
  mutation DeleteSupplier(
    $input: DeleteSupplierInput!
    $condition: ModelSupplierConditionInput
  ) {
    deleteSupplier(input: $input, condition: $condition) {
      id
      owners
      viewers
      email
      phoneNumber
      firstname
      lastname
      hubspotId
      stripeId
      businessName
      bio
      address {
        line1
        line2
        city
        county
        state
        country
        zip
        formattedAddress
        location {
          lat
          lng
        }
      }
      projectTypes
      qualifications {
        certificates
        degrees
        skills
      }
      links {
        facebook
        instagram
        pinterest
        twitter
        website
        youtube
      }
      pic {
        bucket
        region
        key
        type
        filename
      }
      calendarNotes {
        date
        notes
        private
        tagIds {
          type
          tagId
        }
      }
      termsOfUse {
        name
        businessType
        agreedAt
        revisedAt
      }
      createdAt
      updatedAt
      updatedMeta {
        id
        userCognitoId
        userId
        userName
        userEmail
        userGroups
        device
      }
      deleted
    }
  }
`;
export const createSubscriptionNotification = /* GraphQL */ `
  mutation CreateSubscriptionNotification(
    $input: CreateSubscriptionNotificationInput!
    $condition: ModelSubscriptionNotificationConditionInput
  ) {
    createSubscriptionNotification(input: $input, condition: $condition) {
      id
      subscriber
      modelName
      modelId
      expiry
      createdAt
      updatedAt
    }
  }
`;
export const updateSubscriptionNotification = /* GraphQL */ `
  mutation UpdateSubscriptionNotification(
    $input: UpdateSubscriptionNotificationInput!
    $condition: ModelSubscriptionNotificationConditionInput
  ) {
    updateSubscriptionNotification(input: $input, condition: $condition) {
      id
      subscriber
      modelName
      modelId
      expiry
      createdAt
      updatedAt
    }
  }
`;
export const deleteSubscriptionNotification = /* GraphQL */ `
  mutation DeleteSubscriptionNotification(
    $input: DeleteSubscriptionNotificationInput!
    $condition: ModelSubscriptionNotificationConditionInput
  ) {
    deleteSubscriptionNotification(input: $input, condition: $condition) {
      id
      subscriber
      modelName
      modelId
      expiry
      createdAt
      updatedAt
    }
  }
`;
export const createBundle = /* GraphQL */ `
  mutation CreateBundle(
    $input: CreateBundleInput!
    $condition: ModelBundleConditionInput
  ) {
    createBundle(input: $input, condition: $condition) {
      id
      owners
      viewers
      status
      supplierPayeeId
      orderedFeatureIds
      orderedFeatureData {
        id
        lineItemsDescription
        changeItemsDescription
        featureId
      }
      lineItems {
        id
        kind
        name
        description
        unit
        quantity
        pricePerUnit
        costPerUnit
        data {
          imageUrl
          plantDatabaseId
          changeForLineItemId
          catalogSourceId
          importSourceId
        }
        featureId
        hidden
      }
      changeItems {
        id
        kind
        name
        description
        unit
        quantity
        pricePerUnit
        costPerUnit
        data {
          imageUrl
          plantDatabaseId
          changeForLineItemId
          catalogSourceId
          importSourceId
        }
        featureId
        hidden
      }
      invoiceProposalId
      projectId
      createdAt
      updatedAt
      updatedMeta {
        id
        userCognitoId
        userId
        userName
        userEmail
        userGroups
        device
      }
      deleted
    }
  }
`;
export const updateBundle = /* GraphQL */ `
  mutation UpdateBundle(
    $input: UpdateBundleInput!
    $condition: ModelBundleConditionInput
  ) {
    updateBundle(input: $input, condition: $condition) {
      id
      owners
      viewers
      status
      supplierPayeeId
      orderedFeatureIds
      orderedFeatureData {
        id
        lineItemsDescription
        changeItemsDescription
        featureId
      }
      lineItems {
        id
        kind
        name
        description
        unit
        quantity
        pricePerUnit
        costPerUnit
        data {
          imageUrl
          plantDatabaseId
          changeForLineItemId
          catalogSourceId
          importSourceId
        }
        featureId
        hidden
      }
      changeItems {
        id
        kind
        name
        description
        unit
        quantity
        pricePerUnit
        costPerUnit
        data {
          imageUrl
          plantDatabaseId
          changeForLineItemId
          catalogSourceId
          importSourceId
        }
        featureId
        hidden
      }
      invoiceProposalId
      projectId
      createdAt
      updatedAt
      updatedMeta {
        id
        userCognitoId
        userId
        userName
        userEmail
        userGroups
        device
      }
      deleted
    }
  }
`;
export const deleteBundle = /* GraphQL */ `
  mutation DeleteBundle(
    $input: DeleteBundleInput!
    $condition: ModelBundleConditionInput
  ) {
    deleteBundle(input: $input, condition: $condition) {
      id
      owners
      viewers
      status
      supplierPayeeId
      orderedFeatureIds
      orderedFeatureData {
        id
        lineItemsDescription
        changeItemsDescription
        featureId
      }
      lineItems {
        id
        kind
        name
        description
        unit
        quantity
        pricePerUnit
        costPerUnit
        data {
          imageUrl
          plantDatabaseId
          changeForLineItemId
          catalogSourceId
          importSourceId
        }
        featureId
        hidden
      }
      changeItems {
        id
        kind
        name
        description
        unit
        quantity
        pricePerUnit
        costPerUnit
        data {
          imageUrl
          plantDatabaseId
          changeForLineItemId
          catalogSourceId
          importSourceId
        }
        featureId
        hidden
      }
      invoiceProposalId
      projectId
      createdAt
      updatedAt
      updatedMeta {
        id
        userCognitoId
        userId
        userName
        userEmail
        userGroups
        device
      }
      deleted
    }
  }
`;
export const createInvoiceProposal = /* GraphQL */ `
  mutation CreateInvoiceProposal(
    $input: CreateInvoiceProposalInput!
    $condition: ModelInvoiceProposalConditionInput
  ) {
    createInvoiceProposal(input: $input, condition: $condition) {
      id
      owners
      viewers
      name
      description
      kind
      taxPercent
      housePercent
      status
      paymentStatus
      discountItems {
        id
        kind
        name
        description
        pricePerUnit
        featureId
      }
      creditItem {
        id
        name
        creditAmount
        redistributeAmount
        direction
        creditItemId
      }
      payments {
        id
        label
        name
        description
        receiptUrl
        housePercent
        taxPercent
        houseSubtotal
        priceSubtotal
        processingFeeAmount
        processingFeePercent
        paymentMethod
        discountSubtotal
        preTaxSubtotal
        taxSubtotal
        totalAmount
        amountCharged
        amountReceived
        paidAt
        paymentSucceededAt
        stripeId
        stripeObjectType
        stripeObjectStatus
        stripeObjectCache
        items
        invoiceSentAt
      }
      payouts {
        id
        label
        paymentLabel
        description
        shortDescription
        supplierPayeeId
        taxSubtotal
        priceSubtotal
        totalAmount
        amountCharged
        amountReceived
        paidAt
        stripeId
        stripeObjectType
        stripeObjectCache
      }
      phases {
        id
        kind
        name
        description
        dates
      }
      estimatedStartDate
      estimatedEndDate
      timestamps {
        id
        kind
        description
        createdById
        createdByName
        createdByEmail
        createdByAuthId
        createdAt
      }
      totalsAs
      projectId
      bundleIds
      createdAt
      updatedAt
      updatedMeta {
        id
        userCognitoId
        userId
        userName
        userEmail
        userGroups
        device
      }
      deleted
    }
  }
`;
export const updateInvoiceProposal = /* GraphQL */ `
  mutation UpdateInvoiceProposal(
    $input: UpdateInvoiceProposalInput!
    $condition: ModelInvoiceProposalConditionInput
  ) {
    updateInvoiceProposal(input: $input, condition: $condition) {
      id
      owners
      viewers
      name
      description
      kind
      taxPercent
      housePercent
      status
      paymentStatus
      discountItems {
        id
        kind
        name
        description
        pricePerUnit
        featureId
      }
      creditItem {
        id
        name
        creditAmount
        redistributeAmount
        direction
        creditItemId
      }
      payments {
        id
        label
        name
        description
        receiptUrl
        housePercent
        taxPercent
        houseSubtotal
        priceSubtotal
        processingFeeAmount
        processingFeePercent
        paymentMethod
        discountSubtotal
        preTaxSubtotal
        taxSubtotal
        totalAmount
        amountCharged
        amountReceived
        paidAt
        paymentSucceededAt
        stripeId
        stripeObjectType
        stripeObjectStatus
        stripeObjectCache
        items
        invoiceSentAt
      }
      payouts {
        id
        label
        paymentLabel
        description
        shortDescription
        supplierPayeeId
        taxSubtotal
        priceSubtotal
        totalAmount
        amountCharged
        amountReceived
        paidAt
        stripeId
        stripeObjectType
        stripeObjectCache
      }
      phases {
        id
        kind
        name
        description
        dates
      }
      estimatedStartDate
      estimatedEndDate
      timestamps {
        id
        kind
        description
        createdById
        createdByName
        createdByEmail
        createdByAuthId
        createdAt
      }
      totalsAs
      projectId
      bundleIds
      createdAt
      updatedAt
      updatedMeta {
        id
        userCognitoId
        userId
        userName
        userEmail
        userGroups
        device
      }
      deleted
    }
  }
`;
export const deleteInvoiceProposal = /* GraphQL */ `
  mutation DeleteInvoiceProposal(
    $input: DeleteInvoiceProposalInput!
    $condition: ModelInvoiceProposalConditionInput
  ) {
    deleteInvoiceProposal(input: $input, condition: $condition) {
      id
      owners
      viewers
      name
      description
      kind
      taxPercent
      housePercent
      status
      paymentStatus
      discountItems {
        id
        kind
        name
        description
        pricePerUnit
        featureId
      }
      creditItem {
        id
        name
        creditAmount
        redistributeAmount
        direction
        creditItemId
      }
      payments {
        id
        label
        name
        description
        receiptUrl
        housePercent
        taxPercent
        houseSubtotal
        priceSubtotal
        processingFeeAmount
        processingFeePercent
        paymentMethod
        discountSubtotal
        preTaxSubtotal
        taxSubtotal
        totalAmount
        amountCharged
        amountReceived
        paidAt
        paymentSucceededAt
        stripeId
        stripeObjectType
        stripeObjectStatus
        stripeObjectCache
        items
        invoiceSentAt
      }
      payouts {
        id
        label
        paymentLabel
        description
        shortDescription
        supplierPayeeId
        taxSubtotal
        priceSubtotal
        totalAmount
        amountCharged
        amountReceived
        paidAt
        stripeId
        stripeObjectType
        stripeObjectCache
      }
      phases {
        id
        kind
        name
        description
        dates
      }
      estimatedStartDate
      estimatedEndDate
      timestamps {
        id
        kind
        description
        createdById
        createdByName
        createdByEmail
        createdByAuthId
        createdAt
      }
      totalsAs
      projectId
      bundleIds
      createdAt
      updatedAt
      updatedMeta {
        id
        userCognitoId
        userId
        userName
        userEmail
        userGroups
        device
      }
      deleted
    }
  }
`;
export const createCatalog = /* GraphQL */ `
  mutation CreateCatalog(
    $input: CreateCatalogInput!
    $condition: ModelCatalogConditionInput
  ) {
    createCatalog(input: $input, condition: $condition) {
      id
      owners
      viewers
      items {
        id
        kind
        name
        description
        unit
        quantity
        pricePerUnit
        costPerUnit
        data {
          imageUrl
          plantDatabaseId
          changeForLineItemId
          catalogSourceId
          importSourceId
        }
        featureId
        hidden
      }
      createdAt
      updatedAt
      updatedMeta {
        id
        userCognitoId
        userId
        userName
        userEmail
        userGroups
        device
      }
      deleted
    }
  }
`;
export const updateCatalog = /* GraphQL */ `
  mutation UpdateCatalog(
    $input: UpdateCatalogInput!
    $condition: ModelCatalogConditionInput
  ) {
    updateCatalog(input: $input, condition: $condition) {
      id
      owners
      viewers
      items {
        id
        kind
        name
        description
        unit
        quantity
        pricePerUnit
        costPerUnit
        data {
          imageUrl
          plantDatabaseId
          changeForLineItemId
          catalogSourceId
          importSourceId
        }
        featureId
        hidden
      }
      createdAt
      updatedAt
      updatedMeta {
        id
        userCognitoId
        userId
        userName
        userEmail
        userGroups
        device
      }
      deleted
    }
  }
`;
export const deleteCatalog = /* GraphQL */ `
  mutation DeleteCatalog(
    $input: DeleteCatalogInput!
    $condition: ModelCatalogConditionInput
  ) {
    deleteCatalog(input: $input, condition: $condition) {
      id
      owners
      viewers
      items {
        id
        kind
        name
        description
        unit
        quantity
        pricePerUnit
        costPerUnit
        data {
          imageUrl
          plantDatabaseId
          changeForLineItemId
          catalogSourceId
          importSourceId
        }
        featureId
        hidden
      }
      createdAt
      updatedAt
      updatedMeta {
        id
        userCognitoId
        userId
        userName
        userEmail
        userGroups
        device
      }
      deleted
    }
  }
`;
