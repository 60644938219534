import { GraphQLKit } from "@thrivelot/gql";
import { client } from "./client";
import { constructGql } from "./constructGql";

const scan = ({ modelName, variables, resolveItems, queryType = "index" }) => {
  const gql = constructGql({ modelName, queryType });

  const graphqlKit = new GraphQLKit({
    gql,
    variables,
    resolveItems,
    ...(client.endpoint && { endpoint: client.endpoint }),
    ...(client.apiKey && { apiKey: client.apiKey }),
    ...(client.fetchJwt && { fetchJwt: client.fetchJwt }),
    ...(client.queryNameRegex && { queryNameRegex: client.queryNameRegex }),
  });

  return graphqlKit.scan();
};

export { scan };
