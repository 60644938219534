import styled, { css } from "styled-components";
import CssInsetShadow from "./CssInsetShadow";
import CssRaisedShadow from "./CssRaisedShadow";
import CssDivDefault from "./CssDivDefault";

const shadow = ({ shadowInset, shadowRaised }) => {
  if (shadowInset) return CssInsetShadow;
  if (shadowRaised) return CssRaisedShadow;
  return "";
};

const DivColumn = styled.div`
  ${CssDivDefault}
  ${shadow}
  display: flex;
  flex-direction: column;
  min-height: fit-content;
  max-height: fit-content;
  ${({ margin }) =>
    margin &&
    css`
      margin: ${margin};
    `}
`;

export default DivColumn;
