import styled from "styled-components";

const DivModalBanner = styled.div`
  transition: opacity 0.5s ease-out, box-shadow 0.5s ease-out, color 1s ease-in-out, background-color 1s ease-in-out;
  padding: 12px 40px 12px 12px;
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid ${({ theme }) => theme.divLine};
`;

export default DivModalBanner;
