import { getProjectStage } from "./getProjectStage";

const getProjectStageAbbreviation = (project) => {
  const stage = getProjectStage(project) || "";
  let abbr = "";

  const split = stage.split("_");
  split.forEach((word) => {
    abbr += word.charAt(0);
  });

  return abbr;
};

export { getProjectStageAbbreviation };
