import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useModel, useModelList, useAuth } from '@thrivelot/hooks';
import { ModelKit } from '@thrivelot/model-kit';
import { constructUuid, transformStringToTitleCase } from '@thrivelot/utils';
import { Button, Descriptor } from '@thrivelot/stories';
import { LayoutModal } from '../..';
import {
  getDefaultsForOption,
  SelectInvoiceProposalDefaults,
} from './SelectInvoiceProposalDefaults';

const ModalAddInvoiceProposal = ({ onClose, projectId }) => {
  const { userId } = useAuth();

  console.log('blah hi userId', userId);

  const {
    model: project,
    loaded,
    actions,
    updateModel,
  } = useModel({ modelName: 'Project', id: projectId });
  const {
    models,
    loaded: suppliersLoaded,
    loading: suppliersLoading,
    query: querySuppliers,
  } = useModelList({ modelName: 'Supplier', deferLoad: true });

  const {
    models: invoiceProposals,
    loaded: invoiceProposalsLoaded,
    loading: invoiceProposalsLoading,
  } = useModelList({
    modelName: 'InvoiceProposal',
    filter: { projectId: { eq: projectId } },
  });

  const [primarySupplier, setPrimarySupplier] = useState();

  useEffect(() => {
    if (
      (project?.owners || []).length > 0 &&
      !suppliersLoaded &&
      !suppliersLoading
    )
      querySuppliers({ owners: { contains: project.owners[0] } }); // Primary supplier is the first owner in the owners array
  }, [project?.owners]); // Reducing deps to limit the number of times the query runs

  useEffect(() => {
    if (suppliersLoaded && (models || []).length > 0)
      setPrimarySupplier(models[0]);
  }, [suppliersLoaded, setPrimarySupplier, models]); // Reducing deps to limit the number of times the primary supplier gets set

  // if (!loaded || loading) return <RowItemLoader classAppend="!px-0 !py-0 !bg-transparent" />;

  // const supplier = models[0];

  const [errors, setErrors] = useState([]);
  const [saving, setSaving] = useState(false);

  const [name, setName] = useState('');
  const [defaults, setDefaults] = useState(getDefaultsForOption('design'));

  const [creditAmount, creditCostAmount, creditRedeemed, creditLoaded] =
    useMemo(() => {
      if (!invoiceProposalsLoaded) return [];

      const siteVisitIP = invoiceProposals.find(
        (invoiceProposal) => invoiceProposal.name === 'Site Visit'
      );

      const creditRedeemedIP = invoiceProposals.find((invoiceProposal) => {
        if (
          [
            'approved',
            'active',
            'submitted_for_completion',
            'approved_for_completion',
            'complete',
          ].includes(invoiceProposal.status) &&
          invoiceProposal.name !== 'Site Visit'
        )
          return true;

        return false;
      });

      const creditRedeemedResult = !!creditRedeemedIP;

      const creditAmountResult = siteVisitIP?.payments.find(
        (payment) =>
          payment.label === 'single_payment_1' && payment.name === 'Site Visit'
      )?.amountCharged;

      const creditCostAmountResult = siteVisitIP?.payments.find(
        (payment) =>
          payment.label === 'single_payment_1' && payment.name === 'Site Visit'
      )?.priceSubtotal;

      return [
        creditAmountResult,
        creditCostAmountResult,
        creditRedeemedResult,
        true,
      ];
    });

  console.log('blah hi primarySupplier', primarySupplier);

  const handleCreateInvoiceProposal = useCallback(async () => {
    setSaving(true);
    setErrors([]);

    const newBundle = {
      id: constructUuid(),
      owners: project.owners,
      viewers: project.viewers,
      status: 'unlocked',
      lineItems: [],
      projectId,
      ...defaults.bundleValues,
    };

    if (userId) {
      newBundle.supplierPayeeId = userId;
    } else if (primarySupplier?.id)
      newBundle.supplierPayeeId = primarySupplier.id; // Make the default payee the primary supplier on the project

    const newInvoiceProposal = {
      id: constructUuid(),
      owners: project.owners,
      viewers: project.viewers,

      name,
      status: 'draft',
      paymentStatus: 'waiting',
      payments: [],
      payouts: [],
      phases: [],

      projectId: project.id,
      bundleIds: [newBundle.id],
      ...defaults.invoiceProposalValues,
    };

    newBundle.invoiceProposalId = newInvoiceProposal.id;

    if (!creditRedeemed) {
      newInvoiceProposal.creditItem = {
        id: constructUuid(),
        name: 'Credit',
        creditAmount,
        redistributeAmount: 0,
        direction: 'outflow',
      };

      newBundle.orderedFeatureData = [
        { id: constructUuid(), featureId: '__project__' },
      ];

      newBundle.lineItems = [
        ...newBundle.lineItems,
        {
          id: constructUuid(),
          kind: 'labor',
          name: 'Initial consultation fee',
          description: '',
          unit: 'units',
          quantity: 1,
          pricePerUnit: 25000,
          featureId: '__project__',
        },
      ];
    }

    try {
      const invoiceProposalKit = new ModelKit({
        modelName: 'InvoiceProposal',
        model: newInvoiceProposal,
      });
      await invoiceProposalKit.create();

      const bundleKit = new ModelKit({ modelName: 'Bundle', model: newBundle });
      await bundleKit.create();

      updateModel(
        actions.add('invoiceProposalIds', newInvoiceProposal.id, 0).result
      );
      onClose();
    } catch (e) {
      setErrors([
        'There was a problem creating the order. Please try again or submit a bug report.',
      ]);
    }
    setSaving(false);
  }, [
    project?.owners,
    project?.viewers,
    project?.id,
    primarySupplier?.id,
    projectId,
    defaults?.bundleValues,
    defaults?.invoiceProposalValues,
    name,
    updateModel,
    actions,
    onClose,
  ]);

  return (
    <LayoutModal
      onClose={onClose}
      loading={!loaded || !suppliersLoaded}
      header={
        loaded && (
          <div className="text-lg text-brown-dark font-bold text-center">
            Add Order
          </div>
        )
      }
      footer={
        <Button
          disabled={!name || saving || !creditLoaded}
          loading={saving}
          block
          kind="outline"
          color="blue-main"
          label="Submit"
          onClick={handleCreateInvoiceProposal}
        />
      }
    >
      <div className="flex flex-col gap-4">
        <div>
          <div className="text-green-dark font-bold mb-1">Order Name</div>
          <input
            className="input"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </div>
        <div>
          <div className="text-green-dark font-bold mb-1">Default Options</div>
          <div className="text-green-dark text-sm italic mb-4">
            This sets some default options for the order. All options can be
            changed later.
          </div>

          <SelectInvoiceProposalDefaults
            defaults={defaults}
            setDefaults={setDefaults}
          />
          <Descriptor
            classAppend="mt-4"
            icon={defaults.icon}
            header={transformStringToTitleCase(defaults.option)}
            body={defaults.description}
          />
        </div>
        {errors.length > 0 && (
          <div className="">
            {errors.map((error) => (
              <div key={error} className="text-red-main text-sm">
                {error}
              </div>
            ))}
          </div>
        )}
      </div>
    </LayoutModal>
  );
};

export { ModalAddInvoiceProposal };
