import { ProjectLineItemType } from "@thrivelot/aws";

const calculate100Materials50Labor = (lineItems, plantItems) => {
  let laborCost = 0;
  let materialsCost = 0;

  lineItems.forEach((item) => {
    switch (item.type) {
      case ProjectLineItemType.LABOR:
        laborCost += item.cost * item.quantity;
        break;
      default:
        materialsCost += item.cost * item.quantity;
    }
  });

  plantItems.forEach((item) => {
    materialsCost += item.cost * item.quantity;
  });

  return { laborCost: laborCost / 2, materialsCost };
};

export { calculate100Materials50Labor };
