import React from "react";
import colors from "@thrivelot/tailwind/colors";
import { useTheme } from "@thrivelot/hooks";
import { DivLineHorizontal, TextBody, TextHeader, FeatureIcon } from "@thrivelot/styles";
import { useInnerSpacing, useSpacing } from "../../../../hooks";
import { FeatureContainer } from "./FeatureContainer";

const Feature = ({ feature }) => {
  const theme = useTheme();
  const spacing = useSpacing();
  const innerSpacing = useInnerSpacing();
  const negativeMargin = `-${innerSpacing}`;
  if (!feature.name || feature.name.trim() === "") return null;
  return (
    <FeatureContainer style={{ margin: `0px ${spacing} ${spacing}` }}>
      <div
        style={{
          display: "flex",
          minHeight: "fit-content",
        }}
      >
        <FeatureIcon size={20} color={colors.gold} style={{ marginTop: "3px" }} />
        <TextHeader style={{ color: theme.textTitle, marginLeft: innerSpacing }}>{feature.name}</TextHeader>
      </div>
      {feature.description && feature.description.trim() !== "" && (
        <>
          <DivLineHorizontal
            style={{
              margin: `${innerSpacing} ${negativeMargin}`,
              width: `calc(100% + ${innerSpacing} + ${innerSpacing})`,
            }}
          />
          <TextBody style={{ color: theme.textTitle }}>{feature.description}</TextBody>
        </>
      )}
    </FeatureContainer>
  );
};

export { Feature };
