import React, { useState, Fragment, useCallback } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { LeafLoader, Cancel } from '@thrivelot/stories';

export const PdfLayout = ({
  isOpen: externalIsOpen,
  setIsOpen: setExternalIsOpen,
  onClose = () => null,
  header,
  loading,
  subheader,
  children,
}) => {
  const [localIsOpen, setLocalIsOpen] = useState(true);

  const handleClose = useCallback(() => {
    setLocalIsOpen(false);
    if (setExternalIsOpen) setExternalIsOpen(false);
    onClose();
  }, [setLocalIsOpen, setExternalIsOpen, onClose]);

  return (
    <Transition
      appear
      show={setExternalIsOpen ? externalIsOpen : localIsOpen}
      as={Fragment}
      // afterLeave={onClose}
    >
      <Dialog
        as="div"
        className="fixed inset-0 z-20 overflow-y-auto"
        onClose={() => handleClose()}
      >
        <div className="min-h-screen px-4 text-center">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-mulch-dark opacity-80" />
          </Transition.Child>
          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="inline-block h-screen align-middle"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div
              className={`inline-block w-full p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white-dark shadow-xl rounded-main`}
            >
              {loading ? (
                <LeafLoader />
              ) : (
                <>
                  <Dialog.Title
                    as="div"
                    className="text-lg flex items-start font-medium leading-6 text-gray-900"
                  >
                    <h3 className="flex-1 text-brown-dark">{header}</h3>
                    <div className="hover:cursor-pointer" onClick={handleClose}>
                      <Cancel color="orange-light" size={20} />
                    </div>
                  </Dialog.Title>

                  {subheader && <div className="mt-4">{subheader}</div>}
                  <div className="py-4 text-brown-dark">{children}</div>
                </>
              )}
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
};
