import { dateFns } from "@thrivelot/date";
import { constructUuid } from "@thrivelot/utils";

const constructPhase = ({
  id = constructUuid(),
  name = "",
  description = "",
  startDate = dateFns(new Date()).add(7, "day").toISOString(),
  endDate = dateFns(startDate).add(14, "day").toISOString(),
  orderedChildTagIds = [],
}) => ({ id, name, description, startDate, endDate, orderedChildTagIds });

export { constructPhase };
