import React from "react";
import { JumpBar } from "./JumpBar";
import { TitleBar } from "./TitleBar";

const TopBar = () => (
  <header className="text-gray-600 body-font shadow w-full">
    <div className="container mx-auto flex flex-col py-4 px-5">
      <TitleBar />
      <JumpBar />
    </div>
  </header>
);

export { TopBar };
