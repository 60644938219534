import React from 'react';

const CheckEmpty = ({
  color = 'green-main',
  size = 30,
  styleProps = '',
  ...rest
}) => (
  <svg
    width={size.toString()}
    height={size.toString()}
    viewBox="0 0 75 75"
    className={`fill-${color} ${styleProps}`}
    {...rest}
  >
    <path d="M60.1098 75H14.8902C6.67683 75 0 68.3232 0 60.1098V14.8902C0 6.67683 6.67683 0 14.8902 0H60.1098C68.3232 0 75 6.67683 75 14.8902V60.1098C75 68.3232 68.3232 75 60.1098 75ZM14.8902 9.14634C11.7256 9.14634 9.14634 11.7256 9.14634 14.8902V60.1098C9.14634 63.2744 11.7256 65.8537 14.8902 65.8537H60.1098C63.2744 65.8537 65.8537 63.2744 65.8537 60.1098V14.8902C65.8537 11.7256 63.2744 9.14634 60.1098 9.14634H14.8902Z" />
  </svg>
);

export { CheckEmpty };
