import React, { Fragment } from "react";
import { useIsMobile } from "@thrivelot/hooks";
import { transformCurrencyToString } from "@thrivelot/utils";
import { DivContentRow, DivInset, DivLineHorizontal, TextSmallHeader } from "@thrivelot/styles";
import { styleForListItemCostContainer } from "../helpers";

const PhaseCalculation = ({ featureName, calculation }) => {
  const theme = window.$theme;

  const isMobile = useIsMobile();

  const { amount } = calculation;

  const title = featureName === "" ? "Amount" : featureName;

  return (
    <Fragment>
      <DivContentRow style={{ alignItems: "center", justifyContent: "space-between" }}>
        <TextSmallHeader
          color={theme.green}
          style={{
            marginLeft: isMobile ? "15px" : "274px",
            marginRight: "12px",
            fontSize: isMobile ? "12px" : undefined,
          }}
        >
          {title}
        </TextSmallHeader>
        <DivInset
          style={{
            ...styleForListItemCostContainer,
            marginRight: isMobile ? undefined : "55px",
          }}
        >
          <TextSmallHeader>{transformCurrencyToString(Math.ceil(amount / 100) * 100)}</TextSmallHeader>
        </DivInset>
      </DivContentRow>
      <DivLineHorizontal />
    </Fragment>
  );
};

export { PhaseCalculation };
