import { findItemsByTags } from "@thrivelot/actions";
import { calculateFeatureCost } from "./calculateFeatureCost";

const calculatePhaseCost = (model, phaseId) => {
  const features = findItemsByTags(model, `phases[id:${phaseId}]`, "features");
  let phaseCost = 0;
  features.forEach((feature) => {
    phaseCost += calculateFeatureCost(model, feature.id);
  });
  return phaseCost;
};

export { calculatePhaseCost };
