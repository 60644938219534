const getFeatureListForWorkPhases = (phases) => {
  if (!phases) return {};

  const lists = {};
  phases.forEach((phase, index) => {
    lists[phase.id] = { index, list: phase.orderedChildTagIds || [] };
  });

  return lists;
};

export { getFeatureListForWorkPhases };
