import { isNumber } from './isNumber';

const transformCurrencyToString = (currency = 0, props) => {
  const { hideSymbol } = props || {};
  if (!isNumber(`${currency}`)) {
    console.error(`${currency} is not a number`);

    return '$0.00';
  }

  let isNegative = false;
  if (currency < 0) {
    isNegative = true;
    currency *= -1;
  }

  let value = (currency / 100).toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD',
  });

  if (hideSymbol) value = value.split('$')[1];
  if (isNegative) value = `-${value}`;

  return value;
};

export { transformCurrencyToString };
