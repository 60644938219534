import React, { useMemo } from 'react';
import { projectPriorities as availablePriorities } from '@thrivelot/config';
import { Card, CardLoader, Edit } from '@thrivelot/stories';
import { useModal, useModel } from '@thrivelot/hooks';

// ! Possibly deprecated since this is built for the old customer form

const CardProjectPriorities = ({ projectId, editable }) => {
  const { openModal } = useModal();
  const { model: project, loaded } = useModel({
    modelName: 'Project',
    id: projectId,
  });

  const includedPriorities = useMemo(() => {
    if (project?.details?.priorities) {
      const priorities = project?.details?.priorities
        .map((priority) => {
          const key = Object.keys(availablePriorities).find(
            (key) =>
              availablePriorities[key].slice(0, 10) ===
              priority.prompt.replace(/^Priority: /, '').slice(0, 10)
          );

          if ((priority.priority || 0) > 0) {
            return { ...priority, prompt: availablePriorities[key], key };
          }

          return null;
        })
        .filter((item) => !!item);

      return priorities.sort((a, b) => (a.priority < b.priority ? 1 : -1));
    }

    return [];
  }, [project?.details?.priorities]);

  if (!loaded) return <CardLoader showText />;

  if (includedPriorities.length === 0) {
    return (
      <Card classAppend="mb-4">
        <div className="flex">
          <div className="flex-1 italic flex text-sm items-center text-brown-dark">
            No <span className="font-bold mx-1">priorities</span> to show
          </div>
          {editable && (
            <Edit
              color="yellow-dark"
              styleProps="hover:cursor-pointer"
              size="20"
              onClick={() =>
                openModal({ path: 'ModalEditPriorities', projectId })
              }
            />
          )}
        </div>
      </Card>
    );
  }

  return (
    <Card
      header="Priorities"
      headerActions={
        editable && (
          <Edit
            color="yellow-dark"
            styleProps="hover:cursor-pointer"
            size="20"
            onClick={() =>
              openModal({ path: 'ModalEditPriorities', projectId })
            }
          />
        )
      }
      classAppend="mb-4"
    >
      <div className="divide-y divide-tan-light">
        {includedPriorities.map(({ key, prompt, priority }) => (
          <div key={key} className="p-1.5">
            <div className="flex items-center justify-start">
              <div>
                <div
                  className={`rounded-full h-6 w-6 bg-green-light font-bold flex items-center justify-center`}
                >
                  <div className={`font-bold text-green-dark`}>{priority}</div>
                </div>
              </div>
              <div className="text-brown-dark" style={{ marginLeft: '8px' }}>
                {prompt}
              </div>
            </div>
          </div>
        ))}
      </div>
    </Card>
  );
};

export { CardProjectPriorities };
