/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onSubscriptionNotification = /* GraphQL */ `
  subscription OnSubscriptionNotification($subscriber: String) {
    onSubscriptionNotification(subscriber: $subscriber) {
      id
      subscriber
      modelName
      modelId
      expiry
      createdAt
      updatedAt
    }
  }
`;
