import { fetchService } from "./fetchService";

const fetchCognitoUserByEmail = async (email) => {
  try {
    return await fetchService({ method: "get", level: "admin", path: "get-user-by-email", variables: { email } });
  } catch (err) {
    console.error("Error: ", err);
    throw err;
  }
};

export { fetchCognitoUserByEmail };
