import React from 'react';
import { useCatalog, useModal, useAuth } from '@thrivelot/hooks';
import {
  Button,
  EmptyState,
  PageContainer,
  LeafLoader,
} from '@thrivelot/stories';
import { Link } from 'react-router-dom';
import { HelpPrompt, Catalog } from '@thrivelot/components';

const PageCatalog = () => {
  const { openModal } = useModal();

  const {
    user: { username },
  } = useAuth();

  const {
    catalog,
    creating,
    loaded,
    create: createCatalog,
  } = useCatalog({
    ownerId: username,
  });

  return (
    <PageContainer
      icon="Catalog"
      header="Catalog"
      headerActions={
        <div className="flex items-center gap-4">
          <Link to="import">
            <Button
              label="Import Items"
              size="small"
              kind="outline"
              icon="Plus"
              color="blue-main"
            />
          </Link>
          <Button
            label="Add Item"
            size="small"
            kind="outline"
            icon="Plus"
            color="green-main"
            onClick={() =>
              openModal({
                path: 'ModalUpsertCatalogItem',
                catalogId: catalog.id,
              })
            }
          />
        </div>
      }
      documentTitle="Catalog"
      loading={!loaded}
      help={
        <HelpPrompt header="About the Catalog">
          Manage your pricing and costs with ease. The Catalog Page is your
          one-stop-shop for all line items used in forming Orders for projects.
          Easily add, update, delete, and import plants, materials, labor, and
          equipment line items.
        </HelpPrompt>
      }
    >
      {creating && <LeafLoader classAppend="mt-4" />}

      {!creating && loaded && !catalog && (
        <EmptyState
          icon="LineItems"
          header="No catalog yet..."
          children={
            <div>
              Create a catalog or wait to be added to an existing catalog.
            </div>
          }
          footer={
            <div className="flex gap-6 justify-center">
              <Button
                icon="Plus"
                size="small"
                color="green-main"
                kind="outline"
                label="New Catalog"
                onClick={() => createCatalog()}
              />
              <HelpPrompt
                button={
                  <Button
                    icon="Plus"
                    size="small"
                    color="blue-main"
                    kind="outline"
                    label="Add to Existing Catalog"
                    onClick={() => createCatalog()}
                  />
                }
              >
                Please open a support ticket to be added to an existing catalog.
              </HelpPrompt>
            </div>
          }
        />
      )}

      {!creating && catalog && <Catalog catalogId={catalog.id} />}
    </PageContainer>
  );
};

export { PageCatalog };
