import React, { useMemo } from "react";
import ReactSelect from "react-select";
import { customSelectStyles } from "./helpers";

const Select = ({
  options,
  value,
  onSubmit,
  onChange,
  noIcon = false,
  placeholder = "Select...",
  menuPlacement = "bottom",
  style = {},
}) => {
  const handleOnKeyDown = ({ key }) => {
    if (key === "Enter" && onSubmit) onSubmit();
  };

  let extraProps = {};
  if (noIcon) extraProps = { components: { DropdownIndicator: null } };

  const styles = useMemo(() => customSelectStyles(style), [style]);

  return (
    <ReactSelect
      blurInputOnSelect
      placeholder={placeholder}
      menuPlacement={menuPlacement}
      styles={styles}
      options={options}
      value={value}
      onChange={onChange}
      onKeyDown={handleOnKeyDown}
      {...extraProps}
    />
  );
};

export default Select;
