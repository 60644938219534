import { constructAddress } from "./constructAddress";
import { constructProperty } from "./constructProperty";

const constructDetails = ({
  title = "",
  projectNotes = "",
  desire = "",
  address = constructAddress(),
  property = constructProperty(),
  styles = [],
  features = [],
  priorities = [],
  favoritePlants = "",
  petInformation = "",
  allergyInformation = "",
  additionalNotes = "",
  adminNotes = "",
  budgetCommitment = 0,
}) => ({
  title,
  projectNotes,
  desire,
  address,
  property,
  styles,
  features,
  priorities,
  favoritePlants,
  petInformation,
  allergyInformation,
  additionalNotes,
  adminNotes,
  budgetCommitment,
});

export { constructDetails };
