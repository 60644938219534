import React from 'react';

const Phone = ({
  color = 'green-main',
  size = 30,
  styleProps = '',
  ...rest
}) => (
  <svg
    width={size.toString()}
    height={size.toString()}
    viewBox="0 0 75 75"
    className={`fill-${color} ${styleProps}`}
    {...rest}
  >
    <path d="M55.2099 75C54.0141 75 52.8278 74.8576 51.6984 74.5539C42.2835 72.0008 30.0594 64.1043 20.5402 54.4234C10.7647 44.5148 2.88732 32.3093 0.448196 23.3308C-0.776113 18.6897 0.571576 13.2228 3.71302 10.0054L10.888 2.68777C13.8966 -0.396821 18.642 -0.890354 21.9258 1.53935C22.0587 1.64375 22.1916 1.74815 22.3149 1.87154L31.2268 10.888C32.6504 12.3212 33.4191 14.2289 33.4096 16.2505C33.3907 18.2721 32.5934 20.1608 31.1508 21.5749L30.2397 22.4671C28.892 23.7958 27.5633 25.0961 26.6902 25.9503C26.396 26.235 26.3295 26.6906 26.5288 27.0513C28.3795 30.3636 31.4166 35.1471 35.3647 39.1049L35.8962 39.6364C39.8539 43.5847 44.6277 46.6218 47.94 48.463C48.3007 48.6624 48.7562 48.5959 49.0409 48.3017C49.9331 47.3906 51.3282 45.9669 52.7234 44.5432L53.4257 43.8314C54.8398 42.3888 56.7285 41.5915 58.75 41.5726C60.7336 41.5441 62.6697 42.3318 64.1123 43.746L73.1285 52.6581C73.2519 52.7815 73.3658 52.9049 73.4607 53.0472C75.8903 56.3311 75.3968 61.0766 72.3123 64.0853L65.0139 71.251C62.5653 73.6523 58.8639 75 55.2099 75ZM17.1804 6.66451C16.6774 6.66451 16.089 6.87332 15.6334 7.34787L8.45841 14.656C6.97785 16.1745 6.25655 19.2971 6.86396 21.6129C8.62924 28.1048 15.007 39.3422 25.2666 49.7633C35.2319 59.8808 46.5733 66.2777 53.4067 68.138C55.694 68.7454 58.8259 68.0241 60.3445 66.534L67.6429 59.3683C68.3452 58.6849 68.478 57.7263 68.1838 57.1663L59.4144 48.501C59.1961 48.2827 58.9493 48.2637 58.769 48.2448C58.5982 48.2448 58.3514 48.2922 58.1331 48.5105L57.4308 49.2223C56.0451 50.6365 54.6595 52.0412 53.7768 52.9523C51.3947 55.401 47.6553 55.9515 44.6752 54.2811C40.9928 52.231 35.6684 48.8332 31.1698 44.3534L30.6288 43.8124C26.1397 39.3137 22.7515 33.9892 20.692 30.3067C19.0311 27.3265 19.5721 23.587 22.0207 21.2048C22.8844 20.3601 24.2036 19.0693 25.5513 17.75L26.4624 16.8484C26.6807 16.6301 26.7281 16.3833 26.7281 16.2125C26.7281 16.0417 26.6902 15.7949 26.4719 15.5766L17.8068 6.80688C17.6455 6.71197 17.4272 6.66451 17.1804 6.66451Z" />
  </svg>
);

export { Phone };
