const calculateDesignCost = (total, markup, siteVisitCost) => {
  if (total === 0) return { designCost: 0, dollarMarkup: 0 };

  const divider = markup + 1;
  const growerTake = Math.floor(total / divider);
  const fullGrowerTake = growerTake + 25000;

  const designCost = Math.floor(fullGrowerTake * divider) - siteVisitCost;

  const dollarMarkup = designCost - growerTake;

  return { designCost, dollarMarkup };
};

export { calculateDesignCost };
