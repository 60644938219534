import { get } from "@thrivelot/actions";

const calculateTotalLaborCost = (model) => {
  const lineItems = get(model, "lineItems");
  let laborCost = 0;
  lineItems.forEach((lineItem) => {
    if (lineItem.type === "LABOR") {
      laborCost += lineItem.cost * lineItem.quantity;
    }
  });
  return laborCost;
};

export { calculateTotalLaborCost };
