import React from 'react';
import {
  transformCurrencyToFloat,
  transformStringToTitleCase,
} from '@thrivelot/utils';
import { lineItemColorMap, lineItemIconMap } from '@thrivelot/config';
import * as icons from '@thrivelot/stories';
import { LineItemImage } from '../LineItemImage';

export const RowItemCatalog = ({
  item,
  actions,
  disabled,
  classAppend,
  onClick,
}) => {
  const {
    name,
    commonName,
    species,
    genus,
    description,
    pricePerUnit,
    unit,
    kind,
    costPerUnit,
    quantity,
  } = item;
  const Icon = icons[lineItemIconMap[kind]] || icons.Leaf;

  const trueName = name || commonName || `${genus || ''} ${species || ''}`;

  return (
    <div
      className={`${
        disabled
          ? 'text-tan-main'
          : 'text-brown-dark hover:bg-white-light hover:cursor-pointer'
      } flex py-2 px-3 ${classAppend}`}
      onClick={onClick}
    >
      <div className="flex-1 flex min-w-0">
        <div
          className={`flex-1 flex min-w-0 items-center gap-2 ${
            disabled ? 'text-tan-main' : 'text-brown-dark'
          }`}
        >
          <Icon
            size={20}
            color={disabled ? 'tan-main' : lineItemColorMap[kind]}
          />
          <LineItemImage
            item={item}
            classAppend="h-[20px] w-[20px] flex items-center justify-center overflow-hidden rounded-full mr-1"
          />
          <div className="w-full flex-1 text-ellipsis overflow-hidden whitespace-nowrap">
            {trueName}
            {description && (
              <span className="text-sm italic"> - {description}</span>
            )}
          </div>
          <div className="italic ">
            {quantity || '1'} x {transformStringToTitleCase(unit)}
          </div>
          {costPerUnit && (
            <div
              className={`${
                disabled ? 'text-tan-main' : 'text-brown-dark'
              } mx-1 text-sm`}
            >
              (${transformCurrencyToFloat(costPerUnit || 0).toString()})
            </div>
          )}
          <div className={`${disabled ? 'text-tan-main' : 'text-green-main'}`}>
            ${transformCurrencyToFloat(pricePerUnit || 0).toString()}
          </div>
        </div>
      </div>
      {actions && (
        <div className="ml-3 pl-3 border-l border-tan-light flex items-center">
          {actions}
        </div>
      )}
    </div>
  );
};
