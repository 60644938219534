import React from "react";
import { useMapProvider } from "./useMapProvider";
import { MapContext } from "./MapContext";

const MapProvider = ({ children }) => {
  const mapProvider = useMapProvider();

  return <MapContext.Provider value={mapProvider}>{children}</MapContext.Provider>;
};

export { MapProvider };
