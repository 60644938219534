import { constructUuid } from "@thrivelot/utils";

const constructKeyMap = () => {
  const keyMap = {};

  for (let i = 0; i < 10; i++) {
    keyMap[`${i}`] = constructUuid();
  }

  return keyMap;
};

export { constructKeyMap };
