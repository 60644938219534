import React from 'react';

const CreditCard = ({
  color = 'green-main',
  size = 30,
  styleProps = '',
  ...rest
}) => (
  <svg
    width={size.toString()}
    height={size.toString()}
    viewBox="0 0 75 75"
    className={`fill-${color} ${styleProps}`}
    {...rest}
  >
    <path d="M61.7089 9H13.2911C5.98101 9 0 14.981 0 22.2911V53.6203C0 60.9304 5.98101 66.9114 13.2911 66.9114H61.7089C69.019 66.9114 75 60.9304 75 53.6203V22.2911C75 14.981 69.019 9 61.7089 9ZM6.64557 22.2911C6.64557 18.6835 9.58861 15.6456 13.2911 15.6456H61.7089C65.3165 15.6456 68.3544 18.5886 68.3544 22.2911V24.5696H6.64557V22.2911ZM68.3544 53.6203C68.3544 57.2278 65.4114 60.2658 61.7089 60.2658H13.2911C9.68354 60.2658 6.64557 57.3228 6.64557 53.6203V34.0633H68.3544V53.6203Z" />
    <path d="M32.0882 54.6644H14.9995C13.1957 54.6644 11.6768 53.1454 11.6768 51.3416C11.6768 49.5378 13.1957 48.0188 14.9995 48.0188H32.0882C33.8919 48.0188 35.4109 49.5378 35.4109 51.3416C35.4109 53.1454 33.8919 54.6644 32.0882 54.6644Z" />
  </svg>
);

export { CreditCard };
