import React from "react";
import { useTheme } from "@thrivelot/hooks";
import { DivColumn, TextBody, TextTitle } from "@thrivelot/styles";
import { NameContainer } from "./NameContainer";

const Name = ({ plantInfo }) => {
  const theme = useTheme();
  const textStyle = { color: theme.textLight };
  return (
    <NameContainer>
      <DivColumn>
        <TextTitle style={textStyle}>{plantInfo.commonName}</TextTitle>
        <TextBody style={{ ...textStyle, fontStyle: "italic" }}>{plantInfo.scientificName}</TextBody>
      </DivColumn>
    </NameContainer>
  );
};

export { Name };
