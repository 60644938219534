import React, { useMemo, useState } from 'react';
import { clamp } from './clamp';
import * as constants from './constants';
import { Canvas } from './Canvas';

export interface ZoomOptions {
  minZoom?: number;
  maxZoom?: number;
  zoomStep?: number;
  zoomScale?: number;
  zoomOnWheel?: boolean;
}

export const useZoom = (src: string | undefined | null, opts?: ZoomOptions) => {
  const [zoom, setZoom] = useState(1);
  const [isSizeToFit, setIsSizeToFit] = useState(true);

  const { minZoom, maxZoom, zoomStep, zoomScale, zoomOnWheel } = useMemo(
    () => ({
      minZoom: opts?.minZoom ?? constants.minZoom,
      maxZoom: opts?.maxZoom ?? constants.maxZoom,
      zoomStep: opts?.zoomStep ?? constants.zoomStep,
      zoomScale: opts?.zoomScale ?? constants.zoomScale,
      zoomOnWheel: opts?.zoomOnWheel ?? constants.zoomOnWheel,
    }),
    [opts]
  );

  const zoomIn = () => {
    const newZoom = clamp(zoom * zoomScale, minZoom, maxZoom);
    setZoom(newZoom);
  };

  const zoomOut = () => {
    const newZoom = clamp(zoom / zoomScale, minZoom, maxZoom);
    setZoom(newZoom);
  };

  return {
    zoom,
    isSizeToFit,
    setIsSizeToFit,
    zoomIn,
    zoomOut,
    ZoomView: (
      <Canvas
        src={src}
        zoom={zoom}
        isSizeToFit={isSizeToFit}
        minZoom={minZoom}
        maxZoom={maxZoom}
        zoomStep={zoomStep}
        zoomOnWheel={zoomOnWheel}
        setZoom={setZoom}
        zoomIn={zoomIn}
      />
    ),
  };
};
