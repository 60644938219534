import React, { useMemo } from 'react';
import { Dropdown, AngleDown } from '@thrivelot/stories';
import { useModel } from '@thrivelot/hooks';
import { constructAddressString, getProjectStage } from '@thrivelot/common';
import { transformStringToTitleCase } from '@thrivelot/utils';
import { ProjectMilestoneStage } from '@thrivelot/aws';
import { LayoutModal } from '../..';
import { InputAddress } from '../../InputAddress';

const MILESTONES = [
  ProjectMilestoneStage.OPEN_PROJECT,
  ProjectMilestoneStage.SITE_ASSESSMENT,
  ProjectMilestoneStage.DETAILED_PLAN,
  ProjectMilestoneStage.INSTALLATION,
  ProjectMilestoneStage.STEWARDSHIP,
  ProjectMilestoneStage.CHURN,
];

const ModalEditProjectInfo = ({ onClose, projectId }) => {
  const {
    model: project,
    updateModel,
    loaded,
    actions,
  } = useModel({ modelName: 'Project', id: projectId });

  const stage = project && getProjectStage(project);

  const stageOptions = useMemo(
    () =>
      MILESTONES.map((milestone) => ({
        label: transformStringToTitleCase(milestone),
        selected: stage === milestone,
        onClick: () =>
          updateModel(actions.set('currentMilestone', milestone).result),
      })),
    [stage, updateModel, actions]
  );

  const {
    title,
    address,
    budgetCommitment,
    property: { amountImpacted, size },
  } = project?.details || { property: {} };

  return (
    <LayoutModal
      onClose={onClose}
      loading={!loaded}
      header={
        <div className="text-lg text-brown-dark italic">Edit Project Info</div>
      }
    >
      <div className="mb-4 flex items-center">
        <div className="text-brown-dark mr-3">Stage:</div>
        <div className="text-green-dark font-bold">
          <Dropdown
            label={
              <div className="text-green-dark flex items-center border border-green-dark px-3 py-1 rounded-main">
                {transformStringToTitleCase(stage)}
                <AngleDown color="green-dark" size={14} styleProps="ml-2" />
              </div>
            }
            options={stageOptions}
          />
        </div>
      </div>
      <div className="mb-4">
        <div className="text-brown-dark mb-1 text-sm">Project Title</div>
        <input
          className="input"
          placeholder="Add a project title..."
          value={title || ''}
          onChange={(e) =>
            updateModel(actions.set('details.title', e.target.value).result)
          }
        />
      </div>
      <div className="mb-4">
        <div className="text-brown-dark mb-1 text-sm">Address</div>
        <InputAddress
          defaultValue={constructAddressString(address)}
          onSelect={(value) =>
            updateModel(actions.set('details.address', value).result)
          }
        />
      </div>
      <div className="mb-4">
        <div className="text-brown-dark mb-1 text-sm">Property Size</div>
        <input
          className="input"
          placeholder="Add a property size..."
          value={size || ''}
          onChange={(e) =>
            updateModel(
              actions.set('details.property.size', e.target.value).result
            )
          }
        />
      </div>
      <div className="mb-4">
        <div className="text-brown-dark mb-1 text-sm">
          Project impact on property
        </div>
        <input
          className="input"
          placeholder="Add a project impact description..."
          value={amountImpacted || ''}
          onChange={(e) =>
            updateModel(
              actions.set('details.property.amountImpacted', e.target.value)
                .result
            )
          }
        />
      </div>
      <div className="mb-4">
        <div className="text-brown-dark mb-1 text-sm">Budget ($ in cents)</div>
        <input
          className="input"
          placeholder="Add a budget..."
          value={budgetCommitment}
          onChange={(e) =>
            updateModel(
              actions.set('details.budgetCommitment', e.target.value).result
            )
          }
        />
      </div>
    </LayoutModal>
  );
};

export { ModalEditProjectInfo };
