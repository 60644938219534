import React, { useMemo } from 'react';
import { dateFns } from '@thrivelot/date';
import { useProposal, useProposalView } from '@thrivelot/common';
import { Calendar } from '../../../../../common';
import { useSpacing } from '../../../hooks';
import { PhaseInfo } from './PhaseInfo';

const WorkPhaseCalendar = () => {
  const { project } = useProposal();
  const { setValue } = useProposalView();
  const spacing = useSpacing();
  const negativeMargin = `-${spacing}`;

  const events = useMemo(() => {
    const datesWithPhases = [];
    const phases = project?.phases || [];
    phases.forEach((phase) => {
      datesWithPhases.push({
        id: phase.id,
        title: phase.name,
        start: dateFns(phase.startDate).toDate(),
        end: dateFns(phase.endDate).toDate(),
        allDay: true,
      });
    });

    return datesWithPhases;
  }, [project?.phases]);

  const openPhase = (event) => {
    setValue('phase', event.id);
  };

  return (
    <div
      style={{
        marginRight: negativeMargin,
        marginBottom: `calc(${negativeMargin} - 1px)`,
        marginLeft: `calc(${negativeMargin} - 1px)`,
        position: 'relative',
        height: '80vh',
      }}
    >
      <Calendar events={events} notes={[]} onSelectEvent={openPhase} />
      <PhaseInfo />
    </div>
  );
};

export { WorkPhaseCalendar };
