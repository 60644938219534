import { CostPaymentScheduleType, ProjectMilestoneStage } from "@thrivelot/aws";
import {
  // HomeLocationIcon,
  DesignIcon,
  ShovelIcon,
  CalendarIcon,
} from "@thrivelot/styles";
import { calculateDesignCost } from "./calculateDesignCost";
import { calculateInstallationCost } from "./calculateInstallationCost";
// import calculateSiteVisitCost from "./calculateSiteVisitCost";

const contentHashmap = {
  // [ProjectMilestoneStage.SITE_ASSESSMENT]: {
  //   title: "Site Assessment",
  //   innerTitle: "Design Deposit",
  //   info:
  //     "Please Note: Your $299 Design Deposit will be applied to your Landscape Development Plan price.",
  //   type: CostPaymentScheduleType.SITE_VISIT,
  //   Icon: HomeLocationIcon,
  //   InnerIcon: HomeLocationIcon,
  //   calculateCost: calculateSiteVisitCost,
  // },
  [ProjectMilestoneStage.SITE_ASSESSMENT]: {
    title: "Design & Detailed Planning",
    innerTitle: "Landscape Development Plan",
    type: CostPaymentScheduleType.DESIGN,
    Icon: DesignIcon,
    InnerIcon: DesignIcon,
    calculateCost: calculateDesignCost,
  },
  [ProjectMilestoneStage.DETAILED_PLAN]: {
    title: "Project Installation",
    type: CostPaymentScheduleType.PHASE,
    Icon: ShovelIcon,
    InnerIcon: CalendarIcon,
    calculateCost: calculateInstallationCost,
  },
};

export { contentHashmap };
