import { Design, Labor } from '@thrivelot/stories';
import { constructUuid } from '@thrivelot/utils';
import React from 'react';

export const getDefaultsForOption = (option) => {
  if (option === 'design') {
    return {
      icon: 'Design',
      option: 'design',
      description:
        'Creates the default Design billing type and design fee line item.\n\nPayment is received 100% up front and paid out 50% up front and 50% upon completion',
      invoiceProposalValues: {
        housePercent: 25,
        kind: 'design_billing',
        totalsAs: 'item_totals',
      },
      bundleValues: {
        orderedFeatureData: [{ id: constructUuid(), featureId: '__project__' }],
        lineItems: [
          {
            id: constructUuid(),
            kind: 'labor',
            name: 'Design',
            description: '',
            unit: 'units',
            quantity: 1,
            pricePerUnit: 20000,
            featureId: '__project__',
          },
        ],
      },
    };
  }

  if (option === 'install') {
    return {
      icon: 'Labor',
      option: 'install',
      description:
        'Creates the default Install billing type (Time and Materials).\n\nPayment is received and paid out in 2 installments. First installment includes 100% of material, plants, and equipment, and 50% of labor. Second installment is includes 50% of labor plus or minus any changes as discussed and agree upon by the Service Provider and Customer.',
      invoiceProposalValues: {
        housePercent: 25,
        kind: 'time_materials_billing',
        totalsAs: 'feature_totals',
      },
    };
  }

  return {};

  // return {
  //   icon: 'Plan',
  //   option: 'custom',
  //   description:
  //     'Creates a Single Payment billing type without automatically adding any line items.\n\nPayment is received and paid out all up front.',
  //   invoiceProposalValues: {
  //     housePercent: 25,
  //     kind: 'single_billing',
  //     totalsAs: 'item_totals',
  //   },
  // };
};

const SelectInvoiceProposalDefaults = ({ defaults, setDefaults }) => (
  <div className="flex justify-between gap-4">
    <div
      className={`flex flex-col p-4 flex-1 items-center justify-center rounded-main shadow-lg hover:cursor-pointer ${
        defaults.option === 'design'
          ? 'text-white-light bg-green-main'
          : 'text-green-main border-green-main border-2 bg-white-dark hover:bg-white-light'
      }`}
      onClick={() => setDefaults(getDefaultsForOption('design'))}
    >
      <Design
        color={defaults.option === 'design' ? 'white-light' : 'green-main'}
        size={45}
      />
      <div className="font-bold text-lg mt-2">Design</div>
    </div>
    <div
      className={`flex flex-col p-4 flex-1 items-center justify-center rounded-main shadow-lg hover:cursor-pointer ${
        defaults.option === 'install'
          ? 'text-white-light bg-green-main'
          : 'text-green-main border-green-main border-2 bg-white-dark hover:bg-white-light'
      }`}
      onClick={() => setDefaults(getDefaultsForOption('install'))}
    >
      <Labor
        color={defaults.option === 'install' ? 'white-light' : 'green-main'}
        size={45}
      />
      <div className="font-bold text-lg mt-2">Install</div>
    </div>
    {/* <div
      className={`flex flex-col p-4 flex-1 items-center justify-center rounded-main shadow-lg hover:cursor-pointer ${
        defaults.option === 'custom'
          ? 'text-white-light bg-green-main'
          : 'text-green-main border-green-main border-2 bg-white-dark hover:bg-white-light'
      }`}
      onClick={() => setDefaults(getDefaultsForOption('custom'))}
    >
      <Plan
        color={defaults.option === 'custom' ? 'white-light' : 'green-main'}
        size={45}
      />
      <div className="font-bold text-lg mt-2">Custom</div>
    </div> */}
  </div>
);

export { SelectInvoiceProposalDefaults };
