import React from "react";
import SvgContainer from "../SvgWrapper";

const CancelIcon = ({ color, size, ...rest }) => (
  <SvgContainer
    width={`${size}`}
    height={`${size}`}
    viewBox="0 0 300 300"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      d="M98.075 215.385C94.6134 215.385 91.1904 214.077 88.575 211.423C83.3057 206.154 83.3057 197.654 88.575 192.385L192.421 88.5384C197.69 83.2692 206.19 83.2692 211.46 88.5384C216.729 93.8077 216.729 102.308 211.46 107.577L107.613 211.423C104.96 214.077 101.537 215.385 98.075 215.385Z"
      fill={color}
    />
    <path
      d="M201.921 215.385C198.46 215.385 195.037 214.077 192.421 211.423L88.575 107.577C83.3057 102.308 83.3057 93.8077 88.575 88.5384C93.8442 83.2692 102.344 83.2692 107.613 88.5384L211.46 192.385C216.729 197.654 216.729 206.154 211.46 211.423C208.806 214.077 205.383 215.385 201.921 215.385Z"
      fill={color}
    />
    <path
      d="M150 300C67.3077 300 0 232.692 0 150C0 67.3077 67.3077 0 150 0C232.692 0 300 67.3077 300 150C300 232.692 232.692 300 150 300ZM150 23.0769C80 23.0769 23.0769 80 23.0769 150C23.0769 220 80 276.923 150 276.923C220 276.923 276.923 220 276.923 150C276.923 80 220 23.0769 150 23.0769Z"
      fill={color}
    />
  </SvgContainer>
);

export default CancelIcon;
