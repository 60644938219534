import { add, get, remove, set } from '@thrivelot/actions';
import { constructUuid } from '@thrivelot/utils';
import { getSortedPhases } from './getSortedPhases';
import { getPaymentCount } from './getPaymentCount';
import { getBillingSummary } from './getBillingSummary/getBillingSummary';
import { getCombinedBundleItems } from './getCombinedBundleItems';

function actions(model) {
  return {
    result: model,
    add(path, value, index = -1) {
      this.result = add(this.result, path, value, index);
      return this;
    },
    remove(path, value) {
      this.result = remove(this.result, path, value);
      return this;
    },
    set(path, value) {
      this.result = set(this.result, path, value);
      return this;
    },
    get: (path, defaultValue) => get(model, path),

    getBillingSummary: (bundles, paymentMethod) =>
      getBillingSummary(model, bundles, paymentMethod),
    getCombinedBundleItems: (bundles) => getCombinedBundleItems(model, bundles),
    getPaymentCount: () => getPaymentCount(model),
    getSortedPhases: () => getSortedPhases(model),
    constructUuid: () => constructUuid(),
  };
}

export { actions };
