import styled from "styled-components";

const DivCalendarAgendaHeader = styled.div`
  transition: opacity 0.5s ease-out, box-shadow 0.5s ease-out, color 1s ease-in-out, background-color 1s ease-in-out;
  display: flex;
  padding: 13px 0px 13px 12px;
  justify-content: space-between;
  border-bottom: 1px solid ${({ theme }) => theme.divLine};
`;

export default DivCalendarAgendaHeader;
