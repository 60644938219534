import React from "react";
import { useTheme, useMobileDifference } from "@thrivelot/hooks";
import { DivCircleInset, TextHeader } from "@thrivelot/styles";

const Quantity = ({ plant }) => {
  // hooks
  const theme = useTheme();
  const circleSize = useMobileDifference({ desktop: 45, mobile: 30 });
  const fontSize = useMobileDifference({ desktop: "14px", mobile: "10px" });
  // return
  return (
    <DivCircleInset
      size={circleSize}
      style={{
        position: "absolute",
        top: "5px",
        right: "6px",
        backgroundColor: theme.foreground,
      }}
    >
      <TextHeader style={{ color: theme.textTitle, fontSize }}>{plant.quantity}</TextHeader>
    </DivCircleInset>
  );
};

export { Quantity };
