// ! THIS FILE IS DEPRECATED REMOVE WHEN LINE ITEMS ARE REMOVED FROM FEATURES ON THE FRONT END

const normalizeLineItem = (lineItem) => ({
  value: lineItem.id,
  label: `${lineItem.name} ($${lineItem.cost / 100})`,
  item: lineItem,
});

const normalizePlantFromDatabase = (plant) => ({
  value: plant.id,
  label: plant.commonName || plant.scientificName || "No name...",
  item: {
    id: plant.id,
    quantity: 0,
    cost: 0,
    notes: "",
    changes: [
      { key: "commonName", change: plant.commonName || "" },
      { key: "scientificName", change: plant.scientificName || "" },
      { key: "imageUrl", change: plant.imageUrl || "" },
    ],
  },
  fromDatabase: true,
});

const normalizePlant = (plant) => ({
  value: plant.id,
  label: `${plant?.changes?.find((plantChange) => plantChange && plantChange.key === "commonName")?.change || ""} ($${
    plant.cost / 100
  })`,
  item: plant,
});

const normalizeGuess = (item) => {
  if (item?.changes) return normalizePlant(item);
  return normalizeLineItem(item);
};

const normalizeResult = (type, fromDatabase) => (item) => {
  if (type === "guess") return normalizeGuess(item);
  if (type === "lineItem") return normalizeLineItem(item);
  if (type === "plant" && fromDatabase) return normalizePlantFromDatabase(item);
  return normalizePlant(item);
};

const normalizeSearchResults = (arr, type, fromDatabase) => arr.map(normalizeResult(type, fromDatabase));

export { normalizeSearchResults };
