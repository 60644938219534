import { TagIdType } from "@thrivelot/aws";

const getWorkPhaseItems = ({ tagIds, project }) => {
  if (!tagIds) return [];
  const items = [];
  tagIds.forEach((tagId) => {
    if (tagId.type === TagIdType.LINE_ITEM) {
      const lineItem = project.lineItems && project.lineItems.find((e) => e.id === tagId.tagId);
      if (lineItem) items.push(lineItem);
    } else if (tagId.type === TagIdType.PLANT) {
      const plantItem = project.plantItems && project.plantItems.find((e) => e.id === tagId.tagId);
      if (plantItem) items.push(plantItem);
    }
  });
  return items;
};

export { getWorkPhaseItems };
