import { iconSize } from "./iconSize";
import { spacing } from "./spacing";

const stripPxForNumber = (value) => {
  const numStr = value.replace("px", "");
  return parseInt(numStr, 10);
};
const hangingIndent = {
  desktop: iconSize.desktop + stripPxForNumber(spacing.desktop),
  mobile: 0,
};

export { hangingIndent };
