import React, { useMemo, useEffect, useState } from 'react';
import { useModel } from '@thrivelot/hooks';
import { LeafLoader } from '@thrivelot/stories';
import { useInvoiceProposalBillingSummary } from '@thrivelot/common';
import { ViewPlantPalette } from '../ViewPlantPalette';
import HeaderImageLavenderBee from './header_image_lavender_bee.jpg';
import { CardInfo } from './CardInfo';
import { ViewPhase } from './ViewPhase';
import { ViewSection } from './ViewSection';
import { ListInvoiceProposalEvents } from '../..';
import { ViewItemSummary } from './ViewItemSummary';
import { ViewInvoice } from './ViewInvoice';
import { ViewPayment } from './ViewPayment';

const ViewProjectInvoiceProposal = ({ projectId, invoiceProposalId }) => {
  const { model: invoiceProposal, loaded } = useModel({
    modelName: 'InvoiceProposal',
    id: invoiceProposalId,
  });

  const {
    billingSummary: billing,
    loaded: billingLoaded,
    bundles,
  } = useInvoiceProposalBillingSummary(invoiceProposalId);

  const [showPlantPalette, setShowPlantPalette] = useState(false);

  const descriptionMap = useMemo(() => {
    if (!billingLoaded || !bundles || bundles.length === 0) return null;

    const result = { changeItemDescriptions: {}, lineItemDescriptions: {} };
    bundles.forEach((bundle) => {
      (bundle.orderedFeatureData || []).forEach((data) => {
        result[data.featureId] = result[data.featureId] || [];

        if (data.changeItemsDescription)
          result.changeItemDescriptions[data.featureId] =
            data.changeItemsDescription;
        if (data.lineItemsDescription)
          result.lineItemDescriptions[data.featureId] =
            data.lineItemsDescription;
      });
    });

    return result;
  }, [billingLoaded, bundles]);

  const sortedPhases = useMemo(() => {
    if (!invoiceProposal?.phases) return [];
    return invoiceProposal?.phases.sort((a, b) => {
      const aDate = (a.dates || [])[0];
      const bDate = (b.dates || [])[0];
      if (!aDate && !bDate) {
        return 0; // no dates, don't change order
      }
      if (!aDate) {
        return 1; // a has no dates, move it to end
      }
      if (!bDate) {
        return -1; // b has no dates, move it to end
      }
      return new Date(aDate).getTime() - new Date(bDate).getTime();
    });
  }, [invoiceProposal?.phases]);

  const statusIndex = useMemo(() => {
    if (!invoiceProposal) return 0;
    const lifecycle = [
      'draft',
      'submitted',
      'approved',
      'active',
      'submitted_for_completion',
      'approved_for_completion',
      'complete',
    ];
    return lifecycle.findIndex((elm) => elm === invoiceProposal?.status);
  }, [invoiceProposal]);

  useEffect(() => {
    if (!billing?.items) return;

    const hasPlantItems = billing?.items.some(
      (item) => item?.data?.plantDatabaseId
    );

    if (hasPlantItems && !showPlantPalette) setShowPlantPalette(true);
    else if (!hasPlantItems && showPlantPalette) setShowPlantPalette(false);
  }, [billing?.items, showPlantPalette]);

  if (!loaded || !billingLoaded) return <LeafLoader />;

  const { name, description, kind, timestamps, status, totalsAs } =
    invoiceProposal;

  return (
    <div className="flex flex-col divide-y divide-tan-light">
      <div className="relative">
        <img src={HeaderImageLavenderBee} alt="Bee with Lavender" />
        <div className="absolute top-0 h-full w-full flex items-center justify-between">
          <div className="bg-white-light opacity-70 py-6 px-12 m-auto">
            <div className="text-green-main text-3xl lg:text-6xl font-bold font-title text-center">
              {name}
            </div>
          </div>
        </div>
      </div>
      {description && (
        <ViewSection
          header="About This Project"
          classAppend="bg-white-light text-green-dark"
        >
          <div className="text-center whitespace-pre-line">{description}</div>
        </ViewSection>
      )}
      {(billing?.items || []).length > 0 && (
        <ViewSection header="Work Summary" classAppend="bg-white-light">
          <ViewItemSummary
            projectId={projectId}
            invoiceProposalId={invoiceProposalId}
            payment={{
              items: (billing?.items || []).filter(
                (item) => item.type !== 'change'
              ),
            }}
            totalsAs={totalsAs}
            descriptionMap={descriptionMap.lineItemDescriptions}
          />
          <CardInfo
            classAppend="mt-12"
            header="An important note about pricing"
            body="Pricing is subject to change based on material and plant availability and supply. The actual cost may change after all of the project specifications have been negotiated and finalized. You will be informed of any changes in cost in advance."
          />
        </ViewSection>
      )}
      {sortedPhases.length > 0 && (
        <ViewSection header="Work Phases" classAppend="bg-white-light">
          <div>
            <div className="flex flex-col gap-4 lg:gap-12">
              {sortedPhases.map((phase) => (
                <div key={phase.id} className="lg:px-16">
                  <ViewPhase key={phase.id} kind={kind} phase={phase} />
                </div>
              ))}
            </div>
            <CardInfo
              header="An important note about phases"
              body="Slight delays on the part of Weather Conditions or other Natural Factors should be expected. Site Conditions or unforeseen challenges may also impact the Project Timeline and expected completion dates."
              classAppend="mt-6 lg:mt-16"
            />
          </div>
        </ViewSection>
      )}
      {showPlantPalette && (
        <ViewSection header="Plant Palette">
          <ViewPlantPalette
            projectId={projectId}
            invoiceProposalId={invoiceProposalId}
          />
        </ViewSection>
      )}
      {billing?.payments[0] && (
        <ViewSection header="First Payment" disabled={statusIndex <= 0}>
          <ViewInvoice
            invoiceProposalId={invoiceProposalId}
            projectId={projectId}
            payment={billing?.payments[0]}
          />
          <div className="flex justify-center mt-8">
            <div className="flex flex-col gap-12 max-w-lg w-full">
              <ViewPayment
                disabled={statusIndex <= 0}
                projectId={projectId}
                invoiceProposalId={invoiceProposalId}
                title="First Payment"
                payment={billing?.payments[0]}
                dueOn="approval"
                status={status}
                approvalTimestamp={{
                  kind: 'invoiceProposalApproved',
                  description: 'Approved order',
                  status: 'approved',
                }}
              />
            </div>
          </div>
        </ViewSection>
      )}
      {(billing?.items || []).filter((item) => item.type === 'change').length >
        0 && (
        <ViewSection header="Change Summary" disabled={statusIndex <= 2}>
          <ViewItemSummary
            projectId={projectId}
            invoiceProposalId={invoiceProposalId}
            payment={{
              items: (billing?.items || []).filter(
                (item) => item.type === 'change'
              ),
            }}
            totalsAs={totalsAs}
            descriptionMap={descriptionMap.changeItemDescriptions}
          />
        </ViewSection>
      )}
      {billing?.payments[1] && (
        <ViewSection header="Second Payment" disabled={statusIndex <= 3}>
          <ViewInvoice
            invoiceProposalId={invoiceProposalId}
            projectId={projectId}
            payment={billing?.payments[1]}
          />
          <div className="flex justify-center mt-8">
            <div className="flex flex-col gap-12 max-w-lg w-full">
              <ViewPayment
                disabled={statusIndex <= 3}
                projectId={projectId}
                invoiceProposalId={invoiceProposalId}
                title="Second Payment"
                payment={billing?.payments[1]}
                dueOn="completion"
                status={status}
                approvalTimestamp={{
                  kind: 'invoiceProposalApprovedForCompletion',
                  description: 'Approved order for completion',
                  status: 'approved_for_completion',
                }}
              />
            </div>
          </div>
        </ViewSection>
      )}
      {(timestamps || []).length > 0 && (
        <ViewSection
          header="History"
          disabled
          classAppend="bg-white-light text-brown-dark text-center"
        >
          <ListInvoiceProposalEvents
            invoiceProposalId={invoiceProposalId}
            excludeKinds={['invoiceProposalPayoutSent']}
          />
        </ViewSection>
      )}
    </div>
  );
};

export { ViewProjectInvoiceProposal };
