import { isNumber } from "./isNumber";
import { transformCurrencyToString } from "./transformCurrencyToString";

const transformCurrencyToFloat = (currency = "") => {
  let safeCurrency = currency || "";

  if (typeof currency === "number") safeCurrency = transformCurrencyToString(currency) || "";

  let currencyStr = "";

  for (let i = 0; i < safeCurrency.length; i++) {
    const char = safeCurrency.charAt(i);

    if (isNumber(char) || char === ".") currencyStr += char;
  }

  return parseFloat(currencyStr);
};

export { transformCurrencyToFloat };
