import React, { useCallback, useEffect, useMemo, useState } from 'react';
import LogRocket from 'logrocket';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { constructUuid } from '@thrivelot/utils';
import {
  useAuth,
  useMarker,
  ModelKitProvider,
  PlantsProvider,
} from '@thrivelot/hooks';
import { authConfig, modelConfig } from '@thrivelot/config';
import {
  LayoutSimple,
  LayoutProject,
  LayoutSuspense,
  RoutingAuthentication,
  PageProjectFeatures,
  PageProjectFiles,
  PageProjectMap,
  PageProjectNotes,
  PageProjectPaymentSchedule,
  PageProjectPhases,
  PageUnchartedLands,
  PageProjectInvoiceProposals,
  PagePreviewProjectInvoiceProposal,
  PageViewInvoiceProposalPlantPalette,
} from '@thrivelot/components';
import { CustomerProposal } from '../CustomerProposal';
import {
  PageHome,
  PageProfile,
  PageProjectDetails,
  PageExploreProjects,
  PageSettings,
  PageCatalog,
  PageCatalogImport,
} from '../pages';
import { PageEditProjectInvoiceProposal } from '../pages/PageEditProjectInvoiceProposal/PageEditProjectInvoiceProposal';

const Routing = () => {
  const auth = useAuth();
  const { handleMarker } = useMarker();
  const [showError, setShowError] = useState(false);

  useEffect(() => {
    if (auth.authenticated && !auth.groups.includes('supplier')) auth.signOut();
  }, [auth?.authenticated, auth?.groups, auth?.signOut]);

  // Identify LogRocket user only in production and when authenticated
  useEffect(() => {
    if (
      /thrivelot.com/.test(window.location.hostname) &&
      auth?.authenticated &&
      auth?.userId &&
      auth?.user?.attributes
    ) {
      try {
        LogRocket.identify(auth.userId, {
          name: auth?.user?.attributes?.name,
          email: auth?.user?.attributes?.email,
          cognitoId: auth?.user?.attributes?.sub,
        });

        window.Marker.setReporter({
          email: auth?.user?.attributes?.email,
          fullName: auth?.user?.attributes?.name,
        });

        window.pendo.initialize({
          visitor: {
            id: auth?.userId, // Required if user is logged in, default creates anonymous ID
            email: auth?.user?.attributes?.email, //  Recommended if using Pendo Feedback, or NPS Email
            full_name: auth?.user?.attributes?.name, // Recommended if using Pendo Feedback
            role: 'supplier',

            // You can add any additional visitor level key-values here,
            // as long as it's not one of the above reserved names.
          },
        });
      } catch (e) {
        console.log('Marker or Logrocket not loaded', e);
      }
    }
  }, [auth?.authenticated, auth?.userId, auth?.user?.attributes]);

  const onUpdateVariableInput = useCallback(
    (input) => ({
      ...input,
      updatedMeta: {
        id: constructUuid(),
        userCognitoId: auth?.user?.username,
        userId: auth?.userId,
        userEmail: auth?.user?.attributes?.email,
        userName: auth?.user?.attributes?.name,
        userGroups: auth?.groups && JSON.stringify(auth.groups),
        device: navigator.userAgent,
      },
    }),
    [auth]
  );

  const navConfig = useMemo(
    () => ({
      helpOptions: [
        {
          label: '🎬 Thrive Lot Video Tutorials',
          href: 'https://thrivelot.notion.site/thrivelot/Platform-Update-November-21-7ce8caa80c67423f8591bcf5e1787d07',
        },
        {
          label: '🐞 Submit Bug or Feature Request',
          onClick: handleMarker,
        },
        {
          label: '🚨 Trigger error boundary test',
          onClick: () => setShowError(true),
        },
      ],
      navOptions: [
        {
          label: 'Home',
          to: 'home',
          icon: 'Home',
        },
        {
          label: 'Profile',
          to: 'profile',
          icon: 'User',
        },
        {
          label: 'Projects',
          to: 'projects',
          icon: 'Plan',
        },
        {
          label: 'Catalog',
          to: 'catalog',
          icon: 'Catalog',
        },
      ],
      projectNavOptions: [
        {
          label: 'Details',
          icon: 'Book',
          to: 'details',
        },
        {
          label: 'Notes',
          icon: 'Sketch',
          to: 'notes',
        },
        {
          label: 'Files',
          icon: 'FilesFolder',
          to: 'files',
        },
        {
          label: 'Map',
          icon: 'Map',
          to: 'map',
        },
        // {
        //   label: 'Features',
        //   icon: 'LineItems',
        //   to: 'features',
        // },
        {
          label: 'Phases',
          icon: 'Phases',
          to: 'phases',
          hide: (project) => !!project?.details?.useInvoiceProposals,
        },
        {
          label: 'Payment Schedule',
          icon: 'Cost',
          to: 'payment-schedule',
          hide: (project) => !!project?.details?.useInvoiceProposals,
        },
        {
          label: 'Orders',
          icon: 'LineItems',
          to: 'orders',
          hide: (project) => !project?.details?.useInvoiceProposals,
        },
      ],
    }),
    [handleMarker]
  );

  if (auth.loading) return <LayoutSuspense />;

  if (showError) return <div>{(10).sort()}</div>;

  if (window.location.pathname.includes('/proposal')) {
    return (
      <BrowserRouter>
        <Routes>
          <Route path="/proposal/*" element={<CustomerProposal />} />
        </Routes>
      </BrowserRouter>
    );
  }

  return (
    <BrowserRouter>
      {auth?.authenticated ? (
        <ModelKitProvider
          authConfig={authConfig}
          modelConfig={{ ...modelConfig, onUpdateVariableInput }}
          auth={auth}
        >
          <PlantsProvider>
            <Routes>
              <Route
                path="/"
                element={
                  <LayoutSimple navConfig={navConfig} ensureTerms={true} />
                }
              >
                {/* If you're logged in and on / or /auth redirect to projects */}
                <Route
                  path="auth/*"
                  element={<Navigate to="/projects" replace />}
                />
                <Route
                  exact
                  path=""
                  element={<Navigate to="/projects" replace />}
                />

                <Route path="/" element={<Navigate to="/home" replace />} />

                {/* Top level page routing */}
                <Route path="home">
                  <Route exact path="" element={<PageHome />} />
                </Route>

                <Route path="profile">
                  <Route exact path="" element={<PageProfile />} />
                </Route>

                <Route path="settings">
                  <Route exact path="" element={<PageSettings />} />
                </Route>

                <Route path="catalog">
                  <Route path="" element={<PageCatalog />} />
                  <Route path="import" element={<PageCatalogImport />} />
                </Route>

                <Route path="projects">
                  <Route exact path="" element={<PageExploreProjects />} />

                  <Route
                    path=":projectId"
                    element={<LayoutProject navConfig={navConfig} />}
                  >
                    <Route
                      exact
                      path=""
                      element={<Navigate to="details" replace />}
                    />
                    <Route path="details" element={<PageProjectDetails />} />
                    <Route
                      path="features/*"
                      element={<PageProjectFeatures />}
                    />
                    <Route path="files/*" element={<PageProjectFiles />} />
                    <Route path="map">
                      <Route exact path="" element={<PageProjectMap />} />
                      <Route
                        path="features/*"
                        element={<PageProjectFeatures />}
                      />
                    </Route>
                    <Route path="notes" element={<PageProjectNotes />} />
                    <Route
                      path="payment-schedule"
                      element={<PageProjectPaymentSchedule />}
                    />
                    <Route path="phases" element={<PageProjectPhases />} />
                    <Route path="orders">
                      <Route
                        exact
                        path=""
                        element={<PageProjectInvoiceProposals />}
                      />
                      <Route
                        path=":invoiceProposalId"
                        element={<PagePreviewProjectInvoiceProposal />}
                      />
                      <Route
                        path=":invoiceProposalId/plant-palette"
                        element={<PageViewInvoiceProposalPlantPalette />}
                      />
                      <Route path=":invoiceProposalId/edit">
                        <Route
                          exact
                          path=""
                          element={<PageEditProjectInvoiceProposal />}
                        />
                        <Route
                          path="features/*"
                          element={<PageProjectFeatures />}
                        />
                      </Route>
                    </Route>
                  </Route>
                </Route>

                {/* Handle 404 */}
                <Route path="*" element={<PageUnchartedLands />} />
              </Route>
            </Routes>
          </PlantsProvider>
        </ModelKitProvider>
      ) : (
        <RoutingAuthentication />
      )}
    </BrowserRouter>
  );
};

export { Routing };
