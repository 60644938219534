import { useCallback, useEffect, useReducer } from "react";
import { isEqual } from "@thrivelot/utils";

const actionTypes = {
  setValue: "set_value",
  setValues: "set_values",
};

const initialState = (startingValues = {}) => ({
  selectedDate: new Date(),
  view: "month",
  events: [],
  notes: [],
  ...startingValues,
});

const reducer = (state, action) => {
  switch (action.type) {
    case actionTypes.setValue:
      return { ...state, [action.name]: action.value };
    case actionTypes.setValues:
      return { ...state, ...action.values };
    default:
      throw new Error(`Invalid calendar action type "${action.type}"`);
  }
};

const useCalendarProvider = ({ events = [], notes = [] }) => {
  const [calendarState, dispatchToCalendarState] = useReducer(reducer, initialState({ events, notes }));

  const setValue = useCallback((name, value) => {
    dispatchToCalendarState({ type: actionTypes.setValue, name, value });
  }, []);

  useEffect(() => {
    if (!isEqual(events, calendarState.events)) setValue("events", events);
  }, [events, setValue, calendarState.events]);

  useEffect(() => {
    if (!isEqual(notes, calendarState.notes)) setValue("notes", notes);
  }, [notes, setValue, calendarState.notes]);

  return {
    state: calendarState,
    setValue: (name, value) => setValue(name, value),
  };
};

export { useCalendarProvider };
