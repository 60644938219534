import React from "react";
import { useHover } from "react-use";
import getReactIconStyle from "../helpers";
import Filled from "./Filled";
import Outline from "./Outline";

const IconMap = {
  filled: Filled,
  outline: Outline,
};

const MoveIcon = ({ color, size, filled, style = {}, hoverable, ...rest }) => {
  const Icon = IconMap[filled ? "filled" : "outline"];

  const StyledIcon = (hovering) => {
    const iconStyle = {
      ...getReactIconStyle({
        width: size,
        height: size,
        hoverable,
        hovering,
        ...rest,
      }),
      ...style,
    };

    return <Icon color={color} size={size} style={iconStyle} {...rest} />;
  };

  const [HoverableIcon] = useHover(StyledIcon);
  return HoverableIcon;
};

export default MoveIcon;
