import styled from "styled-components";
import CssDivDefault from "./CssDivDefault";

const DivContentRowCenter = styled.div`
  ${CssDivDefault}
  padding: 8px 0px;
  width: 100%;
  min-height: fit-content;
  max-height: fit-content;
  display: flex;
  align-items: center;
`;

export default DivContentRowCenter;
