import React, { useMemo } from 'react';
import { useModal, useModel, useQueryParam } from '@thrivelot/hooks';
import { isEmpty } from '@thrivelot/utils';
import { LeafLoader, Button } from '@thrivelot/stories';
import { ImageFetched } from '../..';
import { BundleFeatureTotals } from './BundleFeatureTotals';
import { ItemKindCounts } from './ItemKindCounts';
import { ListItemsChangeItems } from './ListItemsChangeItems';
import { ListItemsLineItems } from './ListItemsLineItems';

export const featurelessFeature = {
  id: '__project__',
  name: 'General',
  description:
    'These are generic line items not associated with any feature or location on the map.',
};

const FormBundleFeature = ({ featureId, projectId, bundleId }) => {
  const { openModal } = useModal();
  const { param: openFeatures, setParam } = useQueryParam('openFeatures');
  const { model: bundle, loaded } = useModel({
    modelName: 'Bundle',
    id: bundleId,
  });
  const {
    model: project,
    actions: projectActions,
    loaded: projectLoaded,
  } = useModel({ modelName: 'Project', id: projectId });

  const lineItems = useMemo(() => {
    if (loaded)
      return (bundle?.lineItems || []).filter(
        (lineItem) => lineItem.featureId === featureId
      );
    return [];
  }, [loaded, bundle?.lineItems, featureId]);

  const changeItems = useMemo(() => {
    if (loaded)
      return (bundle?.changeItems || []).filter(
        (lineItem) => lineItem.featureId === featureId
      );
    return [];
  }, [loaded, bundle?.changeItems, featureId]);

  const feature = useMemo(
    () =>
      projectLoaded &&
      (featureId === '__project__'
        ? featurelessFeature
        : projectActions.get(`features[id:${featureId}]`)),
    [projectLoaded, projectActions, featureId]
  );

  const file = useMemo(() => {
    if (isEmpty(project?.files)) return null;
    return project.files.find((projectFile) =>
      projectFile?.tagIds?.find((tag) => tag.tagId === featureId)
    );
  }, [featureId, project?.files]);

  if (!loaded || !projectLoaded)
    return (
      <div>
        <LeafLoader />
      </div>
    );

  const { status } = bundle || {};

  return (
    <div
      className={`text-brown-dark rounded-main shadow ${
        status === 'locked' ? '!bg-tan-light' : 'bg-white-light'
      }`}
    >
      <div className="flex justify-between items-center p-4">
        <div className="flex items-center gap-2">
          {file && (
            <div
              className="flex h-[30px] w-[30px] rounded-main items-center justify-center hover:cursor-pointer"
              onClick={() =>
                openModal({
                  path: 'ModalViewImage',
                  modelName: 'Project',
                  modelId: projectId,
                  filePath: 'files',
                  fileId: file.id,
                })
              }
            >
              <ImageFetched
                fileKey={file.file.key}
                height={54}
                width={54}
                style={{
                  height: '30px',
                  width: '30px',
                  borderRadius: '0.5rem',
                  objectFit: 'cover',
                }}
              />
            </div>
          )}
          <div>
            <span className="font-bold">{feature?.name || ''}</span>
          </div>
        </div>
        <div>
          {(openFeatures || []).includes(featureId) ? (
            <Button
              icon="Shrink"
              kind="prompt"
              size="small"
              color="orange-main"
              label="Close"
              onClick={() => {
                const newParams = (openFeatures || []).filter(
                  (id) => id !== featureId
                );
                if (newParams.length > 0) return setParam(newParams);
                return setParam();
              }}
            />
          ) : (
            <Button
              icon="Expand"
              kind="prompt"
              size="small"
              color="brown-dark"
              label="Expand"
              onClick={() => setParam([...(openFeatures || []), featureId])}
            />
          )}
        </div>
      </div>

      <div className="transition-height ease-in-out duration-300"></div>
      {(openFeatures || []).includes(featureId) && (
        <>
          <ListItemsLineItems
            projectId={projectId}
            bundleId={bundleId}
            bundleStatus={status}
            feature={feature}
            items={lineItems}
          />
          {(status === 'changes_only' || changeItems.length > 0) && (
            <ListItemsChangeItems
              projectId={projectId}
              bundleId={bundleId}
              bundleStatus={status}
              feature={feature}
              items={changeItems}
            />
          )}
        </>
      )}

      <div
        className={`border-t border-tan-light rounded-b-main px-4 ${
          status === 'locked' ? '!bg-tan-light' : ''
        }`}
      >
        <div className="md:flex w-full">
          <div className="md:w-1/2 flex items-center justify-around py-4">
            <ItemKindCounts items={[...lineItems, ...changeItems]} />
          </div>
          <BundleFeatureTotals
            featureName={feature?.name || ''}
            changeItems={changeItems.filter((i) => !i.hidden)}
            lineItems={lineItems.filter((i) => !i.hidden)}
            classAppend="md:!w-1/2"
          />
        </div>
      </div>
    </div>
  );
};

export { FormBundleFeature };
