import React from 'react';
import PropTypes from 'prop-types';
import { getIconNameStrings } from './utils';
import * as icons from './icons';

export const EmptyState = ({ icon, header, children, footer, classAppend }) => {
  let IconComponent = icons[icon];
  if (icon) {
    IconComponent = icons[icon];
  }

  return (
    <div
      className={`flex flex-col items-center justify-center text-brown-light text-center pb-4 px-4 max-w-md mx-auto ${
        classAppend || ''
      }`}
    >
      {icon && (
        <div className="mt-4 flex items-center">
          <IconComponent size={40} color="brown-light" />
        </div>
      )}
      {header && <div className="mt-4 font-bold text-2xl">{header}</div>}
      {children && <div className="mt-4 mb-2">{children}</div>}
      {footer && <div className="mt-4">{footer}</div>}
    </div>
  );
};

EmptyState.propTypes = {
  /**
   * Icon for the top
   */
  icon: PropTypes.oneOf(getIconNameStrings()),

  /**
   * Header string
   */
  header: PropTypes.string,

  /**
   * Main content body
   */
  children: PropTypes.node,

  /**
   * Footer is usually a call to action but is optional
   */
  footer: PropTypes.node,
};
