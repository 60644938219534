import React from "react";
import { useIsMobile } from "@thrivelot/hooks";
import { TextSmallHeader, DivColumn, Textarea } from "@thrivelot/styles";
import { styleForListItemTextarea } from "../helpers";
import { Features } from "./Features";

const DropdownContainer = ({
  model,
  updatePhase,
  addFeature,
  deleteFeature,
  description,
  orderedChildTagIds,
  placeholder,
}) => {
  const isMobile = useIsMobile();
  const textStyle = { marginTop: "8px", marginBottom: "5px" };
  return (
    <DivColumn
      style={{
        width: isMobile ? "100%" : "calc(100% - 45px)",
        marginRight: isMobile ? undefined : "45px",
      }}
    >
      <TextSmallHeader style={textStyle}>Description</TextSmallHeader>
      <Textarea
        placeholder="Description"
        value={description}
        onChange={(e) => updatePhase({ description: e.target.value })}
        style={styleForListItemTextarea}
      />
      <TextSmallHeader style={textStyle}>Features</TextSmallHeader>
      <Features
        model={model}
        addFeature={addFeature}
        deleteFeature={deleteFeature}
        orderedChildTagIds={orderedChildTagIds}
        placeholder={placeholder}
      />
    </DivColumn>
  );
};

export { DropdownContainer };
