import React from "react";
import { PicAndNameContainer } from "./PicAndNameContainer";
import { Pic } from "./Pic";
import { Name } from "./Name";

const PicAndName = ({ supplierActions }) => (
  <PicAndNameContainer>
    <Pic supplierActions={supplierActions} />
    <Name supplierActions={supplierActions} />
  </PicAndNameContainer>
);

export { PicAndName };
