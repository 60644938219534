import {
  transformCurrencyToString,
  transformStringToTitleCase,
} from '@thrivelot/utils';
import React from 'react';
import { DropdownSelectBundleLineItemUnit, InputCurrency } from '..';

const InputGroupLineItemCells = ({
  formState: { costPerUnit, pricePerUnit, quantity, unit },
  onChange,
  lockUnit,
  disabled,
  disabledMap,
}) => {
  const isDisabled = (key) => disabled || (disabledMap && disabledMap[key]);
  const total = (pricePerUnit || 0) * (quantity || 1);

  return (
    <div className="flex w-full">
      <div className="relative flex-1">
        <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
          <span className="text-gray-500"> &#x24; </span>
        </div>
        <InputCurrency
          disabled={isDisabled('costPerUnit')}
          className={`input !rounded-none !pl-7 !text-current ${
            isDisabled('costPerUnit') ? '!bg-tan-light' : ''
          }`}
          placeholder="Cost"
          value={costPerUnit}
          onChange={(value) => onChange('costPerUnit', value)}
        />
      </div>

      <div className="relative flex-1">
        <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
          <span className="text-gray-500"> &#x24; </span>
        </div>
        <InputCurrency
          disabled={isDisabled('pricePerUnit')}
          className={`input !rounded-none !pl-7 !text-current ${
            isDisabled('pricePerUnit') ? '!bg-tan-light' : ''
          }`}
          placeholder="Price"
          value={pricePerUnit}
          onChange={(value) => onChange('pricePerUnit', value)}
        />
      </div>

      <div className="relative flex-1">
        <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
          <span className="text-gray-500"> &#x2715; </span>
        </div>
        <input
          disabled={isDisabled('quantity')}
          className={`input !rounded-none !pl-7 !text-current ${
            isDisabled('quantity') ? '!bg-tan-light' : ''
          }`}
          placeholder="Qty"
          value={quantity || ''}
          onChange={(e) =>
            onChange(
              'quantity',
              e.target.value === ''
                ? undefined
                : Math.round(parseFloat(`${e.target.value}`))
            )
          }
        />
      </div>

      <div className="flex-1">
        {lockUnit ? (
          <div
            className={`input w-full flex items-center !rounded-none h-full ${
              isDisabled('unit') ? '!bg-tan-light' : ''
            }`}
          >
            {transformStringToTitleCase(unit, true)}
          </div>
        ) : (
          <DropdownSelectBundleLineItemUnit
            value={unit}
            onChange={(value) => onChange('unit', value)}
            disabled={isDisabled('unit')}
          />
        )}
      </div>
      <div
        className={`input flex-1 text-right !text-current !rounded-none flex justify-between items-center ${
          disabled ? '!bg-tan-light' : ''
        }`}
      >
        <div className="">&#x24;</div>
        <div className="!font-bold font-header">
          {transformCurrencyToString(total, { hideSymbol: true })}
        </div>
      </div>
    </div>
  );
};

export { InputGroupLineItemCells };
