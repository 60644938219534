import { useCallback, useState } from "react";

const useProposalViewProvider = () => {
  const [file, setFile] = useState(null);
  const [payment, setPayment] = useState(false);
  const [phase, setPhase] = useState(null);
  const [plant, setPlant] = useState(null);
  const [zone, setZone] = useState(null);

  const setValue = useCallback((name, value) => {
    const setMap = {
      file: setFile,
      payment: setPayment,
      phase: setPhase,
      plant: setPlant,
      zone: setZone,
    };

    const set = setMap[name];

    set(value);
  }, []);

  return { file, payment, phase, plant, zone, setValue };
};

export { useProposalViewProvider };
