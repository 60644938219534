import React from "react";
import { Card } from "@thrivelot/stories";
import { CardLoader } from "@thrivelot/stories";
import QualificationInput from "./QualificationInput";
import Group from "./Group";
import { useModel } from "@thrivelot/hooks";

const CardSupplierQualifications = ({ supplierId }) => {
  const {
    model: supplier,
    updateModel: updateSupplier,
    actions,
    loaded,
  } = useModel({ modelName: "Supplier", id: supplierId });

  const addQualification = (type, qualification) => {
    updateSupplier(actions.add(`qualifications.${type}`, qualification).result);
  };

  const deleteQualification = (type, qualification) => {
    updateSupplier(actions.remove(`qualifications.${type}[value:${qualification}]`).result);
  };

  if (!loaded) return <CardLoader />;

  return (
    <Card header="Qualifications" classAppend="mb-4">
      <QualificationInput addQualification={addQualification} />
      <div>
        {supplier.qualifications &&
          Object.keys(supplier.qualifications).map((type, index) => {
            const qualifications = supplier.qualifications[type];
            if (!qualifications || qualifications.length === 0) return null;

            return (
              <Group
                key={type}
                index={index}
                type={type}
                qualifications={qualifications}
                deleteQualification={deleteQualification}
              />
            );
          })}
      </div>
    </Card>
  );
};

export { CardSupplierQualifications };
