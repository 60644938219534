import React, { useEffect, useMemo, useRef, useState } from 'react';
import { clamp } from './clamp';

export const Canvas = ({
  src,
  zoom,
  isSizeToFit,
  minZoom,
  maxZoom,
  zoomStep,
  zoomOnWheel,
  setZoom,
  zoomIn,
}) => {
  const [offset, setOffset] = useState({ x: 0, y: 0 });
  const [dragging, setDragging] = useState(false);

  const touch = useRef({ x: 0, y: 0 });
  const canvasRef = useRef(null);
  const containerRef = useRef(null);
  const observer = useRef(null);

  const background = useMemo(() => new Image(), [src]);

  const handleWheel = (event) => {
    const { deltaY } = event;
    if (!dragging && zoomOnWheel) {
      setZoom((_zoom) =>
        clamp(_zoom + deltaY * zoomStep * -1, minZoom, maxZoom)
      );
    }
  };

  const handleDoubleClick = () => zoomIn();

  const handleMouseMove = (event) => {
    if (dragging) {
      const { x, y } = touch.current;
      const { clientX, clientY } = event;
      setOffset({
        x: offset.x + (x - clientX),
        y: offset.y + (y - clientY),
      });
      touch.current = { x: clientX, y: clientY };
    }
  };

  const handleMouseDown = (event) => {
    const { clientX, clientY } = event;
    touch.current = { x: clientX, y: clientY };
    setDragging(true);
  };

  const handleMouseUp = () => setDragging(false);

  const draw = () => {
    if (canvasRef.current) {
      const { width, height } = canvasRef.current;
      const context = canvasRef.current.getContext('2d');

      // Set canvas dimensions
      canvasRef.current.width = width;
      canvasRef.current.height = height;

      // Clear canvas and scale it
      context.translate(-offset.x, -offset.y);
      context.scale(zoom, zoom);
      context.clearRect(0, 0, width, height);

      // Make sure we're zooming to the center
      const x = (context.canvas.width / zoom - background.width) / 2;
      const y = (context.canvas.height / zoom - background.height) / 2;

      // Draw image
      context.drawImage(background, x, y);
    }
  };

  useEffect(() => {
    observer.current = new ResizeObserver((entries) => {
      entries.forEach(({ target }) => {
        const { width, height } = background;
        // If width of the container is smaller than image, scale image down
        if (target.clientWidth < width) {
          // Calculate scale
          const scale = target.clientWidth / width;

          // Redraw image
          canvasRef.current.width = width * scale;
          canvasRef.current.height = height * scale;
          canvasRef.current
            .getContext('2d')
            .drawImage(background, 0, 0, width * scale, height * scale);
        }
      });
    });
    observer.current.observe(containerRef.current);

    return () => {
      if (containerRef.current)
        observer.current.unobserve(containerRef.current);
    };
  }, [isSizeToFit]);

  useEffect(() => {
    background.src = src;

    if (canvasRef.current) {
      background.onload = () => {
        // Get the image dimensions
        const { width, height } = background;
        canvasRef.current.width = width;
        canvasRef.current.height = height;

        // Set image as background
        canvasRef.current.getContext('2d').drawImage(background, 0, 0);
      };
    }
  }, [background, isSizeToFit]);

  useEffect(() => {
    draw();
  }, [zoom, offset, isSizeToFit]);

  useEffect(() => {
    setOffset({ x: 0, y: 0 });
    setDragging(false);
    setZoom(1);
    touch.current = { x: 0, y: 0 };
  }, [isSizeToFit]);

  return (
    <div
      ref={containerRef}
      className={isSizeToFit && 'flex h-full w-full'}
      style={{ cursor: dragging ? 'grabbing' : 'grab' }}
    >
      <canvas
        onWheel={handleWheel}
        onMouseMove={handleMouseMove}
        onMouseDown={handleMouseDown}
        onMouseUp={handleMouseUp}
        onDoubleClick={handleDoubleClick}
        ref={canvasRef}
        className="h-auto w-full object-contain"
      />
    </div>
  );
};
