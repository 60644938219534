import React, { useRef } from "react";
import SvgContainer from "../SvgWrapper";

const ArrowLeftIcon = ({ color, size, ...rest }) => {
  const clipRef = useRef(`${Math.floor(Math.random() * 1000)}`);
  return (
    <SvgContainer
      width={`${size}`}
      height={`${size}`}
      viewBox="0 0 300 300"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <g clipPath={`url(#${clipRef.current})`}>
        <path
          d="M218 300C221.333 300 224.667 298.75 227.583 296.25C232.583 291.25 232.583 282.5 227.583 277.5L100.083 150L227.583 22.5C232.583 17.5 232.583 8.75 227.583 3.75C222.583 -1.25 213.833 -1.25 208.833 3.75L71.75 140.417C66.75 145.417 66.75 154.167 71.75 159.167L208.417 295.833C211.333 298.75 214.667 300 218 300Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id={clipRef.current}>
          <rect width="163.333" height="300" fill="none" transform="translate(68)" />
        </clipPath>
      </defs>
    </SvgContainer>
  );
};

export default ArrowLeftIcon;
