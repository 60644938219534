import axios, { AxiosRequestConfig, AxiosRequestHeaders } from 'axios';
import { constructQueryString } from '@thrivelot/utils';

interface APIKitProps {
  variables?: { [key: string]: any };
  endpoint?: string;
  headers?: AxiosRequestHeaders;
  config?: AxiosRequestConfig;
}

class ApiKit {
  private variables?: { [key: string]: any };

  private endpoint?: string;

  private headers?: AxiosRequestHeaders;

  private config?: AxiosRequestConfig;

  constructor(props?: APIKitProps) {
    const { variables, endpoint, headers, config } = props || {};
    this.#init({ variables, endpoint, headers, config });
  }

  #init(props?: APIKitProps) {
    if (props?.variables) this.variables = props.variables;
    if (props?.endpoint) this.endpoint = props.endpoint;
    if (props?.headers) this.headers = props.headers;
    if (props?.config) this.config = props.config;
  }

  async get(props?: APIKitProps) {
    this.#init(props);

    if (!this.endpoint) throw new Error('ApiKit.get: endpoint is required');

    try {
      const { data } = await axios.get(
        `${this.endpoint}${constructQueryString(this.variables)}`,
        {
          headers: this.headers,
          ...(this.config || {}),
        }
      );

      return data;
    } catch (err) {
      console.error('Error in ApiKit get:', JSON.stringify(err, null, 2));
      throw err;
    }
  }

  async post(props?: APIKitProps) {
    this.#init(props);

    if (!this.endpoint) throw new Error('ApiKit.post: endpoint is required');

    try {
      const { data } = await axios.post(this.endpoint, this.variables, {
        headers: this.headers,
        ...(this.config || {}),
      });

      return data;
    } catch (err) {
      console.error('Error in ApiKit post:', JSON.stringify(err, null, 2));
      throw err;
    }
  }
}

export { ApiKit };
