import { useModel, useAuth } from '@thrivelot/hooks';
import { isCustomerApp } from '@thrivelot/utils';
import React, { useCallback } from 'react';
import { Button } from '@thrivelot/stories';
import { LayoutModal } from '../../LayoutModal';

const getModelName = () => (isCustomerApp() ? 'Customer' : 'Supplier');

const ModalReviewTerms = ({ onClose, termsUrl }) => {
  const { userId } = useAuth();
  const {
    model: user,
    loaded,
    updateModel,
  } = useModel({ modelName: getModelName(), id: userId });

  const handleAccept = useCallback(() => {
    const { firstname = '', lastname = '' } = user;
    const supplierName = [firstname, lastname].join(' ');

    const termsOfUse = {
      name: supplierName,
      agreedAt: new Date().toISOString(),
      revisedAt: '2022-01-19T04:00:00.000Z',
    };

    updateModel({ termsOfUse });
    onClose();
  }, [user, updateModel, onClose]);

  const agreedAt = user?.termsOfUse?.agreedAt;

  return (
    <LayoutModal
      header="Terms &#38; Conditions"
      width="sm"
      loading={!loaded}
      closeDisabled={true}
      footer={
        <Button
          icon="Checkmark"
          block
          kind="outline"
          color="green-main"
          label="I Agree"
          onClick={handleAccept}
        />
      }
    >
      <div className="text-brown-dark">
        {agreedAt ? 'We have recently updated our ' : 'Our '}
        <a
          href={
            isCustomerApp()
              ? 'https://thrivelot.notion.site/Terms-Conditions-for-Customer-d29485acd283404b900c513d533784f2'
              : 'https://thrivelot.notion.site/Terms-Conditions-Service-Provider-569ec9c1306e41f9a9cf2134be100019'
          }
          target="_blank"
          rel="noreferrer"
          className="font-bold text-blue-main mx-1 hover:cursor-pointer hover:underline"
        >
          {isCustomerApp()
            ? 'Thrive Lot Customer Terms & Conditions'
            : 'Thrive Lot Supplier Terms & Conditions'}
        </a>
        {agreedAt ? 'that ' : ' '}
        detail what to expect as you use our services. By clicking “I Agree,”
        you agree to these terms.
      </div>
    </LayoutModal>
  );
};

export { ModalReviewTerms };
