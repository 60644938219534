import React from 'react';
import { Switch } from '@headlessui/react';

export const SelectBox = ({ label, selected, onClick }) => (
  <div className="flex mb-4 items-center">
    <div className="block font-accent text-mulch-dark text-sm ml-2 mr-4">
      {label}
    </div>
    <Switch
      className={`${
        selected ? 'bg-green-main' : 'bg-yellow-dark'
      } relative inline-flex h-6 w-11 items-center rounded-full`}
      checked={selected || false}
      onChange={onClick}
    >
      <span className="sr-only">Enable notifications</span>
      <span
        className={`${
          selected ? 'translate-x-6' : 'translate-x-1'
        } inline-block h-4 w-4 transform rounded-full bg-white-light transition`}
      />
    </Switch>
  </div>
);
