import React from 'react';
import { Input } from '@thrivelot/stories';

const isNumber = (value, numValues = '0123456789') => {
  if (value === '') return true;

  let isInFactNumber = true;

  for (let i = 0; i < value.length; i += 1) {
    if (!numValues.includes(value.charAt(i))) {
      isInFactNumber = false;
      break;
    }
  }

  return isInFactNumber;
};

export const InputNumber = ({
  containerClassName,
  name,
  label,
  placeholder,
  value,
  onChange,
  numValues = '0123456789',
}) => {
  const handleOnChange = (e) => {
    const newValue = e.target.value;
    console.log(newValue);
    if (newValue.trim() === '') onChange(0);
    if (isNumber(value, numValues)) {
      let numValue = parseInt(newValue, 10);
      if (Number.isNaN(numValue)) numValue = 0;
      onChange(numValue);
    }
  };

  return (
    <Input
      containerClassName={containerClassName}
      type="number"
      name={name}
      label={label}
      placeholder={placeholder}
      value={value}
      onChange={handleOnChange}
      onWheel={(e) => e.target.blur()}
    />
  );
};
