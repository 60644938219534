import React from 'react';

const Phases = ({
  color = 'green-main',
  size = 30,
  styleProps = '',
  ...rest
}) => (
  <svg
    width={size.toString()}
    height={size.toString()}
    viewBox="0 0 75 75"
    className={`fill-${color} ${styleProps}`}
    {...rest}
  >
    <path d="M14.8595 38.9583H59.8282C64.7207 38.9583 68.8845 35.5208 70.3418 30.7292C70.4459 30.7292 70.4459 30.625 70.55 30.625L74.6096 26.25C75.1301 25.7292 75.1301 24.7917 74.6096 24.1667C74.0892 23.5417 73.2564 23.5417 72.736 24.1667L70.8623 26.0417C70.4459 19.7917 65.6575 14.8958 59.8282 14.8958H51.0843V9.16667C51.0843 4.16667 47.3369 0 42.6527 0H10.5916C5.90736 0 2.15996 4.0625 2.15996 9.16667V23.75C2.15996 28.75 5.90736 32.9167 10.5916 32.9167H42.7568C47.3369 32.9167 51.1884 28.8542 51.1884 23.75V17.9167H59.9323C64.2002 17.9167 67.7394 21.4583 68.2599 26.0417L66.5944 24.1667C66.0739 23.5417 65.2412 23.5417 64.7207 24.1667C64.2002 24.7917 64.2002 25.625 64.7207 26.25L67.9476 29.7917C66.9067 33.4375 63.6797 36.1458 60.0364 36.1458H14.8595C9.34247 36.1458 4.76232 40.625 3.92956 46.3542L2.26405 44.4792C1.74358 43.9583 0.910826 43.9583 0.390354 44.4792C-0.130118 45.1042 -0.130118 45.9375 0.390354 46.5625L4.13775 50.5208C5.1787 55.9375 9.65475 60 14.9636 60H23.7075V66.0417C23.7075 70.9375 27.3508 75 31.931 75H64.5125C68.9886 75 72.736 71.0417 72.736 66.0417V51.0417C72.736 46.1458 69.0926 42.0833 64.5125 42.0833H31.8269C27.3508 42.0833 23.6034 46.0417 23.6034 51.0417V57.0833H14.8595C11.008 57.0833 7.67696 54.1667 6.74011 50.2083L10.2793 46.4583C10.7998 45.9375 10.7998 45 10.2793 44.375C9.75885 43.8542 8.92609 43.8542 8.40562 44.375L6.74011 46.25C7.46877 42.0833 10.7998 38.9583 14.8595 38.9583ZM45.0468 23.75C45.0468 25.1042 44.0059 26.25 42.7568 26.25H10.5916C9.34247 26.25 8.30153 25.1042 8.30153 23.75V9.16667C8.30153 7.8125 9.34247 6.66667 10.5916 6.66667H42.7568C44.0059 6.66667 45.0468 7.8125 45.0468 9.16667V23.75ZM29.745 51.0417C29.745 49.7917 30.6818 48.75 31.8269 48.75H64.4084C65.5534 48.75 66.4903 49.7917 66.4903 51.0417V66.0417C66.4903 67.2917 65.5534 68.3333 64.4084 68.3333H31.8269C30.6818 68.3333 29.745 67.2917 29.745 66.0417V51.0417Z" />
  </svg>
);

export { Phases };
