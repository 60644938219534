import React, { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useModal, usePlant } from '@thrivelot/hooks';
import { Button } from '@thrivelot/stories';
import { LayoutModal } from '../..';
import { FilePreview } from './FilePreview';
import { convertHeicToPng } from '../ModalAddFiles/convertHeicToPng';

export const ModalAddPlantImages = ({
  onClose,
  modelId,
  fromEditModal = false,
  imagesOnly = true,
  header = 'Add Plant Images',
  subheader = 'You can add multiple images at once',
}) => {
  const [files, setFiles] = useState([]);
  const [urls, setUrls] = useState([]);
  const [currentUrl, setCurrentUrl] = useState('');
  const [enteringUrls, setEnteringUrls] = useState(false);
  const [uploading, setUploading] = useState(false);
  const { uploadMultipleImages } = usePlant({ id: modelId });
  const { openModal } = useModal();

  const onDrop = useCallback((acceptedFiles) => {
    acceptedFiles.forEach((acceptedFile) => {
      const reader = new FileReader();
      const file = {
        path: acceptedFile.path,
        name: acceptedFile.name,
        size: acceptedFile.size,
        type: acceptedFile.type,
      };

      reader.onabort = () => console.log('file reading was aborted');
      reader.onerror = () => console.log('file reading has failed');
      reader.onload = () => {
        const dataUrl = reader.result;
        setFiles((prevFiles) => [...prevFiles, { ...file, dataUrl }]);
      };

      if (acceptedFile.type === 'image/heic')
        convertHeicToPng(acceptedFile).then((blob) =>
          reader.readAsDataURL(blob)
        );
      else reader.readAsDataURL(acceptedFile);
    });
  }, []);

  const handleUpload = useCallback(async () => {
    setUploading(true);

    const uploadingImages = files.map((file) => ({
      base64Image: file.dataUrl.replace(/^data:.+,/, ''),
      metadata: { fileType: file.type },
    }));
    const uploadingUrlImages = urls.map((url) => ({
      url,
    }));
    const images = [...uploadingImages, ...uploadingUrlImages];

    await uploadMultipleImages(images);

    setUploading(false);

    if (fromEditModal) openModal({ path: 'ModalEditPlant', modelId });
    else onClose();
  }, [files, urls, onClose, openModal, fromEditModal]);

  const addUrl = useCallback(() => {
    const url = currentUrl.trim();
    if (!url) return;

    setUrls((prevUrls) => [...prevUrls, url]);
    setCurrentUrl('');
  }, [currentUrl]);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: imagesOnly ? 'image/*' : undefined,
  });

  const totalLength = files.length + urls.length;

  return (
    <LayoutModal
      onClose={() =>
        fromEditModal
          ? openModal({ path: 'ModalEditPlant', modelId })
          : onClose()
      }
      header={<div className="font-bold text-brown-dark">{header}</div>}
      subheader={<div className="text-sm text-brown-dark">{subheader}</div>}
      footer={
        <Button
          disabled={uploading || totalLength === 0}
          loading={uploading}
          block
          kind="outline"
          color="blue-main"
          label="Upload"
          onClick={handleUpload}
        />
      }
    >
      {totalLength > 0 && (
        <div className="pb-5 flex gap-4 items-center">
          {files.map((file) => (
            <FilePreview key={file.name} file={file} height={48} />
          ))}
          {urls.map((url) => (
            <img style={{ height: `${48}px` }} alt={url} src={url} />
          ))}
        </div>
      )}
      {enteringUrls ? (
        <div className="flex">
          <input
            className="input"
            placeholder='Paste image URL here, e.g. "https://example.com/image.jpg"'
            value={currentUrl}
            onChange={(evt) => setCurrentUrl(evt.target.value)}
            onKeyDown={(evt) => {
              const keyCode = evt.code || evt.key;
              if (keyCode === 'Enter') addUrl();
            }}
          />
          <Button
            icon="Plus"
            kind="outline"
            color="green-main"
            label="Add"
            classAppend="ml-2"
            disabled={currentUrl.trim() === ''}
            onClick={addUrl}
          />
        </div>
      ) : (
        <div {...getRootProps()}>
          <input {...getInputProps()} />
          <div className="w-full h-36 flex items-center justify-center border-dashed border-2 border-brown-light p-5 text-brown-light hover:cursor-pointer focus:outline-none focus:ring-none">
            {isDragActive
              ? 'Drop images here'
              : "Drag 'n' drop some images here, or click to select images"}
          </div>
        </div>
      )}
      <div className="w-full flex h-12 items-center justify-center">
        <div className="mr-2">or</div>
        <Button
          kind="prompt"
          color="green-main"
          label={enteringUrls ? 'Upload from your device' : 'Enter image URL'}
          onClick={() => setEnteringUrls(!enteringUrls)}
        />
      </div>
    </LayoutModal>
  );
};
