import { useContext } from "react";
import { ProposalViewContext } from "./ProposalViewContext";

const useProposalView = () => {
  const { file, payment, phase, plant, zone, setValue } = useContext(ProposalViewContext);

  return { file, payment, phase, plant, zone, setValue };
};

export { useProposalView };
