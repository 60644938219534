import React from "react";
import { css } from "styled-components";
import { Circles } from "react-loader-spinner";
import DivAppContainer from "./DivAppContainer";

const DivSuspenseContainer = () => {
  const theme = window.$theme;
  return (
    <DivAppContainer
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Circles
        color={theme.blue}
        css={css`
          height: 30px;
          width: 30px;
        `}
        size={30}
        loading
      />
    </DivAppContainer>
  );
};

export default DivSuspenseContainer;
