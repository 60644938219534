/* eslint-disable no-nested-ternary */
const getReactIconStyle = ({ width, height, onClick, hoverable, hovering, disabled = false }) => ({
  opacity: disabled ? 0.3 : hoverable && hovering ? 0.7 : 1,
  cursor: hoverable && !disabled ? "pointer" : "default",
  pointerEvents: onClick && !disabled ? "auto" : "none",
  minWidth: width,
  maxWidth: width,
  minHeight: height,
  maxHeight: height,
});

export default getReactIconStyle;
