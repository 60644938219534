import React from 'react';
import { milestoneColorMap } from '@thrivelot/config';
import { dateFns } from '@thrivelot/date';
import { Clock, User, RowListItem } from '@thrivelot/stories';
import {
  constructAddressString,
  getProjectStage,
  getProjectStageAbbreviation,
} from '@thrivelot/common';

const RowItemProject = ({ project }) => {
  const { id } = project;
  const stage = getProjectStage(project);
  const stageAbbreviation = getProjectStageAbbreviation(project);
  const address = constructAddressString(project.details.address);

  const projectName = project.details.title;
  const { firstname, lastname } = project.customer || {};

  const customerName =
    firstname || lastname ? `${firstname} ${lastname}` : 'No Customer';

  return (
    <RowListItem
      to={`/projects/${id}`}
      thumbnail={
        <div
          className={`rounded-full h-10 w-10 bg-${milestoneColorMap[stage]}-light font-bold flex items-center justify-center`}
        >
          <div className={`font-bold text-${milestoneColorMap[stage]}-dark`}>
            {stageAbbreviation}
          </div>
        </div>
      }
      header={projectName || customerName || address}
      subheader={projectName ? customerName : null}
      details={
        <>
          {project.owners && project.owners.length > 0 && (
            <div className="italic text-sm flex items-center">
              <div className="mr-1">{project.owners.length} x</div>
              <User size={12} color="mulch-light" />
            </div>
          )}
          {project.updatedAt && (
            <div className="italic text-sm flex items-center">
              <div className="mr-1">{dateFns(project.updatedAt).fromNow()}</div>
              <Clock size={12} color="mulch-light" />
            </div>
          )}
        </>
      }
    />
  );
};

export { RowItemProject };
