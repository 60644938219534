/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getCustomer = /* GraphQL */ `
  query GetCustomer($id: ID!) {
    getCustomer(id: $id) {
      id
      owners
      viewers
      email
      phoneNumber
      stripeId
      hubspotId
      firstname
      lastname
      contactTimes {
        day
        timeBlocks {
          start
          end
        }
      }
      termsOfUse {
        name
        agreedAt
        revisedAt
      }
      projectIds
      projects {
        items {
          id
          owners
          viewers
          hubspotId
          details {
            title
            projectNotes
            address {
              line1
              line2
              city
              county
              state
              country
              zip
              formattedAddress
            }
            property {
              size
              amountImpacted
            }
            desire
            styles {
              name
              description
            }
            features {
              name
              description
            }
            priorities {
              prompt
              priority
            }
            favoritePlants
            petInformation
            allergyInformation
            additionalNotes
            adminNotes
            siteAssessmentAvailability {
              day
            }
            budgetRange
            budgetCommitment
            useInvoiceProposals
          }
          siteAnalysis {
            id
            title
            observations
            recommendations
            color
            tagIds {
              type
              tagId
            }
          }
          files {
            id
            file {
              bucket
              region
              key
              type
              filename
            }
            caption
            selected
            tagIds {
              type
              tagId
            }
          }
          fileFolders {
            id
            name
            fileIds
          }
          annotations {
            id
            type
            location {
              lat
              lng
            }
            notes
            tagIds {
              type
              tagId
            }
          }
          zones {
            id
            type
            name
            color
            description
            center {
              lat
              lng
            }
            bounds {
              lat
              lng
            }
            tagIds {
              type
              tagId
            }
            orderedChildTagIds {
              type
              tagId
            }
          }
          features {
            id
            name
            description
            color
            center {
              lat
              lng
            }
            bounds {
              lat
              lng
            }
            tagIds {
              type
              tagId
            }
            orderedChildTagIds {
              type
              tagId
            }
            hidden
          }
          plantItems {
            id
            cost
            quantity
            notes
            source
            locations {
              lat
              lng
            }
            changes {
              key
              change
            }
            tagIds {
              type
              tagId
            }
            created
          }
          lineItems {
            id
            type
            name
            cost
            quantity
            unit
            notes
            source
            locations {
              lat
              lng
            }
            tagIds {
              type
              tagId
            }
          }
          activities {
            id
            name
            description
            dates
            tagIds {
              type
              tagId
            }
            orderedChildTagIds {
              type
              tagId
            }
          }
          phases {
            id
            name
            description
            startDate
            endDate
            tagIds {
              type
              tagId
            }
            orderedChildTagIds {
              type
              tagId
            }
          }
          costPaymentSchedule {
            id
            type
            payments {
              id
              date
              paidAt
              paidBy
              markup
            }
            memo
            tagIds {
              type
              tagId
            }
          }
          calendarNotes {
            date
            notes
            private
            tagIds {
              type
              tagId
            }
          }
          milestones {
            id
            type
            stage
            deliverables {
              id
            }
            lifecycleEvents {
              id
              type
              notes
              by
              at
            }
            paymentIds
          }
          currentMilestone
          showProposal
          customer {
            id
            owners
            viewers
            email
            phoneNumber
            stripeId
            hubspotId
            firstname
            lastname
            contactTimes {
              day
            }
            termsOfUse {
              name
              agreedAt
              revisedAt
            }
            projectIds
            projects {
              nextToken
            }
            createdAt
            updatedAt
            updatedMeta {
              id
              userCognitoId
              userId
              userName
              userEmail
              userGroups
              device
            }
            deleted
          }
          customerId
          invoiceProposalIds
          createdAt
          updatedAt
          updatedMeta {
            id
            userCognitoId
            userId
            userName
            userEmail
            userGroups
            device
          }
          deleted
        }
        nextToken
      }
      createdAt
      updatedAt
      updatedMeta {
        id
        userCognitoId
        userId
        userName
        userEmail
        userGroups
        device
      }
      deleted
    }
  }
`;
export const listCustomers = /* GraphQL */ `
  query ListCustomers(
    $filter: ModelCustomerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCustomers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        owners
        viewers
        email
        phoneNumber
        stripeId
        hubspotId
        firstname
        lastname
        contactTimes {
          day
          timeBlocks {
            start
            end
          }
        }
        termsOfUse {
          name
          agreedAt
          revisedAt
        }
        projectIds
        projects {
          items {
            id
            owners
            viewers
            hubspotId
            details {
              title
              projectNotes
              desire
              favoritePlants
              petInformation
              allergyInformation
              additionalNotes
              adminNotes
              budgetRange
              budgetCommitment
              useInvoiceProposals
            }
            siteAnalysis {
              id
              title
              observations
              recommendations
              color
            }
            files {
              id
              caption
              selected
            }
            fileFolders {
              id
              name
              fileIds
            }
            annotations {
              id
              type
              notes
            }
            zones {
              id
              type
              name
              color
              description
            }
            features {
              id
              name
              description
              color
              hidden
            }
            plantItems {
              id
              cost
              quantity
              notes
              source
              created
            }
            lineItems {
              id
              type
              name
              cost
              quantity
              unit
              notes
              source
            }
            activities {
              id
              name
              description
              dates
            }
            phases {
              id
              name
              description
              startDate
              endDate
            }
            costPaymentSchedule {
              id
              type
              memo
            }
            calendarNotes {
              date
              notes
              private
            }
            milestones {
              id
              type
              stage
              paymentIds
            }
            currentMilestone
            showProposal
            customer {
              id
              owners
              viewers
              email
              phoneNumber
              stripeId
              hubspotId
              firstname
              lastname
              projectIds
              createdAt
              updatedAt
              deleted
            }
            customerId
            invoiceProposalIds
            createdAt
            updatedAt
            updatedMeta {
              id
              userCognitoId
              userId
              userName
              userEmail
              userGroups
              device
            }
            deleted
          }
          nextToken
        }
        createdAt
        updatedAt
        updatedMeta {
          id
          userCognitoId
          userId
          userName
          userEmail
          userGroups
          device
        }
        deleted
      }
      nextToken
    }
  }
`;
export const getProject = /* GraphQL */ `
  query GetProject($id: ID!) {
    getProject(id: $id) {
      id
      owners
      viewers
      hubspotId
      details {
        title
        projectNotes
        address {
          line1
          line2
          city
          county
          state
          country
          zip
          formattedAddress
          location {
            lat
            lng
          }
        }
        property {
          size
          amountImpacted
        }
        desire
        styles {
          name
          description
        }
        features {
          name
          description
        }
        priorities {
          prompt
          priority
        }
        favoritePlants
        petInformation
        allergyInformation
        additionalNotes
        adminNotes
        siteAssessmentAvailability {
          day
          timeBlocks {
            start
            end
          }
        }
        budgetRange
        budgetCommitment
        useInvoiceProposals
      }
      siteAnalysis {
        id
        title
        observations
        recommendations
        color
        tagIds {
          type
          tagId
        }
      }
      files {
        id
        file {
          bucket
          region
          key
          type
          filename
        }
        caption
        selected
        tagIds {
          type
          tagId
        }
      }
      fileFolders {
        id
        name
        fileIds
      }
      annotations {
        id
        type
        location {
          lat
          lng
        }
        notes
        tagIds {
          type
          tagId
        }
      }
      zones {
        id
        type
        name
        color
        description
        center {
          lat
          lng
        }
        bounds {
          lat
          lng
        }
        tagIds {
          type
          tagId
        }
        orderedChildTagIds {
          type
          tagId
        }
      }
      features {
        id
        name
        description
        color
        center {
          lat
          lng
        }
        bounds {
          lat
          lng
        }
        tagIds {
          type
          tagId
        }
        orderedChildTagIds {
          type
          tagId
        }
        hidden
      }
      plantItems {
        id
        cost
        quantity
        notes
        source
        locations {
          lat
          lng
        }
        changes {
          key
          change
        }
        tagIds {
          type
          tagId
        }
        created
      }
      lineItems {
        id
        type
        name
        cost
        quantity
        unit
        notes
        source
        locations {
          lat
          lng
        }
        tagIds {
          type
          tagId
        }
      }
      activities {
        id
        name
        description
        dates
        tagIds {
          type
          tagId
        }
        orderedChildTagIds {
          type
          tagId
        }
      }
      phases {
        id
        name
        description
        startDate
        endDate
        tagIds {
          type
          tagId
        }
        orderedChildTagIds {
          type
          tagId
        }
      }
      costPaymentSchedule {
        id
        type
        payments {
          id
          date
          calculation {
            type
            amount
            fullPayment
            tagId {
              type
              tagId
            }
          }
          paidAt
          paidBy
          markup
        }
        memo
        tagIds {
          type
          tagId
        }
      }
      calendarNotes {
        date
        notes
        private
        tagIds {
          type
          tagId
        }
      }
      milestones {
        id
        type
        stage
        deliverables {
          id
          estimatedDelivery {
            quantity
            unit
          }
          tagIds {
            type
            tagId
          }
        }
        lifecycleEvents {
          id
          type
          notes
          by
          at
          tagIds {
            type
            tagId
          }
        }
        paymentIds
      }
      currentMilestone
      showProposal
      customer {
        id
        owners
        viewers
        email
        phoneNumber
        stripeId
        hubspotId
        firstname
        lastname
        contactTimes {
          day
          timeBlocks {
            start
            end
          }
        }
        termsOfUse {
          name
          agreedAt
          revisedAt
        }
        projectIds
        projects {
          items {
            id
            owners
            viewers
            hubspotId
            details {
              title
              projectNotes
              desire
              favoritePlants
              petInformation
              allergyInformation
              additionalNotes
              adminNotes
              budgetRange
              budgetCommitment
              useInvoiceProposals
            }
            siteAnalysis {
              id
              title
              observations
              recommendations
              color
            }
            files {
              id
              caption
              selected
            }
            fileFolders {
              id
              name
              fileIds
            }
            annotations {
              id
              type
              notes
            }
            zones {
              id
              type
              name
              color
              description
            }
            features {
              id
              name
              description
              color
              hidden
            }
            plantItems {
              id
              cost
              quantity
              notes
              source
              created
            }
            lineItems {
              id
              type
              name
              cost
              quantity
              unit
              notes
              source
            }
            activities {
              id
              name
              description
              dates
            }
            phases {
              id
              name
              description
              startDate
              endDate
            }
            costPaymentSchedule {
              id
              type
              memo
            }
            calendarNotes {
              date
              notes
              private
            }
            milestones {
              id
              type
              stage
              paymentIds
            }
            currentMilestone
            showProposal
            customer {
              id
              owners
              viewers
              email
              phoneNumber
              stripeId
              hubspotId
              firstname
              lastname
              projectIds
              createdAt
              updatedAt
              deleted
            }
            customerId
            invoiceProposalIds
            createdAt
            updatedAt
            updatedMeta {
              id
              userCognitoId
              userId
              userName
              userEmail
              userGroups
              device
            }
            deleted
          }
          nextToken
        }
        createdAt
        updatedAt
        updatedMeta {
          id
          userCognitoId
          userId
          userName
          userEmail
          userGroups
          device
        }
        deleted
      }
      customerId
      invoiceProposalIds
      createdAt
      updatedAt
      updatedMeta {
        id
        userCognitoId
        userId
        userName
        userEmail
        userGroups
        device
      }
      deleted
    }
  }
`;
export const listProjects = /* GraphQL */ `
  query ListProjects(
    $filter: ModelProjectFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProjects(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        owners
        viewers
        hubspotId
        details {
          title
          projectNotes
          address {
            line1
            line2
            city
            county
            state
            country
            zip
            formattedAddress
            location {
              lat
              lng
            }
          }
          property {
            size
            amountImpacted
          }
          desire
          styles {
            name
            description
          }
          features {
            name
            description
          }
          priorities {
            prompt
            priority
          }
          favoritePlants
          petInformation
          allergyInformation
          additionalNotes
          adminNotes
          siteAssessmentAvailability {
            day
            timeBlocks {
              start
              end
            }
          }
          budgetRange
          budgetCommitment
          useInvoiceProposals
        }
        siteAnalysis {
          id
          title
          observations
          recommendations
          color
          tagIds {
            type
            tagId
          }
        }
        files {
          id
          file {
            bucket
            region
            key
            type
            filename
          }
          caption
          selected
          tagIds {
            type
            tagId
          }
        }
        fileFolders {
          id
          name
          fileIds
        }
        annotations {
          id
          type
          location {
            lat
            lng
          }
          notes
          tagIds {
            type
            tagId
          }
        }
        zones {
          id
          type
          name
          color
          description
          center {
            lat
            lng
          }
          bounds {
            lat
            lng
          }
          tagIds {
            type
            tagId
          }
          orderedChildTagIds {
            type
            tagId
          }
        }
        features {
          id
          name
          description
          color
          center {
            lat
            lng
          }
          bounds {
            lat
            lng
          }
          tagIds {
            type
            tagId
          }
          orderedChildTagIds {
            type
            tagId
          }
          hidden
        }
        plantItems {
          id
          cost
          quantity
          notes
          source
          locations {
            lat
            lng
          }
          changes {
            key
            change
          }
          tagIds {
            type
            tagId
          }
          created
        }
        lineItems {
          id
          type
          name
          cost
          quantity
          unit
          notes
          source
          locations {
            lat
            lng
          }
          tagIds {
            type
            tagId
          }
        }
        activities {
          id
          name
          description
          dates
          tagIds {
            type
            tagId
          }
          orderedChildTagIds {
            type
            tagId
          }
        }
        phases {
          id
          name
          description
          startDate
          endDate
          tagIds {
            type
            tagId
          }
          orderedChildTagIds {
            type
            tagId
          }
        }
        costPaymentSchedule {
          id
          type
          payments {
            id
            date
            calculation {
              type
              amount
              fullPayment
            }
            paidAt
            paidBy
            markup
          }
          memo
          tagIds {
            type
            tagId
          }
        }
        calendarNotes {
          date
          notes
          private
          tagIds {
            type
            tagId
          }
        }
        milestones {
          id
          type
          stage
          deliverables {
            id
            estimatedDelivery {
              quantity
              unit
            }
            tagIds {
              type
              tagId
            }
          }
          lifecycleEvents {
            id
            type
            notes
            by
            at
            tagIds {
              type
              tagId
            }
          }
          paymentIds
        }
        currentMilestone
        showProposal
        customer {
          id
          owners
          viewers
          email
          phoneNumber
          stripeId
          hubspotId
          firstname
          lastname
          contactTimes {
            day
            timeBlocks {
              start
              end
            }
          }
          termsOfUse {
            name
            agreedAt
            revisedAt
          }
          projectIds
          projects {
            items {
              id
              owners
              viewers
              hubspotId
              currentMilestone
              showProposal
              customerId
              invoiceProposalIds
              createdAt
              updatedAt
              deleted
            }
            nextToken
          }
          createdAt
          updatedAt
          updatedMeta {
            id
            userCognitoId
            userId
            userName
            userEmail
            userGroups
            device
          }
          deleted
        }
        customerId
        invoiceProposalIds
        createdAt
        updatedAt
        updatedMeta {
          id
          userCognitoId
          userId
          userName
          userEmail
          userGroups
          device
        }
        deleted
      }
      nextToken
    }
  }
`;
export const getSupplier = /* GraphQL */ `
  query GetSupplier($id: ID!) {
    getSupplier(id: $id) {
      id
      owners
      viewers
      email
      phoneNumber
      firstname
      lastname
      hubspotId
      stripeId
      businessName
      bio
      address {
        line1
        line2
        city
        county
        state
        country
        zip
        formattedAddress
        location {
          lat
          lng
        }
      }
      projectTypes
      qualifications {
        certificates
        degrees
        skills
      }
      links {
        facebook
        instagram
        pinterest
        twitter
        website
        youtube
      }
      pic {
        bucket
        region
        key
        type
        filename
      }
      calendarNotes {
        date
        notes
        private
        tagIds {
          type
          tagId
        }
      }
      termsOfUse {
        name
        businessType
        agreedAt
        revisedAt
      }
      createdAt
      updatedAt
      updatedMeta {
        id
        userCognitoId
        userId
        userName
        userEmail
        userGroups
        device
      }
      deleted
    }
  }
`;
export const listSuppliers = /* GraphQL */ `
  query ListSuppliers(
    $filter: ModelSupplierFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSuppliers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        owners
        viewers
        email
        phoneNumber
        firstname
        lastname
        hubspotId
        stripeId
        businessName
        bio
        address {
          line1
          line2
          city
          county
          state
          country
          zip
          formattedAddress
          location {
            lat
            lng
          }
        }
        projectTypes
        qualifications {
          certificates
          degrees
          skills
        }
        links {
          facebook
          instagram
          pinterest
          twitter
          website
          youtube
        }
        pic {
          bucket
          region
          key
          type
          filename
        }
        calendarNotes {
          date
          notes
          private
          tagIds {
            type
            tagId
          }
        }
        termsOfUse {
          name
          businessType
          agreedAt
          revisedAt
        }
        createdAt
        updatedAt
        updatedMeta {
          id
          userCognitoId
          userId
          userName
          userEmail
          userGroups
          device
        }
        deleted
      }
      nextToken
    }
  }
`;
export const getSubscriptionNotification = /* GraphQL */ `
  query GetSubscriptionNotification($id: ID!) {
    getSubscriptionNotification(id: $id) {
      id
      subscriber
      modelName
      modelId
      expiry
      createdAt
      updatedAt
    }
  }
`;
export const listSubscriptionNotifications = /* GraphQL */ `
  query ListSubscriptionNotifications(
    $filter: ModelSubscriptionNotificationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSubscriptionNotifications(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        subscriber
        modelName
        modelId
        expiry
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getBundle = /* GraphQL */ `
  query GetBundle($id: ID!) {
    getBundle(id: $id) {
      id
      owners
      viewers
      status
      supplierPayeeId
      orderedFeatureIds
      orderedFeatureData {
        id
        lineItemsDescription
        changeItemsDescription
        featureId
      }
      lineItems {
        id
        kind
        name
        description
        unit
        quantity
        pricePerUnit
        costPerUnit
        data {
          imageUrl
          plantDatabaseId
          changeForLineItemId
          catalogSourceId
          importSourceId
        }
        featureId
        hidden
      }
      changeItems {
        id
        kind
        name
        description
        unit
        quantity
        pricePerUnit
        costPerUnit
        data {
          imageUrl
          plantDatabaseId
          changeForLineItemId
          catalogSourceId
          importSourceId
        }
        featureId
        hidden
      }
      invoiceProposalId
      projectId
      createdAt
      updatedAt
      updatedMeta {
        id
        userCognitoId
        userId
        userName
        userEmail
        userGroups
        device
      }
      deleted
    }
  }
`;
export const listBundles = /* GraphQL */ `
  query ListBundles(
    $filter: ModelBundleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBundles(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        owners
        viewers
        status
        supplierPayeeId
        orderedFeatureIds
        orderedFeatureData {
          id
          lineItemsDescription
          changeItemsDescription
          featureId
        }
        lineItems {
          id
          kind
          name
          description
          unit
          quantity
          pricePerUnit
          costPerUnit
          data {
            imageUrl
            plantDatabaseId
            changeForLineItemId
            catalogSourceId
            importSourceId
          }
          featureId
          hidden
        }
        changeItems {
          id
          kind
          name
          description
          unit
          quantity
          pricePerUnit
          costPerUnit
          data {
            imageUrl
            plantDatabaseId
            changeForLineItemId
            catalogSourceId
            importSourceId
          }
          featureId
          hidden
        }
        invoiceProposalId
        projectId
        createdAt
        updatedAt
        updatedMeta {
          id
          userCognitoId
          userId
          userName
          userEmail
          userGroups
          device
        }
        deleted
      }
      nextToken
    }
  }
`;
export const getInvoiceProposal = /* GraphQL */ `
  query GetInvoiceProposal($id: ID!) {
    getInvoiceProposal(id: $id) {
      id
      owners
      viewers
      name
      description
      kind
      taxPercent
      housePercent
      status
      paymentStatus
      discountItems {
        id
        kind
        name
        description
        pricePerUnit
        featureId
      }
      creditItem {
        id
        name
        creditAmount
        redistributeAmount
        direction
        creditItemId
      }
      payments {
        id
        label
        name
        description
        receiptUrl
        housePercent
        taxPercent
        houseSubtotal
        priceSubtotal
        processingFeeAmount
        processingFeePercent
        paymentMethod
        discountSubtotal
        preTaxSubtotal
        taxSubtotal
        totalAmount
        amountCharged
        amountReceived
        paidAt
        paymentSucceededAt
        stripeId
        stripeObjectType
        stripeObjectStatus
        stripeObjectCache
        items
        invoiceSentAt
      }
      payouts {
        id
        label
        paymentLabel
        description
        shortDescription
        supplierPayeeId
        taxSubtotal
        priceSubtotal
        totalAmount
        amountCharged
        amountReceived
        paidAt
        stripeId
        stripeObjectType
        stripeObjectCache
      }
      phases {
        id
        kind
        name
        description
        dates
      }
      estimatedStartDate
      estimatedEndDate
      timestamps {
        id
        kind
        description
        createdById
        createdByName
        createdByEmail
        createdByAuthId
        createdAt
      }
      totalsAs
      projectId
      bundleIds
      createdAt
      updatedAt
      updatedMeta {
        id
        userCognitoId
        userId
        userName
        userEmail
        userGroups
        device
      }
      deleted
    }
  }
`;
export const listInvoiceProposals = /* GraphQL */ `
  query ListInvoiceProposals(
    $filter: ModelInvoiceProposalFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listInvoiceProposals(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owners
        viewers
        name
        description
        kind
        taxPercent
        housePercent
        status
        paymentStatus
        discountItems {
          id
          kind
          name
          description
          pricePerUnit
          featureId
        }
        creditItem {
          id
          name
          creditAmount
          redistributeAmount
          direction
          creditItemId
        }
        payments {
          id
          label
          name
          description
          receiptUrl
          housePercent
          taxPercent
          houseSubtotal
          priceSubtotal
          processingFeeAmount
          processingFeePercent
          paymentMethod
          discountSubtotal
          preTaxSubtotal
          taxSubtotal
          totalAmount
          amountCharged
          amountReceived
          paidAt
          paymentSucceededAt
          stripeId
          stripeObjectType
          stripeObjectStatus
          stripeObjectCache
          items
          invoiceSentAt
        }
        payouts {
          id
          label
          paymentLabel
          description
          shortDescription
          supplierPayeeId
          taxSubtotal
          priceSubtotal
          totalAmount
          amountCharged
          amountReceived
          paidAt
          stripeId
          stripeObjectType
          stripeObjectCache
        }
        phases {
          id
          kind
          name
          description
          dates
        }
        estimatedStartDate
        estimatedEndDate
        timestamps {
          id
          kind
          description
          createdById
          createdByName
          createdByEmail
          createdByAuthId
          createdAt
        }
        totalsAs
        projectId
        bundleIds
        createdAt
        updatedAt
        updatedMeta {
          id
          userCognitoId
          userId
          userName
          userEmail
          userGroups
          device
        }
        deleted
      }
      nextToken
    }
  }
`;
export const getCatalog = /* GraphQL */ `
  query GetCatalog($id: ID!) {
    getCatalog(id: $id) {
      id
      owners
      viewers
      items {
        id
        kind
        name
        description
        unit
        quantity
        pricePerUnit
        costPerUnit
        data {
          imageUrl
          plantDatabaseId
          changeForLineItemId
          catalogSourceId
          importSourceId
        }
        featureId
        hidden
      }
      createdAt
      updatedAt
      updatedMeta {
        id
        userCognitoId
        userId
        userName
        userEmail
        userGroups
        device
      }
      deleted
    }
  }
`;
export const listCatalogs = /* GraphQL */ `
  query ListCatalogs(
    $filter: ModelCatalogFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCatalogs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        owners
        viewers
        items {
          id
          kind
          name
          description
          unit
          quantity
          pricePerUnit
          costPerUnit
          data {
            imageUrl
            plantDatabaseId
            changeForLineItemId
            catalogSourceId
            importSourceId
          }
          featureId
          hidden
        }
        createdAt
        updatedAt
        updatedMeta {
          id
          userCognitoId
          userId
          userName
          userEmail
          userGroups
          device
        }
        deleted
      }
      nextToken
    }
  }
`;
