import React from 'react';

const AngleUp = ({ color = 'green-main', size = 30, styleProps, ...rest }) => (
  <svg
    width={size.toString()}
    height={size.toString()}
    viewBox="0 0 75 75"
    className={`fill-${color} ${styleProps}`}
    {...rest}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.1768 57.8232C-0.392268 56.2541 -0.392268 53.7102 1.1768 52.1411L34.6589 18.6589C36.228 17.0899 38.772 17.0899 40.3411 18.6589L73.8232 52.1411C75.3923 53.7102 75.3923 56.2541 73.8232 57.8232C72.2541 59.3923 69.7102 59.3923 68.1411 57.8232L37.5 27.1821L6.85891 57.8232C5.28984 59.3923 2.74587 59.3923 1.1768 57.8232Z"
    />
  </svg>
);

export { AngleUp };
