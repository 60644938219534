const constructCustomer = ({ email, firstname, lastname, phone, owners }) => ({
  firstname,
  lastname,
  email,
  phoneNumber: phone,
  viewers: [],
  owners,
});

export { constructCustomer };
