import React from "react";
import { TextTitle } from "@thrivelot/styles";
import { useSpacing } from "../../../hooks";

const Title = ({ section }) => {
  const marginLeft = useSpacing();
  return <TextTitle style={{ marginLeft }}>{section}</TextTitle>;
};

export { Title };
