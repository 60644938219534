import styled from "styled-components";

const DivCalendarAgenda = styled.div`
  transition: opacity 0.5s ease-out, box-shadow 0.5s ease-out, color 1s ease-in-out, background-color 1s ease-in-out;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export default DivCalendarAgenda;
