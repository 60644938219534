import React from 'react';
import PropTypes from 'prop-types';
import * as icons from './icons';
import { getTailwindColorStrings, getIconNameStrings } from './utils';

export const Selector = ({
  icon,
  color = 'green-main',
  disabledColor = 'green-dark',
  size = 'md',
  selected,
  label,
  onClick = () => null,
  classAppend,
  children,
  disabled,
}) => {
  const Icon = icon ? icons[icon] : <div></div>;

  const sizeClass = size === 'sm' ? 'px-2 py-1 text-sm' : 'px-4 py-2';

  const safeColor = disabled ? disabledColor : color;

  return (
    <div
      className={`w-full flex ${sizeClass} items-center justify-center rounded-main shadow-lg ${
        !disabled ? 'hover:cursor-pointer' : ''
      } ${
        selected
          ? `text-white-light bg-${safeColor}`
          : `text-${safeColor} border-${safeColor} border-2 bg-white-dark hover:bg-white-light`
      } ${classAppend}`}
      onClick={() => !disabled && onClick()}
    >
      {icon && <Icon color={selected ? 'white-light' : safeColor} size={20} />}
      {(label || children) && (
        <div className="font-bold ml-2 text-center">{label || children}</div>
      )}
    </div>
  );
};

Selector.propTypes = {
  /**
   * The color of the selector
   */
  color: PropTypes.oneOf(getTailwindColorStrings()),

  /**
   * The color of the selector when disabled
   */
  disabledColor: PropTypes.oneOf(getTailwindColorStrings()),

  /**
   * Class string to append to outermost element class
   */
  classAppend: PropTypes.string,

  /**
   * Show an icon in the selector
   */
  icon: PropTypes.oneOf([...getIconNameStrings(), '', undefined]),

  /**
   * If the option is selected or not
   */
  selected: PropTypes.bool,

  /**
   * Text of the selector
   */
  label: PropTypes.string,

  /**
   * Disable the click behavior but doesn't change the color that has to be done in the parent
   */
  disabled: PropTypes.bool,

  /**
   * Function to run when selector is clicked
   */
  onClick: PropTypes.func,
};

Selector.defaultProps = {
  icon: '',
  color: 'green-main',
  classAppend: '',
  selected: false,
  label: '',
  onClick: () => null,
};
