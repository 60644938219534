import React from 'react';
import { useProposal } from '@thrivelot/common';
import { useSpacing } from '../../../hooks';
import { FilesContainer } from './FilesContainer';
import { File } from './File';

export const Files = () => {
  // hooks
  const { projectActions } = useProposal();
  const spacing = useSpacing();
  // actions
  const files = projectActions.get('files');
  // constants
  const negativeMargin = `calc(-${spacing} - 1px)`;
  // return
  return (
    <FilesContainer
      style={{
        marginTop: '-1px',
        marginRight: negativeMargin,
        marginBottom: negativeMargin,
        marginLeft: negativeMargin,
      }}
    >
      {files.map((file) => (
        <File key={file.id} file={file} />
      ))}
    </FilesContainer>
  );
};
