import React, { useEffect } from "react";
import { useMarker } from "@thrivelot/hooks";
import { Button, Card } from "@thrivelot/stories";

const PageError = () => {
  const { handleMarker } = useMarker();

  // The bg needs to be set on the document.body otherwise
  // you can see the wrong color when scrolling up and down
  useEffect(() => {
    document.body.classList.add("!bg-mulch-light");

    return () => {
      document.body.classList.remove("!bg-mulch-light");
    };
  }, []);

  return (
    <div className="flex flex-col h-screen bg-mulch-light items-center justify-center">
      <Card
        header="Oops! Something went wrong!"
        footer={
          <Button color="blue-main" kind="outline" block onClick={() => handleMarker()}>
            Report Issue
          </Button>
        }
        classAppend="max-w-md"
      >
        <div className="text-brown-dark">
          <div className="mb-4">To help us quickly fix this issue, please click “Report Issue” below.</div>
          <div className="mb-4">
            This will provide us with context around your issue and help us understand how we can resolve it.
          </div>
          <div>After submitting the report, please try refreshing the page.</div>
        </div>
      </Card>
    </div>
  );
};

export { PageError };
