import { isObject } from '@thrivelot/utils';

const removeTagsFromItemInObj = (item: any, id: string) => {
  if (!Array.isArray(item) && isObject(item)) {
    if (item.orderedChildTagIds)
      item.orderedChildTagIds = item.orderedChildTagIds.filter(
        ({ tagId }) => tagId !== id
      );
    if (item.tagIds)
      item.tagIds = item.tagIds.filter(({ tagId }) => tagId !== id);
  }

  return item;
};

export const removeTagsFor = (obj: any, id: string): any => {
  let v: any;
  const bObject = Array.isArray(obj) ? [] : {};

  Object.keys(obj).forEach((k) => {
    v = removeTagsFromItemInObj(obj[k], id);
    bObject[k] = isObject(v) ? removeTagsFor(v, id) : v;
  });

  return bObject;
};
