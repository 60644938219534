import React from "react";
import { useHover } from "react-use";
import { IoMdArrowDropup } from "react-icons/io";
import getReactIconStyle from "./helpers";

const ArrowDropupIcon = ({ color, size, style = {}, hoverable, ...rest }) => {
  const Icon = (hovering) => {
    const iconStyle = {
      ...getReactIconStyle({
        width: size,
        height: size,
        hoverable,
        hovering,
        ...rest,
      }),
      ...style,
    };

    return <IoMdArrowDropup color={color} size={size} style={iconStyle} {...rest} />;
  };

  const [HoverableIcon] = useHover(Icon);
  return HoverableIcon;
};

export default ArrowDropupIcon;
