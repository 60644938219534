import { Card, CardLoader, Edit } from '@thrivelot/stories';
import React from 'react';
import { useModel } from '@thrivelot/hooks';
import { formatPhoneNumber } from 'react-phone-number-input';
import { Link } from 'react-router-dom';

const InfoItem = ({ label, value, empty }) => (
  <div className="">
    <div className="text-brown-dark text-sm">{label}</div>
    {empty ? (
      <div className="text-brown-dark italic">None...</div>
    ) : (
      <div className="text-green-dark font-bold">{value}</div>
    )}
  </div>
);

const CardProjectCustomerInfo = ({ projectId, editable }) => {
  const { model: project, loaded } = useModel({
    modelName: 'Project',
    id: projectId,
  });

  if (!loaded) return <CardLoader showText />;

  const { id, firstname, lastname, email, phoneNumber } =
    project.customer || {};

  return (
    <Card
      header="Customer Info"
      headerActions={
        editable && (
          <Link to={`/customers/${id}`}>
            <Edit
              color="yellow-dark"
              styleProps="hover:cursor-pointer"
              size="20"
            />
          </Link>
        )
      }
      classAppend="mb-4"
    >
      <InfoItem
        label="Name"
        value={[firstname, lastname].join(' ')}
        empty={!(firstname || lastname)}
      />
      <div className="my-4">
        <InfoItem label="Email" value={email} empty={!email} />
      </div>
      <InfoItem
        label="Phone"
        value={formatPhoneNumber(phoneNumber)}
        empty={!phoneNumber}
      />
    </Card>
  );
};

export { CardProjectCustomerInfo };
