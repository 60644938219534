const calculateDesignCost = (projectActions, type) => {
  const paymentBlock = projectActions.get(`costPaymentSchedule[type:${type}]`);
  if (!paymentBlock || !paymentBlock.payments || paymentBlock.payments.length === 0)
    return { cost: 0, markup: 0, memo: "" };
  const { memo } = paymentBlock;
  const [payment] = paymentBlock.payments;
  const { markup, calculation } = payment;
  const { amount } = calculation[0];
  const multiplier = markup + 1;
  const supplierAmount = amount + 25000;
  const step = supplierAmount * multiplier;
  const siteVisitCost =
    projectActions.get(`costPaymentSchedule[type:SITE_VISIT].payments[0].calculation[0].amount`) *
    (1 + projectActions.get(`costPaymentSchedule[type:SITE_VISIT].payments[0].markup`));
  const designCost = step - siteVisitCost;
  const designMarkup = designCost - supplierAmount;
  return {
    cost: step,
    totalCost: designCost,
    markup: designMarkup,
    memo,
    paymentBlock,
    payment,
  };
};

export { calculateDesignCost };
