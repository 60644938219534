import { useQueryParam } from '@thrivelot/hooks';
import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { LayoutAuthentication } from '..';
import {
  PageChangePassword,
  PageForgotPassword,
  PageLogin,
  PageResetPassword,
} from '../pages';

const RoutingAuthentication = () => {
  const { constructParamWithKeyAndValue } = useQueryParam('returnTo');

  let path = '/auth/login';

  // if window location path begins with /auth
  if (!window.location.pathname.startsWith('/auth')) {
    const returnTo = `${window.location.pathname}${window.location.search}`;
    console.log('blah fresh returnTo', returnTo);
    // set path to include url save returnTo
    path = `${path}?${constructParamWithKeyAndValue(returnTo)}`;
  }

  return (
    <Routes>
      <Route path="/auth" element={<LayoutAuthentication />}>
        <Route exact path="" element={<Navigate to="login" replace />} />
        <Route path="login" element={<PageLogin />} />
        <Route path="change-password" element={<PageChangePassword />} />
        <Route path="forgot-password" element={<PageForgotPassword />} />
        <Route path="reset-password" element={<PageResetPassword />} />
      </Route>

      {/* If you're NOT logged in on any path redirect to /auth */}
      <Route path="*" element={<Navigate to={path} />} />
    </Routes>
  );
};

export { RoutingAuthentication };
