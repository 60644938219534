import React, { forwardRef } from "react";
import { useIsMobile } from "@thrivelot/hooks";
import { CalendarIcon, DateEndIcon, DateStartIcon } from "@thrivelot/styles";

const IconMap = {
  default: CalendarIcon,
  end: DateEndIcon,
  start: DateStartIcon,
};

const DateIcon = forwardRef((props, ref) => {
  const theme = window.$theme;

  const isMobile = useIsMobile();

  const customProps = {
    hoverable: true,
    color: theme.blue,
    size: isMobile ? 15 : 20,
  };

  const getIconKey = () => {
    if (props.isStart) return "start";
    if (props.isEnd) return "end";
    return "default";
  };

  const iconKey = getIconKey();
  const Icon = IconMap[iconKey];

  return <Icon ref={ref} {...customProps} {...props} />;
});

export { DateIcon };
