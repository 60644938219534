import React from 'react';

const Download = ({
  color = 'green-main',
  size = 30,
  styleProps = '',
  ...rest
}) => (
  <svg
    width={size.toString()}
    height={size.toString()}
    viewBox="0 0 75 75"
    className={`fill-${color} ${styleProps}`}
    {...rest}
  >
    <path d="M61.7184 75H13.2816C5.96203 75 0 69.038 0 61.7184V13.2816C0 5.96203 5.96203 0 13.2816 0H24.5032C26.3354 0 27.8259 1.49051 27.8259 3.32278C27.8259 5.15506 26.3449 6.64557 24.5032 6.64557H13.2816C9.62658 6.64557 6.64557 9.62658 6.64557 13.2816V61.7184C6.64557 65.3734 9.62658 68.3544 13.2816 68.3544H61.7184C65.3734 68.3544 68.3544 65.3734 68.3544 61.7184V13.2816C68.3544 9.62658 65.3734 6.64557 61.7184 6.64557H50.4968C48.6646 6.64557 47.174 5.15506 47.174 3.32278C47.174 1.49051 48.6551 0 50.4968 0H61.7184C69.038 0 75 5.96203 75 13.2816V61.7184C75 69.038 69.038 75 61.7184 75Z" />
    <path d="M58.9937 40.6424L39.845 59.7817C39.4557 60.1804 38.9906 60.4557 38.4874 60.6076C38.383 60.6456 38.2785 60.6741 38.1646 60.6931C37.9463 60.7406 37.7184 60.7595 37.5 60.7595C37.2722 60.7595 37.0538 60.7406 36.8355 60.6931C36.7216 60.6741 36.6171 60.6456 36.5127 60.6076C36.019 60.4557 35.5538 60.1804 35.1551 59.7817C35.0032 59.6298 34.8703 59.4684 34.7469 59.288C34.8608 59.4684 34.9937 59.6298 35.1456 59.7817L16.0064 40.6424C14.7152 39.3513 14.7152 37.2437 16.0064 35.9431C17.307 34.6519 19.4051 34.6519 20.7057 35.9431L34.1773 49.4146V6.17093C34.1773 4.33865 35.6678 2.84814 37.5 2.84814C39.3323 2.84814 40.8228 4.33865 40.8228 6.17093V49.4051L54.2943 35.9431C55.5855 34.6519 57.6931 34.6519 58.9937 35.9431C60.2849 37.2437 60.2849 39.3513 58.9937 40.6424Z" />
  </svg>
);

export { Download };
