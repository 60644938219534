import React, { useCallback } from "react";
import { LayoutModal } from "../..";
import { useModel } from "@thrivelot/hooks";
import { ImgBox } from "@thrivelot/styles";
import { projectDesires as availableDesires } from "@thrivelot/config";

import { Checkmark } from "@thrivelot/stories";

const ModalEditDesire = ({ onClose, projectId }) => {
  const { model: project, updateModel, loaded, actions } = useModel({ modelName: "Project", id: projectId });

  const { desire } = project?.details || {};

  const handleSelect = useCallback(
    ({ name }) => {
      updateModel(actions.set("details.desire", name).result);
    },
    [actions, updateModel]
  );

  return (
    <LayoutModal
      onClose={onClose}
      loading={!loaded}
      header={<div className="text-lg text-brown-dark italic">Whats you're desired goal for your property?</div>}
    >
      <div className="grid grid-cols-2 gap-4">
        {Object.keys(availableDesires).map((key) => {
          const { imageUrl, name } = availableDesires[key];
          const isSelected = name === desire;

          return (
            <div
              key={key}
              onClick={() => handleSelect({ key, ...availableDesires[key] })}
              className="rounded-main shadow flex flex-col hover:cursor-pointer"
            >
              <div className="relative">
                <ImgBox src={{ url: imageUrl }} />
                {isSelected && (
                  <div className="absolute right-1 top-2 bg-white-light rounded-full">
                    <Checkmark color="green-main" size={40} />
                  </div>
                )}
              </div>
              <div className="text-brown-dark p-2 font-bold">{name}</div>
            </div>
          );
        })}
      </div>
    </LayoutModal>
  );
};

export { ModalEditDesire };
