import React from "react";
import { DivContentRow, DivLineHorizontal } from "@thrivelot/styles";
import { styleConstants } from "../../../helpers";
import { useSpacing } from "../../../hooks";
import { Icon } from "./Icon";
import { Title } from "./Title";

// functional component
const Header = ({ section }) => {
  const spacing = useSpacing();
  const negativeMargin = `-${spacing}`;
  return (
    <>
      <DivContentRow style={{ ...styleConstants.headerRowStyle, marginBottom: spacing }}>
        <Icon section={section} />
        <Title section={section} />
      </DivContentRow>
      <DivLineHorizontal
        style={{
          marginLeft: negativeMargin,
          marginRight: negativeMargin,
          width: `calc(100% + ${spacing} + ${spacing})`,
        }}
      />
    </>
  );
};

export { Header };
