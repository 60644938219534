import React from 'react';

export const PlantSection = ({ header, action, details, children }) => (
  <div className="mb-6">
    <div className="flex items-center justify-between mb-4">
      <div className="flex items-center mr-4">
        {header && (
          <div className="font-bold text-lg text-mulch-light">{header}</div>
        )}
        {action && <div className="ml-3">{action}</div>}
      </div>
      {details && <div className="italic text-tan-dark">{details}</div>}
    </div>
    {children}
  </div>
);
