import React from 'react';

const Help = ({
  color = 'green-main',
  size = 30,
  styleProps = '',
  ...rest
}) => (
  <svg
    width={size.toString()}
    height={size.toString()}
    viewBox="0 0 75 75"
    className={`fill-${color} ${styleProps}`}
    {...rest}
  >
    <path d="M37.5 75C16.8228 75 0 58.1772 0 37.5C0 16.8228 16.8228 0 37.5 0C58.1772 0 75 16.8228 75 37.5C75 58.1772 58.1772 75 37.5 75ZM37.5 6.64557C20.4873 6.64557 6.64557 20.4873 6.64557 37.5C6.64557 54.5127 20.4873 68.3544 37.5 68.3544C54.5127 68.3544 68.3544 54.5127 68.3544 37.5C68.3544 20.4873 54.5127 6.64557 37.5 6.64557Z" />
    <path d="M37.4998 50.5824C35.1739 50.5824 33.2846 48.6931 33.2846 46.3672V37.0539C33.2846 34.7279 35.1739 32.8387 37.4998 32.8387C40.3005 32.8387 42.5884 30.5602 42.5884 27.7501C42.5884 24.94 40.3099 22.6615 37.4998 22.6615C34.6897 22.6615 32.4112 24.94 32.4112 27.7501C32.4112 30.076 30.522 31.9653 28.196 31.9653C25.8701 31.9653 23.9808 30.076 23.9808 27.7501C23.9808 20.2976 30.0473 14.2406 37.4998 14.2406C44.9524 14.2406 51.0188 20.3071 51.0188 27.7596C51.0188 33.6646 47.2498 38.8007 41.715 40.6045V46.3767C41.715 48.6931 39.8258 50.5824 37.4998 50.5824ZM37.4998 19.8229C41.8764 19.8229 45.4365 23.383 45.4365 27.7596C45.4365 32.1362 41.8764 35.6963 37.4998 35.6963C36.7498 35.6963 36.1327 36.3134 36.1327 37.0634V46.3767C36.1327 47.1267 36.7498 47.7438 37.4998 47.7438C38.2498 47.7438 38.8669 47.1267 38.8669 46.3767V39.5222C38.8669 38.8577 39.3226 38.2881 39.9587 38.1362C44.791 36.9874 48.1612 32.7248 48.1612 27.7596C48.1707 21.8735 43.3859 17.0887 37.4998 17.0887C31.6137 17.0887 26.8289 21.8735 26.8289 27.7596C26.8289 28.5096 27.446 29.1267 28.196 29.1267C28.946 29.1267 29.5631 28.5096 29.5631 27.7596C29.5631 23.3735 33.1232 19.8229 37.4998 19.8229Z" />
    <path d="M37.5003 60.7595C35.1744 60.7595 33.2852 58.8702 33.2852 56.5443C33.2852 54.2183 35.1744 52.3291 37.5003 52.3291C39.8263 52.3291 41.7155 54.2183 41.7155 56.5443C41.7155 58.8702 39.8263 60.7595 37.5003 60.7595ZM37.5003 55.1772C36.7503 55.1772 36.1333 55.7943 36.1333 56.5443C36.1333 57.2943 36.7503 57.9114 37.5003 57.9114C38.2503 57.9114 38.8674 57.2943 38.8674 56.5443C38.8674 55.7848 38.2503 55.1772 37.5003 55.1772Z" />
  </svg>
);

export { Help };
