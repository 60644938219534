import React, { Fragment, useMemo } from "react";
import { dateFns } from "@thrivelot/date";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useToggle } from "react-use";
import { duplicateObject } from "@thrivelot/utils";
import { useIsMobile, useModel } from "@thrivelot/hooks";
import colors from "@thrivelot/tailwind/colors";
import {
  constructKeyMap,
  getPhase,
  constructPhaseCalculations,
  calculatePhaseInnerMarkup,
  calculatePhaseInnerTotal,
  calculatePhasePayment,
} from "@thrivelot/common";
import { transformCurrencyToString } from "@thrivelot/utils";
import {
  DivColumn,
  DivContentRow,
  DivLineVertical,
  DivInset,
  DivListRow,
  DivLineHorizontal,
  TextBody,
  TextBodyBold,
  TextHeader,
  TextSmallHeader,
  Textarea,
} from "@thrivelot/styles";
import { DropIconMap } from "../../shared";
import { styleForListItemCostContainer } from "../helpers";
import { PhaseCalculation } from "./PhaseCalculation";
import { YieldToThrive } from "./YieldToThrive";
import { DateIcon } from "./DateIcon";

const mobileDateContainer = {
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  flexDirection: "row",
  minWidth: "90px",
  maxWidth: "90px",
  minHeight: "35px",
  maxHeight: "35px",
  padding: "0px 8px",
  marginRight: "12px",
};

const MobilePayment = ({
  theme,
  isOpen,
  toggleIsOpen,
  DropIcon,
  name,
  memo,
  startDate,
  endDate,
  totalCost,
  updateMemo,
  updateInnerPayment,
  keyMap,
  payments,
  calculations,
  keyMap1,
  model,
  getDescription,
  getFeatureName,
}) => (
  <DivColumn>
    <DivListRow
      style={{
        marginBottom: "5px",
      }}
    >
      <TextHeader color={theme.textTitle} style={{ width: "100%", marginRight: "12px", marginTop: "6px" }}>
        {name}
      </TextHeader>
      <TextHeader color={theme.green} style={{ marginRight: "12px", marginTop: "6px" }}>
        {transformCurrencyToString(totalCost)}
      </TextHeader>
      <DropIcon hoverable color={colors.yellow.dark} size={35} onClick={toggleIsOpen} />
    </DivListRow>
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        marginBottom: "12px",
      }}
    >
      <DivInset style={mobileDateContainer}>
        <DatePicker
          popperPlacement="bottom-start"
          showPopperArrow={false}
          selected={dateFns(startDate).toDate()}
          onChange={(date) => updateInnerPayment({ date: date.toISOString() })}
          customInput={<DateIcon isStart />}
        />
        <TextBodyBold style={{ marginLeft: "8px" }}>{dateFns(startDate).format("M/D/YY")}</TextBodyBold>
      </DivInset>
      <DivInset style={mobileDateContainer}>
        <DatePicker
          popperPlacement="bottom-start"
          showPopperArrow={false}
          selected={dateFns(endDate).toDate()}
          onChange={(date) => updateInnerPayment({ date: date.toISOString() }, 1)}
          customInput={<DateIcon isEnd />}
        />
        <TextBodyBold style={{ marginLeft: "8px" }}>{dateFns(endDate).format("M/D/YY")}</TextBodyBold>
      </DivInset>
    </div>
    {isOpen && (
      <Textarea
        placeholder="Activities & deliverables"
        value={memo || ""}
        onChange={(e) => updateMemo(e.target.value)}
        style={{ minHeight: "60px", maxHeight: "60px" }}
      />
    )}
    {isOpen &&
      payments.map((pmt, index) => {
        const calculation = calculations[index];
        const { date, markup } = pmt;
        const title = dateFns(date).format("MMM D, YYYY");
        const subtotalCost = Math.ceil(calculatePhaseInnerTotal(pmt, calculation, model) / 100) * 100;
        const dollarMarkup = Math.ceil(calculatePhaseInnerMarkup(pmt, calculation, model) / 100) * 100;

        return (
          <Fragment key={keyMap1[`${index}`]}>
            <DivContentRow
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <DivColumn style={{ marginLeft: "8px", marginRight: "12px" }}>
                <TextSmallHeader
                  color={theme.textTitle}
                  style={{
                    marginBottom: "-2px",
                    fontSize: "12px",
                  }}
                >
                  {title}
                </TextSmallHeader>
                <TextBody color={theme.textTitle}>({getDescription(index)})</TextBody>
              </DivColumn>
              <div
                style={{
                  ...styleForListItemCostContainer,
                  marginRight: undefined,
                  backgroundColor: "transparent",
                  minWidth: undefined,
                  maxWidth: undefined,
                }}
              >
                <TextSmallHeader color={theme.textTitle}>{transformCurrencyToString(subtotalCost)}</TextSmallHeader>
              </div>
            </DivContentRow>
            <DivLineHorizontal />
            {calculations[index].map((calc, i) => {
              const { tagId } = calc;
              const featureName = getFeatureName(tagId);
              return <PhaseCalculation key={keyMap[`${i}`]} featureName={featureName} calculation={calc} />;
            })}
            <YieldToThrive
              isPhase
              isLast={index === payments.length - 1}
              percentageMarkup={markup}
              dollarMarkup={transformCurrencyToString(dollarMarkup)}
              updatePayment={(updated) => updateInnerPayment(updated, index)}
            />
          </Fragment>
        );
      })}
    <DivLineHorizontal isDark />
  </DivColumn>
);

const PhasePayment = ({ paymentId, projectId }) => {
  const theme = window.$theme;

  const [isOpen, toggleIsOpen] = useToggle(false);

  const isMobile = useIsMobile();

  const { model, updateModel, actions } = useModel({ modelName: "Project", id: projectId });
  const { costPaymentSchedule } = model;

  const keyMap = useMemo(constructKeyMap, []);
  const keyMap1 = useMemo(constructKeyMap, []);

  const getPayment = () => {
    if (!costPaymentSchedule) return undefined;

    const payment = costPaymentSchedule.find((p) => p.id === paymentId);
    return payment;
  };

  const payment = getPayment();
  if (!payment) return null;

  const { tagIds } = payment;

  const getDescription = (index) => {
    if (index === 0) return "100% materials, 50% labor";
    return "50% labor";
  };

  const getFeatureName = (tag) => {
    const { tagId } = tag;
    const { features } = model;
    const feature = features.find((ftr) => ftr.id === tagId);

    if (!feature) return "";
    return feature.name;
  };

  const phase = getPhase(tagIds, model);
  if (!phase) return null;

  const updateMemo = (value) => {
    updateModel(actions.set(`costPaymentSchedule[id:${paymentId}].memo`, value).result);
  };
  const updateInnerPayment = (updated, paymentIndex = 0) => {
    const newCostPaymentSchedule = duplicateObject(costPaymentSchedule);
    const index = newCostPaymentSchedule.findIndex((p) => p.id === paymentId);

    if (index === -1) return;

    const thisPayment = newCostPaymentSchedule[index].payments[paymentIndex];

    if (!thisPayment) return;

    Object.keys(updated).forEach((key) => {
      thisPayment[key] = updated[key];
    });

    newCostPaymentSchedule[index].payments[paymentIndex] = thisPayment;

    updateModel({ costPaymentSchedule: newCostPaymentSchedule });
  };

  const phaseDateContainerStyle = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
    minWidth: "110px",
    maxWidth: "110px",
    padding: "0px 8px",
    marginRight: "12px",
  };
  const dateFormat = isMobile ? "M/D/YY" : "MMM D, 'YY";

  const { name } = phase;
  const { payments, memo } = payment;

  const { date: startDate } = payments[0];
  const { date: endDate } = payments[1];

  const calculations = constructPhaseCalculations(phase.id, model);

  const totalCost = Math.ceil(calculatePhasePayment(payment, calculations, model) / 100) * 100;

  const DropIcon = DropIconMap[isOpen ? "up" : "down"];

  if (isMobile)
    return (
      <MobilePayment
        theme={theme}
        isOpen={isOpen}
        toggleIsOpen={toggleIsOpen}
        DropIcon={DropIcon}
        name={name}
        memo={memo}
        startDate={startDate}
        endDate={endDate}
        totalCost={totalCost}
        updateMemo={updateMemo}
        updateInnerPayment={updateInnerPayment}
        keyMap={keyMap}
        payments={payments}
        calculations={calculations}
        keyMap1={keyMap1}
        model={model}
        getDescription={getDescription}
        getFeatureName={getFeatureName}
      />
    );

  return (
    <DivColumn>
      <DivListRow
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          marginBottom: isOpen ? "5px" : "20px",
        }}
      >
        <div style={phaseDateContainerStyle}>
          <DatePicker
            popperPlacement="bottom-start"
            showPopperArrow={false}
            selected={dateFns(startDate).toDate()}
            onChange={(date) => updateInnerPayment({ date: date.toISOString() })}
            customInput={<DateIcon isStart />}
          />
          <TextBodyBold style={{ fontSize: "12px", minWidth: "fit-content" }}>
            {dateFns(startDate).format(dateFormat)}
          </TextBodyBold>
        </div>
        <DivLineVertical isDark style={{ minHeight: "30px", maxHeight: "30px", marginRight: "12px" }} />
        <div style={phaseDateContainerStyle}>
          <DatePicker
            popperPlacement="bottom-start"
            showPopperArrow={false}
            selected={dateFns(endDate).toDate()}
            onChange={(date) => updateInnerPayment({ date: date.toISOString() }, 1)}
            customInput={<DateIcon isEnd />}
          />
          <TextBodyBold style={{ fontSize: "12px", minWidth: "fit-content" }}>
            {dateFns(endDate).format(dateFormat)}
          </TextBodyBold>
        </div>
        <DivLineVertical isDark style={{ minHeight: "30px", maxHeight: "30px", marginRight: "12px" }} />
        <TextHeader color={theme.textTitle} style={{ width: "100%", marginRight: "12px" }}>
          {name}
        </TextHeader>
        <div
          style={{
            ...styleForListItemCostContainer,
            marginRight: "20px",
            backgroundColor: "transparent",
          }}
        >
          <TextHeader color={theme.green}>{transformCurrencyToString(totalCost)}</TextHeader>
        </div>
        <DropIcon hoverable color={colors.yellow.dark} size={35} onClick={toggleIsOpen} />
      </DivListRow>
      {isOpen && (
        <Textarea
          placeholder="Activities & deliverables"
          value={memo || ""}
          onChange={(e) => updateMemo(e.target.value)}
          style={{
            marginTop: "8px",
            marginLeft: "262px",
            marginRight: "55px",
            minHeight: "90px",
            maxHeight: "90px",
          }}
        />
      )}
      {isOpen &&
        payments.map((pmt, index) => {
          const calculation = calculations[index];
          const { date, markup } = pmt;
          const title = dateFns(date).format("MMM D, YYYY");
          const subtotalCost = Math.ceil(calculatePhaseInnerTotal(pmt, calculation, model) / 100) * 100;
          const dollarMarkup = Math.ceil(calculatePhaseInnerMarkup(pmt, calculation, model) / 100) * 100;

          return (
            <Fragment key={keyMap1[`${index}`]}>
              <DivContentRow
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <div style={{ marginLeft: "262px", marginRight: "12px" }}>
                  <TextSmallHeader color={theme.textTitle} style={{ marginBottom: "-4px" }}>
                    {title}
                  </TextSmallHeader>
                  <TextBody color={theme.textTitle} style={{ marginLeft: "8px" }}>
                    ({getDescription(index)})
                  </TextBody>
                </div>
                <div
                  style={{
                    ...styleForListItemCostContainer,
                    marginRight: "55px",
                    backgroundColor: "transparent",
                  }}
                >
                  <TextSmallHeader color={theme.textTitle}>{transformCurrencyToString(subtotalCost)}</TextSmallHeader>
                </div>
              </DivContentRow>
              <DivLineHorizontal />
              {calculations[index].map((calc, i) => {
                const { tagId } = calc;
                const featureName = getFeatureName(tagId);
                return <PhaseCalculation key={keyMap[`${i}`]} featureName={featureName} calculation={calc} />;
              })}
              <YieldToThrive
                isPhase
                isLast={index === payments.length - 1}
                percentageMarkup={markup}
                dollarMarkup={transformCurrencyToString(dollarMarkup)}
                updatePayment={(updated) => updateInnerPayment(updated, index)}
              />
            </Fragment>
          );
        })}
      <DivLineHorizontal isDark />
    </DivColumn>
  );
};

export { PhasePayment };
