import { add, get, remove, set } from "@thrivelot/actions";
import { constructUuid } from "@thrivelot/utils";
import { constructAddress } from "./constructAddress";
import { constructLinks } from "./constructLinks";
import { constructProfilePic } from "./constructProfilePic";
import { constructQualifications } from "./constructQualifications";
import { constructSelf } from "./constructSelf";
import { constructTermsOfUse } from "./constructTermsOfUse";
import { getAddressText } from "./getAddressText";
import { getSupplierName } from "./getSupplierName";
import { getSupplierSubtitle } from "./getSupplierSubtitle";

function actions(model) {
  return {
    result: model,
    add(path, value, index = -1) {
      this.result = add(this.result, path, value, index);
      return this;
    },
    remove(path, value) {
      this.result = remove(this.result, path, value);
      return this;
    },
    set(path, value) {
      this.result = set(this.result, path, value);
      return this;
    },
    get: (path, defaultValue) => get(model, path),
    constructAddress: (props) => constructAddress(props),
    constructLinks: (props) => constructLinks(props),
    constructProfilePic: (props) => constructProfilePic(props),
    constructQualifications: (props) => constructQualifications(props),
    constructSelf: (props) => constructSelf(props),
    constructTermsOfUse: (props) => constructTermsOfUse(props),
    constructUuid: () => constructUuid(),
    getAddressText: () => getAddressText(model),
    getSupplierName: () => getSupplierName(model),
    getSupplierSubtitle: () => getSupplierSubtitle(model),
  };
}

export { actions };
