import React from "react";
import { Cancel } from "@thrivelot/stories";
import { Card } from "@thrivelot/stories";
import { CardLoader } from "@thrivelot/stories";
import Input from "./ProjectTypeInput";
import { useModel } from "@thrivelot/hooks";

const CardSupplierProjectTypes = ({ supplierId }) => {
  const {
    model: supplier,
    updateModel: updateSupplier,
    actions,
    loaded,
  } = useModel({ modelName: "Supplier", id: supplierId });

  const addProjectType = (type) => {
    updateSupplier(actions.add("projectTypes", type).result);
  };

  const deleteProjectType = (type) => {
    updateSupplier(actions.remove(`projectTypes[value:${type}]`).result);
  };

  if (!loaded) return <CardLoader />;

  return (
    <Card header="Project Types" classAppend="mb-4">
      <Input addProjectType={addProjectType} />
      {supplier.projectTypes && (
        <div className="flex flex-wrap">
          {supplier.projectTypes &&
            supplier.projectTypes.map((projectType) => (
              <div
                key={projectType}
                className={`flex my-1 break-inside-avoid items-center gap-2 border-2 px-2 font-bold rounded-full border-brown-dark text-brown-dark`}
              >
                {projectType}
                <Cancel
                  styleProps="hover:cursor-pointer"
                  size={15}
                  color="red-main"
                  onClick={() => deleteProjectType(projectType)}
                />
              </div>
            ))}
        </div>
      )}
    </Card>
  );
};

export { CardSupplierProjectTypes };
