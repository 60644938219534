import React from 'react';

const List = ({
  color = 'green-main',
  size = 30,
  styleProps = '',
  ...rest
}) => (
  <svg
    width={size.toString()}
    height={size.toString()}
    viewBox="0 0 75 75"
    className={`fill-${color} ${styleProps}`}
    {...rest}
  >
    <g clipPath="url(#clip0_502_395)">
      <path d="M26.1077 -0.000165029L65.6013 -0.000161577C69.7785 -0.000161211 73.1013 3.32262 73.1013 7.49984L73.1013 15.1897C73.1013 19.272 69.7785 22.6897 65.6013 22.6897L26.1076 22.6897C22.0254 22.6897 18.6076 19.3669 18.6076 15.1897L18.6076 7.49983C18.7026 3.32262 22.0254 -0.000165386 26.1077 -0.000165029ZM65.6013 16.0441C66.076 16.0441 66.4557 15.6644 66.4557 15.1897L66.4558 7.49983C66.4558 7.02515 66.076 6.6454 65.6013 6.6454L26.1077 6.6454C25.633 6.6454 25.2532 7.02515 25.2532 7.49983L25.2532 15.1897C25.2532 15.6644 25.633 16.0441 26.1077 16.0441L65.6013 16.0441Z" />
      <path d="M6.0759 5.22163C9.39869 5.22163 12.1519 7.9748 12.1519 11.2976C12.1519 14.6204 9.39869 17.3735 6.0759 17.3735C2.75312 17.3735 -4.91959e-05 14.6204 -4.89054e-05 11.2976C-4.86149e-05 7.9748 2.75312 5.22163 6.0759 5.22163ZM6.0759 14.5254C7.8797 14.5254 9.30375 13.1014 9.30375 11.2976C9.30375 9.49379 7.8797 8.06974 6.0759 8.06974C4.27211 8.06974 2.84805 9.49379 2.84805 11.2976C2.84805 13.1014 4.27211 14.5254 6.0759 14.5254Z" />
      <path d="M6.0759 57.6267C9.39869 57.6267 12.1519 60.3798 12.1519 63.7026C12.1519 67.0254 9.39869 69.7786 6.0759 69.7786C2.75312 69.7786 -4.91959e-05 67.0254 -4.89054e-05 63.7026C-4.86149e-05 60.3798 2.75312 57.6267 6.0759 57.6267ZM6.0759 66.9305C7.8797 66.9305 9.30375 65.5064 9.30375 63.7026C9.30375 61.8988 7.8797 60.4748 6.0759 60.4748C4.27211 60.4748 2.84805 61.8988 2.84805 63.7026C2.84805 65.5064 4.27211 66.9305 6.0759 66.9305Z" />
      <path d="M26.0127 26.2025L65.5063 26.2025C69.5886 26.2025 73.0063 29.5252 73.0063 33.7025L73.0063 41.3923C73.0063 45.4746 69.6836 48.8923 65.5063 48.8923L26.0127 48.8923C21.9304 48.8923 18.5127 45.5695 18.5127 41.3923L18.5127 33.7025C18.6076 29.5252 21.9304 26.2025 26.0127 26.2025ZM65.5063 42.1518C65.981 42.1518 66.3608 41.7721 66.3608 41.2974L66.3608 33.6075C66.3608 33.1328 65.981 32.7531 65.5063 32.7531L26.0127 32.7531C25.538 32.7531 25.1582 33.1328 25.1582 33.6075L25.1582 41.2974C25.1582 41.7721 25.538 42.1518 26.0127 42.1518L65.5063 42.1518Z" />
      <path d="M6.0759 31.424C9.39869 31.424 12.1519 34.1772 12.1519 37.5C12.1519 40.8228 9.39869 43.5759 6.0759 43.5759C2.75312 43.5759 -4.91959e-05 40.8228 -4.89054e-05 37.5C-4.86149e-05 34.1772 2.75312 31.424 6.0759 31.424ZM6.0759 40.7278C7.8797 40.7278 9.30375 39.3038 9.30375 37.5C9.30375 35.6962 7.8797 34.2721 6.0759 34.2721C4.27211 34.2721 2.84805 35.6962 2.84805 37.5C2.84805 39.3038 4.27211 40.7278 6.0759 40.7278Z" />
      <path d="M26.1077 52.3101L65.6013 52.3101C69.7785 52.3101 73.1013 55.7278 73.1013 59.8101L73.1013 67.5C73.1013 71.6772 69.7785 75 65.6013 75L26.1076 75C22.0254 75 18.6076 71.6772 18.6076 67.5L18.6076 59.8101C18.7026 55.7278 22.0254 52.3101 26.1077 52.3101ZM65.6013 68.3544C66.076 68.3544 66.4557 67.9747 66.4557 67.5L66.4558 59.8101C66.4558 59.3354 66.076 58.9557 65.6013 58.9557L26.1077 58.9557C25.633 58.9557 25.2532 59.3354 25.2532 59.8101L25.2532 67.5C25.2532 67.9747 25.633 68.3544 26.1077 68.3544L65.6013 68.3544Z" />
    </g>
    <defs>
      <clipPath id="clip0_502_395">
        <rect
          width="73.1013"
          height="75"
          fill="white"
          transform="translate(73.1013 75) rotate(-180)"
        />
      </clipPath>
    </defs>
  </svg>
);

export { List };
