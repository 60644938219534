import React from "react";
import { useFile } from "./hooks";
import { FileHashmap } from "./FileHashmap";
import { FileContainer } from "./FileContainer";
import { CloseButton } from "./CloseButton";
import { OpenButton } from "./OpenButton";
import { Info } from "./Info";

const FileModal = ({ onClickClose }) => {
  // hooks
  const { name, caption, type, src } = useFile();
  // components
  const File = FileHashmap[type];
  // return
  return (
    <FileContainer>
      <File src={src} />
      <Info name={name} caption={caption} />
      <CloseButton onClickClose={onClickClose} />
      {type === "pdf" && <OpenButton src={src} />}
    </FileContainer>
  );
};

export { FileModal };
