import { calculatePaymentSubtotalAndMarkup } from "./calculatePaymentSubtotalAndMarkup";

const calculatePaymentCost = (model, invoiceId, paymentId) => {
  const { subtotal, markup } = calculatePaymentSubtotalAndMarkup(model, invoiceId, paymentId);
  const multiplier = markup + 1;
  const paymentCost = subtotal * multiplier;
  return { paymentCost, dollarMarkup: paymentCost - subtotal };
};

export { calculatePaymentCost };
