import React from 'react';
import { useModel } from '@thrivelot/hooks';
import { Button, PageContainer, Pill } from '@thrivelot/stories';
import { Link, useNavigate, useParams } from 'react-router-dom';
import {
  HelpPrompt,
  Confirm,
  FormProjectInvoiceProposal,
  LinkBack,
} from '@thrivelot/components';

const PageEditProjectInvoiceProposal = () => {
  const { projectId, invoiceProposalId } = useParams();
  const navigate = useNavigate();
  const {
    model: project,
    actions: projectActions,
    updateModel: updateProject,
  } = useModel({ modelName: 'Project', id: projectId });
  const {
    model: invoiceProposal,
    loaded,
    deleteModel,
  } = useModel({ modelName: 'InvoiceProposal', id: invoiceProposalId });

  const { status, name } = invoiceProposal || {};

  // const previewOptions = [
  //   {
  //     icon: "Visibility",
  //     label: "Preview Proposal",
  //     color: "blue-main",
  //     to: `../${invoiceProposalId}/proposal`,
  //   },
  // ];

  // if (kind === "time_materials_billing") {
  //   previewOptions.push({
  //     icon: "Visibility",
  //     label: "Preview First Invoice",
  //     color: "blue-main",
  //     to: `../${invoiceProposalId}/invoices/1`,
  //   });
  //   previewOptions.push({
  //     icon: "Visibility",
  //     label: "Preview Second Invoice",
  //     color: "blue-main",
  //     to: `../${invoiceProposalId}/invoices/2`,
  //   });
  // } else {
  //   previewOptions.push({
  //     icon: "Visibility",
  //     label: "Preview Invoice",
  //     color: "blue-main",
  //     to: `../${invoiceProposalId}/invoices/1`,
  //   });
  // }

  return (
    <PageContainer
      icon="LineItems"
      header="Edit Order"
      preheader={<LinkBack label="Back to Orders" to=".." />}
      subheader={
        name && (
          <div className="flex gap-4">
            <div className="text-lg font-bold">{invoiceProposal?.name}</div>
            {status === 'draft' && (
              <Pill size="sm" color="green-main">
                Draft
              </Pill>
            )}
          </div>
        )
      }
      documentTitle={loaded && project?.details?.title}
      headerActions={
        <div className="flex items-center gap-4">
          {/* <Dropdown
            align="right"
            label={
              <div className="text-blue-main flex items-center border-2 border-blue-main px-3 py-1 rounded-main">
                <Visibility size={16} color="blue-main" />
                <div className="ml-1 mr-1 font-bold text-blue-main">Preview</div>
                <AngleDown color="blue-main" size={16} styleProps="pt-1" />
              </div>
            }
            options={previewOptions}
          /> */}
          <Link to={`../${invoiceProposalId}`}>
            <Button
              label="Preview"
              size="small"
              kind="outline"
              icon="Visibility"
              color="blue-main"
            />
          </Link>
          {loaded && (
            <Confirm
              prompt="Are you sure you want to delete this order?"
              onConfirm={() => {
                updateProject(
                  projectActions.remove(
                    `invoiceProposalIds[value:${invoiceProposalId}]`
                  ).result
                );
                deleteModel();
                navigate('..');
              }}
            >
              <Button
                icon="Trash"
                size="small"
                kind="outline"
                color="red-main"
                label="Delete"
              />
            </Confirm>
          )}
        </div>
      }
      help={
        <HelpPrompt header="About the Project Invoices page">
          The Order into 5 sections: General Information, Billing Type, Work
          Phases, Invoice Details {'('}
          line item estimation{')'}, and the Billing Summary. At the bottom of
          the page you'll see an activity log and ability to Submit and Preview.
        </HelpPrompt>
      }
    >
      <FormProjectInvoiceProposal
        projectId={projectId}
        invoiceProposalId={invoiceProposalId}
      />
    </PageContainer>
  );
};

export { PageEditProjectInvoiceProposal };
