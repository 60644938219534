import React from "react";
import { useAuthProvider } from "./useAuthProvider";
import { AuthContext } from "./AuthContext";

const AuthProvider = ({ authConfig, children }) => {
  const auth = useAuthProvider(authConfig);

  return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>;
};

export { AuthProvider };
