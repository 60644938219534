import React from 'react';

const Info = ({
  color = 'green-main',
  size = 30,
  styleProps = '',
  ...rest
}) => (
  <svg
    width={size.toString()}
    height={size.toString()}
    viewBox="0 0 75 75"
    className={`fill-${color} ${styleProps}`}
    {...rest}
  >
    <path d="M37.5012 57.7786C35.6689 57.7786 34.1784 56.2881 34.1784 54.4559V36.5508C34.1784 34.7185 35.6689 33.228 37.5012 33.228C39.3334 33.228 40.8239 34.7185 40.8239 36.5508V54.4464C40.8239 56.2881 39.3334 57.7786 37.5012 57.7786Z" />
    <path d="M37.4986 29.098C39.858 29.098 41.7707 27.1853 41.7707 24.8259C41.7707 22.4664 39.858 20.5537 37.4986 20.5537C35.1391 20.5537 33.2264 22.4664 33.2264 24.8259C33.2264 27.1853 35.1391 29.098 37.4986 29.098Z" />
    <path d="M37.5 75C16.8228 75 0 58.1772 0 37.5C0 16.8228 16.8228 0 37.5 0C58.1772 0 75 16.8228 75 37.5C75 58.1772 58.1772 75 37.5 75ZM37.5 6.64557C20.4873 6.64557 6.64557 20.4873 6.64557 37.5C6.64557 54.5127 20.4873 68.3544 37.5 68.3544C54.5127 68.3544 68.3544 54.5127 68.3544 37.5C68.3544 20.4873 54.5127 6.64557 37.5 6.64557Z" />
  </svg>
);

export { Info };
