import React from "react";
import { Notes } from "./Notes";

const ProjectStory = () => (
  <>
    <Notes />
  </>
);

export { ProjectStory };
