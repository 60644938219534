import { get } from "@thrivelot/actions";

const getAddressText = (model) => {
  const addressObject = get(model, "details.address");
  let address = "";
  if (!addressObject) return address;
  if (addressObject.line1 && addressObject.line1.trim() !== "") {
    address += `${addressObject.line1} `;
  }
  if (addressObject.line2 && addressObject.line2.trim() !== "") {
    address += `\n${addressObject.line2} `;
  }
  if (addressObject.city && !addressObject.city.trim() !== "") {
    address += `\n${addressObject.city}, `;
  }
  if (addressObject.state && addressObject.state.trim() !== "") {
    address += `${addressObject.state} `;
  }
  if (addressObject.zip && addressObject.zip.trim() !== "") {
    address += `${addressObject.zip}`;
  }
  return address;
};

export { getAddressText };
