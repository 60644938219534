import styled, { css } from "styled-components";
import CssDivDefault from "./CssDivDefault";

const DivSearchFilterBar = styled.div`
  ${CssDivDefault}
  min-height: fit-content;
  max-height: fit-content;
  width: 100%;
  padding: 8px 12px;
  display: flex;
  flex-direction: row;
  align-items: center;
  ${({ scrollable }) =>
    scrollable &&
    css`
      overflow-x: scroll;
      margin-right: -12px;
    `}
`;

export default DivSearchFilterBar;
