import { constructPlantAttributeWithChanges } from "./constructPlantAttributeWithChanges";

const constructPlantInfo = (plantFromDatabase, plantFromProject) => {
  const plantInfo = {};
  plantInfo.commonName = constructPlantAttributeWithChanges({
    key: "commonName",
    plant: plantFromDatabase,
    plantItem: plantFromProject,
  });
  plantInfo.scientificName = constructPlantAttributeWithChanges({
    key: "scientificName",
    plant: plantFromDatabase,
    plantItem: plantFromProject,
  });
  plantInfo.imageUrl = constructPlantAttributeWithChanges({
    key: "imageUrl",
    plant: plantFromDatabase,
    plantItem: plantFromProject,
  });
  plantInfo.growingDescription = constructPlantAttributeWithChanges({
    key: "growth_growingDescription",
    plant: plantFromDatabase,
    plantItem: plantFromProject,
  });
  plantInfo.sowingDescription = constructPlantAttributeWithChanges({
    key: "planting_sowingDescription",
    plant: plantFromDatabase,
    plantItem: plantFromProject,
  });
  plantInfo.notes = plantFromProject.notes || "";
  return plantInfo;
};

export { constructPlantInfo };
