import React from 'react';
import { Leaf } from '../icons';

export const LeafLoader = ({ size, text, classAppend = '' }) => {
  // md size default
  let textClass = '';
  let iconSize = 20;

  if (size === 'lg') {
    textClass += 'text-2xl';
    iconSize = 30;
  } else if (size === 'sm') {
    textClass += 'text-sm';
    iconSize = 14;
  } else if (size === 'xs') {
    textClass += 'text-xs';
    iconSize = 10;
  }

  return (
    <div
      className={`animate-pulse text-brown-light flex justify-center items-center ${classAppend}`}
    >
      <Leaf color="brown-light" size={iconSize} />
      <div className={`ml-1 ${textClass}`}>{text || 'Loading...'}</div>
    </div>
  );
};
