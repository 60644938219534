import React, { useMemo } from "react";
import { useModel } from "@thrivelot/hooks";
import { LayoutModal, InputGroupLineItemFull } from "../..";
import { Button } from "@thrivelot/stories";

const ModalEditBundleItem = ({ onClose, projectId, bundleId, itemId, itemPath }) => {
  const { loaded: projectLoaded } = useModel({ modelName: "Project", id: projectId });
  const {
    loaded: bundleLoaded,
    actions: bundleActions,
    updateModel: updateBundle,
  } = useModel({ modelName: "Bundle", id: bundleId });

  const item = useMemo(
    () => bundleLoaded && bundleActions.get(`${itemPath}[id:${itemId}]`),
    [itemPath, bundleLoaded, bundleActions, itemId]
  );

  return (
    <LayoutModal
      onClose={onClose}
      loading={!projectLoaded || !bundleLoaded}
      header={<div className="text-lg text-brown-dark italic">Edit Item</div>}
      footer={
        <div className="flex justify-end">
          <Button label="Submit" color="green-main" kind="outline" onClick={onClose} />
        </div>
      }
      dialogCloseDisabled
    >
      <InputGroupLineItemFull
        formState={item}
        setFormState={(newItem) => updateBundle(bundleActions.set(`${itemPath}[id:${itemId}]`, newItem).result)}
      />
    </LayoutModal>
  );
};

export { ModalEditBundleItem };
