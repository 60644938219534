import React, { useEffect, useState } from 'react';
import { useInvoiceProposalBillingSummary } from '@thrivelot/common';
import { useConstructTimestamp, useService } from '@thrivelot/hooks';
import { ModelKit } from '@thrivelot/model-kit';
import { Button } from '@thrivelot/stories';
import {
  transformCurrencyToString,
  transformStringToTitleCase,
} from '@thrivelot/utils';
import { LayoutModal, ImageUserAvatar } from '../..';

const getSupplierName = (supplier) => {
  const { businessName, firstname, lastname } = supplier;
  const supplierName = `${firstname.trim()} ${lastname.trim()}`;

  return {
    header: businessName || supplierName,
    subheader: businessName ? supplierName : null,
  };
};

const ModalInvoiceProposalPayoutSummary = ({
  onClose,
  invoiceProposalId,
  paymentLabel,
  payoutLabel,
}) => {
  const { construct } = useConstructTimestamp();
  const { request: processStripePayout, loading: processingStripePayout } =
    useService({
      method: 'post',
      level: 'admin',
      path: 'process-stripe-payout',
      deferLoad: true,
      variables: {
        invoiceProposalId,
        paymentLabel,
        payoutLabel,
        timestamp: construct({ kind: 'invoiceProposalPayoutSent' }),
      },
    });
  const { billingSummary, loading: loadingBillingSummary } =
    useInvoiceProposalBillingSummary(invoiceProposalId);
  const [supplierPayouts, setSupplierPayouts] = useState([]);
  const [payoutErrors, setPayoutErrors] = useState();

  useEffect(() => {
    if (!billingSummary) return;

    let didCancel;

    const payouts = billingSummary.payouts.filter(
      (p) => p.label === payoutLabel && p.paymentLabel === paymentLabel
    );
    if (!payouts.length) return;

    (async () => {
      try {
        const suppliers = await Promise.all(
          payouts.map(({ supplierPayeeId }) => {
            const supplierKit = new ModelKit({
              modelName: 'Supplier',
              id: supplierPayeeId,
            });
            return supplierKit.query();
          })
        );

        const supplierPayouts = suppliers.map((supplier) => {
          const payout = payouts.find(
            ({ supplierPayeeId }) => supplier.id === supplierPayeeId
          );
          return { supplier, totalAmount: payout.totalAmount };
        });

        if (!didCancel) setSupplierPayouts(supplierPayouts);
      } catch (err) {
        console.error(err);
      }
    })();

    return () => {
      didCancel = true;
    };
  }, [billingSummary, paymentLabel, payoutLabel]);

  const payment = billingSummary
    ? billingSummary.payments.find((p) => p.label === paymentLabel)
    : null;

  const payout = async () => {
    try {
      const { data } = await processStripePayout();

      if (
        Array.isArray(data) &&
        data.length > 0 &&
        data[0]?.error?.errors?.length > 0
      ) {
        const errors = {};

        data.forEach((payoutError) => {
          errors[payoutError.payout.supplierPayeeId] =
            payoutError.error.errors[0].raw.message;
        });

        setPayoutErrors(errors);
      } else onClose();
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <LayoutModal
      onClose={onClose}
      loading={loadingBillingSummary}
      header={
        <div className="text-lg text-brown-dark italic">
          {transformStringToTitleCase(payoutLabel)}
        </div>
      }
      dialogCloseDisabled
    >
      {!loadingBillingSummary && payment && (
        <>
          {supplierPayouts.map((supplierPayout) => {
            const { header, subheader } = getSupplierName(
              supplierPayout.supplier
            );

            return (
              <div key={supplierPayout.supplier.id}>
                <div
                  key={supplierPayout.supplier.id}
                  className="flex items-center justify-between text-mulch-light mb-2"
                >
                  <div className="flex items-center">
                    <div className="mr-3">
                      <ImageUserAvatar user={supplierPayout.supplier} />
                    </div>
                    <div className="w-full flex-1">
                      <div className="font-bold">{header}</div>
                      <div className="text-sm">{subheader}</div>
                    </div>
                  </div>
                  <div className="font-bold text-lg text-green-main">
                    {transformCurrencyToString(supplierPayout.totalAmount)}
                  </div>
                </div>
                {payoutErrors?.[supplierPayout.supplier.id] && (
                  <div className="flex text-red-main mb-4">
                    {payoutErrors[supplierPayout.supplier.id]}
                  </div>
                )}
              </div>
            );
          })}
          <Button
            block
            loading={processingStripePayout}
            color="green-main"
            onClick={payout}
          >
            Send Payout
          </Button>
        </>
      )}
    </LayoutModal>
  );
};

export { ModalInvoiceProposalPayoutSummary };
