import styled from "styled-components";

const StripeElementWrapperDiv = styled.div`
  padding: 5px 8px 5px 5px;
  border-radius: 5px;
  width: 100%;

  background-color: ${({ theme }) => theme.inputBackground};
  padding: 8px 5px 8px 8px;
  align-items: center;

  box-shadow: inset 0px 1px 4px rgb(0 0 0 / 25%);

  border: 0px none transparent;
  ::selection {
    background-color: ${({ theme }) => theme.inputTintColor};
    color: ${({ theme }) => theme.inputSelectedText};
  }
  blockquote::selection {
    background-color: ${({ theme }) => theme.inputTintColor};
    color: ${({ theme }) => theme.inputSelectedText};
  }
  ::placeholder {
    color: ${({ theme }) => theme.inputPlaceholderText};
    opacity: 1;
  }
  :-webkit-autofill {
    background-color: ${({ theme }) => theme.inputTintColor};
    color: ${({ theme }) => theme.inputSelectedText};
  }
  color: ${({ theme }) => theme.inputText};

  :focus {
    box-shadow: -1px -2px 4px ${({ theme }) => theme.shadowDiv1}, 1px 2px 4px ${({ theme }) => theme.shadowDiv2};
    outline: none !important;
    background-color: ${({ theme }) => theme.inputBackgroundFocused};
  }
`;

export { StripeElementWrapperDiv };
