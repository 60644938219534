import React from "react";
import { NotificationsContext } from "./NotificationsContext";
import { useNotificationsProvider } from "./useNotificationsProvider";

const NotificationsProvider = ({ children }) => {
  const notifications = useNotificationsProvider();

  return <NotificationsContext.Provider value={notifications}>{children}</NotificationsContext.Provider>;
};

export { NotificationsProvider };
