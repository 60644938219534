import React, { useMemo } from "react";
import { Dropdown } from "@thrivelot/stories";
import { AngleDown } from "@thrivelot/stories";
import { transformStringToTitleCase } from "@thrivelot/utils";
import { lineItemIconMap } from "@thrivelot/config";
import { LineItemKind } from "@thrivelot/aws";

const DropdownLineItemMultiSelect = ({ selectedOption, onMultiSelect }) => {
  const options = useMemo(
    () => [
      {
        icon: "CheckFull",
        label: "All",
        selected: selectedOption === "all",
        onClick: () => onMultiSelect("all"),
      },
      {
        icon: "CheckEmpty",
        label: "None",
        selected: false,
        onClick: () => onMultiSelect(),
      },
      {
        icon: lineItemIconMap[LineItemKind.MATERIAL],
        label: "Materials",
        selected: selectedOption === "material",
        onClick: () => onMultiSelect("material"),
      },
      {
        icon: lineItemIconMap[LineItemKind.LABOR],
        label: "Labor",
        selected: selectedOption === "labor",
        onClick: () => onMultiSelect("labor"),
      },
      {
        icon: lineItemIconMap[LineItemKind.EQUIPMENT],
        label: "Equipment",
        selected: selectedOption === "equipment",
        onClick: () => onMultiSelect("equipment"),
      },
      {
        icon: lineItemIconMap[LineItemKind.PLANT],
        label: "Plants",
        selected: selectedOption === "plant",
        onClick: () => onMultiSelect("plant"),
      },
    ],
    [selectedOption, onMultiSelect]
  );

  return (
    <Dropdown
      label={
        <div className="flex items-center gap-2 hover:cursor-pointer text-green-main text-sm py-1">
          <div>{selectedOption ? transformStringToTitleCase(selectedOption) : "Select"}</div>
          <AngleDown color="green-main" size={12} />
        </div>
      }
      options={options}
      optionClass="font-bold"
      align="left"
    />
  );
};

export { DropdownLineItemMultiSelect };
