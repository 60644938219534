import React from "react";
import { NavLink } from "react-router-dom";
import * as icons from "@thrivelot/stories";

const NavItem = ({ icon, label, to }) => {
  const Icon = icons[icon];

  return (
    <NavLink
      to={to}
      className={({ isActive }) => `flex items-center text-brown-dark gap-1 ${isActive ? "font-bold" : ""}`}
    >
      <Icon size={16} color="brown-dark" />
      <div>{label}</div>
    </NavLink>
  );
};

export { NavItem };
