import { useContext, useEffect, useRef, useState } from "react";
import { isEmpty, isEqual } from "@thrivelot/utils";
import { useNetwork } from "../useNetwork";
import { ModelContext } from "./ModelContext";

const useModelStatus = () => {
  const { loading, saving } = useContext(ModelContext);
  const { online } = useNetwork();

  const [loadingState, setLoadingState] = useState(!isEmpty(loading));
  const [savingState, setSavingState] = useState(saving);
  const [onlineState, setOnlineState] = useState(online);

  const loadingRef = useRef();
  const savingRef = useRef();
  const onlineRef = useRef();

  loadingRef.current = loadingState;
  savingRef.current = savingState;
  onlineRef.current = onlineState;

  useEffect(() => {
    let didCancel;

    if (!isEqual(loading, loadingRef.current) && !didCancel) setLoadingState(loading);

    return () => {
      didCancel = true;
    };
  }, [loading]);

  useEffect(() => {
    let didCancel;

    if (!isEqual(saving, savingRef.current) && !didCancel) setSavingState(saving);

    return () => {
      didCancel = true;
    };
  }, [saving]);

  useEffect(() => {
    let didCancel;

    if (!isEqual(online, onlineRef.current) && !didCancel) setOnlineState(online);

    return () => {
      didCancel = true;
    };
  }, [online]);

  return { loading: loadingState, saving: savingState, online: onlineState };
};

export { useModelStatus };
