import React from "react";
import { useTheme, useMobileDifference } from "@thrivelot/hooks";
import { TextTruncate } from "@thrivelot/styles";
import { useInnerSpacing } from "../../../../hooks";
import { NameContainer } from "./NameContainer";

const Name = ({ file }) => {
  // hooks
  const theme = useTheme();
  const fontSize = useMobileDifference({ desktop: "14px", mobile: "10px" });
  const innerSpacing = useInnerSpacing();
  // utils
  const name = () => {
    if (!file.file.filename || file.file.filename.trim() === "") return "No name...";
    return file.file.filename;
  };
  const noName = () => name() === "No name...";
  // return
  return (
    <NameContainer style={{ padding: innerSpacing }}>
      <TextTruncate
        textType="body"
        style={{
          fontStyle: noName() && "italic",
          opacity: noName() && 0.5,
          fontSize,
          color: theme.textLight,
          marginTop: "-3px",
          marginRight: innerSpacing,
        }}
      >
        {name()}
      </TextTruncate>
    </NameContainer>
  );
};

export { Name };
