import { Amplify } from "aws-amplify";
import { awsConfig } from "@thrivelot/aws";

const initAmplify = (debugMode = false) => {
  // Configure amplify for app
  Amplify.configure(awsConfig);

  // Set log level to "DEBUG" if in debug mode
  if (debugMode) Amplify.Logger.LOG_LEVEL = "DEBUG";
};

export { initAmplify };
