import React from "react";
import { useModal, useModel } from "@thrivelot/hooks";
import { Button, PageContainer } from "@thrivelot/stories";
import { useParams } from "react-router-dom";
import { HelpPrompt } from "../../HelpPrompt";
import { RowListProjectInvoiceProposals } from "../../RowListProjectInvoiceProposals";

const PageProjectInvoiceProposals = () => {
  const { openModal } = useModal();
  const { projectId } = useParams();
  const { model: project, loaded } = useModel({ modelName: "Project", id: projectId });

  return (
    <PageContainer
      icon="LineItems"
      header="Orders"
      documentTitle={loaded && project.details.title}
      headerActions={
        <Button
          icon="Plus"
          size="small"
          color="blue-main"
          kind="outline"
          label="Order"
          onClick={() =>
            openModal({
              path: "ModalAddInvoiceProposal",
              projectId,
            })
          }
        />
      }
      help={
        <HelpPrompt header="About the Project Invoices page">
          Please use this page to create, share, and update Invoices for this project. You can create Design, Install,
          and Custom Invoices, preview Proposals prior to submission, make Change Orders after submitted for approval,
          and much more. Feel free to experiment and try it for yourself.
        </HelpPrompt>
      }
    >
      <RowListProjectInvoiceProposals projectId={projectId} />
    </PageContainer>
  );
};

export { PageProjectInvoiceProposals };
