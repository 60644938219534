import React from 'react';

const License = ({
  color = 'green-main',
  size = 30,
  styleProps = '',
  ...rest
}) => (
  <svg
    width={size.toString()}
    height={size.toString()}
    viewBox="0 0 75 75"
    className={`fill-${color} ${styleProps}`}
    {...rest}
  >
    <path d="M61.7184 9H13.2816C5.96203 9 0 14.962 0 22.2816V53.6297C0 60.9494 5.96203 66.9114 13.2816 66.9114H61.7184C69.038 66.9114 75 60.9494 75 53.6297V22.2816C75 14.962 69.038 9 61.7184 9ZM68.3544 53.6297C68.3544 57.2848 65.3734 60.2658 61.7184 60.2658H13.2816C9.62658 60.2658 6.64557 57.2848 6.64557 53.6297V22.2816C6.64557 18.6266 9.62658 15.6456 13.2816 15.6456H61.7184C65.3734 15.6456 68.3544 18.6266 68.3544 22.2816V53.6297Z" />
    <path d="M24.0755 23.7153C16.2243 23.7153 9.83502 30.1046 9.83502 37.9558C9.83502 41.8008 11.3445 45.3989 14.0787 48.0951C16.7654 50.7343 20.316 52.1963 24.0755 52.1963C27.835 52.1963 31.3762 50.7438 34.0534 48.1141C36.8065 45.4179 38.316 41.8103 38.316 37.9558C38.316 30.1046 31.9268 23.7153 24.0755 23.7153ZM33.1895 44.7913C32.373 42.0761 30.104 39.8261 27.1325 38.8957C28.1388 38.0223 28.7274 36.7596 28.7274 35.3831C28.7274 32.8198 26.6388 30.7312 24.0755 30.7312C21.5122 30.7312 19.4236 32.8198 19.4236 35.3831C19.4236 36.7596 20.0217 38.0223 21.0186 38.8957C18.047 39.8261 15.7781 42.0761 14.9616 44.7913C13.4806 42.8356 12.6831 40.4622 12.6831 37.9558C12.6831 31.671 17.7907 26.5634 24.0755 26.5634C30.3603 26.5634 35.4679 31.671 35.4679 37.9558C35.4679 40.4622 34.6705 42.8356 33.1895 44.7913Z" />
    <path d="M64.0543 30.2753H41.8202C41.0322 30.2753 40.3961 29.6393 40.3961 28.8513C40.3961 28.0633 41.0322 27.4272 41.8202 27.4272H64.0543C64.8423 27.4272 65.4784 28.0633 65.4784 28.8513C65.4784 29.6393 64.8423 30.2753 64.0543 30.2753Z" />
    <path d="M64.0543 39.5981H41.8202C41.0322 39.5981 40.3961 38.962 40.3961 38.1741C40.3961 37.3861 41.0322 36.75 41.8202 36.75H64.0543C64.8423 36.75 65.4784 37.3861 65.4784 38.1741C65.4784 38.962 64.8423 39.5981 64.0543 39.5981Z" />
    <path d="M64.0543 48.7026H41.8202C41.0322 48.7026 40.3961 48.0665 40.3961 47.2785C40.3961 46.4906 41.0322 45.8545 41.8202 45.8545H64.0543C64.8423 45.8545 65.4784 46.4906 65.4784 47.2785C65.4784 48.0665 64.8423 48.7026 64.0543 48.7026Z" />
  </svg>
);

export { License };
