import React from 'react';
import { Pdf, Info, Checkmark } from '@thrivelot/stories';
import { Document, Page } from 'react-pdf';

export const FilePreview = ({ file }) => (
  <div className="relative">
    <div className={file.status === 'uploading' ? 'animate-pulse' : ''}>
      {/image/.test(file.type) ? (
        <img style={{ height: `${48}px` }} alt={file.name} src={file.dataUrl} />
      ) : (
        <Document
          file={file.dataUrl}
          loading={
            <Pdf size={30} color="brown-light" styleProps="animate-pulse" />
          }
          noData={<Info size={30} color="yellow-main" />}
          error={<Info size={30} color="red-main" />}
        >
          <Page height={48} pageNumber={1} />
        </Document>
      )}
    </div>
    {file.status === 'done' && (
      <div className="absolute top-0 left-0 w-full h-full flex justify-center items-center">
        <div className="bg-white-light rounded-full">
          <Checkmark color="green-main" size={22} styleProps="" />
        </div>
      </div>
    )}
  </div>
);
