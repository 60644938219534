import React from "react";
import { ImgRectangle } from "@thrivelot/styles";

const Pic = ({ supplierActions }) => {
  // actions
  const pic = supplierActions.get("pic");
  // return
  return <ImgRectangle noRounding imgType="rectangle" width="100%" src={pic} style={{ objectFit: "aspect-fit" }} />;
};

export { Pic };
