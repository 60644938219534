import { Auth } from "@thrivelot/aws-amplify";

const requiresNewPassword = (user) =>
  user.challengeName === "NEW_PASSWORD_REQUIRED" || user.challengeName === "FORCE_CHANGE_PASSWORD";

const signIn = async (username, password, executeMigrateUser = true) => {
  try {
    const user = await Auth.signIn(username, password);

    if (requiresNewPassword(user)) return { user, requiresNewPassword: true };

    return { user };
  } catch (err) {
    const error = new Error(err.message || "Error signing in");
    error.name = "SignInError";
    error.stack = err;

    throw error;
  }
};

export { signIn };
