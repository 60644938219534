import React, { useMemo } from "react";
import { dateFns } from "@thrivelot/date";
import { Card } from "@thrivelot/stories";
import { CardLoader } from "@thrivelot/stories";
import { useCanCan, useModel } from "@thrivelot/hooks";
import { Calendar } from "..";
import { InvoiceProposalProjectCalendar } from "./InvoiceProposalProjectCalendar";

const CardProjectCalendar = ({ projectId }) => {
  const { model: project, loaded } = useModel({ modelName: "Project", id: projectId });
  const { canEdit } = useCanCan(project);

  const events = useMemo(() => {
    const datesWithPhases = [];
    const phases = project?.phases || [];
    phases.forEach((phase) => {
      datesWithPhases.push({
        title: phase.name,
        start: dateFns(phase.startDate).toDate(),
        end: dateFns(phase.endDate).toDate(),
        allDay: true,
      });
    });

    return datesWithPhases;
  }, [project?.phases]);

  const notes = useMemo(
    () => (project?.calendarNotes ? project?.calendarNotes.map(({ date }) => date) : []),
    [project?.calendarNotes]
  );

  if (!loaded) return <CardLoader />;

  if (project.invoiceProposalIds)
    return <InvoiceProposalProjectCalendar projectId={projectId} notes={notes} canEdit={canEdit} />;

  return (
    <Card header="Calendar" classAppend="mb-4">
      <div className="h-[20rem] md:h-[28rem] lg:h-[35rem] shadow bg-white-light">
        <Calendar events={events} notes={canEdit && notes} />
      </div>
    </Card>
  );
};

export { CardProjectCalendar };
