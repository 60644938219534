import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { ModelKit } from '@thrivelot/model-kit';
import {
  duplicateObject,
  handleDebounce,
  isEmpty,
  isEqual,
} from '@thrivelot/utils';
import { subscribe } from './subscribe';
import { useWindowFocus } from '../useWindowFocus';
import { useModelKit } from './useModelKit';
import { notifyErrors } from './notifyErrors';
import { constructUnsavedUpdates } from './constructUnsavedUpdates';

const useModelProvider = () => {
  const {
    loaded: modelKitLoaded,
    authenticated,
    subscriber,
    modelConfig,
  } = useModelKit();
  const isWindowFocused = useWindowFocus();

  const [drafts, setDrafts] = useState({});
  const [models, setModels] = useState({});
  const [loading, setLoading] = useState({});
  const [saving, setSaving] = useState(false);
  const [resetSubscriptions, setResetSubscriptions] = useState();

  const subscriberRef = useRef();
  const modelConfigRef = useRef();
  const draftsRef = useRef();
  const modelsRef = useRef();
  const loadingRef = useRef();

  subscriberRef.current = subscriber;
  modelConfigRef.current = modelConfig;
  draftsRef.current = drafts;
  modelsRef.current = models;
  loadingRef.current = loading;

  const initModel = useCallback((modelName, newModel) => {
    setModels((prevState) => ({
      ...prevState,
      [modelName]: {
        ...prevState[modelName],
        [newModel.id]: newModel,
      },
    }));
  }, []);

  const query = useCallback(async (modelName, id, force = false) => {
    if (loadingRef.current[id]) return;
    if (!force && modelsRef.current?.[modelName]?.[id]) return;

    if (!force) setLoading((prevState) => ({ ...prevState, [id]: true }));

    try {
      const modelKit = new ModelKit({ modelName, id });
      const data = await modelKit.query();

      if (data)
        setModels((prevState) => {
          const newState = {
            ...prevState,
            [modelName]: {
              ...prevState[modelName],
              [id]: data,
            },
          };
          return newState;
        });
    } catch (err) {
      notifyErrors(err, modelConfigRef.current.onQueryError);
    } finally {
      if (!force)
        setLoading((prevState) => {
          const newState = duplicateObject(prevState);
          delete newState[id];
          return newState;
        });
    }
  }, []);

  const remove = useCallback(async (modelName, id) => {
    setSaving(true);

    try {
      const modelKit = new ModelKit({ modelName, id });
      await modelKit.remove();
    } catch (err) {
      notifyErrors(err, modelConfigRef.current.onSaveError);
    } finally {
      setSaving(false);
    }
  }, []);

  const update = useCallback((modelName, id, updates) => {
    setDrafts((prevState) => {
      const currentDraftForModel = prevState?.[modelName]?.[id] || {};

      return {
        ...prevState,
        [modelName]: {
          ...prevState[modelName],
          [id]: { ...currentDraftForModel, ...updates },
        },
      };
    });
  }, []);

  const fetchUpToDateModel = useCallback(
    (modelName, id) =>
      new ModelKit({ modelName, id })
        .query()
        .then((data) => {
          if (data)
            modelsRef.current = {
              ...modelsRef.current,
              [modelName]: {
                ...modelsRef.current[modelName],
                [id]: data,
              },
            };
          return 'SUCCESS';
        })
        .catch((err) => {
          notifyErrors(err, modelConfigRef.current.onQueryError);
          return 'ERROR';
        }),
    []
  );

  const updateBackend = useCallback(
    async (updates) => {
      setSaving(true);

      // Filter out updates
      const nonEmptyUpdates = {};
      Object.keys(updates)
        .filter((modelName) => Object.keys(updates[modelName]).length > 0)
        .forEach((modelName) => {
          nonEmptyUpdates[modelName] = updates[modelName];
        });

      // Build queries to fetch current version of model
      const queries = [];
      Object.keys(nonEmptyUpdates).forEach((modelName) =>
        Object.keys(nonEmptyUpdates[modelName]).forEach((id) => {
          queries.push(fetchUpToDateModel(modelName, id));
        })
      );

      // Handle queries
      await Promise.all(queries);

      // Build trimmed updates
      const trimmedUpdates = {};
      Object.keys(nonEmptyUpdates).forEach((modelName) => {
        trimmedUpdates[modelName] = nonEmptyUpdates[modelName];
      });

      console.log('Autosaving udpates:', trimmedUpdates);

      // Build save requests array
      const saves = [];
      Object.keys(trimmedUpdates).forEach((modelName) =>
        Object.keys(trimmedUpdates[modelName]).forEach((id) => {
          console.log(
            modelConfigRef.current.onUpdateVariableInput({
              id,
              ...trimmedUpdates[modelName][id],
            })
          );
          const modelKit = new ModelKit({
            modelName,
            id,
            updated: modelConfigRef.current.onUpdateVariableInput({
              id,
              ...trimmedUpdates[modelName][id],
            }),
          });
          saves.push(
            modelKit
              .update()
              .then((data) => data)
              .catch((err) => ({ error: err }))
          );
        })
      );

      // Handle save
      const saveResponse = await Promise.all(saves);
      const saveErrors = saveResponse
        .filter(({ error, errors }) => !!error || !!errors)
        .map(({ error, errors }) => error || errors);
      if (saveErrors.length > 0)
        notifyErrors(saveErrors, modelConfigRef.current.onSaveError);

      // Build new models from trimmed updates
      const newModels = { ...modelsRef.current };
      Object.keys(trimmedUpdates).forEach((modelName) => {
        newModels[modelName] = newModels[modelName] || {};
        Object.keys(updates[modelName]).forEach((id) => {
          newModels[modelName][id] = {
            ...newModels[modelName][id],
            ...updates[modelName][id],
          };
        });
      });

      // Build new drafts
      const newDrafts = {};
      Object.keys(draftsRef.current).forEach((modelName) => {
        Object.keys(draftsRef.current[modelName]).forEach((id) => {
          if (updates[modelName] && updates[modelName][id]) {
            const draft = {};
            Object.keys(draftsRef.current[modelName][id]).forEach(
              (propertyName) => {
                if (
                  !isEqual(
                    draftsRef.current[modelName][id][propertyName],
                    updates[modelName][id][propertyName]
                  )
                )
                  draft[propertyName] =
                    draftsRef.current[modelName][id][propertyName];
              }
            );
            if (!isEmpty(draft)) {
              newDrafts[modelName] = newDrafts[modelName] || {};
              newDrafts[modelName][id] = draft;
            }
          } else newDrafts[modelName][id] = draftsRef.current[modelName][id];
        });
      });

      // Handle some logging
      console.log('New models:', newModels);
      console.log('New drafts:', newDrafts);

      // Update states
      setModels(newModels);
      setDrafts(newDrafts);
      setSaving(false);
    },
    [fetchUpToDateModel]
  );

  const save = useCallback(() => {
    updateBackend(duplicateObject(draftsRef.current));
  }, [updateBackend]);

  const reconciledModels = useMemo(() => {
    const reconciliation = duplicateObject(models);

    Object.keys(drafts).forEach((modelName) => {
      Object.keys(drafts[modelName]).forEach((id) => {
        reconciliation[modelName][id] = {
          ...reconciliation[modelName][id],
          ...drafts[modelName][id],
        };
      });
    });

    return reconciliation;
  }, [models, drafts]);

  const saveDebounced = useMemo(() => handleDebounce(save, 2000), [save]);

  useEffect(() => {
    const prevSaveDebounced = saveDebounced;

    return () => {
      prevSaveDebounced.cancel();
    };
  }, [saveDebounced]);

  // Check that autoSave is enabled and there are local changes to save
  useEffect(() => {
    if (!isEmpty(drafts)) saveDebounced();
  }, [saveDebounced, drafts]);

  useEffect(() => {
    if (!isWindowFocused || Object.keys(modelsRef.current).length < 1) return;

    (async () => {
      let tasks = Promise.resolve();

      for (let i = 0; i < Object.keys(modelsRef.current).length; i += 1) {
        const modelName = Object.keys(modelsRef.current)[i];

        for (
          let j = 0;
          j < Object.keys(modelsRef.current[modelName]).length;
          j += 1
        ) {
          const modelId = Object.keys(modelsRef.current[modelName])[j];

          console.log(`Refreshing ${modelName}: ${modelId}`);

          tasks = tasks.then(() => query(modelName, modelId, true));
        }
      }

      await tasks;
    })();
  }, [isWindowFocused, query]);

  // Setup and handle subscriptions
  useEffect(() => {
    if (!modelKitLoaded || !authenticated) return;

    const callback = ({ data, error }) => {
      if (error) {
        if (error?.error?.errors?.[0]?.message === 'Connection closed') {
          console.log('Resetting subscriptions');
          return setResetSubscriptions(Date.now());
        }

        return notifyErrors(error, modelConfigRef.current.onSubscriptionError);
      }

      console.log('Subscription response:', data);

      const { modelName, modelId } = data;

      if (modelsRef.current?.[modelName]?.[modelId])
        query(modelName, modelId, true);
    };

    const subscriptions = [];

    const subscriptionNames = Object.keys(
      modelConfigRef?.current?.subscriptions
    );

    if (subscriptionNames)
      subscriptionNames.forEach((subscriptionName) => {
        const { gql } =
          modelConfigRef.current.subscriptions[subscriptionName].subscription;

        subscriptions.push(
          subscribe({
            gql,
            variables: { subscriber: subscriberRef.current },
            callback,
          })
        );
      });

    return () => {
      subscriptions.forEach((subscription) => {
        subscription.unsubscribe();
      });
    };
  }, [authenticated, modelKitLoaded, query, resetSubscriptions]);

  return {
    models: reconciledModels,
    initModel,
    query,
    remove,
    update,
    loading,
    saving,
  };
};

export { useModelProvider };
