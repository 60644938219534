import React from "react";
import { useNotifications } from "@thrivelot/hooks";
import { Notification } from "./Notification";

const NotificationCenter = () => {
  const { notifications } = useNotifications();

  return (
    <div className="fixed top-0 right-4">
      {notifications.map((notification) => (
        <Notification key={notification.id} notification={notification} />
      ))}
    </div>
  );
};

export { NotificationCenter };
