import styled from "styled-components";

const PicAndNameContainer = styled.div`
  position: relative;
  min-height: fit-content;
  max-height: fit-content;
  width: 100%;
  overflow: hidden;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
`;

export { PicAndNameContainer };
