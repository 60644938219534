// Import all the things we need from React
import { TagIdType } from '@thrivelot/aws';
import { useModel } from '@thrivelot/hooks';
import { Button, LeafLoader } from '@thrivelot/stories';
import React, { useCallback, useState } from 'react';
import ReactTextareaAutosize from 'react-textarea-autosize';
import { Link } from 'react-router-dom';
import { DropdownUpdateFeatureArea } from '../DropdownUpdateFeatureArea';

// Create a new component with the same name as the file
const NewFeatureForm = ({ projectId, onSubmit, onCancel }) => {
  const { model, loaded, actions, updateModel } = useModel({
    modelName: 'Project',
    id: projectId,
  });

  const [name, setName] = useState('');
  const [zoneId, setZoneId] = useState();
  const [desc, setDesc] = useState('');

  const handleCreate = useCallback(() => {
    const newFeature = actions.constructFeature({
      name,
      description: desc,
    });
    updateModel(
      actions.add('features', newFeature, 0).add(
        `zones[id:${zoneId}].orderedChildTagIds`,
        {
          type: TagIdType.FEATURE,
          tagId: newFeature.id,
        },
        0
      ).result
    );
    onSubmit(newFeature.id);
    onCancel();
  }, [updateModel, actions, zoneId, onSubmit, onCancel, name, desc]);

  if (!loaded) return <LeafLoader />;

  return (
    <div className="flex flex-col gap-4 mb-4">
      <div className="font-bold">New Feature</div>
      <input
        className="input"
        placeholder="Name"
        value={name}
        onChange={(e) => setName(e.target.value)}
      />
      <DropdownUpdateFeatureArea
        projectId={projectId}
        selectedId={zoneId}
        onSelect={(id) => setZoneId(id)}
      />
      <ReactTextareaAutosize
        className="w-full input"
        placeholder="Add a description..."
        value={desc}
        onChange={(e) => setDesc(e.target.value)}
      />
      {model?.zones?.length === 0 && (
        <div className="text-red-main text-sm">
          You must create an area of work{' '}
          <Link
            to={`/projects/${projectId}/map`}
            className="underline text-blue-main hover:cursor-pointer"
          >
            on the map
          </Link>{' '}
          before you can create a feature.
        </div>
      )}
      <div className="flex justify-between">
        <Button
          icon="Cancel"
          size="small"
          kind="outline"
          color="red-main"
          onClick={onCancel}
          label="Cancel"
        />
        <Button
          icon="Plus"
          color="green-main"
          kind="outline"
          size="small"
          onClick={handleCreate}
          label="Create"
          disabled={!name || !zoneId}
        />
      </div>
    </div>
  );
};

export { NewFeatureForm };
