import React from "react";
import { useBillingBalance, useModelUser } from "@thrivelot/common";
import { transformCurrencyToString } from "@thrivelot/utils";
import { Button, Card } from "@thrivelot/stories";
import { CardLoader } from "@thrivelot/stories";

const CardPayouts = () => {
  const { model: supplier, loaded: loadedSupplier } = useModelUser({ modelName: "Supplier" });
  const { balance, loading, goToStripe } = useBillingBalance(supplier);

  if (!loadedSupplier) return <CardLoader />;

  return (
    <Card
      header="Payouts"
      footer={
        <Button color="blue-main" block kind="outline" loading={loading} onClick={goToStripe}>
          {`${supplier.stripeId ? "View" : "Setup"} Payouts`}
        </Button>
      }
    >
      {balance && (
        <div className="h-full flex items-center justify-around">
          <div className="text-center">
            <div className="text-green-dark">Available</div>
            <div className="text-green-main text-2xl font-bold">
              {balance ? transformCurrencyToString(balance.available) : ""}
            </div>
          </div>

          <div className="text-center">
            <div className="text-blue-dark">Pending</div>
            <div className="text-blue-main text-2xl font-bold">
              {balance ? transformCurrencyToString(balance.pending) : ""}
            </div>
          </div>
        </div>
      )}
      {!balance && (
        <div className="h-full flex flex-col items-center justify-around">
          <div className="text-brown-dark text-center">
            Setup a payout account to view your available and pending balance.
          </div>
        </div>
      )}
    </Card>
  );
};

export { CardPayouts };
