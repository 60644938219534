import styled from "styled-components";
import CssDivDefault from "./CssDivDefault";

const DivProjectMenu = styled.div`
  ${CssDivDefault}
  position: absolute;
  bottom: 0px;
  left: 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: ${({ theme }) => theme.foreground};
  @media (max-width: 767px) {
    overflow-x: scroll;
    right: 0px;
    min-height: 60px;
    max-height: 60px;
    padding-left: 12px;
    padding-right: 12px;
    max-width: 100%;
    border-top: 1px solid ${({ theme }) => theme.divLineDark};
  }
  @media (min-width: 768px) {
    overflow-y: scroll;
    top: 0px;
    min-width: 60px;
    max-width: 60px;
    flex-direction: column;
    border-right: 1px solid ${({ theme }) => theme.divLineDark};
  }
`;

export default DivProjectMenu;
