import React, { useState } from "react";
import { isValidPhoneNumber } from "react-phone-number-input";
import PhoneInput from "react-phone-number-input/input";
import { Button } from "@thrivelot/stories";
import { createSupplier } from "@thrivelot/common";
import { LayoutModal } from "../..";

const ModalAddSupplier = ({ onClose }) => {
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");

  const [errors, setErrors] = useState([]);
  const [saving, setSaving] = useState(false);

  const handleSubmit = async () => {
    const submitErrors = [];

    if (!firstname) submitErrors.push("Customer first name cant be blank");
    if (!lastname) submitErrors.push("Customer last name cant be blank");
    if (!email) submitErrors.push("Customer email cant be blank");
    if (!phone) submitErrors.push("Customer phone number cant be blank");

    if (!isValidPhoneNumber(phone)) submitErrors.push("Invalid phone number");

    if (submitErrors.length > 0) return setErrors(submitErrors);

    try {
      setSaving(true);
      await createSupplier({
        email,
        firstname,
        lastname,
        phone,
      });
      setSaving(false);

      onClose();
    } catch (err) {
      setErrors([err.message]);
    } finally {
      setSaving(false);
    }
  };

  return (
    <LayoutModal
      onClose={onClose}
      header={<div className="font-bold text-brown-dark">Add Supplier</div>}
      subheader={<div className="text-brown-dark italic text-sm">Default password 'grower123'</div>}
      footer={
        <Button
          disabled={saving}
          loading={saving}
          block
          kind="outline"
          color="blue-main"
          label="Submit"
          onClick={handleSubmit}
        />
      }
    >
      <div className="flex flex-col gap-4">
        <div className="">
          <div className="text-green-dark text-sm font-bold mb-1">First Name</div>
          <input className="input" value={firstname} onChange={(e) => setFirstname(e.target.value)} />
        </div>

        <div className="">
          <div className="text-green-dark text-sm font-bold mb-1">Last Name</div>
          <input className="input" value={lastname} onChange={(e) => setLastname(e.target.value)} />
        </div>

        <div className="">
          <div className="text-green-dark text-sm font-bold mb-1">Email</div>
          <input className="input" value={email} onChange={(e) => setEmail(e.target.value)} />
        </div>

        <div className="">
          <div className="text-green-dark text-sm font-bold mb-1">Phone Number</div>
          <PhoneInput className="input" country="US" value={phone || ""} onChange={(value) => setPhone(value)} />
        </div>

        {errors.length > 0 && (
          <div className="">
            {errors.map((error) => (
              <div key={error} className="text-red-main text-sm">
                {error}
              </div>
            ))}
          </div>
        )}
      </div>
    </LayoutModal>
  );
};

export { ModalAddSupplier };
