import React from 'react';

const AngleDown = ({
  color = 'green-main',
  size = 30,
  styleProps = '',
  ...rest
}) => (
  <svg
    width={size.toString()}
    height={size.toString()}
    viewBox="0 0 75 75"
    className={`fill-${color} ${styleProps}`}
    {...rest}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.1768 18.659C-0.392268 20.2281 -0.392268 22.772 1.1768 24.3411L34.6589 57.8232C36.228 59.3923 38.772 59.3923 40.3411 57.8232L73.8232 24.3411C75.3923 22.772 75.3923 20.2281 73.8232 18.659C72.2541 17.0899 69.7102 17.0899 68.1411 18.659L37.5 49.3001L6.85891 18.659C5.28984 17.0899 2.74587 17.0899 1.1768 18.659Z"
    />
  </svg>
);

export { AngleDown };
