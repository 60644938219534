import { dateFns } from "@thrivelot/date";
import { constructUuid } from "@thrivelot/utils";

const constructPayment = ({
  id = constructUuid(),
  date = dateFns(new Date()).startOf("day").add(1, "day").toISOString(),
  calculation = [],
  markup = 0,
  paidAt = null,
  paidBy = null,
}) => ({ id, date, calculation, markup, paidAt, paidBy });

export { constructPayment };
