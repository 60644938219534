import React from "react";
import { useNotifications } from "@thrivelot/hooks";
import { Cancel, Checkmark, Info } from "@thrivelot/stories";
import { transformStringToTitleCase } from "@thrivelot/utils";

const iconMap = {
  error: Info,
  info: Info,
  success: Checkmark,
  warning: Info,
};

const colorMap = {
  error: "red-main",
  info: "blue-main",
  success: "green-main",
  warning: "orange-main",
};

const Notification = ({ notification }) => {
  const { removeNotification } = useNotifications();
  const { id, type, title, body } = notification;

  const color = colorMap[type];
  const Icon = iconMap[type];

  return (
    <div className="relative flex w-full max-w-sm mx-auto overflow-hidden rounded-lg shadow-md bg-white-light mt-4 mr-4">
      <div className={`flex items-center justify-center p-2 bg-${color}`}>
        <Icon color="white-light" />
      </div>

      <div className="py-2">
        <div className="mx-3 pr-3">
          <div className={`font-semibold mr-1 text-${color}`}>{title || transformStringToTitleCase(type)}</div>
          <div className="block text-sm text-gray-600 dark:text-gray-200">{body}</div>
        </div>
      </div>

      <div className="absolute right-2 top-2 hover:cursor-pointer" onClick={() => removeNotification(id)}>
        <Cancel color="orange-light" size={20} />
      </div>
    </div>
  );
};

export { Notification };
