import React, { useMemo } from 'react';
import { Dropdown } from '@thrivelot/stories';
import { LineItemKind } from '@thrivelot/aws';
import { lineItemColorMap, lineItemIconMap } from '@thrivelot/config';
import * as icons from '@thrivelot/stories';

const DropdownSelectLineItemKind = ({
  kind,
  onSelect,
  align = 'left',
  disabled,
}) => {
  const options = useMemo(
    () => [
      {
        icon: lineItemIconMap[LineItemKind.MATERIAL],
        label: LineItemKind.MATERIAL.toLowerCase(),
        selected: kind === LineItemKind.MATERIAL,
        onClick: () => onSelect(LineItemKind.MATERIAL),
      },
      {
        icon: lineItemIconMap[LineItemKind.LABOR],
        label: LineItemKind.LABOR.toLowerCase(),
        selected: kind === LineItemKind.LABOR,
        onClick: () => onSelect(LineItemKind.LABOR),
      },
      {
        icon: lineItemIconMap[LineItemKind.EQUIPMENT],
        label: LineItemKind.EQUIPMENT.toLowerCase(),
        selected: kind === LineItemKind.EQUIPMENT,
        onClick: () => onSelect(LineItemKind.EQUIPMENT),
      },
      {
        icon: lineItemIconMap[LineItemKind.PLANT],
        label: LineItemKind.PLANT.toLowerCase(),
        selected: kind === LineItemKind.PLANT,
        onClick: () => onSelect(LineItemKind.PLANT),
      },
    ],
    [kind, onSelect]
  );

  const Icon = icons[lineItemIconMap[kind]];

  if (disabled) return <Icon size="20" color={lineItemColorMap[kind]} />;

  return (
    <Dropdown
      label={<Icon size="20" color={lineItemColorMap[kind]} />}
      options={options}
      optionClass="font-bold"
      align={align}
      selectedColor={lineItemColorMap[kind]}
    />
  );
};

export { DropdownSelectLineItemKind };
