import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const DivWrapper = (props) => <div {...props}></div>;

export const RowListItem = ({
  to,
  onClick,
  thumbnail,
  header,
  subheader,
  details,
  children,
  ...rest
}) => {
  const Wrapper = to ? Link : DivWrapper;

  return (
    <Wrapper
      className={`flex px-4 py-2 items-center text-mulch-light bg-white-dark hover:bg-white-light ${
        onClick && 'hover:cursor-pointer'
      }`}
      to={to}
      onClick={onClick}
      {...rest}
    >
      {children || <>{thumbnail && <div className="mr-3">{thumbnail}</div>}</>}
      <div className="w-full flex-1">
        {header && <div className="font-bold">{header}</div>}
        {subheader && <div className="text-sm">{subheader}</div>}
      </div>
      {details && <div className="flex flex-col items-end">{details}</div>}
    </Wrapper>
  );
};

RowListItem.propTypes = {
  /**
   * Option to overwrite all the insides
   */
  children: PropTypes.node,

  /**
   * If provided, it renders each item as a <Link /> react router component
   */
  to: PropTypes.string,

  /**
   * If provided, it executes on item click
   */
  onClick: PropTypes.func,

  /**
   * Header string
   */
  header: PropTypes.string,

  /**
   * Subheader string
   */
  subheader: PropTypes.string,

  /**
   * Details can be a full complex component
   */
  details: PropTypes.node,
};
