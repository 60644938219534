import React from 'react';

const Pin = ({ color = 'green-main', size = 30, styleProps = '', ...rest }) => (
  <svg
    width={size.toString()}
    height={size.toString()}
    viewBox="0 0 75 75"
    className={`fill-${color} ${styleProps}`}
    {...rest}
  >
    <path d="M36.7663 74.9259C34.9997 74.6721 33.4478 73.618 32.384 72.193L14.9131 48.8464C9.9353 42.8926 7.51475 35.3284 8.08084 27.5397C9.15448 12.8309 21.0718 1.02095 35.8001 0.0644439C44.1451 -0.472372 52.0607 2.35811 58.1316 8.04837C64.1049 13.6605 67.5406 21.5761 67.5406 29.7748C67.5406 36.7436 65.0907 43.5075 60.6303 48.8464L43.0325 72.3687C41.5782 74.311 39.2357 75.287 36.7663 74.9259ZM20.0762 44.3762C20.1543 44.4543 20.2227 44.5421 20.291 44.63L36.2588 65.9757C37.0104 66.981 38.5232 66.981 39.2747 65.9757L55.2426 44.63C55.3109 44.5421 55.3792 44.4543 55.4573 44.3762C58.8441 40.2769 60.6986 35.1039 60.6986 29.7748C60.6986 23.3623 58.1219 17.4182 53.4467 13.0359C48.7715 8.6535 42.6518 6.4672 36.2295 6.88689C24.8978 7.61891 15.7232 16.7155 14.8935 28.0375C14.4641 34.0108 16.299 39.7986 20.0762 44.3762Z" />
    <path d="M39.3622 41.9263C31.3881 42.9414 24.6632 36.2068 25.6685 28.2326C26.3615 22.7961 30.7439 18.4137 36.1804 17.7208C44.1545 16.7057 50.8794 23.4403 49.8741 31.4145C49.1811 36.8509 44.7889 41.2333 39.3622 41.9263ZM39.0011 24.592C35.097 23.7233 31.6711 27.1395 32.5398 31.0533C32.979 33.0249 34.5699 34.6158 36.5415 35.0551C40.4456 35.9237 43.8715 32.5076 43.0028 28.5937C42.5636 26.6319 40.9727 25.041 39.0011 24.592Z" />
  </svg>
);

export { Pin };
