import { useMemo } from 'react';

const useFeatureItemsMap = ({ items }) => {
  const map = useMemo(() => {
    if (!items) return {};
    const result = {};

    items
      .filter((i) => !i.hidden)
      .forEach((item) => {
        result[item.featureId] = result[item.featureId] || {
          items: [],
          itemsTotalAmount: 0,
          materialItemsTotalAmount: 0,
          equipmentItemsTotalAmount: 0,
          laborItemsTotalAmount: 0,
          plantItemsTotalAmount: 0,
        };

        if (item.isHalf) {
          result[item.featureId][`${item.kind}IsHalf`] = true;
        }

        result[item.featureId][`${item.kind}ItemsTotalAmount`] =
          (result[item.featureId][`${item.kind}ItemsTotalAmount`] || 0) +
          item.totalAmount;
        result[item.featureId].itemsTotalAmount += item.totalAmount;
        result[item.featureId].items.push(item);
      });

    return result;
  }, [items]);

  return { map };
};

export { useFeatureItemsMap };
