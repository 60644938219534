import React from 'react';
import { authConfig } from '@thrivelot/config';
import {
  AuthProvider,
  NotificationsProvider,
  ThemeProvider,
} from '@thrivelot/hooks';
import { EnsureVersion } from '../../shared';
import { ErrorBoundary } from '..';

const AppContexts = ({ children }) => (
  <>
    <EnsureVersion />
    <ThemeProvider>
      <NotificationsProvider>
        <AuthProvider authConfig={authConfig}>
          <ErrorBoundary>{children}</ErrorBoundary>
        </AuthProvider>
      </NotificationsProvider>
    </ThemeProvider>
  </>
);

export { AppContexts };
