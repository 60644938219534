import React from 'react';

const Shrink = ({
  color = 'green-main',
  size = 30,
  styleProps = '',
  ...rest
}) => (
  <svg
    width={size.toString()}
    height={size.toString()}
    viewBox="0 0 75 75"
    className={`fill-${color} ${styleProps}`}
    {...rest}
  >
    <path d="M61.7089 75H13.2911C5.98101 75 0 69.019 0 61.7089V13.2911C0 5.98101 5.98101 0 13.2911 0H37.5C39.3038 0 40.8228 1.51899 40.8228 3.32278C40.8228 5.12658 39.3038 6.64557 37.5 6.64557H13.2911C9.58861 6.64557 6.64557 9.58861 6.64557 13.2911V61.7089C6.64557 65.4114 9.58861 68.3544 13.2911 68.3544H61.7089C65.4114 68.3544 68.3544 65.4114 68.3544 61.7089V37.5C68.3544 35.6962 69.8734 34.1772 71.6772 34.1772C73.481 34.1772 75 35.6962 75 37.5V61.7089C75 69.019 69.019 75 61.7089 75Z" />
    <path d="M71.8671 7.59502L43.386 36.076H52.6898C54.4936 36.076 56.0126 37.595 56.0126 39.3988C56.0126 41.2026 54.4936 42.7216 52.6898 42.7216H35.6012C33.7974 42.7216 32.2784 41.2026 32.2784 39.3988V22.3102C32.2784 20.5064 33.7974 18.9874 35.6012 18.9874C37.405 18.9874 38.924 20.5064 38.924 22.3102V31.1393L67.2152 2.94312C68.5443 1.61401 70.6329 1.61401 71.8671 2.94312C73.1962 4.1773 73.1962 6.36085 71.8671 7.59502Z" />
  </svg>
);

export { Shrink };
