import React from "react";
import { useModel } from "@thrivelot/hooks";
import { PageContainer } from "@thrivelot/stories";
import { useParams } from "react-router-dom";
import { WorkPhases, AddPhaseButton } from "../../../pages";
import { HelpPrompt } from "../..";

const PageProjectPhases = () => {
  const { projectId } = useParams();
  const { model: project, loaded } = useModel({ modelName: "Project", id: projectId });

  return (
    <PageContainer
      icon="Phases"
      header="Phases"
      documentTitle={loaded && project.details.title}
      headerActions={loaded && <AddPhaseButton projectId={projectId} />}
      help={
        <HelpPrompt header="About the Project Phases page">
          The phases section is use to organize the flow of work and payments necessary to fulfill the project in a
          timely and efficient manner, as well as communicate expectations with the Customer.
        </HelpPrompt>
      }
    >
      <WorkPhases projectId={projectId} />
    </PageContainer>
  );
};

export { PageProjectPhases };
