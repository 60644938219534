import { ApiKit } from '@thrivelot/api';
import { isEmpty } from '@thrivelot/utils';
import { constructQueryInfo } from './constructQueryInfo';
import { constructRequest } from './constructRequest';

class GraphQLKit {
  #gql;

  #queryName;

  #variables;

  #resolveItems;

  #endpoint;

  #apiKey;

  #fetchJwt;

  #queryNameRegex;

  constructor({
    gql,
    queryName,
    variables,
    resolveItems,
    endpoint,
    apiKey,
    fetchJwt,
    queryNameRegex,
  }: {
    [key: string]: any;
  }) {
    this.#init({
      gql,
      queryName,
      variables,
      resolveItems,
      endpoint,
      apiKey,
      fetchJwt,
      queryNameRegex,
    });
  }

  #init(props) {
    if (props?.gql) this.gql = props.gql;
    if (props?.queryName) this.queryName = props.queryName;
    if (props?.variables) this.variables = props.variables;
    if (props?.resolveItems) this.resolveItems = props.resolveItems;
    if (props?.endpoint) this.endpoint = props.endpoint;
    if (props?.apiKey) this.apiKey = props.apiKey;
    if (props?.fetchJwt) this.fetchJwt = props.fetchJwt;
    if (props?.queryNameRegex) this.queryNameRegex = props.queryNameRegex;
  }

  set gql(gql) {
    this.#gql = gql;
  }

  get gql() {
    return this.#gql;
  }

  set queryName(queryName) {
    this.#queryName = queryName;
  }

  get queryName() {
    return this.#queryName;
  }

  set variables(variables) {
    this.#variables = variables;
  }

  get variables() {
    return this.#variables;
  }

  set resolveItems(resolveItems) {
    this.#resolveItems = resolveItems;
  }

  get resolveItems() {
    return this.#resolveItems;
  }

  set endpoint(endpoint) {
    this.#endpoint = endpoint;
  }

  get endpoint() {
    return this.#endpoint;
  }

  set apiKey(apiKey) {
    this.#apiKey = apiKey;
  }

  get apiKey() {
    return this.#apiKey;
  }

  set fetchJwt(fetchJwt) {
    this.#fetchJwt = fetchJwt;
  }

  get fetchJwt() {
    return this.#fetchJwt;
  }

  set queryNameRegex(queryNameRegex) {
    this.#queryNameRegex = queryNameRegex;
  }

  get queryNameRegex() {
    return this.#queryNameRegex;
  }

  async request(props: { [key: string]: any } = {}) {
    this.#init(props);

    try {
      const { queryName, gql } = constructQueryInfo({
        gql: this.gql,
        queryName: this.queryName,
        queryNameRegex: this.queryNameRegex,
      });

      const req = await constructRequest({
        gql,
        variables: this.variables,
        endpoint: this.endpoint,
        apiKey: this.apiKey,
        fetchJwt: this.fetchJwt,
      });

      const apiKit = new ApiKit(req);
      const { errors, data } = await apiKit.post();

      if (errors) throw errors;

      return data[queryName];
    } catch (err) {
      console.error(
        'Error in GraphQLKit request:',
        JSON.stringify(err, null, 2)
      );
      throw err;
    }
  }

  async scan(props: { [key: string]: any } = {}) {
    this.#init(props);
    props.items ||= [];

    try {
      const data = await this.request(props);

      const newItems = data?.items?.filter(({ deleted }) => !deleted) || [];

      if (this.resolveItems && !isEmpty(newItems)) this.resolveItems(newItems);

      const items: any[] = [...props.items, ...newItems];

      if (data.nextToken && !props.scanOnce)
        return this.scan({
          ...props,
          variables: { ...this.variables, nextToken: data.nextToken },
          items,
        });

      return items;
    } catch (err) {
      console.error('Error in GraphQLKit scan:', JSON.stringify(err, null, 2));
      throw err;
    }
  }
}

export { GraphQLKit };
