import styled, { css } from "styled-components";
import chroma from "chroma-js";
import colors from "@thrivelot/tailwind/colors";
import CssDivDefault from "./CssDivDefault";

const widthConstructor = ({ width }) => (width ? `${width}px` : "14%");

const borderColor = ({ isEmpty, theme }) => {
  if (isEmpty) return "transparent";
  return theme.divLine;
};

const todayColor = ({ theme }) =>
  chroma(theme.isDarkMode ? colors.yellow.main : colors.green.main)
    .alpha(0.1)
    .css();

const selectedColor = ({ theme }) => {
  if (theme.isDarkMode) return colors.yellow.main;
  return colors.green.main;
};

const DivCalendarDay = styled.div`
  ${CssDivDefault}
  position: relative;
  min-width: ${widthConstructor};
  max-width: ${widthConstructor};
  display: flex;
  border-left: 1px solid ${borderColor};
  border-bottom: 1px solid ${({ theme }) => theme.divLine};
  ${({ isLast }) =>
    isLast &&
    css`
      border-right: 1px solid ${borderColor};
    `}
  ${({ isToday }) =>
    isToday &&
    css`
      background-color: ${todayColor};
    `}
  ${({ isSelected }) =>
    isSelected &&
    css`
      background-color: ${selectedColor};
    `}
`;

export default DivCalendarDay;
