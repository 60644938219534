import React, { Fragment, useMemo } from 'react';
import { useModel } from '@thrivelot/hooks';
import { sortComplex } from '@thrivelot/utils';
import { Payment } from './Payment';
import { Submit } from './Submit';

const CostPaymentSchedule = ({ projectId }) => {
  const { model, loaded } = useModel({ modelName: 'Project', id: projectId });

  const getPayments = () => {
    const unsortedPayments = model?.costPaymentSchedule
      ? [...model.costPaymentSchedule]
      : [];
    const sortedPayments = sortComplex({
      arr: unsortedPayments,
      nestedByIndex: 0,
      nestedBy: ['payments', 'date'],
      ascending: false,
    });

    return sortedPayments;
  };

  const payments = useMemo(getPayments, [model?.costPaymentSchedule]);

  if (!loaded) return null;

  return (
    <Fragment>
      <Submit projectId={projectId} />
      {payments.map((payment) => (
        <Payment key={payment.id} payment={payment} projectId={projectId} />
      ))}
      <div style={{ minHeight: '100px', maxHeight: '100px' }} />
    </Fragment>
  );
};

export { CostPaymentSchedule };
