import { ProjectMilestoneStage } from "@thrivelot/aws";

const sectionHeaders = ({ type, project }) => {
  let headers = [
    "Project Team",
    "Site Analysis",
    "Site Planning",
    "Plant Palette",
    "Files",
    "Calendar",
    "Project Investment",
  ];

  if (type === ProjectMilestoneStage.SITE_ASSESSMENT) {
    headers = ["Project Team", "Site Analysis", "Site Planning", "Files", "Project Investment"];
  }

  if (!project.phases || project.phases.length === 0) {
    if (headers.includes("Calendar")) headers.splice(headers.indexOf("Calendar"), 1);
  }
  if (!project.plantItems || project.plantItems.length === 0) {
    if (headers.includes("Plant Palette")) headers.splice(headers.indexOf("Plant Palette"), 1);
  }
  if (!project.files || project.files.length === 0) {
    if (headers.includes("Files")) headers.splice(headers.indexOf("Files"), 1);
  }
  if ((project.details || {}).adminNotes) {
    headers = ["Project Story", ...headers];
  }

  return headers;
};

export { sectionHeaders };
