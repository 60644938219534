import React from "react";
import { useProposal } from "@thrivelot/common";
import { contentHashmap } from "./contentHashmap";
import { InvestmentContainer } from "./InvestmentContainer";
import { PhasePaymentColumns } from "./PhasePaymentColumns";
import { Header } from "./Header";
import { Columns } from "./Columns";

const Investment = () => {
  // hooks
  const { projectActions, type } = useProposal();
  // constants
  const content = contentHashmap[type];
  // actions
  const { cost, totalCost, memo, groupedPayments } = content.calculateCost(projectActions, content.type);
  return (
    <InvestmentContainer>
      <Header title={content.title} Icon={content.Icon} cost={totalCost} />
      {groupedPayments ? (
        <PhasePaymentColumns groupedPayments={groupedPayments} Icon={content.InnerIcon} />
      ) : (
        <Columns title={content.innerTitle} memo={memo} Icon={content.InnerIcon} cost={cost} totalCost={totalCost} />
      )}
    </InvestmentContainer>
  );
};

export { Investment };
