const getFeatures = ({ tagIds, project }) => {
  if (!tagIds || !project.features) return [];

  const features = [];

  tagIds.forEach((tagId) => {
    const feature = project.features.find((e) => e.id === tagId.tagId);
    if (feature) features.push(feature);
  });

  return features;
};

export { getFeatures };
