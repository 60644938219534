import React from "react";
import { ButtonJump } from "@thrivelot/styles";
import { useSectionHeaders } from "../../hooks";

const JumpBar = () => {
  // hooks
  const sections = useSectionHeaders();
  // navigation
  const jumpTo = (id) => {
    const element = document.getElementById(id);
    if (element) element.scrollIntoView({ behavior: "smooth" });
  };
  // return
  return (
    <div className="w-full flex overflow-x-scroll">
      {sections.map((section) => (
        <ButtonJump filterSection key={section.replace(" ", "-")} onClick={() => jumpTo(section)}>
          {section}
        </ButtonJump>
      ))}
      <div style={{ minWidth: "1px" }} />
    </div>
  );
};

export { JumpBar };
