import React from 'react';

const Checkmark = ({
  color = 'green-main',
  size = 30,
  styleProps = '',
  ...rest
}) => (
  <svg
    width={size.toString()}
    height={size.toString()}
    viewBox="0 0 75 75"
    className={`fill-${color} ${styleProps}`}
    {...rest}
  >
    <path d="M37.5 75C16.8269 75 0 58.1731 0 37.5C0 16.8269 16.8269 0 37.5 0C58.1731 0 75 16.8269 75 37.5C75 58.1731 58.1731 75 37.5 75ZM37.5 5.76923C20 5.76923 5.76923 20 5.76923 37.5C5.76923 55 20 69.2308 37.5 69.2308C55 69.2308 69.2308 55 69.2308 37.5C69.2308 20 55 5.76923 37.5 5.76923Z" />
    <path d="M32.0859 50.4807H32.0763C31.1628 50.4807 30.2878 50.0961 29.6532 49.4326L23.7494 43.2307C22.4705 41.8846 22.5186 39.7499 23.8647 38.4711C25.2109 37.1923 27.3455 37.2403 28.6244 38.5865L32.1051 42.2499L46.3936 27.4615C47.682 26.1249 49.8167 26.0865 51.1532 27.3846C52.4897 28.673 52.5282 30.8076 51.2301 32.1442L34.4994 49.4519C33.8647 50.1057 32.9994 50.4807 32.0859 50.4807Z" />
  </svg>
);

export { Checkmark };
