import React from 'react';
import { useModal } from '@thrivelot/hooks';
import { Button, Edit, Lock } from '@thrivelot/stories';
import { DropdownBundleItemActions } from './DropdownBundleItemActions';
import { DropdownMultiSelectedActions } from './DropdownMultiSelectedActions';
import { ListItems } from './ListItems';
import { InputFeatureDescription } from './InputFeatureDescription';

const ListItemsLineItems = ({
  projectId,
  bundleId,
  bundleStatus,
  feature,
  items,
}) => {
  const { openModal } = useModal();
  const status = bundleStatus;

  const disabled = status !== 'unlocked';

  return (
    <div
      className={`p-4 border-t border-tan-light ${
        disabled ? '!bg-tan-light' : ''
      }`}
    >
      <div
        className={`flex justify-between items-center ${
          !disabled ? 'mb-2' : 'mb-1'
        }`}
      >
        <div className="flex items-center gap-2 font-bold">
          {disabled && <Lock size={16} color="red-main" />}
          Line Items
        </div>
        <div>
          {status === 'unlocked' && (
            <Button
              icon="Plus"
              kind="prompt"
              size="small"
              color="green-main"
              label="Line Items"
              onClick={() =>
                openModal({
                  path: 'ModalAddBundleItems',
                  bundleId,
                  projectId,
                  featureId: feature.id,
                  itemPath: 'lineItems',
                })
              }
            />
          )}
        </div>
      </div>

      <InputFeatureDescription
        bundleId={bundleId}
        path={`orderedFeatureData[prop:featureId:${feature.id}].lineItemsDescription`}
        disabled={disabled}
        placeholder="Describe the work to be done on this feature or area of the property..."
      />

      <ListItems
        itemPath="lineItems"
        disabled={disabled}
        featureId={feature.id}
        projectId={projectId}
        bundleId={bundleId}
        multiSelectDisabled={disabled}
        renderMultiSelectActions={({ selectedItemIds, onMultiSelect }) => (
          <DropdownMultiSelectedActions
            itemPath="lineItems"
            selectedItemIds={selectedItemIds}
            clearSelected={() => onMultiSelect()}
            bundleId={bundleId}
            featureId={feature.id}
            projectId={projectId}
          />
        )}
        renderItemActions={(item) => {
          if (status === 'unlocked')
            return (
              <DropdownBundleItemActions
                itemPath="lineItems"
                bundleId={bundleId}
                projectId={projectId}
                item={item}
              />
            );

          if (status === 'changes_only') return <Edit size={20} />;
        }}
      />
    </div>
  );
};

export { ListItemsLineItems };
