import React from 'react';

const Design = ({
  color = 'green-main',
  size = 30,
  styleProps = '',
  ...rest
}) => (
  <svg
    width={size.toString()}
    height={size.toString()}
    viewBox="0 0 75 75"
    className={`fill-${color} ${styleProps}`}
    {...rest}
  >
    <path d="M27.4015 18.5315L28.1515 19.6897V14.1074C26.7939 15.1422 26.4426 17.0694 27.4015 18.5315Z" />
    <path d="M28.1519 14.1076V3.32278C28.1519 1.49051 26.6709 0 24.8291 0H4.32279C2.49051 0 1 1.49051 1 3.32278V71.6772C1 73.5095 2.49051 75 4.32279 75H24.8291C26.6709 75 28.1519 73.5095 28.1519 71.6772V19.6899L27.4019 18.5316C26.443 17.0696 26.7943 15.1424 28.1519 14.1076ZM21.5063 68.3544H7.64557V56.5823H14.576C15.3639 56.5823 16 55.9462 16 55.1582C16 54.3797 15.3639 53.7342 14.576 53.7342H7.64557V49.7089H11.4051C12.193 49.7089 12.8291 49.0633 12.8291 48.2848C12.8291 47.4968 12.193 46.8608 11.4051 46.8608H7.64557V42.826H14.576C15.3639 42.826 16 42.1899 16 41.4019C16 40.6139 15.3639 39.9778 14.576 39.9778H7.64557V35.943H11.4051C12.193 35.943 12.8291 35.307 12.8291 34.519C12.8291 33.731 12.193 33.0949 11.4051 33.0949H7.64557V29.0601H14.576C15.3639 29.0601 16 28.4241 16 27.6361C16 26.8576 15.3639 26.212 14.576 26.212H7.64557V22.1867H11.4051C12.193 22.1867 12.8291 21.5506 12.8291 20.7627C12.8291 19.9747 12.193 19.3386 11.4051 19.3386H7.64557V15.3038H14.576C15.3639 15.3038 16 14.6677 16 13.8797C16 13.0918 15.3639 12.4557 14.576 12.4557H7.64557V6.64557H21.5063V68.3544Z" />
    <path d="M17.0532 65.8575C17.9917 65.8575 18.7525 65.0966 18.7525 64.1581C18.7525 63.2196 17.9917 62.4587 17.0532 62.4587C16.1146 62.4587 15.3538 63.2196 15.3538 64.1581C15.3538 65.0966 16.1146 65.8575 17.0532 65.8575Z" />
    <path d="M27.4015 18.5315L28.1515 19.6897V14.1074C26.7939 15.1422 26.4426 17.0694 27.4015 18.5315Z" />
    <path d="M75.0219 70.6614L72.7529 57.6836C72.6769 57.2468 72.506 56.8291 72.2687 56.4494L41.6326 9.28482C40.6358 7.74685 38.5757 7.31014 37.0377 8.30697L28.3795 13.9367C28.3035 13.9842 28.2181 14.0412 28.1516 14.1076V19.6899L58.0282 65.6962C58.275 66.0665 58.5883 66.3893 58.9586 66.6456L69.8953 73.9937C70.4554 74.3639 71.101 74.5538 71.7465 74.5538C72.3731 74.5538 72.9997 74.3734 73.5598 74.0222C74.6706 73.2912 75.2497 71.9715 75.0219 70.6614ZM37.8731 15.6931L41.3573 21.057L38.2624 23.0601L34.7782 17.6962L37.8731 15.6931ZM63.2307 61.5095L39.8098 25.4525L42.9048 23.4399L66.3257 59.4968L67.1326 64.1298L63.2307 61.5095Z" />
  </svg>
);

export { Design };
