import { get } from "@thrivelot/actions";

const getFileNext = (model, fileId) => {
  const files = get(model, "files");
  const index = files.findIndex((file) => file.id === fileId);
  if (index === files.length - 1) {
    return files[0];
  }
  return files[index + 1];
};

export { getFileNext };
