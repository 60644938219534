import React, { useMemo } from "react";
import { transformCurrencyToFloat, transformCurrencyToString, transformStringToTitleCase } from "@thrivelot/utils";
import { DropdownMoveFeatureItem } from "..";
import { useModel } from "@thrivelot/hooks";
import { Confirm } from "../Confirm";
import { Button } from "@thrivelot/stories";
import { colorMap, iconMap } from "../RowItemFeatureLineItem/maps";

const UnassignedLineItem = ({ projectId, lineItemId, featureId }) => {
  const { updateModel, actions } = useModel({ modelName: "Project", id: projectId });
  const lineItem = useMemo(() => actions.get(`lineItems[id:${lineItemId}]`), [lineItemId, actions]);
  const deleteLineItem = () => updateModel(actions.remove(`lineItems[id:${lineItemId}]`).result);

  if (!lineItem) return null;

  const { name, quantity, cost, type, unit } = lineItem;

  const numberCost = Math.ceil((quantity * cost) / 100) * 100;
  const totalCost = transformCurrencyToString(numberCost);

  const Icon = iconMap[type];

  return (
    <div className="p-3 flex" data-line-item-id={lineItemId}>
      <div className="flex items-center flex-1 min-w-0">
        <div className="mr-1">
          <DropdownMoveFeatureItem
            projectId={projectId}
            currentFeatureId={featureId}
            itemId={lineItemId}
            itemBasePath="lineItems"
          />
        </div>
        <div className="ml-2 mr-3">
          <Icon size="20" color={colorMap[type]} />
        </div>
        <div className="break-words text-bold text-brown-dark flex-1 min-w-0 mr-2">
          <div className="text-ellipsis overflow-hidden whitespace-nowrap">{name}</div>
        </div>
      </div>

      <div className="flex items-center">
        <div className={`ml-4 font-bold text-${colorMap[type]}`}>$ {transformCurrencyToFloat(cost).toString()}</div>

        <div className="ml-4 text-brown-dark font-bold">
          {" "}
          x {quantity} <span className="font-normal text-sm">{transformStringToTitleCase(unit, true)}</span>
        </div>

        <div className="ml-4 text-right text-green-main font-bold text-lg ">{totalCost}</div>

        <div className="ml-5 mr-2">
          <Confirm prompt="Are you sure you want to delete this line item?" onConfirm={deleteLineItem}>
            <Button icon="Trash" kind="prompt" size="small" color="red-main" label="" />
          </Confirm>
        </div>
      </div>
    </div>
  );
};

export { UnassignedLineItem };
