import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import { useFetchFileUrl } from "@thrivelot/common";
import CssImageDefault from "./CssImageDefault";

const sizeConstructor = ({ size }) => `${size}px`;

const Image = styled.img`
  overflow: hidden;
  object-position: 50% 50%;
  object-fit: cover;
  min-height: ${sizeConstructor};
  max-height: ${sizeConstructor};
  min-width: ${sizeConstructor};
  max-width: ${sizeConstructor};
  border-radius: ${sizeConstructor};
  ${CssImageDefault}
`;

const ImgCircle = ({ src: srcObj, size, imgType, onLoadError, ...rest }) => {
  const [src, setSrc] = useState(
    "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkqAcAAIUAgUW0RjgAAAAASUVORK5CYII="
  );

  const fetchUrl = useFetchFileUrl();

  useEffect(() => {
    let didCancel;

    fetchUrl(srcObj).then((data) => {
      if (!didCancel) setSrc(data);
    });

    return () => {
      didCancel = true;
    };
  }, [srcObj, fetchUrl]);

  const onLoad = useCallback((event) => {
    event.target.classList.add("loaded");
  }, []);

  const onError = useCallback(
    (event) => {
      event.target.classList.add("has-error");

      if (onLoadError) onLoadError();
    },
    [onLoadError]
  );

  return <Image src={src} alt="" onLoad={onLoad} onError={onError} size={size} {...rest} />;
};

export default ImgCircle;
