import React from "react";
import colors from "@thrivelot/tailwind/colors";
import { useTheme, useMobileDifference } from "@thrivelot/hooks";
import { TextBody, TextBodyBold } from "@thrivelot/styles";
import { LeftColumnContainer } from "./LeftColumnContainer";

const LeftColumn = ({ title, memo, Icon }) => {
  const theme = useTheme();
  const iconSize = useMobileDifference({ desktop: 20, mobile: 15 });
  const fontSize = useMobileDifference({ desktop: "21px", mobile: "17px" });
  return (
    <LeftColumnContainer>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          marginBottom: "12px",
        }}
      >
        <Icon color={colors.yellow.dark} size={iconSize} />
        <TextBodyBold style={{ color: theme.textTitle, fontSize, marginLeft: "12px" }}>{title}</TextBodyBold>
      </div>
      <TextBody style={{ marginLeft: `${iconSize + 12}px` }}>{memo}</TextBody>
    </LeftColumnContainer>
  );
};

export { LeftColumn };
