import styled, { css } from "styled-components";
import CssTextDefault from "./CssTextDefault";

const withInfoConstructor = ({ withInfo }) => {
  if (withInfo)
    return css`
      align-items: center;
    `;

  return css`
    top: 12px;
  `;
};

const TextProjectTitle = styled.span`
  ${CssTextDefault}
  ${withInfoConstructor}
  margin-top: 3px;
  display: flex;
  font-family: Poppins-Bold;
  color: ${({ theme }) => theme.textTitle};
  @media (max-width: 767px) {
    font-size: 14px;
  }
  @media (min-width: 768px) {
    font-size: 21px;
  }
`;

export default TextProjectTitle;
