import React from 'react';

const Image = ({
  color = 'green-main',
  size = 30,
  styleProps = '',
  ...rest
}) => (
  <svg
    width={size.toString()}
    height={size.toString()}
    viewBox="0 0 75 75"
    className={`fill-${color} ${styleProps}`}
    {...rest}
  >
    <path d="M64.6875 0H10.3125C4.625 0 0 4.60072 0 10.2584V64.7416C0 70.3993 4.625 75 10.3125 75H64.6875C70.375 75 75 70.3993 75 64.7416V10.2584C75 4.60072 70.375 0 64.6875 0ZM3.95833 10.2584C3.95833 6.77673 6.79167 3.95828 10.2917 3.95828H64.6667C68.1667 3.95828 71 6.77673 71 10.2584V31.2725C67.7083 29.9047 64.2917 29.1793 60.8125 29.0964C60.625 29.0964 60.4375 29.0964 60.2708 29.0964C48.2292 29.0964 39.9583 37.0337 36.8958 40.5568C34.0417 38.8367 27.4167 35.5623 15.0208 36.3498C10.4583 36.64 6.625 37.5311 3.9375 38.8367V10.2584H3.95833ZM10.3125 71.0417C6.8125 71.0417 3.97917 68.2233 3.97917 64.7416V41.593C5.83333 40.4117 9.375 39.1061 15.2083 38.7331C28.375 37.8627 34.5208 41.8209 36.5208 43.1058C36.5417 43.1266 36.5625 43.1473 36.5833 43.1473C48.6875 50.9809 51.3542 65.1976 51.9375 71.0417H10.3125ZM71.0417 64.7416C71.0417 68.2233 68.1875 71.0417 64.7083 71.0417H54.375C53.8125 65.1561 51.1667 50.4628 38.9583 41.8831C41.7292 38.816 49.4583 31.4797 60.3333 31.4797C60.5 31.4797 60.6667 31.4797 60.8125 31.4797C64.3125 31.5419 67.75 32.3501 71.0417 33.8629V64.7416Z" />
    <path d="M21.0415 26.941C17.2707 26.941 14.1874 23.8738 14.1874 20.1021C14.1874 16.3303 17.2499 13.2632 21.0415 13.2632C24.8332 13.2632 27.8957 16.3303 27.8957 20.1021C27.8957 23.8738 24.8332 26.941 21.0415 26.941ZM21.0415 17.2215C19.4582 17.2215 18.1665 18.5271 18.1665 20.1021C18.1665 21.6771 19.4582 22.9827 21.0415 22.9827C22.6249 22.9827 23.9165 21.6771 23.9165 20.1021C23.9165 18.5271 22.6457 17.2215 21.0415 17.2215Z" />
  </svg>
);

export { Image };
