import { add, get, remove, set } from '@thrivelot/actions';
import { constructUuid } from '@thrivelot/utils';

function actions(model) {
  return {
    result: model,
    add(path, value, index = -1) {
      this.result = add(this.result, path, value, index);
      return this;
    },
    remove(path, value) {
      this.result = remove(this.result, path, value);
      return this;
    },
    set(path, value) {
      this.result = set(this.result, path, value);
      return this;
    },
    get: (path, defaultValue = null) => get(model, path, defaultValue),
    constructUuid: () => constructUuid(),
  };
}

export { actions };
