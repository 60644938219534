import { useIsMobile, useModel, useFiles } from '@thrivelot/hooks';
import { Button } from '@thrivelot/stories';
import { LeafLoader } from '@thrivelot/stories';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import AvatarEditor from 'react-avatar-editor';
import { LayoutModal } from '../..';
import { convertHeicToPng } from '../ModalAddFiles/convertHeicToPng';

const getBlobFromCanvas = (canvas, file) =>
  new Promise((resolve, reject) => {
    canvas.toBlob((blob) => {
      if (blob) {
        blob.name = file.name;
        blob.lastModified = file.lastModified;

        resolve({ blob });
      } else {
        reject(new Error('Canvas is empty'));
      }
    }, file.type);
  });

const ModalAddSupplierPic = ({ onClose, supplierId }) => {
  const [uploading, setUploading] = useState(false);
  const [file, setFile] = useState();
  const [dataUrl, setDataUrl] = useState();
  const [signedUrl, setSignedUrl] = useState();
  const {
    model: supplier,
    actions,
    updateModel,
  } = useModel({ modelName: 'Supplier', id: supplierId });
  const { fetchUrl, upload } = useFiles();
  const isMobile = useIsMobile();

  const cropRef = useRef();

  useEffect(() => {
    if (!supplier?.pic?.key) return;

    const fetchSignedUrl = async () => {
      const { data } = await fetchUrl(supplier.pic.key);
      setSignedUrl(data);
    };

    fetchSignedUrl();
  }, [fetchUrl, supplier?.pic?.key]);

  useEffect(() => {
    if (!signedUrl) return;

    fetch(signedUrl)
      .then((res) => res.blob())
      .then((acceptedFile) => {
        console.log(acceptedFile);
        const reader = new FileReader();
        const file = {
          path: acceptedFile.path,
          name: supplier.pic.filename,
          size: acceptedFile.size,
          type: supplier.pic.type,
        };
        setFile(file);

        reader.onabort = () => console.log('file reading was aborted');
        reader.onerror = () => console.log('file reading has failed');
        reader.onload = () => {
          const dataUrl = reader.result;
          setDataUrl(dataUrl);
          setUploading(false);
        };

        if (acceptedFile.type === 'image/heic')
          convertHeicToPng(acceptedFile).then((blob) =>
            reader.readAsDataURL(blob)
          );
        else reader.readAsDataURL(acceptedFile);
      });
  }, [signedUrl, supplier?.pic]);

  const onDrop = useCallback((acceptedFiles) => {
    setUploading(true);
    acceptedFiles.forEach((acceptedFile) => {
      console.log(acceptedFile);
      const reader = new FileReader();
      const file = {
        path: acceptedFile.path,
        name: acceptedFile.name,
        size: acceptedFile.size,
        type: acceptedFile.type,
      };
      setFile(file);

      reader.onabort = () => console.log('file reading was aborted');
      reader.onerror = () => console.log('file reading has failed');
      reader.onload = () => {
        const dataUrl = reader.result;
        setDataUrl(dataUrl);
        setUploading(false);
      };

      if (acceptedFile.type === 'image/heic')
        convertHeicToPng(acceptedFile).then((blob) =>
          reader.readAsDataURL(blob)
        );
      else reader.readAsDataURL(acceptedFile);
    });
  }, []);

  const uploadCroppedImage = useCallback(() => {
    setUploading(true);
    const canvas = cropRef.current.getImage();
    return getBlobFromCanvas(canvas, file).then(({ blob }) => {
      console.log(blob);

      const reader = new FileReader();
      const file = {
        path: blob.path,
        name: blob.name,
        size: blob.size,
        type: blob.type,
      };

      reader.onabort = () => console.log('file reading was aborted');
      reader.onerror = () => console.log('file reading has failed');
      reader.onload = () => {
        const dataUrl = reader.result;
        return upload({
          filename: file.name,
          metadata: { fileType: file.type },
          data: dataUrl.replace(/^data:.+,/, ''),
        }).then(({ data: { file } }) => {
          updateModel(actions.set('pic', file).result);
          setUploading(false);
          onClose();
        });
      };
      reader.readAsDataURL(blob);
    });
  }, [file, actions, onClose, updateModel, upload]);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  return (
    <LayoutModal
      onClose={onClose}
      header={<div className="font-bold text-brown-dark">Add Profile Pic</div>}
      footer={
        file && dataUrl && !uploading ? (
          <div className="flex w-full">
            <Button block color="blue-main" onClick={uploadCroppedImage}>
              Upload
            </Button>
          </div>
        ) : null
      }
    >
      {uploading ? (
        <LeafLoader />
      ) : (
        <>
          {file && dataUrl && (
            <div className="flex items-center justify-center mb-3">
              <AvatarEditor
                ref={cropRef}
                image={dataUrl}
                width={isMobile ? 200 : 400}
                height={isMobile ? 200 : 400}
                borderRadius={isMobile ? 200 : 400}
              />
            </div>
          )}
          <div {...getRootProps()}>
            <input {...getInputProps()} />
            <div className="w-full h-36 flex items-center justify-center border-dashed border-2 border-brown-light p-5 text-brown-light hover:cursor-pointer focus:outline-none focus:ring-none">
              {isDragActive
                ? 'Drop photo here'
                : "Drag 'n' drop a photo here, or click to select a photo"}
            </div>
          </div>
        </>
      )}
    </LayoutModal>
  );
};

export { ModalAddSupplierPic };
