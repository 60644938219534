import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { isNumber } from "@thrivelot/utils";

const parseHash = (hashstring = "") => {
  const hash = {};

  const splitHash = hashstring.substr(1).split(",");

  splitHash.forEach((item) => {
    let key = item;
    let value = true;

    if (item.includes("=")) {
      const keyValuePair = item.split("=");
      [key, value] = keyValuePair;

      if (isNumber(value)) value = parseFloat(value);
    }

    hash[key] = value;
  });

  return hash;
};

const useHash = () => {
  const location = useLocation();
  const [hash, setHash] = useState(parseHash(location.hash));

  useEffect(() => setHash(parseHash(location.hash)), [location.hash]);

  return hash;
};

export { useHash };
