import React from "react";
import { Plus } from "@thrivelot/stories";

const ResultItem = ({ description, matchedSubstrings, placeId, onSelect }) => {
  const { length, offset } = matchedSubstrings[0];

  const pre = description.substring(0, offset);
  const match = description.substring(offset, length);
  const post = description.substring(length);

  return (
    <div
      className="text-brown-dark py-2 text-sm hover:bg-white-light hover:cursor-pointer flex items-center"
      onClick={() => onSelect(placeId)}
    >
      <div className="flex-1 flex items-center">
        {pre}
        <span className="font-bold">{match}</span>
        {post}
      </div>
      <Plus size={18} color="blue-main" />
    </div>
  );
};

export { ResultItem };
