import parseFindByVarForPropertyNameAndValue from "./parseFindByVarForPropertyNameAndValue";
import findValueWithIndex from "./findValueWithIndex";
import propertyComparison from "./propertyComparison";

const findObjectInArrayByProperty = (deepCopiedObj, currentPath, findByVar, returnWithIndex) => {
  const { name, value } = parseFindByVarForPropertyNameAndValue(findByVar);
  if (!returnWithIndex) return deepCopiedObj[currentPath].find((item) => propertyComparison(item, name, value));
  return findValueWithIndex(deepCopiedObj, currentPath, name, value);
};

export default findObjectInArrayByProperty;
