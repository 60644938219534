import React from 'react';
import { useModelKitProvider } from './useModelKitProvider';
import { ModelKitContext } from './ModelKitContext';
import { ModelProvider } from './ModelProvider';

const ModelKitProvider = ({ authConfig, modelConfig, auth, children }) => {
  const modelKitProvider = useModelKitProvider({
    authConfig,
    modelConfig,
    auth,
  });

  return (
    <ModelKitContext.Provider value={modelKitProvider}>
      <ModelProvider>{children}</ModelProvider>
    </ModelKitContext.Provider>
  );
};

export { ModelKitProvider };
