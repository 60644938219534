const calculateItemPriceTotal = ({ pricePerUnit, quantity }) =>
  Math.round((pricePerUnit || 0) * (quantity || 0));
const calculateItemCostTotal = ({ costPerUnit, quantity }) =>
  Math.round((costPerUnit || 0) * (quantity || 0));
export const calculatePercentTotal = (num, percent) =>
  percent === 0 ? 0 : Math.round((num || 0) * ((percent || 100) / 100));

export const constructItemWithTotals = (item, housePercent, bundle, type) => {
  const costSubtotal = calculateItemCostTotal(item);
  const priceSubtotal = calculateItemPriceTotal(item);
  const houseSubtotal = housePercent
    ? calculatePercentTotal(priceSubtotal, housePercent)
    : 0;
  const totalAmount = priceSubtotal + houseSubtotal;

  const result = {
    ...item,
    type,
    bundleId: bundle?.id,
    supplierPayeeId: bundle?.supplierPayeeId,
    priceSubtotal,
    costSubtotal,
    houseSubtotal,
    totalAmount,
  };

  // For time_materials_billing
  if (item.kind === 'labor') {
    result.halfPriceSubtotal = calculatePercentTotal(priceSubtotal, 50);
    result.halfHouseSubtotal = calculatePercentTotal(houseSubtotal, 50);
    result.halfTotalAmount =
      result.halfPriceSubtotal + result.halfHouseSubtotal;
  }

  return result;
};
