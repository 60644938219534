import { isEmpty } from "@thrivelot/utils";
import { get } from "@thrivelot/actions";

const getSupplierName = (model) => {
  const businessName = get(model, "businessName");
  if (!isEmpty(businessName)) {
    return businessName.trim();
  }
  const firstname = get(model, "firstname");
  const lastname = get(model, "lastname");
  const name = `${firstname?.trim() || ""} ${lastname?.trim() || ""}`;
  if (!isEmpty(name.trim())) {
    return name;
  }
  return "No supplier name";
};

export { getSupplierName };
