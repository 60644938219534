import { findObjectInArrayByProperty } from './findObjectInArrayByProperty';

export const findNewObjectByPath = (
  obj: any,
  currentPath: string,
  findByVar?: string,
  returnWithIndex?: boolean
): any => {
  if (findByVar) return findObjectInArrayByProperty(obj, currentPath, findByVar, returnWithIndex);
  return obj[currentPath];
};
