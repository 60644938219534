import React, { useMemo } from 'react';
import {
  useCanCan,
  useModal,
  useModel,
  useMultiSelect,
} from '@thrivelot/hooks';
import {
  Button,
  EmptyState,
  LeafLoader,
  CheckFull,
  CheckEmpty,
  CheckMinus,
} from '@thrivelot/stories';
import { RowItemProjectFile } from '../RowItemProjectFile';
import { DropdownMultiSelectedActions } from './DropdownMultiSelectedActions';

export const ListFiles = ({ projectId, fileFolder }) => {
  const { model: project, loaded } = useModel({
    modelName: 'Project',
    id: projectId,
  });
  const { openModal } = useModal();
  const { canEdit } = useCanCan(project);

  const filesInFolder = useMemo(() => {
    if (!project?.files.length) return [];

    return (fileFolder?.fileIds || []).map((fileId) =>
      (project?.files || []).find((file) => file.id === fileId)
    );
  }, [project?.files, fileFolder]);

  const {
    selectedOption,
    allOfOptionAreSelected,
    onMultiSelect,
    selectedItemIds,
    itemIsSelected,
    setItemSelection,
  } = useMultiSelect({
    items: filesInFolder,
  });

  if (!loaded)
    return (
      <div>
        <LeafLoader />
      </div>
    );

  if (filesInFolder.length === 0)
    return (
      <EmptyState
        icon="FilesFolder"
        header="No files in this folder yet..."
        children={
          <div>
            Note that this folder won't be shown to the customer without files
          </div>
        }
        footer={
          <div>
            <Button
              color="blue-main"
              kind="outline"
              icon="Plus"
              label="Add File"
              onClick={() =>
                openModal({
                  path: 'ModalAddFiles',
                  modelName: 'Project',
                  modelId: projectId,
                  filesPath: 'files',
                  allowMultiple: true,
                  fileFolderId: fileFolder.id,
                })
              }
            />
          </div>
        }
      />
    );

  return (
    <div className="p-4">
      {canEdit && (
        <div className="flex items-center mb-2 gap-6 h-10">
          {selectedOption === 'all' && allOfOptionAreSelected && (
            <CheckFull
              size={20}
              onClick={() => onMultiSelect()}
              styleProps="hover:cursor-pointer"
            />
          )}
          {!selectedOption &&
            selectedItemIds.length === filesInFolder.length && (
              <CheckMinus
                size={20}
                onClick={() => onMultiSelect()}
                styleProps="hover:cursor-pointer"
              />
            )}
          {!selectedOption &&
            selectedItemIds.length !== filesInFolder.length && (
              <CheckEmpty
                size={20}
                onClick={() => onMultiSelect('all')}
                styleProps="hover:cursor-pointer"
              />
            )}
          <DropdownMultiSelectedActions
            projectId={projectId}
            selectedItemIds={selectedItemIds}
            clearSelected={() => onMultiSelect()}
            folderId={fileFolder.id}
          />
        </div>
      )}
      <div className="divide-y divide-tan-light">
        {filesInFolder.map((file) => (
          <div key={file.id} className="flex items-center gap-3 w-full">
            {canEdit && (
              <>
                {itemIsSelected(file.id) ? (
                  <CheckFull
                    size={20}
                    onClick={() => setItemSelection(file.id, false)}
                    styleProps="hover:cursor-pointer"
                  />
                ) : (
                  <CheckEmpty
                    size={20}
                    onClick={() => setItemSelection(file.id, true)}
                    styleProps="hover:cursor-pointer"
                  />
                )}
              </>
            )}
            <RowItemProjectFile
              projectId={projectId}
              file={file}
              folderId={fileFolder.id}
              classAppend="!bg-white-light"
            />
          </div>
        ))}
      </div>
    </div>
  );
};
