import { useCallback, useContext, useMemo } from "react";
import { ProposalContext } from "./ProposalContext";
import { actions } from "./actions";

const constructSupplierActions = (suppliers, id) => {
  const supplier = suppliers.find((sup) => sup && sup.id === id);

  return actions(supplier);
};

const useProposal = () => {
  const { customer, project, suppliers, loading, type } = useContext(ProposalContext);

  const customerActions = useMemo(() => actions(customer), [customer]);

  const projectActions = useMemo(() => actions(project), [project]);

  const supplierActions = useCallback((id) => constructSupplierActions(suppliers, id), [suppliers]);

  return {
    customer,
    project,
    suppliers,
    customerActions,
    projectActions,
    supplierActions,
    loading,
    type,
  };
};

export { useProposal };
