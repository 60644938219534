import React from 'react';
import { useModelList } from '@thrivelot/hooks';
import { RowItemLoader } from '@thrivelot/stories';
import { ServiceProvider } from './ServiceProvider';

const ServiceProviderFromOwnerId = ({ ownerId, renderDetails }) => {
  const { models, loaded, loading } = useModelList({
    modelName: 'Supplier',
    filter: { owners: { contains: ownerId } },
  });

  if (!loaded || loading)
    return <RowItemLoader classAppend="!px-0 !py-0 !bg-transparent" />;

  const supplier = models[0];

  return <ServiceProvider supplier={supplier} renderDetails={renderDetails} />;
};

export { ServiceProviderFromOwnerId };
