import { useFiles } from "@thrivelot/hooks";
import React, { useEffect, useState } from "react";
import Image from "react-graceful-image";

const ImageFetched = ({ fileKey, filename, loader, ...rest }) => {
  const [signedUrl, setSignedUrl] = useState();
  const { fetchUrl } = useFiles();

  useEffect(() => {
    const fetchSignedUrl = async () => {
      const { data } = await fetchUrl(fileKey);
      setSignedUrl(data);
    };
    fetchSignedUrl();
  }, [fetchUrl, fileKey]);

  if (!signedUrl) return loader || null;

  return <Image src={signedUrl} alt={filename} {...rest} />;
};

export { ImageFetched };
