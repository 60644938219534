import React from 'react';
import { useModelList } from '@thrivelot/hooks';

const InvoiceServiceProviderInfo = ({ ownerId, renderDetails }) => {
  const { models, loaded, loading } = useModelList({
    modelName: 'Supplier',
    filter: { owners: { contains: ownerId } },
  });

  if (!loaded || loading || !models?.length || !ownerId) return;

  const supplier = models[0];
  const { businessName, address } = supplier || {};
  const { line1, line2, city, state, zip } = address || {};

  return (
    <div className="text-brown-dark">
      <div className="text-2xl">{businessName}</div>
      <div>
        <div>{line1}</div>
        <div>{line2}</div>
        <div>
          {city}, {state} {zip}
        </div>
      </div>
    </div>
  );
};

export { InvoiceServiceProviderInfo };
