import React from "react";
import { useCanCan, useModel } from "@thrivelot/hooks";
import { Button, PageContainer } from "@thrivelot/stories";
import { useParams } from "react-router-dom";
import { CardSiteAnalysisNote, CardAdminNotes, CardProjectNotes, HelpPrompt } from "../..";

const PageProjectNotes = () => {
  const { projectId } = useParams();
  const { model: project, loaded, actions, updateModel } = useModel({ modelName: "Project", id: projectId });
  const { canEdit } = useCanCan(project);

  return (
    <PageContainer
      icon="Sketch"
      header="Notes"
      documentTitle={loaded && project.details.title}
      headerActions={
        canEdit &&
        loaded && (
          <Button
            icon="Plus"
            size="small"
            color="blue-main"
            kind="outline"
            label="Add Site Note"
            onClick={() => updateModel(actions.add("siteAnalysis", actions.constructNote({}), 0).result)}
          />
        )
      }
      help={
        <HelpPrompt header="About the Project Notes page">
          This section contains the Project Story for the Customer, Project Notes not visible to the Customer (for
          internal use), and all Site Notes created to record observations and recommendations for the property during
          the Assess stage.
        </HelpPrompt>
      }
    >
      <div className="columns-1 lg:columns-2">
        {canEdit && <CardProjectNotes projectId={projectId} />}
        <CardAdminNotes projectId={projectId} />
        {loaded &&
          (project.siteAnalysis || []).map(({ id }) => (
            <CardSiteAnalysisNote key={id} projectId={projectId} siteAnalysisId={id} />
          ))}
      </div>
    </PageContainer>
  );
};

export { PageProjectNotes };
