import React from "react";
import { PicAndNameContainer } from "./PicAndNameContainer";
import { Pic } from "./Pic";
import { Name } from "./Name";

const PicAndName = ({ plantInfo }) => (
  <div className="max-w-md">
    <PicAndNameContainer>
      <Pic plantInfo={plantInfo} />
      <Name plantInfo={plantInfo} />
    </PicAndNameContainer>
  </div>
);

export { PicAndName };
