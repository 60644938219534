import styled from "styled-components";

const FileContainer = styled.div`
  overflow: hidden;
  position: fixed;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
`;

export { FileContainer };
