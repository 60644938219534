import React from 'react';
import { useModel } from '@thrivelot/hooks';
import { PageContainer } from '@thrivelot/stories';
import { useParams } from 'react-router-dom';
import { HelpPrompt, LinkBack } from '../..';
import { ViewPlantPalette } from '../../ViewPlantPalette';

export const PageViewInvoiceProposalPlantPalette = () => {
  const { projectId, invoiceProposalId } = useParams();

  const { model: project } = useModel({ modelName: 'Project', id: projectId });
  const { model: invoiceProposal, loaded } = useModel({
    modelName: 'InvoiceProposal',
    id: invoiceProposalId,
  });

  return (
    <PageContainer
      icon="Leaf"
      header="Plant Palette"
      preheader={
        <LinkBack
          label="Back to Order"
          to={`/projects/${projectId}/orders/${invoiceProposalId}`}
        />
      }
      subheader={invoiceProposal?.name}
      documentTitle={loaded && project?.details?.title}
      help={
        <HelpPrompt header="About the Project Invoices page">
          Here you can view the Plant Palette for your Order.
        </HelpPrompt>
      }
    >
      <div className="m-auto max-w-6xl">
        <ViewPlantPalette
          projectId={projectId}
          invoiceProposalId={invoiceProposalId}
        />
      </div>
    </PageContainer>
  );
};
