import { isNumber } from './isNumber';

const transformCurrencyToInt = (currency = '') => {
  let safeCurrency = currency;
  if (typeof currency === 'number') safeCurrency = currency.toString();

  let currencyStr = '';

  for (let i = 0; i < safeCurrency.length; i += 1) {
    const char = safeCurrency.charAt(i);

    if (isNumber(char) || char === '.') currencyStr += char;
  }

  const currencyFloat = parseFloat(currencyStr) * 100;
  return Math.round(currencyFloat);
};

export { transformCurrencyToInt };
