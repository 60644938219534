import styled from "styled-components";
import CssDivDefault from "./CssDivDefault";

const DivProjectTopBar = styled.div`
  ${CssDivDefault}
  @media (max-width: 767px) {
    padding: 8px 12px;
  }
  @media (min-width: 768px) {
    padding: 12px;
  }
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.foreground};
  border-bottom: 1px solid ${({ theme }) => theme.divLineDark};
`;

export default DivProjectTopBar;
