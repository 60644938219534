import React from "react";
import { Card } from "@thrivelot/stories";
import { CardLoader } from "@thrivelot/stories";
import { InputPhoneNumber } from "@thrivelot/styles";
import { ProfilePic } from "./ProfilePic";
import { constructAddressString } from "@thrivelot/common";
import { useModal, useModel } from "@thrivelot/hooks";
import { Edit } from "@thrivelot/stories";

const CardSupplierPersonal = ({ supplierId }) => {
  const { openModal } = useModal();
  const { model: supplier, updateModel: updateSupplier, loaded } = useModel({ modelName: "Supplier", id: supplierId });

  if (!loaded) return <CardLoader />;

  const formattedAddress = constructAddressString(supplier.address);

  return (
    <Card header="Personal" classAppend="mb-4">
      <div className="flex flex-col gap-4">
        <div className="flex justify-center items-center">
          <ProfilePic supplierId={supplierId} />
        </div>
        <div>
          <div className="text-green-dark text-sm font-bold">DBA</div>
          <input
            className="input"
            placeholder="Business Name"
            value={supplier.businessName || ""}
            onChange={(e) => updateSupplier({ businessName: e.target.value })}
          />
        </div>
        <div>
          <div className="text-green-dark text-sm font-bold">First Name</div>
          <input
            className="input"
            placeholder="First name"
            value={supplier.firstname || ""}
            onChange={(e) => updateSupplier({ firstname: e.target.value })}
          />
        </div>
        <div>
          <div className="text-green-dark text-sm font-bold">Last Name</div>
          <input
            className="input"
            placeholder="Last name"
            value={supplier.lastname || ""}
            onChange={(e) => updateSupplier({ lastname: e.target.value })}
          />
        </div>

        <div>
          <div className="text-green-dark text-sm font-bold">Email</div>
          <input
            className="input"
            placeholder="Email"
            value={supplier.email || ""}
            onChange={(e) => updateSupplier({ email: e.target.value })}
          />
        </div>

        <div>
          <div className="text-green-dark text-sm font-bold">Phone</div>
          <InputPhoneNumber
            placeholder="Phone number"
            className="input"
            value={supplier.phoneNumber || ""}
            onChange={(value) => updateSupplier({ phoneNumber: value })}
          />
        </div>

        <div>
          <div className="flex justify-between items-center">
            <div className="text-green-dark text-sm font-bold">Address</div>
            <Edit
              color="yellow-dark"
              styleProps="hover:cursor-pointer"
              size="16"
              onClick={() =>
                openModal({
                  path: "ModalEditAddress",
                  modelName: "Supplier",
                  modelId: supplierId,
                  addressPath: "address",
                })
              }
            />
          </div>
          <div className="text-brown-dark">{formattedAddress || <span className="italic">No address...</span>}</div>
        </div>
      </div>
    </Card>
  );
};

export { CardSupplierPersonal };
