import { calculatePaymentTotal } from "./calculatePaymentTotal";

const calculatePaymentMarkup = (payment, project) => {
  const total = Math.ceil(calculatePaymentTotal(payment, project) / 100) * 100;
  const subtotal = Math.ceil(calculatePaymentTotal(payment, project, true) / 100) * 100;

  return total - subtotal;
};

export { calculatePaymentMarkup };
