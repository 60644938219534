import { Auth } from "@thrivelot/aws-amplify";

const login = async () => {
  try {
    const user = await Auth.currentAuthenticatedUser();
    if (user) return;
  } catch (err) {
    console.log("Not logged in.");
  }

  try {
    const user = await Auth.signIn("customer@thrivelot.com", "customer");
    if (user.challengeName === "NEW_PASSWORD_REQUIRED" || user.challengeName === "FORCE_CHANGE_PASSWORD") {
      await Auth.completeNewPassword(user, "customer");
      await Auth.signIn("customer@thrivelot.com", "customer");
    }
  } catch (err) {
    console.log(err);
  }
};

export { login };
