import React from "react";
import { useAuth, useModal } from "@thrivelot/hooks";
import { Button, Card } from "@thrivelot/stories";
import { Edit } from "@thrivelot/stories";
import { formatPhoneNumber } from "react-phone-number-input";

const CardAccount = () => {
  const { openModal } = useModal();
  const { user, signOut } = useAuth();

  const handleLogout = () => {
    signOut();
  };

  return (
    <Card
      header="Account"
      headerActions={
        <Edit
          color="yellow-dark"
          styleProps="hover:cursor-pointer"
          size="20"
          onClick={() => openModal({ path: "ModalEditAccount" })}
        />
      }
      footer={
        <>
          <Button kind="outline" block color="blue-main" onClick={() => openModal({ path: "ModalEditPassword" })}>
            Update Password
          </Button>
          <div className="p-1" />
          <Button color="red-main" block kind="outline" onClick={handleLogout}>
            Log Out
          </Button>
        </>
      }
    >
      <div className="flex mb-2">
        <div className="text-green-dark font-bold mr-2">Email</div>
        <div className="text-brown-dark font-bold flex-1">{user.attributes.email}</div>
        {!user.attributes.email_verified && (
          <Button
            kind="prompt"
            color="green-main"
            onClick={() => openModal({ path: "ModalVerifyUserAttribute", attribute: "email" })}
          >
            <span className="text-sm">Verify</span>
          </Button>
        )}
      </div>
      <div className="flex">
        <div className="text-green-dark font-bold mr-2">Phone</div>
        <div className="text-brown-dark font-bold flex-1">{formatPhoneNumber(user.attributes.phone_number)}</div>
        {!user.attributes.phone_number_verified && (
          <Button
            kind="prompt"
            color="green-main"
            onClick={() => openModal({ path: "ModalVerifyUserAttribute", attribute: "phone_number" })}
          >
            <span className="text-sm">Verify</span>
          </Button>
        )}
      </div>
    </Card>
  );
};

export { CardAccount };
