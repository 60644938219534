const urlFolders = {
  auth: "auth",
  customer: "customer",
  customers: "customers",
  home: "home",
  profile: "profile",
  project: "project",
  projects: "projects",
  proposal: "proposal",
  search: "search",
  settings: "settings",
  supplier: "supplier",
  suppliers: "suppliers",
};

export { urlFolders };
