import { useCallback } from "react";
import { Storage } from "@thrivelot/aws-amplify";

const useFetchFileUrl = () => {
  const fetchFileUrl = useCallback(async (src) => {
    if (src.url) return src.url;

    try {
      const url = await Storage.get(src.key);
      return url;
    } catch (err) {
      console.error(JSON.stringify(err, null, 2));
      throw err;
    }
  }, []);

  return fetchFileUrl;
};

export { useFetchFileUrl };
