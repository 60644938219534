import React from "react";
import { useTheme } from "@thrivelot/hooks";
import { useProposalView } from "@thrivelot/common";
import { CancelIcon } from "@thrivelot/styles";
import { ZoneInfoContainer } from "./ZoneInfoContainer";
import { ZoneScrollContainer } from "./ZoneScrollContainer";
import { TitleBar } from "./TitleBar";
import { Description } from "./Description";
import { Features } from "./Features";

const ZoneInfo = () => {
  // hooks
  const { zone: zoneId, setValue } = useProposalView();
  const theme = useTheme();
  // navigation
  const closeInfo = () => {
    setValue("zone", null);
  };
  // return
  return (
    <ZoneInfoContainer isOpen={zoneId}>
      <TitleBar />
      <ZoneScrollContainer>
        <Description />
        <Features />
      </ZoneScrollContainer>
      <CancelIcon
        hoverable
        color={theme.text}
        size={20}
        onClick={closeInfo}
        style={{ position: "absolute", top: "12px", right: "12px" }}
      />
    </ZoneInfoContainer>
  );
};

export { ZoneInfo };
