import styled from "styled-components";
import CssDivDefault from "./CssDivDefault";

const DivTruncated = styled.div`
  ${CssDivDefault}
  display: flex;
  flex: 1;
  min-width: 0px;
  overflow: hidden;
`;

export default DivTruncated;
