import React, { useMemo, useState } from 'react';
import { useModel, usePlantSearch } from '@thrivelot/hooks';
import {
  Button,
  Cancel,
  Checkmark,
  Descriptor,
  LeafLoader,
} from '@thrivelot/stories';
import { LayoutModal } from '../../LayoutModal';
import { RowItemCatalog } from '../../RowItemCatalog';

export const ModalLinkPlantToDB = ({
  onClose,
  description,
  itemPath,
  bundleId,
  itemId,
}) => {
  const [plant, setPlant] = useState(null);
  const {
    update,
    actions,
    loaded: bundleLoaded,
  } = useModel({
    modelName: 'Bundle',
    id: bundleId,
  });
  const { query, setQuery, items, loading: dbLoading } = usePlantSearch();

  const item = useMemo(() => {
    if (!bundleLoaded) return null;
    return actions.get(`${itemPath}[id:${itemId}]`);
  }, [itemPath, itemId, actions, bundleLoaded]);

  const handleLinkPlant = () => {
    const { id: plantDatabaseId } = plant;

    update(
      actions.set(`${itemPath}[id:${itemId}].data`, {
        ...item?.data,
        plantDatabaseId,
      }).result
    );

    onClose();
  };

  const shortenedDbResults = useMemo(
    () => (items?.length > 10 ? items.slice(0, 10) : items),
    [items]
  );

  return (
    <LayoutModal
      onClose={onClose}
      loading={!bundleLoaded || !item}
      header={
        <div className="text-lg text-brown-dark italic">
          Link plant to database
        </div>
      }
      footer={
        <div className="flex justify-end">
          <Button
            label="Submit"
            color="green-main"
            kind="outline"
            disabled={!plant}
            onClick={handleLinkPlant}
          />
        </div>
      }
      dialogCloseDisabled
    >
      <div>
        {description && (
          <div className="mb-4">
            <Descriptor>{description}</Descriptor>
          </div>
        )}
      </div>

      <div className="flex flex-row mt-6">
        <div className="relative flex-1">
          <input
            className="input !pr-7"
            placeholder="Search for a plant..."
            value={query}
            onChange={(e) => setQuery(e.target.value)}
          />
          {query.length > 0 && (
            <div className="absolute inset-y-0 right-0 pr-3 flex items-center">
              <Cancel
                color="yellow-main"
                size={16}
                styleProps="hover:cursor-pointer"
                onClick={() => setQuery('')}
              />
            </div>
          )}
        </div>
      </div>

      {dbLoading && <LeafLoader classAppend="mt-4" />}
      {!dbLoading &&
        (items || []).map((plantItem) => {
          const scientificName = `${plantItem.genus || ''} ${
            plantItem.species || ''
          }`;
          const formattedPlantItem = {
            id: plantItem.id,
            kind: 'plant',
            unit: 'plants',
            name: plantItem.commonName || scientificName || '',
            quantity: 1,
            pricePerUnit: 0,
            data: {
              ...plantItem?.data,
              plantDatabaseId: plantItem.id,
              catalogSourceId: plantItem.id,
            },
          };

          return (
            <RowItemCatalog
              key={plantItem.id}
              item={formattedPlantItem}
              actions={<Checkmark color="green-main" size="20" />}
              onClick={() => setPlant(formattedPlantItem)}
            />
          );
        })}

      {plant && (
        <div className="mt-8 pt-6 border-t-2 border-tan-light">
          <div className="flex">Plant ready to link</div>
          <RowItemCatalog item={plant} />
        </div>
      )}
    </LayoutModal>
  );
};
