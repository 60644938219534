import { get } from "@thrivelot/actions";

const calculateInvoiceSubtotalAndMarkup = (model, invoiceId) => {
  const invoice = get(model, `costPaymentSchedule[id:${invoiceId}]`);
  let markup = 0;
  let subtotal = 0;
  invoice?.payments?.forEach((payment) => {
    if (payment.calculation) {
      let paymentTotal = 0;
      payment.calculation.forEach((item) => {
        paymentTotal += item.amount;
      });
      ({ markup } = payment);
      subtotal += paymentTotal;
    }
  });
  return { markup, subtotal };
};

export { calculateInvoiceSubtotalAndMarkup };
