import React from "react";
import { isNumber } from "@thrivelot/utils";
import InputBubble from "./InputBubble";
import InputUnderline from "./InputUnderline";
import { numberInputProps } from "./helpers";

const InputMap = {
  bubble: InputBubble,
  underline: InputUnderline,
};

const InputNumber = ({ inputType, numValues = "0123456789.", onChange, ...rest }) => {
  const handleOnChange = (e) => {
    const { target } = e;
    const { value } = target;

    if (value.trim() === "") onChange(0);
    if (isNumber(value, numValues)) {
      let numValue = parseFloat(value);
      if (isNaN(numValue)) numValue = 0;
      onChange(numValue);
    }
  };

  const Input = InputMap[inputType];
  return <Input onChange={handleOnChange} onWheel={(e) => e.target.blur()} {...numberInputProps} {...rest} />;
};

export default InputNumber;
