import { useCallback, useMemo, useState } from 'react';
import { plantClient, PlantKit } from '@thrivelot/plant-kit';
import { Plant } from '@thrivelot/aws';
import { constants } from './constants';

export const usePlantCreate = (initialModel?: { [key: string]: any }) => {
  const [model, setModel] = useState(initialModel || {});
  const [saving, setSaving] = useState(false);

  const actions = useMemo(() => plantClient.actionsFactory(model), [model]);

  const update = useCallback(
    (updated: { [key: string]: any }) => setModel({ ...model, ...updated }),
    [model]
  );

  const create = useCallback(async () => {
    if (saving) return;

    setSaving(true);

    try {
      const plantKit = new PlantKit({ model: model as Plant });
      await plantKit.create();
    } catch (error) {
      console.log('Error in usePlantCreate create:', error);
    } finally {
      setSaving(false);
    }
  }, [model]);

  return { model, actions, saving, update, create, constants };
};
