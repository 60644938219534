import { useInvoiceProposalBillingSummary } from '@thrivelot/common';
import { useModel } from '@thrivelot/hooks';
import { Alert, Button, Checkmark, LeafLoader } from '@thrivelot/stories';
import {
  transformStringToSnakeCase,
  transformStringToTitleCase,
} from '@thrivelot/utils';
import React, { useMemo } from 'react';
import { ListInvoiceProposalEvents } from '../..';
import { Action } from './Action';

const InvoiceProposalActions = ({ projectId, invoiceProposalId }) => {
  const {
    model: invoiceProposal,
    updateModel,
    loaded,
    actions,
  } = useModel({ modelName: 'InvoiceProposal', id: invoiceProposalId });
  const { billingSummary: billing, loaded: billingLoaded } =
    useInvoiceProposalBillingSummary(invoiceProposalId);

  const agreed = useMemo(
    () =>
      loaded && actions.get('timestamps[prop:kind:invoiceProposalTermsAgreed]'),
    [loaded, actions]
  );

  const requiredMap = useMemo(
    () =>
      loaded && {
        name: !!invoiceProposal.name,
        description: !!invoiceProposal.description,
        estimatedStartDate: !!invoiceProposal.estimatedStartDate,
        estimatedEndDate: !!invoiceProposal.estimatedEndDate,
      },
    [loaded, invoiceProposal]
  );

  const allRequired = useMemo(
    () => loaded && Object.values(requiredMap).every((value) => value === true),
    [loaded, requiredMap]
  );

  const canSubmit = useMemo(() => {
    if (!loaded || !billingLoaded) return false;

    const firstPayment = billing?.payments?.[0];
    const secondPayment = billing?.payments?.[1];

    if (invoiceProposal.creditItem?.creditAmount) {
      if (firstPayment && secondPayment) {
        return (
          firstPayment.totalAmount + invoiceProposal.creditItem.creditAmount >
            firstPayment.priceSubtotal &&
          secondPayment.totalAmount + invoiceProposal.creditItem.creditAmount >
            secondPayment.priceSubtotal
        );
      }

      if (firstPayment && !secondPayment) {
        return (
          firstPayment.totalAmount + invoiceProposal.creditItem.creditAmount >
          firstPayment.priceSubtotal
        );
      }
    }

    if (!invoiceProposal.creditItem?.creditAmount) {
      if (firstPayment && secondPayment) {
        return (
          firstPayment.totalAmount > firstPayment.priceSubtotal &&
          secondPayment.totalAmount > secondPayment.priceSubtotal
        );
      }

      if (firstPayment && !secondPayment) {
        return firstPayment.totalAmount > firstPayment.priceSubtotal;
      }
    }

    return false;
  }, [billing?.payments, billingLoaded, loaded]);

  const shouldBeSingleBilling = useMemo(() => {
    if (!loaded || !billingLoaded) return false;

    const firstPayment = billing?.payments?.[0];
    const secondPayment = billing?.payments?.[1];

    if (
      invoiceProposal.kind === 'time_materials_billing' &&
      firstPayment &&
      secondPayment &&
      firstPayment.totalAmount > 0 &&
      secondPayment.totalAmount <= 0
    )
      return true;

    return false;
  }, [invoiceProposal?.kind, billing?.payments, billingLoaded, loaded]);

  if (!loaded || !billingLoaded) return <LeafLoader />;

  const { status, kind, timestamps } = invoiceProposal;

  return (
    <div className="flex justify-center text-center">
      <div className="flex flex-col gap-6">
        <ListInvoiceProposalEvents invoiceProposalId={invoiceProposalId} />
        {(timestamps || []).length > 0 && (
          <div className="border-b border-tan-light" />
        )}
        <div>
          {!allRequired && (
            <div>
              <div className="font-bold text-xl mb-2">
                Missing some required fields...
              </div>
              <div>
                {Object.keys(requiredMap).map((key) => (
                  <div key={key} className="flex justify-between items-center">
                    <div
                      className={
                        requiredMap[key] ? 'text-green-main' : 'text-red-main'
                      }
                    >
                      {transformStringToTitleCase(
                        transformStringToSnakeCase(key)
                      )}
                    </div>
                    <div>
                      {requiredMap[key] ? (
                        <Checkmark size={18} />
                      ) : (
                        <Alert color="red-main" size={18} />
                      )}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}

          {shouldBeSingleBilling && status === 'draft' && (
            <div className="text-center flex flex-col justify-center mb-8">
              <div>
                The second payment is currently $0 because there are no labor
                line items.
              </div>
              <div className="mb-3">
                If this is intentional, please click below to convert this order
                to a single payment order.
              </div>
              <div className="flex justify-center">
                <Button
                  label="Convert to Single Payment"
                  color="blue-main"
                  onClick={() =>
                    updateModel(actions.set('kind', 'single_billing').result)
                  }
                />
              </div>
            </div>
          )}

          {allRequired && !agreed && status === 'draft' && (
            <Action
              invoiceProposalId={invoiceProposalId}
              showPreviewLink={true}
              name="I Agree"
              description={
                <div>
                  I agree to perform the work described for the price quoted
                  subject to these{' '}
                  <a
                    className="text-blue-main"
                    target="_blank"
                    href="https://thrivelot.notion.site/Terms-Conditions-Service-Provider-569ec9c1306e41f9a9cf2134be100019"
                    rel="noreferrer"
                  >
                    Terms & Conditions
                  </a>
                </div>
              }
              color="blue-main"
              timestampKind="invoiceProposalTermsAgreed"
              timestampDescription="Agreed to terms of service for project"
            />
          )}
          {allRequired && agreed && status === 'draft' && canSubmit && (
            <Action
              invoiceProposalId={invoiceProposalId}
              name="Submit"
              showPreviewLink={true}
              description="Submit order for acceptance and review by the customer"
              timestampKind="invoiceProposalSubmitted"
              timestampDescription="Submitted order"
              newStatus="submitted"
              newBundlesStatus="locked"
            />
          )}

          {allRequired && agreed && status === 'draft' && !canSubmit && (
            <div className="flex flex-col items-center justify-center text-brown-dark text-center pb-4 px-4 max-w-md mx-auto mb-10">
              <div className="mt-4 font-bold text-2xl">
                Something doesn't add up...
              </div>
              <div className="mt-4 mb-2">
                This order can't be submitted because the total amount of one of
                the payments is not greater than the subtotal. If this is
                intentional then you may need to convert this order to single
                payment.
              </div>
            </div>
          )}

          {status === 'submitted' && (
            <Action
              invoiceProposalId={invoiceProposalId}
              name="Unsubmit"
              description="Awaiting customer approval."
              color="orange-main"
              timestampKind="invoiceProposalUnsubmitted"
              timestampDescription="Unsubmitted order"
              newStatus="draft"
              newBundlesStatus="unlocked"
            />
          )}
          {status === 'approved' && (
            <div>The customer has approved the proposal. Awaiting payment.</div>
          )}
          {status === 'active' && kind === 'time_materials_billing' && (
            <Action
              invoiceProposalId={invoiceProposalId}
              name="Submit"
              showPreviewLink={true}
              description="This order is now paid and active. Once the work is complete, submit order for acceptance and review by the customer for final payment."
              color="orange-main"
              timestampKind="invoiceProposalSubmittedForCompletion"
              timestampDescription="Submitted order for completion"
              newStatus="submitted_for_completion"
              newBundlesStatus="locked"
            />
          )}
          {status === 'active' && kind === 'design_billing' && (
            <Action
              invoiceProposalId={invoiceProposalId}
              name="Complete"
              description="Order has been paid. Please click below when the design is complete."
              color="orange-main"
              timestampKind="invoiceProposalComplete"
              timestampDescription="Order complete"
              newStatus="complete"
            />
          )}
          {status === 'submitted_for_completion' && (
            <Action
              invoiceProposalId={invoiceProposalId}
              name="Unsubmit"
              description="Awaiting customer approval and final payment."
              color="orange-main"
              timestampKind="invoiceProposalUnsubmittedForCompletion"
              timestampDescription="Unsubmitted order for completion"
              newStatus="active"
              newBundlesStatus="changes_only"
            />
          )}
          {status === 'approved_for_completion' && (
            <div>The customer has approved the proposal. Awaiting payment.</div>
          )}
          {status === 'complete' && <div>Order Complete</div>}
        </div>
      </div>
    </div>
  );
};

export { InvoiceProposalActions };
