import React, { useMemo } from 'react';
import { useModel } from '@thrivelot/hooks';
import { Button, PageContainer } from '@thrivelot/stories';
import {
  calculatePaymentTotal,
  constructPhaseCalculations,
} from '@thrivelot/common';
import { transformCurrencyToString } from '@thrivelot/utils';
import { CostPaymentScheduleType, TagIdType } from '@thrivelot/aws';
import { Navigate, Route, Routes, useParams } from 'react-router-dom';
import {
  DropdownLinks,
  HelpPrompt,
  ProjectFeaturesGroupedByArea,
  ProjectFeaturesGroupedByPhase,
  RowListProjectFeatures,
  RowListFeatureUnassignedItems,
} from '../..';
import { PageUnchartedLands } from '..';
import { LinkBack } from '../../LinkBack';

const PageProjectFeatures = () => {
  const { projectId } = useParams();
  const {
    model: project,
    actions,
    updateModel,
    loaded,
  } = useModel({ modelName: 'Project', id: projectId });

  const total = useMemo(() => {
    if (!project || !project.costPaymentSchedule) return 0;
    let result = 0;
    project.costPaymentSchedule.forEach((payment) => {
      if (payment.type === CostPaymentScheduleType.PHASE) {
        const { tagId: phaseId } = payment.tagIds.find(
          ({ type }) => type === TagIdType.PHASE
        );

        const calculations = constructPhaseCalculations(phaseId, project);
        const paymentTotal = calculatePaymentTotal(
          payment,
          calculations,
          project
        );
        result += paymentTotal;
      } else {
        const paymentTotal = calculatePaymentTotal(payment, project);
        result += paymentTotal;
      }
    });

    return result;
  }, [project]);

  const budgetCommitment = project?.details?.budgetCommitment || 0;
  const useInvoiceProposals = !!project?.details?.useInvoiceProposals;

  const options = [
    {
      label: 'Ungrouped',
      icon: 'ListView',
      to: 'list',
    },
    {
      label: 'By Area',
      icon: 'Pin',
      to: 'area',
    },
  ];

  if (!useInvoiceProposals)
    options.push({
      label: 'By Phase',
      icon: 'Phases',
      to: 'phase',
    });

  return (
    <PageContainer
      preheader={
        <LinkBack
          label={`Back to ${
            window.location.pathname.includes('orders') ? 'Order' : 'Map'
          }`}
          to=".."
        />
      }
      icon="Feature"
      header="Features"
      dropdown={
        <DropdownLinks
          color="brown-dark"
          options={options}
          pathMatch={({ location, params, to }) =>
            to === location.pathname.split('/')[4]
          }
          classAppend="!px-3 !py-0.5 text-sm !border-none pt-1"
          align="left"
        />
      }
      subheader={
        !useInvoiceProposals && (
          <div className="text-brown-dark mr-1">
            <span
              className={`font-bold ${
                total > budgetCommitment ? 'text-red-main' : 'text-green-main'
              }`}
            >
              {transformCurrencyToString(total)}
            </span>{' '}
            of{' '}
            <span className="font-bold">
              {transformCurrencyToString(budgetCommitment)}
            </span>
          </div>
        )
      }
      documentTitle={loaded && project.details.title}
      headerActions={
        loaded && (
          <div className="flex items-center gap-2">
            <Button
              icon="Plus"
              size="small"
              color="blue-main"
              kind="outline"
              label="Add Feature"
              onClick={() =>
                updateModel(
                  actions.add('features', actions.constructFeature({}), 0)
                    .result
                )
              }
            />
          </div>
        )
      }
      help={
        <HelpPrompt header="About the Project Features page">
          The features section is the primary location for estimating Features
          by line items and plants. You can sort by Phase or Area of Work.
        </HelpPrompt>
      }
    >
      <Routes>
        <Route exact path="" element={<Navigate to="list" replace />} />
        <Route
          path="list"
          element={
            <>
              {!project?.details?.useInvoiceProposals && (
                <RowListFeatureUnassignedItems projectId={projectId} />
              )}
              <RowListProjectFeatures projectId={projectId} />
            </>
          }
        />
        <Route
          path="area"
          element={<ProjectFeaturesGroupedByArea projectId={projectId} />}
        />
        <Route
          path="phase"
          element={<ProjectFeaturesGroupedByPhase projectId={projectId} />}
        />

        <Route path="*" element={<PageUnchartedLands />} />
      </Routes>
    </PageContainer>
  );
};

export { PageProjectFeatures };
