// Borrowed from https://github.com/mhnpd/react-loader-spinner

import React, { useMemo } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import PropTypes from 'prop-types';

const RADIUS = 20;

const getPath = (radius) =>
  [`M${radius} 0c0-9.94-8.06`, radius, radius, radius].join('-');

const getViewBoxSize = (strokeWidth, secondaryStrokeWidth, radius) => {
  const maxStrokeWidth = Math.max(strokeWidth, secondaryStrokeWidth);
  const startingPoint = -radius - maxStrokeWidth / 2 + 1;
  const endpoint = radius * 2 + maxStrokeWidth;
  return [startingPoint, startingPoint, endpoint, endpoint].join(' ');
};

export const Spinner = ({ size, color = 'green-main' }) => {
  const strokeWidth = 2;

  const sizeValue = useMemo(() => {
    let value = 20;
    if (size === 'small') {
      value = '18';
    } else if (size === 'large') {
      value = '26';
    } else if (size === 'extra-small') {
      value = '12';
    }
    return value;
  }, [size]);

  return (
    <svg
      width={sizeValue}
      height={sizeValue}
      viewBox={getViewBoxSize(Number(strokeWidth), Number(strokeWidth), RADIUS)}
      xmlns="http://www.w3.org/2000/svg"
      data-testid="spinner-svg"
      aria-label={'spinner-loading'}
      className={`stroke-${color}`}
    >
      <g fill="none" fillRule="evenodd">
        <g transform="translate(1 1)" strokeWidth={Number(strokeWidth)}>
          <circle
            strokeOpacity=".5"
            cx="0"
            cy="0"
            r={RADIUS}
            strokeWidth={strokeWidth}
          />
          <path d={getPath(RADIUS)}>
            <animateTransform
              attributeName="transform"
              type="rotate"
              from="0 0 0"
              to="360 0 0"
              dur="1s"
              repeatCount="indefinite"
            />
          </path>
        </g>
      </g>
    </svg>
  );
};

Spinner.propTypes = {
  /**
   * Size of the spinner
   */
  size: PropTypes.oneOf(['extra-small', 'small', 'default', 'large']),
};

Spinner.defaultProps = {};
