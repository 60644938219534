export const trimOutTypename = (
  item: Record<string, any> | any[] | null | undefined
) => {
  if (!item) return item;

  if (Array.isArray(item))
    return item.map((value) =>
      typeof value === 'object' ? trimOutTypename(value) : value
    );

  const newObj: Record<string, any> = {};

  Object.keys(item).forEach((key) => {
    if (key !== '__typename') {
      const value = item[key];
      newObj[key] = typeof value === 'object' ? trimOutTypename(value) : value;
    }
  });

  return newObj;
};
