import styled from "styled-components";

const ZoneInfoContainer = styled.div`
  box-shadow: 0px 0px 6px 1px rgba(192, 165, 146, 0.4);
  display: flex;
  flex-direction: column;
  position: absolute;
  left: 0px;
  top: 0px;
  bottom: 0px;
  transition: all 0.5s ease-out 0s;
  border-radius: 5px;
  background-color: ${({ theme }) => theme.foreground};
  opacity: ${({ isOpen }) => (isOpen ? 1 : 0)};
  pointer-events: ${({ isOpen }) => (isOpen ? "auto" : "none")};
  @media (max-width: 767px) {
    left: 12px;
    top: 12px;
    bottom: 12px;
    right: 12px;
  }
  @media (min-width: 768px) {
    left: 20px;
    top: 20px;
    bottom: 20px;
    width: 500px;
  }
`;

export { ZoneInfoContainer };
