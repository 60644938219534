import { useCallback, useEffect, useRef, useState } from "react";

const updateUserAttributesLocally = (user, attributeName, attribute) => {
  if (!attribute) return;

  user.attributes[attributeName] = attribute;
  user.attributes[`${attributeName}_verified`] = false;
};

const useAuthProvider = (authConfig) => {
  const [tempUser, setTempUser] = useState();
  const [user, setUser] = useState();
  const [groups, setGroups] = useState(user?.signInUserSession?.accessToken?.payload?.["cognito:groups"] || []);
  const [userId, setUserId] = useState(user?.attributes?.["custom:userId"]);
  const [authenticated, setAuthenticated] = useState(!!user);
  const [loading, setLoading] = useState(!user);

  const configRef = useRef(authConfig);

  const fetchUserCredentials = useCallback(() => configRef.current.fetchUserCredentials(), []);

  const fetchUserSession = useCallback(() => configRef.current.fetchUserSession(), []);

  const fetchUserJwt = useCallback(() => configRef.current.fetchUserJwt(), []);

  const signIn = useCallback(
    (username, password) =>
      configRef.current.signIn(username, password).then((response) => {
        if (response?.requiresNewPassword) {
          setTempUser(response.user);
          return response;
        }

        setUser(response.user);
        setGroups(response.user.signInUserSession.accessToken.payload["cognito:groups"]);
        setUserId(response.user.attributes["custom:userId"]);
        setAuthenticated(true);

        return response;
      }),
    []
  );

  const signOut = useCallback(
    () =>
      configRef.current.signOut().then(() => {
        setUser(null);
        setGroups([]);
        setUserId(null);
        setAuthenticated(false);
      }),
    []
  );

  const completeNewPassword = useCallback(
    (newPassword) => configRef.current.completeNewPassword(tempUser, newPassword),
    [tempUser]
  );

  const sendPasswordResetCode = useCallback((username) => configRef.current.sendPasswordResetCode(username), []);

  const resetPassword = useCallback(
    (username, passwordResetCode, newPassword) =>
      configRef.current.resetPassword(username, passwordResetCode, newPassword),
    []
  );

  const changePassword = useCallback(
    (oldPassword, newPassword) => configRef.current.changePassword(oldPassword, newPassword),
    []
  );

  const updateUserAttributes = useCallback(
    (attrs) =>
      configRef.current.updateUserAttributes(attrs).then((user) => {
        if (attrs.email) updateUserAttributesLocally(user, "email", attrs.email);
        if (attrs.phone_number) updateUserAttributesLocally(user, "phone_number", attrs.phone_number);

        return setUser(user);
      }),
    []
  );

  const sendUserAttributeVerificationCode = useCallback(
    (attrName) => configRef.current.sendUserAttributeVerificationCode(attrName),
    []
  );

  const verifyUserAttribute = useCallback(
    (attrName, verificationCode) =>
      configRef.current.verifyUserAttribute(attrName, verificationCode).then((user) => {
        user.attributes[`${attrName}_verified`] = true;

        return setUser(user);
      }),
    []
  );

  useEffect(() => {
    let didCancel;

    configRef.current
      .fetchUser()
      .then((newUser) => {
        if (didCancel) return;

        if (!user && !!newUser) {
          setUser(newUser);
          setGroups(newUser.signInUserSession.accessToken.payload["cognito:groups"]);
          setUserId(newUser.attributes["custom:userId"]);
          setAuthenticated(true);
        } else if (!!user && !newUser) {
          signOut();
        }
      })
      .finally(() => {
        if (!didCancel) setLoading(false);
      });

    return () => {
      didCancel = true;
    };
  }, [user, signOut]);

  return {
    user,
    groups,
    userId,
    authenticated,
    loading,
    fetchUserCredentials,
    fetchUserSession,
    fetchUserJwt,
    signIn,
    signOut,
    completeNewPassword,
    sendPasswordResetCode,
    resetPassword,
    changePassword,
    updateUserAttributes,
    sendUserAttributeVerificationCode,
    verifyUserAttribute,
  };
};

export { useAuthProvider };
