import React, { useMemo } from 'react';
import { Dropdown } from '@thrivelot/stories';
import { MeasurementUnit } from '@thrivelot/aws';
import { transformStringToTitleCase } from '@thrivelot/utils';

const DropdownSelectBundleLineItemUnit = ({ value, onChange, disabled }) => {
  const unit = value || MeasurementUnit.UNITS;

  const options = useMemo(
    () =>
      Object.keys(MeasurementUnit).map((key) => ({
        label: transformStringToTitleCase(MeasurementUnit[key], true),
        selected: unit === MeasurementUnit[key],
        onClick: () => onChange(MeasurementUnit[key]),
      })),
    [unit, onChange]
  );

  if (disabled)
    return (
      <div className="input w-full flex items-center !rounded-none h-full !bg-tan-light">
        {transformStringToTitleCase(unit, true)}
      </div>
    );

  return (
    <Dropdown
      label={
        <div className="input w-full flex items-center !text-current !rounded-none h-full">
          {transformStringToTitleCase(unit, true)}
        </div>
      }
      labelWrapperClassAppend="h-full w-full"
      dropdownClassAppend="bottom-11"
      optionsClassAppend="!max-h-40"
      header={<div className="text-brown-dark italic">select unit...</div>}
      options={options}
      optionClass="font-bold"
      align="right"
      // selectedColor={colorMap[type]}
    />
  );
};

export { DropdownSelectBundleLineItemUnit };
