import { PaymentCalculationType, TagIdType } from "@thrivelot/aws";
import { getFeatures } from "./getFeatures";
import { getCostEstimateItems } from "./getCostEstimateItems";
import { calculate100Materials50Labor } from "./calculate100Materials50Labor";

const constructPhaseCalculations = (phaseId, project) => {
  if (!project.phases) return [[], []];

  const phase = project.phases.find((e) => e.id === phaseId);

  const features = getFeatures({ tagIds: phase.orderedChildTagIds, project });
  const calculation1 = [];
  const calculation2 = [];

  features.forEach((feature) => {
    const { lineItems, plantItems } = getCostEstimateItems({
      tagIds: feature.orderedChildTagIds,
      project,
    });

    const { laborCost, materialsCost } = calculate100Materials50Labor(lineItems, plantItems);

    calculation1.push({
      type: PaymentCalculationType.AMOUNT,
      amount: laborCost + materialsCost,
      tagId: { type: TagIdType.FEATURE, tagId: feature.id },
    });
    calculation2.push({
      type: PaymentCalculationType.AMOUNT,
      amount: laborCost,
      tagId: { type: TagIdType.FEATURE, tagId: feature.id },
    });
  });

  const calculations = [calculation1, calculation2];
  return calculations;
};

export { constructPhaseCalculations };
