import React from "react";
import TextBody from "./TextBody";
import TextBodyBold from "./TextBodyBold";
import TextHeader from "./TextHeader";
import TextTitle from "./TextTitle";

const TextMap = {
  body: TextBody,
  "body-bold": TextBodyBold,
  header: TextHeader,
  title: TextTitle,
};

const TextTruncate = ({ textType, style = {}, ...rest }) => {
  const Content = TextMap[textType] || TextBody;
  return (
    <Content
      style={{
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        ...style,
      }}
      {...rest}
    />
  );
};

export default TextTruncate;
