import styled from "styled-components";
import CssDivDefault from "./CssDivDefault";

const DivBordered = styled.div`
  ${CssDivDefault}
  border-radius: 5px;
  padding: 12px;
  flex-direction: column;
  border: 1px solid ${({ theme }) => theme.buttonCancel};
`;

export default DivBordered;
