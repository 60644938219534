import React, { useMemo } from 'react';
import { useModel, useQueryParam } from '@thrivelot/hooks';
import { Button, CardLoader } from '@thrivelot/stories';
import { ListFiles } from './ListFiles';

const GENERAL_FOLDER = '__general__';

export const NoFolderRow = ({ projectId }) => {
  const { param: openFolders, setParam } = useQueryParam('openFolders');
  const { model: project, loaded } = useModel({
    modelName: 'Project',
    id: projectId,
  });

  const filesWithoutFolder = useMemo(() => {
    if (!project?.files) return [];

    return project.files.filter(
      (file) =>
        !(project.fileFolders || []).find((folder) =>
          folder.fileIds.includes(file.id)
        )
    );
  }, [project?.files, project?.fileFolders]);

  if (!loaded) return <CardLoader />;

  return (
    <div className="text-brown-dark rounded-main shadow bg-white-light">
      <div className="flex justify-between items-center p-4">
        <div>
          <div className="font-bold">General</div>
          <div className="italic text-sm">{`${
            filesWithoutFolder.length || 0
          } file${filesWithoutFolder.length === 1 ? '' : 's'}`}</div>
        </div>
        <div className="flex items-center gap-3">
          <div className="w-24 flex justify-end">
            {(openFolders || []).includes(GENERAL_FOLDER) ? (
              <Button
                icon="Shrink"
                kind="prompt"
                size="small"
                color="orange-main"
                label="Close"
                onClick={() => {
                  const newParams = (openFolders || []).filter(
                    (id) => id !== GENERAL_FOLDER
                  );
                  if (newParams.length > 0) return setParam(newParams);
                  return setParam();
                }}
              />
            ) : (
              <Button
                icon="Expand"
                kind="prompt"
                size="small"
                color="brown-dark"
                label="Expand"
                onClick={() =>
                  setParam([...(openFolders || []), GENERAL_FOLDER])
                }
              />
            )}
          </div>
        </div>
      </div>
      {(openFolders || []).includes(GENERAL_FOLDER) && (
        <div className="h-[1px] bg-tan-light w-full" />
      )}
      <div className="transition-height ease-in-out duration-300"></div>
      {(openFolders || []).includes(GENERAL_FOLDER) ? (
        <ListFiles projectId={projectId} />
      ) : null}
    </div>
  );
};
