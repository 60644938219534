import { calculatePercentTotal } from './constructItemWithTotals';

const calculateTotals = ({
  items,
  cache = {},
  housePercent,
  applyCredit = false,
  creditItem,
  paymentMethod,
}) => {
  const requiredProps = [
    'items',
    'houseSubtotal',
    'priceSubtotal',
    'totalAmount',
  ];

  if (cache.paidAt && cache.priceSubtotal) return { ...cache, cached: true };
  // eslint-disable-next-line no-prototype-builtins
  if (requiredProps.every((key) => cache.hasOwnProperty(key) && cache[key]))
    return cache;

  const result = {
    items: [],
    houseSubtotal: 0,
    priceSubtotal: 0,
    totalAmount: null,
    ccFeePercent: 3,
    ccFeeAmount: null,
    housePercent,
  };

  (cache.items || items).forEach((item) => {
    result.items.push(item);

    result.houseSubtotal += item.houseSubtotal;
    result.priceSubtotal += item.priceSubtotal;

    if (creditItem && item.redistributeSubtotal) {
      result.redistributeSubtotal = result.redistributeSubtotal || 0;
      result.redistributeSubtotal += item.redistributeSubtotal;
    }
  });

  result.totalAmount = result.priceSubtotal + result.houseSubtotal;
  result.ccFeeAmount = calculatePercentTotal(
    result.totalAmount,
    result.ccFeePercent
  );

  if (creditItem) {
    result.totalAmount += result.redistributeSubtotal || 0;
    result.preCreditSubtotal = result.totalAmount;
    result.creditItem = creditItem;
  }

  if (applyCredit) {
    result.applyCredit = applyCredit;
    result.totalAmount -= creditItem?.creditAmount || 0;
  }

  if (paymentMethod === 'card') {
    result.totalAmount += result.ccFeeAmount;
    result.processingFeeAmount = result.ccFeeAmount;
    result.processingFeePercent = result.ccFeePercent;
    result.paymentMethod = paymentMethod;
  } else if (paymentMethod === 'us_bank_account') {
    result.processingFeeAmount = 0;
    result.processingFeePercent = 0;
    result.paymentMethod = paymentMethod;
  }

  return result;
};

export { calculateTotals };
