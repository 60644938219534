import React from "react";
import { DivModalContent } from "@thrivelot/styles";
import { useSupplierActionSet } from "./hooks";
import { PicAndName } from "./PicAndName";
import { Bio } from "./Bio";

const SupplierModal = () => {
  // hooks
  const supplierActions = useSupplierActionSet();
  // return
  return (
    <DivModalContent style={{ padding: "0px" }}>
      <PicAndName supplierActions={supplierActions} />
      <Bio supplierActions={supplierActions} />
    </DivModalContent>
  );
};

export { SupplierModal };
