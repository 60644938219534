import React, { useMemo } from 'react';
import { useModel } from '@thrivelot/hooks';
import {
  RowItemLoader,
  Button,
  Descriptor,
  EmptyState,
  DateDone,
} from '@thrivelot/stories';
import ReactTextareaAutosize from 'react-textarea-autosize';
import { constructUuid } from '@thrivelot/utils';
import ReactDatePicker from 'react-datepicker';
import { formatLongDate } from '@thrivelot/date';
import { FormBundle, InputCurrency, Confirm } from '..';
import { kindMap } from './kindMap';
import { BillingSummary } from './BillingSummary';
import { InvoiceProposalActions } from './InvoiceProposalActions';
import { FormInvoiceProposalPhase } from './FormInvoiceProposalPhase';
import {
  SelectInvoiceProposalTotalsAs,
  totalsAsMap,
} from './SelectInvoiceProposalTotalsAs';

const FormProjectInvoiceProposal = ({ projectId, invoiceProposalId }) => {
  const {
    model: invoiceProposal,
    loaded,
    updateModel,
    actions,
  } = useModel({ modelName: 'InvoiceProposal', id: invoiceProposalId });

  const sortedPhases = useMemo(
    () => loaded && actions.getSortedPhases(),
    [loaded, actions]
  );

  if (!loaded) return <RowItemLoader />;

  const {
    name,
    description,
    status,
    kind,
    totalsAs,
    estimatedStartDate,
    estimatedEndDate,
    bundleIds,
    creditItem,
  } = invoiceProposal;

  return (
    <div className="flex flex-col gap-4 mb-12">
      <div>
        <div className="text-brown-dark text-sm font-bold mb-1">Name</div>
        <input
          className="input"
          placeholder="Add a name for your order..."
          disabled={status !== 'draft'}
          value={name || ''}
          onChange={(e) =>
            updateModel(actions.set('name', e.target.value).result)
          }
        />
      </div>
      <div>
        <div className="text-brown-dark text-sm font-bold mb-1">
          Description
        </div>
        <ReactTextareaAutosize
          className="w-full input"
          placeholder={
            status !== 'draft'
              ? 'No description...'
              : 'Describe the overall goal of this project...'
          }
          disabled={status !== 'draft'}
          value={description || ''}
          minRows={3}
          onChange={(e) =>
            updateModel(actions.set('description', e.target.value).result)
          }
        />
      </div>
      <div className="md:flex md:gap-4">
        <div className="flex-1 flex gap-4">
          <div className="flex-1">
            <div className="text-brown-dark font-bold mb-2">
              Estimated Start Date
            </div>
            <ReactDatePicker
              popperPlacement="bottom-start"
              showPopperArrow={false}
              disabled={status !== 'draft'}
              onChange={(date) => {
                const noon = new Date(date);
                noon.setHours(12); // set to noon to avoid timezone issues
                return updateModel(
                  actions.set('estimatedStartDate', noon.toISOString()).result
                );
              }}
              customInput={
                <div
                  className={`flex items-center gap-2 input ${
                    status !== 'draft' ? 'hover:cursor-pointer' : ''
                  }`}
                >
                  <DateDone color="brown-dark" size={16} />
                  {estimatedStartDate ? (
                    <div>{formatLongDate(estimatedStartDate)}</div>
                  ) : (
                    <div className="italic">
                      {status !== 'draft'
                        ? 'No date selected...'
                        : 'Select a date...'}
                    </div>
                  )}
                </div>
              }
            />
          </div>
          <div className="flex-1">
            <div className="text-brown-dark font-bold mb-2">
              Estimated Completion Date
            </div>
            <ReactDatePicker
              popperPlacement="bottom-start"
              showPopperArrow={false}
              disabled={status !== 'draft'}
              onChange={(date) => {
                const noon = new Date(date);
                noon.setHours(12); // set to noon to avoid timezone issues
                return updateModel(
                  actions.set('estimatedEndDate', noon.toISOString()).result
                );
              }}
              customInput={
                <div
                  className={`flex items-center gap-2 input ${
                    status !== 'draft' ? 'hover:cursor-pointer' : ''
                  }`}
                >
                  <DateDone color="brown-dark" size={16} />
                  {estimatedEndDate ? (
                    <div>{formatLongDate(estimatedEndDate)}</div>
                  ) : (
                    <div className="italic">
                      {status !== 'draft'
                        ? 'No date selected...'
                        : 'Select a date...'}
                    </div>
                  )}
                </div>
              }
            />
          </div>
        </div>
        <div className="flex-1 flex gap-4"></div>
      </div>
      <div className="mt-6 pt-4 border-t-2 border-tan-light" />
      <div>
        <div className="font-bold text-brown-dark mb-2">Invoice View</div>
        <SelectInvoiceProposalTotalsAs invoiceProposalId={invoiceProposalId} />
        {totalsAs && totalsAsMap[totalsAs] && (
          <Descriptor
            classAppend="mt-4"
            icon={totalsAsMap[totalsAs].icon}
            header={totalsAsMap[totalsAs].label}
            body={totalsAsMap[totalsAs].description}
          />
        )}
      </div>
      <div className="mt-6 pt-4 border-t-2 border-tan-light" />
      <div>
        <div className="flex items-center gap-6 mb-4">
          <div className="flex-1 font-bold text-brown-dark mb-2 text-lg">
            Work Phases
          </div>
          <div>
            {status === 'draft' && (
              <Button
                color="blue-main"
                kind="outline"
                size="small"
                icon="Plus"
                label="Add Phase"
                onClick={() =>
                  updateModel(
                    actions.add('phases', {
                      id: constructUuid(),
                      name: '',
                      description: '',
                      dates: [],
                      kind: 'date',
                    }).result
                  )
                }
              />
            )}
          </div>
        </div>

        {sortedPhases.length === 0 && status === 'draft' && (
          <EmptyState
            icon="Phases"
            header="Add one or more phases to this Order"
            footer={
              <Button
                color="blue-main"
                kind="outline"
                icon="Plus"
                label="Add Phase"
                onClick={() =>
                  updateModel(
                    actions.add('phases', {
                      id: constructUuid(),
                      name: '',
                      description: '',
                      dates: [],
                      kind: 'date',
                    }).result
                  )
                }
              />
            }
          >
            <div className="text-center">
              Use work phases to describe the timeline of the work associated
              with this order. Select a single date or a data range, and
              describe which work will happen during that time.
            </div>
          </EmptyState>
        )}

        {sortedPhases.length === 0 && status !== 'draft' && (
          <EmptyState>This order has no phases.</EmptyState>
        )}

        {sortedPhases.length > 0 && (
          <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-4">
            {sortedPhases.map((phase) => (
              <FormInvoiceProposalPhase
                key={phase.id}
                projectId={projectId}
                invoiceProposalId={invoiceProposalId}
                phaseId={phase.id}
              />
            ))}
          </div>
        )}
      </div>
      <div className="mt-6 pt-4 border-t-2 border-tan-light" />
      <div>
        {bundleIds.map((id) => (
          <FormBundle key={id} projectId={projectId} bundleId={id} />
        ))}
      </div>

      <div className="mt-6 pt-4 border-t-2 border-tan-light" />
      <BillingSummary
        projectId={projectId}
        invoiceProposalId={invoiceProposalId}
      />
      <div className="mt-6 pt-4 border-t-2 border-tan-light" />
      <InvoiceProposalActions
        projectId={projectId}
        invoiceProposalId={invoiceProposalId}
      />
    </div>
  );
};

export { FormProjectInvoiceProposal };
