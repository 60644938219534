import React, { useEffect, useMemo, useState } from 'react';
import { useFiles, useModel, useZoom } from '@thrivelot/hooks';
import { Button, Download, Minus, Plus } from '@thrivelot/stories';
import { Layout } from '../ModalViewFiles/Layout';

const ViewImage = ({ onClose, modelName, modelId, filePath, fileId }) => {
  const [signedUrl, setSignedUrl] = useState(null);

  const { actions, loaded } = useModel({
    modelName,
    id: modelId,
  });
  const { fetchUrl } = useFiles();
  const { isSizeToFit, setIsSizeToFit, zoomIn, zoomOut, ZoomView } =
    useZoom(signedUrl);

  const file = useMemo(
    () => loaded && actions.get(`${filePath}[id:${fileId}]`),
    [loaded, actions, filePath, fileId]
  );

  useEffect(() => {
    const fetchSignedUrl = async () => {
      const { data } = await fetchUrl(file?.file?.key);
      setSignedUrl(data);
    };

    if (file?.file?.key) fetchSignedUrl();
  }, [fetchUrl, file?.file?.key]);

  const download = () => {
    const newWindow = window.open();
    newWindow.document.title = 'Thrive Lot | View File';
    newWindow.document.write(
      `<iframe src="${signedUrl}" frameborder="0" style="border:0; top:0px; left:0px; bottom:0px; right:0px; width:100%; height:100%;" allowfullscreen></iframe>`
    );
  };

  const filename = file?.file?.filename;
  const caption = file?.caption;

  return (
    <Layout
      onClose={onClose}
      header={filename}
      subheader={caption}
      footer={
        <div className="flex flex-1 items-center justify-between">
          <div className="flex items-center gap-3 select-none">
            {/* <div className="text-white-light font-bold">Zoom</div> */}
            <Minus
              size={24}
              color="white-light"
              styleProps="hover:cursor-pointer"
              onClick={zoomOut}
            />
            <Plus
              size={24}
              color="white-light"
              styleProps="hover:cursor-pointer"
              onClick={zoomIn}
            />
            <Button
              label={isSizeToFit ? 'Fill Screen' : 'Size to Fit'}
              size="small"
              kind="outline"
              color="white-light"
              onClick={() => setIsSizeToFit(!isSizeToFit)}
            />
          </div>
          <Download
            size={24}
            color="white-light"
            styleProps="hover:cursor-pointer"
            onClick={download}
          />
        </div>
      }
    >
      {loaded && signedUrl && ZoomView}
    </Layout>
  );
};

const ViewImageWithUrl = ({ onClose, fileUrl, fileName }) => {
  const { isSizeToFit, setIsSizeToFit, zoomIn, zoomOut, ZoomView } =
    useZoom(fileUrl);

  const download = () => {
    const newWindow = window.open();
    newWindow.document.title = 'Thrive Lot | View File';
    newWindow.document.write(
      `<iframe src="${fileUrl}" frameborder="0" style="border:0; top:0px; left:0px; bottom:0px; right:0px; width:100%; height:100%;" allowfullscreen></iframe>`
    );
  };

  return (
    <Layout
      onClose={onClose}
      header={fileName}
      footer={
        <div className="flex flex-1 items-center justify-between">
          <div className="flex items-center gap-3 select-none">
            {/* <div className="text-white-light font-bold">Zoom</div> */}
            <Minus
              size={24}
              color="white-light"
              styleProps="hover:cursor-pointer"
              onClick={zoomOut}
            />
            <Plus
              size={24}
              color="white-light"
              styleProps="hover:cursor-pointer"
              onClick={zoomIn}
            />
            <Button
              label={isSizeToFit ? 'Fill Screen' : 'Size to Fit'}
              size="small"
              kind="outline"
              color="white-light"
              onClick={() => setIsSizeToFit(!isSizeToFit)}
            />
          </div>
          <Download
            size={24}
            color="white-light"
            styleProps="hover:cursor-pointer"
            onClick={download}
          />
        </div>
      }
    >
      {ZoomView}
    </Layout>
  );
};

export const ModalViewImage = ({
  onClose,
  fileUrl,
  fileName,
  modelName,
  modelId,
  filePath,
  fileId,
}) => {
  if (fileUrl)
    return (
      <ViewImageWithUrl
        onClose={onClose}
        fileUrl={fileUrl}
        fileName={fileName}
      />
    );
  return (
    <ViewImage
      onClose={onClose}
      modelName={modelName}
      modelId={modelId}
      filePath={filePath}
      fileId={fileId}
    />
  );
};
