import React from 'react';

const Catalog = ({
  color = 'green-main',
  size = 30,
  styleProps = '',
  ...rest
}) => (
  <svg
    width={size.toString()}
    height={size.toString()}
    viewBox="0 0 75 75"
    className={`fill-${color} ${styleProps}`}
    {...rest}
  >
    <path d="M64.7753 0H10.2152C4.57595 0 0 4.58544 0 10.2152V64.7848C0 70.4241 4.57595 75 10.2152 75H64.7753C70.4146 75 75 70.4241 75 64.7848V10.2152C75 4.58544 70.4146 0 64.7753 0ZM6.64557 10.2152C6.64557 8.25 8.24051 6.64557 10.2152 6.64557H64.7753C66.75 6.64557 68.3544 8.25 68.3544 10.2152V34.1772H6.64557V10.2152ZM68.3544 64.7848C68.3544 66.7595 66.75 68.3544 64.7753 68.3544H10.2152C8.24051 68.3544 6.64557 66.7595 6.64557 64.7848V40.8228H68.3544V64.7848Z" />
    <path d="M25.2816 18.2278C23.9715 18.2278 22.9082 17.1646 22.9082 15.8544C22.9082 14.5443 23.9715 13.481 25.2816 13.481H49.7184C51.0285 13.481 52.0918 14.5443 52.0918 15.8544C52.0918 17.1646 51.0285 18.2278 49.7184 18.2278H25.2816Z" />
    <path d="M25.2816 52.6899C23.9715 52.6899 22.9082 51.6266 22.9082 50.3165C22.9082 49.0063 23.9715 47.943 25.2816 47.943H49.7184C51.0285 47.943 52.0918 49.0063 52.0918 50.3165C52.0918 51.6266 51.0285 52.6899 49.7184 52.6899H25.2816Z" />
  </svg>
);

export { Catalog };
