import React, { useCallback, useMemo } from 'react';
import { Transition } from '@headlessui/react';
import { useMap, useModel, useQueryParam } from '@thrivelot/hooks';
import { Button, Card, Cancel } from '@thrivelot/stories';
import { TagIdType } from '@thrivelot/aws';
import { HomeInfoBox } from './HomeInfoBox';
import { ZoneInfoBox } from './ZoneInfoBox';
import { ZoneFeatures } from './ZoneFeatures';
import { Confirm } from '../Confirm';

const MapProjectInfoBox = ({ projectId }) => {
  const { infoBoxId, toggleInfoBox } = useMap();
  const { setParam } = useQueryParam('map');

  const { actions, update, loaded } = useModel({
    modelName: 'Project',
    id: projectId,
  });

  const header = useMemo(() => {
    if (infoBoxId === 'address') return 'Home Address';
    if (!infoBoxId) return '';
    return 'Work Area';
  }, [infoBoxId]);

  const path = useMemo(
    () => infoBoxId && `zones[id:${infoBoxId}]`,
    [infoBoxId]
  );

  const close = useCallback(() => {
    toggleInfoBox(null);
  }, [toggleInfoBox]);

  const relocate = useCallback(() => {
    setParam({ zoneEditingId: infoBoxId });
    toggleInfoBox(null);
  }, [infoBoxId, setParam, toggleInfoBox]);

  const deleteZone = useCallback(() => {
    if (!path) return;
    update(actions.remove(path).result);
    toggleInfoBox(null);
  }, [update, actions, path, toggleInfoBox]);

  const addFeature = useCallback(() => {
    if (!path) return;
    const newFeature = actions.constructFeature({});
    update(
      actions.add('features', newFeature, 0).add(
        `zones[id:${infoBoxId}].orderedChildTagIds`,
        {
          type: TagIdType.FEATURE,
          tagId: newFeature.id,
        },
        0
      ).result
    );
  }, [update, actions, path]);

  const Content = useMemo(
    () => (header === 'Home Address' ? HomeInfoBox : ZoneInfoBox),
    [header]
  );

  return (
    <Transition
      show={!!infoBoxId}
      enter="transition-opacity duration-150"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leave="transition-opacity duration-50"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
      <Card
        header={header}
        headerActions={
          <Cancel
            color="orange-light"
            styleProps="hover:cursor-pointer"
            size="20"
            onClick={close}
          />
        }
        footer={
          <div className="flex gap-2">
            <div className="flex-1">
              <Confirm
                prompt="Are you sure you want to delete this work area?"
                onConfirm={deleteZone}
              >
                <Button
                  classAppend="w-full"
                  icon="Trash"
                  label="Delete"
                  color="red-main"
                  kind="outline"
                />
              </Confirm>
            </div>
            <Button
              icon="Pin"
              classAppend="flex-1"
              kind="outline"
              color="blue-main"
              label="Relocate"
              onClick={relocate}
            />
          </div>
        }
        classAppend="absolute top-3 overflow-y-scroll left-3 right-3 bottom-3 md:w-1/2 lg:w-1/3 md:right-[null] lg:right-[null]"
      >
        <div className="">
          {infoBoxId && <Content projectId={projectId} />}
          {infoBoxId && (
            <div>
              <div className="flex justify-between">
                <div className="font-bold text-lg">Features</div>
                <Button
                  icon="Plus"
                  kind="prompt"
                  size="small"
                  color="green-main"
                  label="Add Feature"
                  onClick={() => addFeature()}
                />
              </div>
              <ZoneFeatures projectId={projectId} />
            </div>
          )}
        </div>
      </Card>
    </Transition>
  );
};

export { MapProjectInfoBox };
