import { constructBuffer } from '@thrivelot/utils';
import { client } from './client';
import { s3 } from './s3';

interface StorageKitProps {
  noEncoding?: boolean;
  key?: string;
  prefix?: string;
  body?: string | Buffer;
  metadata?: { [key: string]: string };
  bucket?: string;
  region?: string;
  fetchCredentials?: () => Promise<any>;
  keyPrefix?: string;
}

class StorageKit {
  #key;

  #prefix;

  #body;

  #metadata;

  noEncoding: boolean | undefined;

  constructor(props?: StorageKitProps) {
    this.#init(props);
  }

  #init(props?: StorageKitProps) {
    if (props?.noEncoding) this.noEncoding = props.noEncoding;
    if (props?.key) this.key = props.key;
    if (props?.body) this.body = props.body;
    if (props?.metadata) this.metadata = props.metadata;
    if (props?.prefix) this.prefix = props.prefix;
    if (props?.bucket) client.setBucket(props.bucket);
    if (props?.region) client.setRegion(props.region);
    if (props?.fetchCredentials)
      client.setFetchCredentials(props.fetchCredentials);
    if (props?.keyPrefix) client.setKeyPrefix(props.keyPrefix);
  }

  set key(key) {
    this.#key = this.#constructKey(key);
  }

  get key() {
    return this.#key;
  }

  set prefix(prefix) {
    this.#prefix = this.#constructKey(prefix);
  }

  get prefix() {
    return this.#prefix;
  }

  set body(body) {
    let base64Body;

    if (/^@[a-zA-Z0-9+/]+={,2}$/.test(body)) base64Body = body;
    else if (!this.noEncoding) {
      const Buffer = constructBuffer();
      // eslint-disable-next-line new-cap
      base64Body = Buffer.from(body, 'base64');
    } else base64Body = body;

    this.#body = base64Body;
  }

  get body() {
    return this.#body;
  }

  set metadata(metadata) {
    this.#metadata = metadata;
  }

  get metadata() {
    return this.#metadata;
  }

  // eslint-disable-next-line class-methods-use-this
  #constructKey(key) {
    if (key.startsWith(client.keyPrefix) || !client.keyPrefix) return key;
    if (client.keyPrefix.endsWith('/')) return `${client.keyPrefix}${key}`;
    return `${client.keyPrefix}/${key}`;
  }

  async fetch(props?: StorageKitProps) {
    this.#init(props);

    try {
      const Storage = await s3({
        region: client.region,
        fetchCredentials: client.fetchCredentials,
      });

      const data = await Storage.getObject({
        Bucket: client.bucket,
        Key: this.key,
      }).promise();

      return data.Body.toString('utf-8');
    } catch (err) {
      console.error('Error in StorageKit fetch:', JSON.stringify(err, null, 2));
      throw err;
    }
  }

  async fetchSignedUrl(props?: StorageKitProps) {
    this.#init(props);

    try {
      const Storage = await s3({
        region: client.region,
        fetchCredentials: client.fetchCredentials,
      });

      const data = await Storage.getSignedUrlPromise('getObject', {
        Bucket: client.bucket,
        Key: this.key,
      });

      return data;
    } catch (err) {
      console.error(
        'Error in StorageKit fetchSignedUrl:',
        JSON.stringify(err, null, 2)
      );
      throw err;
    }
  }

  async list(props: { [key: string]: any } = {}) {
    this.#init(props);

    try {
      const Storage = await s3({
        region: client.region,
        fetchCredentials: client.fetchCredentials,
      });

      const data = await Storage.listObjectsV2({
        Bucket: client.bucket,
        Prefix: this.prefix,
        ...(props.ContinuationToken && {
          ContinuationToken: props.ContinuationToken,
        }),
      }).promise();

      const items = [
        ...props.items,
        ...(data?.Contents?.map(({ Key }) => Key) || []),
      ];

      if (data.NextContinuationToken)
        return this.list({
          ...props,
          ContinuationToken: data.NextContinuationToken,
          items,
        });

      return items;
    } catch (err) {
      console.error('Error in StorageKit list:', JSON.stringify(err, null, 2));
      throw err;
    }
  }

  async upload(props: { [key: string]: any } = {}) {
    this.#init(props);

    try {
      const Storage = await s3({
        region: client.region,
        fetchCredentials: client.fetchCredentials,
      });

      const data = await Storage.upload({
        Bucket: client.bucket,
        Key: this.key,
        Body: this.body,
        ...(this.metadata && { Metadata: this.metadata }),
      }).promise();

      this.#body = '';
      this.#metadata = null;

      return data;
    } catch (err) {
      console.error(
        'Error in StorageKit upload:',
        JSON.stringify(err, null, 2)
      );
      throw err;
    }
  }
}

export { StorageKit };
