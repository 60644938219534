import {
  createCustomer,
  createProject,
  createSupplier,
  createBundle,
  createInvoiceProposal,
  createCatalog,
  updateBundle,
  updateInvoiceProposal,
  updateCustomer,
  updateProject,
  updateSupplier,
  updateCatalog,
  getCustomer,
  getProject,
  getSupplier,
  getBundle,
  getInvoiceProposal,
  getCatalog,
  listCustomers,
  listProjects,
  listSuppliers,
  listBundles,
  listInvoiceProposals,
  listCatalogs,
  createSubscriptionNotification,
  onSubscriptionNotification,
} from '@thrivelot/aws';
import {
  customerActions,
  projectActions,
  supplierActions,
  bundleActions,
  invoiceProposalActions,
  catalogActions,
} from './actions';

const modelConfig = {
  queryNameRegex: /\b([a-z]+[A-Z][a-zA-Z]*)\(/m, // Used for plucking the query/mutation name out of the gql string for parsing the response
  invalidUpdateKeys: [
    'customer',
    'projects',
    'updatedAt',
    'createdAt',
    '__typename',
  ],
  subscriptions: {
    SubscriptionNotification: {
      subscription: {
        gql: onSubscriptionNotification,
      },
    },
  },
  models: {
    Customer: {
      index: {
        gql: listCustomers,
      },
      read: {
        gql: getCustomer,
      },
      create: {
        gql: createCustomer,
      },
      update: {
        gql: updateCustomer,
      },
      actions: (model) => customerActions(model),
    },
    Project: {
      index: {
        gql: listProjects,
      },
      read: {
        gql: getProject,
      },
      create: {
        gql: createProject,
      },
      update: {
        gql: updateProject,
      },
      actions: (model) => projectActions(model),
    },
    SubscriptionNotification: {
      create: {
        gql: createSubscriptionNotification,
      },
    },
    Supplier: {
      index: {
        gql: listSuppliers,
      },
      read: {
        gql: getSupplier,
      },
      create: {
        gql: createSupplier,
      },
      update: {
        gql: updateSupplier,
      },
      actions: (model) => supplierActions(model),
    },
    Bundle: {
      index: {
        gql: listBundles,
      },
      read: {
        gql: getBundle,
      },
      create: {
        gql: createBundle,
      },
      update: {
        gql: updateBundle,
      },
      actions: (model) => bundleActions(model),
    },
    InvoiceProposal: {
      index: {
        gql: listInvoiceProposals,
      },
      read: {
        gql: getInvoiceProposal,
      },
      create: {
        gql: createInvoiceProposal,
      },
      update: {
        gql: updateInvoiceProposal,
      },
      actions: (model) => invoiceProposalActions(model),
    },
    Catalog: {
      index: {
        gql: listCatalogs,
      },
      read: {
        gql: getCatalog,
      },
      create: {
        gql: createCatalog,
      },
      update: {
        gql: updateCatalog,
      },
      actions: (model) => catalogActions(model),
    },
  },
  onQueryError: (errors) => {
    console.error('onQueryError: ', errors);
    window.showNotification({ type: 'error', body: 'Error loading' });
  },
  onSaveError: (errors) => {
    console.error('onSaveError: ', errors);
    window.showNotification({
      type: 'error',
      title: 'Autosave Error',
      body: 'There was a problem saving your data.',
    });
  },
  onSubscriptionError: (errors) => {
    console.error('onSubscriptionError: ', errors);
  },
  onFileQueryError: (errors) => {
    console.error('onFileQueryError: ', errors);
    window.showNotification({
      type: 'error',
      title: 'File Error',
      body: 'There was a problem loading a file',
    });
  },
  onFileSaveError: (errors) => {
    console.error('onFileSaveError: ', errors);
    window.showNotification({
      type: 'error',
      title: 'File Error',
      body: 'There was a problem saving a file',
    });
  },
  onUpdateVariableInput: (input) =>
    // Can overwrite on passing into ModelProvider to add default variables
    input,
};

export { modelConfig };
