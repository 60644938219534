import React from "react";
import ReactDOM from "react-dom";
import { useRouter } from "@thrivelot/common";
import { DivModalShadow } from "@thrivelot/styles";
import { LayoutModal } from "../../../../common";
import { ModalHashmap } from "./ModalHashmap";

const Modal = () => {
  // hooks
  const { hash, history, urlHashes, urlModalValues } = useRouter();
  // breakout
  if (!hash[urlHashes.proposalModal]) return null;
  // utils
  const isFileModal = () => hash[urlHashes.proposalModal] === urlModalValues.proposalFile;
  // navigation
  const onClickClose = () => {
    history.push({ hash: {} });
  };
  // components
  const ModalContent = ModalHashmap[hash[urlHashes.proposalModal]];
  return ReactDOM.createPortal(
    <>
      <DivModalShadow />
      {isFileModal() ? (
        <ModalContent onClickClose={onClickClose} />
      ) : (
        <LayoutModal onClose={onClickClose} width="full">
          <ModalContent />
        </LayoutModal>
      )}
    </>,
    document.getElementById("proposal-modal")
  );
};

export { Modal };
