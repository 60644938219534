import React from 'react';

const Bank = ({
  color = 'green-main',
  size = 30,
  styleProps = '',
  ...rest
}) => (
  <svg
    width={size.toString()}
    height={size.toString()}
    viewBox="0 0 75 75"
    className={`fill-${color} ${styleProps}`}
    {...rest}
  >
    <path d="M70.3481 19.0712L42.7215 5.21044C39.5886 3.59652 35.4114 3.59652 32.2785 5.21044L4.6519 19.0712C1.8038 20.5902 0 23.2484 0 26.0965C0 27.9003 1.51899 29.4193 3.32278 29.4193H8.82911V53.3434C6.17089 54.1029 4.27215 56.4763 4.27215 59.3244V66.3497C4.27215 69.7674 7.02532 72.5206 10.443 72.5206H64.557C67.9747 72.5206 70.7279 69.7674 70.7279 66.3497V59.3244C70.7279 56.4763 68.8291 54.1029 66.1709 53.3434V29.4193H71.6772C73.481 29.4193 75 27.9003 75 26.0965C75 23.2484 73.1962 20.5902 70.3481 19.0712ZM35.2215 11.0965C36.5506 10.432 38.3544 10.432 39.6835 11.0965L62.7532 22.7737H12.1519L35.2215 11.0965ZM59.5253 29.4193V53.0585H53.4494V29.4193H59.5253ZM46.8987 29.4193V53.0585H28.1013V29.4193H46.8987ZM21.4557 29.4193V53.0585H15.3797V29.4193H21.4557ZM64.0823 65.875H10.9177V59.7041H64.0823V65.875Z" />
  </svg>
);

export { Bank };
