import React from "react";
import { Button, Card } from "@thrivelot/stories";

const CardTLResources = () => {
  const resourcesUrl = "https://thrivelot.notion.site/Downloadable-Assets-48f1322cd3224b1489551c98e7e81b4f";

  return (
    <Card
      header="Thrive Lot Resources"
      footer={
        <a target="_blank" href={resourcesUrl} rel="noreferrer">
          <Button icon="Visibility" color="blue-main" block kind="outline">
            View Resources
          </Button>
        </a>
      }
    >
      <div className="text-brown-dark text-center px-2 py-5">
        We've created some useful templates for accelerating the design process. Check them out here!
      </div>
    </Card>
  );
};

export { CardTLResources };
