import { cleanObject, isEqual } from '@thrivelot/utils';
import { client } from './client';

const constructUnsavedUpdates = (updates, model) => {
  if (!updates?.id) return updates;

  const newUpdates = {};
  const keys = Object.keys(updates);

  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < keys.length; i++) {
    const key = keys[i];

    const updating = cleanObject(updates[key], ['__typename']);
    const existing = cleanObject(model?.[key] || {}, ['__typename']);

    if (
      !client.modelConfig?.invalidUpdateKeys?.includes(key) &&
      updating !== null &&
      updating !== undefined &&
      !isEqual(updating, existing)
    )
      newUpdates[key] = updating;
    else if (
      !client.modelConfig?.invalidUpdateKeys?.includes(key) &&
      updating === undefined
    )
      newUpdates[key] = null;
  }

  return newUpdates;
};

export { constructUnsavedUpdates };
