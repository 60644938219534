import React from 'react';

const Print = ({
  color = 'green-main',
  size = 30,
  styleProps = '',
  ...rest
}) => (
  <svg
    width={size.toString()}
    height={size.toString()}
    viewBox="0 0 75 75"
    className={`fill-${color} ${styleProps}`}
    {...rest}
  >
    <path d="M63.3703 14.4525V14.2816C63.3703 6.96203 57.4082 1 50.0886 1H27.2848C19.9652 1 14.0032 6.96203 14.0032 14.2816V14.3481H13.2816C5.96203 14.3481 0 20.3101 0 27.6297V50.4335C0 57.7532 5.96203 63.7152 13.2816 63.7152H13.6709V66.5253C13.6709 70.731 17.0981 74.1582 21.3038 74.1582H53.6962C57.9019 74.1582 61.3291 70.731 61.3291 66.5253V63.7152H61.7184C69.038 63.7152 75 57.7532 75 50.4335V27.6297C75 20.8703 69.9114 15.269 63.3703 14.4525ZM20.6487 14.2816C20.6487 10.6266 23.6297 7.64557 27.2848 7.64557H50.0886C53.7437 7.64557 56.7247 10.6266 56.7247 14.2816V14.3481H20.6487V14.2816ZM54.6835 66.5253C54.6835 67.0665 54.2373 67.5127 53.6962 67.5127H21.3038C20.7627 67.5127 20.3165 67.0665 20.3165 66.5253V49.5127C20.3165 48.9715 20.7627 48.5253 21.3038 48.5253H53.6962C54.2373 48.5253 54.6835 48.9715 54.6835 49.5127V66.5253ZM68.3544 50.4335C68.3544 54.0886 65.3734 57.0696 61.7184 57.0696H61.3291V49.5127C61.3291 45.307 57.9019 41.8797 53.6962 41.8797H21.3038C17.0981 41.8797 13.6709 45.307 13.6709 49.5127V57.0696H13.2816C9.62658 57.0696 6.64557 54.0886 6.64557 50.4335V27.6297C6.64557 23.9747 9.62658 20.9937 13.2816 20.9937H61.7184C65.3734 20.9937 68.3544 23.9747 68.3544 27.6297V50.4335Z" />
    <path d="M49.6898 52.3227C49.6898 53.1107 49.0537 53.7468 48.2657 53.7468H26.7341C25.9461 53.7468 25.3101 53.1107 25.3101 52.3227C25.3101 51.5348 25.9461 50.8987 26.7341 50.8987H48.2657C49.0537 50.8987 49.6898 51.5348 49.6898 52.3227Z" />
    <path d="M49.6898 58.019C49.6898 58.807 49.0537 59.4431 48.2657 59.4431H26.7341C25.9461 59.4431 25.3101 58.807 25.3101 58.019C25.3101 57.6488 25.4525 57.3165 25.6803 57.0697C25.9366 56.7754 26.3164 56.595 26.7341 56.595H48.2657C48.6835 56.595 49.0632 56.7754 49.3195 57.0697C49.5474 57.3165 49.6898 57.6488 49.6898 58.019Z" />
    <path d="M14.7154 24.1743C12.0477 24.1743 9.87366 26.3484 9.87366 29.0161C9.87366 31.6838 12.0477 33.8579 14.7154 33.8579C17.3831 33.8579 19.5572 31.6838 19.5572 29.0161C19.5572 26.3484 17.3831 24.1743 14.7154 24.1743ZM14.7154 31.0098C13.6142 31.0098 12.7218 30.1174 12.7218 29.0161C12.7218 27.9148 13.6142 27.0224 14.7154 27.0224C15.8167 27.0224 16.7091 27.9148 16.7091 29.0161C16.7091 30.1174 15.8167 31.0098 14.7154 31.0098Z" />
    <path d="M49.6898 63.7151C49.6898 64.503 49.0537 65.1391 48.2657 65.1391H26.7341C25.9461 65.1391 25.3101 64.503 25.3101 63.7151C25.3101 62.9271 25.9461 62.291 26.7341 62.291H48.2657C49.0537 62.291 49.6898 62.9271 49.6898 63.7151Z" />
  </svg>
);

export { Print };
