import styled, { css } from "styled-components";
import colors from "@thrivelot/tailwind/colors";
import CssDivDefault from "./CssDivDefault";

const leftRight = (isFirstOrLast, isSmall) => {
  if (!isFirstOrLast) return "0px";
  if (isSmall) return "5px";
  return "12px";
};

const positions = ({ isFirst, isLast, isSmall }) => css`
  left: ${leftRight(isFirst, isSmall)};
  right: ${leftRight(isLast, isSmall)};
  bottom: ${isSmall ? "5px" : "12px"};
  height: ${isSmall ? "12px" : "30px"};
`;

const firstBorderRadius = ({ isFirst }) => (isFirst ? "5px" : "0px");

const lastBorderRadius = ({ isLast }) => (isLast ? "5px" : "0px");

const DivCalendarPhaseLine = styled.div`
  ${CssDivDefault}
  display: flex;
  position: absolute;
  align-items: center;
  border-top-left-radius: ${firstBorderRadius};
  border-bottom-left-radius: ${firstBorderRadius};
  border-top-right-radius: ${lastBorderRadius};
  border-bottom-right-radius: ${lastBorderRadius};
  background-color: ${colors.green.light};
  @media (max-width: 767px) {
    ${({ isFirst, isLast }) => positions({ isFirst, isLast, isSmall: true })}
  }
  @media (min-width: 768px) {
    ${positions}
  }
`;

export default DivCalendarPhaseLine;
