import { useModel } from '@thrivelot/hooks';
import React, { useMemo } from 'react';
import ReactTextareaAutosize from 'react-textarea-autosize';
import DatePicker from 'react-datepicker';
import {
  CardLoader,
  Button,
  Card,
  Selector,
  DateDone,
  DateStart,
} from '@thrivelot/stories';
import { transformStringToTitleCase } from '@thrivelot/utils';
import { dateFns, formatLongDate } from '@thrivelot/date';
import { Confirm } from '../Confirm';

const FormInvoiceProposalPhase = ({ invoiceProposalId, phaseId }) => {
  const {
    model: invoiceProposal,
    loaded,
    actions,
    updateModel,
  } = useModel({ modelName: 'InvoiceProposal', id: invoiceProposalId });

  const phase = useMemo(
    () => loaded && actions.get(`phases[id:${phaseId}]`),
    [loaded, actions, phaseId]
  );

  if (!loaded) return <CardLoader />;

  const { status } = invoiceProposal;
  const { name, description, kind, dates } = phase || {};

  return (
    <Card>
      <div className="flex flex-col gap-3">
        <div>
          <div className="text-brown-dark text-sm font-bold mb-1">Name</div>
          <div className="flex">
            <input
              className="input mr-2"
              placeholder="Add a name for this phase..."
              disabled={status !== 'draft'}
              value={name || ''}
              onChange={(e) =>
                updateModel(
                  actions.set(`phases[id:${phaseId}].name`, e.target.value)
                    .result
                )
              }
            />
            {status === 'draft' && (
              <Confirm
                prompt="Are you sure you want to delete this phase?"
                onConfirm={() =>
                  updateModel(actions.remove(`phases[id:${phaseId}]`).result)
                }
              >
                <Button
                  color="red-main"
                  kind="prompt"
                  icon="Trash"
                  size="small"
                />
              </Confirm>
            )}
          </div>
        </div>
        <div>
          <div className="text-brown-dark text-sm font-bold mb-1">Type</div>
          <div className="flex gap-2 items-center">
            <Selector
              color="green-main"
              size="sm"
              selected={kind === 'date'}
              disabled={status !== 'draft'}
              onClick={() =>
                updateModel(
                  actions.set(`phases[id:${phaseId}].kind`, 'date').result
                )
              }
            >
              Single Date
            </Selector>
            <Selector
              color="green-main"
              size="sm"
              selected={kind === 'range'}
              disabled={status !== 'draft'}
              onClick={() =>
                updateModel(
                  actions.set(`phases[id:${phaseId}].kind`, 'range').result
                )
              }
            >
              Date Range
            </Selector>
          </div>
        </div>
        <div>
          <div className="text-brown-dark text-sm font-bold mb-1">
            {transformStringToTitleCase(kind)}
          </div>
          <div className="flex gap-2">
            <DatePicker
              popperPlacement="bottom-start"
              showPopperArrow={false}
              selected={(dates || [])[0] && dateFns(dates[0]).toDate()}
              disabled={status !== 'draft'}
              onChange={(date) =>
                updateModel(
                  actions.set(
                    `phases[id:${phaseId}].dates[index:0]`,
                    date.toISOString()
                  ).result
                )
              }
              customInput={
                <div className="flex items-center gap-2 input hover:cursor-pointer">
                  {kind === 'date' ? (
                    <DateStart color="brown-dark" size={16} />
                  ) : (
                    <DateDone color="brown-dark" size={16} />
                  )}
                  {(dates || [])[0] ? (
                    <div>{formatLongDate(dates[0])}</div>
                  ) : (
                    <div className="italic">
                      {status !== 'draft'
                        ? 'No date selected...'
                        : 'Select a date...'}
                    </div>
                  )}
                </div>
              }
            />
            {kind === 'range' && (
              <DatePicker
                popperPlacement="bottom-start"
                showPopperArrow={false}
                selected={(dates || [])[1] && dateFns(dates[1]).toDate()}
                disabled={status !== 'draft'}
                onChange={(date) =>
                  updateModel(
                    actions.set(
                      `phases[id:${phaseId}].dates[index:1]`,
                      date.toISOString()
                    ).result
                  )
                }
                customInput={
                  <div className="flex items-center gap-2 input hover:cursor-pointer">
                    <DateDone color="brown-dark" size={16} />
                    {(dates || [])[1] ? (
                      <div>{formatLongDate(dates[1])}</div>
                    ) : (
                      <div className="italic">
                        {status !== 'draft'
                          ? 'No date selected...'
                          : 'Select a date...'}
                      </div>
                    )}
                  </div>
                }
              />
            )}
          </div>
        </div>
        <div>
          <div className="text-brown-dark text-sm font-bold mb-1">
            Description
          </div>
          <ReactTextareaAutosize
            className="w-full input"
            placeholder={
              status !== 'draft'
                ? 'No description...'
                : `Describe the work to be done ${
                    kind === 'range'
                      ? 'within this timeframe...'
                      : 'on or by this date...'
                  }`
            }
            value={description || ''}
            disabled={status !== 'draft'}
            minRows={3}
            onChange={(e) =>
              updateModel(
                actions.set(`phases[id:${phaseId}].description`, e.target.value)
                  .result
              )
            }
          />
        </div>
      </div>
    </Card>
  );
};

export { FormInvoiceProposalPhase };
