import React from "react";
import { useTheme } from "@thrivelot/hooks";
import { transformCurrencyToString } from "@thrivelot/utils";
import { TextBodyBold, TextHeader } from "@thrivelot/styles";
import { MiddleColumnContainer } from "./MiddleColumnContainer";

const MiddleColumn = ({ cost }) => {
  const theme = useTheme();
  return (
    <MiddleColumnContainer>
      <TextBodyBold>Items</TextBodyBold>
      <TextHeader style={{ color: theme.textTitle }}>{transformCurrencyToString(cost)}</TextHeader>
    </MiddleColumnContainer>
  );
};

export { MiddleColumn };
