import styled from "styled-components";
import CssTextDefault from "./CssTextDefault";

const colorConstructor = ({ color, isLightText, theme }) => {
  if (color) return color;
  if (isLightText) return theme.textLight;
  return theme.text;
};

const TextBody = styled.span`
  ${CssTextDefault}
  font-family: Rokkitt-Bold;
  color: ${colorConstructor};
  @media (max-width: 767px) {
    font-size: 14px;
  }
  @media (min-width: 768px) {
    font-size: 17px;
  }
`;

export default TextBody;
