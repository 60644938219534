import { fetchUser } from "./fetchUser";
import { fetchUserSession } from "./fetchUserSession";

const refreshUserSession = () =>
  fetchUser().then((user) =>
    fetchUserSession().then(
      (session) =>
        new Promise((resolve, reject) =>
          user.refreshSession(session.getRefreshToken(), (err, refreshedSession) => {
            if (err) return reject(err);
            resolve(refreshedSession);
          })
        )
    )
  );

export { refreshUserSession };
