import React from "react";
import { DivCircle, DivModalContent } from "@thrivelot/styles";
import { usePlantInfo } from "./hooks";
import { PicAndName } from "./PicAndName";
import { Notes } from "./Notes";

const PlantModal = () => {
  // hooks
  const plantInfo = usePlantInfo();
  // return
  return (
    <>
      <DivCircle style={{ padding: "0px", display: "flex", flexDirection: "column" }}>
        <PicAndName plantInfo={plantInfo} />
        <Notes plantInfo={plantInfo} />
        <DivModalContent
          shadowBackground
          size={30}
          style={{
            position: "absolute",
            top: "3px",
            right: "3px",
          }}
        />
      </DivCircle>
    </>
  );
};

export { PlantModal };
