import { useModel, useModal } from '@thrivelot/hooks';
import {
  LeafLoader,
  Cancel,
  EmptyState,
  Button,
  Dropdown,
  AngleDown,
} from '@thrivelot/stories';
import { lineItemColorMap, lineItemIconMap } from '@thrivelot/config';
import { constructUuid, isAdminApp } from '@thrivelot/utils';
import React, { useCallback, useState, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { RowItemForm } from '../RowItemForm';

export const Catalog = ({ catalogId }) => {
  const { openModal } = useModal();
  const {
    model: catalog,
    loaded,
    actions,
    updateModel,
  } = useModel({
    modelName: 'Catalog',
    id: catalogId,
  });

  const [filter, setFilter] = useState({});
  const [query, setQuery] = useState('');

  const resultItems = useMemo(() => {
    const items = catalog?.items || [];
    const filteredItems = items.filter((item) => {
      const itemMatchString =
        `${item.kind}${item.name}${item.description}`.toLowerCase();
      const queryMatch =
        query.length === 0 || itemMatchString.includes(query.toLowerCase());
      const filterMatch = Object.keys(filter).length === 0 || filter[item.kind];
      return queryMatch && filterMatch;
    });
    return filteredItems;
  }, [catalog?.items, query, filter]);

  const toggleFilter = useCallback(
    (kind) => {
      setFilter((prevFilter) => {
        const newFilter = { ...prevFilter };
        if (newFilter[kind]) delete newFilter[kind];
        else newFilter[kind] = true;
        return newFilter;
      });
    },
    [filter]
  );

  return (
    <div>
      <div className="flex gap-4 items-center">
        <div className="relative flex-1">
          <input
            className="input !pr-7"
            disabled={!loaded}
            placeholder={
              loaded
                ? 'Search for an item in the catalog...'
                : 'Loading catalog...'
            }
            value={query}
            onChange={(e) => setQuery(e.target.value)}
          />
          {query.length > 0 && (
            <div className="absolute inset-y-0 right-0 pr-3 flex items-center">
              <Cancel
                color="yellow-main"
                size={16}
                styleProps="hover:cursor-pointer"
                onClick={() => setQuery('')}
              />
            </div>
          )}
        </div>
        <div className="flex gap-2 items-center">
          <Button
            size="small"
            kind="outline"
            icon="Minus"
            color="red-main"
            disabled={Object.keys(filter).length === 0}
            onClick={() => setFilter({})}
          />
          <Button
            size="small"
            kind={filter.equipment ? undefined : 'outline'}
            icon={lineItemIconMap.equipment}
            color={lineItemColorMap.equipment}
            onClick={() => toggleFilter('equipment')}
          />
          <Button
            size="small"
            kind={filter.material ? undefined : 'outline'}
            icon={lineItemIconMap.material}
            color={lineItemColorMap.material}
            onClick={() => toggleFilter('material')}
          />
          <Button
            size="small"
            kind={filter.labor ? undefined : 'outline'}
            icon={lineItemIconMap.labor}
            color={lineItemColorMap.labor}
            onClick={() => toggleFilter('labor')}
          />
          <Button
            size="small"
            kind={filter.plant ? undefined : 'outline'}
            icon={lineItemIconMap.plant}
            color={lineItemColorMap.plant}
            onClick={() => toggleFilter('plant')}
          />
        </div>
      </div>
      {!loaded && <LeafLoader classAppend="mt-4" />}
      {loaded && (catalog?.items?.length === 0 || !catalog?.items) && (
        <EmptyState
          classAppend="mt-4"
          icon="LineItems"
          header="No catalog items yet..."
          children={
            <div>
              Create a catalog item or import items from another existing
              projects
            </div>
          }
          footer={
            <div className="flex gap-6 justify-center">
              <div
                onClick={() =>
                  openModal({
                    path: 'ModalUpsertCatalogItem',
                    catalogId,
                  })
                }
              >
                <Button
                  label="Add Item"
                  size="small"
                  kind="outline"
                  icon="Plus"
                  color="blue-main"
                />
              </div>
              <Link to="import">
                <Button
                  label="Import Items"
                  size="small"
                  kind="outline"
                  icon="Plus"
                  color="blue-main"
                />
              </Link>
            </div>
          }
        />
      )}
      {resultItems.length > 0 && (
        <div className="mt-4 flex flex-col divide-y divide-tan-light">
          {resultItems.map((item) => (
            <RowItemForm
              key={item.id}
              itemPath="items"
              modelName="Catalog"
              modelId={catalogId}
              item={item}
              selectDisabled={true}
              disabledMap={{ quantity: true }}
              renderItemActions={(_item) => {
                const options = [
                  {
                    icon: 'Edit',
                    label: 'Edit item details',
                    onClick: () =>
                      openModal({
                        path: 'ModalUpsertCatalogItem',
                        catalogId,
                        itemId: _item.id,
                      }),
                  },
                  {
                    icon: 'Duplicate',
                    label: 'Duplicate item',
                    onClick: () =>
                      updateModel(
                        actions.add(
                          'items',
                          { ..._item, id: constructUuid() },
                          0
                        ).result
                      ),
                  },
                  {
                    icon: 'Trash',
                    label: 'Delete item',
                    color: 'red-main',
                    onClick: () =>
                      updateModel(
                        actions.remove(`items[id:${_item.id}]`).result
                      ),
                  },
                ];

                if (_item.kind === 'plant' && !_item.data?.plantDatabaseId)
                  options.splice(1, 0, {
                    icon: 'Link',
                    label: 'Link plant to DB',
                    onClick: () =>
                      openModal({
                        path: 'ModalLinkCatalogPlantToDB',
                        description: `Link ${_item.name} to a plant database entry`,
                        catalogId,
                        itemId: _item.id,
                      }),
                  });
                else if (item?.data?.plantDatabaseId && isAdminApp())
                  options.splice(1, 0, {
                    icon: 'Leaf',
                    label: 'View/Edit plant DB details',
                    onClick: () =>
                      openModal({
                        path: 'ModalEditPlant',
                        modelId: item.data.plantDatabaseId,
                      }),
                  });
                else if (item?.data?.plantDatabaseId)
                  options.splice(1, 0, {
                    icon: 'Leaf',
                    label: 'View plant DB details',
                    onClick: () =>
                      openModal({
                        path: 'ModalViewPlant',
                        modelId: item.data.plantDatabaseId,
                      }),
                  });

                return (
                  <Dropdown
                    label={<AngleDown size={16} />}
                    optionClass="font-bold"
                    align="right"
                    options={options}
                  />
                );
              }}
            />
          ))}
        </div>
      )}
    </div>
  );
};
