import React from 'react';
import { useModel } from '@thrivelot/hooks';
import { LeafLoader } from '@thrivelot/stories';
import { ImageUserAvatar } from '../ImageUserAvatar';

const BundleSupplier = ({ supplierId }) => {
  const { model: supplier, loaded } = useModel({
    modelName: 'Supplier',
    id: supplierId,
  });

  if (!supplierId) return null;
  if (!loaded) return <LeafLoader />;

  const { businessName, firstname, lastname } = supplier;
  const supplierName = `${firstname.trim()} ${lastname.trim()}`;

  return (
    <div className="flex items-center text-brown-dark gap-1">
      <ImageUserAvatar size={20} user={supplier} />
      <div className="font-bold">{businessName || supplierName}</div>
      <div className="text-sm">{businessName ? supplierName : null}</div>
    </div>
  );
};

export { BundleSupplier };
