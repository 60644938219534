import { get } from "@thrivelot/actions";
import { calculateDesignCost } from "./calculateDesignCost";
import { calculateInvoiceSubtotalAndMarkup } from "./calculateInvoiceSubtotalAndMarkup";

const calculateInvoiceCost = (model, invoiceId) => {
  const invoice = get(model, `costPaymentSchedule[id:${invoiceId}]`);
  if (invoice.type === "DESIGN") {
    const { designCost, dollarMarkup } = calculateDesignCost(model);
    return { invoiceCost: designCost, dollarMarkup };
  }
  const { subtotal, markup } = calculateInvoiceSubtotalAndMarkup(model, invoiceId);
  const multiplier = markup + 1;
  const invoiceCost = subtotal * multiplier;
  return { invoiceCost, dollarMarkup: invoiceCost - subtotal };
};

export { calculateInvoiceCost };
