import React from 'react';

const Expand = ({
  color = 'green-main',
  size = 30,
  styleProps = '',
  ...rest
}) => (
  <svg
    width={size.toString()}
    height={size.toString()}
    viewBox="0 0 75 75"
    className={`fill-${color} ${styleProps}`}
    {...rest}
  >
    <path d="M61.7089 75H13.2911C5.98101 75 0 69.019 0 61.7089V13.2911C0 5.98101 5.98101 0 13.2911 0H37.5C39.3038 0 40.8228 1.51899 40.8228 3.32278C40.8228 5.12658 39.3038 6.64557 37.5 6.64557H13.2911C9.58861 6.64557 6.64557 9.58861 6.64557 13.2911V61.7089C6.64557 65.4114 9.58861 68.3544 13.2911 68.3544H61.7089C65.4114 68.3544 68.3544 65.4114 68.3544 61.7089V37.5C68.3544 35.6962 69.8734 34.1772 71.6772 34.1772C73.481 34.1772 75 35.6962 75 37.5V61.7089C75 69.019 69.019 75 61.7089 75Z" />
    <path d="M74.8103 3.32278V20.4114C74.8103 22.2152 73.2913 23.7342 71.4875 23.7342C69.6837 23.7342 68.1647 22.2152 68.1647 20.4114V11.5823L39.8736 39.8734C39.209 40.538 38.3546 40.8228 37.5001 40.8228C36.6457 40.8228 35.7913 40.538 35.1267 39.8734C33.7976 38.5443 33.7976 36.4557 35.1267 35.2215L63.6077 6.74051H54.3039C52.5001 6.74051 50.9812 5.22152 50.9812 3.41772C50.9812 1.61392 52.5951 0 54.3989 0H71.4875C73.2913 0 74.8103 1.51899 74.8103 3.32278Z" />
  </svg>
);

export { Expand };
