import { isNumber } from "@thrivelot/utils";
import findObjectInArrayByIndex from "./findObjectInArrayByIndex";
import findObjectInArrayByProperty from "./findObjectInArrayByProperty";

const findNewObjectByPath = (deepCopiedObj, currentPath, findByVar, returnWithIndex) => {
  if (findByVar && isNumber(findByVar)) return findObjectInArrayByIndex(deepCopiedObj, currentPath, findByVar);
  if (findByVar) return findObjectInArrayByProperty(deepCopiedObj, currentPath, findByVar, returnWithIndex);
  return deepCopiedObj[currentPath];
};

export default findNewObjectByPath;
