import React, { useMemo } from "react";
import { dateFns } from "@thrivelot/date";
import { Card } from "@thrivelot/stories";
import { CardLoader } from "@thrivelot/stories";
import { useModelList } from "@thrivelot/hooks";
import { Calendar } from "..";

const isActive = ({ status }) => status !== "draft" && status !== "submitted";

const InvoiceProposalProjectCalendar = ({ projectId, notes, canEdit }) => {
  const { models: invoiceProposals, loaded } = useModelList({
    modelName: "InvoiceProposal",
    filter: { projectId: { eq: projectId } },
  });

  const events = useMemo(() => {
    const datesWithPhases = [];

    invoiceProposals.filter(isActive).forEach((invoiceProposal) => {
      (invoiceProposal.phases || []).forEach((phase) => {
        const start = phase?.dates?.[0] || null;
        const end = phase?.dates?.[1] || start;
        if (start)
          datesWithPhases.push({
            title: phase.name,
            start: dateFns(start).toDate(),
            end: dateFns(end).toDate(),
            allDay: true,
          });
      });
    });

    return datesWithPhases;
  }, [invoiceProposals]);

  if (!loaded) return <CardLoader />;

  return (
    <Card header="Calendar" classAppend="mb-4">
      <div className="h-[20rem] md:h-[28rem] lg:h-[35rem] shadow bg-white-light">
        <Calendar events={events} notes={canEdit && notes} />
      </div>
    </Card>
  );
};

export { InvoiceProposalProjectCalendar };
