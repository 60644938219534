import React, { useEffect } from 'react';
import { useModel, useService } from '@thrivelot/hooks';
import { isEmpty } from '@thrivelot/utils';
import { Card, CardLoader } from '@thrivelot/stories';

const dayOrder = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
];

const getDays = (days) =>
  isEmpty(days)
    ? 'Unavailable'
    : dayOrder.filter((v) => days.includes(v)).join(', ');

const CardProjectSiteAssessmentAvailability = ({ projectId }) => {
  const { model: project, loaded } = useModel({
    modelName: 'Project',
    id: projectId,
  });
  const { data: hubspotContact, request: getHubspotContact } = useService({
    method: 'get',
    level: 'protected',
    path: 'get-hubspot-contact',
    deferLoad: true,
  });

  useEffect(() => {
    if (isEmpty(project?.customer?.hubspotId)) return;

    getHubspotContact({ variables: { hubspotId: project.customer.hubspotId } });
  }, [project?.customer?.hubspotId, getHubspotContact]);

  if (!loaded) return <CardLoader showText />;

  if (!hubspotContact)
    return (
      <Card classAppend="mb-4">
        Add a hubspot customer contact id to load availability.
      </Card>
    );

  if (isEmpty(project?.customer?.hubspotId))
    return (
      <Card header="Site Assessment Availability" classAppend="mb-4">
        <div className="flex">
          <div className="inline italic text-sm items-center text-brown-dark">
            No <span className="font-bold mx-1">Hubspot ID</span> connected to
            this project's customer. Add it in the{' '}
            <span className="font-bold mx-1">Admin</span> card to view the SA
            Availability.
          </div>
        </div>
      </Card>
    );

  return (
    <Card header="Site Assessment Availability" classAppend="mb-4">
      <div className="text-brown-dark mb-1 text-sm">
        Early Morning (Before 9 AM)
      </div>
      <div className="text-brown-dark font-bold mb-4">
        {getDays(hubspotContact.properties.sa_availability___early_morning)}
      </div>
      <div className="text-brown-dark mb-1 text-sm">Morning (9 AM - Noon)</div>
      <div className="text-brown-dark font-bold mb-4">
        {getDays(hubspotContact.properties.sa_availability___morning)}
      </div>
      <div className="text-brown-dark mb-1 text-sm">
        Afternoon (Noon - 4 PM)
      </div>
      <div className="text-brown-dark font-bold mb-4">
        {getDays(hubspotContact.properties.sa_availability___afternoon)}
      </div>
      <div className="text-brown-dark mb-1 text-sm">
        Late Afternoone or Evening (4 - 7 PM)
      </div>
      <div className="text-brown-dark font-bold mb-4">
        {getDays(
          hubspotContact.properties.sa_availability___late_afternoon_or_evening
        )}
      </div>
      <div className="text-brown-dark mb-1 text-sm">Dates to Avoid</div>
      <div className="text-brown-dark">
        {hubspotContact.properties.sa_availability___customer_comments}
      </div>
    </Card>
  );
};

export { CardProjectSiteAssessmentAvailability };
