import { GraphQLKit } from '@thrivelot/gql';
import { plantClient } from './PlantClient';

type QueryType = 'create' | 'delete' | 'get' | 'list' | 'search' | 'update';

export const request = async (
  queryType: QueryType,
  variables: { [key: string]: any }
) => {
  const gql = plantClient.queries[queryType];
  const rest: { [key: string]: any } = {
    endpoint: plantClient.endpoint,
    queryNameRegex: plantClient.queryNameRegex,
    apiKey: plantClient.apiKey,
  };

  console.log(rest);

  const gqlKit = new GraphQLKit({ gql, variables, ...rest });
  if (queryType === 'list' || queryType === 'search')
    return gqlKit.scan({ scanOnce: true });
  return gqlKit.request();
};
