import { Button } from "@thrivelot/stories";
import React from "react";
import { LayoutModal } from "../..";

const ModalInvoiceProposalVerifyBankAccount = ({ onClose }) => (
  <LayoutModal
    onClose={onClose}
    header={<div className="text-lg text-brown-dark italic">Please note</div>}
    footer={<Button block color="green-main" label="OK" onClick={onClose} />}
    dialogCloseDisabled
  >
    Stripe will make a micro deposit into your bank account in the next 1-2 business days. Once received, you will
    complete your payment by entering the 6-digit code from that deposit starting with "SM."
  </LayoutModal>
);

export { ModalInvoiceProposalVerifyBankAccount };
