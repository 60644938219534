import { constructUuid } from "@thrivelot/utils";

const constructNote = ({ id = constructUuid(), title = "", observations = "", recommendations = "" }) => ({
  id,
  title,
  observations,
  recommendations,
});

export { constructNote };
