import React from "react";
import { dateFns } from "@thrivelot/date";
import { constructUuid, duplicateObject } from "@thrivelot/utils";
import { CostPaymentScheduleType, TagIdType } from "@thrivelot/aws";
import { Button } from "@thrivelot/stories";
import { useModel } from "@thrivelot/hooks";

const AddPhaseButton = ({ projectId }) => {
  const { model, updateModel, loaded } = useModel({ modelName: "Project", id: projectId });

  if (!loaded) return null;

  const projectPhases = model?.phases ? [...model.phases] : [];
  if (!projectPhases) return null;

  const addPhase = () => {
    const startDate = dateFns(new Date()).startOf("day").toISOString();
    const endDate = dateFns(startDate).startOf("day").toISOString();

    const phase = {
      id: constructUuid(),
      name: "",
      description: "",
      startDate,
      endDate,
      orderedChildTagIds: [],
    };

    const workPhases = duplicateObject(projectPhases);
    workPhases.push(phase);

    const payment = {
      id: constructUuid(),
      type: CostPaymentScheduleType.PHASE,
      payments: [
        {
          id: constructUuid(),
          date: phase.startDate,
          markup: 0.2,
        },
        {
          id: constructUuid(),
          date: phase.endDate,
          markup: 0.2,
        },
      ],
      memo: "",
      tagIds: [{ type: TagIdType.PHASE, tagId: phase.id }],
    };

    const costPaymentSchedule = duplicateObject(model.costPaymentSchedule || []);
    costPaymentSchedule.push(payment);

    updateModel({ costPaymentSchedule, phases: workPhases });
  };

  return <Button size="small" icon="Plus" color="blue-main" kind="outline" label="Add Phase" onClick={addPhase} />;
};

export { AddPhaseButton };
