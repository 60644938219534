import React from 'react';

const Clock = ({
  color = 'green-main',
  size = 30,
  styleProps = '',
  ...rest
}) => (
  <svg
    width={size.toString()}
    height={size.toString()}
    viewBox="0 0 75 75"
    className={`fill-${color} ${styleProps}`}
    {...rest}
  >
    <path d="M37.5 75C16.8038 75 0 58.1962 0 37.5C0 16.8038 16.8038 0 37.5 0C58.1962 0 75 16.8038 75 37.5C75 58.1962 58.1962 75 37.5 75ZM37.5 6.64557C20.5063 6.64557 6.64557 20.5063 6.64557 37.5C6.64557 54.4937 20.5063 68.3544 37.5 68.3544C54.4937 68.3544 68.3544 54.4937 68.3544 37.5C68.3544 20.5063 54.4937 6.64557 37.5 6.64557Z" />
    <path d="M36.266 42.057C35.6014 42.057 35.0318 41.7722 34.5571 41.3925C34.0824 40.9178 33.8925 40.3482 33.8925 39.6836V18.2279C33.8925 16.8988 34.9369 15.8545 36.266 15.8545C37.5951 15.8545 38.6394 16.8988 38.6394 18.2279V37.4051L57.8166 37.3102C59.1457 37.3102 60.19 38.3545 60.19 39.6836C60.19 41.0127 59.1457 42.057 57.8166 42.057H36.266Z" />
  </svg>
);

export { Clock };
