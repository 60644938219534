import { createGlobalStyle } from "styled-components";

const backgroundColor = ({ theme }) => theme.background;

const GlobalStyle = createGlobalStyle`
  body {
    transition: opacity 0.5s ease-out, box-shadow 0.5s ease-out, color 1s ease-in-out, background-color 1s ease-in-out;
    background-color: ${backgroundColor};
  }
`;

export { GlobalStyle };
