import { createCognitoUser } from "./createCognitoUser";
import { fetchCognitoUserByEmail } from "./fetchCognitoUserByEmail";

const fetchOrCreateCognitoUser = async ({ email, phone, group, userId }) => {
  let createCognitoResults;
  const result = await fetchCognitoUserByEmail(email);
  ({ data: createCognitoResults } = result);

  if (!createCognitoResults) {
    try {
      await createCognitoUser({ email, phoneNumber: phone, userId, groupname: group, confirm: true });
      ({ data: createCognitoResults } = await fetchCognitoUserByEmail(email));
    } catch (err) {
      if (err.name === "UsernameExistsException") {
        ({ data: createCognitoResults } = await fetchCognitoUserByEmail(email));
      } else throw new Error(err);
    }
  }

  return createCognitoResults;
};

export { fetchOrCreateCognitoUser };
