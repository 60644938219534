import { constructUuid } from "@thrivelot/utils";

const constructLineItem = ({ id = constructUuid(), type = "", name = "", quantity = 0, cost = 0 }) => ({
  id,
  type,
  name,
  quantity,
  cost,
});

export { constructLineItem };
