import React, { useMemo } from "react";
import { useModel } from "@thrivelot/hooks";
import { RowItemFeature } from "..";
import { RowList } from "@thrivelot/stories";

const PhaselessFeatures = ({ projectId }) => {
  const { model: project, loaded } = useModel({ modelName: "Project", id: projectId });

  const assignedMap = useMemo(() => {
    const map = {};
    project.phases.forEach(({ orderedChildTagIds = [] }) => {
      orderedChildTagIds.forEach(({ tagId }) => {
        map[tagId] = true;
      });
    });
    return map;
  }, [project?.phases]);

  const features = useMemo(() => {
    return project.features.filter(({ id }) => !assignedMap[id]);
  }, [project?.features, assignedMap]);

  if (!loaded) return null;

  return (
    <div>
      <div className="text-brown-dark text-xl mb-3 italic">These features are not assigned to an phase yet...</div>
      <RowList>
        {features.map(({ id }) => (
          <RowItemFeature key={id} featureId={id} projectId={projectId} />
        ))}
      </RowList>
    </div>
  );
};

export { PhaselessFeatures };
