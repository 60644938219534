import React from 'react';
import { CardPlant } from './CardPlant';

export const PlantPalette = ({ plantIdsWithQuantities }) => (
  <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5">
    {plantIdsWithQuantities.map(
      ({ plantId, zones, quantity, changeQuantity, total, imageUrl }) => (
        <CardPlant
          key={plantId}
          plantId={plantId}
          zones={zones}
          quantity={quantity}
          changeQuantity={changeQuantity}
          total={total}
          imageUrl={imageUrl}
        />
      )
    )}
  </div>
);
