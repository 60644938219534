import React, { useCallback } from "react";
import { constructUuid } from "@thrivelot/utils";
import { useModelPlantCatalog, useModelPlantSearch } from "@thrivelot/common";
import { useModel } from "@thrivelot/hooks";
import { TagIdType } from "@thrivelot/aws";
import { Leaf, Plus } from "@thrivelot/stories";
import { LeafLoader } from "@thrivelot/stories";
import { Button } from "@thrivelot/stories";
import { LayoutModal } from "../..";

const resultsCount = 8;

const ModalAddFeaturePlant = ({ onClose, projectId, featureId }) => {
  const { loaded, actions, updateModel } = useModel({ modelName: "Project", id: projectId });
  const { query, setQuery: setCatalogQuery, plants: catalogPlants, loadedProjects } = useModelPlantCatalog();
  const { setQuery: setSearchQuery, plants: searchPlants, loading: loadingSearch } = useModelPlantSearch();

  const create = useCallback(
    (name) => {
      const freshItem = actions.constructPlantItem({ commonName: name });

      updateModel(
        actions
          .add("plantItems", freshItem, 0)
          .add(`features[id:${featureId}].orderedChildTagIds`, { type: TagIdType.PLANT, tagId: freshItem.id }).result
      );

      onClose();
    },
    [onClose, updateModel, actions, featureId]
  );

  const add = useCallback(
    (item) => {
      const freshItem = actions.constructPlantItem({ ...item, id: constructUuid() });

      updateModel(
        actions
          .add("plantItems", freshItem)
          .add(`features[id:${featureId}].orderedChildTagIds`, { type: TagIdType.PLANT, tagId: freshItem.id }).result
      );

      onClose();
    },
    [onClose, updateModel, actions, featureId]
  );

  return (
    <LayoutModal
      onClose={onClose}
      loading={!loaded && !loadedProjects}
      header={<div className="text-lg text-brown-dark italic">Add a plant...</div>}
    >
      <div className="flex flex-row">
        <input
          className="input"
          placeholder="Search for a plant to add..."
          value={query}
          onChange={(e) => {
            setCatalogQuery(e.target.value);
            setSearchQuery(e.target.value);
          }}
        />
        <Button label="Create" color="blue-main" kind="outline" classAppend="ml-3" onClick={() => create(query)} />
      </div>

      {!loadedProjects && (
        <div className="mt-4 flex">
          <LeafLoader text="Loading catalog, please wait to search..." />
        </div>
      )}

      {/* Only show results if there are results */}
      {query && catalogPlants.length > 0 && (
        <div className="mt-4 flex flex-col gap-3">
          <div className="font-bold text-green-main">Catalog Results</div>
          {catalogPlants.slice(0, resultsCount).map(({ label, value, item }) => (
            <div key={value} className="flex items-center text-mulch-light">
              <div className="mr-3">
                <Leaf size={14} color="green-main" />
              </div>
              <div className="w-full flex-1">
                <div className="font-bold">{label}</div>
              </div>
              <Plus color="green-main" size="20" styleProps="hover:cursor-pointer" onClick={() => add(item)} />
            </div>
          ))}
        </div>
      )}

      {/* Show empty message for catalog plants */}
      {query && catalogPlants.length === 0 && <div className="mt-4 text-green-main">No results from catalog...</div>}

      {/* If there are only a few catalog plants then show the search plants */}
      {query && catalogPlants.length < resultsCount && searchPlants.length > 0 && (
        <div className="mt-4 pt-3 flex flex-col gap-3 border-t border-brown-light text-brown-dark">
          <div className="font-bold text-blue-main flex justify-start items-center">
            <div className="mr-2">Database Results</div>
            {loadingSearch && <LeafLoader size="sm" />}
          </div>
          {searchPlants.slice(0, resultsCount - catalogPlants.length).map(({ label, value, item }) => (
            <div key={value} className="flex items-center text-mulch-light">
              <div className="mr-3">
                <Leaf size={14} color="blue-main" />
              </div>
              <div className="w-full flex-1">
                <div className="font-bold">{label}</div>
              </div>
              <Plus color="green-main" size="20" styleProps="hover:cursor-pointer" onClick={() => add(item)} />
            </div>
          ))}
        </div>
      )}

      {/* Show empty message for search plants */}
      {query && catalogPlants.length < resultsCount && searchPlants.length === 0 && (
        <div className="mt-4 pt-3 flex flex-col gap-3 border-t border-brown-light text-blue-main">
          No results from database...
        </div>
      )}
    </LayoutModal>
  );
};

export { ModalAddFeaturePlant };
