import React, { useRef } from "react";
import SvgContainer from "../SvgWrapper";

const PlantIcon = ({ color, size, ...rest }) => {
  const clipRef = useRef(`${Math.floor(Math.random() * 1000)}`);
  return (
    <SvgContainer
      width={`${size}`}
      height={`${size}`}
      viewBox="0 0 300 300"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <g clipPath={`url(#${clipRef.current})`}>
        <path
          d="M245.922 89.8286C245.808 89.6386 245.694 89.4867 245.58 89.2967C216.093 47.1561 104.909 -14.4399 59.1583 3.03954C45.0988 8.43536 37.347 20.481 37.347 36.5164C37.347 38.6064 37.499 40.7723 37.765 43.0142C41.9829 77.4791 37.233 103.85 33.0532 127.105C27.3534 158.796 21.9576 188.701 44.0348 223.546C44.1488 223.736 44.2628 223.888 44.3768 224.078C77.7397 271.767 135.802 299.962 196.106 299.962C208.037 299.962 220.159 299.43 231.977 296.58C235.89 295.63 238.588 293.844 240.564 290.842C281.071 229.55 283.275 148.727 245.922 89.8286ZM230.153 255.199C229.203 257.213 226.239 257.137 225.403 255.047C212.179 222.71 189.912 178.708 173.611 151.995C159.551 128.891 142.908 107.194 124.174 87.5107C122.008 85.2308 118.398 85.1548 116.118 87.3207C113.838 89.4867 113.762 93.0965 115.928 95.3765C134.092 114.49 150.241 135.541 163.883 157.96C182.464 188.473 204.807 233.844 216.777 264.167C218.297 268.005 215.637 272.223 211.533 272.603C154.497 277.998 97.613 253.565 66.3401 209.069C50.0006 183.078 53.6485 162.672 59.2343 131.817C63.5282 107.954 68.886 78.239 64.1741 39.7843C62.9582 30.0566 66.3781 28.7647 68.658 27.9287C97.765 16.7951 195.992 65.2435 223.617 104.306C251.204 148.043 253.18 206.143 230.153 255.199Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id={clipRef.current}>
          <rect width="244.522" height="300" fill="none" transform="translate(28)" />
        </clipPath>
      </defs>
    </SvgContainer>
  );
};

export default PlantIcon;
