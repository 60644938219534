import React, { useState } from "react";
import { LayoutModal } from "../..";
import { useAuth } from "@thrivelot/hooks";
import { isValidPhoneNumber } from "react-phone-number-input";
import PhoneInput from "react-phone-number-input/input";
import { Button } from "@thrivelot/stories";

const ModalEditAccount = ({ onClose }) => {
  const { user, updateUserAttributes } = useAuth();
  const [email, setEmail] = useState(user.attributes.email);
  const [phone, setPhone] = useState(user.attributes.phone_number);
  const [errors, setErrors] = useState([]);
  const [isSaving, setIsSaving] = useState(false);

  const handleSubmit = async () => {
    if (isSaving) return;

    const savingErrors = [];
    const attributes = {};
    const cleanEmail = email.trim();
    const cleanPhone = phone.trim();

    if (cleanEmail === "") savingErrors.push("Email cannot be empty.");
    if (!isValidPhoneNumber(cleanPhone)) savingErrors.push("Phone number is not valid.");

    if (cleanEmail !== user.attributes.email) attributes.email = cleanEmail;
    if (cleanPhone !== user.attributes.phone_number) attributes.phone_number = cleanPhone;
    if (cleanEmail === user.attributes.email && cleanPhone === user.attributes.phone_number)
      savingErrors.push("No changes to save.");

    if (savingErrors.length > 0) return setErrors(savingErrors);

    try {
      setIsSaving(true);
      await updateUserAttributes(attributes);
      setIsSaving(false);

      onClose();
    } catch (err) {
      setErrors([err.message]);
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <LayoutModal onClose={onClose} loading={!user.attributes} header="Edit Account">
      <div className="flex flex-col gap-4">
        <div>
          <div className="text-green-dark text-sm font-bold mb-1">Email</div>
          <input
            className="input"
            placeholder="Add your email..."
            value={email || ""}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>

        <div>
          <div className="text-green-dark text-sm font-bold mb-1">Phone</div>
          <PhoneInput
            placeholder="Add your phone number..."
            className="input"
            country="US"
            value={phone || ""}
            onChange={(value) => setPhone(value)}
          />
        </div>
        {errors.length > 0 && (
          <div>
            {errors.map((error) => (
              <div key={error} className="text-red-main text-sm">
                {error}
              </div>
            ))}
          </div>
        )}
        <Button
          disabled={isSaving}
          loading={isSaving}
          block
          kind="outline"
          color="blue-main"
          label="Submit"
          onClick={handleSubmit}
        />
      </div>
    </LayoutModal>
  );
};

export { ModalEditAccount };
