import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useFiles, useModal, usePlant, useZoom } from '@thrivelot/hooks';
import { PlantKit } from '@thrivelot/plant-kit';
import {
  AngleLeft,
  AngleRight,
  Button,
  Download,
  Minus,
  Plus,
} from '@thrivelot/stories';
import { Layout } from '../ModalViewFiles/Layout';

export const ModalViewPlantImages = ({
  modelId,
  currentImageId,
  fromViewPlantModal,
  onClose,
}) => {
  const [fileId, setFileId] = useState(currentImageId);
  const [signedUrl, setSignedUrl] = useState(null);

  const { openModal } = useModal();
  const { model: plant, loading } = usePlant({
    id: modelId,
  });
  const { fetchUrl } = useFiles();
  const { isSizeToFit, setIsSizeToFit, zoomIn, zoomOut, ZoomView } =
    useZoom(signedUrl);

  const handleNext = useCallback(() => {
    if (!plant?.images?.length) return;

    const currentIndex = plant.images.findIndex((image) => image.id === fileId);
    if (currentIndex < 0) return;

    setFileId(plant.images[currentIndex + 1].id);
  }, [fileId, plant?.images]);

  const handlePrevious = useCallback(() => {
    if (!plant?.images?.length) return;

    const currentIndex = plant.images.findIndex((image) => image.id === fileId);
    console.log(currentIndex);
    if (currentIndex > plant.images.length - 1 || currentIndex === -1) return;

    setFileId(plant.images[currentIndex - 1].id);
  }, [fileId, plant?.images]);

  const currentIndex = useMemo(() => {
    if (!plant?.images?.length) return 0;
    return plant.images.findIndex((image) => image.id === fileId);
  }, [plant?.images, fileId]);

  const prevDisabled = useMemo(() => currentIndex === 0, [currentIndex]);

  const nextDisabled = useMemo(
    () =>
      !plant?.images?.length ? true : currentIndex === plant.images.length - 1,
    [currentIndex, plant?.images]
  );

  const image = useMemo(
    () => (loading ? null : plant?.images?.[currentIndex]),
    [currentIndex, loading]
  );

  useEffect(() => {
    if (!image?.key) return;

    const fetchImage = async () => {
      const plantKit = new PlantKit({ id: modelId });
      const img = await plantKit.getImage(image.key);
      setSignedUrl(img.url);
    };

    fetchImage();
  }, [fetchUrl, image?.key, modelId]);

  const download = () => {
    const newWindow = window.open();
    newWindow.document.title = 'Thrive Lot | View File';
    newWindow.document.write(
      `<iframe src="${signedUrl}" frameborder="0" style="border:0; top:0px; left:0px; bottom:0px; right:0px; width:100%; height:100%;" allowfullscreen></iframe>`
    );
  };

  const filename = plant?.commonName;

  return (
    <Layout
      onClose={
        fromViewPlantModal
          ? () => openModal({ path: 'ModalViewPlant', modelId })
          : onClose
      }
      header={filename}
      footer={
        <div className="flex flex-1 items-center justify-between">
          <div className="flex items-center gap-2 mr-5">
            <AngleLeft
              size={20}
              color="white-light"
              styleProps={`${
                prevDisabled
                  ? 'pointer-events-none opacity-30'
                  : 'cursor-pointer'
              }`}
              onClick={handlePrevious}
            />
            <div className="text-white-light w-18 select-none">
              {currentIndex + 1} of {plant?.images?.length}
            </div>
            <AngleRight
              size={20}
              color="white-light"
              styleProps={`${
                nextDisabled
                  ? 'pointer-events-none opacity-30'
                  : 'cursor-pointer'
              }`}
              onClick={handleNext}
            />
          </div>
          <div className="flex items-center gap-3 select-none">
            {/* <div className="text-white-light font-bold">Zoom</div> */}
            <Minus
              size={24}
              color="white-light"
              styleProps="hover:cursor-pointer"
              onClick={zoomOut}
            />
            <Plus
              size={24}
              color="white-light"
              styleProps="hover:cursor-pointer"
              onClick={zoomIn}
            />
            <Button
              label={isSizeToFit ? 'Fill Screen' : 'Size to Fit'}
              size="small"
              kind="outline"
              color="white-light"
              onClick={() => setIsSizeToFit(!isSizeToFit)}
            />
          </div>
          <Download
            size={24}
            color="white-light"
            styleProps="hover:cursor-pointer"
            onClick={download}
          />
        </div>
      }
    >
      {!loading && signedUrl && ZoomView}
    </Layout>
  );

  // return (
  //   <LayoutModal
  //     onClose={onClose}
  //     width="full"
  //     loading={!loaded && !signedUrl}
  //     header={
  //       loaded && (
  //         <div className="flex items-center justify-between gap-4">
  //           <div className="text-lg text-brown-dark font-bold truncate">
  //             {filename}
  //           </div>
  //           <div className="flex items-center gap-2 mr-5">
  //             <AngleLeft
  //               size={16}
  //               color="green-main"
  //               styleProps={`${
  //                 prevDisabled
  //                   ? 'pointer-events-none opacity-30'
  //                   : 'cursor-pointer'
  //               }`}
  //               onClick={handlePrevious}
  //             />
  //             <div className="text-brown-dark w-18">
  //               {currentIndex + 1} of {fileIds.length}
  //             </div>
  //             <AngleRight
  //               size={16}
  //               color="green-main"
  //               styleProps={`${
  //                 nextDisabled
  //                   ? 'pointer-events-none opacity-30'
  //                   : 'cursor-pointer'
  //               }`}
  //               onClick={handleNext}
  //             />
  //           </div>
  //         </div>
  //       )
  //     }
  //   >
  //     <ViewFile loaded={loaded} signedUrl={signedUrl} />
  //     {caption && <div className="mt-4">{caption}</div>}
  //   </LayoutModal>
  // );
};
