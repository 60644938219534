import { Card } from "@thrivelot/stories";
import { CardLoader } from "@thrivelot/stories";
import React from "react";
import TextareaAutosize from "react-textarea-autosize";
import { useModel } from "@thrivelot/hooks";

const CardSupplierAbout = ({ supplierId }) => {
  const {
    model: supplier,
    updateModel: updateSupplier,
    actions,
    loaded,
  } = useModel({ modelName: "Supplier", id: supplierId });

  if (!loaded) return <CardLoader />;

  const { bio } = supplier;

  return (
    <Card header="About" classAppend="mb-4">
      <TextareaAutosize
        className="input"
        placeholder="Describe your background and the type projects you feel comfortable with..."
        value={bio || ""}
        minRows={2}
        onChange={(e) => updateSupplier(actions.set("bio", e.target.value).result)}
      />
    </Card>
  );
};

export { CardSupplierAbout };
