import React, { useEffect, useMemo, useState } from 'react';
import { useMeasure, useModal, usePlant } from '@thrivelot/hooks';
import { CardLoader, Leaf } from '@thrivelot/stories';
import colors from '@thrivelot/tailwind/colors';

export const CardPlant = ({ plantId, zones, quantity, total, imageUrl }) => {
  const { model: plant, loading, getImage } = usePlant({ id: plantId });
  const [imgContainerRef, { width }] = useMeasure();
  const { openModal } = useModal();
  const [src, setSrc] = useState(imageUrl);

  useEffect(() => {
    console.log('blah hi plantId', plantId, imageUrl);
    if (loading || !plant?.images?.length) return;
    console.log('blah hi plants', plant.images);

    let didCancel;

    const fetchImage = async () => {
      const [image] = plant.images;
      if (!image) return;

      const { url } = await getImage(image.key);
      if (!didCancel && url) setSrc(url);
    };

    fetchImage();

    // eslint-disable-next-line consistent-return
    return () => {
      didCancel = true;
    };
  }, [plant?.images, loading, getImage]);

  const zoneColors = useMemo(() => {
    if (!zones?.length) return [];

    return zones
      .map((z) =>
        z.type === 'general' ? 'brown-dark' : colors.zonesIconString[z.type]
      )
      .filter((z, i, self) => self.indexOf(z) === i);
  }, [zones]);

  if (loading) return <CardLoader showText />;

  const { genus, species, commonName } = plant || {};
  const scientificName = `${genus || ''} ${species || ''}`;
  const name = commonName || scientificName;

  return (
    <div
      className="cursor-pointer transition-all duration-500 border border-transparent hover:border-tan-light hover:shadow-outer rounded overflow-hidden p-2"
      onClick={() => openModal({ path: 'ModalViewPlant', modelId: plantId })}
    >
      <div className="flex flex-col items-center justify-between">
        <div
          ref={imgContainerRef}
          className="relative w-full max-h-48 pb-1 flex items-center justify-center"
          style={{ height: `${(Math.round(width) * 3) / 4}px` }}
        >
          {src ? (
            <img
              src={src}
              alt={`${name} plant`}
              className="w-full h-full rounded object-cover"
            />
          ) : (
            <div className="w-full h-full flex items-center justify-center bg-tan-light rounded">
              <Leaf size={60} />
            </div>
          )}
          <div className="flex w-full items-center absolute top-2 left-2 gap-2">
            <div className="rounded-full bg-white-light h-6 w-6 flex items-center justify-center shadow-outer">
              <div className="text-xs text-mulch-dark">{total}</div>
            </div>
            {zoneColors.map((color) => (
              <div
                key={color}
                className={`rounded-full h-5 w-5 shadow-outer bg-${color}`}
              />
            ))}
          </div>
        </div>
        <div className="flex h-full w-full">
          <div className="w-full">
            <div className="font-bold text-mulch-dark text-sm">{name}</div>
            {name !== scientificName && (
              <div className="text-mulch-dark text-sm italic">
                {scientificName}
              </div>
            )}
          </div>
          {quantity !== total && (
            <div className="flex text-tan-dark text-xs items-center w-full mt-2">
              Originally {quantity}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
