import { css } from "styled-components";

const disabledConstructor = ({ disabled }) => {
  if (disabled)
    return css`
      opacity: 0.3;
      pointer-events: none;
    `;
  return css`
    cursor: pointer;
    &:hover {
      opacity: 0.7;
    }
  `;
};
const CssButtonDefault = css`
  display: flex;
  -webkit-tap-highlight-color: transparent;
  -webkit-appearance: none;
  transition: opacity 0.5s ease-out, box-shadow 0.5s ease-out, color 1s ease-in-out, background-color 1s ease-in-out;
  position: relative;
  ${disabledConstructor}
`;

export default CssButtonDefault;
