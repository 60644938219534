import React from 'react';

const ListView = ({
  color = 'green-main',
  size = 30,
  styleProps = '',
  ...rest
}) => (
  <svg
    width={size.toString()}
    height={size.toString()}
    viewBox="0 0 75 75"
    className={`fill-${color} ${styleProps}`}
    {...rest}
  >
    <path d="M0 6C0 2.68629 2.68629 0 6 0H14C17.3137 0 20 2.68629 20 6V14C20 17.3137 17.3137 20 14 20H6C2.68629 20 0 17.3137 0 14V6Z" />
    <path d="M0 61C0 57.6863 2.68629 55 6 55H14C17.3137 55 20 57.6863 20 61V69C20 72.3137 17.3137 75 14 75H6C2.68629 75 0 72.3137 0 69V61Z" />
    <path d="M0 34C0 30.6863 2.68629 28 6 28H14C17.3137 28 20 30.6863 20 34V42C20 45.3137 17.3137 48 14 48H6C2.68629 48 0 45.3137 0 42V34Z" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M25 38C25 35.7909 26.7909 34 29 34H71C73.2091 34 75 35.7909 75 38C75 40.2091 73.2091 42 71 42H29C26.7909 42 25 40.2091 25 38Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M25 65C25 62.7909 26.7909 61 29 61H71C73.2091 61 75 62.7909 75 65C75 67.2091 73.2091 69 71 69H29C26.7909 69 25 67.2091 25 65Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M25 10C25 7.79086 26.7909 6 29 6H71C73.2091 6 75 7.79086 75 10C75 12.2091 73.2091 14 71 14H29C26.7909 14 25 12.2091 25 10Z"
    />
  </svg>
);

export { ListView };
