import { isObject } from "./isObject";

const isEqual = (aObject, bObject) => {
  if (!isObject(aObject) || !isObject(bObject)) return aObject === bObject;

  const keys1 = Object.keys(aObject);
  const keys2 = Object.keys(bObject);

  if (keys1.length !== keys2.length) return false;

  for (const key of keys1) {
    const val1 = aObject[key];
    const val2 = bObject[key];
    const areObjects = isObject(val1) && isObject(val2);

    if ((areObjects && !isEqual(val1, val2)) || (!areObjects && val1 !== val2)) return false;
  }

  return true;
};

export { isEqual };
