import { constructUuid } from "@thrivelot/utils";

const constructZone = ({
  id = constructUuid(),
  name = "",
  description = "",
  center = null,
  type = "_1",
  orderedChildTagIds = [],
}) => ({ id, name, description, center, type, orderedChildTagIds });

export { constructZone };
