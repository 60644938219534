import React from "react";
import { useTheme } from "@thrivelot/hooks";
import { TextBody, TextHeader } from "@thrivelot/styles";
import { InfoContainer } from "./InfoContainer";

const Info = ({ name, caption }) => {
  const theme = useTheme();
  const textStyle = { color: theme.textLight };
  return (
    <InfoContainer>
      <TextHeader style={textStyle}>{name}</TextHeader>
      <TextBody style={textStyle}>{caption}</TextBody>
    </InfoContainer>
  );
};

export { Info };
