import styled from "styled-components";
import CssTextDefault from "./CssTextDefault";

const colorConstructor = ({ color, isLightText, theme }) => {
  if (color) return color;
  if (isLightText) return theme.textLight;
  return theme.textTitle;
};

const TextTitle = styled.span`
  ${CssTextDefault}
  font-family: Poppins-Bold;
  color: ${colorConstructor};
  @media (max-width: 767px) {
    font-size: 17px;
  }
  @media (min-width: 768px) {
    font-size: 21px;
  }
`;

export default TextTitle;
