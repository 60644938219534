import { ApiKit } from '@thrivelot/api';
import { apiEndpoints } from '@thrivelot/aws';
import { authConfig } from '@thrivelot/config';

const fetchService = async ({
  method,
  level,
  path,
  variables,
  headers = {},
}) => {
  try {
    // Fetch and pass jwt if level is not public
    if (level !== 'public') {
      const jwt = await authConfig.fetchJwt();

      headers.Authorization = `Bearer ${jwt}`;
    }

    // Construct endpoint from level and path
    const endpoint = `${apiEndpoints.Services}/${level}/${path}`;

    // Setup api request based on the method
    const request = new ApiKit({
      variables: variables || {},
      endpoint,
      headers,
    });
    const data = await request[method]();

    return { data };
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export { fetchService };
