import { dateFns } from '@thrivelot/date';
import { constructUuid } from '@thrivelot/utils';
import { constructDetails } from './constructDetails';

const priorityKeys = [
  'Growing an abundance of food/replacing groceries',
  'Having rare & healthy superfoods that are pesticide free',
  'Make my yard/property more beautiful',
  'Feed and create habitat for wildlife and pollinators',
  'Reduce my personal supply chain carbon footprint & sequester carbon in my yard',
  'Share my abundance with neighbors, friends & family',
  'Provide educational activity for children',
  'Increase my property value',
  'Reduce or remove mowing',
];
const constructPriorities = () =>
  priorityKeys.map((key) => ({
    prompt: `Priority: ${key}`,
    priority: 0,
  }));

const costPaymentScheduleTypesAndMemos = [
  { type: 'SITE_VISIT', memo: 'Site Visit' },
  { type: 'DESIGN', memo: 'Design Plan' },
];
const constructDate = ({ type }) =>
  type === 'SITE_VISIT'
    ? null
    : dateFns(new Date()).startOf('day').add(30, 'day').toISOString();
const constructCalculation = ({ type }) => [
  { type: 'AMOUNT', amount: type === 'SITE_VISIT' ? 25000 : 0 },
];
const constructMarkup = ({ type }) => (type === 'SITE_VISIT' ? 0.996 : 0.4);
const constructCostPaymentSchedule = () =>
  costPaymentScheduleTypesAndMemos.map(({ type, memo }) => ({
    id: constructUuid(),
    date: constructDate({ type }),
    calculation: constructCalculation({ type }),
    markup: constructMarkup({ type }),
  }));

const constructMilestones = () => [
  {
    id: constructUuid(),
    type: 'OPEN_PROJECT_ACCEPTANCE',
    stage: 'OPEN_PROJECT',
    lifecycleEvents: [],
  },
];

const siteAnalysisTitles = [
  'General Field Notes',
  'Hydrology Analysis',
  'Soil Analysis',
  'Sun Analysis',
  'Existing Flora and Fauna',
];
const constructSiteAnalysis = () =>
  siteAnalysisTitles.map((title) => ({
    id: `DEFAULT${constructUuid()}`,
    title,
    observations: '',
    recommendations: '',
  }));

const constructSelf = ({
  id = constructUuid(),
  title,
  address,
  owners,
  viewers,
  customerId,
}) => ({
  id,
  currentMilestone: 'OPEN_PROJECT',
  owners,
  viewers,
  customerId,
  details: constructDetails({
    title,
    address,
    priorities: constructPriorities(),
  }),
  costPaymentSchedule: constructCostPaymentSchedule(),
  milestones: constructMilestones(),
  siteAnalysis: constructSiteAnalysis(),
  files: [],
  zones: [],
  features: [],
  plantItems: [],
  lineItems: [],
  phases: [],
  calendarNotes: [],
});

export { constructSelf };
