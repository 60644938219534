import { useEffect, useState } from "react";
import { useFetchFileUrl } from "@thrivelot/common";

const useFileUrl = (src) => {
  const [fileUrl, setFileUrl] = useState();
  const fetchUrl = useFetchFileUrl();

  useEffect(() => {
    let didCancel = false;

    const getFileUrl = async () => {
      const url = await fetchUrl(src);

      if (!didCancel) setFileUrl(url);
    };

    getFileUrl();

    return () => {
      didCancel = true;
    };
  }, [src, fetchUrl]);

  return fileUrl;
};

export { useFileUrl };
