import React from "react";
import { useTheme } from "@thrivelot/hooks";
import { ButtonRaised, ExpandIcon } from "@thrivelot/styles";
import { useSpacing } from "../../../hooks";
import { useFileUrl } from "./hooks";

const OpenButton = ({ src }) => {
  // hooks
  const fileUrl = useFileUrl(src);
  const theme = useTheme();
  const spacing = useSpacing();
  // navigation
  const openPdfInBrowser = () => {
    window.open(fileUrl);
  };
  // return
  return (
    <ButtonRaised
      done
      disabled={!fileUrl}
      onClick={openPdfInBrowser}
      style={{
        position: "absolute",
        top: spacing,
        right: spacing,
        paddingLeft: "12px",
      }}
    >
      <ExpandIcon color={theme.textLight} size={15} style={{ marginRight: "12px" }} />
      Open
    </ButtonRaised>
  );
};

export { OpenButton };
