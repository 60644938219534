import React, { useCallback } from 'react';
import { useModel } from '@thrivelot/hooks';
import { Checkmark } from '@thrivelot/stories';
import { isSupplierApp } from '@thrivelot/utils';
import { ServiceProviderFromOwnerId } from '../../CardProjectServiceProviders/ServiceProviderFromOwnerId';
import { LayoutModal } from '../..';

// ! Something is broken here when trying to reassign the bundle payee to a different supplier
// ! I didn't have time to fix it before launch and since this is an uncommon use case I'm
// ! not worrying about it now and maybe we never need this feature
// ? Activated it on May 31 and it seems to be ok.

const ModalEditBundlePayee = ({ onClose, bundleId, projectId }) => {
  const { model: project, loaded: projectLoaded } = useModel({
    modelName: 'Project',
    id: projectId,
  });
  const {
    model: bundle,
    loaded: bundleLoaded,
    updateModel: updateBundle,
    actions: bundleActions,
  } = useModel({ modelName: 'Bundle', id: bundleId });

  const setPayee = useCallback(
    (supplierId) => {
      updateBundle(bundleActions.set('supplierPayeeId', supplierId).result);
      onClose();
    },
    [updateBundle, bundleActions, onClose]
  );

  const { owners } = project || {};
  const { supplierPayeeId } = bundle || {};

  if (isSupplierApp()) return null;

  return (
    <LayoutModal
      onClose={onClose}
      loading={!projectLoaded || !bundleLoaded}
      header={<div className="text-lg text-brown-dark italic">Edit Payee</div>}
      subheader={
        <div className="text-brown-dark italic">
          Select one of the project's service providers to be the payee for
          these line items
        </div>
      }
    >
      {owners?.length > 0 ? (
        <div className="flex flex-col gap-2">
          {owners.map((ownerId) => (
            <ServiceProviderFromOwnerId
              key={ownerId}
              ownerId={ownerId}
              renderDetails={(supplier) => (
                <div className="flex items-center">
                  {supplier.id === supplierPayeeId ? (
                    <Checkmark size={20} color="green-main" styleProps="mr-2" />
                  ) : (
                    <Checkmark
                      size={20}
                      color="tan-main"
                      styleProps="mr-2 hover:cursor-pointer hover:fill-green-main"
                      onClick={() => setPayee(supplier.id)}
                    />
                  )}
                </div>
              )}
            />
          ))}
        </div>
      ) : (
        <div className="italic text-brown-dark text-sm">
          No service providers assigned...
        </div>
      )}
    </LayoutModal>
  );
};

export { ModalEditBundlePayee };
