import React from "react";
import { MapProvider } from "@thrivelot/hooks";
import { Map } from "./Map";

const MapProposal = () => (
  <MapProvider>
    <div className="h-full relative">
      <Map />
    </div>
  </MapProvider>
);

export { MapProposal };
