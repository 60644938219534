import React from 'react';

const Clipboard = ({
  color = 'green-main',
  size = 30,
  styleProps = '',
  ...rest
}) => (
  <svg
    width={size.toString()}
    height={size.toString()}
    viewBox="0 0 75 75"
    className={`fill-${color} ${styleProps}`}
    {...rest}
  >
    <path d="M61.7184 75H13.2816C5.96203 75 0 69.3038 0 62.307V24.7595C0 17.7627 5.96203 12.0665 13.2816 12.0665H21.7405C23.3354 5.17405 29.8006 0 37.5 0C45.1994 0 51.6646 5.17405 53.2595 12.0665H61.7184C69.038 12.0665 75 17.7627 75 24.7595V62.307C75 69.3038 69.038 75 61.7184 75ZM13.2816 18.712C9.62658 18.712 6.64557 21.4272 6.64557 24.7595V62.307C6.64557 65.6392 9.62658 68.3544 13.2816 68.3544H61.7184C65.3734 68.3544 68.3544 65.6392 68.3544 62.307V24.7595C68.3544 21.4272 65.3734 18.712 61.7184 18.712H50.3165C48.4842 18.712 46.9937 17.2215 46.9937 15.3892C46.9937 10.5665 42.731 6.64557 37.5 6.64557C32.269 6.64557 28.0063 10.5665 28.0063 15.3892C28.0063 17.2215 26.5158 18.712 24.6835 18.712H13.2816Z" />
    <path d="M37.4999 22.0443C34.1391 22.0443 31.4144 19.3101 31.4144 15.9589C31.4144 12.5981 34.1486 9.87341 37.4999 9.87341C40.8606 9.87341 43.5853 12.6076 43.5853 15.9589C43.5853 19.3101 40.8606 22.0443 37.4999 22.0443ZM37.4999 12.7215C35.7151 12.7215 34.2625 14.174 34.2625 15.9589C34.2625 17.7437 35.7151 19.1962 37.4999 19.1962C39.2847 19.1962 40.7372 17.7437 40.7372 15.9589C40.7372 14.174 39.2847 12.7215 37.4999 12.7215Z" />
    <path d="M58.8607 31.8227H16.1392C15.3513 31.8227 14.7152 31.1866 14.7152 30.3987C14.7152 29.6107 15.3513 28.9746 16.1392 28.9746H58.8607C59.6487 28.9746 60.2848 29.6107 60.2848 30.3987C60.2848 31.1866 59.6487 31.8227 58.8607 31.8227Z" />
    <path d="M58.8607 41.1361H16.1392C15.3513 41.1361 14.7152 40.5 14.7152 39.712C14.7152 38.924 15.3513 38.288 16.1392 38.288H58.8607C59.6487 38.288 60.2848 38.924 60.2848 39.712C60.2848 40.5 59.6487 41.1361 58.8607 41.1361Z" />
    <path d="M58.8607 50.2404H16.1392C15.3513 50.2404 14.7152 49.6044 14.7152 48.8164C14.7152 48.0284 15.3513 47.3923 16.1392 47.3923H58.8607C59.6487 47.3923 60.2848 48.0284 60.2848 48.8164C60.2848 49.6044 59.6487 50.2404 58.8607 50.2404Z" />
    <path d="M48.0095 59.0886H16.1392C15.3513 59.0886 14.7152 58.4525 14.7152 57.6645C14.7152 56.8766 15.3513 56.2405 16.1392 56.2405H48.0095C48.7975 56.2405 49.4335 56.8766 49.4335 57.6645C49.4335 58.4525 48.7975 59.0886 48.0095 59.0886Z" />
  </svg>
);

export { Clipboard };
