import React, { useState } from 'react';
import { Help } from '@thrivelot/stories';
import { LayoutModal } from '../LayoutModal';

const HelpPrompt = ({ header, children, button }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="hover:cursor-pointer">
      <div onClick={() => setIsOpen(true)}>
        {button || <Help size={16} color="brown-light" />}
      </div>
      {isOpen && (
        <LayoutModal isOpen={isOpen} setIsOpen={setIsOpen} header={header}>
          {children}
        </LayoutModal>
      )}
    </div>
  );
};

export { HelpPrompt };
