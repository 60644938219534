import React from "react";
import { DivColumn, TextBody } from "@thrivelot/styles";

const Bio = ({ supplierActions }) => {
  // actions
  const bio = supplierActions.get("bio");
  // breakout
  if (!bio || bio.trim() === "") return null;
  // return
  return (
    <DivColumn>
      <TextBody
        style={{
          margin: "12px",
          minHeight: "fit-content",
          maxHeight: "fit-content",
        }}
      >
        {bio}
      </TextBody>
    </DivColumn>
  );
};

export { Bio };
