import React from 'react';

const Plus = ({
  color = 'green-main',
  size = 30,
  styleProps = '',
  ...rest
}) => (
  <svg
    width={size.toString()}
    height={size.toString()}
    viewBox="0 0 75 75"
    className={`fill-${color} ${styleProps}`}
    {...rest}
  >
    <path d="M53.6393 37.5001C53.6393 39.3324 52.1488 40.8229 50.3165 40.8229H40.8228V50.3165C40.8228 52.1488 39.3323 53.6393 37.5 53.6393C35.6677 53.6393 34.1772 52.1488 34.1772 50.3165V40.8229H24.6836C22.8513 40.8229 21.3608 39.3324 21.3608 37.5001C21.3608 35.6678 22.8513 34.1773 24.6836 34.1773H34.1772V24.6836C34.1772 22.8513 35.6677 21.3608 37.5 21.3608C39.3323 21.3608 40.8228 22.8513 40.8228 24.6836V34.1773H50.3165C52.1488 34.1773 53.6393 35.6678 53.6393 37.5001Z" />
    <path d="M37.5 75C16.8228 75 0 58.1772 0 37.5C0 16.8228 16.8228 0 37.5 0C58.1772 0 75 16.8228 75 37.5C75 58.1772 58.1772 75 37.5 75ZM37.5 6.64557C20.4873 6.64557 6.64557 20.4873 6.64557 37.5C6.64557 54.5127 20.4873 68.3544 37.5 68.3544C54.5127 68.3544 68.3544 54.5127 68.3544 37.5C68.3544 20.4873 54.5127 6.64557 37.5 6.64557Z" />
  </svg>
);

export { Plus };
