import React from 'react';
import { useAuth, useModel } from '@thrivelot/hooks';
import { ProjectMilestoneStage } from '@thrivelot/aws';
import { transformCurrencyToString } from '@thrivelot/utils';
import { useBillingBalance, sortProjects } from '@thrivelot/common';
import { milestoneColorMap } from '@thrivelot/config';
import { Button, Card, CardLoader } from '@thrivelot/stories';

const Progress = ({ projects: allProjects }) => {
  const { userId } = useAuth();
  const { model: supplier, loaded: loadedSupplier } = useModel({
    modelName: 'Supplier',
    id: userId,
  });
  const { balance, loading, goToStripe } = useBillingBalance(supplier);

  const projects = sortProjects({
    projects: allProjects,
    showExamples: false,
  });

  const getActiveAmount = () => {
    let amount = 0;
    if (projects[ProjectMilestoneStage.SITE_ASSESSMENT]) {
      amount += projects[ProjectMilestoneStage.SITE_ASSESSMENT].length;
    }
    if (projects[ProjectMilestoneStage.DETAILED_PLAN]) {
      amount += projects[ProjectMilestoneStage.DETAILED_PLAN].length;
    }
    if (projects[ProjectMilestoneStage.INSTALLATION]) {
      amount += projects[ProjectMilestoneStage.INSTALLATION].length;
    }
    if (projects[ProjectMilestoneStage.STEWARDSHIP]) {
      amount += projects[ProjectMilestoneStage.STEWARDSHIP].length;
    }
    return amount;
  };

  if (!loadedSupplier) return <CardLoader />;

  return (
    <Card
      header="Progress"
      footer={
        <div className="w-full flex justify-around">
          <div className="flex flex-col justify-center items-center">
            <div className="text-brown-dark font-bold">Open</div>
            <div
              className={`font-bold text-2xl text-${milestoneColorMap.OPEN_PROJECT}-light`}
            >
              {projects[ProjectMilestoneStage.OPEN_PROJECT]
                ? projects[ProjectMilestoneStage.OPEN_PROJECT].length
                : 0}
            </div>
          </div>
          <div className="flex flex-col justify-center items-center">
            <div className="text-brown-dark font-bold">Active</div>
            <div className={`font-bold text-2xl text-blue-main`}>
              {getActiveAmount()}
            </div>
          </div>
          <div className="flex flex-col justify-center items-center">
            <div className="text-brown-dark font-bold">Installs</div>
            <div className={`font-bold text-2xl text-green-main`}>
              {projects[ProjectMilestoneStage.INSTALLATION]
                ? projects[ProjectMilestoneStage.INSTALLATION].length
                : 0}
            </div>
          </div>
        </div>
      }
    >
      <div className="flex flex-col justify-center items-center">
        {balance && (
          <div className="w-full flex justify-around mb-4">
            <div className="flex flex-col justify-center items-center">
              <div className="text-blue-main text-sm">Pending</div>
              <div className="text-blue-dark text-2xl font-bold">
                {balance ? transformCurrencyToString(balance.pending) : ''}
              </div>
            </div>
            <div className="flex flex-col justify-center items-center">
              <div className="text-green-main text-sm">Available</div>
              <div className="text-green-dark text-2xl font-bold">
                {balance ? transformCurrencyToString(balance.available) : ''}
              </div>
            </div>
          </div>
        )}

        {!balance && (
          <div className="mb-4 flex justify-center align-center text-center">
            <div className="text-brown-dark">
              Setup a payout account to view your available and pending balance.
            </div>
          </div>
        )}

        <div className="w-full mb-4">
          <Button
            color="blue-main"
            kind="outline"
            loading={loading}
            label={`${supplier.stripeId ? 'View' : 'Setup'} Payouts`}
            block
            onClick={goToStripe}
          />
        </div>
      </div>
    </Card>
  );
};

export { Progress };
