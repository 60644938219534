import React from 'react';

const Grid = ({
  color = 'green-main',
  size = 30,
  styleProps = '',
  ...rest
}) => (
  <svg
    width={size.toString()}
    height={size.toString()}
    viewBox="0 0 75 75"
    className={`fill-${color} ${styleProps}`}
    {...rest}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.5 9.18902C0.5 4.39021 4.39021 0.5 9.18902 0.5H28.3963C33.1952 0.5 37.0854 4.39021 37.0854 9.18902V28.3963C37.0854 33.1952 33.1952 37.0854 28.3963 37.0854H9.18902C4.39021 37.0854 0.5 33.1952 0.5 28.3963V9.18902ZM9.18902 6.90244C7.92618 6.90244 6.90244 7.92618 6.90244 9.18902V28.3963C6.90244 29.6592 7.92618 30.6829 9.18902 30.6829H28.3963C29.6592 30.6829 30.6829 29.6592 30.6829 28.3963V9.18902C30.6829 7.92618 29.6592 6.90244 28.3963 6.90244H9.18902ZM38.9146 9.18902C38.9146 4.39021 42.8048 0.5 47.6037 0.5H66.811C71.6098 0.5 75.5 4.39021 75.5 9.18902V28.3963C75.5 33.1952 71.6098 37.0854 66.811 37.0854H47.6037C42.8048 37.0854 38.9146 33.1952 38.9146 28.3963V9.18902ZM47.6037 6.90244C46.3408 6.90244 45.3171 7.92618 45.3171 9.18902V28.3963C45.3171 29.6592 46.3408 30.6829 47.6037 30.6829H66.811C68.0738 30.6829 69.0976 29.6592 69.0976 28.3963V9.18902C69.0976 7.92618 68.0738 6.90244 66.811 6.90244H47.6037ZM0.5 47.6037C0.5 42.8048 4.39021 38.9146 9.18902 38.9146H28.3963C33.1952 38.9146 37.0854 42.8048 37.0854 47.6037V66.811C37.0854 71.6098 33.1952 75.5 28.3963 75.5H9.18902C4.39021 75.5 0.5 71.6098 0.5 66.811V47.6037ZM9.18902 45.3171C7.92618 45.3171 6.90244 46.3408 6.90244 47.6037V66.811C6.90244 68.0738 7.92618 69.0976 9.18902 69.0976H28.3963C29.6592 69.0976 30.6829 68.0738 30.6829 66.811V47.6037C30.6829 46.3408 29.6592 45.3171 28.3963 45.3171H9.18902ZM38.9146 47.6037C38.9146 42.8048 42.8048 38.9146 47.6037 38.9146H66.811C71.6098 38.9146 75.5 42.8048 75.5 47.6037V66.811C75.5 71.6098 71.6098 75.5 66.811 75.5H47.6037C42.8048 75.5 38.9146 71.6098 38.9146 66.811V47.6037ZM47.6037 45.3171C46.3408 45.3171 45.3171 46.3408 45.3171 47.6037V66.811C45.3171 68.0738 46.3408 69.0976 47.6037 69.0976H66.811C68.0738 69.0976 69.0976 68.0738 69.0976 66.811V47.6037C69.0976 46.3408 68.0738 45.3171 66.811 45.3171H47.6037Z"
    />
  </svg>
);

export { Grid };
