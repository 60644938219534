// ! THIS IS DEPRECATED NOW BECAUSE ALL LINE ITEM CATALOG RESULTS HAVE BEEN COMBINED INTO ONE LIST WITH useModelCatalog
// ! Remove this file when features have been refactored to hide line items

import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useModelList } from "@thrivelot/hooks";
import {
  filterCatalogItemsByQuery,
  filterUniqueSearchResults,
  getAllCatalogItemsByType,
  normalizeSearchResults,
  sortSearchResults,
} from "../helpers";

const useModelPlantCatalog = (props = { deferLoadOfProjects: false }) => {
  const {
    models: projects,
    queryModels: queryProjects,
    loading: loadingProjects,
    loaded: loadedProjects,
  } = useModelList({ modelName: "Project", deferLoad: props.deferLoadOfProjects });

  const [query, setQuery] = useState("");
  const [plants, setPlants] = useState([]);

  const allUniquePlantsFromProjects = useMemo(
    () =>
      sortSearchResults(
        filterUniqueSearchResults(normalizeSearchResults(getAllCatalogItemsByType(projects, "plantItems"), "plant"))
      ),
    [projects]
  );

  const queryRef = useRef();
  const plantsRef = useRef();
  const allUniquePlantsFromProjectsRef = useRef();

  queryRef.current = query;
  plantsRef.current = plants;
  allUniquePlantsFromProjectsRef.current = allUniquePlantsFromProjects;

  const queryPlants = useCallback(() => {
    const staticQuery = queryRef.current;

    if (staticQuery.length < 3) setPlants([]);
    else setPlants(filterCatalogItemsByQuery(allUniquePlantsFromProjectsRef.current, staticQuery));
  }, []);

  useEffect(() => {
    queryPlants();
  }, [queryPlants, query, loadedProjects]);

  return {
    query,
    setQuery,
    plants,
    queryProjects,
    loadingProjects,
    loadedProjects,
  };
};

export { useModelPlantCatalog };
