import React from 'react';

const Folder = ({
  color = 'green-main',
  size = 30,
  styleProps = '',
  ...rest
}) => (
  <svg
    width={size.toString()}
    height={size.toString()}
    viewBox="0 0 75 75"
    className={`fill-${color} ${styleProps}`}
    {...rest}
  >
    <path d="M64.1582 9.89618H40.7753C40.1974 9.89539 39.6259 9.7752 39.0966 9.54313C38.5673 9.31106 38.0916 8.97212 37.6993 8.54755L33.0189 3.48551C32.0032 2.38914 30.7726 1.51381 29.4038 0.914136C28.0351 0.314463 26.5576 0.00329131 25.0633 0H10.8228C7.95233 0.00502793 5.20098 1.14863 3.17213 3.18003C1.14328 5.21143 0.00250916 7.96482 0 10.8364V61.7038C0 65.2302 1.40027 68.6121 3.89285 71.1056C6.38542 73.5992 9.76611 75 13.2911 75H61.7089C65.2339 75 68.6145 73.5992 71.107 71.1056C73.5996 68.6121 75 65.2302 75 61.7038V20.7231C74.995 17.8498 73.8505 15.096 71.8178 13.0661C69.7851 11.0363 67.0303 9.89617 64.1582 9.89618ZM68.3449 61.7038C68.3449 63.467 67.6447 65.158 66.3984 66.4047C65.1522 67.6515 63.4619 68.3519 61.6994 68.3519H13.2816C11.5191 68.3519 9.82872 67.6515 8.58243 66.4047C7.33615 65.158 6.63607 63.467 6.63607 61.7038V33.6868C6.63607 31.9237 7.33615 30.2327 8.58243 28.9859C9.82872 27.7392 11.5191 27.0387 13.2816 27.0387H61.6994C63.4619 27.0387 65.1522 27.7392 66.3984 28.9859C67.6447 30.2327 68.3449 31.9237 68.3449 33.6868V61.7038ZM61.6994 20.4002H13.2816C10.9471 20.3933 8.65302 21.0096 6.63607 22.1857V10.7889C6.63856 9.68053 7.07915 8.6181 7.86171 7.83346C8.64428 7.04881 9.70534 6.60563 10.8133 6.60062H25.0538C25.6317 6.6014 26.2032 6.72157 26.7325 6.95364C27.2618 7.1857 27.7375 7.52465 28.1298 7.94922L32.8101 13.0113C33.8247 14.109 35.0552 14.9852 36.4242 15.585C37.7932 16.1848 39.2712 16.4952 40.7658 16.4968H64.1487C65.2574 16.4968 66.321 16.9367 67.1058 17.7201C67.8907 18.5035 68.3329 19.5664 68.3354 20.6756V22.1761C66.3191 21.0208 64.0325 20.4211 61.7089 20.4381L61.6994 20.4002Z" />
  </svg>
);

export { Folder };
