import React from "react";
import { Elements } from "@stripe/react-stripe-js";
import { useProposal, useRouter } from "@thrivelot/common";
import { useService } from "@thrivelot/hooks";
import { contentHashmap } from "../../Section/ProjectInvestment/Investment/contentHashmap";
import { PaymentForm } from "./PaymentForm";
import { constructStripePromise } from "./constructStripePromise";

const stripe = constructStripePromise();

const PaymentModal = () => {
  const showNotification = window.showNotification;
  const { history } = useRouter();
  const { request } = useService({ method: "post", level: "public", path: "create-stripe-charge", deferLoad: true });

  const { project, suppliers, projectActions, type } = useProposal();
  const supplier = suppliers[0];

  const content = contentHashmap[type];
  const { totalCost, payment, paymentBlock } = content.calculateCost(projectActions, content.type);

  const handleSubmit = async ({ token }) => {
    const result = await request({
      variables: {
        supplierId: supplier.id,
        projectId: project.id,
        amount: Math.ceil(totalCost),
        paymentMethod: token.id,
        paymentId: paymentBlock.id,
        innerPaymentId: payment.id,
      },
    });

    if (result.errors) {
      showNotification({ type: "error", body: `Payment Failed: ${result.errors[0].raw.message}` });
    } else {
      history.push({
        hash: {},
      });
      window.location.reload(true);
    }
  };

  return (
    <>
      <Elements stripe={stripe}>
        <PaymentForm amount={totalCost} onSubmit={handleSubmit} />
      </Elements>
    </>
  );
};

export { PaymentModal };
