/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getPlant = /* GraphQL */ `
  query GetPlant($id: ID!) {
    getPlant(id: $id) {
      id
      commonName
      familyCommonName
      scientificName
      family
      genus
      species
      otherCommonNames
      synonyms
      edibleParts
      form
      habit
      growingMonths
      bloomMonths
      fruitingMonths
      growthRate
      growingDescription
      averageHeightMm
      maximumHeightMm
      flowerColors
      flowerConspicuous
      foliageColors
      foliageTexture
      foliageConspicuous
      fruitOrSeedColors
      fruitOrSeedConspicuous
      daysToHarvest
      sowingDescription
      rowSpacingMm
      spreadMm
      minimumRootDepthMm
      anaerobicTolerance
      light
      soilHumidity
      soilNutriments
      soilSalinity
      atmosphericHumidity
      phMinimum
      phMaximum
      distributions
      links {
        usda
        tropicos
        telaBotanica
        powo
        plantnet
        gbif
        openfarm
        catminat
        wikipediaEn
        __typename
      }
      images {
        id
        bucket
        region
        key
        url
        __typename
      }
      createdAt
      updatedAt
      updatedMeta {
        id
        userCognitoId
        userId
        userName
        userEmail
        userGroups
        device
        __typename
      }
      __typename
    }
  }
`;
export const listPlants = /* GraphQL */ `
  query ListPlants(
    $filter: ModelPlantFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPlants(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        commonName
        familyCommonName
        scientificName
        family
        genus
        species
        otherCommonNames
        synonyms
        edibleParts
        form
        habit
        growingMonths
        bloomMonths
        fruitingMonths
        growthRate
        growingDescription
        averageHeightMm
        maximumHeightMm
        flowerColors
        flowerConspicuous
        foliageColors
        foliageTexture
        foliageConspicuous
        fruitOrSeedColors
        fruitOrSeedConspicuous
        daysToHarvest
        sowingDescription
        rowSpacingMm
        spreadMm
        minimumRootDepthMm
        anaerobicTolerance
        light
        soilHumidity
        soilNutriments
        soilSalinity
        atmosphericHumidity
        phMinimum
        phMaximum
        distributions
        links {
          usda
          tropicos
          telaBotanica
          powo
          plantnet
          gbif
          openfarm
          catminat
          wikipediaEn
          __typename
        }
        images {
          id
          bucket
          region
          key
          url
          __typename
        }
        createdAt
        updatedAt
        updatedMeta {
          id
          userCognitoId
          userId
          userName
          userEmail
          userGroups
          device
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const searchPlants = /* GraphQL */ `
  query SearchPlants(
    $filter: SearchablePlantFilterInput
    $sort: [SearchablePlantSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchablePlantAggregationInput]
  ) {
    searchPlants(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        commonName
        familyCommonName
        scientificName
        family
        genus
        species
        otherCommonNames
        synonyms
        edibleParts
        form
        habit
        growingMonths
        bloomMonths
        fruitingMonths
        growthRate
        growingDescription
        averageHeightMm
        maximumHeightMm
        flowerColors
        flowerConspicuous
        foliageColors
        foliageTexture
        foliageConspicuous
        fruitOrSeedColors
        fruitOrSeedConspicuous
        daysToHarvest
        sowingDescription
        rowSpacingMm
        spreadMm
        minimumRootDepthMm
        anaerobicTolerance
        light
        soilHumidity
        soilNutriments
        soilSalinity
        atmosphericHumidity
        phMinimum
        phMaximum
        distributions
        links {
          usda
          tropicos
          telaBotanica
          powo
          plantnet
          gbif
          openfarm
          catminat
          wikipediaEn
          __typename
        }
        images {
          id
          bucket
          region
          key
          url
          __typename
        }
        createdAt
        updatedAt
        updatedMeta {
          id
          userCognitoId
          userId
          userName
          userEmail
          userGroups
          device
          __typename
        }
        __typename
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
              __typename
            }
          }
        }
        __typename
      }
      __typename
    }
  }
`;
