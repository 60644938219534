import React, { useEffect, useMemo, useRef } from 'react';
import { GoogleMap } from '@react-google-maps/api';
import { useMap } from '@thrivelot/hooks';
import { isEqual } from '@thrivelot/utils';
import { LeafLoader } from '@thrivelot/stories';
import { useProposal } from '@thrivelot/common';
// import '@thrivelot/styles/dist/esm/index.css';
import { MapProposalElementZone } from '../MapProposalElementZone';

const Map = () => {
  const {
    loading,
    loadError,
    map,
    center,
    options,
    zoom,
    onLoad,
    onUnmount,
    onCenterChanged,
    onZoomChanged,
    setValue,
  } = useMap();
  const { project } = useProposal();
  const centerRef = useRef();
  centerRef.current = center;

  const { location } = useMemo(
    () => project?.details?.address || {},
    [project?.details?.address]
  );
  const zones = useMemo(() => project?.zones || [], [project?.zones]);

  useEffect(() => {
    if (location && !centerRef.current && !isEqual(centerRef.current, location))
      setValue('center', location);
  }, [location, setValue]);

  if (loading || loadError) return <LeafLoader />;

  return (
    <GoogleMap
      mapContainerClassName="absolute top-0 right-0 bottom-0 left-0"
      mapContainerStyle={{ position: 'absolute' }}
      options={options}
      zoom={zoom}
      center={center}
      onLoad={onLoad}
      onUnmount={onUnmount}
      onDragEnd={onCenterChanged}
      onZoomChanged={onZoomChanged}
    >
      {map && (
        <>
          {zones.map((zone) => (
            <MapProposalElementZone key={zone.id} zone={zone} />
          ))}
        </>
      )}
    </GoogleMap>
  );
};

export { Map };
