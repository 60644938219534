import React from "react";
import { useProposal } from "@thrivelot/common";
import { contentHashmap } from "./Investment/contentHashmap";
import { Investment } from "./Investment";
import { PayButton } from "./PayButton";
import { Paid } from "./Paid";

const PaymentFooter = ({ milestone, payment }) => {
  if (["SITE_ASSESSMENT"].includes(milestone)) {
    return payment && payment.paidAt ? <Paid /> : <PayButton />;
  }
  return null;
};

const ProjectInvestment = () => {
  const { projectActions, type } = useProposal();

  const content = contentHashmap[type];
  const { payment } = content.calculateCost(projectActions, content.type);

  return (
    <>
      <Investment />
      <PaymentFooter milestone={type} payment={payment} />
    </>
  );
};

export { ProjectInvestment };
