const MapHomeIcon = ({ size, color }) =>
  encodeURIComponent(
    `<svg
        width="${size}"
        height="${size}"
        viewBox="0 0 300 300"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#map-home-icon)">
          <path
            d="M300 120.584V262.636C300 278.338 287.221 291.078 271.558 291.078H28.4026C12.7403 291.078 0 278.338 0 262.636V120.584C0 111.195 4.5974 102.429 12.3506 97.1299L133.948 13.987C143.688 7.32467 156.273 7.32467 166.013 13.987L287.61 97.1299C295.364 102.429 300 111.195 300 120.584Z"
            fill="${color}"
          />
        </g>
        <defs>
          <clipPath id="map-home-icon">
            <rect y="9" width="300" height="282.078" fill="none" />
          </clipPath>
        </defs>
      </svg>`
  );

export default MapHomeIcon;
