import React from 'react';
import { dateFns } from '@thrivelot/date';
import { Calendar } from '@thrivelot/stories';

const MyCalendar = ({ projects }) => {
  const getCalendarNotesAndDatesWithPhases = () => {
    const datesWithPhases = [];
    const calendarNotes = [];

    projects.forEach((project) => {
      let { phases } = project;
      if (!phases) phases = [];

      (phases || []).forEach((phase) => {
        datesWithPhases.push({
          id: phase.id,
          title: phase.name,
          start: dateFns(phase.startDate).toDate(),
          end: dateFns(phase.endDate).toDate(),
          allDay: true,
        });
      });
    });

    return { calendarNotes, datesWithPhases };
  };

  const { calendarNotes, datesWithPhases } =
    getCalendarNotesAndDatesWithPhases();

  return (
    <div className="h-[20rem] md:h-[28rem] lg:h-[35rem] shadow bg-white-light">
      <Calendar events={datesWithPhases} notes={calendarNotes} />
    </div>
  );
};

export { MyCalendar };
