import { useConstructTimestamp, useModel } from '@thrivelot/hooks';
import { Descriptor, DateDone, Button } from '@thrivelot/stories';
import { useInvoiceProposalBillingSummary } from '@thrivelot/common';
import { ModelKit } from '@thrivelot/model-kit';
import { formatLongDate } from '@thrivelot/date';
import ReactDatePicker from 'react-datepicker';
import React, { useCallback } from 'react';
import { constructUuid, transformStringToTitleCase } from '@thrivelot/utils';
import { LayoutModal } from '../..';
import { DropdownBundleStatus } from './DropdownBundleStatus';
import { DropdownInvoiceProposalStatus } from './DropdownInvoiceProposalStatus';

const ModalAdminEditInvoiceProposal = ({
  onClose,
  projectId,
  invoiceProposalId,
}) => {
  const { model: project, loaded: projectLoaded } = useModel({
    modelName: 'Project',
    id: projectId,
  });
  const {
    model: invoiceProposal,
    loaded,
    updateModel,
    actions,
  } = useModel({ modelName: 'InvoiceProposal', id: invoiceProposalId });

  const { billingSummary: billing, loaded: billingLoaded } =
    useInvoiceProposalBillingSummary(invoiceProposalId);

  const { construct } = useConstructTimestamp();

  const { email } = project?.customer || {};

  const { bundleIds, housePercent } = invoiceProposal || {};

  // const updatePaidAtDate = useCallback(
  //   async (payment, paidAt) => {
  //     const constructedPayment = {
  //       id: constructUuid(),
  //       label: payment.label,
  //       name: payment.name,
  //       description: payment.description,
  //       houseSubtotal: payment.houseSubtotal,
  //       housePercent: payment.housePercent,
  //       priceSubtotal: payment.priceSubtotal,
  //       discountSubtotal: payment.discountSubtotal,
  //       totalAmount: payment.totalAmount,
  //       amountCharged: payment.totalAmount,
  //       amountReceived: payment.totalAmount,
  //       paidAt,
  //       items: JSON.stringify(payment.items),
  //     };

  //     const timestampAmount = payment.totalAmount / 100;
  //     const paymentTimestamp = construct({
  //       kind: 'invoiceProposalPaymentSent',
  //       description: `${email} paid $${timestampAmount.toFixed(2)}`,
  //       createdAt: paidAt,
  //     });

  //     const payout = billing.payouts.find(
  //       (p) => p.paymentLabel === payment.label
  //     );

  //     const constructedPayout = {
  //       id: constructUuid(),
  //       label: payout.label,
  //       paymentLabel: payment.label,
  //       totalAmount: payout.totalAmount,
  //       priceSubtotal: payout.priceSubtotal,
  //       supplierPayeeId: payout.supplierPayeeId,
  //       paidAt,
  //     };

  //     const supplierKit = new ModelKit({
  //       modelName: 'Supplier',
  //       id: payout.supplierPayeeId,
  //     });
  //     const supplier = await supplierKit.query();
  //     const { email: supplierEmail } = supplier || {};

  //     const payoutTimestampAmount = payout.totalAmount / 100;
  //     const payoutTimestamp = construct({
  //       kind: 'invoiceProposalPayoutSent',
  //       description: `Paid out $${payoutTimestampAmount.toFixed(
  //         2
  //       )} to ${supplierEmail}`,
  //       createdAt: paidAt,
  //     });

  //     let paymentActionName = 'add';
  //     let payoutActionName = 'add';

  //     if (billing.payments.find((p) => p.label === payment.label && p.id))
  //       paymentActionName = 'set';
  //     if (billing.payouts.find((p) => p.label === payout.label && p.id))
  //       payoutActionName = 'set';

  //     updateModel(
  //       actions
  //         .set('status', 'complete')
  //         .add('timestamps', [paymentTimestamp, payoutTimestamp])
  //         [paymentActionName](
  //           paymentActionName === 'set'
  //             ? `payments[prop:label:${payment.label}]`
  //             : 'payments',
  //           constructedPayment
  //         )
  //         [payoutActionName](
  //           payoutActionName === 'set'
  //             ? `payouts[prop:label:${payout.label}]`
  //             : 'payouts',
  //           constructedPayout
  //         ).result
  //     );
  //   },
  //   [
  //     updateModel,
  //     actions,
  //     construct,
  //     email,
  //     billing?.payouts,
  //     billing?.payments,
  //   ]
  // );

  return (
    <LayoutModal
      onClose={onClose}
      loading={!loaded || !billingLoaded || !projectLoaded}
      header={
        loaded && (
          <div className="text-lg text-brown-dark font-bold text-center">
            Add Order
          </div>
        )
      }
    >
      <div className="flex flex-col gap-4">
        <Descriptor classAppend="my-6">
          Here admins can override the status of both the line item bundles and
          the order. This affects the editability of different options of the
          order and the line items.
        </Descriptor>
        <div className="flex">
          <div className="flex-1">
            <div className="text-green-dark font-bold mb-1">Order Status</div>
            <DropdownInvoiceProposalStatus
              invoiceProposalId={invoiceProposalId}
            />
          </div>
          <div className="flex-1">
            <div className="text-green-dark font-bold mb-1">
              Line Item Bundle Status
            </div>
            {(bundleIds || []).map((bundleId) => (
              <DropdownBundleStatus key={bundleId} bundleId={bundleId} />
            ))}
          </div>
        </div>
        <div>
          <div className="text-brown-dark font-bold mb-2">ThriveLot Markup</div>
          <div className="flex gap-4">
            <div className="flex-1 flex items-center">
              <div className="text-brown-dark font-bold w-6 flex items-center justify-center">
                %
              </div>
              <input
                className="input"
                placeholder="Add markup percent..."
                value={housePercent || ''}
                onChange={(e) =>
                  updateModel(
                    actions.set('housePercent', e.target.value).result
                  )
                }
              />
            </div>
          </div>
        </div>
        <Descriptor classAppend="my-6">
          Here admins can add a paid at date and payment amounts if the order
          has been paid for. DO NOT CHANGE IF PAID FOR THROUGH THE PLATFORM.
        </Descriptor>
        {/* {(billing?.payments || []).map((payment) => (
          <div key={payment.id || payment.label}>
            <div className="text-brown-dark font-bold mb-2">
              {transformStringToTitleCase(payment.label)}
            </div>
            <div className="flex gap-4 items-center">
              <div className="flex w-fit">At</div>
              <ReactDatePicker
                popperPlacement="bottom-start"
                showPopperArrow={false}
                onChange={(date) =>
                  updatePaidAtDate(payment, date.toISOString())
                }
                customInput={
                  <div className="flex items-center gap-2 input hover:cursor-pointer">
                    <DateDone color="brown-dark" size={16} />
                    {payment.paidAt ? (
                      <div>{formatLongDate(payment.paidAt)}</div>
                    ) : (
                      <div className="italic">Select a date...</div>
                    )}
                  </div>
                }
              />
            </div>
          </div>
        ))} */}
        {(billing?.payments || []).map((payment) => (
          <div key={payment.id || payment.label}>
            {!payment.paidAt && payment.totalAmount ? (
              <>
                <div className="text-brown-dark font-bold mb-2">
                  {transformStringToTitleCase(payment.label)}
                </div>
                <div className="flex gap-4 items-center">
                  <Button
                    color="green-main"
                    label="Mark as paid"
                    onClick={() => {
                      if (
                        actions.get(`payments[prop:label:${payment.label}]`)
                      ) {
                        return updateModel(
                          actions.set(`payments[prop:label:${payment.label}]`, {
                            id: constructUuid(),
                            label: payment.label,
                            name: payment.name,
                            description: payment.description,
                            houseSubtotal: payment.houseSubtotal,
                            housePercent: payment.housePercent,
                            taxPercent: payment.taxPercent,
                            priceSubtotal: payment.priceSubtotal,
                            discountSubtotal: payment.discountSubtotal,
                            processingFeeAmount: payment.processingFeeAmount,
                            processingFeePercent: payment.processingFeePercent,
                            paymentMethod: payment.paymentMethod,
                            taxSubtotal: payment.taxSubtotal,
                            totalAmount: payment.totalAmount,
                            items: JSON.stringify(payment.items),
                            paidAt: new Date().toISOString(),
                          }).result
                        );
                      }

                      if (!payment.id) {
                        return updateModel(
                          actions.add('payments', {
                            id: constructUuid(),
                            label: payment.label,
                            name: payment.name,
                            description: payment.description,
                            houseSubtotal: payment.houseSubtotal,
                            housePercent: payment.housePercent,
                            taxPercent: payment.taxPercent,
                            priceSubtotal: payment.priceSubtotal,
                            discountSubtotal: payment.discountSubtotal,
                            processingFeeAmount: payment.processingFeeAmount,
                            processingFeePercent: payment.processingFeePercent,
                            paymentMethod: payment.paymentMethod,
                            taxSubtotal: payment.taxSubtotal,
                            totalAmount: payment.totalAmount,
                            items: JSON.stringify(payment.items),
                            paidAt: new Date().toISOString(),
                          }).result
                        );
                      }

                      return updateModel(
                        actions.set(
                          `payments[prop:label:${payment.label}].paidAt`,
                          new Date().toISOString()
                        ).result
                      );
                    }}
                  />
                </div>
              </>
            ) : undefined}
          </div>
        ))}

        {(billing?.payouts || []).map((payout) => (
          <div key={payout.id || payout.label}>
            {!payout.paidAt && payout.totalAmount ? (
              <>
                <div className="text-brown-dark font-bold mb-2">
                  {transformStringToTitleCase(payout.label)}
                </div>
                <div className="flex gap-4 items-center">
                  <Button
                    color="green-main"
                    label="Mark as paid "
                    onClick={() => {
                      if (!payout.id) {
                        return updateModel(
                          actions.add('payouts', {
                            id: constructUuid(),
                            label: payout.label,
                            paymentLabel: payout.paymentLabel,
                            totalAmount: payout.totalAmount,
                            taxSubtotal: payout.taxSubtotal,
                            priceSubtotal: payout.priceSubtotal,
                            supplierPayeeId: payout.supplierPayeeId,
                            paidAt: new Date().toISOString(),
                          }).result
                        );
                      }
                      return updateModel(
                        actions.set(
                          `payouts[prop:label:${payout.label}].paidAt`,
                          new Date().toISOString()
                        ).result
                      );
                    }}
                  />
                </div>
              </>
            ) : undefined}
          </div>
        ))}
      </div>
    </LayoutModal>
  );
};

export { ModalAdminEditInvoiceProposal };
