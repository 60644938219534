import { fetchUserCredentials, refreshUserSession } from "@thrivelot/auth";
import { dateFns } from "@thrivelot/date";

const fetchRefreshedCredentials = async () => {
  try {
    await refreshUserSession();
    return await fetchUserCredentials();
  } catch (err) {
    throw err;
  }
};

const fetchCredentials = async () => {
  let credentials;

  try {
    credentials = await fetchUserCredentials();
  } catch (err) {
    console.log(`Error fetching credentials: `, JSON.stringify(err, null, 2));
    throw err;
  }

  const timeTillRefresh = dateFns(credentials.expiration).diff(new Date());

  try {
    if (timeTillRefresh <= 500) credentials = await fetchRefreshedCredentials();
  } catch (err) {
    console.log(`Error refreshing credentials: `, JSON.stringify(err, null, 2));
    throw err;
  }

  return credentials;
};

export { fetchCredentials };
