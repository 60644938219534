import React, { useMemo, useCallback } from "react";
import { LayoutModal } from "../..";
import { useModel } from "@thrivelot/hooks";
import { ImgBox } from "@thrivelot/styles";
import { projectStyles as availableStyles } from "@thrivelot/config";
import { Checkmark } from "@thrivelot/stories";

// ! Possibly deprecated since this is built for the old customer form

const ModalEditStyles = ({ onClose, projectId }) => {
  const { model: project, updateModel, loaded, actions } = useModel({ modelName: "Project", id: projectId });

  const projectStylesMap = useMemo(() => {
    if (loaded) {
      const map = {};

      (project?.details?.styles || []).forEach((style) => {
        const resultKey = Object.keys(availableStyles).find((key) => availableStyles[key].name === style.name);
        map[resultKey] = { ...availableStyles[resultKey], key: resultKey };
      });

      return map;
    }
  }, [loaded, project?.details?.styles]);

  const handleSelect = useCallback(
    ({ key, imageUrl, ...style }) => {
      if (projectStylesMap[key]) return updateModel(actions.remove(`details.styles[name:${style.name}]`, style).result);

      return updateModel(actions.add("details.styles", style).result);
    },
    [projectStylesMap, actions, updateModel]
  );

  return (
    <LayoutModal
      onClose={onClose}
      loading={!loaded}
      header={
        <div className="text-lg text-brown-dark italic">Select all the features you&apos;re interested in...</div>
      }
    >
      <div className="grid grid-cols-2 gap-4">
        {projectStylesMap &&
          Object.keys(availableStyles).map((key) => {
            const isSelected = !!projectStylesMap[key];
            const { imageUrl, description, name } = availableStyles[key];
            return (
              <div
                key={key}
                onClick={() => handleSelect({ key, ...availableStyles[key] })}
                className="rounded-main shadow flex flex-col hover:cursor-pointer"
              >
                <div className="relative">
                  <ImgBox src={{ url: imageUrl }} />
                  {isSelected && (
                    <div className="absolute right-1 top-2 bg-white-light rounded-full">
                      <Checkmark color="green-main" size={40} />
                    </div>
                  )}
                </div>
                <div className="text-brown-dark p-2 font-bold">{name}</div>
                {description && (
                  <div className="text-brown-dark px-2 text-sm pb-2 flex-1 flex justify-center items-center">
                    {description}
                  </div>
                )}
              </div>
            );
          })}
      </div>
    </LayoutModal>
  );
};

export { ModalEditStyles };
