import { InvoiceProposal } from '@thrivelot/aws';

const getSortedPhases = (model: any): any[] => {
  const phases = [...(model.phases || [])];
  return phases.sort((a, b) => {
    const aDate = (a.dates || [])[0];
    const bDate = (b.dates || [])[0];
    if (!aDate && !bDate) {
      return 0; // no dates, don't change order
    }
    if (!aDate) {
      return 1; // a has no dates, move it to end
    }
    if (!bDate) {
      return -1; // b has no dates, move it to end
    }
    return new Date(aDate).getTime() - new Date(bDate).getTime();
  });
};

export { getSortedPhases };
