import React, { useState } from "react";
import colors from "@thrivelot/tailwind/colors";
import { AddIcon, Select, InputBubble } from "@thrivelot/styles";
import { transformStringToTitleCase } from "@thrivelot/utils";

const options = [
  { label: "Certificate", value: "certificates" },
  { label: "Degree", value: "degrees" },
  { label: "Skill", value: "skills" },
];

const Input = ({ addQualification }) => {
  const [type, setType] = useState("certificates");
  const [qualification, setQualification] = useState("");

  const isDisabled = qualification.trim() === "";

  const onSubmit = () => {
    addQualification(type, qualification);
    setQualification("");
  };
  const handleOnKeyPress = ({ key }) => {
    if (!isDisabled && key === "Enter") onSubmit();
  };

  return (
    <div
      style={{
        width: "100%",
        marginTop: "8px",
        marginBottom: "8px",
        display: "flex",
        alignItems: "center",
      }}
    >
      <Select
        options={options}
        value={{
          label: transformStringToTitleCase(type).substr(0, type.length - 1),
          value: type,
        }}
        onChange={({ value }) => setType(value)}
        style={{ marginRight: "6px", width: "150px" }}
      />
      <div style={{ position: "relative", width: "100%", marginLeft: "6px" }}>
        <InputBubble
          placeholder={`Add a new ${type.substr(0, type.length - 1)}`}
          value={qualification}
          onChange={(e) => setQualification(e.target.value)}
          onKeyPress={handleOnKeyPress}
          style={{ paddingRight: "31px", width: "calc(100% - 36px)" }}
        />
        <AddIcon
          hoverable
          disabled={isDisabled}
          color={colors.green.main}
          size={21}
          style={{
            position: "absolute",
            top: "7px",
            right: "7px",
          }}
          onClick={onSubmit}
        />
      </div>
    </div>
  );
};

export default Input;
