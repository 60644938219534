import React, { useRef } from "react";
import SvgContainer from "../SvgWrapper";

const ShovelIcon = ({ color, size, ...rest }) => {
  const clipRef = useRef(`${Math.floor(Math.random() * 1000)}`);
  return (
    <SvgContainer
      width={`${size}`}
      height={`${size}`}
      viewBox="0 0 300 300"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <g clipPath={`url(#${clipRef.current})`}>
        <path
          d="M294.023 38.3829L261.613 5.97296C255.744 0.103846 247.122 -1.5582 238.552 1.55814C228.684 5.14194 221.776 13.9716 220.893 24.1516L219.127 43.8885C218.088 44.6156 217.102 45.4467 216.167 46.3816L127.922 134.626L106.16 118.837C96.1355 111.565 82.9949 109.539 70.1659 113.383C55.4672 117.798 43.3654 129.069 37.9118 143.508L3.63198 234.089C-3.58755 253.255 0.152061 273.355 13.3965 286.6C26.641 299.896 46.7933 303.636 65.9069 296.364L156.541 262.136C170.98 256.683 182.25 244.581 186.665 229.882C190.457 217.001 188.535 203.913 181.212 193.889L165.37 172.126L253.615 83.8296C254.55 82.9467 255.381 81.9598 256.108 80.921L275.948 79.207C286.025 78.2721 294.854 71.3123 298.49 61.4958C301.554 52.8739 299.944 44.304 294.023 38.3829ZM88.3446 211.652C84.5011 207.808 98.8363 187.084 121.118 163.556C122.313 166.984 124.338 170.152 127.091 172.905C129.844 175.658 133.064 177.684 136.492 178.93C112.964 201.212 92.24 215.547 88.3446 211.652ZM242.604 72.8185L156.073 159.349L154.203 161.219L153.528 161.894C153.165 162.31 152.697 162.673 152.282 162.985C148.023 166.101 141.998 165.738 138.102 161.894C134.259 158.051 133.895 152.026 137.063 147.767C137.375 147.299 137.739 146.884 138.102 146.468L140.647 143.923L218.14 66.482L220.322 64.2486L227.178 57.3926C231.437 53.1336 238.345 53.1336 242.604 57.3926C246.863 61.6516 246.863 68.5595 242.604 72.8185Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id={clipRef.current}>
          <rect width="300" height="300" fill="none" />
        </clipPath>
      </defs>
    </SvgContainer>
  );
};

export default ShovelIcon;
