import { useModel } from '@thrivelot/hooks';
import { LeafLoader } from '@thrivelot/stories';
import {
  transformCurrencyToString,
  transformStringToTitleCase,
} from '@thrivelot/utils';
import React, { useMemo } from 'react';
import * as icons from '@thrivelot/stories';
import { lineItemIconMap, lineItemColorMap } from '@thrivelot/config';
import { useFeatureItemsMap } from './useFeatureItemsMap';
import { useFeaturesMap } from './useFeaturesMap';
import { useSortedItems } from './useSortedItems';
import { featurelessFeature } from '../FormBundleFeature/FormBundleFeature';
import { LineItemImage } from '../LineItemImage';

const ViewItemIndividualTotals = ({
  invoiceProposalId,
  projectId,
  payment = {},
  descriptionMap = {},
}) => {
  const { model: project, loaded: projectLoaded } = useModel({
    modelName: 'Project',
    id: projectId,
  });

  const { sortedItems } = useSortedItems({ items: payment.items || [] });
  const { map: featureItemsMap } = useFeatureItemsMap({ items: sortedItems });
  const { map: featuresMap } = useFeaturesMap({ project });

  const sortedFeatureIds = useMemo(() => {
    const features = Object.keys(featureItemsMap)
      .filter((featureId) => featureId !== featurelessFeature.id)
      .sort((a, b) => {
        const featureA = featuresMap[a];
        const featureB = featuresMap[b];

        if (featureA?.name === featureB?.name) return 0;
        return featureA?.name < featureB?.name ? -1 : 1;
      });

    if (featureItemsMap?.[featurelessFeature.id])
      features.unshift(featurelessFeature.id);

    return features;
  }, [featuresMap, featureItemsMap]);

  if (!projectLoaded) return <LeafLoader />;

  return (
    <>
      {sortedFeatureIds.map(
        (featureId) =>
          featuresMap[featureId] && (
            <div key={featureId}>
              <div className="font-bold text-xl mb-2 text-center">
                {featuresMap[featureId].name}
              </div>
              {descriptionMap[featureId] && (
                <div className="pb-6 pt-4 whitespace-pre-line">
                  {descriptionMap[featureId]}
                </div>
              )}

              <div className="divide-y divide-tan-light">
                {(featureItemsMap[featureId]?.items || []).map((item) => {
                  const {
                    id,
                    kind,
                    name,
                    quantity,
                    unit,
                    totalAmount,
                    isHalf,
                    originalTotals,
                    data,
                    description,
                  } = item;
                  const Icon = icons[lineItemIconMap[kind]];

                  return (
                    <div
                      key={id}
                      className="flex py-3 text-brown-dark items-center"
                    >
                      <Icon size={16} color={lineItemColorMap[kind]} />
                      <LineItemImage
                        item={item}
                        classAppend="h-[20px] w-[20px] flex items-center justify-center overflow-hidden rounded-full ml-1"
                      />
                      <div className="flex-1 ml-2">
                        {name} &times; {quantity}{' '}
                        {transformStringToTitleCase(unit || 'Units')}
                        {description && (
                          <span className="ml-1 italic whitespace-pre-line">
                            - {description}
                          </span>
                        )}
                      </div>
                      <div>
                        {isHalf && <span className="text-sm mr-2">(50%)</span>}
                        {transformCurrencyToString(totalAmount)}
                      </div>
                    </div>
                  );
                })}

                <div className="flex py-3 text-green-dark items-center">
                  <div className="flex-1 font-bold ml-2">
                    {featuresMap[featureId].name} Subtotal
                  </div>
                  <div className="font-bold">
                    {transformCurrencyToString(
                      featureItemsMap[featureId]?.itemsTotalAmount || 0
                    )}
                  </div>
                </div>
              </div>
            </div>
          )
      )}
    </>
  );
};

export { ViewItemIndividualTotals };
