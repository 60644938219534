import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { usePopper } from 'react-popper';

export const Tip = ({ children, content }) => {
  const [referenceElement, setReferenceElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);
  const [arrowElement, setArrowElement] = useState(null);
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    modifiers: [{ name: 'arrow', options: { element: arrowElement } }],
    placement: 'top',
  });

  return (
    <>
      <span ref={setReferenceElement} onClick={() => popperElement.hide()}>
        {children}
      </span>
      <div
        className="bg-tan-light text-brown-dark text-sm text-center py-2 px-4 rounded m-2"
        ref={setPopperElement}
        style={styles.popper}
        {...attributes.popper}
      >
        {content}
        <div className="tip-arrow" ref={setArrowElement} style={styles.arrow} />
      </div>
    </>
  );
};

Tip.propTypes = {
  /**
   * Header string
   */
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),

  /**
   * Header string
   */
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
};

Tip.defaultProps = {
  content: '',
};
