import { getHashFromCurrentUrl } from "./getHashFromCurrentUrl";
import { modelNameByUrlKey } from "./modelNameByUrlKey";

const getModelNameFromCurrentUrl = (modelName, hash) => {
  if (modelName) {
    return modelName;
  }
  if (!hash) {
    hash = getHashFromCurrentUrl();
  }
  const modelNames = Object.keys(modelNameByUrlKey);
  for (let i = 0; i < modelNames.length; i++) {
    const modelNameOption = modelNames[i];
    if (hash[modelNameByUrlKey[modelNameOption]] || window.location.pathname.includes(modelNameOption.toLowerCase())) {
      return modelNameOption;
    }
  }
  return null;
};

export { getModelNameFromCurrentUrl };
