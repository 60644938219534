import { isEmpty } from '@thrivelot/utils';
import { findPropertyByPath, middleware } from './middleware';

const action = (
  obj: any,
  path: string,
  defaultValue: any,
  returnWithIndex?: boolean
): any => {
  if (isEmpty(path)) return defaultValue;
  if (path.includes('[')) return findPropertyByPath(obj, path, returnWithIndex);
  return obj[path];
};

export const get = (
  obj: any,
  path: string,
  defaultValue?: any,
  returnWithIndex?: boolean
) =>
  middleware(
    obj,
    path,
    (newObj: any, newPath: string) =>
      action(newObj, newPath, defaultValue, returnWithIndex),
    true
  );
