import React from 'react';

const AngleRight = ({
  color = 'green-main',
  size = 30,
  styleProps = '',
  ...rest
}) => (
  <svg
    width={size.toString()}
    height={size.toString()}
    viewBox="0 0 75 75"
    className={`fill-${color} ${styleProps}`}
    {...rest}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.1768 1.1768C19.7459 -0.392268 22.2898 -0.392268 23.8589 1.1768L57.3411 34.6589C58.9101 36.228 58.9101 38.772 57.3411 40.3411L23.8589 73.8232C22.2898 75.3923 19.7459 75.3923 18.1768 73.8232C16.6077 72.2541 16.6077 69.7102 18.1768 68.1411L48.8179 37.5L18.1768 6.85891C16.6077 5.28984 16.6077 2.74587 18.1768 1.1768Z"
    />
  </svg>
);

export { AngleRight };
