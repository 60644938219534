import React, { useMemo, useState } from 'react';
import { useCanCan, useModel, useQueryParam } from '@thrivelot/hooks';
import { Button, Input, CardLoader } from '@thrivelot/stories';
import { DropdownFolderActions } from './DropdownFolderActions';
import { ListFiles } from './ListFiles';

export const RowItemProjectFileFolder = ({ projectId, fileFolder, files }) => {
  const [editing, setEditing] = useState(false);
  const { param: openFolders, setParam } = useQueryParam('openFolders');
  const {
    model: project,
    actions,
    update,
    loaded,
  } = useModel({ modelName: 'Project', id: projectId });
  const { canEdit } = useCanCan(project);

  const filesInFolder = useMemo(() => {
    if (!files.length) return [];

    return (fileFolder?.fileIds || []).map((fileId) =>
      (files || []).find((file) => file.id === fileId)
    );
  }, [files, fileFolder]);

  if (!loaded) return <CardLoader />;

  return (
    <div className="text-brown-dark rounded-main shadow bg-white-light">
      <div className="flex justify-between items-center p-4">
        {editing ? (
          <div className="w-full mr-4">
            <Input
              classAppend="w-full"
              placeholder="Enter name..."
              value={fileFolder?.name || ''}
              onChange={(e) =>
                update(
                  actions.set(
                    `fileFolders[id:${fileFolder.id}].name`,
                    e.target.value
                  ).result
                )
              }
            />
          </div>
        ) : (
          <div>
            <div className="font-bold">{fileFolder?.name || ''}</div>
            <div className="italic text-sm">{`${
              filesInFolder.length || 0
            } file${filesInFolder.length === 1 ? '' : 's'}`}</div>
          </div>
        )}
        <div className="flex items-center gap-3">
          {canEdit && (
            <>
              {editing ? (
                <Button
                  icon="Minus"
                  kind="outline"
                  size="small"
                  color="blue-main"
                  label="Save"
                  onClick={() => setEditing(false)}
                />
              ) : (
                <DropdownFolderActions
                  projectId={projectId}
                  folderId={fileFolder.id}
                  setEditing={setEditing}
                />
              )}
            </>
          )}
          <div className="w-24 flex justify-end">
            {(openFolders || []).includes(fileFolder?.id) ? (
              <Button
                icon="Shrink"
                kind="prompt"
                size="small"
                color="orange-main"
                label="Close"
                onClick={() => {
                  const newParams = (openFolders || []).filter(
                    (id) => id !== fileFolder?.id
                  );
                  if (newParams.length > 0) return setParam(newParams);
                  return setParam();
                }}
              />
            ) : (
              <Button
                icon="Expand"
                kind="prompt"
                size="small"
                color="brown-dark"
                label="Expand"
                onClick={() =>
                  setParam([...(openFolders || []), fileFolder?.id])
                }
              />
            )}
          </div>
        </div>
      </div>
      {(openFolders || []).includes(fileFolder?.id) && (
        <div className="h-[1px] bg-tan-light w-full" />
      )}
      <div className="transition-height ease-in-out duration-300"></div>
      {(openFolders || []).includes(fileFolder?.id) ? (
        <ListFiles projectId={projectId} fileFolder={fileFolder} />
      ) : null}
    </div>
  );
};
