import React, { useCallback, useEffect, useState } from 'react';
import { LogoGold, Button } from '@thrivelot/stories';
import { useAuth, useQueryParam } from '@thrivelot/hooks';
import { Link, useNavigate } from 'react-router-dom';
import { InputPassword } from '../../InputPassword';

const PageLogin = () => {
  const navigate = useNavigate();
  const { constructParamWithKeyAndValue } = useQueryParam('change');
  const { param: login } = useQueryParam('login');
  const { param: returnTo, paramAsString: returnToString } =
    useQueryParam('returnTo');
  const [errors, setErrors] = useState(false);
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState(login?.email || '');
  const [password, setPassword] = useState('');

  const showSignUp = window?.appConfig?.appName === 'customer';

  const { signIn } = useAuth();

  useEffect(() => {
    document.title = 'Thrive Lot | Login';
  }, []);

  const handleSubmit = useCallback(
    (evt) => {
      evt.preventDefault();

      setErrors([]);
      setLoading(true);
      return signIn(email.trim(), password)
        .then((result) => {
          setLoading(false);

          if (result?.requiresNewPassword) {
            const change = constructParamWithKeyAndValue({ email });
            navigate(
              `/auth/change-password?${[change, returnToString]
                .filter((x) => !!x)
                .join('&')}`
            );
          } else {
            navigate(returnTo || '/', { replace: true });
          }
        })
        .catch((error) => {
          if (error.message.includes('422'))
            setErrors((prev) => [...prev, 'Invalid email or password']);
          else if (error.message.includes('401'))
            setErrors((prev) => [...prev, 'Invalid email or password']);
          else setErrors((prev) => [...prev, error.message]);

          setLoading(false);
        });
    },
    [
      email,
      password,
      setErrors,
      setLoading,
      signIn,
      constructParamWithKeyAndValue,
      navigate,
    ]
  );

  return (
    <form
      onSubmit={handleSubmit}
      className="max-w-sm w-full bg-white-dark shadow rounded-main p-5"
    >
      <div className="flex justify-center mb-8 mt-4">
        <LogoGold size={180} />
      </div>
      <input
        placeholder="Email"
        className="input mb-4"
        value={email}
        onChange={(evt) => setEmail(evt.target.value)}
      />
      <div className="mb-4">
        <InputPassword
          placeholder="Password"
          className="input flex-1"
          value={password}
          onChange={(evt) => setPassword(evt.target.value)}
        />
      </div>

      {errors.length > 0 && (
        <div className="mb-4">
          {errors.map((error) => (
            <div key={error} className="mb-2 text-sm text-red-main">
              {error}
            </div>
          ))}
        </div>
      )}

      <Button
        block
        loading={loading}
        disabled={loading}
        label="Log In"
        color="green-main"
        type="submit"
      />

      <div className="flex flex-col items-center mt-5 gap-4">
        <Link
          to="/auth/forgot-password"
          className="text-blue-main font-bold text-sm"
        >
          Forgot Password?
        </Link>
      </div>
      {showSignUp && ( // Doubling up the showSignUp logic because the Forgot Password link is commented (due to AWS SNS issues). If we uncomment it remove this outermost showSignUp check.
        <div className="flex flex-col items-center mt-5 gap-4">
          {/* <Link
            to="/auth/forgot-password"
            className="text-blue-main font-bold text-sm"
          >
            Forgot Password?
          </Link> */}
          {showSignUp && (
            <a
              className="text-blue-main font-bold text-sm"
              href="https://www.thrivelot.com/availability"
            >
              Don't have an account? Sign up here
            </a>
          )}
        </div>
      )}
    </form>
  );
};

export { PageLogin };
