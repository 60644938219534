import { constructUuid } from "@thrivelot/utils";
import { ModelKit } from "@thrivelot/model-kit";
import { constructSupplier } from "./constructSupplier";
import { fetchOrCreateCognitoUser } from "..";

const createSupplier = async ({ email, firstname, lastname, phone }) => {
  const showNotification = window.showNotification;

  const supplierId = constructUuid();
  let cognitoId;
  const cognitoResults = await fetchOrCreateCognitoUser({ email, phone, group: "supplier", userId: supplierId });
  cognitoId = cognitoResults.Username;

  const supplier = constructSupplier({
    email,
    firstname,
    lastname,
    phone,
    owners: [cognitoId],
  });

  try {
    const supplierKit = new ModelKit({ modelName: "Supplier", model: { ...supplier, id: supplierId } });
    await supplierKit.create();
  } catch (err) {
    showNotification({ type: "error", body: err });
    return null;
  }

  return supplierId;
};

export { createSupplier };
