import { ModelKit } from "@thrivelot/model-kit";
import { isNumber } from "@thrivelot/utils";

const deconstructPlantId = (id) => {
  if (isNumber(id)) return id;
  else if (id.includes("__//__") && isNumber(id.split("__//__")[0])) return id.split("__//__")[0];

  return null;
};

const getPlantFromDatabase = (id) => {
  const plantId = deconstructPlantId(id);

  if (!isNumber(plantId)) return null;

  const plantKit = new ModelKit({ modelName: "Plant", id: plantId });
  return plantKit.query();
};

export { getPlantFromDatabase };
