import React from "react";
import { useTheme } from "@thrivelot/hooks";
import { useRouter } from "@thrivelot/common";
import { ButtonRaised } from "@thrivelot/styles";

const PayButton = () => {
  const theme = useTheme();
  const { history, urlHashes, urlModalValues } = useRouter();

  const openModal = () => {
    history.push({
      hash: {
        [urlHashes.proposalModal]: urlModalValues.proposalPayment,
      },
    });
  };

  return (
    <ButtonRaised onClick={openModal} backgroundColor={theme.green} style={{ minHeight: "48px", maxHeight: "48px" }}>
      Pay Now
    </ButtonRaised>
  );
};

export { PayButton };
