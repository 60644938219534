import { PaymentCalculationType } from "@thrivelot/aws";
import { calculateTotalLaborCost } from "./calculateTotalLaborCost";
import { calculateTotalMaterialCost } from "./calculateTotalMaterialCost";

const calculatePhasePayment = (payment, calculations, project, noMultiplier = false) => {
  let subtotal = 0;

  const lineItems = project.lineItems || [];
  const plantItems = project.plantItems || [];

  const totalLaborCost = calculateTotalLaborCost(lineItems);
  const totalMaterialCost = calculateTotalMaterialCost(lineItems, plantItems);

  if (payment.payments)
    payment.payments.forEach((pmnt, index) => {
      if (calculations[index]) {
        let paymentTotal = 0;

        calculations[index].forEach((item) => {
          if (item.type === PaymentCalculationType.AMOUNT) paymentTotal += item.amount;
          else if (item.type === PaymentCalculationType.LABOR_PERCENTAGE)
            paymentTotal += (item.amount * totalLaborCost) / 100;
          else paymentTotal += (item.amount * totalMaterialCost) / 100;
        });

        if (noMultiplier) subtotal += paymentTotal;
        else {
          const multiplier = pmnt.markup + 1;
          subtotal += paymentTotal * multiplier;
        }
      }
    });

  return Math.ceil(subtotal / 100) * 100;
};

export { calculatePhasePayment };
