import React from "react";
import { dateFns } from "@thrivelot/date";
import { useTheme } from "@thrivelot/hooks";
import { useProposal, useProposalView } from "@thrivelot/common";
import { TextBody, TextSmallHeader } from "@thrivelot/styles";
import { useSpacing } from "../../../../hooks";

const DATE_FORMAT = "D MMM, YYYY";

const Dates = () => {
  const { projectActions } = useProposal();
  const { phase: phaseId } = useProposalView();
  const theme = useTheme();
  const spacing = useSpacing();
  if (!phaseId) return null;
  const startDate = projectActions.get(`phases[id:${phaseId}].startDate`);
  const endDate = projectActions.get(`phases[id:${phaseId}].endDate`);
  const dates = `${dateFns(startDate).format(DATE_FORMAT)} - ${dateFns(endDate).format(DATE_FORMAT)}`;
  return (
    <>
      <TextSmallHeader style={{ margin: `${spacing} ${spacing} 8px`, color: theme.textLabel }}>
        Timeline
      </TextSmallHeader>
      <TextBody style={{ margin: `0px ${spacing} ${spacing}`, color: theme.textTitle }}>{dates}</TextBody>
    </>
  );
};

export { Dates };
