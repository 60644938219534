const getCombinedBundleItems = (model, bundles) => {
  const lineItems = bundles.map((bundle) => bundle.lineItems || []).flat();
  const changeItems = bundles.map((bundle) => bundle.changeItems || []).flat();

  return {
    lineItems,
    changeItems,
  };
};

export { getCombinedBundleItems };
