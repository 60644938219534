import styled, { css } from "styled-components";

const widthConstructor = ({ width }) => `${width}px`;
const heightConstructor = ({ height }) => `${height}px`;

const SvgWrapper = styled.svg`
  transition: opacity 0.5s ease-out, box-shadow 0.5s ease-out, color 1s ease-in-out, background-color 1s ease-in-out;
  -webkit-tap-highlight-color: transparent;
  -webkit-appearance: none;
  min-width: ${widthConstructor};
  max-width: ${widthConstructor};
  min-height: ${heightConstructor};
  max-height: ${heightConstructor};
  ${({ disabled, hoverable }) =>
    !disabled &&
    hoverable &&
    css`
      cursor: pointer;
      &:hover {
        opacity: 0.7;
      }
    `}
  ${({ disabled }) =>
    disabled &&
    css`
      pointer-events: none;
    `}
`;

export default SvgWrapper;
