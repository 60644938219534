import React from 'react';

const User = ({
  color = 'green-main',
  size = 30,
  styleProps = '',
  ...rest
}) => (
  <svg
    width={size.toString()}
    height={size.toString()}
    viewBox="0 0 75 75"
    className={`fill-${color} ${styleProps}`}
    {...rest}
  >
    <path d="M37.5 0C16.8228 0 0 16.8228 0 37.5C0 58.1772 16.8228 75 37.5 75C58.1772 75 75 58.1772 75 37.5C75 16.8228 58.1772 0 37.5 0ZM37.5 68.3544C28.7563 68.3544 20.8386 64.6899 15.2278 58.8228C16.5949 50.6203 23.5063 43.7563 32.6677 41.9525C33.2563 41.8291 33.712 41.3544 33.7975 40.7468C33.8829 40.1487 33.5791 39.5601 33.038 39.2848C29.8006 37.6044 27.788 34.3006 27.788 30.6551C27.788 25.2911 32.1456 20.9335 37.5 20.9335C42.8544 20.9335 47.2215 25.2911 47.2215 30.6551C47.2215 34.3006 45.1994 37.6044 41.962 39.2848C41.4209 39.5601 41.1171 40.1487 41.2025 40.7468C41.288 41.3544 41.7437 41.8291 42.3418 41.9525C51.5032 43.7563 58.4051 50.6203 59.7722 58.8228C54.1614 64.6899 46.2437 68.3544 37.5 68.3544ZM62.0791 56.1171C60 48.693 53.9525 42.5411 45.9589 39.9494C48.5506 37.6044 50.0696 34.2532 50.0696 30.6551C50.0696 23.7247 44.4304 18.0854 37.5 18.0854C30.5696 18.0854 24.9399 23.7247 24.9399 30.6551C24.9399 34.2532 26.4589 37.6044 29.0411 39.9494C21.0475 42.5411 15 48.693 12.9209 56.1171C8.98101 50.943 6.64557 44.4873 6.64557 37.5C6.64557 20.4873 20.4873 6.64557 37.5 6.64557C54.5127 6.64557 68.3544 20.4873 68.3544 37.5C68.3544 44.4873 66.019 50.943 62.0791 56.1171Z" />
  </svg>
);

export { User };
