import React from 'react';

const Minus = ({
  color = 'green-main',
  size = 30,
  styleProps = '',
  ...rest
}) => (
  <svg
    width={size.toString()}
    height={size.toString()}
    viewBox="0 0 75 75"
    className={`fill-${color} ${styleProps}`}
    {...rest}
  >
    <path d="M50.3164 40.8228H24.6835C22.8512 40.8228 21.3607 39.3323 21.3607 37.5C21.3607 35.6678 22.8512 34.1772 24.6835 34.1772H50.3164C52.1487 34.1772 53.6392 35.6678 53.6392 37.5C53.6392 39.3323 52.1487 40.8228 50.3164 40.8228Z" />
    <path d="M37.5 75C16.8228 75 0 58.1772 0 37.5C0 16.8228 16.8228 0 37.5 0C58.1772 0 75 16.8228 75 37.5C75 58.1772 58.1772 75 37.5 75ZM37.5 6.64557C20.4873 6.64557 6.64557 20.4873 6.64557 37.5C6.64557 54.5127 20.4873 68.3544 37.5 68.3544C54.5127 68.3544 68.3544 54.5127 68.3544 37.5C68.3544 20.4873 54.5127 6.64557 37.5 6.64557Z" />
  </svg>
);

export { Minus };
