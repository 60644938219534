import { AngleLeft } from '@thrivelot/stories';
import React from 'react';
import { Link } from 'react-router-dom';

const LinkBack = ({ label, to }) => (
  <Link to={to} className="flex items-center gap-2 text-tan-dark">
    <AngleLeft size={14} color="tan-dark" />
    {label}
  </Link>
);

export { LinkBack };
