import { useCallback, useEffect, useState } from 'react';

const useTimer = (amountOfTime = 0) => {
  const [timeRemaining, setTimeRemaining] = useState(amountOfTime);
  const [status, setStatus] = useState('stopped');

  const start = useCallback(() => {
    setStatus('running');
  }, []);

  const stop = useCallback(() => {
    setStatus('stopped');
    setTimeRemaining(amountOfTime);
  }, []);

  const done = useCallback(() => {
    setStatus('finished');
    setTimeRemaining(amountOfTime);
  }, []);

  useEffect(() => {
    if (status !== 'running') return;

    let didCancel;

    const interval = setInterval(() => {
      if (!didCancel) setTimeRemaining((prevState) => prevState - 1);
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [status]);

  useEffect(() => {
    if (timeRemaining === 0) done();
  }, [timeRemaining, done]);

  return {
    timeRemaining,
    status,
    start: () => start(),
    stop: () => stop(),
  };
};

export { useTimer };
