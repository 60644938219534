import colors from '@thrivelot/tailwind/colors';

export const getTailwindColorStrings = () => {
  const result = [];

  Object.keys(colors).forEach((main) => {
    if (main !== 'current' && main !== 'transparent') {
      Object.keys(colors[main]).forEach((variant) => {
        result.push(`${main}-${variant}`);
      });
    }
  });

  return result;
};
