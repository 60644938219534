import React from 'react';

const Menu = ({
  color = 'green-main',
  size = 30,
  styleProps = '',
  ...rest
}) => (
  <svg
    width={size.toString()}
    height={size.toString()}
    viewBox="0 0 75 75"
    className={`fill-${color} ${styleProps}`}
    {...rest}
  >
    <path d="M71.6772 15.6456H3.32278C1.51899 15.6456 0 14.1266 0 12.3228C0 10.519 1.51899 9 3.32278 9H71.6772C73.481 9 75 10.519 75 12.3228C75 14.1266 73.481 15.6456 71.6772 15.6456Z" />
    <path d="M71.6772 49.8228H3.32278C1.51899 49.8228 0 48.3038 0 46.5C0 44.6962 1.51899 43.1772 3.32278 43.1772H71.6772C73.481 43.1772 75 44.6962 75 46.5C75 48.3038 73.481 49.8228 71.6772 49.8228Z" />
    <path d="M71.6772 66.9114H3.32278C1.51899 66.9114 0 65.3925 0 63.5887C0 61.7849 1.51899 60.2659 3.32278 60.2659H71.6772C73.481 60.2659 75 61.7849 75 63.5887C75 65.3925 73.481 66.9114 71.6772 66.9114Z" />
    <path d="M71.6772 32.7342H3.32278C1.51899 32.7342 0 31.2152 0 29.4114C0 27.6076 1.51899 26.0886 3.32278 26.0886H71.6772C73.481 26.0886 75 27.6076 75 29.4114C75 31.2152 73.481 32.7342 71.6772 32.7342Z" />
  </svg>
);

export { Menu };
