import styled from "styled-components";

const NoteContainer = styled.div`
  transition: opacity 0.5s ease-out, box-shadow 0.5s ease-out, color 1s ease-in-out, background-color 1s ease-in-out;
  overflow: hidden;
  min-height: fit-content;
  max-height: fit-content;
  margin-bottom: 12px;
  display: flex;
  flex-direction: column;
`;

export { NoteContainer };
