import styled, { css } from "styled-components";
import CssDivDefault from "./CssDivDefault";

const sizeConstructor = ({ size }) => `${size}px`;

const DivCircle = styled.div`
  ${CssDivDefault}
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: ${sizeConstructor};
  max-height: ${sizeConstructor};
  min-width: ${sizeConstructor};
  max-width: ${sizeConstructor};
  border-radius: ${sizeConstructor};
  ${({ shadowBackground }) =>
    shadowBackground &&
    css`
      background: radial-gradient(rgba(0, 0, 0, 0.4), rgba(200, 200, 200, 0.1));
    `}
`;

export default DivCircle;
