import styled from "styled-components";
import CssRaisedShadow from "./CssRaisedShadow";
import CssDivDefault from "./CssDivDefault";

const sizeConstructor = ({ size }) => `${size}px`;

const DivCircleRaised = styled.div`
  ${CssDivDefault}
  ${CssRaisedShadow}
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: ${sizeConstructor};
  max-height: ${sizeConstructor};
  min-width: ${sizeConstructor};
  max-width: ${sizeConstructor};
  border-radius: ${sizeConstructor};
`;

export default DivCircleRaised;
