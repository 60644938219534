import { constructUuid } from "@thrivelot/utils";
import { ModelKit } from "@thrivelot/model-kit";
import { constructCustomer } from "./constructCustomer";
import { constructProject } from "./constructProject";
import { fetchOrCreateCognitoUser } from "./fetchOrCreateCognitoUser";

const createProject = async ({ address, email, firstname, lastname, phone }) => {
  let cognitoId;
  let customerId = constructUuid();

  const cognitoResults = await fetchOrCreateCognitoUser({ email, phone, group: "customer", userId: customerId });
  cognitoId = cognitoResults.Username;

  const customer = {
    id: customerId,
    ...constructCustomer({ email, firstname, lastname, phone, owners: [cognitoId] }),
  };

  const project = {
    id: constructUuid(),
    ...constructProject({
      address,
      customerId: customer.id,
      email,
      viewers: [cognitoId],
      title: `${firstname} ${lastname}`,
    }),
  };

  // TODO: Update new customer cognito and set custom:userId attribute

  const customerKit = new ModelKit({ modelName: "Customer", filter: { email: { eq: customer.email } } });
  const data = await customerKit.scan();

  if ((data || []).length === 0) {
    await customerKit.create({ model: customer });
  } else {
    project.customerId = data[0].id;
  }

  const projectKit = new ModelKit({ modelName: "Project", model: project });
  await projectKit.create();

  return project.id;
};

export { createProject };
