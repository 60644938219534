import React from 'react';

const SingleBilling = ({
  color = 'green-main',
  size = 30,
  styleProps = '',
  ...rest
}) => (
  <svg
    width={size.toString()}
    height={size.toString()}
    viewBox="0 0 75 75"
    className={`fill-${color} ${styleProps}`}
    {...rest}
  >
    <path d="M74.9335 28.6994C74.924 28.6139 74.8956 28.5285 74.8671 28.443C74.8101 28.2152 74.7247 27.9968 74.6108 27.788C74.5538 27.693 74.5063 27.5981 74.4399 27.5127C74.3829 27.4082 74.3165 27.3228 74.2405 27.2373C74.174 27.1519 74.1076 27.076 74.0316 27L48.2089 0.977848H48.1994C47.8196 0.598101 47.3544 0.313291 46.8323 0.151899C46.7753 0.132911 46.7089 0.113924 46.6424 0.0949367C46.3861 0.0379747 46.1203 0 45.8449 0H13.2816C5.96203 0 0 5.96203 0 13.2816V61.7184C0 69.038 5.96203 75 13.2816 75H61.7184C69.038 75 75 69.038 75 61.7184V29.3449C75 29.1266 74.981 28.9082 74.9335 28.6994ZM49.1677 11.3829L63.7025 26.0222H50.6013C49.8133 26.0222 49.1677 25.3766 49.1677 24.5886V11.3829ZM68.3544 61.7184C68.3544 65.3734 65.3734 68.3544 61.7184 68.3544H13.2816C9.62658 68.3544 6.64557 65.3734 6.64557 61.7184V13.2816C6.64557 9.62658 9.62658 6.64557 13.2816 6.64557H42.5222V24.5886C42.5222 29.0411 46.1487 32.6677 50.6013 32.6677H68.3544V61.7184Z" />
    <path d="M25.3484 25.5665C25.3484 27.4557 24.1142 28.7184 21.9307 29.0791V30.6741C21.9307 30.9399 21.7218 31.1487 21.456 31.1487H19.5193C19.263 31.1487 19.0446 30.9399 19.0446 30.6741V29.0506C17.4497 28.7658 16.1111 27.9779 15.1522 26.7437C15.0098 26.5633 15.0288 26.307 15.1902 26.1361L16.3674 24.8829C16.4528 24.788 16.5952 24.7405 16.7092 24.731C16.8421 24.731 16.9655 24.788 17.0604 24.8829C18.0383 25.9462 19.1585 26.4399 20.6016 26.4399C21.7788 26.4399 22.3674 26.1361 22.3674 25.5475C22.3674 25.0253 22.225 24.6741 20.2123 24.2848C17.9244 23.8671 15.6839 23.231 15.6839 20.4779C15.6839 18.731 16.994 17.3924 19.0446 16.9747V15.5601C19.0446 15.2943 19.263 15.0854 19.5193 15.0854H21.456C21.7218 15.0854 21.9307 15.2943 21.9307 15.5601V17.0032C23.1933 17.269 24.2471 17.9051 25.1206 18.9589C25.282 19.1392 25.2725 19.4241 25.1016 19.5949L23.9054 20.7911C23.8104 20.8861 23.6775 20.8956 23.5636 20.9335C23.4402 20.9335 23.3168 20.8766 23.2313 20.7911C22.244 19.7848 21.5035 19.6044 20.4117 19.6044C19.8801 19.6044 18.6459 19.6804 18.6459 20.4399C18.6459 21.0095 18.9877 21.1709 20.9054 21.5506C23.2123 21.9873 25.3484 22.7848 25.3484 25.5665Z" />
    <path d="M58.8607 43.2436H16.1392C15.3513 43.2436 14.7152 42.6075 14.7152 41.8196C14.7152 41.0316 15.3513 40.3955 16.1392 40.3955H58.8607C59.6487 40.3955 60.2848 41.0316 60.2848 41.8196C60.2848 42.6075 59.6487 43.2436 58.8607 43.2436Z" />
    <path d="M58.8607 52.3481H16.1392C15.3513 52.3481 14.7152 51.712 14.7152 50.9241C14.7152 50.1361 15.3513 49.5 16.1392 49.5H58.8607C59.6487 49.5 60.2848 50.1361 60.2848 50.9241C60.2848 51.712 59.6487 52.3481 58.8607 52.3481Z" />
    <path d="M48.0095 61.1867H16.1392C15.3513 61.1867 14.7152 60.5506 14.7152 59.7627C14.7152 58.9747 15.3513 58.3386 16.1392 58.3386H48.0095C48.7975 58.3386 49.4335 58.9747 49.4335 59.7627C49.4335 60.5506 48.7975 61.1867 48.0095 61.1867Z" />
  </svg>
);

export { SingleBilling };
