import { add, duplicate, get, remove, set } from '@thrivelot/actions';

type Month =
  | 'January'
  | 'February'
  | 'March'
  | 'April'
  | 'May'
  | 'June'
  | 'July'
  | 'August'
  | 'September'
  | 'October'
  | 'November'
  | 'December';

const MONTHS: Month[] = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

const getStringAsArray = (model: { [key: string]: any }, path: string) => {
  const value: string | undefined = get(model, path);
  if (!value) return [];
  return value.split(',');
};

const getMonthsAsArray = (model: { [key: string]: any }, path: string) => {
  const value: string | undefined = get(model, path);
  if (!value) return [];

  const months: string[] = [];
  for (let i = 0; i < 12; i++) {
    if (value.charAt(i) === '1') months.push(MONTHS[i]);
  }
  return months;
};

const addToStringArray = (
  model: { [key: string]: any },
  path: string,
  value: string,
  index = -1
) => {
  const array = getStringAsArray(model, path);
  if (index !== -1 && index < array.length) array.splice(index, 0, value);
  else array.push(value);
  return array.join(',');
};

const convertMonthArrayToBinaryString = (array: Month[]) => {
  let result = '';
  for (let i = 0; i < 12; i++) {
    if (array.includes(MONTHS[i])) result += '1';
    else result += '0';
  }
  return result;
};

const addMonthToString = (
  model: { [key: string]: any },
  path: string,
  value: Month
) => {
  const array = getMonthsAsArray(model, path);
  array.push(value);
  let result = '';
  for (let i = 0; i < 12; i++) {
    if (array.includes(MONTHS[i])) result += '1';
    else result += '0';
  }
  return result;
};

const removeFromStringArray = (
  model: { [key: string]: any },
  path: string,
  value: string
) => {
  const array = getStringAsArray(model, path);
  const index = array.indexOf(value);
  if (index !== -1) array.splice(index, 1);
  return array.join(',');
};

const removeMonthFromString = (
  model: { [key: string]: any },
  path: string,
  value: Month
) => {
  const array = getMonthsAsArray(model, path);
  const index = array.indexOf(value);
  if (index !== -1) array.splice(index, 1);
  let result = '';
  for (let i = 0; i < 12; i++) {
    if (array.includes(MONTHS[i])) result += '1';
    else result += '0';
  }
  return result;
};

export function actions(model: { [key: string]: any }) {
  return {
    result: model,
    add(path: string, value: any, index = -1) {
      this.result = add(this.result, path, value, index);
      return this;
    },
    duplicate(path: string, newId?: string) {
      this.result = duplicate(this.result, path, newId);
      return this;
    },
    get(path: string, defaultValue?: any) {
      return get(this.result, path, defaultValue);
    },
    remove(path: string) {
      this.result = remove(this.result, path);
      return this;
    },
    set(path: string, value: any) {
      this.result = set(this.result, path, value);
      return this;
    },
    addToStringArray(path: string, value: string, index = -1) {
      this.result = set(
        this.result,
        path,
        addToStringArray(this.result, path, value, index)
      );
      return this;
    },
    addMonthToString(path: string, value: Month) {
      this.result = set(
        this.result,
        path,
        addMonthToString(this.result, path, value)
      );
      return this;
    },
    removeFromStringArray(path: string, value: string) {
      this.result = set(
        this.result,
        path,
        removeFromStringArray(this.result, path, value)
      );
      return this;
    },
    removeMonthFromString(path: string, value: Month) {
      this.result = set(
        this.result,
        path,
        removeMonthFromString(this.result, path, value)
      );
      return this;
    },
    getStringAsArray(path: string) {
      return getStringAsArray(this.result, path);
    },
    getMonthsAsArray(path: string) {
      return getMonthsAsArray(this.result, path);
    },
    getScientificName() {
      const genus = this.get('genus');
      const species = this.get('species');
      return `${genus || ''} ${species || ''}`;
    },
    convertMonthArrayToBinaryString(array: Month[]) {
      return convertMonthArrayToBinaryString(array);
    },
  };
}
