import styled, { css } from "styled-components";
import CssRaisedShadow from "./CssRaisedShadow";
import CssDivDefault from "./CssDivDefault";

const DivAddressSuggestionsBox = styled.div`
  ${CssDivDefault}
  min-height: 200px;
  max-height: 200px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  ${({ active }) =>
    active &&
    css`
      background-color: ${({ theme }) => theme.foreground};
      ${CssRaisedShadow}
    `}
`;

export default DivAddressSuggestionsBox;
