import React from 'react';

const ProgressDesign = ({ size = 30, classAppend = '', ...rest }) => (
  <svg
    width={`${size}px`}
    height={`${size}px`}
    viewBox="50 50 200 200"
    fill="none"
    className={classAppend}
    {...rest}
  >
    <circle cx="150.5" cy="150" r="94.5" stroke="#F5E8D3" strokeWidth="11" />
    <g opacity="0.3">
      <mask id="path-2-inside-1_167_1248" fill="white">
        <path d="M245.606 119.098C252.131 139.183 252.131 160.817 245.606 180.902C239.08 200.986 226.363 218.489 209.279 230.902L202.813 222.003C218.018 210.955 229.336 195.378 235.144 177.503C240.952 159.627 240.952 140.373 235.144 122.497L245.606 119.098Z" />
      </mask>
      <path
        d="M245.606 119.098C252.131 139.183 252.131 160.817 245.606 180.902C239.08 200.986 226.363 218.489 209.279 230.902L202.813 222.003C218.018 210.955 229.336 195.378 235.144 177.503C240.952 159.627 240.952 140.373 235.144 122.497L245.606 119.098Z"
        stroke="#00A272"
        strokeWidth="22"
        mask="url(#path-2-inside-1_167_1248)"
      />
      <mask id="path-3-inside-2_167_1248" fill="white">
        <path d="M150.5 50C171.618 50 192.194 56.6855 209.279 69.0983C226.363 81.5111 239.08 99.014 245.606 119.098L235.144 122.497C229.336 104.622 218.018 89.0449 202.813 77.9975C187.607 66.9501 169.295 61 150.5 61V50Z" />
      </mask>
      <path
        d="M150.5 50C171.618 50 192.194 56.6855 209.279 69.0983C226.363 81.5111 239.08 99.014 245.606 119.098L235.144 122.497C229.336 104.622 218.018 89.0449 202.813 77.9975C187.607 66.9501 169.295 61 150.5 61V50Z"
        stroke="#00A272"
        strokeWidth="22"
        mask="url(#path-3-inside-2_167_1248)"
      />
    </g>
    <mask id="path-4-inside-3_167_1248" fill="white">
      <path d="M209.279 230.902C192.194 243.314 171.618 250 150.5 250C129.382 250 108.806 243.314 91.7215 230.902L98.1871 222.003C113.393 233.05 131.705 239 150.5 239C169.295 239 187.607 233.05 202.813 222.003L209.279 230.902Z" />
    </mask>
    <path
      d="M209.279 230.902C192.194 243.314 171.618 250 150.5 250C129.382 250 108.806 243.314 91.7215 230.902L98.1871 222.003C113.393 233.05 131.705 239 150.5 239C169.295 239 187.607 233.05 202.813 222.003L209.279 230.902Z"
      stroke="#EA7211"
      strokeWidth="22"
      mask="url(#path-4-inside-3_167_1248)"
    />
    <path
      d="M136.702 124.709L137.702 126.253V118.81C135.892 120.19 135.424 122.759 136.702 124.709Z"
      fill="#EA7211"
    />
    <path
      d="M137.703 118.81V104.43C137.703 101.987 135.728 100 133.272 100H105.93C103.487 100 101.5 101.987 101.5 104.43V195.57C101.5 198.013 103.487 200 105.93 200H133.272C135.728 200 137.703 198.013 137.703 195.57V126.253L136.703 124.709C135.424 122.759 135.892 120.19 137.703 118.81ZM128.842 191.139H110.361V175.443H119.601C120.652 175.443 121.5 174.595 121.5 173.544C121.5 172.506 120.652 171.646 119.601 171.646H110.361V166.278H115.373C116.424 166.278 117.272 165.418 117.272 164.38C117.272 163.329 116.424 162.481 115.373 162.481H110.361V157.101H119.601C120.652 157.101 121.5 156.253 121.5 155.203C121.5 154.152 120.652 153.304 119.601 153.304H110.361V147.924H115.373C116.424 147.924 117.272 147.076 117.272 146.025C117.272 144.975 116.424 144.127 115.373 144.127H110.361V138.747H119.601C120.652 138.747 121.5 137.899 121.5 136.848C121.5 135.81 120.652 134.949 119.601 134.949H110.361V129.582H115.373C116.424 129.582 117.272 128.734 117.272 127.684C117.272 126.633 116.424 125.785 115.373 125.785H110.361V120.405H119.601C120.652 120.405 121.5 119.557 121.5 118.506C121.5 117.456 120.652 116.608 119.601 116.608H110.361V108.861H128.842V191.139Z"
      fill="#EA7211"
    />
    <path
      d="M122.904 187.81C124.156 187.81 125.17 186.796 125.17 185.544C125.17 184.293 124.156 183.278 122.904 183.278C121.653 183.278 120.638 184.293 120.638 185.544C120.638 186.796 121.653 187.81 122.904 187.81Z"
      fill="#EA7211"
    />
    <path
      d="M136.702 124.709L137.702 126.253V118.81C135.892 120.19 135.424 122.759 136.702 124.709Z"
      fill="#EA7211"
    />
    <path
      d="M200.196 194.215L197.171 176.911C197.069 176.329 196.841 175.772 196.525 175.266L155.677 112.38C154.348 110.329 151.601 109.747 149.55 111.076L138.006 118.582C137.905 118.645 137.791 118.721 137.702 118.81V126.253L177.538 187.595C177.867 188.089 178.284 188.519 178.778 188.861L193.36 198.658C194.107 199.152 194.968 199.405 195.829 199.405C196.664 199.405 197.5 199.164 198.246 198.696C199.727 197.721 200.5 195.962 200.196 194.215ZM150.664 120.924L155.31 128.076L151.183 130.747L146.538 123.595L150.664 120.924ZM184.474 182.013L153.246 133.937L157.373 131.253L188.601 179.329L189.677 185.506L184.474 182.013Z"
      fill="#EA7211"
    />
  </svg>
);

export { ProgressDesign };
