import React, { useMemo } from 'react';
import { useModal, usePlant } from '@thrivelot/hooks';
import { transformStringToTitleCase } from '@thrivelot/utils';
import { LayoutModal } from '../../LayoutModal';
import { ItemSection } from './ItemSection';
import { PlantImage } from './PlantImage';
import { MonthSection } from './MonthSection';

export const ModalViewPlant = ({ modelId, onClose }) => {
  const { model: plant, loading, actions } = usePlant({ id: modelId });
  const { openModal } = useModal();

  const image = useMemo(() => plant?.images?.[0], [plant?.images]);

  const growingMonths = actions.getMonthsAsArray('growingMonths');
  const bloomMonths = actions.getMonthsAsArray('bloomMonths');
  const fruitingMonths = actions.getMonthsAsArray('fruitingMonths');

  const scientificName = `${plant?.genus || ''} ${plant?.species || ''}`;
  const name = plant?.commonName || scientificName;

  return (
    <LayoutModal
      onClose={onClose}
      loading={loading}
      header={<div className="text-lg text-brown-dark italic">View Plant</div>}
      width="xl"
      dialogCloseDisabled
    >
      <div
        className="flex relative w-full max-h-80 rounded overflow-hidden items-center justify-center"
        onClick={() =>
          openModal({
            path: 'ModalViewPlantImages',
            modelId,
            currentImageId: image.id,
            fromViewPlantModal: true,
          })
        }
      >
        <PlantImage plantId={modelId} image={image} />
      </div>
      <div className="flex items-center pt-4">
        <div className="text-lg font-header text-mulch-dark">{name}</div>
        {name !== scientificName && (
          <div className="text-mulch-dark italic ml-4">{scientificName}</div>
        )}
      </div>
      {plant?.growingDescription && (
        <div className="text-mulch-dark pt-2">
          {plant?.growingDescription || 'No description available.'}
        </div>
      )}
      {plant?.form ||
      plant?.habit ||
      plant?.edibleParts ||
      plant?.foliageTexture ||
      plant?.foliageColors ||
      plant?.flowerColors ||
      plant?.fruitOrSeedColors ? (
        <div className="flex items-center pt-8 pb-2">
          <div className="text-lg font-header text-mulch-dark">
            Characteristics
          </div>
        </div>
      ) : null}
      <div className="flex flex-wrap gap-5">
        {plant?.form && (
          <ItemSection
            title="Form"
            content={[{ text: transformStringToTitleCase(plant.form) }]}
          />
        )}
        {plant?.habit && (
          <ItemSection
            title="Habits"
            content={plant.habit
              .split(',')
              .map((habit) => ({ text: transformStringToTitleCase(habit) }))}
          />
        )}
        {plant?.edibleParts && (
          <ItemSection
            title="Edible Parts"
            content={plant.edibleParts
              .split(',')
              .map((part) => ({ text: transformStringToTitleCase(part) }))}
          />
        )}
        {plant?.foliageTexture && (
          <ItemSection
            title="Foliage Texture"
            content={plant.foliageTexture.split(',').map((texture) => ({
              text: transformStringToTitleCase(texture),
            }))}
          />
        )}
        {plant?.foliageColors && (
          <ItemSection
            title="Foliage Colors"
            content={plant.foliageColors.split(',').map((color) => ({
              bgColor: color,
              text: transformStringToTitleCase(color),
            }))}
          />
        )}
        {plant?.flowerColors && (
          <ItemSection
            title="Flower Colors"
            content={plant.flowerColors.split(',').map((color) => ({
              bgColor: color,
              text: transformStringToTitleCase(color),
            }))}
          />
        )}
        {plant?.fruitOrSeedColors && (
          <ItemSection
            title="Fruit / Seed Colors"
            content={plant.fruitOrSeedColors.split(',').map((color) => ({
              bgColor: color,
              text: transformStringToTitleCase(color),
            }))}
          />
        )}
      </div>
      {growingMonths.length || bloomMonths.length || fruitingMonths.length ? (
        <div className="flex items-center pt-8 pb-2">
          <div className="text-lg font-header text-mulch-dark">Growth</div>
        </div>
      ) : null}
      <div className="flex flex-wrap gap-5">
        {growingMonths.length ? (
          <MonthSection title="Growing Months" content={growingMonths} />
        ) : null}
        {bloomMonths.length ? (
          <MonthSection title="Bloom Months" content={bloomMonths} />
        ) : null}
        {fruitingMonths.length ? (
          <MonthSection title="Fruiting Months" content={fruitingMonths} />
        ) : null}
      </div>
    </LayoutModal>
  );
};
