import React, { useEffect } from "react";

const timeout = 180000; // 3 min
const versionUrl = "/package-version.json";

const EnsureVersion = () => {
  useEffect(() => {
    if (/thrivelot.com/.test(window.location.hostname)) {
      const intervalId = setInterval(() => {
        fetch(versionUrl, { cache: "no-store" })
          .then((response) => response.json())
          .then((data) => {
            if (data.version !== window.appConfig.version) {
              window.location.reload(true);
            }
          });
      }, timeout);
      return () => clearInterval(intervalId);
    }
    return () => {};
  }, []);

  return <></>;
};

export { EnsureVersion };
