import React, { useEffect, useMemo, useState } from 'react';
import { Document, Outline, Page } from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import { useFiles, useMeasure, useModel } from '@thrivelot/hooks';
import { AngleDown, Dropdown, Pdf, Info, Button } from '@thrivelot/stories';
import { PdfLayout } from './PdfLayout';

export const ModalViewPdf = ({
  onClose,
  modelName,
  modelId,
  filePath,
  fileId,
}) => {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [signedUrl, setSignedUrl] = useState(null);
  const { actions, loaded } = useModel({ modelName, id: modelId });
  const { fetchUrl } = useFiles();
  const [ref, { width }] = useMeasure();

  const file = useMemo(
    () => loaded && actions.get(`${filePath}[id:${fileId}]`),
    [loaded, actions, filePath, fileId]
  );

  const pageOptions = useMemo(() => {
    const arr = numPages ? Array.from(Array(numPages).keys()) : [];
    return arr.map((num) => ({
      label: num + 1,
      onClick: () => setPageNumber(num + 1),
    }));
  }, [numPages]);

  useEffect(() => {
    const fetchSignedUrl = async () => {
      const { data } = await fetchUrl(file?.file?.key);
      setSignedUrl(data);
    };

    if (file?.file?.key) fetchSignedUrl();
  }, [fetchUrl, file?.file?.key]);

  const onDocumentLoadSuccess = ({ numPages: _numPages }) => {
    if (_numPages) setNumPages(_numPages);
  };

  const openPdfInBrowser = () => {
    const newWindow = window.open();
    newWindow.document.title = 'Thrive Lot | View PDF';
    newWindow.document.write(
      `<iframe src="${signedUrl}" frameborder="0" style="border:0; top:0px; left:0px; bottom:0px; right:0px; width:100%; height:100%;" allowfullscreen></iframe>`
    );
  };

  return (
    <PdfLayout
      onClose={onClose}
      header={
        loaded && (
          <div className="text-lg text-brown-dark font-bold text-center">
            {file?.file?.filename}
          </div>
        )
      }
      loading={!loaded || !signedUrl}
      subheader={
        loaded && signedUrl && numPages ? (
          <div className="flex flex-1 items-center justify-between">
            <Dropdown
              dropdownClassAppend="!z-100"
              label={
                <div className="flex items-center justify-center py-2 px-4 border-2 rounded-main border-tan-light">
                  <div className="text-brown-dark font-bold text-lg mr-2">
                    {pageNumber}
                  </div>
                  <div className="text-brown-dark text-lg mr-2">of</div>
                  <div className="text-brown-dark font-bold text-lg mr-2">
                    {numPages}
                  </div>
                  <AngleDown size={16} />
                </div>
              }
              optionClass="font-bold"
              align="left"
              options={pageOptions}
            />
            <Button
              label="Download"
              icon="Download"
              kind="outline"
              color="blue-main"
              onClick={openPdfInBrowser}
            />
          </div>
        ) : (
          <div className="h-10" />
        )
      }
    >
      {loaded && signedUrl && (
        <div ref={ref} className="flex flex-1 items-center justify-center">
          <Document
            file={signedUrl}
            loading={
              <Pdf size={90} color="brown-light" styleProps="animate-pulse" />
            }
            noData={<Info size={90} color="yellow-main" />}
            error={<Info size={90} color="red-main" />}
            onLoadSuccess={onDocumentLoadSuccess}
          >
            <Page width={width} pageNumber={pageNumber} />
          </Document>
        </div>
      )}
      {file?.caption && <div className="mt-4">{file?.caption}</div>}
    </PdfLayout>
  );
};
