import React from "react";
import { useTheme } from "@thrivelot/hooks";
import { DivColumn, DivLineHorizontal, TextBody, TextHeader, TextSmallHeader } from "@thrivelot/styles";
import { useInnerSpacing } from "../../../../hooks";
import { NoteContainer } from "./NoteContainer";

const Note = ({ note }) => {
  const theme = useTheme();
  const innerSpacing = useInnerSpacing();
  const negativeMargin = `-${innerSpacing}`;
  // utils
  const hasObservations = () => note.observations && note.observations.trim() !== "";
  const hasRecommendations = () => note.recommendations && note.recommendations.trim() !== "";
  return (
    <NoteContainer style={{ height: "fit-content", overflow: "hidden" }}>
      <TextHeader style={{ color: theme.textTitle }}>{note.title}</TextHeader>
      <DivLineHorizontal
        style={{
          margin: `${innerSpacing} ${negativeMargin}`,
          width: `calc(100% + ${innerSpacing} + ${innerSpacing})`,
        }}
      />
      <DivColumn>
        {hasObservations() && (
          <>
            <TextSmallHeader style={{ color: theme.textLabel }}>Observations</TextSmallHeader>
            <TextBody style={{ color: theme.textTitle }}>{note.observations}</TextBody>
          </>
        )}
        {hasRecommendations() && (
          <>
            <TextSmallHeader
              style={{
                marginTop: hasObservations() && innerSpacing,
                color: theme.textLabel,
              }}
            >
              Recommendations
            </TextSmallHeader>
            <TextBody style={{ color: theme.textTitle }}>{note.recommendations}</TextBody>
          </>
        )}
      </DivColumn>
    </NoteContainer>
  );
};

export { Note };
