import { useMemo } from 'react';

const useSortedItems = ({ items }) => {
  const sortedItems = useMemo(() => {
    const groupedItems = items
      .filter((i) => !i.hidden)
      .reduce((groups, item) => {
        groups[item.kind] = groups[item.kind] || [];
        groups[item.kind].push(item);
        return groups;
      }, {});

    return [
      ...(groupedItems?.material?.sort(
        (a, b) => b.totalAmount - a.totalAmount
      ) || []),
      ...(groupedItems?.equipment?.sort(
        (a, b) => b.totalAmount - a.totalAmount
      ) || []),
      ...(groupedItems?.labor?.sort((a, b) => b.totalAmount - a.totalAmount) ||
        []),
      ...(groupedItems?.plant?.sort((a, b) => b.totalAmount - a.totalAmount) ||
        []),
    ];
  }, [items]);

  return { sortedItems };
};

export { useSortedItems };
