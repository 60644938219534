import React from "react";
import { useProposal } from "@thrivelot/common";
import { useSpacing } from "../../../hooks";
import { formatPlants } from "./helpers";
import { PlantPaletteContainer } from "./PlantPaletteContainer";
import { Plant } from "./Plant";

const PlantPalette = () => {
  // hooks
  const { projectActions } = useProposal();
  const spacing = useSpacing();
  // actions
  const plants = projectActions.get("plantItems");
  // return
  return (
    <PlantPaletteContainer style={{ marginTop: `calc(${spacing} - 4px)` }}>
      {formatPlants(plants).map((plant) => (
        <Plant key={plant.id} plant={plant} />
      ))}
    </PlantPaletteContainer>
  );
};

export { PlantPalette };
