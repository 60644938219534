import * as tailwindColors from "@thrivelot/tailwind/colors";

const colors = {
  lightBlue: tailwindColors.blue.light,
  blue: tailwindColors.blue.main,
  lightBrown: tailwindColors.tan.dark,
  brown: tailwindColors.brown.light,
  darkBrownFaded: tailwindColors.brown.dark,
  darkBrown: tailwindColors.brown.dark,
  gold: tailwindColors.yellow.dark,
  gray: "#757575",
  lightGreen: tailwindColors.green.light,
  green: tailwindColors.green.main,
  darkGreen: tailwindColors.green.dark,
  lightOrange: tailwindColors.orange.light,
  orange: tailwindColors.orange.main,
  darkOrange: tailwindColors.orange.dark,
  lightPurple: tailwindColors.purple.light,
  purple: tailwindColors.purple.main,
  lightPink: tailwindColors.pink.light,
  pink: tailwindColors.pink.main,
  lightRed: tailwindColors.red.light,
  red: tailwindColors.red.main,
  lightShadow1: "rgba(245, 232, 211, 0.2)",
  lightShadow2: "rgba(133, 115, 109, 0.25)",
  darkShadow1: "rgba(0, 0, 0, 0.15)",
  darkShadow2: "rgba(0, 0, 0, 0.25)",
  lightButtonShadow: "rgba(0, 0, 0, 0.25)",
  darkButtonShadow: "rgba(0, 0, 0, 0.25)",
  tan: tailwindColors.tan.light,
  white: tailwindColors.white.dark,
  lightYellow: tailwindColors.yellow.light,
  yellow: tailwindColors.yellow.main,
};

export { colors };
