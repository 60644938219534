import { cleanObject, isEqual } from '@thrivelot/utils';

export const constructUnsavedUpdates = (
  updates: { [key: string]: any },
  model: { [key: string]: any }
) => {
  if (!updates?.id) return updates;

  const newUpdates = {};
  const keys = Object.keys(updates);

  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < keys.length; i++) {
    const key = keys[i];

    const updating = cleanObject(updates[key], ['__typename']);
    const existing = cleanObject(model?.[key] || {}, ['__typename']);

    if (
      updating !== null &&
      updating !== undefined &&
      !isEqual(updating, existing)
    )
      newUpdates[key] = updating;
    else if (updating === undefined) newUpdates[key] = null;
  }

  return newUpdates;
};
