/* eslint-disable camelcase */
import { fetchService } from "./fetchService";

const createCognitoUser = async ({ email, phoneNumber, userId, groupname, confirm }) => {
  try {
    return await fetchService({
      method: "post",
      level: "admin",
      path: "create-user",
      variables: { email, phoneNumber, userId, groupname, confirm },
    });
  } catch (err) {
    console.error("Error: ", err);
    throw err;
  }
};

export { createCognitoUser };
