import { formatLongDate } from "@thrivelot/date";
import { DateDone, DateStart, Phases } from "@thrivelot/stories";
import React from "react";

const ViewPhase = ({ phase, kind }) => {
  const { name, dates, description } = phase;

  return (
    <div className="w-full">
      <div className="px-8 py-4 text-3xl font-bold flex items-center">
        <Phases color="brown-dark" size={24} styleProps="mr-3" />
        {name}
      </div>
      <div className="p-8 ">
        <div className="flex justify-between items-center gap-4 mb-4">
          <div className="flex items-center gap-2 hover:cursor-pointer">
            {kind === "date" ? <DateDone color="brown-dark" size={16} /> : <DateStart color="brown-dark" size={16} />}
            {(dates || [])[0] ? <div>{formatLongDate(dates[0])}</div> : <div className="italic">Select a date...</div>}
          </div>
          {kind !== "date" && (dates || [])?.[1] && <div className="flex-1 border-b-2 border-brown-dark" />}
          {kind !== "date" && (dates || [])?.[1] && (
            <div className="flex items-center gap-2 hover:cursor-pointer">
              <DateDone color="brown-dark" size={16} />
              {(dates || [])[1] ? (
                <div>{formatLongDate(dates[1])}</div>
              ) : (
                <div className="italic">Select a date...</div>
              )}
            </div>
          )}
        </div>
        <div className="whitespace-pre-line">{description}</div>
      </div>
    </div>
  );
};

export { ViewPhase };
