import { useProposal } from "@thrivelot/common";
import { sectionHeaders } from "./sectionHeaders";

const useSectionHeaders = () => {
  const { project, type } = useProposal();

  return sectionHeaders({ type, project });
};

export { useSectionHeaders };
