import { constructUuid, duplicateObject, isEmpty } from '@thrivelot/utils';
import { client } from './client';
import { constructActions } from './constructActions';
import { constructUnsavedUpdates } from './constructUnsavedUpdates';
import { request } from './request';
import { scan } from './scan';
import { trimOutTypename } from './trimOutTypename';

interface ModelKitProps {
  modelName?: string;
  id?: string;
  filter?: { [key: string]: any };
  updated?: { [key: string]: any };
  model?: { [key: string]: any };
  resolveItems?: (items: any[]) => any[];
  modelConfig?: { [key: string]: any };
  endpoint?: string;
  apiKey?: string;
  fetchJwt?: () => Promise<string>;
  queryNameRegex?: RegExp;
}

class ModelKit {
  #modelName;

  #id;

  #filter;

  #updated;

  #model;

  #resolveItems;

  #actions;

  constructor(props?: ModelKitProps) {
    this.#init(props);
  }

  #init(props?: ModelKitProps) {
    if (props?.modelName) this.modelName = props.modelName;
    if (props?.id) this.id = props.id;
    if (props?.filter) this.filter = props.filter;
    if (props?.updated) this.updated = props.updated;
    if (props?.model) this.model = props.model;
    if (props?.resolveItems) this.resolveItems = props.resolveItems;
    if (props?.modelConfig) client.setModelConfig(props.modelConfig);
    if (props?.endpoint) client.setEndpoint(props.endpoint);
    if (props?.apiKey) client.setApiKey(props.apiKey);
    if (props?.fetchJwt) client.setFetchJwt(props.fetchJwt);
    if (props?.queryNameRegex) client.setQueryNameRegex(props.queryNameRegex);
  }

  #setActions() {
    const actionsFactory = constructActions(this.modelName);
    this.#actions = actionsFactory ? actionsFactory(this.model) : {};
  }

  set modelName(modelName) {
    this.#modelName = modelName;
  }

  get modelName() {
    return this.#modelName;
  }

  set id(id) {
    this.#id = id;
  }

  get id() {
    return this.#id;
  }

  set filter(filter) {
    this.#filter = filter;
  }

  get filter() {
    return this.#filter;
  }

  set updated(updated) {
    this.#updated = updated;
  }

  get updated() {
    return this.#updated;
  }

  set model(model) {
    this.#model = model;
    if (this.#model.id) this.id = this.#model.id;

    this.#setActions();
  }

  get model() {
    return this.#model;
  }

  set resolveItems(resolveItems) {
    this.#resolveItems = resolveItems;
  }

  get resolveItems() {
    return this.#resolveItems;
  }

  get actions() {
    return this.#actions || {};
  }

  setValue(name: string, value: any) {
    const model = duplicateObject(this.model);
    model[name] = value;
    this.model = model;
  }

  async query(props?: ModelKitProps) {
    this.#init(props);

    try {
      const data = await request({
        modelName: this.modelName,
        variables: { id: this.id },
        queryType: 'read',
      });

      const trimmedData = trimOutTypename(data);

      this.model = trimmedData;

      return trimmedData;
    } catch (err) {
      console.error('Error in ModelKit query:', JSON.stringify(err, null, 2));
      throw err;
    }
  }

  async scan(props?: ModelKitProps) {
    this.#init(props);

    try {
      const items = await scan({
        modelName: this.modelName,
        variables: { filter: this.filter },
        resolveItems: this.resolveItems,
      });
      return trimOutTypename(items);
    } catch (err) {
      console.error('Error in ModelKit scan:', JSON.stringify(err, null, 2));
      throw err;
    }
  }

  async search(props?: ModelKitProps) {
    this.#init(props);

    try {
      const items = await scan({
        modelName: this.modelName,
        variables: { filter: this.filter },
        resolveItems: this.resolveItems,
        queryType: 'search',
      });
      return items;
    } catch (err) {
      console.error('Error in ModelKit search:', JSON.stringify(err, null, 2));
      throw err;
    }
  }

  async update(props?: ModelKitProps) {
    this.#init(props);

    if (isEmpty(this.updated))
      return console.log(
        `${this.modelName} (${this.id}) can't be updated because updated variable is empty`
      );

    try {
      const model = await this.query();

      const unsavedUpdates = constructUnsavedUpdates(this.updated, model);

      if (isEmpty(unsavedUpdates))
        return console.log(`${this.modelName} (${this.id}) already up to date`);

      const data = await request({
        modelName: this.modelName,
        variables: { input: { ...unsavedUpdates, id: this.id } },
        queryType: 'update',
      });

      this.model = data;

      return data;
    } catch (err) {
      console.error('Error in ModelKit update:', JSON.stringify(err, null, 2));
      throw err;
    }
  }

  async remove(props?: ModelKitProps) {
    this.#init(props);

    try {
      const data = await request({
        modelName: this.modelName,
        variables: { input: { id: this.id, deleted: true } },
        queryType: 'update',
      });

      this.model = data;

      return data;
    } catch (err) {
      console.error('Error in ModelKit remove:', JSON.stringify(err, null, 2));
      throw err;
    }
  }

  async create(props?: ModelKitProps) {
    this.#init(props);

    if (!this.model.id) {
      if (this.id) this.model = { ...this.model, id: this.id };
      else this.model = { ...this.model, id: constructUuid() };
    }

    try {
      const data = await request({
        modelName: this.modelName,
        variables: { input: this.model },
        queryType: 'create',
      });

      this.model = data;

      return data;
    } catch (err) {
      console.error('Error in ModelKit create:', JSON.stringify(err, null, 2));
      throw err;
    }
  }
}

export { ModelKit };
