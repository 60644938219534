import React, { useMemo } from 'react';
import { useModel } from '@thrivelot/hooks';
import { RowList, RowItemLoader } from '@thrivelot/stories';
import { RowItemFeature } from '..';

const ArealessFeatures = ({ projectId }) => {
  const { model: project, loaded } = useModel({
    modelName: 'Project',
    id: projectId,
  });

  const assignedMap = useMemo(() => {
    const map = {};
    project.zones.forEach(({ orderedChildTagIds }) => {
      (orderedChildTagIds || []).forEach(({ tagId }) => {
        map[tagId] = true;
      });
    });
    return map;
  }, [project?.zones]);

  const features = useMemo(
    () =>
      project.features
        .filter(({ id }) => !assignedMap[id])
        .filter((f) => !f.hidden),
    [project?.features, assignedMap]
  );

  if (!loaded) return <RowItemLoader />;

  return (
    <div>
      <div className="text-brown-dark text-xl mb-3 italic">
        These features are not assigned to an area yet...
      </div>
      <RowList>
        {features.map(({ id }) => (
          <RowItemFeature key={id} featureId={id} projectId={projectId} />
        ))}
      </RowList>
    </div>
  );
};

export { ArealessFeatures };
