const projectDesires = {
  foodAndPlants: {
    name: "Just a little food and/or beneficial plants in my yard",
    imageUrl: "https://images.typeform.com/images/PN9dUfTZrB6e/image/default",
  },
  feedMyselfFamily: {
    name: "Feed myself/my family",
    imageUrl: "https://images.typeform.com/images/9JXjii8RvHiU/image/default",
  },
  maximizeValue: {
    name: "Maximize value from my lot",
    imageUrl: "https://images.typeform.com/images/PUmV4TqjF7LQ/image/default",
  },
  notSure: {
    name: "I'm not sure yet. I want to talk to someone",
    imageUrl: "https://images.typeform.com/images/VZYsZn7awrYE/image/default",
  },
};

export { projectDesires };
