import { ProjectLineItemType } from "@thrivelot/aws";

const calculateTotalLaborCost = (lineItems) => {
  let total = 0;

  lineItems.forEach((item) => {
    if (item.type === ProjectLineItemType.LABOR) total += item.cost * item.quantity;
  });

  return total;
};

export { calculateTotalLaborCost };
