import React from 'react';

const Equipment = ({
  color = 'green-main',
  size = 30,
  styleProps = '',
  ...rest
}) => (
  <svg
    width={size.toString()}
    height={size.toString()}
    viewBox="0 0 75 75"
    className={`fill-${color} ${styleProps}`}
    {...rest}
  >
    <path d="M71.3941 48.637L55.8621 44.1843L52.5108 37.4152C51.7892 35.9341 50.5645 34.8043 49.055 34.2062L44.5739 19.3578C43.2447 14.9621 39.2668 12 34.6717 12H23.9721C21.4752 12 19.2062 13.576 18.3137 15.902L15.6744 22.861L14.4307 22.4433C13.6712 22.187 12.8737 22.054 12.0762 22.054H4.92733C2.21208 22.054 0 24.2661 0 26.9814V51.9598C0 54.7605 2.27854 57.0296 5.06974 57.0296H7.01599C8.57299 60.8366 12.3136 63.5234 16.6713 63.5234C21.029 63.5234 24.7696 60.8366 26.3266 57.0296H30.352C31.909 60.8366 35.6496 63.5234 40.0073 63.5234C44.365 63.5234 48.1056 60.8366 49.6626 57.0296H51.9696V57.8935C51.9696 59.3366 52.6057 60.7132 53.6975 61.6626C54.6089 62.4601 55.7767 62.8873 56.9634 62.8873C57.2007 62.8873 57.4381 62.8683 57.666 62.8398L70.3403 61.055C72.4859 60.7512 74.2138 59.0328 74.5366 56.8872L74.9448 54.1624C75.3151 51.7035 73.7866 49.3205 71.3941 48.637ZM24.3803 18.6457H34.6717C36.3142 18.6457 37.7383 19.709 38.2129 21.2755L41.3175 31.5574L21.9689 24.9971L24.3803 18.6457ZM16.6713 60.6752C12.494 60.6752 9.09516 57.2764 9.09516 53.0991C9.09516 48.9218 12.494 45.523 16.6713 45.523C20.8486 45.523 24.2474 48.9218 24.2474 53.0991C24.2474 57.2764 20.8486 60.6752 16.6713 60.6752ZM40.0073 60.6752C35.83 60.6752 32.4312 57.2764 32.4312 53.0991C32.4312 48.9218 35.83 45.523 40.0073 45.523C44.1846 45.523 47.5834 48.9218 47.5834 53.0991C47.5834 57.2764 44.1846 60.6752 40.0073 60.6752ZM51.9696 52.2826H50.3936C49.9854 46.9091 45.4853 42.6748 40.0073 42.6748C34.5388 42.6748 30.0387 46.9091 29.621 52.2826H27.0576C26.6494 46.9091 22.1493 42.6748 16.6713 42.6748C11.2028 42.6748 6.70269 46.9091 6.28496 52.2826H5.06974C4.88936 52.2826 4.74695 52.1402 4.74695 51.9598V26.9814C4.74695 26.8769 4.8229 26.801 4.92733 26.801H12.0762C12.3516 26.801 12.6364 26.8485 12.9022 26.9339L47.1372 38.5355C47.6309 38.7063 48.0296 39.0576 48.2575 39.5228L51.9696 47.0705V52.2826ZM58.6153 55.9947V51.8934L68.1567 54.6276L58.6153 55.9947Z" />
    <path d="M35.821 41.3836H15.504C13.7477 41.3836 12.3141 39.9595 12.3141 38.1937V32.9246C12.3141 31.8897 12.8173 30.9024 13.6527 30.2948C14.4977 29.6871 15.58 29.5163 16.5674 29.8391L36.609 36.3898C37.8337 36.7886 38.5457 37.9563 38.3464 39.2285C38.147 40.5007 37.1121 41.3836 35.821 41.3836ZM15.561 32.5258C15.4566 32.5258 15.3711 32.5733 15.3237 32.6018C15.2572 32.6492 15.1623 32.7442 15.1623 32.9246V38.1937C15.1623 38.3836 15.3142 38.5355 15.504 38.5355H34.0266L15.6939 32.5448C15.6464 32.5258 15.599 32.5258 15.561 32.5258Z" />
    <path d="M16.6717 58.7955C13.5292 58.7955 10.9753 56.2417 10.9753 53.0992C10.9753 49.9567 13.5292 47.4028 16.6717 47.4028C19.8142 47.4028 22.368 49.9567 22.368 53.0992C22.368 56.2417 19.8142 58.7955 16.6717 58.7955ZM16.6717 50.251C15.1052 50.251 13.8235 51.5327 13.8235 53.0992C13.8235 54.6657 15.1052 55.9473 16.6717 55.9473C18.2382 55.9473 19.5199 54.6657 19.5199 53.0992C19.5199 51.5327 18.2477 50.251 16.6717 50.251Z" />
    <path d="M40.0077 58.7955C36.8653 58.7955 34.3114 56.2417 34.3114 53.0992C34.3114 49.9567 36.8653 47.4028 40.0077 47.4028C43.1502 47.4028 45.7041 49.9567 45.7041 53.0992C45.7041 56.2417 43.1502 58.7955 40.0077 58.7955ZM40.0077 50.251C38.4412 50.251 37.1596 51.5327 37.1596 53.0992C37.1596 54.6657 38.4412 55.9473 40.0077 55.9473C41.5742 55.9473 42.8559 54.6657 42.8559 53.0992C42.8559 51.5327 41.5837 50.251 40.0077 50.251Z" />
  </svg>
);

export { Equipment };
