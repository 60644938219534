import React from 'react';

const Plan = ({
  color = 'green-main',
  size = 30,
  styleProps = '',
  ...rest
}) => (
  <svg
    width={size.toString()}
    height={size.toString()}
    viewBox="0 0 75 75"
    className={`fill-${color} ${styleProps}`}
    {...rest}
  >
    <path d="M74.9335 28.6994C74.924 28.6139 74.8956 28.5285 74.8671 28.443C74.8101 28.2152 74.7247 27.9968 74.6108 27.788C74.5538 27.693 74.5063 27.5981 74.4399 27.5127C74.3829 27.4082 74.3165 27.3228 74.2405 27.2373C74.174 27.1519 74.1076 27.076 74.0316 27L48.2089 0.977848H48.1994C47.8196 0.598101 47.3544 0.313291 46.8323 0.151899C46.7753 0.132911 46.7089 0.113924 46.6424 0.0949367C46.3861 0.0379747 46.1203 0 45.8449 0H13.2816C5.96203 0 0 5.96203 0 13.2816V61.7184C0 69.038 5.96203 75 13.2816 75H61.7184C69.038 75 75 69.038 75 61.7184V29.3449C75 29.1266 74.981 28.9082 74.9335 28.6994ZM49.1677 11.3829L63.7025 26.0222H50.6013C49.8133 26.0222 49.1677 25.3766 49.1677 24.5886V11.3829ZM68.3544 61.7184C68.3544 65.3734 65.3734 68.3544 61.7184 68.3544H13.2816C9.62658 68.3544 6.64557 65.3734 6.64557 61.7184V13.2816C6.64557 9.62658 9.62658 6.64557 13.2816 6.64557H42.5222V24.5886C42.5222 29.0411 46.1487 32.6677 50.6013 32.6677H68.3544V61.7184Z" />
    <path d="M23.3728 33.2944C17.876 33.2944 13.4044 28.8229 13.4044 23.326C13.4044 17.8292 17.876 13.3577 23.3728 13.3577C28.8696 13.3577 33.3412 17.8292 33.3412 23.326C33.3412 28.8229 28.8696 33.2944 23.3728 33.2944ZM23.3728 16.2058C19.4424 16.2058 16.2526 19.3956 16.2526 23.326C16.2526 27.2564 19.4424 30.4463 23.3728 30.4463C27.3032 30.4463 30.4931 27.2564 30.4931 23.326C30.4931 19.3956 27.2937 16.2058 23.3728 16.2058Z" />
    <path d="M30.4274 57.4462C30.9781 58.0063 30.9781 58.9082 30.4274 59.4588C30.1426 59.7341 29.7819 59.8766 29.4116 59.8766C29.0509 59.8766 28.6901 59.7341 28.4053 59.4588L23.3736 54.4272L18.342 59.4588C18.0572 59.7341 17.6964 59.8766 17.3357 59.8766C16.9654 59.8766 16.6047 59.7341 16.3293 59.4588C15.7692 58.9082 15.7692 58.0063 16.3293 57.4462L21.361 52.4145L16.3293 47.3734C15.7692 46.8228 15.7692 45.9209 16.3293 45.3607C16.88 44.8101 17.7819 44.8101 18.342 45.3607L23.3736 50.3924L28.4053 45.3607C28.9654 44.8101 29.8673 44.8101 30.4274 45.3607C30.9781 45.9209 30.9781 46.8228 30.4274 47.3734L25.3958 52.4145L30.4274 57.4462Z" />
    <path d="M53.316 38.2025C53.354 38.1835 53.3825 38.174 53.4205 38.1646C53.411 38.1646 53.4015 38.1646 53.3825 38.1835C53.3445 38.193 53.3065 38.2025 53.2686 38.231C53.2875 38.212 53.3065 38.2025 53.316 38.2025Z" />
    <path d="M59.4965 44.5253C59.2212 44.8386 58.8225 45 58.4332 45C58.1009 45 57.7592 44.8766 57.4839 44.6393L55.3383 42.731C55.1104 45.019 54.2845 48.0285 51.8446 50.6298C49.2908 53.345 45.7307 54.8165 42.0187 54.8165C40.794 54.8165 39.5598 54.6646 38.3351 54.3323C37.5756 54.1329 37.1294 53.345 37.3288 52.5855C37.5377 51.826 38.3161 51.3893 39.0756 51.5791C43.006 52.6329 47.0978 51.5222 49.7655 48.6741C51.6927 46.6329 52.3383 44.2405 52.5092 42.4272L49.8794 44.5443C49.6136 44.7532 49.3003 44.8576 48.987 44.8576C48.5693 44.8576 48.1611 44.6772 47.8763 44.326C47.3826 43.7089 47.487 42.8165 48.0946 42.3228L52.9649 38.4209C52.9744 38.4114 52.9839 38.4019 52.9839 38.4019C52.9933 38.3924 52.9934 38.3924 53.0028 38.3924C53.0313 38.3639 53.0598 38.3355 53.0978 38.326C53.1073 38.307 53.1168 38.307 53.1263 38.307C53.1452 38.288 53.1642 38.2785 53.1832 38.269C53.2117 38.25 53.2402 38.231 53.2687 38.231C53.3066 38.2025 53.3446 38.1931 53.3826 38.1836C53.4016 38.1646 53.4111 38.1646 53.4206 38.1646C53.4775 38.1456 53.5345 38.1266 53.6009 38.1171C53.6199 38.1171 53.6294 38.1076 53.6484 38.1076C53.7244 38.0886 53.8003 38.0886 53.8763 38.0886C53.9807 38.0886 54.0756 38.0981 54.1801 38.1266C54.275 38.1456 54.3604 38.1741 54.4459 38.212C54.4649 38.2215 54.4933 38.231 54.5123 38.25C54.5788 38.269 54.6358 38.307 54.6832 38.3544C54.7307 38.3829 54.7782 38.4114 54.8161 38.4494L59.3826 42.5127C59.9712 43.0348 60.0187 43.9367 59.4965 44.5253Z" />
    <path d="M53.316 38.2026C53.335 38.1931 53.3635 38.1836 53.3825 38.1836C53.3445 38.1931 53.3065 38.2026 53.2686 38.2311C53.2875 38.2121 53.3065 38.2026 53.316 38.2026Z" />
  </svg>
);

export { Plan };
