import colors from "@thrivelot/tailwind/colors";

const optionColor = ({ state, theme }) => {
  if (state.isSelected || state.isFocused) return theme.textLight;
  return theme.text;
};

const optionBackgroundColor = ({ state, theme }) => {
  if (state.isFocused) return colors.blue.light;
  if (state.isSelected) return colors.green.light;
  return theme.foreground;
};

const controlBackgroundColor = ({ state, style, theme }) => {
  if (style.backgroundColor) return style.backgroundColor;
  if (state.isFocused) return theme.inputBackgroundFocused;
  return theme.inputBackground;
};

const controlBoxShadow = ({ state, theme }) => {
  if (state.isFocused) return `-1px -2px 4px ${theme.shadowDiv1}, 1px 2px 4px ${theme.shadowDiv2}`;

  return `inset 0px 1px 4px ${theme.shadowButton}`;
};

const customSelectStyles = (style) => {
  const transition = {
    transition: "all 0.5s ease-out",
  };
  const theme = window.$theme;

  return {
    option: (provided, state) => ({
      ...provided,
      color: optionColor({ state, theme }),
      backgroundColor: optionBackgroundColor({ state, theme }),
      padding: "8px 10px",
      fontFamily: "Rokkitt-Bold",
      fontSize: "17px",
      ...transition,
    }),
    control: (styles, state) => ({
      ...styles,
      backgroundColor: controlBackgroundColor({ state, style, theme }),
      color: style.color || theme.inputText,
      border: 0,
      boxShadow: controlBoxShadow({ state, theme }),
      maxHeight: "35px",
      minHeight: "35px",
      fontFamily: style.fontFamily || "Rokkitt-Bold",
      borderRadius: "5px",
      fontSize: style.fontSize || "17px",
      "&:hover": {
        border: 0,
      },
      ...transition,
    }),
    indicatorsContainer: (styles) => ({
      ...styles,
      minHeight: "35px",
      maxHeight: "35px",
      ...transition,
    }),
    dropdownIndicator: (styles) => ({
      ...styles,
      color: style.dropdownColor || colors.yellow.dark,
    }),
    indicatorSeparator: (styles) => ({
      ...styles,
      minHeight: "0px",
      maxHeight: "0px",
      minWidth: "0px",
      maxWidth: "0px",
      ...transition,
    }),
    input: (styles) => ({
      ...styles,
      caretColor: style.color || theme.inputTintColor,
      color: style.color || theme.inputText,
      fontFamily: style.fontFamily || "Rokkitt-Bold",
      fontSize: style.fontSize || "17px",
      "& input": {
        fontFamily: style.fontFamily || "Rokkitt-Bold",
        fontSize: style.fontSize || "17px",
      },
      ...transition,
    }),
    menu: (provided) => ({
      ...provided,
      maxHeight: style.menuHeight || "150px",
      background: theme.foreground,
      ...transition,
    }),
    menuList: (styles) => ({
      ...styles,
      maxHeight: style.menuHeight || "150px",
      ...transition,
    }),
    container: (styles) => ({
      ...styles,
      ...style,
      fontFamily: style.fontFamily || "Rokkitt-Bold",
      ...transition,
    }),
    valueContainer: (styles) => ({
      ...styles,
      fontFamily: style.fontFamily || "Rokkitt-Bold",
    }),
    placeholder: (styles) => ({
      ...styles,
      fontFamily: style.fontFamily || "Rokkitt-Bold",
      fontSize: style.fontSize || "17px",
      color: theme.inputPlaceholderText,
    }),
    noOptionsMessage: (styles) => ({
      ...styles,
      color: theme.text,
      fontStyle: "italic",
      opacity: 0.7,
    }),
    singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 0.5 : 1;

      return {
        ...provided,
        opacity,
        color: style.color || theme.inputText,
        fontFamily: style.fontFamily || "Rokkitt-Bold",
        fontSize: style.fontSize || "17px",
        ...transition,
      };
    },
    multiValue: (styles) => ({
      ...styles,
      backgroundColor: colors.blue.main,
    }),
    multiValueLabel: (styles) => ({
      ...styles,
      color: theme.inputTextLight,
    }),
    multiValueRemove: (styles) => ({
      ...styles,
      color: theme.inputTextLight,
      opacity: 1,
      ":hover": {
        opacity: 0.3,
      },
    }),
  };
};

export default customSelectStyles;
