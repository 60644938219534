import React, { useMemo } from "react";
import { useModel } from "@thrivelot/hooks";
import { LeafLoader, RowItemLoader } from "@thrivelot/stories";
import { PhaseFeaturesGroup } from "./PhaseFeaturesGroup";
import { PhaselessFeatures } from "./PhaselessFeatures";
import { Button, EmptyState } from "@thrivelot/stories";

const ProjectFeaturesGroupedByPhase = ({ projectId }) => {
  const { model: project, loaded, updateModel, actions } = useModel({ modelName: "Project", id: projectId });

  const sortedPhases = useMemo(() => {
    return project?.phases && project.phases.sort((a, b) => (a.startDate > b.startDate ? 1 : -1));
  }, [project?.phases]);

  if (!loaded)
    return (
      <div>
        <div className="flex">
          <LeafLoader />
        </div>
        <RowItemLoader />
      </div>
    );

  if ((project?.features || []).length === 0)
    return (
      <EmptyState
        icon="LineItems"
        header="No features here yet..."
        children={<div>Get started by creating a feature...</div>}
        footer={
          <div>
            <Button
              color="blue-main"
              kind="outline"
              icon="Plus"
              label="Add Feature"
              onClick={() => updateModel(actions.add("features", actions.constructFeature({}), 0).result)}
            />
          </div>
        }
      />
    );

  return (
    <div className="flex flex-col gap-6">
      <PhaselessFeatures projectId={projectId} />
      {sortedPhases.map(({ id }) => id && <PhaseFeaturesGroup key={id} projectId={projectId} phaseId={id} />)}
    </div>
  );
};

export { ProjectFeaturesGroupedByPhase };
