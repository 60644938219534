import { useModal, useRecycleItems, useModel } from '@thrivelot/hooks';
import { LeafLoader, Cancel, Plus, Edit } from '@thrivelot/stories';
import React, { useMemo } from 'react';
import { RowItemCatalog } from '../RowItemCatalog';

export const CatalogImport = ({ catalogId, ownerId }) => {
  const { openModal } = useModal();

  const {
    model: catalog,
    actions,
    updateModel,
    loaded: catalogLoaded,
  } = useModel({
    modelName: 'Catalog',
    id: catalogId,
  });

  const {
    query,
    setQuery,
    results: recycleResults,
    loaded,
  } = useRecycleItems({
    catalogId,
    ownerId,
  });

  const importedMap = useMemo(() => {
    const map = {};
    catalog?.items?.forEach((item) => {
      map[item.id] = true;
    });
    return map;
  }, [catalogLoaded, catalog?.items]);

  return (
    <div>
      <div className="flex gap-4 items-center">
        <div className="relative flex-1">
          <input
            className="input !pr-7"
            disabled={!loaded}
            placeholder={
              loaded ? 'Search for an item to import...' : 'Loading catalog...'
            }
            value={query}
            onChange={(e) => setQuery(e.target.value)}
          />
          {query.length > 0 && (
            <div className="absolute inset-y-0 right-0 pr-3 flex items-center">
              <Cancel
                color="yellow-main"
                size={16}
                styleProps="hover:cursor-pointer"
                onClick={() => setQuery('')}
              />
            </div>
          )}
        </div>
      </div>
      {!loaded && <LeafLoader classAppend="mt-4" />}
      {loaded && recycleResults.length === 0 && (
        <div className="mt-4">
          No results found in existing projects or orders...
        </div>
      )}
      {loaded && recycleResults.length > 0 && (
        <div className="mt-4 flex flex-col divide-y divide-tan-light">
          {recycleResults.map((item) => (
            <RowItemCatalog
              key={item.id}
              item={item}
              disabled={importedMap[item.id]}
              actions={
                !item.imported && (
                  <div className="ml-2 flex items-center gap-2">
                    <Edit
                      size={20}
                      color="blue-main"
                      onClick={() =>
                        openModal({
                          path: 'ModalUpsertCatalogItem',
                          catalogId,
                          ...item,
                          id: undefined,
                          quantity: undefined,
                          data: { ...item?.data, importSourceId: item.id },
                        })
                      }
                    />
                    <Plus
                      size={20}
                      color="green-main"
                      onClick={() =>
                        updateModel(
                          actions.add(
                            'items',
                            {
                              ...item,
                              quantity: 1,
                              data: { ...item?.data, importSourceId: item.id },
                            },
                            0
                          ).result
                        )
                      }
                    />
                  </div>
                )
              }
            />
          ))}
        </div>
      )}
    </div>
  );
};
