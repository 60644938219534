import { useProposal, useRouter } from "@thrivelot/common";
import { formatFile } from "../helpers";

const useFile = () => {
  // state hooks
  const { projectActions } = useProposal();
  const { hash, urlHashes } = useRouter();
  // utils
  const getFile = () => {
    const fileId = hash[urlHashes.fileId];
    return projectActions.get(`files[id:${fileId}]`);
  };
  const file = getFile();
  // return
  return formatFile(file);
};

export { useFile };
