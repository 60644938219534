import {
  AreasOfWorkIcon,
  CalendarIcon,
  CostIcon,
  FilesFolderIcon,
  NotepadIcon,
  PlantIcon,
  ProjectStoryIcon,
  UserGroupIcon,
} from "@thrivelot/styles";

const IconHashmap = {
  "Project Story": ProjectStoryIcon,
  "Project Team": UserGroupIcon,
  "Site Analysis": NotepadIcon,
  "Site Planning": AreasOfWorkIcon,
  "Plant Palette": PlantIcon,
  Files: FilesFolderIcon,
  Calendar: CalendarIcon,
  "Project Investment": CostIcon,
};

export { IconHashmap };
