import React from "react";
import { ImgGrid } from "@thrivelot/styles";
import { Pdf } from "../../../../../../shared";
import { styleConstants } from "../../../../helpers";

const ImageFile = ({ file }) => <ImgGrid src={file.file} />;

const PdfFile = ({ file }) => (
  <Pdf
    className="gradient-document"
    src={file.file}
    style={{
      overflow: "hidden",
      width: "100%",
      height: "100%",
    }}
  />
);

const FileDisplay = ({ file }) => (
  <div style={styleConstants.gradientItemStyle}>
    {/image/.test(file.file.type) ? <ImageFile file={file} /> : <PdfFile file={file} />}
  </div>
);

export { FileDisplay };
