import styled from "styled-components";

const RightColumnContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: flex-end;
  padding: 0px;
`;

export { RightColumnContainer };
