import styled from "styled-components";

const TitleBarContainer = styled.div`
  transition: opacity 0.5s ease-out, box-shadow 0.5s ease-out, color 1s ease-in-out, background-color 1s ease-in-out;
  display: flex;
  border-bottom: 1px solid ${({ theme }) => theme.divLine};
  @media (max-width: 767px) {
    padding: 12px;
  }
  @media (min-width: 768px) {
    padding: 20px;
  }
`;

export { TitleBarContainer };
