import chroma from "chroma-js";
import colors from "@thrivelot/tailwind/colors";

const styleForListItemCostContainer = {
  position: "relative",
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: "0px 5px 0px 8px",
  minHeight: "35px",
  maxHeight: "35px",
  minWidth: "90px",
  maxWidth: "90px",
  marginRight: "20px",
  backgroundColor: chroma(colors.green.main).alpha(0.1).css(),
};

export { styleForListItemCostContainer };
