import React from 'react';
import { useModel } from '@thrivelot/hooks';
import { LeafLoader, Button, EmptyState, RowList } from '@thrivelot/stories';
import { RowItemFeature } from '..';

const RowListProjectFeatures = ({ projectId }) => {
  const {
    model: project,
    loaded,
    updateModel,
    actions,
  } = useModel({ modelName: 'Project', id: projectId });

  if (!loaded) return <LeafLoader />;

  if ((project?.features || []).filter((f) => !f.hidden).length === 0)
    return (
      <EmptyState
        icon="LineItems"
        header="No features here yet..."
        children={<div>Get started by creating a feature...</div>}
        footer={
          <div>
            <Button
              color="blue-main"
              kind="outline"
              icon="Plus"
              label="Add Feature"
              onClick={() =>
                updateModel(
                  actions.add('features', actions.constructFeature({}), 0)
                    .result
                )
              }
            />
          </div>
        }
      />
    );

  const { features } = project;

  return (
    <RowList>
      {features
        .filter((f) => !f.hidden)
        .map(({ id }) => (
          <RowItemFeature key={id} featureId={id} projectId={projectId} />
        ))}
    </RowList>
  );
};

export { RowListProjectFeatures };
