import React from 'react';
import { useCanCan, useModel, useModal } from '@thrivelot/hooks';
import { Button, PageContainer } from '@thrivelot/stories';
import { Navigate, Route, Routes, useParams } from 'react-router-dom';
import {
  CardListProjectFiles,
  HelpPrompt,
  RowListProjectFiles,
  DropdownLinks,
} from '../..';

const PageProjectFiles = ({ startingView = 'list' }) => {
  const { openModal } = useModal();
  const { projectId } = useParams();
  const { model: project, loaded } = useModel({
    modelName: 'Project',
    id: projectId,
  });
  const { canEdit } = useCanCan(project);

  // const options = [
  //   {
  //     label: 'List View',
  //     icon: 'List',
  //     to: 'list',
  //   },
  //   {
  //     label: 'Grid View',
  //     icon: 'Grid',
  //     to: 'grid',
  //   },
  // ];

  console.log('blah project: ', project);

  return (
    <PageContainer
      icon="FilesFolder"
      header="Files"
      // dropdown={
      //   <DropdownLinks
      //     color="brown-dark"
      //     options={options}
      //     pathMatch={({ location, params, to }) =>
      //       to === location.pathname.split('/')[4]
      //     }
      //     classAppend="!px-3 !py-0.5 text-sm !border-none pt-1"
      //     align="left"
      //   />
      // }
      documentTitle={loaded && project.details.title}
      headerActions={
        canEdit && (
          <div className="flex items-center space-x-3">
            <Button
              icon="FilesFolder"
              size="small"
              color="blue-main"
              kind="outline"
              label="New Folder"
              onClick={() =>
                openModal({ path: 'ModalAddFolder', modelId: projectId })
              }
            />
            <Button
              icon="Plus"
              size="small"
              color="blue-main"
              kind="outline"
              label="Add File"
              onClick={() =>
                openModal({
                  path: 'ModalAddFiles',
                  modelName: 'Project',
                  modelId: projectId,
                  filesPath: 'files',
                  allowMultiple: true,
                })
              }
            />
          </div>
        )
      }
      help={
        <HelpPrompt header="About the Project Files page">
          This section is the list or gallery view of files uploaded as
          documentation for each project. For each photo or PDF uploaded, you
          can add captions and edit the files over time.
        </HelpPrompt>
      }
    >
      <Routes>
        <Route exact path="" element={<Navigate to={startingView} replace />} />
        <Route
          path="grid"
          element={<CardListProjectFiles projectId={projectId} />}
        />
        <Route
          path="list"
          element={<RowListProjectFiles projectId={projectId} />}
        />
      </Routes>
    </PageContainer>
  );
};

export { PageProjectFiles };
