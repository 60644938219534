import styled from "styled-components";

const SiteAnalysisContainer = styled.div`
  transition: opacity 0.5s ease-out, box-shadow 0.5s ease-out, color 1s ease-in-out, background-color 1s ease-in-out;
  @media (max-width: 767px) {
    display: flex;
    flex-direction: column;
    padding-top: 12px;
    margin-bottom: -12px;
  }
  @media (min-width: 768px) {
    column-count: 3;
    padding-top: 20px;
  }
`;

export { SiteAnalysisContainer };
