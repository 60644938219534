import { useContext } from "react";
import { NotificationsContext } from "./NotificationsContext";

const useNotifications = () => {
  const { notifications, showNotification, removeNotification } = useContext(NotificationsContext);

  return { notifications, showNotification, removeNotification };
};

export { useNotifications };
