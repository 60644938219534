import React from 'react';

const DateDone = ({
  color = 'green-main',
  size = 30,
  styleProps = '',
  ...rest
}) => (
  <svg
    width={size.toString()}
    height={size.toString()}
    viewBox="0 0 75 75"
    className={`fill-${color} ${styleProps}`}
    {...rest}
  >
    <path d="M53.05 32.2595C51.0563 30.3417 47.8759 30.3987 45.9582 32.3829L31.7557 47.0791L29.069 44.25C28.1481 43.2816 26.8949 42.731 25.5563 42.693C24.2177 42.6645 22.9456 43.1487 21.9772 44.0696C21.0089 45 20.4582 46.2436 20.4203 47.5822C20.3918 48.9209 20.876 50.193 21.7968 51.1614L28.0911 57.7785C29.031 58.7658 30.3411 59.3259 31.7082 59.3354H31.7272C33.0753 59.3354 34.3854 58.7753 35.3253 57.8069L53.1639 39.3512C54.1038 38.3924 54.5975 37.1202 54.5785 35.7816C54.55 34.443 54.0089 33.1898 53.05 32.2595ZM51.1228 37.3671L33.2842 55.8228C32.8759 56.25 32.3063 56.4873 31.7272 56.4873H31.7177C31.1291 56.4873 30.5595 56.2405 30.1513 55.8133L23.8665 49.1962C23.0405 48.3322 23.0785 46.9557 23.9424 46.1392C24.3506 45.75 24.8728 45.5411 25.4329 45.5411H25.4899C26.069 45.5601 26.6006 45.7974 26.9994 46.2152L31.7367 51.1993L48.0089 34.3576C48.8348 33.5031 50.2114 33.4841 51.0658 34.3101C51.4835 34.7088 51.7209 35.25 51.7304 35.8291C51.7399 36.4082 51.5215 36.9588 51.1228 37.3671Z" />
    <path d="M61.7184 10.1867H57.9114V3.32278C57.9114 1.49051 56.4209 0 54.5886 0C52.7563 0 51.2658 1.49051 51.2658 3.32278V10.1867H23.7342V3.32278C23.7342 1.49051 22.2437 0 20.4114 0C18.5791 0 17.0886 1.49051 17.0886 3.32278V10.1867H13.2816C5.96203 10.1867 0 16.1487 0 23.4684V61.7184C0 69.038 5.96203 75 13.2816 75H61.7184C69.038 75 75 69.038 75 61.7184V23.4684C75 16.1487 69.038 10.1867 61.7184 10.1867ZM68.3544 61.7184C68.3544 65.3734 65.3734 68.3544 61.7184 68.3544H13.2816C9.62658 68.3544 6.64557 65.3734 6.64557 61.7184V23.4684C6.64557 19.8133 9.62658 16.8323 13.2816 16.8323H17.0886V20.288C17.0886 22.1203 18.5791 23.6108 20.4114 23.6108C22.2437 23.6108 23.7342 22.1203 23.7342 20.288V16.8323H51.2658V20.288C51.2658 22.1203 52.7563 23.6108 54.5886 23.6108C56.4209 23.6108 57.9114 22.1203 57.9114 20.288V16.8323H61.7184C65.3734 16.8323 68.3544 19.8133 68.3544 23.4684V61.7184Z" />
  </svg>
);

export { DateDone };
