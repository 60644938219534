import React from "react";
import SvgContainer from "../SvgWrapper";

const ReorderIcon = ({ color, size, ...rest }) => (
  <SvgContainer
    width={`${size}`}
    height={`${size * 0.75}`}
    viewBox="0 0 20 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      d="M19.1139 2.29508H0.886076C0.405063 2.29508 0 1.77049 0 1.14754C0 0.52459 0.405063 0 0.886076 0H19.1139C19.5949 0 20 0.52459 20 1.14754C20 1.77049 19.5949 2.29508 19.1139 2.29508Z"
      fill={color}
    />
    <path
      d="M19.1139 14.0983H0.886076C0.405063 14.0983 0 13.5737 0 12.9508C0 12.3278 0.405063 11.8032 0.886076 11.8032H19.1139C19.5949 11.8032 20 12.3278 20 12.9508C20 13.5737 19.5949 14.0983 19.1139 14.0983Z"
      fill={color}
    />
    <path
      d="M19.1139 8.19669H0.886076C0.405063 8.19669 0 7.6721 0 7.04915C0 6.4262 0.405063 5.90161 0.886076 5.90161H19.1139C19.5949 5.90161 20 6.4262 20 7.04915C20 7.6721 19.5949 8.19669 19.1139 8.19669Z"
      fill={color}
    />
  </SvgContainer>
);

export default ReorderIcon;
