import React from 'react';

const Pdf = ({ color = 'green-main', size = 30, styleProps = '', ...rest }) => (
  <svg
    width={size.toString()}
    height={size.toString()}
    viewBox="0 0 75 75"
    className={`fill-${color} ${styleProps}`}
    {...rest}
  >
    <path d="M74.9335 28.6994C74.924 28.6139 74.8956 28.5285 74.8671 28.443C74.8101 28.2152 74.7247 27.9968 74.6108 27.788C74.5538 27.693 74.5063 27.5981 74.4399 27.5127C74.3829 27.4082 74.3165 27.3228 74.2405 27.2373C74.174 27.1519 74.1076 27.076 74.0316 27L48.2089 0.977848H48.1994C47.8196 0.598101 47.3544 0.313291 46.8323 0.151899C46.7753 0.132911 46.7089 0.113924 46.6424 0.0949367C46.3861 0.0379747 46.1203 0 45.8449 0H13.2816C5.96203 0 0 5.96203 0 13.2816V61.7184C0 69.038 5.96203 75 13.2816 75H61.7184C69.038 75 75 69.038 75 61.7184V29.3449C75 29.1266 74.981 28.9082 74.9335 28.6994ZM49.1677 11.3829L63.7025 26.0222H50.6013C49.8133 26.0222 49.1677 25.3766 49.1677 24.5886V11.3829ZM68.3544 61.7184C68.3544 65.3734 65.3734 68.3544 61.7184 68.3544H13.2816C9.62658 68.3544 6.64557 65.3734 6.64557 61.7184V13.2816C6.64557 9.62658 9.62658 6.64557 13.2816 6.64557H42.5222V24.5886C42.5222 29.0411 46.1487 32.6677 50.6013 32.6677H68.3544V61.7184Z" />
    <path d="M30.7 47.52C30.7 48.3333 30.5133 49.08 30.14 49.76C29.7667 50.4267 29.1933 50.9667 28.42 51.38C27.6467 51.7933 26.6867 52 25.54 52H23.42V57.04H20V43H25.54C26.66 43 27.6067 43.1933 28.38 43.58C29.1533 43.9667 29.7333 44.5 30.12 45.18C30.5067 45.86 30.7 46.64 30.7 47.52ZM25.28 49.28C25.9333 49.28 26.42 49.1267 26.74 48.82C27.06 48.5133 27.22 48.08 27.22 47.52C27.22 46.96 27.06 46.5267 26.74 46.22C26.42 45.9133 25.9333 45.76 25.28 45.76H23.42V49.28H25.28Z" />
    <path d="M37.7405 43C39.2205 43 40.5138 43.2933 41.6205 43.88C42.7271 44.4667 43.5805 45.2933 44.1805 46.36C44.7938 47.4133 45.1005 48.6333 45.1005 50.02C45.1005 51.3933 44.7938 52.6133 44.1805 53.68C43.5805 54.7467 42.7205 55.5733 41.6005 56.16C40.4938 56.7467 39.2071 57.04 37.7405 57.04H32.4805V43H37.7405ZM37.5205 54.08C38.8138 54.08 39.8205 53.7267 40.5405 53.02C41.2605 52.3133 41.6205 51.3133 41.6205 50.02C41.6205 48.7267 41.2605 47.72 40.5405 47C39.8205 46.28 38.8138 45.92 37.5205 45.92H35.9005V54.08H37.5205Z" />
    <path d="M56.1517 43V45.74H50.4317V48.7H54.7117V51.36H50.4317V57.04H47.0117V43H56.1517Z" />
  </svg>
);

export { Pdf };
