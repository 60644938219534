import styled from "styled-components";
import CssRaisedShadow from "./CssRaisedShadow";
import CssInsetShadow from "./CssInsetShadow";
import CssDivDefault from "./CssDivDefault";

// const backgroundColor = ({ isSelected, theme }) => {
//   if (isSelected) return theme.green;
//   return theme.foreground;
// };

const shadow = ({ isSelected }) => {
  if (isSelected) return CssInsetShadow;
  return CssRaisedShadow;
};

const DivSubmitFeedbackNavbarItem = styled.div`
  ${CssDivDefault}
  ${shadow}
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 35px;
  max-height: 35px;
  min-width: 35px;
  max-width: 35px;
  border-radius: 5px;
  margin-left: 12px;
`;

export default DivSubmitFeedbackNavbarItem;
