import { useContext } from "react";
import { MapContext } from "./MapContext";

const useMap = () => {
  const {
    loading,
    loadError,
    map,
    center,
    zoom,
    infoBoxId,
    drawingManager,
    drawingMode,
    options,
    drawingOptions,
    setValue,
    onLoad,
    onUnmount,
    onCenterChanged,
    onZoomChanged,
    onLoadDrawingManager,
    onUnmountDrawingManager,
    onMarkerComplete,
    onPolylineComplete,
    onRectangleComplete,
    onCircleComplete,
    onPolygonComplete,
    toggleInfoBox,
  } = useContext(MapContext);

  return {
    loading,
    loadError,
    map,
    center,
    zoom,
    infoBoxId,
    drawingManager,
    drawingMode,
    options,
    drawingOptions,
    setValue,
    onLoad,
    onUnmount,
    onCenterChanged,
    onZoomChanged,
    onLoadDrawingManager,
    onUnmountDrawingManager,
    onMarkerComplete,
    onPolylineComplete,
    onRectangleComplete,
    onCircleComplete,
    onPolygonComplete,
    toggleInfoBox,
  };
};

export { useMap };
