import React from "react";
import { Outlet } from "react-router-dom";
import { NotificationCenter } from "../../shared";
import { HeaderNav } from "../HeaderNav";
import { RoutingModals } from "../RoutingModals";
import { EnsureTerms } from "../EnsureTerms";

const LayoutSimple = ({ navConfig, ensureTerms }) => {
  const { navOptions: options, helpOptions } = navConfig;

  return (
    <div className="flex flex-col h-screen text-brown-dark">
      <HeaderNav helpOptions={helpOptions} navOptions={options} />
      <div className="container mx-auto p-4 flex flex-col flex-grow">
        <Outlet />
      </div>
      <RoutingModals />
      <NotificationCenter />
      {ensureTerms && <EnsureTerms />}
    </div>
  );
};

export { LayoutSimple };
