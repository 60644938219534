import React, { useRef } from "react";
import SvgContainer from "../SvgWrapper";

const InstallationIcon = ({ color, size, ...rest }) => {
  const clipRef = useRef(`${Math.floor(Math.random() * 1000)}`);
  return (
    <SvgContainer
      width={`${size}`}
      height={`${size}`}
      viewBox="0 0 300 300"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <g clipPath={`url(#${clipRef.current})`}>
        <path
          d="M285.57 193.544L223.443 175.734L210.038 148.658C207.152 142.734 202.253 138.215 196.215 135.823L178.291 76.4304C172.975 58.8481 157.063 47 138.684 47H95.8861C85.8987 47 76.8228 53.3038 73.2532 62.6076L62.6962 90.443L57.7215 88.7722C54.6835 87.7468 51.4937 87.2152 48.3038 87.2152H19.7089C8.8481 87.2152 0 96.0633 0 106.924V206.835C0 218.038 9.11392 227.114 20.2785 227.114H28.0633C34.2911 242.342 49.2532 253.089 66.6835 253.089C84.1139 253.089 99.0759 242.342 105.304 227.114H121.405C127.633 242.342 142.595 253.089 160.025 253.089C177.456 253.089 192.418 242.342 198.646 227.114H207.873V230.57C207.873 236.342 210.418 241.848 214.785 245.646C218.43 248.835 223.101 250.544 227.848 250.544C228.797 250.544 229.747 250.468 230.658 250.354L281.354 243.215C289.937 242 296.848 235.127 298.139 226.544L299.772 215.646C301.253 205.81 295.139 196.278 285.57 193.544ZM97.519 73.5823H138.684C145.253 73.5823 150.949 77.8354 152.848 84.1013L165.266 125.228L87.8734 98.9873L97.519 73.5823ZM66.6835 241.696C49.9747 241.696 36.3797 228.101 36.3797 211.392C36.3797 194.684 49.9747 181.089 66.6835 181.089C83.3924 181.089 96.9873 194.684 96.9873 211.392C96.9873 228.101 83.3924 241.696 66.6835 241.696ZM160.025 241.696C143.316 241.696 129.722 228.101 129.722 211.392C129.722 194.684 143.316 181.089 160.025 181.089C176.734 181.089 190.329 194.684 190.329 211.392C190.329 228.101 176.734 241.696 160.025 241.696ZM207.873 208.127H201.57C199.937 186.633 181.937 169.696 160.025 169.696C138.152 169.696 120.152 186.633 118.481 208.127H108.228C106.595 186.633 88.5949 169.696 66.6835 169.696C44.8101 169.696 26.8101 186.633 25.1392 208.127H20.2785C19.557 208.127 18.9873 207.557 18.9873 206.835V106.924C18.9873 106.506 19.2911 106.203 19.7089 106.203H48.3038C49.4051 106.203 50.5443 106.392 51.6076 106.734L188.544 153.139C190.519 153.823 192.114 155.228 193.025 157.089L207.873 187.278V208.127V208.127ZM234.456 222.975V206.57L272.62 217.506L234.456 222.975Z"
          fill={color}
        />
        <path
          d="M143.281 164.532H62.0154C54.99 164.532 49.2559 158.835 49.2559 151.772V130.696C49.2559 126.557 51.2685 122.608 54.6103 120.177C57.99 117.747 62.3192 117.063 66.2685 118.354L146.433 144.557C151.332 146.152 154.18 150.823 153.382 155.911C152.585 161 148.446 164.532 143.281 164.532ZM62.2432 129.101C61.8255 129.101 61.4837 129.291 61.2938 129.405C61.028 129.595 60.6483 129.975 60.6483 130.696V151.772C60.6483 152.532 61.2559 153.139 62.0154 153.139H136.104L62.7748 129.177C62.585 129.101 62.3951 129.101 62.2432 129.101Z"
          fill={color}
        />
        <path
          d="M66.6852 234.177C54.1156 234.177 43.9004 223.962 43.9004 211.392C43.9004 198.823 54.1156 188.608 66.6852 188.608C79.2548 188.608 89.47 198.823 89.47 211.392C89.47 223.962 79.2548 234.177 66.6852 234.177ZM66.6852 200C60.4194 200 55.2928 205.127 55.2928 211.392C55.2928 217.658 60.4194 222.785 66.6852 222.785C72.951 222.785 78.0776 217.658 78.0776 211.392C78.0776 205.127 72.989 200 66.6852 200Z"
          fill={color}
        />
        <path
          d="M160.027 234.177C147.457 234.177 137.242 223.962 137.242 211.392C137.242 198.823 147.457 188.608 160.027 188.608C172.597 188.608 182.812 198.823 182.812 211.392C182.812 223.962 172.597 234.177 160.027 234.177ZM160.027 200C153.761 200 148.635 205.127 148.635 211.392C148.635 217.658 153.761 222.785 160.027 222.785C166.293 222.785 171.419 217.658 171.419 211.392C171.419 205.127 166.331 200 160.027 200Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id={clipRef.current}>
          <rect y="47" width="300" height="206.089" fill="none" />
        </clipPath>
      </defs>
    </SvgContainer>
  );
};

export default InstallationIcon;
