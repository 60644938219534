import React from "react";
import { useSpacing } from "../../hooks";
import { SectionContainer } from "./SectionContainer";
import { Header } from "./Header";
import { SectionHashmap } from "./SectionHashmap";

const Section = ({ section }) => {
  const marginBottom = useSpacing();
  const SectionContent = SectionHashmap[section];
  return (
    <SectionContainer
      id={section}
      style={{
        marginBottom: section === "Project Investment" ? "300px" : marginBottom,
      }}
    >
      <Header section={section} />
      <SectionContent />
    </SectionContainer>
  );
};

export { Section };
