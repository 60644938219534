import { urlModalValues } from "@thrivelot/config";
import { FileModal } from "./FileModal";
import { PaymentModal } from "./PaymentModal";
import { PlantModal } from "./PlantModal";
import { SupplierModal } from "./SupplierModal";

const ModalHashmap = {
  [urlModalValues.proposalFile]: FileModal,
  [urlModalValues.proposalPayment]: PaymentModal,
  [urlModalValues.proposalPlant]: PlantModal,
  [urlModalValues.proposalSupplier]: SupplierModal,
};

export { ModalHashmap };
