import { isEmpty } from "@thrivelot/utils";
import { get } from "@thrivelot/actions";
import { getSupplierName } from "./getSupplierName";

const getSupplierSubtitle = (model) => {
  const firstname = get(model, "firstname");
  const lastname = get(model, "lastname");
  const name = `${firstname?.trim() || ""} ${lastname?.trim() || ""}`;
  if (isEmpty(name.trim()) || getSupplierName(model) === name) {
    return "";
  }
  return name;
};

export { getSupplierSubtitle };
