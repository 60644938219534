import { PaymentCalculationType, CostPaymentScheduleType } from "@thrivelot/aws";
import { calculateDesignCost } from "./calculateDesignCost";
import { calculateTotalLaborCost } from "./calculateTotalLaborCost";
import { calculateTotalMaterialCost } from "./calculateTotalMaterialCost";

const calculatePaymentTotal = (payment, project, noMultiplier = false, giveDollarMarkup = false) => {
  let subtotal = 0;

  const lineItems = project.lineItems || [];
  const plantItems = project.plantItems || [];

  const totalLaborCost = calculateTotalLaborCost(lineItems);
  const totalMaterialCost = calculateTotalMaterialCost(lineItems, plantItems);

  let markup;

  if (payment.payments)
    payment.payments.forEach((pmnt) => {
      if (pmnt.calculation) {
        let paymentTotal = 0;

        pmnt.calculation.forEach((item) => {
          if (item.type === PaymentCalculationType.AMOUNT) paymentTotal += item.amount;
          else if (item.type === PaymentCalculationType.LABOR_PERCENTAGE)
            paymentTotal += (item.amount * totalLaborCost) / 100;
          else paymentTotal += (item.amount * totalMaterialCost) / 100;
        });

        ({ markup } = pmnt);

        if (noMultiplier) subtotal += paymentTotal;
        else {
          const multiplier = pmnt.markup + 1;
          subtotal += paymentTotal * multiplier;
        }
      }
    });

  let dollarMarkup;
  if (payment.type === CostPaymentScheduleType.DESIGN) {
    const siteVisitPayment = project.costPaymentSchedule.find((p) => p.type === CostPaymentScheduleType.SITE_VISIT);
    const siteVisitCost =
      siteVisitPayment.payments[0].calculation[0].amount * (1 + siteVisitPayment.payments[0].markup);
    ({ designCost: subtotal, dollarMarkup } = calculateDesignCost(subtotal, markup, siteVisitCost));
  }

  subtotal = Math.ceil(subtotal / 100) * 100;
  if (dollarMarkup) dollarMarkup = Math.ceil(dollarMarkup / 100) * 100;

  if (giveDollarMarkup) return { subtotal, dollarMarkup };
  return subtotal;
};

export { calculatePaymentTotal };
