import { TagIdType } from "@thrivelot/aws";

const getPhase = (tagIds, project) => {
  if (!tagIds) return undefined;

  const tagId = tagIds.find((e) => e.type === TagIdType.PHASE);
  if (!tagId) return undefined;

  const phases = project.phases ? [...project.phases] : [];
  return phases.find((e) => e.id === tagId.tagId);
};

export { getPhase };
