import React, { useCallback, useEffect, useState } from 'react';
import { LogoGold, Button } from '@thrivelot/stories';
import { useAuth, useQueryParam } from '@thrivelot/hooks';
import { Link, useNavigate } from 'react-router-dom';
import { InputPassword } from '../../InputPassword';

const PageChangePassword = () => {
  const navigate = useNavigate();
  const { constructParamWithKeyAndValue } = useQueryParam('login');
  const { paramAsString: returnTo } = useQueryParam('returnTo');
  const { param: change } = useQueryParam('change');
  const [errors, setErrors] = useState(false);
  const [loading, setLoading] = useState(false);
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const { completeNewPassword } = useAuth();

  const { email } = change;

  useEffect(() => {
    document.title = 'Thrive Lot | Change Password';
  }, []);

  const handleSubmit = useCallback(
    (evt) => {
      evt.preventDefault();

      setErrors([]);

      if (password !== confirmPassword)
        return setErrors((prev) => [...prev, "Passwords don't match"]);

      setLoading(true);

      completeNewPassword(password) // Undefined user arg lets the function pull it from authState
        .then(() => {
          setLoading(false);
          const login = constructParamWithKeyAndValue({ email });
          navigate(
            `/auth/login?${[returnTo, login].filter((x) => !!x).join('&')}`
          );
        })
        .catch((error) => {
          if (error.message.includes('combination not found'))
            setErrors((prev) => [...prev, 'Unknown email']);
          else setErrors((prev) => [...prev, error.message]);

          setLoading(false);
        });
    },
    [
      password,
      confirmPassword,
      email,
      completeNewPassword,
      setErrors,
      setLoading,
      navigate,
      constructParamWithKeyAndValue,
    ]
  );

  return (
    <form
      onSubmit={handleSubmit}
      className="max-w-sm w-full bg-white-dark shadow rounded-main p-5"
    >
      <div className="flex justify-center mb-8 mt-4">
        <LogoGold size={180} />
      </div>

      <div className="text-brown-dark text-sm text-center mb-4">
        Please set a new password to continue.
      </div>

      <div className="mb-4">
        <InputPassword
          placeholder="New Password"
          className="input"
          value={password}
          onChange={(evt) => setPassword(evt.target.value)}
        />
      </div>

      <div className="mb-4">
        <InputPassword
          placeholder="Confirm Password"
          className="input"
          value={confirmPassword}
          onChange={(evt) => setConfirmPassword(evt.target.value)}
        />
      </div>

      {errors.length > 0 && (
        <div className="mb-4">
          {errors.map((error) => (
            <div key={error} className="mb-2 text-sm text-red-main">
              {error}
            </div>
          ))}
        </div>
      )}

      <Button
        block
        type="submit"
        loading={loading}
        disabled={loading}
        label="Submit"
        color="green-main"
      />

      <div className="flex flex-col items-center mt-5 gap-4">
        <Link to="/auth/login" className="text-blue-main font-bold text-sm">
          Back to login form
        </Link>
      </div>
    </form>
  );
};

export { PageChangePassword };
