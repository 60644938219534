import React from 'react';

const Location = ({
  color = 'green-main',
  size = 30,
  styleProps = '',
  ...rest
}) => (
  <svg
    width={size.toString()}
    height={size.toString()}
    viewBox="0 0 75 75"
    className={`fill-${color} ${styleProps}`}
    {...rest}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M31.9512 4.70464C33.5884 3.59385 35.5214 3 37.4999 3C39.4785 3 41.4115 3.59381 43.0488 4.70464L43.0597 4.71207L70.7431 23.6609L70.7642 23.6754C72.0729 24.5867 73.1415 25.8009 73.879 27.2146C74.6166 28.6282 75.0012 30.1993 75 31.794L71.7988 31.7915H75V31.794V63.446L74.9999 63.4689C74.9813 66.0779 73.9317 68.5737 72.0803 70.412C70.2288 72.2502 67.7256 73.2818 65.1165 73.2817H46.0246C44.2566 73.2817 42.8234 71.8485 42.8234 70.0805C42.8234 68.3125 44.2566 66.8793 46.0246 66.8793H65.1167C66.0356 66.8793 66.9172 66.516 67.5693 65.8685C68.2191 65.2234 68.5884 64.3483 68.5976 63.433V63.446H71.7988L68.5976 63.4232C68.5976 63.4264 68.5976 63.4297 68.5976 63.433V31.789C68.598 31.2275 68.4625 30.6741 68.2027 30.1761C67.9446 29.6813 67.5713 29.256 67.1145 28.9358L67.1268 28.9442L68.935 26.3025L67.1057 28.9296L39.4543 10.0028L41.2516 7.35373L39.4434 9.99538L39.4497 9.9997C38.8741 9.61049 38.195 9.40244 37.4999 9.40244C36.805 9.40244 36.126 9.61046 35.5503 9.9997L35.5566 9.99538L33.7484 7.35373L35.5457 10.0028L7.88542 28.9358C7.88838 28.9337 7.89133 28.9317 7.89429 28.9296L6.06502 26.3025L7.87319 28.9442L7.88542 28.9358C7.42849 29.2561 7.05522 29.6815 6.79716 30.1761C6.53745 30.6739 6.402 31.2272 6.40244 31.789L6.40244 63.4327C6.40241 63.4296 6.40238 63.4264 6.40236 63.4232L3.20122 63.446H6.40244V63.4327C6.4115 64.3483 6.78082 65.2234 7.43057 65.8685C8.08263 66.5159 8.96436 66.8793 9.88342 66.8793H28.9754C30.7434 66.8793 32.1766 68.3125 32.1766 70.0805C32.1766 71.8485 30.7434 73.2817 28.9754 73.2817H9.8835C7.2745 73.2818 4.77115 72.2503 2.91962 70.412C1.06805 68.5736 0.0187184 66.0778 8.31383e-05 63.4689L1.36367e-06 63.446V31.7924L2.454e-06 31.794L3.20122 31.7915H1.36367e-06C-0.000835573 30.1979 0.383584 28.6277 1.12085 27.2146C1.85849 25.8008 2.92728 24.5866 4.23575 23.6754L4.25686 23.6609L31.9403 4.71207L31.9512 4.70464Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M36.474 29.4107C39.3389 29.2206 42.1966 29.8758 44.6924 31.2952C47.1883 32.7147 49.2124 34.8359 50.5135 37.3955C51.8147 39.9551 52.3354 42.8405 52.0113 45.6934C51.6886 48.5338 50.5425 51.2176 48.7145 53.4148L39.8115 65.3076C39.5362 65.6762 39.1787 65.9754 38.7675 66.1815C38.3564 66.3876 37.9028 66.495 37.4427 66.495C36.9828 66.495 36.5291 66.3877 36.1178 66.1815C35.7067 65.9754 35.3492 65.6762 35.0738 65.3076L26.1709 53.4147C24.4484 51.3443 23.3293 48.8392 22.937 46.1742C22.5427 43.4966 22.8974 40.7623 23.9617 38.2739C25.0259 35.7856 26.7582 33.6404 28.9666 32.0761C31.175 30.5118 33.7737 29.5892 36.474 29.4107ZM27.2476 52.5637L28.3459 51.7415L37.2723 63.6657C37.292 63.6921 37.3177 63.7137 37.3474 63.7285C37.377 63.7433 37.4096 63.7511 37.4427 63.7511C37.4758 63.7511 37.5083 63.7434 37.538 63.7285C37.5676 63.7137 37.5934 63.6921 37.6131 63.6657L46.5395 51.7415C46.5543 51.7217 46.5696 51.7024 46.5854 51.6835C48.0828 49.8932 49.0214 47.7027 49.2849 45.3837C49.5484 43.0647 49.1251 40.7194 48.0675 38.6389C47.0099 36.5583 45.3647 34.8342 43.3359 33.6804C41.3071 32.5266 38.9842 31.994 36.6554 32.1486C34.4603 32.2937 32.3478 33.0436 30.5527 34.3151C28.7576 35.5867 27.3496 37.3303 26.4845 39.353C25.6195 41.3756 25.3312 43.5981 25.6516 45.7745C25.972 47.9508 26.8887 49.996 28.3 51.6835L27.2476 52.5637Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M37.4045 41.0103C35.7159 41.0103 34.3471 42.3791 34.3471 44.0677C34.3471 45.7562 35.7159 47.1251 37.4045 47.1251C39.093 47.1251 40.4619 45.7562 40.4619 44.0677C40.4619 42.3791 39.093 41.0103 37.4045 41.0103ZM31.6032 44.0677C31.6032 40.8637 34.2005 38.2664 37.4045 38.2664C40.6085 38.2664 43.2058 40.8637 43.2058 44.0677C43.2058 47.2716 40.6085 49.869 37.4045 49.869C34.2005 49.869 31.6032 47.2716 31.6032 44.0677Z"
    />
  </svg>
);

export { Location };
