import { TimestampKind } from '@thrivelot/aws';
import { constructUuid, isCustomerApp } from '@thrivelot/utils';
import { useCallback, useMemo, useEffect } from 'react';
import { useAuth, useModel } from '..';

const getModelName = () => (isCustomerApp() ? 'Customer' : 'Supplier');

const useConstructTimestamp = () => {
  const { userId, user: authUser } = useAuth();
  const { model: user, queryModel } = useModel({
    modelName: getModelName(),
    id: userId,
    deferLoad: true,
  });

  useEffect(() => {
    userId && queryModel();
  }, [userId, queryModel]);

  // description: String # Optional pretty description for user
  // createdById: String # Id of user who created the timestamp
  // createdByName: String # Name of user who created the timestamp
  // createdByEmail: String! # Name of user who created the timestamp
  // createdByAuthId: String! # Cognito id of user who created the timestamp
  // createdAt: AWSDateTime!

  const [username, email] = useMemo(() => {
    if (!authUser) return;
    return [authUser?.username, authUser?.attributes?.email];
  }, [authUser]);

  const construct = useCallback(
    (props) => {
      if (!props.kind)
        throw new Error('Missing required prop to construct timestamp: kind');
      if (!Object.values(TimestampKind).includes(props.kind))
        throw new Error(
          `Invalid timestamp kind. Possible values include: ${Object.values(
            TimestampKind
          ).join(', ')}`
        );

      const name =
        user?.firstname ||
        (user?.lastname && `${user?.firstname} ${user?.lastname}`);

      return {
        id: constructUuid(),
        createdById: userId,
        createdByName: name,
        createdByEmail: email,
        createdByAuthId: username,
        createdAt: props.createdAt || new Date().toISOString(),
        ...props,
      };
    },
    [email, user?.firstname, user?.lastname, userId, username]
  );

  return { construct };
};

export { useConstructTimestamp };
