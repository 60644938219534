import React, { forwardRef } from "react";
import SvgContainer from "../SvgWrapper";

const DateEndIcon = forwardRef(({ color, size, ...rest }, ref) => (
  <SvgContainer
    ref={ref}
    width={`${size}`}
    height={`${size}`}
    viewBox="0 0 300 300"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      d="M212.2 129.038C204.225 121.367 191.504 121.595 183.833 129.532L127.023 188.317L116.276 177C112.592 173.127 107.58 170.924 102.225 170.772C96.8708 170.658 91.7822 172.595 87.9087 176.279C84.0353 180 81.8328 184.975 81.6809 190.329C81.567 195.684 83.5037 200.772 87.1872 204.646L112.364 231.114C116.124 235.063 121.364 237.304 126.833 237.342H126.909C132.301 237.342 137.542 235.101 141.301 231.228L212.656 157.405C216.415 153.57 218.39 148.481 218.314 143.127C218.2 137.772 216.035 132.76 212.2 129.038ZM204.491 149.468L133.137 223.291C131.504 225 129.225 225.95 126.909 225.95H126.871C124.516 225.95 122.238 224.962 120.605 223.253L95.4657 196.785C92.1619 193.329 92.3138 187.823 95.7695 184.557C97.4024 183 99.491 182.165 101.732 182.165H101.959C104.276 182.241 106.402 183.19 107.997 184.861L126.947 204.798L192.035 137.43C195.339 134.013 200.845 133.937 204.263 137.241C205.934 138.836 206.883 141 206.921 143.317C206.959 145.633 206.086 147.836 204.491 149.468Z"
      fill={color}
    />
    <path
      d="M246.873 40.7468H231.646V13.2911C231.646 5.96203 225.684 0 218.354 0C211.025 0 205.063 5.96203 205.063 13.2911V40.7468H94.9367V13.2911C94.9367 5.96203 88.9747 0 81.6456 0C74.3165 0 68.3544 5.96203 68.3544 13.2911V40.7468H53.1266C23.8481 40.7468 0 64.5949 0 93.8734V246.873C0 276.152 23.8481 300 53.1266 300H246.873C276.152 300 300 276.152 300 246.873V93.8734C300 64.5949 276.152 40.7468 246.873 40.7468ZM273.418 246.873C273.418 261.494 261.494 273.418 246.873 273.418H53.1266C38.5063 273.418 26.5823 261.494 26.5823 246.873V93.8734C26.5823 79.2532 38.5063 67.3291 53.1266 67.3291H68.3544V81.1519C68.3544 88.481 74.3165 94.443 81.6456 94.443C88.9747 94.443 94.9367 88.481 94.9367 81.1519V67.3291H205.063V81.1519C205.063 88.481 211.025 94.443 218.354 94.443C225.684 94.443 231.646 88.481 231.646 81.1519V67.3291H246.873C261.494 67.3291 273.418 79.2532 273.418 93.8734V246.873Z"
      fill={color}
    />
  </SvgContainer>
));

export default DateEndIcon;
