import React from "react";
import { Link } from "react-router-dom";
import { Help, Logo, Save } from "@thrivelot/stories";
import { NavItem } from "./NavItem";
import { Dropdown } from "@thrivelot/stories";
import { useModelStatus } from "@thrivelot/hooks";
import { DropdownLinks } from "../DropdownLinks";
import { DropdownUser } from "./DropdownUser";

const HeaderNav = ({ helpOptions, navOptions, currentOption }) => {
  const { saving } = useModelStatus();

  // const itemMap = {
  //   customer: [<NavItem key={urlFolders.home} icon="Home" to={urlFolders.home} label="Home" />],
  // };

  // const helpMap = {
  //   customer: [
  //     {
  //       label: "🐞 Submit Bug or Feature Request",
  //       onClick: handleMarker,
  //     },
  //   ],
  // };

  return (
    <header className="text-gray-600 body-font shadow">
      <div className="container mx-auto flex flex-wrap p-4 items-center">
        <div className="flex-1 md:flex-0 flex">
          <Link to="/" className="flex title-font font-medium items-center text-gray-900">
            <Logo size={30} color="green-main" />
            <span className="text-green-main ml-3 text-xl">Thrive Lot</span>
            {saving && (
              <div className="animate-pulse ml-2 text-tan-main flex items-center">
                <Save size={16} color="tan-main" />
                <div className="hidden md:block ml-1">Saving...</div>
              </div>
            )}
          </Link>
        </div>
        <nav className="hidden md:flex md:ml-auto md:flex-wrap items-center text-base justify-center gap-5 mr-5">
          {navOptions.map(({ to, icon, label }) => (
            <NavItem key={to} icon={icon} to={to} label={label} />
          ))}
        </nav>
        <div className="block md:hidden mr-4">
          <DropdownLinks
            color="brown-dark"
            options={navOptions}
            pathMatch={({ location, to }) => to === location.pathname.split("/")[1]}
          />
        </div>
        <div className="flex items-center gap-4">
          <Dropdown align="right" label={<Help size={20} color="yellow-dark" />} options={helpOptions} />
          <DropdownUser />
        </div>
      </div>
    </header>
  );
};

export { HeaderNav };
