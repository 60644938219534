/* eslint-disable no-nested-ternary */
import React, { useState, Fragment, useCallback } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { LeafLoader, Cancel, File } from '@thrivelot/stories';

export const Layout = ({
  isOpen: externalIsOpen,
  setIsOpen: setExternalIsOpen,
  onClose = () => null,
  dialogCloseDisabled,
  closeDisabled,
  header,
  subheader,
  loading,
  footer,
  children,
}) => {
  const [localIsOpen, setLocalIsOpen] = useState(true);
  const [viewingCaption, setViewingCaption] = useState(false);

  const handleClose = useCallback(() => {
    setLocalIsOpen(false);
    if (setExternalIsOpen) setExternalIsOpen(false);
    onClose();
  }, [setLocalIsOpen, setExternalIsOpen, onClose]);

  return (
    <Transition
      appear
      show={setExternalIsOpen ? externalIsOpen : localIsOpen}
      as={Fragment}
      // afterLeave={onClose}
    >
      <Dialog
        as="div"
        className="fixed inset-0 z-20 overflow-y-auto"
        onClose={() => !dialogCloseDisabled && !closeDisabled && handleClose()}
      >
        <div className="min-h-screen px-4 text-center">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-mulch-dark opacity-95" />
          </Transition.Child>
          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="inline-block h-screen align-middle"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="absolute inset-0 overflow-hidden transition-all transform">
              <div className="absolute top-[72px] right-0 bottom-[85px] left-0">
                {loading ? <LeafLoader /> : children}
                {viewingCaption && (
                  <div className="absolute inset-0 p-6 bg-mulch-dark overflow-y-scroll text-left">
                    <div className="text-2xl text-white-light font-bold mb-4">
                      {header}
                    </div>
                    <div className="text-white-light whitespace-pre-wrap">
                      {subheader}
                    </div>
                  </div>
                )}
              </div>
              <div className="absolute top-0 right-0 left-0 h-[72px] p-6 text-left border-b border-b-white-light bg-mulch-dark select-none">
                <div className="flex items-center justify-between gap-3">
                  {!viewingCaption && (
                    <div
                      className="font-bold text-white-light truncate cursor-pointer"
                      onClick={() => setViewingCaption(true)}
                    >
                      {!viewingCaption && header}
                      {subheader && !viewingCaption && (
                        <span className="font-normal italic ml-1 text-sm">
                          - {subheader}
                        </span>
                      )}
                    </div>
                  )}
                  {viewingCaption && (
                    <div
                      className="flex items-center gap-2 cursor-pointer font-bold text-white-light"
                      onClick={() => setViewingCaption(false)}
                    >
                      <File size={20} color="white-light" />
                      View File
                    </div>
                  )}
                  {!closeDisabled && (
                    <div className="hover:cursor-pointer" onClick={handleClose}>
                      <Cancel color="white-light" size={20} />
                    </div>
                  )}
                </div>
              </div>
              <div className="flex items-center absolute right-0 bottom-0 left-0 p-6 h-[85px] border-t border-t-white-light bg-mulch-dark">
                {footer}
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
};
