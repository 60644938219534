import { duplicateObject } from "@thrivelot/utils";

const sortComplex = ({
  arr,
  by = "name",
  backupBy = null,
  nestedBy = null,
  backupNestedBy = null,
  nestedByIndex = -1,
  ascending = true,
  ofTypeString = false,
}) => {
  const compare = (a, b) => {
    let comparison = 0;
    let aPrime = "ZZZZZZ";
    let bPrime = "ZZZZZZ";
    if (
      nestedByIndex !== -1 &&
      a[nestedBy[0]] &&
      a[nestedBy[0]][nestedByIndex] &&
      a[nestedBy[0]][nestedByIndex][nestedBy[1]] &&
      a[nestedBy[0]][nestedByIndex][nestedBy[1]].trim() !== ""
    )
      aPrime = a[nestedBy[0]][nestedByIndex][nestedBy[1]].trim().toUpperCase();
    else if (nestedBy && a[nestedBy[0]] && a[nestedBy[0]][nestedBy[1]] && a[nestedBy[0]][nestedBy[1]].trim() !== "")
      aPrime = a[nestedBy[0]][nestedBy[1]].trim().toUpperCase();
    else if (
      backupNestedBy &&
      a[backupNestedBy[0]] &&
      a[backupNestedBy[0]][backupNestedBy[1]] &&
      a[backupNestedBy[0]][backupNestedBy[1]].trim() !== ""
    )
      aPrime = a[backupNestedBy[0]][backupNestedBy[1]].trim().toUpperCase();
    else if (ofTypeString) aPrime = a.trim().toUpperCase();
    else if (a[by] && a[by].trim() !== "") aPrime = a[by].trim().toUpperCase();
    else if (backupBy && a[backupBy] && a[backupBy].trim() !== "") aPrime = a[backupBy].trim().toUpperCase();

    if (
      nestedByIndex !== -1 &&
      b[nestedBy[0]] &&
      b[nestedBy[0]][nestedByIndex] &&
      b[nestedBy[0]][nestedByIndex][nestedBy[1]] &&
      b[nestedBy[0]][nestedByIndex][nestedBy[1]].trim() !== ""
    )
      bPrime = b[nestedBy[0]][nestedByIndex][nestedBy[1]].trim().toUpperCase();
    else if (nestedBy && b[nestedBy[0]] && b[nestedBy[0]][nestedBy[1]] && b[nestedBy[0]][nestedBy[1]].trim() !== "")
      bPrime = b[nestedBy[0]][nestedBy[1]].trim().toUpperCase();
    else if (
      backupNestedBy &&
      b[backupNestedBy[0]] &&
      b[backupNestedBy[0]][backupNestedBy[1]] &&
      b[backupNestedBy[0]][backupNestedBy[1]].trim() !== ""
    )
      bPrime = b[backupNestedBy[0]][backupNestedBy[1]].trim().toUpperCase();
    else if (ofTypeString) bPrime = b.trim().toUpperCase();
    else if (b[by] && b[by].trim() !== "") bPrime = b[by].trim().toUpperCase();
    else if (backupBy && b[backupBy] && b[backupBy].trim() !== "") bPrime = b[backupBy].trim().toUpperCase();

    if (aPrime > bPrime) comparison = ascending ? 1 : -1;
    else if (aPrime < bPrime) comparison = ascending ? -1 : 1;
    return comparison;
  };

  const sortableArr = duplicateObject(arr);
  return sortableArr.sort(compare);
};

export { sortComplex };
