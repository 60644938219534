import { getPlantFromDatabase } from "./getPlantFromDatabase";

const fetchPlantFromDatabase = async (id) => {
  try {
    return await getPlantFromDatabase(id);
  } catch (err) {
    console.log("Fetch plant error:", JSON.stringify(err, null, 2));

    return null;
  }
};

export { fetchPlantFromDatabase };
