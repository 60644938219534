import React from 'react';

const monthMap = {
  January: 'Jan',
  February: 'Feb',
  March: 'Mar',
  April: 'Apr',
  May: 'May',
  June: 'Jun',
  July: 'Jul',
  August: 'Aug',
  September: 'Sep',
  October: 'Oct',
  November: 'Nov',
  December: 'Dec',
};

export const MonthSection = ({ title, content }) => (
  <div>
    <div className="text-mulch-dark mb-2">{title}</div>
    <div className="flex flex-wrap items-center gap-2">
      {content.map((item) => (
        <div
          key={item}
          className="rounded flex items-center justify-center px-3 py-1 bg-tan-light text-mulch-dark font-bold text-sm shadow-outer"
        >
          {monthMap[item]}
        </div>
      ))}
    </div>
  </div>
);
