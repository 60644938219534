import React from 'react';

const Labor = ({
  color = 'green-main',
  size = 30,
  styleProps = '',
  ...rest
}) => (
  <svg
    width={size.toString()}
    height={size.toString()}
    viewBox="0 0 75 75"
    className={`fill-${color} ${styleProps}`}
    {...rest}
  >
    <path d="M73.503 9.59217L65.4005 1.48985C63.9332 0.022604 61.7778 -0.3929 59.6353 0.38617C57.1682 1.2821 55.4412 3.48946 55.2205 6.03442L54.779 10.9685C54.5193 11.1503 54.2726 11.3581 54.0388 11.5918L31.9777 33.6524L26.5371 29.7051C24.0311 27.8873 20.7459 27.3809 17.5387 28.3418C13.864 29.4455 10.8385 32.2631 9.47513 35.8728L0.905187 58.5177C-0.899695 63.309 0.0352077 68.334 3.34632 71.645C6.65744 74.9691 11.6955 75.904 16.4739 74.0861L39.1323 65.5294C42.7421 64.166 45.5598 61.1406 46.6635 57.466C47.6114 54.2458 47.131 50.9737 45.3001 48.4677L41.3397 43.0272L63.4009 20.9536C63.6346 20.7329 63.8424 20.4862 64.0241 20.2265L68.9843 19.798C71.5034 19.5643 73.7108 17.8243 74.6197 15.3703C75.3858 13.2148 74.9833 11.0724 73.503 9.59217ZM22.0833 52.9084C21.1225 51.9476 24.7063 46.7668 30.2767 40.8848C30.5754 41.7418 31.0818 42.5338 31.77 43.222C32.4582 43.9102 33.2632 44.4166 34.1202 44.7282C28.2381 50.2985 23.0572 53.8823 22.0833 52.9084ZM60.6481 18.2009L39.0155 39.833L38.548 40.3005L38.3792 40.4693C38.2883 40.5732 38.1715 40.6641 38.0676 40.742C37.0028 41.521 35.4966 41.4301 34.5227 40.4693C33.5619 39.5084 33.471 38.0022 34.2631 36.9375C34.341 36.8206 34.4319 36.7168 34.5227 36.6129L35.159 35.9767L54.5323 16.6168L55.0776 16.0584L56.7916 14.3445C57.8564 13.2798 59.5833 13.2798 60.6481 14.3445C61.7128 15.4092 61.7128 17.1362 60.6481 18.2009Z" />
  </svg>
);

export { Labor };
