import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useMeasure } from 'react-use';
import {
  Calendar as ReactBigCalendar,
  dateFnsLocalizer,
} from 'react-big-calendar';
import {
  format,
  getDay,
  isSameDay,
  isToday,
  parse,
  parseISO,
  startOfDay,
  startOfWeek,
} from 'date-fns';
import enUS from 'date-fns/locale/en-US';
import 'react-big-calendar/lib/css/react-big-calendar.css';

import { Button } from './Button';
import { AngleLeft, AngleRight, Clipboard } from './icons';

import './index.css';
import './Calendar.css';

const locales = {
  'en-US': enUS,
};

const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek,
  getDay,
  locales,
});

const Header = ({ label }) => {
  const headerLabel = useMemo(() => {
    const splitLabel = label.split(' ')[0];
    return splitLabel.startsWith('0') ? splitLabel.slice(1) : splitLabel;
  }, [label]);
  return (
    <div className="text-center font-normal text-brown-light">
      {headerLabel}
    </div>
  );
};

const arrowStyleProps =
  'cursor-pointer hover:opacity-80 transition-all duration-500';

const ToolbarHeaderMap = {
  large: ({ navigate, label }) => (
    <div className="flex items-center">
      <AngleLeft
        size={18}
        styleProps={`${arrowStyleProps} mr-2`}
        onClick={navigate('PREV')}
      />
      <AngleRight
        size={18}
        styleProps={`${arrowStyleProps} mr-2`}
        onClick={navigate('NEXT')}
      />
      <div className="font-header text-mulch-dark text-xl ml-2 select-none">
        {label}
      </div>
    </div>
  ),
  small: ({ navigate, label }) => (
    <div className="flex items-center justify-between">
      <AngleLeft
        size={15}
        styleProps={arrowStyleProps}
        onClick={navigate('PREV')}
      />
      <div className="font-header text-mulch-dark text-sm mx-3 select-none">
        {label}
      </div>
      <AngleRight
        size={15}
        styleProps={arrowStyleProps}
        onClick={navigate('NEXT')}
      />
    </div>
  ),
};

const Toolbar = ({ label, date, view, onView, onNavigate }) => {
  const [ref, { width }] = useMeasure();
  const sizeMapKey = useMemo(() => (width < 767 ? 'small' : 'large'), [width]);

  const ToolbarHeader = useMemo(
    () => ToolbarHeaderMap[sizeMapKey],
    [sizeMapKey]
  );
  const wrapperClassName = useMemo(
    () =>
      `relative flex px-3 pt-3 ${
        sizeMapKey === 'small'
          ? 'flex-col justify-between pb-1'
          : 'flex-wrap items-center justify-between pb-3'
      }`,
    [sizeMapKey]
  );
  const buttonType = useMemo(
    () => (sizeMapKey === 'small' ? 'prompt' : 'outline'),
    [sizeMapKey]
  );
  const buttonWrapperClassName = useMemo(
    () => `flex ${sizeMapKey === 'small' ? 'justify-around mt-2' : ''}`,
    [sizeMapKey]
  );
  const getButtonColor = useCallback(
    (type) => (type === view ? 'green-main' : 'tan-dark'),
    [view]
  );

  const changeViews = (clickedView) => () => {
    if (clickedView === view) return;
    onView(clickedView);
  };

  const navigate = (to) => () => {
    onNavigate(to);
  };

  return (
    <div ref={ref} className={wrapperClassName}>
      <ToolbarHeader label={label} navigate={navigate} />
      <div className={buttonWrapperClassName}>
        <Button
          size="small"
          color={getButtonColor('month')}
          kind={buttonType}
          onClick={changeViews('month')}
        >
          Month
        </Button>
        <Button
          size="small"
          color={getButtonColor('week')}
          kind={buttonType}
          onClick={changeViews('week')}
        >
          Week
        </Button>
        <Button
          size="small"
          color={getButtonColor('day')}
          kind={buttonType}
          onClick={changeViews('day')}
        >
          Day
        </Button>
        <Button
          size="small"
          color={getButtonColor('agenda')}
          kind={buttonType}
          onClick={changeViews('agenda')}
        >
          Agenda
        </Button>
      </div>
    </div>
  );
};

const DateHeader =
  ({ context, selectable }) =>
  ({ date, label, isOffRange, onDrillDown }) => {
    const className = () => {
      let name = `flex ${
        selectable ? 'cursor-pointer ' : ''
      }justify-between items-center font-header py-1 pl-1 text-xs`;
      if (isToday(date) && isOffRange) name += ' text-green-main/50';
      else if (isToday(date)) name += ' text-green-main';
      else if (isOffRange) name += ' text-tan-main';
      else if (selectable && isSameDay(context.state.selectedDate, date))
        name += ' text-white-light';
      else name += ' text-mulch-dark';
      return name;
    };

    return (
      <div className={className()} onClick={onDrillDown}>
        {context.state.notes &&
        context.state.notes.find((notesDate) =>
          isSameDay(parseISO(notesDate), date)
        ) ? (
          <Clipboard
            color={
              isSameDay(context.state.selectedDate, date) && !isToday(date)
                ? 'white-light'
                : 'green-main'
            }
            size={15}
          />
        ) : (
          <div />
        )}
        <div>{label.startsWith('0') ? label.slice(1) : label}</div>
      </div>
    );
  };

const dayPropGetter =
  ({ context, selectable }) =>
  (date) => ({
    className:
      selectable &&
      isSameDay(context.state.selectedDate, date) &&
      (context.state.view === 'month' || context.state.view === 'week')
        ? 'rbc-selected-day-bg'
        : `rbc-day-bg${selectable ? ' cursor-pointer' : ''}`,
  });

/**
 * Calendar UI Component
 */
export const Calendar = ({
  context,
  selectable,
  onSelectEvent: externalOnSelectEvent,
}) => {
  const onNavigate = (newDate) => {
    context.setValue('selectedDate', startOfDay(newDate));
  };

  const onSelectSlot = (slotInfo) => {
    if (selectable)
      context.setValue('selectedDate', startOfDay(slotInfo.start));
  };

  const onSelectEvent = (event) => {
    if (externalOnSelectEvent) externalOnSelectEvent(event);
  };

  const onView = (view) => {
    context.setValue('view', view);
  };

  return (
    <div className={`w-full h-full`}>
      <ReactBigCalendar
        selectable
        view={context.state.view}
        date={context.state.selectedDate}
        localizer={localizer}
        events={context.state.events}
        onNavigate={onNavigate}
        onSelectSlot={onSelectSlot}
        onSelectEvent={onSelectEvent}
        onView={onView}
        startAccessor="start"
        endAccessor="end"
        dayPropGetter={dayPropGetter({ context, selectable })}
        components={{
          toolbar: Toolbar,
          day: { header: Header },
          week: { header: Header },
          month: {
            header: Header,
            dateHeader: DateHeader({ context, selectable }),
          },
        }}
        messages={{
          noEventsInRange: 'No phases in this date range.',
        }}
      />
    </div>
  );
};

Calendar.propTypes = {
  /**
   * Calendar context:
   * {
   *  selectedDate: Date,
   *  events: [
   *    { title: String, start: Date, end: Date, allDay: Bool }
   *  ],
   *  notes: [Date]
   * }
   */
  context: PropTypes.object,
};

Calendar.defaultProps = {
  context: {
    state: { selectedDate: new Date(), events: [], notes: [] },
    setValue: (name, value) => null,
  },
};
