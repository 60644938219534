// This hook fetches and filters all items used in all projects and bundles of which a particular user is an owner
// Its useful for importing items into a catalog or recycling items from a project or bundle into a new bundle

import { ModelKit } from '@thrivelot/model-kit';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useModelKit, useModelList } from '../useModel';

const normalizePlantItemIntoBundleLineItemResult = (item) => {
  const commonName = (
    item.changes.find((change) => change.key === 'commonName') || {}
  ).change;
  const scientificName = (
    item.changes.find((change) => change.key === 'scientificName') || {}
  ).change;
  const imageUrl = (
    item.changes.find((change) => change.key === 'imageUrl') || {}
  ).change;

  return {
    value: item.id,
    label: commonName || scientificName || 'No name...',
    source: 'ProjectPlantItem',
    item: {
      id: item.id,
      kind: 'plant',
      name: commonName || scientificName,
      description: item.notes,
      unit: 'plants',
      quantity: item.quantity,
      pricePerUnit: item.cost,
      data: { imageUrl },
    },
  };
};

const normalizeLineItemIntoBundleLineItemResult = (item) => {
  const typeKindMap = {
    MATERIAL: 'material',
    LABOR: 'labor',
    EQUIPMENT: 'equipment',
  };

  return {
    id: item.id,
    kind: typeKindMap[item.type],
    name: item.name,
    description: item.notes,
    unit: item.unit,
    quantity: item.quantity,
    pricePerUnit: item.cost,
    data: {},
  };
};

const getProjectItemsByType = (projects, type) =>
  projects?.flatMap((project) => project?.[type] || []) || [];

const allUniqueLineItemsFromProjects = (projects) => {
  const uniqueByNameResults = {};

  getProjectItemsByType(projects, 'lineItems').forEach((item) => {
    const result = normalizeLineItemIntoBundleLineItemResult(item);
    if (result.id && result.name) {
      uniqueByNameResults[result.name] = { ...result };
    }
  });

  getProjectItemsByType(projects, 'plantItems').forEach((item) => {
    const result = normalizePlantItemIntoBundleLineItemResult(item);
    if (result.id && result.name) {
      uniqueByNameResults[result.name] = { ...result };
    }
  });

  return uniqueByNameResults;
};

const allUniqueItemsFromBundles = (bundles) => {
  const uniqueByNameResults = {};

  bundles.forEach((bundle) => {
    (bundle.lineItems || []).forEach((item) => {
      if (item.id && item.name) {
        uniqueByNameResults[item.name] = item;
      }
    });
    (bundle.changeItems || []).forEach((item) => {
      if (item.id && item.name) {
        uniqueByNameResults[item.name] = item;
      }
    });
  });

  return uniqueByNameResults;
};

const useRecycleItems = (props = {}) => {
  const {
    ownerId: initialOwnerId,
    supplierId: initialSupplierId,
    resultsCount = 200,
  } = props;
  const [ownerId, setOwnerId] = useState(initialOwnerId);
  const [supplierId, setSupplierId] = useState(initialSupplierId);
  const { loaded: modelKitLoaded } = useModelKit();

  const {
    models: projects,
    queryModels: loadProjects,
    loading: loadingProjects,
    loaded: loadedProjects,
  } = useModelList({
    modelName: 'Project',
    deferLoad: true,
  });

  const {
    models: bundles,
    queryModels: loadBundles,
    loading: loadingBundles,
    loaded: loadedBundles,
  } = useModelList({
    modelName: 'Bundle',
    filter: { owners: { contains: ownerId } },
    deferLoad: true,
  });

  const [query, setQuery] = useState('');
  const [results, setResults] = useState([]);

  // This load function can be simple because deferring the load is just initializing this hook without supplierId or ownerId
  // And setting these values automatically triggers the loading change of useEffect hooks
  const load = useCallback(
    ({ ownerId: newOwnerId, supplierId: newSupplierId }) => {
      if (newOwnerId) setOwnerId(newOwnerId);
      if (newSupplierId) setSupplierId(newSupplierId);
    }
  );

  const allItems = useMemo(() => {
    const uniqueItemsResults = {
      ...allUniqueLineItemsFromProjects(projects),
      ...allUniqueItemsFromBundles(bundles),
    };
    return Object.values(uniqueItemsResults) || [];
  }, [projects, bundles]);

  const filterLineItems = useCallback(
    (staticQuery) => {
      if (staticQuery === '')
        return setResults(allItems.slice(0, resultsCount));

      return setResults(
        allItems
          .filter((item) =>
            item.name.toLowerCase().includes(staticQuery.toLowerCase())
          )
          .slice(0, resultsCount)
      );
    },
    [allItems, resultsCount]
  );

  useEffect(() => {
    // If the supplierId is given but not the ownerId, fetch the supplier and set the ownerId
    const handle = async () => {
      const supplierKit = new ModelKit({
        modelName: 'Supplier',
        id: supplierId,
      });
      const supplier = await supplierKit.query();
      setOwnerId(supplier?.owners[0]);
    };

    if (modelKitLoaded && !ownerId && supplierId) handle();
  }, [modelKitLoaded, ownerId, supplierId]);

  useEffect(() => {
    if (loadedProjects && loadedBundles) filterLineItems(query);
  }, [query, allItems]);

  useEffect(() => {
    if (ownerId) {
      loadProjects({ owners: { contains: ownerId } });
      loadBundles({ owners: { contains: ownerId } });
    }
  }, [ownerId]); // should load only once when the ownerId is set

  return {
    results,
    query,
    setQuery,
    load,
    loading: loadingProjects || loadingBundles,
    loaded: loadedProjects && loadedBundles,
  };
};

export { useRecycleItems };
