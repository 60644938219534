import { useInvoiceProposalBillingSummary } from "@thrivelot/common";
import { EmptyState, Tip, SingleBilling, LeafLoader } from "@thrivelot/stories";
import { transformCurrencyToString } from "@thrivelot/utils";
import React, { useMemo } from "react";
import { CardPaymentPayout } from "./CardPaymentPayout";
import { kindMap } from "./kindMap";

const BillingSummary = ({ projcetId, invoiceProposalId }) => {
	const {
		invoiceProposal,
		billingSummary: billing,
		loaded: billingLoaded,
	} = useInvoiceProposalBillingSummary(invoiceProposalId);

	const totals = useMemo(() => {
		if (!billing?.items) return {};
		const result = {
			linePriceSubtotal: 0,
			lineHouseSubtotal: 0,
			lineTotalAmount: 0,
			changePriceSubtotal: 0,
			changeHouseSubtotal: 0,
			changeTotalAmount: 0,
			discountPriceSubtotal: 0,
			discountHouseSubtotal: 0,
			discountTotalAmount: 0,
			priceSubtotal: 0,
			houseSubtotal: 0,
			totalAmount: 0,
		};

		billing?.items.forEach((item) => {
			result[`${item.type}PriceSubtotal`] += item.priceSubtotal;
			result[`${item.type}HouseSubtotal`] += item.houseSubtotal;
			result[`${item.type}TotalAmount`] += item.totalAmount;

			result.priceSubtotal += item.priceSubtotal;
			result.houseSubtotal += item.houseSubtotal;
			result.totalAmount += item.totalAmount;
		});

		return result;
	}, [billing?.items]);

	if (!billingLoaded)
		return (
			<div>
				<div className="flex-1 font-bold text-brown-dark mb-2 text-2xl">
					Billing Summary
				</div>
				<LeafLoader />
			</div>
		);

	if ((billing?.payments || []).length <= 0)
		return (
			<div>
				<div className="flex">
					<div className="flex-1 font-bold text-brown-dark mb-2 text-2xl">
						Billing Summary
					</div>
					{invoiceProposal && (
						<div>{kindMap[invoiceProposal.kind].label} Billing</div>
					)}
				</div>
				<EmptyState>No billing summary to show yet</EmptyState>
			</div>
		);

	return (
		<div>
			<div className="flex">
				<div className="flex-1 font-bold text-brown-dark mb-2 text-2xl">
					Billing Summary
				</div>
				{invoiceProposal && (
					<div>{kindMap[invoiceProposal.kind].label} Billing</div>
				)}
			</div>
			<div className="font-bold">Order Summary</div>
			<div className="divide-y divide-tan-light mt-2">
				<div className="flex justify-between py-1">
					<div>Price Subtotal</div>
					<div>
						{Math.abs(totals.changePriceSubtotal) > 0 && (
							<span className="text-sm mx-2">
								({transformCurrencyToString(totals.linePriceSubtotal)}{" "}
								<span className="text-blue-main">
									{totals.changePriceSubtotal > 0 ? "+" : "-"}{" "}
									{transformCurrencyToString(
										Math.abs(totals.changePriceSubtotal),
									)}
								</span>
								)
							</span>
						)}
						{transformCurrencyToString(totals.priceSubtotal)}
					</div>
				</div>
				<div className="flex justify-between py-1">
					<div>ThriveLot Markup</div>
					<div>
						{Math.abs(totals.changeHouseSubtotal) > 0 && (
							<span className="text-sm mx-2">
								({transformCurrencyToString(totals.lineHouseSubtotal)}{" "}
								<span className="text-blue-main">
									{totals.changeHouseSubtotal > 0 ? "+" : "-"}{" "}
									{transformCurrencyToString(
										Math.abs(totals.changeHouseSubtotal),
									)}
								</span>
								)
							</span>
						)}
						{transformCurrencyToString(totals.houseSubtotal)}
					</div>
				</div>
				<div className="flex justify-between text-green-main py-1">
					<div>Order Total</div>
					<div>
						{Math.abs(totals.changeTotalAmount) > 0 && (
							<span className="text-sm mx-2 text-brown-dark">
								({transformCurrencyToString(totals.lineTotalAmount)}{" "}
								<span className="text-blue-main">
									{totals.changeTotalAmount > 0 ? "+" : "-"}{" "}
									{transformCurrencyToString(
										Math.abs(totals.changeTotalAmount),
									)}
								</span>
								)
							</span>
						)}
						<span>{transformCurrencyToString(totals.totalAmount)}</span>
					</div>
				</div>
			</div>
			<div className="text-brown-dark mb-4 flex items-center gap-2 pt-8">
				<SingleBilling color="brown-dark" size={16} />{" "}
				<span className="font-bold">Payments</span> will be in
				<span className="font-bold">
					{billing.payments.length} installment
					{billing.payments.length > 1 ? "s" : ""}
				</span>{" "}
			</div>
			<div className="grid grid-cols-1 lg:grid-cols-2 gap-4 mt-2">
				{billing.payments.map((payment) => (
					<CardPaymentPayout
						key={payment.label}
						payment={payment}
						payouts={billing.payouts}
					/>
				))}
			</div>
		</div>
	);
};

export { BillingSummary };
