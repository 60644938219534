import styled from "styled-components";
import CssInsetShadow from "./CssInsetShadow";
import CssDivDefault from "./CssDivDefault";

const DivInset = styled.div`
  ${CssDivDefault}
  ${CssInsetShadow}
  border-radius: 5px;
  padding: 12px;
  flex-direction: column;
`;

export default DivInset;
