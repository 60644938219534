import styled from "styled-components";
import CssRaisedShadow from "./CssRaisedShadow";

const DivPill = styled.div`
  ${CssRaisedShadow}

  display: inline-block;
  text-align: center;
  padding: 2px 12px;
  border-radius: 13px;
  font-family: Poppins-Bold;
  background-color: ${({ color }) => color};
  color: ${({ theme }) => theme.foreground};
`;

export default DivPill;
