/* eslint-disable no-nested-ternary */
import React from 'react';
import { AngleDown, Dropdown } from '@thrivelot/stories';
import { transformStringToTitleCase } from '@thrivelot/utils';

export const DropdownWithLabel = ({
  name,
  label,
  placeholder,
  value,
  options,
  capitalize = true,
}) => (
  <div className="mb-4">
    <label
      htmlFor={name}
      className="block font-accent text-mulch-dark text-sm ml-2 mb-0.5"
    >
      {label}
    </label>
    <Dropdown
      labelWrapperClassAppend="w-full"
      label={
        <div className="flex justify-between items-center border border-tan-light hover:ring-1 hover:ring-tan-light focus:border-tan-dark focus:ring-1 focus:ring-tan-dark bg-white-dark w-full px-2 py-2 outline-none rounded shadow-inset transition-all duration-300">
          <div
            className={`${
              value ? 'text-brown-dark' : 'italic text-brown-light'
            }`}
          >
            {value
              ? capitalize
                ? transformStringToTitleCase(value)
                : value
              : placeholder}
          </div>
          <AngleDown size={16} />
        </div>
      }
      optionClass="font-bold"
      align="left"
      options={options}
    />
  </div>
);
