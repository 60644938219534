import React, { useCallback, useMemo, useState } from 'react';
import { transformCurrencyToFloat } from '@thrivelot/utils';
import { Cancel, Edit, Minus } from '@thrivelot/stories';
import { InputGroupLineItemFull } from '../..';
import { RowItemCatalog } from '../../RowItemCatalog';

const RowListItemsToAdd = ({ formState, setFormState }) => {
  const [editingItem, setEditingItem] = useState();

  const handleRemove = useCallback(
    (itemId) => {
      setFormState((prevFormState) => {
        const newItems = [...prevFormState.items];
        const index = newItems.findIndex((item) => item.id === itemId);
        newItems.splice(index, 1);
        return { ...prevFormState, items: newItems };
      });
    },
    [setFormState]
  );

  const handleUpdate = useCallback(
    (itemId, update) => {
      setFormState((prevFormState) => {
        const newItems = [...prevFormState.items];
        const index = newItems.findIndex((item) => item.id === itemId);
        newItems[index] = { ...newItems[index], ...update };
        return { ...prevFormState, items: newItems };
      });
    },
    [setFormState]
  );

  const lineItemTotal = useMemo(() => {
    let result = 0;
    formState.items.forEach((item) => {
      result += item.pricePerUnit * item.quantity;
    });
    return result;
  }, [formState.items]);

  return (
    <div className="mt-8 pt-6 border-t-2 border-tan-light">
      <div className="flex justify-between">
        <div className="text-brown-dark font-bold mb-2">
          {formState.items.length} line items ready to add
        </div>
        <div className="text-green-main font-bold">
          ${transformCurrencyToFloat(lineItemTotal).toString()}
        </div>
      </div>
      <div className="divide-y divide-tan-light">
        {formState.items.map((item) => (
          <div key={item.id}>
            {editingItem === item.id ? (
              <div className="py-2">
                <InputGroupLineItemFull
                  formState={item}
                  setFormState={(newItem) => handleUpdate(item.id, newItem)}
                  actions={
                    <div className="flex gap-2">
                      <Cancel
                        color="yellow-main"
                        size={20}
                        styleProps="hover:cursor-pointer"
                        onClick={() => setEditingItem()}
                      />
                      <Minus
                        color="red-main"
                        size={20}
                        styleProps="hover:cursor-pointer"
                        onClick={() => handleRemove(item.id)}
                      />
                    </div>
                  }
                />
              </div>
            ) : (
              <RowItemCatalog
                item={item}
                actions={
                  <div className="flex gap-2">
                    <Edit
                      color="yellow-main"
                      size={20}
                      styleProps="hover:cursor-pointer"
                      onClick={() => setEditingItem(item.id)}
                    />
                    <Minus
                      color="red-main"
                      size={20}
                      styleProps="hover:cursor-pointer"
                      onClick={() => handleRemove(item.id)}
                    />
                  </div>
                }
              />
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export { RowListItemsToAdd };
