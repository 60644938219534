import { LeafLoader } from "@thrivelot/stories";
import React, { useEffect } from "react";

const LayoutSuspense = () => {
  // The bg needs to be set on the document.body otherwise
  // you can see the wrong color when scrolling up and down
  useEffect(() => {
    document.body.classList.add("!bg-tan-light");

    return () => {
      document.body.classList.remove("!bg-tan-light");
    };
  }, []);

  return (
    <div className="flex flex-col h-screen bg-white-dark items-center justify-center">
      <LeafLoader />
    </div>
  );
};

export { LayoutSuspense };
