import styled from "styled-components";
import CssTextDefault from "./CssTextDefault";

const labelWidthConstructor = ({ labelWidth }) => `${labelWidth}px`;

const TextRowLabel = styled.span`
  ${CssTextDefault}
  font-family: Rokkitt-ExtraBold;
  color: ${({ theme }) => theme.textLabel};
  display: flex;
  justify-content: flex-end;
  margin-right: 12px;
  min-width: ${labelWidthConstructor};
  max-width: ${labelWidthConstructor};
  @media (max-width: 767px) {
    font-size: 14px;
  }
  @media (min-width: 768px) {
    font-size: 17px;
  }
`;

export default TextRowLabel;
