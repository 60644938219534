import { useLocation, useNavigate } from 'react-router-dom';
import { urlFolders, urlHashes, urlModalValues } from '@thrivelot/config';
import { useHash } from '../useHash';

const getHash = (hash) => {
  let hashstring = '';

  Object.keys(hash)
    .filter((key) => key !== '')
    .forEach((key, index) => {
      hashstring += `${index !== 0 ? ',' : '#'}${key}`;
      if (hash[key] !== null && hash[key] !== true) {
        hashstring += `=${hash[key]}`;
      }
    });

  return hashstring;
};

const getPathname = (folders) => {
  let pathname = '';

  folders.forEach((item) => {
    pathname += `/${item}`;
  });

  return pathname;
};

const getSearch = (query) => {
  let search = '';

  Object.keys(query)
    .filter((key) => key !== '')
    .forEach((key, index) => {
      search += `${index !== 0 ? '&' : '?'}${key}=${query[key]}`;
    });

  return search;
};

const constructUrl = ({ folders = [], query = {}, hash = {} }) => {
  const location = {};

  if (folders.length !== 0) {
    const pathname = getPathname(folders);
    location.pathname = pathname;
  }

  const search = getSearch(query);
  location.search = search;

  const hashstring = getHash(hash);
  location.hash = hashstring;

  return location;
};

export default constructUrl;

const stringifyUrlObj = (urlObj, currentHash, keepHash) => {
  const hash = keepHash ? { ...currentHash, ...urlObj.hash } : urlObj.hash;
  const urlObjWithHash = { ...urlObj, hash };
  const url = constructUrl(urlObjWithHash);
  return url;
};

const useRouter = () => {
  const currentHash = useHash();
  const location = useLocation();
  const navigate = useNavigate();

  const push = ({ folders = [], query = {}, hash = {} }, keepHash = false) => {
    const urlObj = { folders, query, hash };
    const url = stringifyUrlObj(urlObj, currentHash, keepHash);
    navigate(url);
  };

  const replace = (
    { folders = [], query = {}, hash = {} },
    keepHash = false
  ) => {
    const urlObj = { folders, query, hash };
    const url = stringifyUrlObj(urlObj, currentHash, keepHash);
    navigate.replace(url);
  };

  const go = (historyIndex) => {
    navigate.go(historyIndex);
  };

  const goBack = () => {
    navigate.goBack();
  };

  const goForward = () => {
    navigate.goForward();
  };

  const block = (prompt) => {
    navigate.block(prompt);
  };

  const action = (text) => {
    navigate.action(text);
  };

  const createHref = (location) => {
    navigate.createHref(location);
  };

  const { length } = navigate;

  return {
    location,
    hash: currentHash,
    history: {
      push,
      replace,
      go,
      goBack,
      goForward,
      block,
      action,
      createHref,
      length,
    },
    urlFolders,
    urlHashes,
    urlModalValues,
  };
};

export { useRouter };
