/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.

export type CreatePlantInput = {
  id?: string | null,
  commonName?: string | null,
  familyCommonName?: string | null,
  scientificName?: string | null,
  family?: string | null,
  genus?: string | null,
  species?: string | null,
  otherCommonNames?: string | null,
  synonyms?: string | null,
  edibleParts?: string | null,
  form?: PlantForm | null,
  habit?: string | null,
  growingMonths?: string | null,
  bloomMonths?: string | null,
  fruitingMonths?: string | null,
  growthRate?: PlantGrowthRate | null,
  growingDescription?: string | null,
  averageHeightMm?: number | null,
  maximumHeightMm?: number | null,
  flowerColors?: string | null,
  flowerConspicuous?: boolean | null,
  foliageColors?: string | null,
  foliageTexture?: PlantFoliageTexture | null,
  foliageConspicuous?: boolean | null,
  fruitOrSeedColors?: string | null,
  fruitOrSeedConspicuous?: boolean | null,
  daysToHarvest?: number | null,
  sowingDescription?: string | null,
  rowSpacingMm?: number | null,
  spreadMm?: number | null,
  minimumRootDepthMm?: number | null,
  anaerobicTolerance?: PlantAnaerobicTolerance | null,
  light?: number | null,
  soilHumidity?: number | null,
  soilNutriments?: number | null,
  soilSalinity?: number | null,
  atmosphericHumidity?: number | null,
  phMinimum?: number | null,
  phMaximum?: number | null,
  distributions?: string | null,
  links?: PlantLinksInput | null,
  images?: Array< PlantImageInput | null > | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  updatedMeta?: UpdatedMetaInput | null,
};

export enum PlantForm {
  bunch = "bunch",
  colonizing = "colonizing",
  erect = "erect",
  multiple_stem = "multiple_stem",
  rhizomatous = "rhizomatous",
  single_crown = "single_crown",
  single_stem = "single_stem",
  stoloniferous = "stoloniferous",
  thicket_forming = "thicket_forming",
}


export enum PlantGrowthRate {
  moderate = "moderate",
  rapid = "rapid",
  slow = "slow",
}


export enum PlantFoliageTexture {
  coarse = "coarse",
  fine = "fine",
  medium = "medium",
}


export enum PlantAnaerobicTolerance {
  high = "high",
  low = "low",
  medium = "medium",
  none = "none",
}


export type PlantLinksInput = {
  usda?: string | null,
  tropicos?: string | null,
  telaBotanica?: string | null,
  powo?: string | null,
  plantnet?: string | null,
  gbif?: string | null,
  openfarm?: string | null,
  catminat?: string | null,
  wikipediaEn?: string | null,
};

export type PlantImageInput = {
  id?: string | null,
  bucket?: string | null,
  region?: string | null,
  key?: string | null,
  url?: string | null,
};

export type UpdatedMetaInput = {
  id?: string | null,
  userCognitoId?: string | null,
  userId?: string | null,
  userName?: string | null,
  userEmail?: string | null,
  userGroups?: string | null,
  device?: string | null,
};

export type ModelPlantConditionInput = {
  commonName?: ModelStringInput | null,
  familyCommonName?: ModelStringInput | null,
  scientificName?: ModelStringInput | null,
  family?: ModelStringInput | null,
  genus?: ModelStringInput | null,
  species?: ModelStringInput | null,
  otherCommonNames?: ModelStringInput | null,
  synonyms?: ModelStringInput | null,
  edibleParts?: ModelStringInput | null,
  form?: ModelPlantFormInput | null,
  habit?: ModelStringInput | null,
  growingMonths?: ModelStringInput | null,
  bloomMonths?: ModelStringInput | null,
  fruitingMonths?: ModelStringInput | null,
  growthRate?: ModelPlantGrowthRateInput | null,
  growingDescription?: ModelStringInput | null,
  averageHeightMm?: ModelIntInput | null,
  maximumHeightMm?: ModelIntInput | null,
  flowerColors?: ModelStringInput | null,
  flowerConspicuous?: ModelBooleanInput | null,
  foliageColors?: ModelStringInput | null,
  foliageTexture?: ModelPlantFoliageTextureInput | null,
  foliageConspicuous?: ModelBooleanInput | null,
  fruitOrSeedColors?: ModelStringInput | null,
  fruitOrSeedConspicuous?: ModelBooleanInput | null,
  daysToHarvest?: ModelIntInput | null,
  sowingDescription?: ModelStringInput | null,
  rowSpacingMm?: ModelIntInput | null,
  spreadMm?: ModelIntInput | null,
  minimumRootDepthMm?: ModelIntInput | null,
  anaerobicTolerance?: ModelPlantAnaerobicToleranceInput | null,
  light?: ModelIntInput | null,
  soilHumidity?: ModelIntInput | null,
  soilNutriments?: ModelIntInput | null,
  soilSalinity?: ModelIntInput | null,
  atmosphericHumidity?: ModelIntInput | null,
  phMinimum?: ModelFloatInput | null,
  phMaximum?: ModelFloatInput | null,
  distributions?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelPlantConditionInput | null > | null,
  or?: Array< ModelPlantConditionInput | null > | null,
  not?: ModelPlantConditionInput | null,
};

export type ModelStringInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export enum ModelAttributeTypes {
  binary = "binary",
  binarySet = "binarySet",
  bool = "bool",
  list = "list",
  map = "map",
  number = "number",
  numberSet = "numberSet",
  string = "string",
  stringSet = "stringSet",
  _null = "_null",
}


export type ModelSizeInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
};

export type ModelPlantFormInput = {
  eq?: PlantForm | null,
  ne?: PlantForm | null,
};

export type ModelPlantGrowthRateInput = {
  eq?: PlantGrowthRate | null,
  ne?: PlantGrowthRate | null,
};

export type ModelIntInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type ModelBooleanInput = {
  ne?: boolean | null,
  eq?: boolean | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type ModelPlantFoliageTextureInput = {
  eq?: PlantFoliageTexture | null,
  ne?: PlantFoliageTexture | null,
};

export type ModelPlantAnaerobicToleranceInput = {
  eq?: PlantAnaerobicTolerance | null,
  ne?: PlantAnaerobicTolerance | null,
};

export type ModelFloatInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type Plant = {
  __typename: "Plant",
  id: string,
  commonName?: string | null,
  familyCommonName?: string | null,
  scientificName?: string | null,
  family?: string | null,
  genus?: string | null,
  species?: string | null,
  otherCommonNames?: string | null,
  synonyms?: string | null,
  edibleParts?: string | null,
  form?: PlantForm | null,
  habit?: string | null,
  growingMonths?: string | null,
  bloomMonths?: string | null,
  fruitingMonths?: string | null,
  growthRate?: PlantGrowthRate | null,
  growingDescription?: string | null,
  averageHeightMm?: number | null,
  maximumHeightMm?: number | null,
  flowerColors?: string | null,
  flowerConspicuous?: boolean | null,
  foliageColors?: string | null,
  foliageTexture?: PlantFoliageTexture | null,
  foliageConspicuous?: boolean | null,
  fruitOrSeedColors?: string | null,
  fruitOrSeedConspicuous?: boolean | null,
  daysToHarvest?: number | null,
  sowingDescription?: string | null,
  rowSpacingMm?: number | null,
  spreadMm?: number | null,
  minimumRootDepthMm?: number | null,
  anaerobicTolerance?: PlantAnaerobicTolerance | null,
  light?: number | null,
  soilHumidity?: number | null,
  soilNutriments?: number | null,
  soilSalinity?: number | null,
  atmosphericHumidity?: number | null,
  phMinimum?: number | null,
  phMaximum?: number | null,
  distributions?: string | null,
  links?: PlantLinks | null,
  images?:  Array<PlantImage | null > | null,
  createdAt: string,
  updatedAt: string,
  updatedMeta?: UpdatedMeta | null,
};

export type PlantLinks = {
  __typename: "PlantLinks",
  usda?: string | null,
  tropicos?: string | null,
  telaBotanica?: string | null,
  powo?: string | null,
  plantnet?: string | null,
  gbif?: string | null,
  openfarm?: string | null,
  catminat?: string | null,
  wikipediaEn?: string | null,
};

export type PlantImage = {
  __typename: "PlantImage",
  id: string,
  bucket?: string | null,
  region?: string | null,
  key?: string | null,
  url?: string | null,
};

export type UpdatedMeta = {
  __typename: "UpdatedMeta",
  id: string,
  userCognitoId?: string | null,
  userId?: string | null,
  userName?: string | null,
  userEmail?: string | null,
  userGroups?: string | null,
  device?: string | null,
};

export type UpdatePlantInput = {
  id: string,
  commonName?: string | null,
  familyCommonName?: string | null,
  scientificName?: string | null,
  family?: string | null,
  genus?: string | null,
  species?: string | null,
  otherCommonNames?: string | null,
  synonyms?: string | null,
  edibleParts?: string | null,
  form?: PlantForm | null,
  habit?: string | null,
  growingMonths?: string | null,
  bloomMonths?: string | null,
  fruitingMonths?: string | null,
  growthRate?: PlantGrowthRate | null,
  growingDescription?: string | null,
  averageHeightMm?: number | null,
  maximumHeightMm?: number | null,
  flowerColors?: string | null,
  flowerConspicuous?: boolean | null,
  foliageColors?: string | null,
  foliageTexture?: PlantFoliageTexture | null,
  foliageConspicuous?: boolean | null,
  fruitOrSeedColors?: string | null,
  fruitOrSeedConspicuous?: boolean | null,
  daysToHarvest?: number | null,
  sowingDescription?: string | null,
  rowSpacingMm?: number | null,
  spreadMm?: number | null,
  minimumRootDepthMm?: number | null,
  anaerobicTolerance?: PlantAnaerobicTolerance | null,
  light?: number | null,
  soilHumidity?: number | null,
  soilNutriments?: number | null,
  soilSalinity?: number | null,
  atmosphericHumidity?: number | null,
  phMinimum?: number | null,
  phMaximum?: number | null,
  distributions?: string | null,
  links?: PlantLinksInput | null,
  images?: Array< PlantImageInput | null > | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  updatedMeta?: UpdatedMetaInput | null,
};

export type DeletePlantInput = {
  id: string,
};

export type ModelPlantFilterInput = {
  id?: ModelIDInput | null,
  commonName?: ModelStringInput | null,
  familyCommonName?: ModelStringInput | null,
  scientificName?: ModelStringInput | null,
  family?: ModelStringInput | null,
  genus?: ModelStringInput | null,
  species?: ModelStringInput | null,
  otherCommonNames?: ModelStringInput | null,
  synonyms?: ModelStringInput | null,
  edibleParts?: ModelStringInput | null,
  form?: ModelPlantFormInput | null,
  habit?: ModelStringInput | null,
  growingMonths?: ModelStringInput | null,
  bloomMonths?: ModelStringInput | null,
  fruitingMonths?: ModelStringInput | null,
  growthRate?: ModelPlantGrowthRateInput | null,
  growingDescription?: ModelStringInput | null,
  averageHeightMm?: ModelIntInput | null,
  maximumHeightMm?: ModelIntInput | null,
  flowerColors?: ModelStringInput | null,
  flowerConspicuous?: ModelBooleanInput | null,
  foliageColors?: ModelStringInput | null,
  foliageTexture?: ModelPlantFoliageTextureInput | null,
  foliageConspicuous?: ModelBooleanInput | null,
  fruitOrSeedColors?: ModelStringInput | null,
  fruitOrSeedConspicuous?: ModelBooleanInput | null,
  daysToHarvest?: ModelIntInput | null,
  sowingDescription?: ModelStringInput | null,
  rowSpacingMm?: ModelIntInput | null,
  spreadMm?: ModelIntInput | null,
  minimumRootDepthMm?: ModelIntInput | null,
  anaerobicTolerance?: ModelPlantAnaerobicToleranceInput | null,
  light?: ModelIntInput | null,
  soilHumidity?: ModelIntInput | null,
  soilNutriments?: ModelIntInput | null,
  soilSalinity?: ModelIntInput | null,
  atmosphericHumidity?: ModelIntInput | null,
  phMinimum?: ModelFloatInput | null,
  phMaximum?: ModelFloatInput | null,
  distributions?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelPlantFilterInput | null > | null,
  or?: Array< ModelPlantFilterInput | null > | null,
  not?: ModelPlantFilterInput | null,
};

export type ModelIDInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export type ModelPlantConnection = {
  __typename: "ModelPlantConnection",
  items:  Array<Plant | null >,
  nextToken?: string | null,
};

export type SearchablePlantFilterInput = {
  id?: SearchableIDFilterInput | null,
  commonName?: SearchableStringFilterInput | null,
  familyCommonName?: SearchableStringFilterInput | null,
  scientificName?: SearchableStringFilterInput | null,
  family?: SearchableStringFilterInput | null,
  genus?: SearchableStringFilterInput | null,
  species?: SearchableStringFilterInput | null,
  otherCommonNames?: SearchableStringFilterInput | null,
  synonyms?: SearchableStringFilterInput | null,
  edibleParts?: SearchableStringFilterInput | null,
  habit?: SearchableStringFilterInput | null,
  growingMonths?: SearchableStringFilterInput | null,
  bloomMonths?: SearchableStringFilterInput | null,
  fruitingMonths?: SearchableStringFilterInput | null,
  growingDescription?: SearchableStringFilterInput | null,
  averageHeightMm?: SearchableIntFilterInput | null,
  maximumHeightMm?: SearchableIntFilterInput | null,
  flowerColors?: SearchableStringFilterInput | null,
  flowerConspicuous?: SearchableBooleanFilterInput | null,
  foliageColors?: SearchableStringFilterInput | null,
  foliageConspicuous?: SearchableBooleanFilterInput | null,
  fruitOrSeedColors?: SearchableStringFilterInput | null,
  fruitOrSeedConspicuous?: SearchableBooleanFilterInput | null,
  daysToHarvest?: SearchableIntFilterInput | null,
  sowingDescription?: SearchableStringFilterInput | null,
  rowSpacingMm?: SearchableIntFilterInput | null,
  spreadMm?: SearchableIntFilterInput | null,
  minimumRootDepthMm?: SearchableIntFilterInput | null,
  light?: SearchableIntFilterInput | null,
  soilHumidity?: SearchableIntFilterInput | null,
  soilNutriments?: SearchableIntFilterInput | null,
  soilSalinity?: SearchableIntFilterInput | null,
  atmosphericHumidity?: SearchableIntFilterInput | null,
  phMinimum?: SearchableFloatFilterInput | null,
  phMaximum?: SearchableFloatFilterInput | null,
  distributions?: SearchableStringFilterInput | null,
  createdAt?: SearchableStringFilterInput | null,
  updatedAt?: SearchableStringFilterInput | null,
  form?: SearchableStringFilterInput | null,
  growthRate?: SearchableStringFilterInput | null,
  foliageTexture?: SearchableStringFilterInput | null,
  anaerobicTolerance?: SearchableStringFilterInput | null,
  and?: Array< SearchablePlantFilterInput | null > | null,
  or?: Array< SearchablePlantFilterInput | null > | null,
  not?: SearchablePlantFilterInput | null,
};

export type SearchableIDFilterInput = {
  ne?: string | null,
  gt?: string | null,
  lt?: string | null,
  gte?: string | null,
  lte?: string | null,
  eq?: string | null,
  match?: string | null,
  matchPhrase?: string | null,
  matchPhrasePrefix?: string | null,
  multiMatch?: string | null,
  exists?: boolean | null,
  wildcard?: string | null,
  regexp?: string | null,
  range?: Array< string | null > | null,
};

export type SearchableStringFilterInput = {
  ne?: string | null,
  gt?: string | null,
  lt?: string | null,
  gte?: string | null,
  lte?: string | null,
  eq?: string | null,
  match?: string | null,
  matchPhrase?: string | null,
  matchPhrasePrefix?: string | null,
  multiMatch?: string | null,
  exists?: boolean | null,
  wildcard?: string | null,
  regexp?: string | null,
  range?: Array< string | null > | null,
};

export type SearchableIntFilterInput = {
  ne?: number | null,
  gt?: number | null,
  lt?: number | null,
  gte?: number | null,
  lte?: number | null,
  eq?: number | null,
  range?: Array< number | null > | null,
};

export type SearchableBooleanFilterInput = {
  eq?: boolean | null,
  ne?: boolean | null,
};

export type SearchableFloatFilterInput = {
  ne?: number | null,
  gt?: number | null,
  lt?: number | null,
  gte?: number | null,
  lte?: number | null,
  eq?: number | null,
  range?: Array< number | null > | null,
};

export type SearchablePlantSortInput = {
  field?: SearchablePlantSortableFields | null,
  direction?: SearchableSortDirection | null,
};

export enum SearchablePlantSortableFields {
  id = "id",
  commonName = "commonName",
  familyCommonName = "familyCommonName",
  scientificName = "scientificName",
  family = "family",
  genus = "genus",
  species = "species",
  otherCommonNames = "otherCommonNames",
  synonyms = "synonyms",
  edibleParts = "edibleParts",
  habit = "habit",
  growingMonths = "growingMonths",
  bloomMonths = "bloomMonths",
  fruitingMonths = "fruitingMonths",
  growingDescription = "growingDescription",
  averageHeightMm = "averageHeightMm",
  maximumHeightMm = "maximumHeightMm",
  flowerColors = "flowerColors",
  flowerConspicuous = "flowerConspicuous",
  foliageColors = "foliageColors",
  foliageConspicuous = "foliageConspicuous",
  fruitOrSeedColors = "fruitOrSeedColors",
  fruitOrSeedConspicuous = "fruitOrSeedConspicuous",
  daysToHarvest = "daysToHarvest",
  sowingDescription = "sowingDescription",
  rowSpacingMm = "rowSpacingMm",
  spreadMm = "spreadMm",
  minimumRootDepthMm = "minimumRootDepthMm",
  light = "light",
  soilHumidity = "soilHumidity",
  soilNutriments = "soilNutriments",
  soilSalinity = "soilSalinity",
  atmosphericHumidity = "atmosphericHumidity",
  phMinimum = "phMinimum",
  phMaximum = "phMaximum",
  distributions = "distributions",
  createdAt = "createdAt",
  updatedAt = "updatedAt",
}


export enum SearchableSortDirection {
  asc = "asc",
  desc = "desc",
}


export type SearchablePlantAggregationInput = {
  name: string,
  type: SearchableAggregateType,
  field: SearchablePlantAggregateField,
};

export enum SearchableAggregateType {
  terms = "terms",
  avg = "avg",
  min = "min",
  max = "max",
  sum = "sum",
}


export enum SearchablePlantAggregateField {
  id = "id",
  commonName = "commonName",
  familyCommonName = "familyCommonName",
  scientificName = "scientificName",
  family = "family",
  genus = "genus",
  species = "species",
  otherCommonNames = "otherCommonNames",
  synonyms = "synonyms",
  edibleParts = "edibleParts",
  form = "form",
  habit = "habit",
  growingMonths = "growingMonths",
  bloomMonths = "bloomMonths",
  fruitingMonths = "fruitingMonths",
  growthRate = "growthRate",
  growingDescription = "growingDescription",
  averageHeightMm = "averageHeightMm",
  maximumHeightMm = "maximumHeightMm",
  flowerColors = "flowerColors",
  flowerConspicuous = "flowerConspicuous",
  foliageColors = "foliageColors",
  foliageTexture = "foliageTexture",
  foliageConspicuous = "foliageConspicuous",
  fruitOrSeedColors = "fruitOrSeedColors",
  fruitOrSeedConspicuous = "fruitOrSeedConspicuous",
  daysToHarvest = "daysToHarvest",
  sowingDescription = "sowingDescription",
  rowSpacingMm = "rowSpacingMm",
  spreadMm = "spreadMm",
  minimumRootDepthMm = "minimumRootDepthMm",
  anaerobicTolerance = "anaerobicTolerance",
  light = "light",
  soilHumidity = "soilHumidity",
  soilNutriments = "soilNutriments",
  soilSalinity = "soilSalinity",
  atmosphericHumidity = "atmosphericHumidity",
  phMinimum = "phMinimum",
  phMaximum = "phMaximum",
  distributions = "distributions",
  createdAt = "createdAt",
  updatedAt = "updatedAt",
}


export type SearchablePlantConnection = {
  __typename: "SearchablePlantConnection",
  items:  Array<Plant | null >,
  nextToken?: string | null,
  total?: number | null,
  aggregateItems:  Array<SearchableAggregateResult | null >,
};

export type SearchableAggregateResult = {
  __typename: "SearchableAggregateResult",
  name: string,
  result?: SearchableAggregateGenericResult | null,
};

export type SearchableAggregateGenericResult = SearchableAggregateScalarResult | SearchableAggregateBucketResult


export type SearchableAggregateScalarResult = {
  __typename: "SearchableAggregateScalarResult",
  value: number,
};

export type SearchableAggregateBucketResult = {
  __typename: "SearchableAggregateBucketResult",
  buckets?:  Array<SearchableAggregateBucketResultItem | null > | null,
};

export type SearchableAggregateBucketResultItem = {
  __typename: "SearchableAggregateBucketResultItem",
  key: string,
  doc_count: number,
};

export type ModelSubscriptionPlantFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  commonName?: ModelSubscriptionStringInput | null,
  familyCommonName?: ModelSubscriptionStringInput | null,
  scientificName?: ModelSubscriptionStringInput | null,
  family?: ModelSubscriptionStringInput | null,
  genus?: ModelSubscriptionStringInput | null,
  species?: ModelSubscriptionStringInput | null,
  otherCommonNames?: ModelSubscriptionStringInput | null,
  synonyms?: ModelSubscriptionStringInput | null,
  edibleParts?: ModelSubscriptionStringInput | null,
  form?: ModelSubscriptionStringInput | null,
  habit?: ModelSubscriptionStringInput | null,
  growingMonths?: ModelSubscriptionStringInput | null,
  bloomMonths?: ModelSubscriptionStringInput | null,
  fruitingMonths?: ModelSubscriptionStringInput | null,
  growthRate?: ModelSubscriptionStringInput | null,
  growingDescription?: ModelSubscriptionStringInput | null,
  averageHeightMm?: ModelSubscriptionIntInput | null,
  maximumHeightMm?: ModelSubscriptionIntInput | null,
  flowerColors?: ModelSubscriptionStringInput | null,
  flowerConspicuous?: ModelSubscriptionBooleanInput | null,
  foliageColors?: ModelSubscriptionStringInput | null,
  foliageTexture?: ModelSubscriptionStringInput | null,
  foliageConspicuous?: ModelSubscriptionBooleanInput | null,
  fruitOrSeedColors?: ModelSubscriptionStringInput | null,
  fruitOrSeedConspicuous?: ModelSubscriptionBooleanInput | null,
  daysToHarvest?: ModelSubscriptionIntInput | null,
  sowingDescription?: ModelSubscriptionStringInput | null,
  rowSpacingMm?: ModelSubscriptionIntInput | null,
  spreadMm?: ModelSubscriptionIntInput | null,
  minimumRootDepthMm?: ModelSubscriptionIntInput | null,
  anaerobicTolerance?: ModelSubscriptionStringInput | null,
  light?: ModelSubscriptionIntInput | null,
  soilHumidity?: ModelSubscriptionIntInput | null,
  soilNutriments?: ModelSubscriptionIntInput | null,
  soilSalinity?: ModelSubscriptionIntInput | null,
  atmosphericHumidity?: ModelSubscriptionIntInput | null,
  phMinimum?: ModelSubscriptionFloatInput | null,
  phMaximum?: ModelSubscriptionFloatInput | null,
  distributions?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionPlantFilterInput | null > | null,
  or?: Array< ModelSubscriptionPlantFilterInput | null > | null,
};

export type ModelSubscriptionIDInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  in?: Array< string | null > | null,
  notIn?: Array< string | null > | null,
};

export type ModelSubscriptionStringInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  in?: Array< string | null > | null,
  notIn?: Array< string | null > | null,
};

export type ModelSubscriptionIntInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  in?: Array< number | null > | null,
  notIn?: Array< number | null > | null,
};

export type ModelSubscriptionBooleanInput = {
  ne?: boolean | null,
  eq?: boolean | null,
};

export type ModelSubscriptionFloatInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  in?: Array< number | null > | null,
  notIn?: Array< number | null > | null,
};

export type CreatePlantMutationVariables = {
  input: CreatePlantInput,
  condition?: ModelPlantConditionInput | null,
};

export type CreatePlantMutation = {
  createPlant?:  {
    __typename: "Plant",
    id: string,
    commonName?: string | null,
    familyCommonName?: string | null,
    scientificName?: string | null,
    family?: string | null,
    genus?: string | null,
    species?: string | null,
    otherCommonNames?: string | null,
    synonyms?: string | null,
    edibleParts?: string | null,
    form?: PlantForm | null,
    habit?: string | null,
    growingMonths?: string | null,
    bloomMonths?: string | null,
    fruitingMonths?: string | null,
    growthRate?: PlantGrowthRate | null,
    growingDescription?: string | null,
    averageHeightMm?: number | null,
    maximumHeightMm?: number | null,
    flowerColors?: string | null,
    flowerConspicuous?: boolean | null,
    foliageColors?: string | null,
    foliageTexture?: PlantFoliageTexture | null,
    foliageConspicuous?: boolean | null,
    fruitOrSeedColors?: string | null,
    fruitOrSeedConspicuous?: boolean | null,
    daysToHarvest?: number | null,
    sowingDescription?: string | null,
    rowSpacingMm?: number | null,
    spreadMm?: number | null,
    minimumRootDepthMm?: number | null,
    anaerobicTolerance?: PlantAnaerobicTolerance | null,
    light?: number | null,
    soilHumidity?: number | null,
    soilNutriments?: number | null,
    soilSalinity?: number | null,
    atmosphericHumidity?: number | null,
    phMinimum?: number | null,
    phMaximum?: number | null,
    distributions?: string | null,
    links?:  {
      __typename: "PlantLinks",
      usda?: string | null,
      tropicos?: string | null,
      telaBotanica?: string | null,
      powo?: string | null,
      plantnet?: string | null,
      gbif?: string | null,
      openfarm?: string | null,
      catminat?: string | null,
      wikipediaEn?: string | null,
    } | null,
    images?:  Array< {
      __typename: "PlantImage",
      id: string,
      bucket?: string | null,
      region?: string | null,
      key?: string | null,
      url?: string | null,
    } | null > | null,
    createdAt: string,
    updatedAt: string,
    updatedMeta?:  {
      __typename: "UpdatedMeta",
      id: string,
      userCognitoId?: string | null,
      userId?: string | null,
      userName?: string | null,
      userEmail?: string | null,
      userGroups?: string | null,
      device?: string | null,
    } | null,
  } | null,
};

export type UpdatePlantMutationVariables = {
  input: UpdatePlantInput,
  condition?: ModelPlantConditionInput | null,
};

export type UpdatePlantMutation = {
  updatePlant?:  {
    __typename: "Plant",
    id: string,
    commonName?: string | null,
    familyCommonName?: string | null,
    scientificName?: string | null,
    family?: string | null,
    genus?: string | null,
    species?: string | null,
    otherCommonNames?: string | null,
    synonyms?: string | null,
    edibleParts?: string | null,
    form?: PlantForm | null,
    habit?: string | null,
    growingMonths?: string | null,
    bloomMonths?: string | null,
    fruitingMonths?: string | null,
    growthRate?: PlantGrowthRate | null,
    growingDescription?: string | null,
    averageHeightMm?: number | null,
    maximumHeightMm?: number | null,
    flowerColors?: string | null,
    flowerConspicuous?: boolean | null,
    foliageColors?: string | null,
    foliageTexture?: PlantFoliageTexture | null,
    foliageConspicuous?: boolean | null,
    fruitOrSeedColors?: string | null,
    fruitOrSeedConspicuous?: boolean | null,
    daysToHarvest?: number | null,
    sowingDescription?: string | null,
    rowSpacingMm?: number | null,
    spreadMm?: number | null,
    minimumRootDepthMm?: number | null,
    anaerobicTolerance?: PlantAnaerobicTolerance | null,
    light?: number | null,
    soilHumidity?: number | null,
    soilNutriments?: number | null,
    soilSalinity?: number | null,
    atmosphericHumidity?: number | null,
    phMinimum?: number | null,
    phMaximum?: number | null,
    distributions?: string | null,
    links?:  {
      __typename: "PlantLinks",
      usda?: string | null,
      tropicos?: string | null,
      telaBotanica?: string | null,
      powo?: string | null,
      plantnet?: string | null,
      gbif?: string | null,
      openfarm?: string | null,
      catminat?: string | null,
      wikipediaEn?: string | null,
    } | null,
    images?:  Array< {
      __typename: "PlantImage",
      id: string,
      bucket?: string | null,
      region?: string | null,
      key?: string | null,
      url?: string | null,
    } | null > | null,
    createdAt: string,
    updatedAt: string,
    updatedMeta?:  {
      __typename: "UpdatedMeta",
      id: string,
      userCognitoId?: string | null,
      userId?: string | null,
      userName?: string | null,
      userEmail?: string | null,
      userGroups?: string | null,
      device?: string | null,
    } | null,
  } | null,
};

export type DeletePlantMutationVariables = {
  input: DeletePlantInput,
  condition?: ModelPlantConditionInput | null,
};

export type DeletePlantMutation = {
  deletePlant?:  {
    __typename: "Plant",
    id: string,
    commonName?: string | null,
    familyCommonName?: string | null,
    scientificName?: string | null,
    family?: string | null,
    genus?: string | null,
    species?: string | null,
    otherCommonNames?: string | null,
    synonyms?: string | null,
    edibleParts?: string | null,
    form?: PlantForm | null,
    habit?: string | null,
    growingMonths?: string | null,
    bloomMonths?: string | null,
    fruitingMonths?: string | null,
    growthRate?: PlantGrowthRate | null,
    growingDescription?: string | null,
    averageHeightMm?: number | null,
    maximumHeightMm?: number | null,
    flowerColors?: string | null,
    flowerConspicuous?: boolean | null,
    foliageColors?: string | null,
    foliageTexture?: PlantFoliageTexture | null,
    foliageConspicuous?: boolean | null,
    fruitOrSeedColors?: string | null,
    fruitOrSeedConspicuous?: boolean | null,
    daysToHarvest?: number | null,
    sowingDescription?: string | null,
    rowSpacingMm?: number | null,
    spreadMm?: number | null,
    minimumRootDepthMm?: number | null,
    anaerobicTolerance?: PlantAnaerobicTolerance | null,
    light?: number | null,
    soilHumidity?: number | null,
    soilNutriments?: number | null,
    soilSalinity?: number | null,
    atmosphericHumidity?: number | null,
    phMinimum?: number | null,
    phMaximum?: number | null,
    distributions?: string | null,
    links?:  {
      __typename: "PlantLinks",
      usda?: string | null,
      tropicos?: string | null,
      telaBotanica?: string | null,
      powo?: string | null,
      plantnet?: string | null,
      gbif?: string | null,
      openfarm?: string | null,
      catminat?: string | null,
      wikipediaEn?: string | null,
    } | null,
    images?:  Array< {
      __typename: "PlantImage",
      id: string,
      bucket?: string | null,
      region?: string | null,
      key?: string | null,
      url?: string | null,
    } | null > | null,
    createdAt: string,
    updatedAt: string,
    updatedMeta?:  {
      __typename: "UpdatedMeta",
      id: string,
      userCognitoId?: string | null,
      userId?: string | null,
      userName?: string | null,
      userEmail?: string | null,
      userGroups?: string | null,
      device?: string | null,
    } | null,
  } | null,
};

export type GetPlantQueryVariables = {
  id: string,
};

export type GetPlantQuery = {
  getPlant?:  {
    __typename: "Plant",
    id: string,
    commonName?: string | null,
    familyCommonName?: string | null,
    scientificName?: string | null,
    family?: string | null,
    genus?: string | null,
    species?: string | null,
    otherCommonNames?: string | null,
    synonyms?: string | null,
    edibleParts?: string | null,
    form?: PlantForm | null,
    habit?: string | null,
    growingMonths?: string | null,
    bloomMonths?: string | null,
    fruitingMonths?: string | null,
    growthRate?: PlantGrowthRate | null,
    growingDescription?: string | null,
    averageHeightMm?: number | null,
    maximumHeightMm?: number | null,
    flowerColors?: string | null,
    flowerConspicuous?: boolean | null,
    foliageColors?: string | null,
    foliageTexture?: PlantFoliageTexture | null,
    foliageConspicuous?: boolean | null,
    fruitOrSeedColors?: string | null,
    fruitOrSeedConspicuous?: boolean | null,
    daysToHarvest?: number | null,
    sowingDescription?: string | null,
    rowSpacingMm?: number | null,
    spreadMm?: number | null,
    minimumRootDepthMm?: number | null,
    anaerobicTolerance?: PlantAnaerobicTolerance | null,
    light?: number | null,
    soilHumidity?: number | null,
    soilNutriments?: number | null,
    soilSalinity?: number | null,
    atmosphericHumidity?: number | null,
    phMinimum?: number | null,
    phMaximum?: number | null,
    distributions?: string | null,
    links?:  {
      __typename: "PlantLinks",
      usda?: string | null,
      tropicos?: string | null,
      telaBotanica?: string | null,
      powo?: string | null,
      plantnet?: string | null,
      gbif?: string | null,
      openfarm?: string | null,
      catminat?: string | null,
      wikipediaEn?: string | null,
    } | null,
    images?:  Array< {
      __typename: "PlantImage",
      id: string,
      bucket?: string | null,
      region?: string | null,
      key?: string | null,
      url?: string | null,
    } | null > | null,
    createdAt: string,
    updatedAt: string,
    updatedMeta?:  {
      __typename: "UpdatedMeta",
      id: string,
      userCognitoId?: string | null,
      userId?: string | null,
      userName?: string | null,
      userEmail?: string | null,
      userGroups?: string | null,
      device?: string | null,
    } | null,
  } | null,
};

export type ListPlantsQueryVariables = {
  filter?: ModelPlantFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListPlantsQuery = {
  listPlants?:  {
    __typename: "ModelPlantConnection",
    items:  Array< {
      __typename: "Plant",
      id: string,
      commonName?: string | null,
      familyCommonName?: string | null,
      scientificName?: string | null,
      family?: string | null,
      genus?: string | null,
      species?: string | null,
      otherCommonNames?: string | null,
      synonyms?: string | null,
      edibleParts?: string | null,
      form?: PlantForm | null,
      habit?: string | null,
      growingMonths?: string | null,
      bloomMonths?: string | null,
      fruitingMonths?: string | null,
      growthRate?: PlantGrowthRate | null,
      growingDescription?: string | null,
      averageHeightMm?: number | null,
      maximumHeightMm?: number | null,
      flowerColors?: string | null,
      flowerConspicuous?: boolean | null,
      foliageColors?: string | null,
      foliageTexture?: PlantFoliageTexture | null,
      foliageConspicuous?: boolean | null,
      fruitOrSeedColors?: string | null,
      fruitOrSeedConspicuous?: boolean | null,
      daysToHarvest?: number | null,
      sowingDescription?: string | null,
      rowSpacingMm?: number | null,
      spreadMm?: number | null,
      minimumRootDepthMm?: number | null,
      anaerobicTolerance?: PlantAnaerobicTolerance | null,
      light?: number | null,
      soilHumidity?: number | null,
      soilNutriments?: number | null,
      soilSalinity?: number | null,
      atmosphericHumidity?: number | null,
      phMinimum?: number | null,
      phMaximum?: number | null,
      distributions?: string | null,
      links?:  {
        __typename: "PlantLinks",
        usda?: string | null,
        tropicos?: string | null,
        telaBotanica?: string | null,
        powo?: string | null,
        plantnet?: string | null,
        gbif?: string | null,
        openfarm?: string | null,
        catminat?: string | null,
        wikipediaEn?: string | null,
      } | null,
      images?:  Array< {
        __typename: "PlantImage",
        id: string,
        bucket?: string | null,
        region?: string | null,
        key?: string | null,
        url?: string | null,
      } | null > | null,
      createdAt: string,
      updatedAt: string,
      updatedMeta?:  {
        __typename: "UpdatedMeta",
        id: string,
        userCognitoId?: string | null,
        userId?: string | null,
        userName?: string | null,
        userEmail?: string | null,
        userGroups?: string | null,
        device?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type SearchPlantsQueryVariables = {
  filter?: SearchablePlantFilterInput | null,
  sort?: Array< SearchablePlantSortInput | null > | null,
  limit?: number | null,
  nextToken?: string | null,
  from?: number | null,
  aggregates?: Array< SearchablePlantAggregationInput | null > | null,
};

export type SearchPlantsQuery = {
  searchPlants?:  {
    __typename: "SearchablePlantConnection",
    items:  Array< {
      __typename: "Plant",
      id: string,
      commonName?: string | null,
      familyCommonName?: string | null,
      scientificName?: string | null,
      family?: string | null,
      genus?: string | null,
      species?: string | null,
      otherCommonNames?: string | null,
      synonyms?: string | null,
      edibleParts?: string | null,
      form?: PlantForm | null,
      habit?: string | null,
      growingMonths?: string | null,
      bloomMonths?: string | null,
      fruitingMonths?: string | null,
      growthRate?: PlantGrowthRate | null,
      growingDescription?: string | null,
      averageHeightMm?: number | null,
      maximumHeightMm?: number | null,
      flowerColors?: string | null,
      flowerConspicuous?: boolean | null,
      foliageColors?: string | null,
      foliageTexture?: PlantFoliageTexture | null,
      foliageConspicuous?: boolean | null,
      fruitOrSeedColors?: string | null,
      fruitOrSeedConspicuous?: boolean | null,
      daysToHarvest?: number | null,
      sowingDescription?: string | null,
      rowSpacingMm?: number | null,
      spreadMm?: number | null,
      minimumRootDepthMm?: number | null,
      anaerobicTolerance?: PlantAnaerobicTolerance | null,
      light?: number | null,
      soilHumidity?: number | null,
      soilNutriments?: number | null,
      soilSalinity?: number | null,
      atmosphericHumidity?: number | null,
      phMinimum?: number | null,
      phMaximum?: number | null,
      distributions?: string | null,
      links?:  {
        __typename: "PlantLinks",
        usda?: string | null,
        tropicos?: string | null,
        telaBotanica?: string | null,
        powo?: string | null,
        plantnet?: string | null,
        gbif?: string | null,
        openfarm?: string | null,
        catminat?: string | null,
        wikipediaEn?: string | null,
      } | null,
      images?:  Array< {
        __typename: "PlantImage",
        id: string,
        bucket?: string | null,
        region?: string | null,
        key?: string | null,
        url?: string | null,
      } | null > | null,
      createdAt: string,
      updatedAt: string,
      updatedMeta?:  {
        __typename: "UpdatedMeta",
        id: string,
        userCognitoId?: string | null,
        userId?: string | null,
        userName?: string | null,
        userEmail?: string | null,
        userGroups?: string | null,
        device?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
    total?: number | null,
    aggregateItems:  Array< {
      __typename: "SearchableAggregateResult",
      name: string,
      result: ( {
          __typename: "SearchableAggregateScalarResult",
          value: number,
        } | {
          __typename: "SearchableAggregateBucketResult",
          buckets?:  Array< {
            __typename: string,
            key: string,
            doc_count: number,
          } | null > | null,
        }
      ) | null,
    } | null >,
  } | null,
};

export type OnCreatePlantSubscriptionVariables = {
  filter?: ModelSubscriptionPlantFilterInput | null,
};

export type OnCreatePlantSubscription = {
  onCreatePlant?:  {
    __typename: "Plant",
    id: string,
    commonName?: string | null,
    familyCommonName?: string | null,
    scientificName?: string | null,
    family?: string | null,
    genus?: string | null,
    species?: string | null,
    otherCommonNames?: string | null,
    synonyms?: string | null,
    edibleParts?: string | null,
    form?: PlantForm | null,
    habit?: string | null,
    growingMonths?: string | null,
    bloomMonths?: string | null,
    fruitingMonths?: string | null,
    growthRate?: PlantGrowthRate | null,
    growingDescription?: string | null,
    averageHeightMm?: number | null,
    maximumHeightMm?: number | null,
    flowerColors?: string | null,
    flowerConspicuous?: boolean | null,
    foliageColors?: string | null,
    foliageTexture?: PlantFoliageTexture | null,
    foliageConspicuous?: boolean | null,
    fruitOrSeedColors?: string | null,
    fruitOrSeedConspicuous?: boolean | null,
    daysToHarvest?: number | null,
    sowingDescription?: string | null,
    rowSpacingMm?: number | null,
    spreadMm?: number | null,
    minimumRootDepthMm?: number | null,
    anaerobicTolerance?: PlantAnaerobicTolerance | null,
    light?: number | null,
    soilHumidity?: number | null,
    soilNutriments?: number | null,
    soilSalinity?: number | null,
    atmosphericHumidity?: number | null,
    phMinimum?: number | null,
    phMaximum?: number | null,
    distributions?: string | null,
    links?:  {
      __typename: "PlantLinks",
      usda?: string | null,
      tropicos?: string | null,
      telaBotanica?: string | null,
      powo?: string | null,
      plantnet?: string | null,
      gbif?: string | null,
      openfarm?: string | null,
      catminat?: string | null,
      wikipediaEn?: string | null,
    } | null,
    images?:  Array< {
      __typename: "PlantImage",
      id: string,
      bucket?: string | null,
      region?: string | null,
      key?: string | null,
      url?: string | null,
    } | null > | null,
    createdAt: string,
    updatedAt: string,
    updatedMeta?:  {
      __typename: "UpdatedMeta",
      id: string,
      userCognitoId?: string | null,
      userId?: string | null,
      userName?: string | null,
      userEmail?: string | null,
      userGroups?: string | null,
      device?: string | null,
    } | null,
  } | null,
};

export type OnUpdatePlantSubscriptionVariables = {
  filter?: ModelSubscriptionPlantFilterInput | null,
};

export type OnUpdatePlantSubscription = {
  onUpdatePlant?:  {
    __typename: "Plant",
    id: string,
    commonName?: string | null,
    familyCommonName?: string | null,
    scientificName?: string | null,
    family?: string | null,
    genus?: string | null,
    species?: string | null,
    otherCommonNames?: string | null,
    synonyms?: string | null,
    edibleParts?: string | null,
    form?: PlantForm | null,
    habit?: string | null,
    growingMonths?: string | null,
    bloomMonths?: string | null,
    fruitingMonths?: string | null,
    growthRate?: PlantGrowthRate | null,
    growingDescription?: string | null,
    averageHeightMm?: number | null,
    maximumHeightMm?: number | null,
    flowerColors?: string | null,
    flowerConspicuous?: boolean | null,
    foliageColors?: string | null,
    foliageTexture?: PlantFoliageTexture | null,
    foliageConspicuous?: boolean | null,
    fruitOrSeedColors?: string | null,
    fruitOrSeedConspicuous?: boolean | null,
    daysToHarvest?: number | null,
    sowingDescription?: string | null,
    rowSpacingMm?: number | null,
    spreadMm?: number | null,
    minimumRootDepthMm?: number | null,
    anaerobicTolerance?: PlantAnaerobicTolerance | null,
    light?: number | null,
    soilHumidity?: number | null,
    soilNutriments?: number | null,
    soilSalinity?: number | null,
    atmosphericHumidity?: number | null,
    phMinimum?: number | null,
    phMaximum?: number | null,
    distributions?: string | null,
    links?:  {
      __typename: "PlantLinks",
      usda?: string | null,
      tropicos?: string | null,
      telaBotanica?: string | null,
      powo?: string | null,
      plantnet?: string | null,
      gbif?: string | null,
      openfarm?: string | null,
      catminat?: string | null,
      wikipediaEn?: string | null,
    } | null,
    images?:  Array< {
      __typename: "PlantImage",
      id: string,
      bucket?: string | null,
      region?: string | null,
      key?: string | null,
      url?: string | null,
    } | null > | null,
    createdAt: string,
    updatedAt: string,
    updatedMeta?:  {
      __typename: "UpdatedMeta",
      id: string,
      userCognitoId?: string | null,
      userId?: string | null,
      userName?: string | null,
      userEmail?: string | null,
      userGroups?: string | null,
      device?: string | null,
    } | null,
  } | null,
};

export type OnDeletePlantSubscriptionVariables = {
  filter?: ModelSubscriptionPlantFilterInput | null,
};

export type OnDeletePlantSubscription = {
  onDeletePlant?:  {
    __typename: "Plant",
    id: string,
    commonName?: string | null,
    familyCommonName?: string | null,
    scientificName?: string | null,
    family?: string | null,
    genus?: string | null,
    species?: string | null,
    otherCommonNames?: string | null,
    synonyms?: string | null,
    edibleParts?: string | null,
    form?: PlantForm | null,
    habit?: string | null,
    growingMonths?: string | null,
    bloomMonths?: string | null,
    fruitingMonths?: string | null,
    growthRate?: PlantGrowthRate | null,
    growingDescription?: string | null,
    averageHeightMm?: number | null,
    maximumHeightMm?: number | null,
    flowerColors?: string | null,
    flowerConspicuous?: boolean | null,
    foliageColors?: string | null,
    foliageTexture?: PlantFoliageTexture | null,
    foliageConspicuous?: boolean | null,
    fruitOrSeedColors?: string | null,
    fruitOrSeedConspicuous?: boolean | null,
    daysToHarvest?: number | null,
    sowingDescription?: string | null,
    rowSpacingMm?: number | null,
    spreadMm?: number | null,
    minimumRootDepthMm?: number | null,
    anaerobicTolerance?: PlantAnaerobicTolerance | null,
    light?: number | null,
    soilHumidity?: number | null,
    soilNutriments?: number | null,
    soilSalinity?: number | null,
    atmosphericHumidity?: number | null,
    phMinimum?: number | null,
    phMaximum?: number | null,
    distributions?: string | null,
    links?:  {
      __typename: "PlantLinks",
      usda?: string | null,
      tropicos?: string | null,
      telaBotanica?: string | null,
      powo?: string | null,
      plantnet?: string | null,
      gbif?: string | null,
      openfarm?: string | null,
      catminat?: string | null,
      wikipediaEn?: string | null,
    } | null,
    images?:  Array< {
      __typename: "PlantImage",
      id: string,
      bucket?: string | null,
      region?: string | null,
      key?: string | null,
      url?: string | null,
    } | null > | null,
    createdAt: string,
    updatedAt: string,
    updatedMeta?:  {
      __typename: "UpdatedMeta",
      id: string,
      userCognitoId?: string | null,
      userId?: string | null,
      userName?: string | null,
      userEmail?: string | null,
      userGroups?: string | null,
      device?: string | null,
    } | null,
  } | null,
};
