import React, { useState } from "react";
import { ImgGrid } from "@thrivelot/styles";
import { styleConstants } from "../../../../helpers";
import { DefaultPic } from "./DefaultPic";

const Pic = ({ plant }) => {
  const isValidPic = () => plant.imageUrl !== "";
  // hooks
  const [errored, setErrored] = useState(!isValidPic());
  // return
  if (errored) return <DefaultPic />;
  return (
    <div
      style={{
        ...styleConstants.gradientItemStyle,
        left: "4px",
        right: "4px",
        bottom: "4px",
        top: "4px",
        borderRadius: "100%",
        overflow: "hidden",
      }}
    >
      <ImgGrid src={{ url: plant.imageUrl }} onLoadError={() => setErrored(true)} />
    </div>
  );
};

export { Pic };
