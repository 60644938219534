import React from 'react';
import { PlantImage } from './PlantImage';
import { Image } from './Image';

export const LineItemImage = ({ item, classAppend }) => {
  const { data } = item;

  if (!data?.plantDatabaseId && !data?.imageUrl) return null;

  return data?.imageUrl ? (
    <Image classAppend={classAppend} item={item} />
  ) : (
    <PlantImage
      plantId={data.plantDatabaseId}
      classAppend={classAppend}
      item={item}
    />
  );
};
