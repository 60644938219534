import React, { useMemo } from 'react';
import { useMap, useModel } from '@thrivelot/hooks';
import { LeafLoader, RowList } from '@thrivelot/stories';
import { RowItemFeature } from '..';

const ZoneFeatures = ({ projectId }) => {
  const { infoBoxId } = useMap();
  const { actions, update, loaded } = useModel({
    modelName: 'Project',
    id: projectId,
  });

  const features = useMemo(
    () =>
      (
        (infoBoxId &&
          actions.findItemsByTags(`zones[id:${infoBoxId}]`, 'features')) ||
        []
      ).filter((f) => !f.hidden),
    [actions, infoBoxId]
  );

  if (!loaded) return <LeafLoader />;

  return (
    <div>
      <RowList>
        {features.map(({ id }) => (
          <RowItemFeature key={id} featureId={id} projectId={projectId} />
        ))}
      </RowList>
    </div>
  );
};

export { ZoneFeatures };
