import React from "react";
import { CalendarProvider, useCalendar } from "@thrivelot/hooks";
import { Calendar as CalendarStory } from "@thrivelot/stories";

const CalendarChild = ({ selectable, onSelectEvent }) => {
  const calendar = useCalendar();
  return <CalendarStory context={calendar} selectable={selectable} onSelectEvent={onSelectEvent} />;
};

const Calendar = ({ selectedDate, selectable, onSelectEvent, events, notes, withoutProvider }) =>
  withoutProvider ? (
    <CalendarChild selectable={selectable} />
  ) : (
    <CalendarProvider selectedDate={selectedDate} events={events} notes={notes}>
      <CalendarChild selectable={selectable} onSelectEvent={onSelectEvent} />
    </CalendarProvider>
  );

export { Calendar };
