import React from "react";
import { useProposal } from "@thrivelot/common";
import { useTheme } from "@thrivelot/hooks";
import { TextTitle, TLIcon } from "@thrivelot/styles";
import { useIconSize, useSpacing } from "../../hooks";

const TitleBar = () => {
  // hooks
  const { projectActions, customerActions } = useProposal();
  const theme = useTheme();
  const iconSize = useIconSize();
  const spacing = useSpacing();
  // utils
  const isValidTitle = (title) => title && title.trim() !== "";
  const fullCustomerName = () => {
    const firstname = customerActions.get("firstname");
    const lastname = customerActions.get("lastname");
    return `${firstname} ${lastname}`;
  };
  const getProjectTitle = () => {
    const title = projectActions.get("details.title");
    if (isValidTitle(title)) return title;
    return fullCustomerName();
  };
  // return
  return (
    <div className="flex items-center mb-4">
      <TLIcon color={theme.green} size={iconSize} />
      <TextTitle style={{ marginLeft: spacing }}>{getProjectTitle()}</TextTitle>
    </div>
  );
};

export { TitleBar };
