import { useModal, useModel } from '@thrivelot/hooks';
import { Dropdown, AngleDown } from '@thrivelot/stories';
import React, { useMemo, useCallback, useState } from 'react';

export const DropdownFileActions = ({
  projectId,
  fileId,
  folderId,
  setEditing,
}) => {
  const { openModal } = useModal();
  const {
    model: project,
    update,
    actions,
  } = useModel({
    modelName: 'Project',
    id: projectId,
  });
  const [deleting, setDeleting] = useState(false);

  const handleDeleteItem = useCallback(() => {
    if (!project?.files) return;

    const newFiles = project.files.filter((file) => file.id !== fileId);
    const newFolders = (project.fileFolders || []).map((folder) => {
      const newFileIds = (folder.fileIds || []).filter((id) => id !== fileId);
      return { ...folder, fileIds: newFileIds };
    });

    update({ files: newFiles, fileFolders: newFolders });
  }, [fileId, project?.files, project?.fileFolders, update, actions]);

  const options = useMemo(() => {
    let result = [];
    if (deleting)
      result = [
        {
          icon: 'Minus',
          label: 'No, keep file',
          color: 'blue-main',
          closeOnClick: true,
          onClick: () => setDeleting(false),
        },
        {
          icon: 'Trash',
          label: 'Yes, delete file',
          color: 'red-main',
          closeOnClick: true,
          onClick: () => handleDeleteItem(),
        },
      ];
    else
      result = [
        {
          icon: 'Edit',
          label: 'Edit file details',
          onClick: () => setEditing(true),
          closeOnClick: true,
        },
        {
          icon: 'FilesFolder',
          label: 'Move file to a folder',
          onClick: () =>
            openModal({
              path: 'ModalAddFileToFolder',
              modelId: projectId,
              fileIds: [fileId],
              folderId,
            }),
          closeOnClick: true,
        },
        {
          icon: 'Trash',
          label: 'Delete file',
          color: 'red-main',
          onClick: () => setDeleting(true),
        },
      ];

    return result;
  }, [handleDeleteItem, deleting]);

  return (
    <Dropdown
      label={<AngleDown size={16} />}
      options={options}
      optionClass="font-bold"
      align="right"
      closeOnSelect={false}
      header={deleting ? <div className="italic">Are you sure?</div> : null}
      onClose={() => setDeleting(false)}
    />
  );
};
