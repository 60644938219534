import { isObject } from "./isObject";

const duplicateObject = (aObject) => {
  // If not an object then return
  if (!isObject(aObject)) return aObject;

  let v;

  // Check the type of the input
  const bObject = Array.isArray(aObject) ? [] : {};

  // Copy each element
  Object.keys(aObject).forEach((k) => {
    v = aObject[k];

    // If type of element is object
    // Then recursively call the same function and create  a copy
    bObject[k] = typeof v === "object" ? duplicateObject(v) : v;
  });

  return bObject;
};

export { duplicateObject };
