import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import { useFetchFileUrl } from "@thrivelot/common";
import CssImageDefault from "./CssImageDefault";

const Image = styled.img`
  @media (max-width: 767px) {
    min-height: 30vw;
    max-height: 30vw;
  }
  @media (min-width: 768px) {
    min-height: 200px;
    max-height: 200px;
  }
  overflow: hidden;
  object-position: 50% 50%;
  object-fit: cover;
  width: 100%;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  ${CssImageDefault}
`;

const ImgBox = ({ src: srcObj, size, imgType, onLoadError, ...rest }) => {
  const [src, setSrc] = useState(
    "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkqAcAAIUAgUW0RjgAAAAASUVORK5CYII="
  );

  const fetchUrl = useFetchFileUrl();

  useEffect(() => {
    let didCancel;

    fetchUrl(srcObj).then((data) => {
      if (!didCancel) setSrc(data);
    });

    return () => {
      didCancel = true;
    };
  }, [srcObj, fetchUrl]);

  const onLoad = useCallback((event) => {
    event.target.classList.add("loaded");
  }, []);

  const onError = useCallback(
    (event) => {
      event.target.classList.add("has-error");

      if (onLoadError) onLoadError();
    },
    [onLoadError]
  );

  return <Image src={src} alt="" size={size} onLoad={onLoad} onError={onError} {...rest} />;
};

export default ImgBox;
