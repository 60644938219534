import React from 'react';

const CheckFull = ({
  color = 'green-main',
  size = 30,
  styleProps = '',
  ...rest
}) => (
  <svg
    width={size.toString()}
    height={size.toString()}
    viewBox="0 0 75 75"
    className={`fill-${color} ${styleProps}`}
    {...rest}
  >
    <path d="M29.6158 56.4878H29.5975C27.8597 56.4878 26.1951 55.7561 24.9878 54.4939L16.4085 45.4573C13.9756 42.8963 14.067 38.8353 16.628 36.4024C19.189 33.9695 23.25 34.0609 25.6829 36.6219L29.6707 40.8109L49.3719 20.4329C51.8231 17.8902 55.8841 17.817 58.4268 20.2866C60.9695 22.7561 61.0427 26.7987 58.5731 29.3414L34.2256 54.5305C33.0183 55.7744 31.3536 56.4878 29.6158 56.4878V56.4878Z" />
    <path d="M60.1098 75H14.8902C6.67683 75 0 68.3232 0 60.1098V14.8902C0 6.67683 6.67683 0 14.8902 0H60.1098C68.3232 0 75 6.67683 75 14.8902V60.1098C75 68.3232 68.3232 75 60.1098 75ZM14.8902 9.14634C11.7256 9.14634 9.14634 11.7256 9.14634 14.8902V60.1098C9.14634 63.2744 11.7256 65.8537 14.8902 65.8537H60.1098C63.2744 65.8537 65.8537 63.2744 65.8537 60.1098V14.8902C65.8537 11.7256 63.2744 9.14634 60.1098 9.14634H14.8902Z" />
  </svg>
);

export { CheckFull };
