const urlHashes = {
  addingZone: "adding-zone",
  areasOfWork: "areas-of-work",
  calendar: "calendar",
  changePassword: "change-password",
  componentId: "component-id",
  costEstimate: "cost-estimate",
  costPaymentSchedule: "cost-payment-schedule",
  customerId: "customer-id",
  date: "date",
  designFee: "design-fee",
  documentation: "documentation",
  editingId: "editing-id",
  fileId: "file-id",
  fileKey: "file-key",
  files: "files",
  fileType: "file-type",
  fileUpload: "file-upload",
  fileUrl: "file-url",
  forgotPassword: "forgot-password",
  fullscreen: "fullscreen",
  installationCost: "installation-cost",
  map: "map",
  mapLayers: "map-layers",
  menu: "menu",
  modal: "modal",
  page: "page",
  phaseId: "phase-id",
  photos: "photos",
  plantId: "plant-id",
  plantPalette: "plant-palette",
  projectDetails: "project-details",
  projectNotes: "project-notes",
  projectId: "project-id",
  projectIdModalOnly: "project-id-modal-only",
  proposalModal: "proposal-modal",
  resetPassword: "reset-password",
  searchPage: "search-page",
  siteAnalysis: "analysis",
  siteAssessmentOverview: "site-assessment-overview",
  supplierId: "supplier-id",
  tab: "tab",
  verificationType: "verification-type",
  workPhases: "work-phases",
  workPlan: "work-plan",
  zoneId: "zone-id",
};

export { urlHashes };
