import React from "react";
import { useTheme } from "@thrivelot/hooks";
import { useProposal, useProposalView } from "@thrivelot/common";
import { TextBody, TextSmallHeader } from "@thrivelot/styles";
import { useSpacing } from "../../../../hooks";

const Description = () => {
  const { projectActions } = useProposal();
  const { zone: zoneId } = useProposalView();
  const theme = useTheme();
  const spacing = useSpacing();
  if (!zoneId) return null;
  const description = projectActions.get(`zones[id:${zoneId}].description`);
  if (!description || description.length === 0) return null;
  return (
    <>
      <TextSmallHeader style={{ margin: `${spacing} ${spacing} 8px`, color: theme.textLabel }}>
        Description
      </TextSmallHeader>
      <TextBody
        style={{
          minHeight: "fit-content",
          maxHeight: "fit-content",
          margin: `0px ${spacing} ${spacing}`,
          color: theme.textTitle,
        }}
      >
        {description}
      </TextBody>
    </>
  );
};

export { Description };
