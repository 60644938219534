import { isObject } from './isObject';

const cleanObject = (aObject, ignoreKeys = []) => {
  if (!isObject(aObject)) {
    return aObject;
  }
  let v;
  const bObject = Array.isArray(aObject) ? [] : {};
  Object.keys(aObject).forEach((k) => {
    v = aObject[k];
    bObject[k] = typeof v === 'object' ? cleanObject(v, ignoreKeys) : v;
    if (bObject[k] === null || ignoreKeys.includes(k)) delete bObject[k];
  });
  return bObject;
};

export { cleanObject };
