import React from 'react';

const Shield = ({
  color = 'green-main',
  size = 30,
  styleProps = '',
  ...rest
}) => (
  <svg
    width={size.toString()}
    height={size.toString()}
    viewBox="0 0 75 75"
    className={`fill-${color} ${styleProps}`}
    {...rest}
  >
    <path d="M37.6722 74.9999C37.157 74.9992 36.651 74.8639 36.2043 74.6072C29.2013 70.6429 16.3641 61.6298 9.09003 45.7539C4.84196 36.4833 3.20613 26.2287 4.35906 16.0965C4.95744 10.9074 9.70711 6.99921 15.4011 6.99921H22.348C24.1851 7.01339 25.9816 6.45811 27.4903 5.40974L33.1002 1.44546C34.4461 0.504593 36.0487 0 37.6909 0C39.3331 0 40.9357 0.504593 42.2816 1.44546L47.8447 5.40041C49.3602 6.45765 51.1674 7.01647 53.0151 6.99921H59.8031C65.5906 6.99921 70.4057 10.9729 71.0228 16.2368C72.1473 26.3236 70.5088 36.5264 66.2824 45.7539C59.0177 61.6485 46.1805 70.6429 39.1682 74.6072C38.7132 74.8686 38.1969 75.0042 37.6722 74.9999ZM15.4105 12.9644C12.7925 12.9644 10.5486 14.6379 10.2962 16.779C9.26908 25.8284 10.7302 34.9864 14.5222 43.2669C20.6089 56.5716 31.1274 64.6123 37.6722 68.5579C44.217 64.5843 54.7355 56.5716 60.8222 43.2669C64.5982 35.0246 66.0622 25.9108 65.0576 16.9006C64.8145 14.6941 62.4958 12.9644 59.7937 12.9644H53.0058C49.9193 12.9755 46.905 12.0317 44.376 10.2623L38.7661 6.30734C38.4312 6.08383 38.0375 5.96454 37.6348 5.96454C37.2321 5.96454 36.8385 6.08383 36.5035 6.30734L30.8936 10.2716C28.3714 12.0322 25.3678 12.9724 22.2919 12.9644H15.4105Z" />
  </svg>
);

export { Shield };
