const transformStringToTitleCase = (str, words = false) => {
  if (!str) return '';

  let capitalizedStr = '';

  for (let i = 0; i < str.length; i += 1) {
    if (i === 0) {
      capitalizedStr += str.toUpperCase().charAt(i);
    } else if (str.charAt(i) === '_' || str.charAt(i) === '-') {
      i += 1;
      capitalizedStr += ` ${
        words ? str.toUpperCase().charAt(i) : str.charAt(i)
      }`;
    } else {
      capitalizedStr += str.toLowerCase().charAt(i);
    }
  }

  return capitalizedStr;
};

export { transformStringToTitleCase };
