import React, { useEffect, useState } from 'react';
import Image from 'react-graceful-image';
import { PlantKit } from '@thrivelot/plant-kit';
import { Leaf } from '@thrivelot/stories';

export const PlantImage = ({ plantId, image }) => {
  const [src, setSrc] = useState(null);

  useEffect(() => {
    if (!image?.key) return;

    let didCancel;

    const fetchImage = async () => {
      const plantKit = new PlantKit({ id: plantId });
      const img = await plantKit.getImage(image.key);
      if (!didCancel) setSrc(img.url);
    };

    fetchImage();

    // eslint-disable-next-line consistent-return
    return () => {
      didCancel = true;
    };
  }, [plantId, image?.key]);

  return src ? (
    <Image
      src={src}
      alt=""
      className="h-full w-full rounded object-center object-cover cursor-pointer"
    />
  ) : (
    <div className="flex h-10 w-10 items-center justify-center">
      <Leaf size={24} color="green-main" />
    </div>
  );
};
