import React, { useState } from 'react';
import PropTypes from 'prop-types';

import './index.css';

/**
 * Primary UI component for user input
 */
export const Input = ({
  containerClassName,
  classAppend,
  name,
  label,
  type,
  min,
  max,
  placeholder,
  ...props
}) => {
  const [value, setValue] = useState('');

  const onChange = (e) => {
    setValue(e.target.value);
  };

  return (
    <div className={containerClassName}>
      {label && (
        <label
          htmlFor={name}
          className="block font-accent text-mulch-dark text-sm ml-2 mb-0.5"
        >
          {label}
        </label>
      )}
      <input
        aria-label={name}
        data-testid={`input-for-${name}`}
        name={name}
        type={type}
        min={min}
        max={max}
        value={value}
        onChange={onChange}
        className={`input ${classAppend || ''}`}
        placeholder={placeholder}
        {...props}
      />
    </div>
  );
};

Input.propTypes = {
  /**
   * Name of the input, e.g. "email"
   */
  name: PropTypes.string.isRequired,
  /**
   * Label that will show above input if not undefined
   */
  label: PropTypes.string,
  /**
   * The type of input
   */
  type: PropTypes.string,
  /**
   * Minimum number of characters
   */
  min: PropTypes.number,
  /**
   * Maximum number of characters
   */
  max: PropTypes.number,
  /**
   * Placeholder text to be displayed in the string
   */
  placeholder: PropTypes.string,
};

Input.defaultProps = {
  label: undefined,
  type: 'text',
  min: undefined,
  max: undefined,
  placeholder: undefined,
};
