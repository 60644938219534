/* global google */

import colors from "@thrivelot/tailwind/colors";
import { MapHomeIcon } from "@thrivelot/styles";

const getHomeIcon = (color = colors.green.main, size = 30) => ({
  url: `data:image/svg+xml;charset=UTF-8,${MapHomeIcon({
    size: `${size}`,
    color,
  })}`,
  scaledSize: new google.maps.Size(size, size),
});

export { getHomeIcon };
