import { useModel } from '@thrivelot/hooks';
import { isAdminApp, transformStringToTitleCase } from '@thrivelot/utils';
import { getProjectSubmitType } from './getProjectSubmitType';

const useSubmitProject = ({ projectId }) => {
  // state hooks
  const { actions, updateModel: updateProject } = useModel({
    modelName: 'Project',
    id: projectId,
  });
  // actions
  const milestones = actions.get('milestones');
  const stage = actions.get('currentMilestone');
  const submitType = getProjectSubmitType(stage, milestones);
  const submit = () => {
    const lifecycleEvent = actions.constructLifecycleEvent({
      type: submitType,
    });
    updateProject(
      actions.add(
        `milestones[index:${milestones.length - 1}].lifecycleEvents`,
        lifecycleEvent
      ).result
    );
  };
  // return
  return {
    title: transformStringToTitleCase(stage, true),
    buttonTitle: isAdminApp() ? 'Mark as Reviewed' : 'Submit',
    submitType,
    submit,
  };
};

export { useSubmitProject };
