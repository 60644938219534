import React, { useCallback, useEffect, useState } from 'react';
import { LogoGold, Button } from '@thrivelot/stories';
import { useAuth, useQueryParam } from '@thrivelot/hooks';
import { Link, useNavigate } from 'react-router-dom';

const PageForgotPassword = () => {
  const navigate = useNavigate();
  const { constructParamWithKeyAndValue } = useQueryParam('reset');
  const [errors, setErrors] = useState(false);
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState('');

  const { sendPasswordResetCode } = useAuth();

  useEffect(() => {
    document.title = 'Thrive Lot | Forgot Password';
  }, []);

  const handleSubmit = useCallback(
    (evt) => {
      evt.preventDefault();

      setErrors([]);
      setLoading(true);

      sendPasswordResetCode(email.trim())
        .then((result) => {
          setLoading(false);

          const reset = constructParamWithKeyAndValue({
            username: email,
            attributeName: result?.CodeDeliveryDetails?.AttributeName,
            destination: result?.CodeDeliveryDetails?.Destination,
          });

          navigate(`/auth/reset-password?${reset}`);
        })
        .catch((error) => {
          if (error.message.includes('combination not found'))
            setErrors((prev) => [...prev, 'Unknown email']);
          else setErrors((prev) => [...prev, error.message]);

          setLoading(false);
        });
    },
    [
      email,
      setErrors,
      setLoading,
      sendPasswordResetCode,
      navigate,
      constructParamWithKeyAndValue,
    ]
  );

  return (
    <form
      onSubmit={handleSubmit}
      className="max-w-sm w-full bg-white-dark shadow rounded-main p-5"
    >
      <div className="flex justify-center mb-8 mt-4">
        <LogoGold size={180} />
      </div>

      <div className="text-brown-dark text-sm text-center mb-4">
        Please enter the <span className="font-bold">email</span> of your
        account below.
      </div>

      <div className="text-brown-dark text-sm text-center mb-4">
        This will send a code to a device or email registered to your account.
        You can use this code to set your password.
      </div>

      <input
        placeholder="Email"
        className="input mb-4"
        value={email}
        onChange={(evt) => setEmail(evt.target.value)}
      />

      {errors.length > 0 && (
        <div className="mb-4">
          {errors.map((error) => (
            <div key={error} className="mb-2 text-sm text-red-main">
              {error}
            </div>
          ))}
        </div>
      )}

      <Button
        block
        type="submit"
        loading={loading}
        disabled={loading}
        label="Send Code"
        color="green-main"
      />

      <div className="flex flex-col items-center mt-5 gap-4">
        <Link to="/auth/login" className="text-blue-main font-bold text-sm">
          Back to login form
        </Link>
      </div>
    </form>
  );
};

export { PageForgotPassword };
