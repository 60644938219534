import { API } from '@thrivelot/aws-amplify';
import { plantClient } from '@thrivelot/plant-kit';

interface Subscription {
  unsubscribe: () => any;
}

interface CallbackProps {
  data?: { [key: string]: any };
  error?: { [key: string]: any };
}

export const subscribe = (
  gql: string,
  callback: (props: CallbackProps) => void
): Subscription => {
  gql = gql
    .replace('($filter: ModelSubscriptionPlantFilterInput)', '')
    .replace('(filter: $filter)', '');
  const queryName = gql.match(plantClient.queryNameRegex)?.[1];
  if (!queryName) return { unsubscribe: () => null };

  return (
    API.graphql({ query: gql, variables: { filter: {} } }) as any
  ).subscribe({
    next: (res: { [key: string]: any }) => {
      const data = res.value.data[queryName];
      callback({ data });
    },
    error: (error: { [key: string]: any }) => {
      console.log('Error in subscription:', error);
      callback({ error });
    },
  });
};
