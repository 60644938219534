import React, { forwardRef } from "react";
import SvgContainer from "../SvgWrapper";

const CalendarIcon = forwardRef(({ color, size, ...rest }, ref) => (
  <SvgContainer
    ref={ref}
    width={`${size}`}
    height={`${size}`}
    viewBox="0 0 300 300"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      d="M246.873 40.7468H231.646V13.2911C231.646 5.96203 225.684 0 218.354 0C211.025 0 205.063 5.96203 205.063 13.2911V40.7468H94.9367V13.2911C94.9367 5.96203 88.9747 0 81.6456 0C74.3165 0 68.3544 5.96203 68.3544 13.2911V40.7468H53.1266C23.8481 40.7468 0 64.5949 0 93.8734V246.873C0 276.152 23.8481 300 53.1266 300H246.873C276.152 300 300 276.152 300 246.873V93.8734C300 64.5949 276.152 40.7468 246.873 40.7468ZM273.418 246.873C273.418 261.494 261.494 273.418 246.873 273.418H53.1266C38.5063 273.418 26.5823 261.494 26.5823 246.873V93.8734C26.5823 79.2532 38.5063 67.3291 53.1266 67.3291H68.3544V81.1519C68.3544 88.481 74.3165 94.443 81.6456 94.443C88.9747 94.443 94.9367 88.481 94.9367 81.1519V67.3291H205.063V81.1519C205.063 88.481 211.025 94.443 218.354 94.443C225.684 94.443 231.646 88.481 231.646 81.1519V67.3291H246.873C261.494 67.3291 273.418 79.2532 273.418 93.8734V246.873Z"
      fill={color}
    />
    <path
      d="M235.443 144.304H64.5568C57.2277 144.304 51.2656 138.342 51.2656 131.013C51.2656 123.683 57.2277 117.721 64.5568 117.721H235.443C242.772 117.721 248.734 123.683 248.734 131.013C248.734 138.342 242.772 144.304 235.443 144.304Z"
      fill={color}
    />
  </SvgContainer>
));

export default CalendarIcon;
