import React, { useMemo } from "react";
import { useModel } from "@thrivelot/hooks";
import ReactTextareaAutosize from "react-textarea-autosize";

const InputFeatureDescription = ({ bundleId, path, disabled, placeholder }) => {
  const { updateModel, actions, loaded } = useModel({ modelName: "Bundle", id: bundleId });

  const description = useMemo(() => loaded && actions.get(path), [loaded, actions, path]);

  return (
    <div className="my-4">
      {!disabled && (
        <ReactTextareaAutosize
          className="w-full input"
          placeholder={disabled ? "No description..." : placeholder}
          value={description || ""}
          disabled={disabled}
          minRows={3}
          onChange={(e) => updateModel(actions.set(path, e.target.value).result)}
        />
      )}
      {disabled && description && <div>{description}</div>}
    </div>
  );
};

export { InputFeatureDescription };
