import React, { useCallback } from "react";
import { constructUuid } from "@thrivelot/utils";
import { useModelLineItemCatalog } from "@thrivelot/common";
import { useModel } from "@thrivelot/hooks";
import { TagIdType, ProjectLineItemType, MeasurementUnit } from "@thrivelot/aws";
import { Plus } from "@thrivelot/stories";
import { Button } from "@thrivelot/stories";
import { colorMap, iconMap } from "../../RowItemFeatureLineItem/maps";
import { LayoutModal } from "../..";

const resultsCount = 8;

const ModalAddFeatureLineItem = ({ onClose, projectId, featureId }) => {
  const { loaded, actions, updateModel } = useModel({ modelName: "Project", id: projectId });
  const { query, setQuery, lineItems } = useModelLineItemCatalog();

  const create = useCallback(
    (name) => {
      const freshItem = actions.constructLineItem({
        name: name.trim(),
        type: ProjectLineItemType.MATERIAL,
        unit: MeasurementUnit.SQ_FT,
      });

      updateModel(
        actions
          .add("lineItems", freshItem, 0)
          .add(`features[id:${featureId}].orderedChildTagIds`, { type: TagIdType.LINE_ITEM, tagId: freshItem.id })
          .result
      );

      onClose();
    },
    [onClose, updateModel, actions, featureId]
  );

  const add = useCallback(
    (item) => {
      const freshItem = actions.constructLineItem({ ...item, id: constructUuid() });

      updateModel(
        actions
          .add("lineItems", freshItem, 0)
          .add(`features[id:${featureId}].orderedChildTagIds`, { type: TagIdType.LINE_ITEM, tagId: freshItem.id })
          .result
      );

      onClose();
    },
    [onClose, updateModel, actions, featureId]
  );

  return (
    <LayoutModal
      onClose={onClose}
      loading={!loaded}
      header={<div className="text-lg text-brown-dark italic">Add a line item...</div>}
    >
      <div className="flex flex-row">
        <input
          className="input"
          placeholder="Search for a line item to add..."
          value={query}
          onChange={(e) => setQuery(e.target.value)}
        />
        <Button label="Create" color="blue-main" kind="outline" classAppend="ml-3" onClick={() => create(query)} />
      </div>
      <div className="mt-4 flex flex-col gap-3">
        {lineItems.slice(0, resultsCount).map(({ label, value, item }) => {
          const { type } = item;
          const Icon = iconMap[type];

          return (
            <div key={value} className="flex items-center text-mulch-light">
              <div className="mr-3">
                <Icon size={20} color={colorMap[type]} />
              </div>
              <div className="w-full flex-1">
                <div className="font-bold">{label}</div>
              </div>
              <Plus color="green-main" size="20" styleProps="hover:cursor-pointer" onClick={() => add(item)} />
            </div>
          );
        })}
      </div>
      {/* {query && lineItems.length < resultsCount && (
        <div className="mt-4 pt-4 border-t border-brown-light text-brown-dark flex items-center">
          <div className="flex-1">
            Create <span className="font-bold">"{query}"</span>
          </div>
          <Plus color="brown-dark" size="20" styleProps="hover:cursor-pointer" onClick={() => create(query)} />
        </div>
      )} */}
    </LayoutModal>
  );
};

export { ModalAddFeatureLineItem };
