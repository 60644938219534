import { useCallback, useEffect, useRef, useState } from "react";
import { ApiKit } from "@thrivelot/api";
import { apiEndpoints } from "@thrivelot/aws";
import { authConfig } from "@thrivelot/config";

const useService = ({ method, level, path, variables, headers = {}, deferLoad = false }) => {
  const [loading, setLoading] = useState(!deferLoad);
  const [loaded, setLoaded] = useState(false);
  const [data, setData] = useState(null);
  const [errors, setErrors] = useState(null);

  const methodRef = useRef(method);
  const levelRef = useRef(level);
  const pathRef = useRef(path);
  const variablesRef = useRef(variables);
  const headersRef = useRef(headers);
  const deferLoadRef = useRef(deferLoad);
  const loadingRef = useRef(loading);
  const loadedRef = useRef(loaded);
  const errorsRef = useRef(errors);

  loadingRef.current = loading;
  loadedRef.current = loaded;
  errorsRef.current = errors;

  const request = useCallback(async (props = { variables: null, headers: null }) => {
    try {
      if (!loadingRef.current) setLoading(true);

      // Clear errors state
      if (errorsRef.current) setErrors(null);

      // Update variables and headers if passed as args
      if (props.variables) variablesRef.current = props.variables;
      if (props.headers) headersRef.current = props.headers;

      const headers = { ...headersRef.current };

      // Fetch and pass jwt if level is not public
      if (levelRef.current !== "public") {
        const jwt = await authConfig.fetchJwt();

        headers.Authorization = `Bearer ${jwt}`;
      }

      // Construct endpoint from level and path
      const endpoint = `${apiEndpoints.Services}/${levelRef.current}/${pathRef.current}`;

      // Setup api request based on the method
      const request = new ApiKit({ variables: variablesRef.current || {}, endpoint, headers });
      const data = await request[methodRef.current]();

      setData(data);

      return { data };
    } catch (err) {
      const error = new Error(err?.message || `Error in request to "${levelRef.current}/${pathRef.current}"`);
      error.name = err?.name || "ServiceError";
      error.stack = err;

      console.error(`${error.message}: `, JSON.stringify(err, null, 2));

      setErrors([error]);

      return { errors: [error] };
    } finally {
      if (loadingRef.current) setLoading(false);
      setLoaded(true);
    }
  }, []);

  useEffect(() => {
    if (!deferLoadRef.current) request();
  }, [request]);

  return { loading, loaded, data, errors, request };
};

export { useService };
