import { css } from "styled-components";

const CssTextDefault = css`
  -webkit-appearance: none;
  ::selection {
    background-color: ${({ theme }) => theme.inputTintColor};
    color: ${({ theme }) => theme.textLight};
  }
  blockquote::selection {
    background-color: ${({ theme }) => theme.inputTintColor};
    color: ${({ theme }) => theme.textLight};
  }
  transition: opacity 0.5s ease-out, box-shadow 0.5s ease-out, color 1s ease-in-out, background-color 1s ease-in-out;
  white-space: pre-wrap;
  ${({ disabled, hoverable }) =>
    !disabled &&
    hoverable &&
    css`
      cursor: pointer;
      &:hover {
        opacity: 0.7;
      }
    `}
`;

export default CssTextDefault;
