import styled from "styled-components";

const DivModalShadow = styled.div`
  transition: opacity 0.5s ease-out, box-shadow 0.5s ease-out, color 1s ease-in-out, background-color 1s ease-in-out;
  position: fixed;
  height: 100vh;
  width: 100vw;
  top: 0px;
  left: 0px;
  background-color: black;
  opacity: 0.7;
`;

export default DivModalShadow;
