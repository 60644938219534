import {
  changePassword,
  completeNewPassword,
  fetchUser,
  fetchUserCredentials,
  fetchUserJwt,
  fetchUserSession,
  refreshUserSession,
  resetPassword,
  sendPasswordResetCode,
  sendUserAttributeVerificationCode,
  signIn,
  signOut,
  updateUserAttributes,
  verifyUserAttribute,
} from '@thrivelot/auth';
import { fetchCredentials } from './fetchCredentials';
import { fetchJwt } from './fetchJwt';

const authConfig = {
  changePassword,
  completeNewPassword,
  fetchUser,
  fetchUserCredentials,
  fetchUserJwt,
  fetchUserSession,
  refreshUserSession,
  resetPassword,
  sendPasswordResetCode,
  sendUserAttributeVerificationCode,
  signIn,
  signOut,
  updateUserAttributes,
  verifyUserAttribute,
  fetchCredentials,
  fetchJwt,
};

export { authConfig };
