import React, { useState } from "react";
import { LayoutModal } from "../..";
import { useAuth } from "@thrivelot/hooks";
import { Button } from "@thrivelot/stories";
import { InputPassword } from "../../InputPassword";

const ModalEditPassword = ({ onClose }) => {
  const { user, changePassword } = useAuth();

  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const [errors, setErrors] = useState([]);
  const [saving, setSaving] = useState(false);

  const handleSubmit = async () => {
    const submitErrors = [];

    if (confirmPassword !== newPassword) submitErrors.push("New password must match confirm password");
    if (newPassword.length < 8) submitErrors.push("Password must be at least 8 characters");

    if (submitErrors.length > 0) return setErrors(submitErrors);

    try {
      setSaving(true);
      await changePassword(oldPassword, newPassword);
      setSaving(false);

      onClose();
    } catch (err) {
      setErrors([err.message]);
    } finally {
      setSaving(false);
    }
  };

  return (
    <LayoutModal onClose={onClose} loading={!user.attributes} header="Update Password">
      <div className="flex flex-col gap-4">
        <div className="mb-2 pb-6 border-b border-tan-light">
          <div className="text-green-dark text-sm font-bold mb-1">Old Password</div>
          <InputPassword className="input" value={oldPassword} onChange={(e) => setOldPassword(e.target.value)} />
        </div>

        <div className="">
          <div className="text-green-dark text-sm font-bold mb-1">New Password</div>
          <InputPassword className="input" value={newPassword} onChange={(e) => setNewPassword(e.target.value)} />
        </div>

        <div className="">
          <div className="text-green-dark text-sm font-bold mb-1">Confirm Password</div>
          <input
            type="password"
            className="input"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
          />
        </div>

        {errors.length > 0 && (
          <div className="">
            {errors.map((error) => (
              <div key={error} className="text-red-main text-sm">
                {error}
              </div>
            ))}
          </div>
        )}

        <Button
          disabled={saving}
          loading={saving}
          block
          kind="outline"
          color="blue-main"
          label="Submit"
          onClick={handleSubmit}
        />
      </div>
    </LayoutModal>
  );
};

export { ModalEditPassword };
