import styled from "styled-components";
import CssRaisedShadow from "./CssRaisedShadow";
import CssDivDefault from "./CssDivDefault";

const DivAuthorizationContainer = styled.div`
  ${CssDivDefault}
  ${CssRaisedShadow}
  position: absolute;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${({ theme }) => theme.foreground};
  @media (max-width: 767px) {
    justify-content: space-between;
    padding: 30px 12px 12px;
    top: 50px;
    right: 8px;
    bottom: 8px;
    left: 8px;
  }
  @media (min-width: 768px) {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 30px;
    min-width: fit-content;
    max-width: fit-content;
    min-height: fit-content;
    max-height: fit-content;
  }
`;

export default DivAuthorizationContainer;
