import React, { useMemo } from "react";
import { InvoiceProposalStatus } from "@thrivelot/aws";
import { transformStringToTitleCase } from "@thrivelot/utils";
import { useModel } from "@thrivelot/hooks";
import { Dropdown } from "@thrivelot/stories";
import { AngleDown } from "@thrivelot/stories";
import { LeafLoader } from "@thrivelot/stories";

const DropdownInvoiceProposalStatus = ({ invoiceProposalId }) => {
  const {
    model: invoiceProposal,
    loaded,
    updateModel,
    actions,
  } = useModel({ modelName: "InvoiceProposal", id: invoiceProposalId });

  const options = useMemo(
    () =>
      invoiceProposal?.status &&
      Object.values(InvoiceProposalStatus).map((option) => ({
        label: transformStringToTitleCase(option),
        selected: invoiceProposal.status === option,
        onClick: () => updateModel(actions.set("status", option).result),
      })),
    [invoiceProposal?.status, actions, updateModel]
  );

  if (!loaded) return <LeafLoader />;

  const { status } = invoiceProposal;

  return (
    <Dropdown
      label={
        <div className="text-green-dark text-sm flex items-center border border-green-dark px-2 py-0.5 rounded-main">
          {transformStringToTitleCase(status)}
          <AngleDown color="green-dark" size={14} styleProps="ml-2" />
        </div>
      }
      dropdownClassAppend="bottom-11"
      optionsClassAppend="!max-h-48"
      options={options}
    />
  );
};

export { DropdownInvoiceProposalStatus };
