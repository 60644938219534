const client = {
  modelConfig: {
    invalidUpdateKeys: [],
    subscriptions: {},
    models: {},
    onQueryError: () => {},
    onSaveError: () => {},
    onSubscriptionError: () => {},
    onFileQueryError: () => {},
    onFileSaveError: () => {},
    onUpdateVariableInput: () => {},
  },
  endpoint: null,
  apiKey: null,
  fetchJwt: null,
  queryNameRegex: null,
  setModelConfig(modelConfig) {
    if (!modelConfig) return;

    if (modelConfig.queryNameRegex)
      this.setQueryNameRegex(modelConfig.queryNameRegex);

    this.modelConfig = modelConfig;
  },
  setEndpoint(endpoint) {
    if (endpoint) this.endpoint = endpoint;
  },
  setApiKey(apiKey) {
    if (apiKey) this.apiKey = apiKey;
  },
  setFetchJwt(fetchJwt) {
    if (fetchJwt) this.fetchJwt = fetchJwt;
  },
  setQueryNameRegex(queryNameRegex) {
    if (queryNameRegex) this.queryNameRegex = queryNameRegex;
  },
  init({ modelConfig, endpoint, apiKey, fetchJwt, queryNameRegex }) {
    this.setModelConfig(modelConfig);
    this.setEndpoint(endpoint);
    this.setApiKey(apiKey);
    this.setFetchJwt(fetchJwt);
    this.setQueryNameRegex(queryNameRegex);
  },
};

export { client };
