import styled from "styled-components";

const DivLineVertical = styled.div`
  transition: opacity 0.5s ease-out, box-shadow 0.5s ease-out, color 1s ease-in-out, background-color 1s ease-in-out;
  min-width: 1px;
  max-width: 1px;
  min-height: 100%;
  max-height: 100%;
  background-color: ${({ isDark, theme }) => (isDark ? theme.divLineDark : theme.divLine)};
`;

export default DivLineVertical;
