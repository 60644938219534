import { useModel, useFiles } from '@thrivelot/hooks';
import { AngleDown, Button, Dropdown } from '@thrivelot/stories';
import React, { useCallback, useMemo, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { LayoutModal } from '../..';
import { FilePreview } from './FilePreview';
import { convertHeicToPng } from './convertHeicToPng';

const ModalAddFiles = ({
  onClose,
  modelName,
  modelId,
  filesPath,
  allowMultiple,
  tagIds,
  fileFolderId,
  // imagesOnly, // TODO: Allow this flag to restrict file types to images only
  header = 'Add Files',
  subheader = 'You can add multiple files at once',
}) => {
  const [files, setFiles] = useState([]);
  const [folderId, setFolderId] = useState(fileFolderId);
  const [uploading, setUploading] = useState(false);
  const { upload } = useFiles();
  const {
    model: project,
    actions,
    updateModel,
  } = useModel({ modelName, id: modelId });

  if (allowMultiple) {
    header = header || 'Add Files';
    subheader = subheader || 'You can add multiple files at once';
  } else {
    header = header || 'Add File';
    subheader = subheader || 'Please add one file';
  }

  const folder = useMemo(() => {
    if (!folderId || !project?.fileFolders?.length) return null;

    return project.fileFolders.find((_folder) => _folder.id === folderId);
  }, [folderId, project?.fileFolders]);

  const folderOptions = useMemo(() => {
    if (!project?.fileFolders?.length) return [];

    const options = project.fileFolders.map((_folder) => ({
      icon: _folder.id === folderId ? 'Checkmark' : null,
      color: _folder.id === folderId ? 'green-main' : null,
      label: _folder.name,
      onClick: () => setFolderId(_folder.id),
    }));

    options.splice(0, 0, {
      icon: folderId ? null : 'Checkmark',
      color: folderId ? null : 'green-main',
      label: 'General',
      onClick: () => setFolderId(null),
    });

    return options;
  }, [folderId, project?.fileFolders]);

  const onDrop = useCallback(
    (acceptedFiles) => {
      acceptedFiles.forEach((acceptedFile) => {
        const reader = new FileReader();
        const file = {
          path: acceptedFile.path,
          name: acceptedFile.name,
          size: acceptedFile.size,
          type: acceptedFile.type,
        };

        reader.onabort = () => console.log('file reading was aborted');
        reader.onerror = () => console.log('file reading has failed');
        reader.onload = () => {
          const dataUrl = reader.result;
          setFiles((prevFiles) => [...prevFiles, { ...file, dataUrl }]);
        };

        if (acceptedFile.type === 'image/heic')
          convertHeicToPng(acceptedFile).then((blob) =>
            reader.readAsDataURL(blob)
          );
        else reader.readAsDataURL(acceptedFile);
      });
    },
    [setFiles]
  );

  const handleUpload = useCallback(() => {
    setUploading(true);
    let tasks = Promise.resolve();

    const fileFolders = [...(project?.fileFolders || [])];

    files.forEach((file, index) => {
      tasks = tasks.then(() => {
        setFiles((prevFiles) => {
          const newFiles = [...prevFiles];
          newFiles[index].status = 'uploading';
          return newFiles;
        });

        return upload({
          filename: file.name,
          metadata: { fileType: file.type },
          data: file.dataUrl.replace(/^data:.+,/, ''),
        }).then(({ data: { file: _file } }) => {
          const fileId = actions.constructUuid();

          if (folderId) {
            const folderIndex = fileFolders.findIndex(
              (_folder) => _folder.id === folderId
            );
            fileFolders[folderIndex].fileIds = [
              ...(fileFolders[folderIndex].fileIds || []),
              fileId,
            ];

            updateModel(
              actions
                .add(
                  filesPath,
                  {
                    id: fileId,
                    caption: '',
                    tagIds: tagIds || [],
                    file: _file,
                  },
                  0
                )
                .set('fileFolders', fileFolders).result
            );
          } else
            updateModel(
              actions.add(
                filesPath,
                {
                  id: fileId,
                  caption: '',
                  tagIds: tagIds || [],
                  file: _file,
                },
                0
              ).result
            );

          setFiles((prevFiles) => {
            const newFiles = [...prevFiles];
            newFiles[index].status = 'done';
            return newFiles;
          });
        });
      });
    });

    tasks.then(() => {
      setUploading(false);
      onClose();
    });
  }, [
    files,
    setUploading,
    onClose,
    upload,
    actions,
    filesPath,
    updateModel,
    tagIds,
    project?.fileFolders,
    folderId,
  ]);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  return (
    <LayoutModal
      onClose={onClose}
      header={<div className="font-bold text-brown-dark">{header}</div>}
      subheader={<div className="text-sm text-brown-dark">{subheader}</div>}
      footer={
        <div className="flex items-center justify-between gap-3">
          <div className="flex items-center">
            <div className="text-sm text-brown-dark mr-2">Folder:</div>
            <Dropdown
              label={
                <div className="gap-3 flex items-center justify-center py-2 px-4 border-2 border-tan-light rounded-main">
                  <div className="text-brown-dark font-bold">
                    {folder ? folder.name : 'General'}
                  </div>
                  <AngleDown size={14} />
                </div>
              }
              options={folderOptions}
              optionClass="font-bold"
              align="left"
              verticalAlign="top"
            />
          </div>
          <Button
            disabled={uploading || files.length === 0}
            loading={uploading}
            kind="outline"
            color="blue-main"
            label="Upload"
            onClick={handleUpload}
          />
        </div>
      }
    >
      {files.length > 0 && (
        <div className="pb-5 flex gap-4 items-center">
          {files.map((file) => (
            <FilePreview key={file.name} file={file} height={48} />
          ))}
        </div>
      )}
      {!allowMultiple && files.length > 0 ? null : (
        <div {...getRootProps()}>
          <input {...getInputProps()} />
          <div className="w-full h-36 flex items-center justify-center border-dashed border-2 border-brown-light p-5 text-brown-light hover:cursor-pointer focus:outline-none focus:ring-none">
            {isDragActive
              ? 'Drop files here'
              : "Drag 'n' drop some files here, or click to select files"}
          </div>
        </div>
      )}
    </LayoutModal>
  );
};

export { ModalAddFiles };
