import React, { Fragment, useMemo } from "react";
import { useToggle } from "react-use";
import { dateFns } from "@thrivelot/date";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import colors from "@thrivelot/tailwind/colors";
import { duplicateObject, transformCurrencyToString } from "@thrivelot/utils";
import { constructKeyMap, calculatePaymentMarkup, calculatePaymentTotal } from "@thrivelot/common";
import { useIsMobile, useModel } from "@thrivelot/hooks";
import { CostPaymentScheduleType } from "@thrivelot/aws";
import {
  DivColumn,
  DivLineVertical,
  DivInset,
  DivListRow,
  DivLineHorizontal,
  TextBodyBold,
  TextHeader,
  Textarea,
} from "@thrivelot/styles";
import { DropIconMap } from "../../shared";
import { styleForListItemCostContainer } from "../helpers";
import { StandardCalculation } from "./StandardCalculation";
import { YieldToThrive } from "./YieldToThrive";
import { DateIcon } from "./DateIcon";

const getTitle = (type) => {
  if (type === CostPaymentScheduleType.SITE_VISIT) return "Site Visit";
  if (type === CostPaymentScheduleType.DESIGN) return "Design Plan & Detailed Planning";
  return "Invoice";
};

const renderDateString = (date, dateFormat) => {
  if (date.startsWith("2021-11-29") || date.startsWith("2021-11-30")) {
    return <TextBodyBold style={{ fontSize: "12px", flex: "1", minWidth: "fit-content" }}>N/A</TextBodyBold>;
  }

  return (
    <TextBodyBold style={{ fontSize: "12px", minWidth: "fit-content" }}>
      {dateFns(date).format(dateFormat)}
    </TextBodyBold>
  );
};

const mobileDateContainer = {
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  flexDirection: "row",
  minWidth: "90px",
  maxWidth: "90px",
  minHeight: "35px",
  maxHeight: "35px",
  padding: "0px 8px",
  marginRight: "12px",
};

const MobilePayment = ({
  theme,
  isOpen,
  toggleIsOpen,
  DropIcon,
  memo,
  date,
  totalCost,
  updateMemo,
  updateInnerPayment,
  calculation,
  keyMap,
  type,
  markup,
  dollarMarkup,
  updateCalculation,
  siteVisitCost,
}) => (
  <DivColumn>
    <DivListRow
      style={{
        marginBottom: "5px",
      }}
    >
      <TextHeader color={theme.textTitle} style={{ width: "100%", marginRight: "12px", marginTop: "6px" }}>
        {getTitle(type)}
      </TextHeader>
      <TextHeader color={theme.green} style={{ marginRight: "12px", marginTop: "6px" }}>
        {transformCurrencyToString(totalCost)}
      </TextHeader>
      <DropIcon hoverable color={colors.yellow.dark} size={35} onClick={toggleIsOpen} />
    </DivListRow>
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        marginBottom: "12px",
      }}
    >
      <DivInset style={mobileDateContainer}>
        <DatePicker
          popperPlacement="bottom-start"
          showPopperArrow={false}
          selected={dateFns(date).toDate()}
          onChange={(selectedDate) => {
            updateInnerPayment({ date: selectedDate.toISOString() });
          }}
          customInput={<DateIcon />}
        />
        <TextBodyBold
          style={{
            maxHeight: "fit-content",
            minWidth: "fit-content",
            maxWidth: "fit-content",
          }}
        >
          {dateFns(date).format("M/D/YY")}
        </TextBodyBold>
      </DivInset>
    </div>
    {isOpen && (
      <Textarea
        placeholder="Activities & deliverables"
        value={memo || ""}
        onChange={(e) => updateMemo(e.target.value)}
        style={{ minHeight: "60px", maxHeight: "60px" }}
      />
    )}
    {isOpen && (
      <Fragment>
        {calculation.map((calc, index) => (
          <StandardCalculation
            key={keyMap[`${index}`]}
            calculation={calc}
            updateCalculation={
              type === CostPaymentScheduleType.SITE_VISIT
                ? undefined
                : (updated) => updateCalculation({ calcIndex: index, updated })
            }
          />
        ))}
        <YieldToThrive
          isLast
          percentageMarkup={markup}
          dollarMarkup={transformCurrencyToString(dollarMarkup)}
          updatePayment={type !== CostPaymentScheduleType.SITE_VISIT && updateInnerPayment}
          siteVisitCost={siteVisitCost}
        />
      </Fragment>
    )}
    <DivLineHorizontal isDark />
  </DivColumn>
);

const StandardPayment = ({ paymentId, projectId }) => {
  const theme = window.$theme;

  const [isOpen, toggleIsOpen] = useToggle(false);

  const isMobile = useIsMobile();

  const { model, updateModel, actions } = useModel({ modelName: "Project", id: projectId });
  const { costPaymentSchedule } = model;

  const getPayment = () => {
    if (!costPaymentSchedule) return undefined;

    const payment = costPaymentSchedule.find((p) => p.id === paymentId);
    return payment;
  };

  const keyMap = useMemo(constructKeyMap, []);

  const payment = getPayment();
  if (!payment) return null;

  const updatePayment = (updated) => {
    const newCostPaymentSchedule = duplicateObject(costPaymentSchedule);
    const index = newCostPaymentSchedule.findIndex((p) => p.id === paymentId);

    if (index === -1) return;

    Object.keys(updated).forEach((key) => {
      newCostPaymentSchedule[index][key] = updated[key];
    });

    updateModel({ costPaymentSchedule: newCostPaymentSchedule });
  };
  const updateMemo = (value) => {
    updateModel(actions.set(`costPaymentSchedule[id:${paymentId}].memo`, value).result);
  };
  const updateInnerPayment = (updated) => {
    const newCostPaymentSchedule = duplicateObject(costPaymentSchedule);
    const index = newCostPaymentSchedule.findIndex((p) => p.id === paymentId);

    if (index === -1) return;

    const thisPayment = newCostPaymentSchedule[index].payments[0];

    if (!thisPayment) return;

    Object.keys(updated).forEach((key) => {
      thisPayment[key] = updated[key];
    });

    newCostPaymentSchedule[index].payments[0] = thisPayment;

    updateModel({ costPaymentSchedule: newCostPaymentSchedule });
  };

  const updateCalculation = ({ calcIndex, updated }) => {
    const { payments } = duplicateObject(payment);
    const thisCalculation = { ...payments[0].calculation[calcIndex] };

    Object.keys(updated).forEach((key) => {
      thisCalculation[key] = updated[key];
    });

    payments[0].calculation[calcIndex] = thisCalculation;

    updatePayment({ payments });
  };

  const phaseDateContainerStyle = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
    minWidth: "110px",
    maxWidth: "110px",
    padding: "0px 8px",
    marginRight: "12px",
  };
  const dateFormat = isMobile ? "M/D/YY" : "MMM D, 'YY";

  const { type, memo } = payment;

  const thisPayment = payment.payments[0];
  const { calculation, date, markup } = thisPayment;

  // eslint-disable-next-line prefer-const
  let { subtotal: totalCost, dollarMarkup } = calculatePaymentTotal(payment, model, false, true);
  let siteVisitCost;
  if (type === "DESIGN") {
    siteVisitCost =
      actions.get(`costPaymentSchedule[prop:type:SITE_VISIT].payments[index:0].calculation[index:0].amount`) *
      (1 + actions.get(`costPaymentSchedule[prop:type:SITE_VISIT].payments[index:0].markup`));
  }
  if (!dollarMarkup) dollarMarkup = calculatePaymentMarkup(payment, model);

  const DropIcon = DropIconMap[isOpen ? "up" : "down"];

  if (isMobile)
    return (
      <MobilePayment
        theme={theme}
        isOpen={isOpen}
        toggleIsOpen={toggleIsOpen}
        DropIcon={DropIcon}
        memo={memo}
        date={date}
        totalCost={totalCost}
        updateMemo={updateMemo}
        updateInnerPayment={updateInnerPayment}
        calculation={calculation}
        keyMap={keyMap}
        type={type}
        markup={markup}
        dollarMarkup={dollarMarkup}
        updateCalculation={updateCalculation}
        siteVisitCost={siteVisitCost}
      />
    );

  return (
    <DivColumn>
      <DivListRow
        style={{
          display: "flex",
          alignItems: "center",
          marginBottom: isOpen ? "5px" : "20px",
        }}
      >
        <div style={{ ...phaseDateContainerStyle, marginRight: "12px" }}>
          <DatePicker
            popperPlacement="bottom-start"
            showPopperArrow={false}
            selected={dateFns(date).toDate()}
            onChange={(selectedDate) => {
              updateInnerPayment({ date: selectedDate.toISOString() });
            }}
            customInput={<DateIcon />}
          />
          {renderDateString(date, dateFormat)}
        </div>
        <div
          style={{
            ...phaseDateContainerStyle,
            marginLeft: "13px",
            marginRight: "12px",
          }}
        />
        <DivLineVertical isDark style={{ minHeight: "30px", maxHeight: "30px", marginRight: "12px" }} />
        <TextHeader color={theme.textTitle} style={{ width: "100%", marginRight: "20px" }}>
          {getTitle(type)}
        </TextHeader>
        <div style={{ display: "flex", alignItems: "center" }}>
          <div
            style={{
              ...styleForListItemCostContainer,
              marginRight: "20px",
              backgroundColor: "transparent",
            }}
          >
            <TextHeader color={theme.green}>{transformCurrencyToString(totalCost)}</TextHeader>
          </div>
          <DropIcon hoverable color={colors.yellow.dark} size={35} onClick={toggleIsOpen} />
        </div>
      </DivListRow>
      {isOpen && (
        <Textarea
          placeholder="Activities & deliverables"
          value={memo || ""}
          onChange={(e) => updateMemo(e.target.value)}
          style={{
            marginTop: "8px",
            marginLeft: "262px",
            marginRight: "55px",
            minHeight: "90px",
            maxHeight: "90px",
          }}
        />
      )}
      {isOpen && (
        <Fragment>
          {calculation.map((calc, index) => (
            <StandardCalculation
              key={keyMap[`${index}`]}
              calculation={calc}
              updateCalculation={
                type === CostPaymentScheduleType.SITE_VISIT
                  ? undefined
                  : (updated) => updateCalculation({ calcIndex: index, updated })
              }
            />
          ))}
          <YieldToThrive
            isLast
            amount={type === CostPaymentScheduleType.DESIGN ? calculation[0].amount : undefined}
            percentageMarkup={markup}
            dollarMarkup={transformCurrencyToString(dollarMarkup)}
            updatePayment={type !== CostPaymentScheduleType.SITE_VISIT && updateInnerPayment}
            siteVisitCost={siteVisitCost}
          />
        </Fragment>
      )}
      <DivLineHorizontal isDark />
    </DivColumn>
  );
};

export { StandardPayment };
