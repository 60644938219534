import React from "react";
import { dateFns } from "@thrivelot/date";
import { useTheme } from "@thrivelot/hooks";
import { useProposal } from "@thrivelot/common";
import { transformCurrencyToString } from "@thrivelot/utils";
import { TextBody } from "@thrivelot/styles";
import { contentHashmap } from "./Investment/contentHashmap";

const Paid = () => {
  const theme = useTheme();
  const { projectActions, type } = useProposal();

  const content = contentHashmap[type];
  const { payment, totalCost } = content.calculateCost(projectActions, content.type);

  return (
    <TextBody style={{ display: "flex", justifyContent: "center" }}>
      <div>
        Paid <span style={{ color: theme.blue }}>{transformCurrencyToString(totalCost)} </span>
        on <span style={{ color: theme.blue }}>{dateFns(payment.paidAt).format("MMM, D YYYY")}</span>
      </div>
    </TextBody>
  );
};

export { Paid };
