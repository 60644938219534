import { useMemo } from "react";
import { useIsMobile } from "../useIsMobile";

const useMobileDifference = ({ desktop, mobile }) => {
  const isMobile = useIsMobile();

  return useMemo(() => (isMobile ? mobile : desktop), [isMobile, desktop, mobile]);
};

export { useMobileDifference };
