import React from "react";
import { NotesContainer } from "./NotesContainer";
import { Note } from "./Note";

const constructNote = (title, note) => {
  if (!note) return { title, note: "" };
  return { title, note };
};

const constructNotesArray = (plantInfo) => {
  const notes = [];
  notes.push(constructNote("General notes", plantInfo.notes));
  notes.push(constructNote("Sowing description", plantInfo.sowingDescription));
  notes.push(constructNote("Growing description", plantInfo.growingDescription));
  return notes.filter((note) => note.note.trim() !== "");
};

const Notes = ({ plantInfo }) => {
  // constants
  const notes = constructNotesArray(plantInfo);
  // breakout
  if (notes.length === 0) return null;
  // return
  return (
    <NotesContainer>
      {notes.map(({ title, note }) => (
        <Note key={title} title={title} note={note} />
      ))}
    </NotesContainer>
  );
};

export { Notes };
