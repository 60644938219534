import styled from "styled-components";

const InvestmentContainer = styled.div`
  transition: opacity 0.5s ease-out, box-shadow 0.5s ease-out, color 1s ease-in-out, background-color 1s ease-in-out;
  border: 1px solid ${({ theme }) => theme.divLine};
  overflow: hidden;
  border-radius: 5px;
  min-height: fit-content;
  max-height: fit-content;
  @media (max-width: 767px) {
    margin: 12px 0px 0px;
    padding: 8px;
  }
  @media (min-width: 768px) {
    margin: 70px 50px 50px;
    padding: 12px;
  }
`;

export { InvestmentContainer };
