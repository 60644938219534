import styled from "styled-components";

const NoteContainer = styled.div`
  transition: opacity 0.5s ease-out, box-shadow 0.5s ease-out, color 1s ease-in-out, background-color 1s ease-in-out;
  border: 1px solid ${({ theme }) => theme.divLine};
  overflow: hidden;
  border-radius: 5px;
  min-height: fit-content;
  max-height: fit-content;
  break-inside: avoid;
  @media (max-width: 767px) {
    padding: 8px;
    margin-bottom: 12px;
  }
  @media (min-width: 768px) {
    padding: 12px;
    margin-bottom: 20px;
  }
`;

export { NoteContainer };
