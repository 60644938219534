import React, { useMemo } from 'react';
import { useModel } from '@thrivelot/hooks';
import { LeafLoader, RowList, Cal } from '@thrivelot/stories';
import { RowItemFeature } from '..';

const PhaseFeaturesGroup = ({ projectId, phaseId }) => {
  const { actions, loaded } = useModel({ modelName: 'Project', id: projectId });

  const phase = useMemo(
    () => actions.get(`phases[id:${phaseId}]`),
    [actions, phaseId]
  );

  const features = useMemo(
    () => actions.findItemsByTags(`phases[id:${phaseId}]`, 'features') || [],
    [actions, phaseId]
  );

  if (!loaded) return <LeafLoader />;

  const { name } = phase;

  if (features.length === 0) return null;

  return (
    <div>
      {name ? (
        <div className="text-blue-dark flex items-center mb-3">
          <Cal color="blue-dark" size="16" />
          <div className="font-bold ml-2 text-xl text-ellipsis overflow-hidden whitespace-nowrap">
            {name}
          </div>
        </div>
      ) : (
        <div className="italic">Edit this phase to give it a name...</div>
      )}

      <RowList>
        {features.map(({ id }) => (
          <RowItemFeature key={id} featureId={id} projectId={projectId} />
        ))}
      </RowList>
    </div>
  );
};

export { PhaseFeaturesGroup };
