import { ModelKit } from '@thrivelot/model-kit';
import { constructUuid } from '@thrivelot/utils';
import { useCallback, useEffect, useState } from 'react';
import { useModelKit, useModelList } from '../useModel';

// You can give useCatalog a supplierId OR an ownerId
const useCatalog = (props = {}) => {
  const {
    ownerId: initialOwnerId,
    supplierId: initialSupplierId,
    resultsCount = 10,
  } = props;

  const { loaded: modelKitLoaded } = useModelKit();
  const [catalog, setCatalog] = useState();
  const [ownerId, setOwnerId] = useState(initialOwnerId);
  const [supplierId, setSupplierId] = useState(initialSupplierId);
  const [creating, setCreating] = useState(false);
  const [query, setQuery] = useState('');
  const [results, setResults] = useState('');

  const {
    models: catalogs,
    loaded: catalogsLoaded,
    loading: catalogsLoading,
    query: queryCatalogs,
  } = useModelList({
    modelName: 'Catalog',
    deferLoad: true,
  });

  // This load function can be simple because deferring the load is just initializing this hook without supplierId or ownerId
  // And setting these values automatically triggers the loading change of useEffect hooks
  const load = useCallback(
    ({ ownerId: newOwnerId, supplierId: newSupplierId }) => {
      if (newOwnerId) setOwnerId(newOwnerId);
      if (newSupplierId) setSupplierId(newSupplierId);
    }
  );

  const fetchCatalogs = useCallback(() => {
    if (ownerId) queryCatalogs({ owners: { contains: ownerId } });
  }, [ownerId, queryCatalogs]);

  const createCatalog = useCallback(async () => {
    if (ownerId) {
      setCreating(true);
      const newCatalog = {
        id: constructUuid(),
        owners: [ownerId],
        items: [],
      };

      const catalogKit = new ModelKit({
        modelName: 'Catalog',
        model: newCatalog,
      });
      await catalogKit.create();
      await fetchCatalogs();
      setCreating(false);
    }
  });

  useEffect(() => {
    // If the supplierId is given but not the ownerId, fetch the supplier and set the ownerId
    const handle = async () => {
      const supplierKit = new ModelKit({
        modelName: 'Supplier',
        id: supplierId,
      });
      const supplier = await supplierKit.query();
      setOwnerId(supplier?.owners[0]);
    };

    if (modelKitLoaded && !ownerId && supplierId) handle();
  }, [modelKitLoaded, ownerId, supplierId]);

  useEffect(() => {
    // Whenever the ownerId is valid, fetch the catalogs
    if (ownerId) fetchCatalogs();
  }, [ownerId, fetchCatalogs]);

  useEffect(() => {
    // Whenever the catalogs load, set the catalog in state
    if (catalogsLoaded && catalogs?.length > 0) {
      const firstCatalog = catalogs?.[0];
      setCatalog(firstCatalog);
      setResults(firstCatalog.items);
    }
  }, [catalogsLoaded, catalogs]);

  useEffect(() => {
    // Whenever the query changes, filter the results
    if (!catalog) return;

    if (query?.length > 0) {
      const filteredResults = catalog?.items
        ?.filter((item) => {
          const { name } = item;
          return name && name?.toLowerCase().includes(query.toLowerCase());
        })
        .slice(0, resultsCount);

      setResults(filteredResults);
    } else {
      setResults(catalog?.items?.slice(0, resultsCount) || []);
    }
  }, [query, catalog]);

  return {
    load,
    loading: catalogsLoading,
    loaded: ownerId && catalogsLoaded,
    creating,
    create: createCatalog,
    ownerId,
    catalog,
    query,
    setQuery,
    results,
  };
};

export { useCatalog };
