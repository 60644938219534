import { useConstructTimestamp, useModel } from '@thrivelot/hooks';
import { ModelKit } from '@thrivelot/model-kit';
import { Button } from '@thrivelot/stories';
import React, { useCallback, useState } from 'react';
import { Link } from 'react-router-dom';
import { Confirm } from '../Confirm';

const Action = ({
  invoiceProposalId,
  name,
  color,
  description,
  timestampKind,
  timestampDescription,
  newStatus,
  newBundlesStatus,
  showPreviewLink,
}) => {
  const { construct } = useConstructTimestamp();
  const [loading, setLoading] = useState(false);

  const { model, updateModel, actions } = useModel({
    modelName: 'InvoiceProposal',
    id: invoiceProposalId,
  });

  const handleUpdateBundlesStatus = useCallback(() => {
    if (!newBundlesStatus) return Promise.resolve();

    return Promise.all(
      model.bundleIds.map((id) => {
        // supplierKit.update({ updated: { viewers: [...(supplier.viewers || []), username] } });
        const bundleKit = new ModelKit({ modelName: 'Bundle', id });
        return bundleKit.update({ updated: { status: newBundlesStatus } });
      })
    );
  }, [model.bundleIds, newBundlesStatus]);

  const handleAction = useCallback(async () => {
    setLoading(true);

    await handleUpdateBundlesStatus();

    const timestamp = construct({
      kind: timestampKind,
      description: timestampDescription,
    });

    const newInvoiceProposal = actions.add('timestamps', timestamp).result;
    // newInvoiceProposal.timestamps = newInvoiceProposal.timestamps.map(
    //   (timestamp) => {
    //     const { __typename, ...rest } = timestamp;
    //     return rest;
    //   }
    // );

    updateModel(newInvoiceProposal);

    if (newStatus) updateModel(actions.set('status', newStatus).result);

    setLoading(false);
  }, [
    actions,
    construct,
    handleUpdateBundlesStatus,
    newStatus,
    timestampDescription,
    timestampKind,
    updateModel,
  ]);

  return (
    <div>
      <div className="mb-6 text-center">{description}</div>
      <div className="flex justify-center items-center gap-6">
        {showPreviewLink && (
          <Link to={`../${invoiceProposalId}`}>
            <Button
              label="Preview Order"
              kind="outline"
              icon="Visibility"
              color="blue-main"
            />
          </Link>
        )}

        {name === 'Submit' ? (
          <Confirm
            prompt="The customer will be notified when you submit. Please double check that everything is correct before submitting."
            onConfirm={handleAction}
          >
            <Button
              color={color || 'green-main'}
              loading={loading}
              label={name}
            />
          </Confirm>
        ) : (
          <Button
            color={color || 'green-main'}
            loading={loading}
            label={name}
            onClick={handleAction}
          />
        )}
      </div>
    </div>
  );
};

export { Action };
